import React from "react";
import { Box, Typography, Divider, Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { styled } from "@mui/system";
import BarChartIcon from "@mui/icons-material/BarChartRounded";
import { BoxContainer, Table } from "../../components";
import {
  FormSearchSelectField,
  FormDate,
  SubmitButton,
} from "../../components/Form";
import LineAreaChart from "../../components/LineAreaChart/LineAreaChart";
import ReflectionBarChart from "../../components/ReflectionBarChart/ReflectionBarChart";
import { useLocation } from "react-router-dom";
import { startOfMonth, format } from "date-fns";
import { Form, Formik } from "formik";
import Yup from "../../config/yup";
import { useCockpit } from "../../service";
import { useQuery } from "react-query";
import useNotifier from "../../hooks/useNotifier";
import convertDateTobirthday from "../../utils/convertDateToBirthday";

// Styled Components
const TabBackground = styled(Tab)(({ theme }) => ({
  display: "block",
  background: "#FFFF",
  color: theme.palette.primary.light,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 4,
  transition: ".3s",
  textTransform: "uppercase",
  minHeight: 30,
  padding: 6,
  fontWeight: 600,
  fontSize: 13,
  flex: 1,
  margin: "0px 5px",
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    borderColor: "transparent",
  },
}));

const TabLabel = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 10.5,
}));

const TabNumber = styled(Typography)(() => ({
  fontWeight: 300,
  fontSize: 24,
}));

// Settings to Chart
const gradientAreaConfig = [
  { id: "lineColor1", from: "#00E0FF", to: "#001AFF" },
  { id: "bgColor1", from: "#0A8596", to: "#0075FF" },
  { id: "lineColor2", from: "#FF00E5", to: "#FF00B8" },
  { id: "bgColor2", from: "#000", to: "#FF00E5" },
  { id: "lineColor3", from: "#000", to: "#61716F" },
  { id: "bgColor3", from: "#000", to: "#687B7D" },
];

const gradientBarConfig = [
  { id: "barColor1", from: "#0899BA77", to: "#0899BA77", x: 0, y1: 1, y2: 0 },
  { id: "barColor2", from: "#F7258588", to: "#F7258588", x: 0, y1: 1, y2: 0 },
  { id: "barColor3", from: "#aaa", to: "#999", x: 0, y1: 1, y2: 0 },
];

const areaConfig = [
  { key: "masc", lineId: "lineColor1", bgId: "bgColor1" },
  { key: "fem", lineId: "lineColor2", bgId: "bgColor2" },
  { key: "indef", lineId: "lineColor3", bgId: "bgColor3" },
];

const barConfig = [
  { key: "male", barId: "barColor1" },
  { key: "female", barId: "barColor2" },
  { key: "other", barId: "barColor3" },
];

// Formik Configs
const gender = [
  { name: "Masculino", id: 1 },
  { name: "Feminino", id: 2 },
  { name: "Outros", id: 3 },
];

const validations = Yup.object().shape({
  initial_date: Yup.date()
    .required()
    .min(new Date("01/01/1900"), "O ano deve ser posterior ou igual a 1900")
    .typeError("Data Inválida"),
  final_date: Yup.date()
    .required()
    .max(new Date(), "A data final não pode ser maior que o dia de hoje")
    .typeError("Data Inválida")
    .nullable(),
  gender: Yup.object().nullable(),
});

const initialValues = {
  initial_date: startOfMonth(new Date()),
  final_date: new Date(),
  gender: null,
};

// Object to titleAreaChart
const titles = {
  0: [
    "Monitorização da Pressão Arterial",
    "Doenças Crônicas",
    "Monitorização dos Sinais e Sintomas",
    "Mortalidade",
    "Plano de Cuidado",
  ],
  1: [
    "Monitorização Glicêmica",
    "Doenças Crônicas",
    "Tratamento de Lesões",
    "Mortalidade",
    "Plano de Cuidado",
  ],
  2: [
    "Estado Nutricional",
    "Doenças Crônicas",
    "Avaliação do Consumo Alimentar",
    "Mortalidade",
    "Plano de Cuidado",
  ],
  3: [
    "Risco de Queda",
    "Doenças Crônicas",
    "Comorbidade",
    "Mortalidade",
    "Plano de Cuidado",
  ],
  4: [
    "Alto Risco",
    "Doenças Crônicas",
    "Comorbidade",
    "Mortalidade",
    "Plano de Cuidado",
  ],
};

// Array of Objects for Patient Table
const tableHeaders = [
  { name: "Código Paciente" },
  { name: "Nome" },
  { name: "Idade" },
  { name: "Sexo" },
  { name: "Internações por Ano" },
];

function CockPitComorbidity() {
  const location = useLocation();
  const notify = useNotifier();
  const [tab, setTab] = React.useState(location?.state?.id || false);
  const [dataBarChart, setDataBarChart] = React.useState([]);
  const [titleAreaChart, setTitleAreaChart] = React.useState(titles[0]);
  const [tableRows, setTableRows] = React.useState([]);
  const [formFilter, setFormFilter] = React.useState({});

  const { getDataAnalisys, getDataAnalisysByProgram, getAgesDataAnalisys } =
    useCockpit();

  const queryDataAnalisys = useQuery(
    ["queryDataAnalisys"],
    () => getDataAnalisys(),
    { refetchOnWindowFocus: false, retry: false }
  );

  useQuery(
    ["queryAgesData", [tab, formFilter]],
    () => {
      if (tab) return getAgesDataAnalisys(tab, formFilter);
    },
    {
      onSuccess: (result) => {
        const modified = result.map((data) => {
          data.male = getPercent(data.male);
          data.female = getPercent(data.female);
          data.other = getPercent(data.other);
          return data;
        });
        setDataBarChart(modified);
      },
    }
  );

  const dataAnalisysByProgram = useQuery(
    ["DataAnalisysByProgram", [tab, formFilter]],
    () => getDataAnalisysByProgram(tab, formFilter),
    {
      enable: !!tab,
      onSuccess: (data) => {
        const rows = data.items.map(({ gender, number, name, birth_date }) => ({
          cod: number,
          name: name,
          idade: convertDateTobirthday(birth_date, ["years"]),
          sexo: gender,
          inter: "0",
        }));
        setTableRows(rows);
      },
      onError: () => {
        setTableRows([]);
        notify("Nenhum paciente encontrado", `error`);
      },
    }
  );

  React.useLayoutEffect(() => {
    window.scrollTo({ top: 0 });
    setTitleAreaChart(titles[0]);
  }, []);

  function handleChange(event, value) {
    setTab(value);
    setTableRows([]);
    dataAnalisysByProgram.refetch();
  }

  function getPercent(value) {
    return ((value * 100) / 3).toFixed();
  }

  const renderLabel = (item, number) => {
    return (
      <Box display="flex" flexDirection="column">
        <TabLabel component="span">{item}</TabLabel>
        <TabNumber component="span">{number}</TabNumber>
      </Box>
    );
  };

  function handleFilter(values) {
    const filter = {
      gender: values.gender?.name || undefined,
      initial_date: values.initial_date
        ? format(new Date(values.initial_date), "yyyy-MM-dd")
        : undefined,
      final_date: values.final_date
        ? format(new Date(values.final_date), "yyyy-MM-dd")
        : undefined,
    };
    setFormFilter(filter);
  }

  return (
    <>
      <BoxContainer title="Indicadores - Comorbidades">
        <Grid container display="flex">
          <Grid item xs={3}>
            {titleAreaChart?.map((item) => (
              <Box width="100%" sx={{ mb: 5.5 }}>
                <LineAreaChart
                  title={item}
                  data={[
                    { fem: 0, masc: 1 },
                    { fem: 2, masc: 3 },
                  ]}
                  percentage={`5%`}
                  gradientConfig={gradientAreaConfig}
                  areaConfig={areaConfig}
                />
              </Box>
            ))}
          </Grid>
          <Grid item xs={9}>
            <TabContext value={tab}>
              <TabList
                orientation="horizontal"
                onChange={handleChange}
                aria-label="programs"
                sx={{
                  "& .MuiTabs-indicator": { display: "none" },
                }}
              >
                {queryDataAnalisys.data?.programs?.map((item, key) => (
                  <TabBackground
                    label={renderLabel(item.name, item.patients)}
                    value={item.id}
                    sx={{ width: "100%" }}
                    icon={
                      <BarChartIcon
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          position: "absolute",
                          right: 8,
                          top: 2,
                        }}
                      />
                    }
                  />
                ))}
              </TabList>
              <Divider variant="fullWidth" sx={{ marginTop: 2 }} />
              <Box display="flex" sx={{ paddingX: 0.4, paddingY: 1.5 }}>
                <Grid container>
                  <Grid item xs={8}>
                    <ReflectionBarChart
                      title="Faixa Etária"
                      data={dataBarChart}
                      gradientConfig={gradientBarConfig}
                      barConfig={barConfig}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Formik
                      onSubmit={handleFilter}
                      initialValues={initialValues}
                      validationSchema={validations}
                    >
                      {() => (
                        <Form>
                          <Grid container gap={1.5} p={2.2}>
                            <Typography
                              sx={{
                                color: (theme) => theme.palette.primary.light,
                                fontWeight: 600,
                                fontSize: 12,
                              }}
                            >
                              Período
                            </Typography>
                            <Grid item display="flex" gap={1} xs={12}>
                              <FormDate
                                name="initial_date"
                                label="Início"
                                maxDate={new Date()}
                              />
                              <FormDate
                                name="final_date"
                                label="Fim"
                                maxDate={new Date()}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormSearchSelectField
                                name="gender"
                                label="Gênero"
                                options={gender}
                                getOptionLabel={(gender) => gender.name}
                                isOptionEqualToValue={(gender, value) => {
                                  return gender.id === value.id ? value : "";
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{ display: "flex", justifyContent: "end" }}
                            >
                              <SubmitButton>Filtrar</SubmitButton>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </Box>
              <Divider
                variant="fullWidth"
                sx={{ marginTop: 2.5, marginBottom: 2 }}
              />
              <Table size="small" columns={tableHeaders} rows={tableRows} />
            </TabContext>
          </Grid>
        </Grid>
      </BoxContainer>
    </>
  );
}

export default CockPitComorbidity;
