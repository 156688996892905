import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useTheme } from "@emotion/react";
import { Info, InfoOutlined } from "@mui/icons-material";
function FormRadio({
  name,
  radios,
  keys,
  required = false,
  disabled,
  disabledFn,
  checked,
  customChange = () => null,
  multiple,
  row = false,
  infoLegend,
  ...props
}) {
  const theme = useTheme();
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const settings = {
    name,
    row,
    ...props,
    ...field,
  };

  if (meta.touched && meta.error) {
    settings.error = true;
    settings.helperText = meta.error;
  }

  function onChange(e) {
    const value =
      e.target.value === "true"
        ? true
        : e.target.value === "false"
        ? false
        : e.target.value;

    setFieldValue(name, value);

    if (customChange instanceof Function) {
      customChange(value);
    }
  }

  return (
    <FormControl required={required}>
      <FormLabel
        sx={{
          fontSize: "0.8rem",
          color: meta.error
            ? theme.palette.error.main
            : theme.palette.primary.light,
        }}
      >
        {props.legend}
      </FormLabel>
      {infoLegend && (
        <Typography
          color={"primary.main"}
          component={"span"}
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "3px",
            fontSize: "10px",
          }}
        >
          <InfoOutlined sx={{ fontSize: "10px" }} />
          {infoLegend}
        </Typography>
      )}
      <RadioGroup
        {...settings}
        onChange={onChange}
        sx={{
          "& .MuiFormControlLabel-label": {
            fontSize: "0.8rem",
            color: meta.error
              ? theme.palette.error.main
              : theme.palette.primary.light,
          },
        }}
      >
        {radios &&
          radios.map((radio) => (
            <FormControlLabel
              key={radio[keys[1]]}
              sx={{ marginTop: -1 }}
              value={radio[keys[1]]}
              control={
                <Radio
                  checked={
                    field.value?.toString() === radio[keys[1]]?.toString()
                  }
                  sx={{
                    color: meta.error
                      ? theme.palette.error.main
                      : theme.palette.primary.light,
                  }}
                />
              }
              label={radio[keys[0]]}
              {...props}
              disabled={
                disabledFn instanceof Function
                  ? disabledFn(radio)
                  : radio.disabled || disabled
              }
            />
          ))}
      </RadioGroup>
      {meta.error && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default FormRadio;
