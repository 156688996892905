import React from "react";
import { BoxContainer } from "../../components";
import { Divider, Grid, Typography, Button, Box } from "@mui/material";
import Yup from "../../config/yup";
import { useCep, usePatient } from "../../service";
import { Feedback, Loading } from "../../helper";
import {
  states,
  bloodTypes,
  organDonor,
  race,
  genders,
  countries,
  kinships,
} from "../../config/standardSelects";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { format, isValid, parseISO } from "date-fns";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "../../components/FormFields/TextField";
import DateField from "../../components/FormFields/DateField";
import AutocompleteField from "../../components/FormFields/AutocompleteField";
import SwitchField from "../../components/FormFields/SwitchField";
import SelectField from "../../components/FormFields/SelectField";
import RegexOf from "../../config/regex";
import { cleanUpMask, formatWithMask } from "../../config/mask";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useNotifier from "../../hooks/useNotifier";

function PatientForm() {
  const [loading, setLoading] = React.useState(false);
  const [patientData, setPatientData] = React.useState("");
  const [cep, setCep] = React.useState("");
  let navigate = useNavigate();
  const notify = useNotifier();
  const regexConfig = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]*$/;
  const { fetchCep } = useCep();
  const { id } = useParams();
  const { state } = useLocation();
  const queryClient = useQueryClient();
  const { getPatient, updatePatient, postPatient } = usePatient();
  const postPatientMutation = useMutation(postPatient);
  const updatePatientMutation = useMutation(updatePatient);

  const initialValues = {
    name: "",
    social_name: "",
    social_prioritize: false,
    mother_name: "",
    dad_name: "",
    spouse_name: "",
    race: { describe: "" },
    nationality: { describe: "" },
    naturality: "",
    profession: "",
    marital_status: { describe: "" },
    birth_date: "",
    physic_national: "",
    required_cpf: true,
    ident_national: "",
    issuing_body: "",
    sus_card: null,
    required_sus_card: true,
    type_blood: { describe: "" },
    organ_donor: { describe: "" },
    email: "",
    phone: "",
    cell_phone: "",
    street: "",
    cep: "",
    district: "",
    county: "",
    state: "",
    address_number: "",
    address_complement: "",
    responsible_name: "",
    responsible_physic_national: "",
    required_responsible_physic_national: true,
    responsible_kinship: { describe: "" },
    responsible_phone: "",
    responsible_cell_phone: "",
    gender: { describe: "" },
    pregnant: false,
    disabled: false,
  };

  const validator = Yup.object().shape({
    name: Yup.string()
      .min(5, "Muito Curto")
      .required("Escreva o nome do paciente")
      .nullable()
      .matches(regexConfig, "Nome Inválido"),
    social_name: Yup.string().when("social_prioritize", {
      is: true,
      then: Yup.string()
        .required("É requerido")
        .nullable()
        .matches(regexConfig, "Nome Inválido"),
      otherwise: Yup.string().nullable().matches(regexConfig, "Nome Inválido"),
    }),
    social_prioritize: Yup.boolean(),
    mother_name: Yup.string()
      .required("Campo obrigatório")
      .nullable()
      .matches(regexConfig, "Nome Inválido"),
    dad_name: Yup.string().nullable().matches(regexConfig, "Nome Inválido"),
    spouse_name: Yup.string().nullable().matches(regexConfig, "Nome Inválido"),
    race: Yup.object().nullable().required("É requerido"),
    nationality: Yup.object().nullable(),
    naturality: Yup.string().nullable().matches(regexConfig, "Nome Inválido"),
    profession: Yup.string().nullable(),
    marital_status: Yup.object().nullable(),
    birth_date: Yup.date()
      .typeError("Data Inválida")
      .min(new Date("01/01/1900"), "O Ano deve ser posterior ou igual a 1900")
      .required("Insira a data de nascimento")
      .nullable(),
    required_cpf: Yup.boolean(),
    physic_national: Yup.string().when("required_cpf", {
      is: true,
      then: Yup.string().cpfValid().nullable().required("Insira o CPF"),
      otherwise: Yup.string().nullable(),
    }),
    ident_national: Yup.string().nullable(),
    issuing_body: Yup.string().nullable(),
    sus_card: Yup.number().when("required_sus_card", {
      is: true,
      then: Yup.number()
        .required()
        .nullable("CNS requerido")
        .typeError("Número Inválido"),
      otherwise: Yup.number().nullable().typeError("Número Inválido"),
    }),
    required_sus_card: Yup.boolean(),
    type_blood: Yup.object().nullable(),
    organ_donor: Yup.object().nullable(),
    gender: Yup.object().required("Selecione um gênero").nullable(),
    cep: Yup.string()
      .matches(/^(\d{5}-\d{3}|\d{8})$/, "Cep Inválido!!")
      .required("O CEP é obrigatório."),
    email: Yup.string().email("Email Inválido").nullable(),
    cell_phone: Yup.string()
      .required("Insira um número de telefone celular")
      .nullable(),
    phone: Yup.string().nullable(),
    street: Yup.string().nullable(),
    district: Yup.string().nullable(),
    county: Yup.string().nullable(),
    state: Yup.string().nullable(),
    address_number: Yup.string(),
    address_complement: Yup.string().nullable(),
    responsible_physic_national: Yup.string().when(
      "required_responsible_physic_national",
      {
        is: true,
        then: Yup.string().cpfValid().nullable().required("Insira o CPF"),
        otherwise: Yup.string().nullable(),
      }
    ),
    required_responsible_physic_national: Yup.boolean(),
    responsible_name: Yup.string()
      .required("Escreva o nome do responsavel")
      .nullable()
      .matches(regexConfig, "Nome Inválido"),
    responsible_cell_phone: Yup.string()
      .required("Insira o número de telefone celular")
      .nullable(),
    responsible_phone: Yup.string().nullable(),
    responsible_kinship: Yup.object()
      .required("Selecione o parentesco")
      .nullable(),
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validator),
    defaultValues: initialValues,
  });

  const [mother_name, dad_name, name, physic_national, phone, cell_phone] =
    watch([
      "mother_name",
      "dad_name",
      "name",
      "physic_national",
      "phone",
      "cell_phone",
    ]);

  const cleanerFields = () => {
    setValue("state", "");
    setValue("county", "");
    setValue("district", "");
    setValue("street", "");
  };

  useQuery(["cep", cep], () => fetchCep(cep), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!cep,
    onSuccess(response) {
      if (!response?.erro) {
        setValue("state", response.data.uf);
        setValue("county", response.data.localidade);
        setValue("district", response.data.bairro);
        setValue("street", response.data.logradouro);
      } else {
        cleanerFields();
      }
    },
    onError() {
      cleanerFields();
    },
  });

  useQuery("patient", () => getPatient(id), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== "new",
    onSuccess(response) {
      const cep = formatWithMask({
        mask: RegexOf.cep,
        text: response.contact?.cep,
      }).masked;

      const physic_national = formatWithMask({
        mask: RegexOf.cpf,
        text: response.physic_national,
      }).masked;

      const phone = formatWithMask({
        mask: RegexOf.phone,
        text: response.contact?.phone,
      }).masked;

      const cell_phone = formatWithMask({
        mask: RegexOf.phone,
        text: response.contact?.cell_phone,
      }).masked;

      const responsible_physic_national = formatWithMask({
        mask: RegexOf.cpf,
        text: response.responsible?.physic_national,
      }).masked;

      const responsible_phone = formatWithMask({
        mask: RegexOf.phone,
        text: response.responsible?.phone,
      }).masked;

      const responsible_cell_phone = formatWithMask({
        mask: RegexOf.phone,
        text: response.responsible?.cell_phone,
      }).masked;
      console.log(response);
      reset({
        name: response.name,
        social_prioritize: response.social_prioritize,
        social_name: response.social_name || "",
        required_physic_national: false,
        physic_national,
        ident_national: response.ident_national,
        issuing_body: response.issuing_body || "",
        required_sus_card: false,
        sus_card: response.sus_card || "",
        birth_date: response.birth_date ? parseISO(response.birth_date) : null,
        type_blood: { describe: response.type_blood || "" },
        organ_donor: { describe: response.organ_donor ? "Sim" : "Não" },
        gender: { describe: response.gender || "" },
        nationality: { describe: response.nationality || "" },
        naturality: response.naturality,
        race: { describe: response.race || "" },
        profession: response.profession || "",
        mother_name: response.mother_name,
        dad_name: response.dad_name || "",
        spouse_name: response.spouse_name || "",
        marital_status: { describe: response.marital_status || "" },
        cep,
        state: response.contact?.state || "",
        county: response.contact?.county || "",
        district: response.contact?.district || "",
        street: response.contact?.street || "",
        address_number: response.contact?.address_number || "",
        phone,
        cell_phone,
        email: response.contact?.email || "",
        responsible_kinship: { describe: response.responsible?.kinship },
        responsible_name: response.responsible?.name,
        required_responsible_physic_national: false,
        responsible_physic_national,
        responsible_phone,
        responsible_cell_phone,
      });
    },
    onError(error) {
      navigate("/attendance/patient", {
        replace: true,
      });
      notify(error.message, "error");
    },
  });

  function handleOnBlur(value) {
    if (!errors?.cep) {
      const cleanedCep = cleanUpMask(value, "", ["-"]);
      setCep(cleanedCep);
    }
  }

  function handleChange(value) {
    const kinship = value.describe;
    if (kinship === "Pai") {
      setValue("responsible_name", dad_name);
      setValue("responsible_physic_national", "");
      setValue("responsible_phone", "");
      setValue("responsible_cell_phone", "");
      return;
    }
    if (kinship === "Mãe") {
      setValue("responsible_name", mother_name);
      setValue("responsible_physic_national", "");
      setValue("responsible_phone", "");
      setValue("responsible_cell_phone", "");
      return;
    }
    if (kinship === "O Mesmo") {
      setValue("responsible_name", name);
      setValue("responsible_physic_national", physic_national);
      setValue("responsible_phone", phone);
      setValue("responsible_cell_phone", cell_phone);
      return;
    }
    setValue("responsible_name", "");
    setValue("responsible_physic_national", "");
    setValue("responsible_phone", "");
    setValue("responsible_cell_phone", "");
    setValue("responsible_kinship", kinship);
  }

  async function onSubmit(values) {
    delete values.required_cpf;
    delete values.required_sus_card;
    delete values.required_responsible_physic_national;
    values.birth_date = isValid(new Date(values.birth_date))
      ? format(values.birth_date, "yyyy-MM-dd")
      : null;
    const data = {
      ...values,
      physic_national: cleanUpMask(values.physic_national, "", ["_", "-", "."]),
      sus_card: cleanUpMask(values.sus_card?.toString(), "", ["_"]),
      ident_national: cleanUpMask(values.ident_national, "", [".", "_"]),
      race: values.race.describe || null,
      nationality: values.nationality.describe || null,
      marital_status: values.marital_status.describe || null,
      type_blood: values.type_blood.describe || null,
      contact: {
        email: values.email,
        phone: cleanUpMask(values.phone, "", ["-", " ", "+", "(", ")"]),
        cell_phone: cleanUpMask(values.cell_phone, "", [
          "-",
          " ",
          "+",
          "(",
          ")",
        ]),
        street: values.street,
        cep: cleanUpMask(values.cep, "", ["-"]),
        district: values.district,
        county: values.county,
        state: values.state,
        address_number: values.address_number,
        complement: values.address_complement,
      },
      responsible: {
        name: values.responsible_name,
        physic_national: cleanUpMask(values.responsible_physic_national, "", [
          "_",
          "-",
          ".",
        ]),
        kinship: values.responsible_kinship.describe,
        phone: cleanUpMask(values.responsible_phone, "", [
          "-",
          " ",
          "+",
          "(",
          ")",
        ]),
        cell_phone: cleanUpMask(values.responsible_cell_phone, "", [
          "-",
          " ",
          "+",
          "(",
          ")",
        ]),
      },
      gender: values.gender.describe || null,
      organ_donor: values.organ_donor.describe === "Sim" ? true : false,
      token_audit_id: state?.token_audit_id || null,
    };

    try {
      setLoading(true);
      if (id !== "new") {
        updatePatientMutation.mutate(
          { id, data },
          {
            onSuccess(response) {
              queryClient.invalidateQueries("patient");
              notify("Paciente atualizado com sucesso", "success");
              setPatientData(response);
              reset();
            },
            onError(error) {
              notify(error.message, "error");
            },
          }
        );
        return;
      } else {
        postPatientMutation.mutate(data, {
          onSuccess(response) {
            notify("Paciente cadastrado com sucesso", "success");
            setPatientData(response);
            reset();
          },
          onError(error) {
            notify(error.message, "error");
          },
        });
      }
    } catch (err) {
      setLoading(false);
      notify(err.response ? err.response.data.message : err.message, "error");
    } finally {
      setLoading(false);
    }
  }
  if (!!patientData.id) {
    return (
      <Feedback
        data={patientData}
        setData={setPatientData}
        token={state?.token_audit_id}
      />
    );
  }

  if (postPatientMutation.isLoading || updatePatientMutation.isLoading) {
    return <Loading />;
  }

  return (
    <BoxContainer title="Dados do Paciente">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={8} alignItems="stretch">
            <TextField
              name="name"
              control={control}
              label="Nome Completo"
              required
            />
          </Grid>
          <Grid item xs={2} alignItems="stretch">
            <DateField
              name="birth_date"
              label="Data de Nascimento"
              required
              maxDate={new Date()}
              control={control}
            />
          </Grid>
          <Grid item xs={2} alignItems="stretch">
            <AutocompleteField
              options={genders.sort()}
              optionLabelKey="describe"
              name="gender"
              label="Gênero"
              control={control}
            />
          </Grid>
          <Grid item xs={4.8} alignItems="stretch">
            <TextField
              name="social_name"
              label="Nome Social"
              control={control}
            />
          </Grid>
          <Grid item xs={1.2} alignItems="center">
            <SwitchField
              name="social_prioritize"
              label="Preferência?"
              control={control}
            />
          </Grid>
          <Grid item xs={2} alignItems="stretch">
            <AutocompleteField
              options={countries.sort()}
              optionLabelKey="describe"
              name="nationality"
              label="Nacionalidade"
              control={control}
            />
          </Grid>
          <Grid item xs={2} alignItems="stretch">
            <TextField
              name="naturality"
              label="Naturalidade"
              control={control}
            />
          </Grid>
          <Grid item xs={2} alignItems="stretch">
            <AutocompleteField
              options={race}
              optionLabelKey="describe"
              name="race"
              label="Raça"
              control={control}
              required
            />
          </Grid>
          <Grid item xs={3} alignItems="stretch">
            <TextField
              name="physic_national"
              label="CPF"
              control={control}
              mask={RegexOf.cpf}
            />
          </Grid>
          <Grid item xs={1.2}>
            <SwitchField
              name="required_cpf"
              label="CPF requerido?"
              control={control}
            />
          </Grid>
          <Grid item xs={3.9} alignItems="stretch">
            <TextField
              name="mother_name"
              label="Nome da Mãe:"
              control={control}
            />
          </Grid>
          <Grid item xs={3.9} alignItems="stretch">
            <TextField name="dad_name" label="Nome do Pai:" control={control} />
          </Grid>
          <Grid item xs={4} alignItems="stretch">
            <TextField name="spouse_name" label="Cônjuge:" control={control} />
          </Grid>
          <Grid item xs={3} alignItems="stretch">
            <TextField
              name="ident_national"
              label="Registro Geral:"
              control={control}
              mask={RegexOf.rg}
            />
          </Grid>
          <Grid item xs={3} alignItems="stretch">
            <TextField
              name="issuing_body"
              label="Orgão Emissor:"
              control={control}
            />
          </Grid>
          <Grid item xs={2} alignItems="stretch">
            <AutocompleteField
              options={states.sort()}
              optionLabelKey="describe"
              name="marital_status"
              label="Estado Civil:"
              control={control}
            />
          </Grid>
          <Grid item xs={3} alignItems="stretch">
            <TextField name="profession" label="Profissão" control={control} />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="sus_card"
              label="Cartão do SUS"
              mask={RegexOf.cns}
              control={control}
            />
          </Grid>
          <Grid item xs={1.2}>
            <SwitchField
              name="required_sus_card"
              label="CNS requerido?"
              control={control}
            />
          </Grid>
          <Grid item xs={3} alignItems="stretch">
            <AutocompleteField
              options={bloodTypes.sort()}
              optionLabelKey="describe"
              name="type_blood"
              label="Tipo Sanguineo"
              control={control}
            />
          </Grid>
          <Grid item xs={1.8} alignItems="stretch">
            <AutocompleteField
              options={organDonor.sort()}
              optionLabelKey="describe"
              name="organ_donor"
              label="Doador de orgãos"
              control={control}
            />
          </Grid>
        </Grid>
        <Box my={2}>
          <Typography variant="h7" color="secondary" fontWeight="500">
            Dados de Contato
          </Typography>
          <Divider sx={{ my: 1 }} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4} alignItems="stretch">
            <TextField
              name="email"
              type="email"
              label="Email"
              control={control}
            />
          </Grid>
          <Grid item xs={4} alignItems="stretch">
            <TextField
              name="phone"
              label="Telefone"
              mask={RegexOf.phone}
              control={control}
            />
          </Grid>
          <Grid item xs={4} alignItems="stretch">
            <TextField
              name="cell_phone"
              label="Celular"
              mask={RegexOf.phone}
              control={control}
              required
            />
          </Grid>
          <Grid item xs={1.2}>
            <TextField
              name="cep"
              label="CEP"
              required
              onBlur={(value) => handleOnBlur(value)}
              control={control}
              mask={RegexOf.cep}
            />
          </Grid>
          <Grid item xs={2.5}>
            <TextField
              name="street"
              fullWidth
              size="small"
              label="Logradouro"
              disabled={true}
              control={control}
            />
          </Grid>
          <Grid item xs={1.6}>
            <TextField
              name="district"
              fullWidth
              size="small"
              label="Bairro"
              disabled={true}
              control={control}
            />
          </Grid>
          <Grid item xs={1.2}>
            <TextField
              name="address_number"
              label="Número"
              control={control}
              required
              type="number"
            />
          </Grid>
          <Grid item xs={1.4}>
            <TextField
              name="county"
              fullWidth
              size="small"
              label="Localidade"
              control={control}
              disabled={true}
              props={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={0.8}>
            <TextField
              name="state"
              fullWidth
              size="small"
              label="Estado"
              control={control}
              disabled={true}
            />
          </Grid>
          <Grid item xs={3.3}>
            <TextField
              name="complement"
              fullWidth
              size="small"
              label="Complemento"
              control={control}
            />
          </Grid>
        </Grid>
        <Box my={2}>
          <Typography variant="h7" color="secondary" fontWeight="500">
            Dados do Responsável
          </Typography>
          <Divider sx={{ my: 1 }} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4.2} alignItems="stretch">
            <TextField name="responsible_name" label="Nome" control={control} />
          </Grid>
          <Grid item xs={1.6} alignItems="stretch">
            <SelectField
              name="responsible_kinship"
              label="Parentesco:"
              customOnChange={(value) => handleChange(value)}
              options={kinships}
              control={control}
              optionLabelKey="describe"
              required
            />
          </Grid>
          <Grid item xs={1.4} alignItems="stretch">
            <TextField
              name="responsible_physic_national"
              label="CPF"
              mask={RegexOf.cpf}
              control={control}
            />
          </Grid>
          <Grid item xs={1.2}>
            <SwitchField
              name="required_responsible_physic_national"
              label="CPF requerido?"
              control={control}
            />
          </Grid>
          <Grid item xs={1.8} alignItems="stretch">
            <TextField
              name="responsible_phone"
              label="Telefone"
              mask={RegexOf.phone}
              control={control}
            />
          </Grid>
          <Grid item xs={1.8} alignItems="stretch">
            <TextField
              name="responsible_cell_phone"
              label="Celular"
              mask={RegexOf.phone}
              control={control}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" gap={2}>
              <Button
                type="submit"
                disabled={loading}
                color="secondary"
                variant="contained"
              >
                {id !== "new" ? "Atualizar" : "Cadastrar"}
              </Button>
              <Button
                onClick={() =>
                  state?.token_audit_id
                    ? navigate("/attendance/patientUnregistered", {
                        replace: true,
                      })
                    : navigate("/attendance/patient", {
                        replace: true,
                      })
                }
              >
                Voltar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </BoxContainer>
  );
}

export default PatientForm;
