function dateTimeFormaterEdit(time) {
  const object = Object.entries(time);
  let dateString = "";

  object.forEach(([key, value], index, array) => {
    const existsNextValue = index < array.length - 1;
    const delimiter = " ";

    switch (key) {
      case "years":
        dateString +=
          value +
          `${value > 1 && value > 0 ? "y" : "y"}${
            existsNextValue ? delimiter : ""
          }`;
        break;
      case "months":
        dateString +=
          value +
          `${value > 1 && value > 0 ? "m" : "m"}${
            existsNextValue ? delimiter : ""
          }`;
        break;
      case "weeks":
        dateString +=
          value +
          `${value > 1 && value > 0 ? "w" : "w"}${
            existsNextValue ? delimiter : ""
          }`;
        break;
      case "days":
        dateString +=
          value +
          `${value > 1 && value > 0 ? "d" : "d"}${
            existsNextValue ? delimiter : ""
          }`;
        break;
      default:
        dateString += "";
    }
  });
  return dateString;
}

export default dateTimeFormaterEdit;
