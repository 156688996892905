import React from "react";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import { Dialog } from "../../../helper";
import { useQuery } from "react-query";
import { usePatient } from "../../../service";
import { format, parseISO } from "date-fns";
import useNotifier from "../../../hooks/useNotifier";

function PatientHealthFeedback(response) {
  const [rows, setRows] = React.useState([]);
  const [visualizationPatientHealth, setVisualizationPatientHealth] =
    React.useState(false);
  const idPatient = response.data.patient.id;
  const idProgram = response.data.program.id;
  const [programsAvailableForPatient, setProgramsAvailableForPatient] =
    React.useState([]);
  const { getPatientsProgramsHistory } = usePatient();
  const notify = useNotifier();
  // requisição

  const programsQuery = useQuery(
    ["patients", [idPatient, idProgram]],
    () => getPatientsProgramsHistory(idPatient, idProgram),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (res) => {
        const programFormatting = res.map((index) => {
          return {
            employee: index.inserted_by_employee.name,
            inserted_at: format(parseISO(index.inserted_at), "dd/MM/yyyy"),
            removed_at: !!index.removed_at
              ? format(parseISO(index.removed_at), "dd/MM/yyyy")
              : "",
          };
        });
        setRows(programFormatting);
      },
    }
  );

  const tableColumns = [
    { name: "Usuário Operação" },
    { name: "Início Vigência" },
    { name: "Fim Vigência" },
  ];

  function handleVisualization() {
    setVisualizationPatientHealth(true);
  }
  function handleCloseDialog() {
    setVisualizationPatientHealth(false);
  }

  return (
    <>
      <Dialog
        fullWidth
        open={visualizationPatientHealth}
        title={"Log Paciente Programa Saúde"}
        handleClose={handleCloseDialog}
      >
        <PatientHealthFeedback data={programsAvailableForPatient} />
      </Dialog>
      <PaginatedTable
        data={rows}
        columns={tableColumns}
        loading={programsQuery.isRefetching}
        shouldDisableFields={["id"]}
      />
    </>
  );
}

export default PatientHealthFeedback;
