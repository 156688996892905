import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/health-insurances";

function useHealthInsurance() {
  async function getHealthInsurances(params) {
    try {
      const response = await instance.get(path, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getHealthInsurance(id) {
    try {
      const response = await instance.get(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postHealthInsurance(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updateHealthInsurance({ id, data }) {
    try {
      const response = await instance.put(`${path}/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getHealthInsurances,
    getHealthInsurance,
    postHealthInsurance,
    updateHealthInsurance,
  };
}
export default useHealthInsurance;
