import React from "react";
import { usePagination, usePreillness } from "../../../service";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DialogMedium } from "../../../helper";
import { useTheme } from "@emotion/react";
import Yup from "../../../config/yup";
import { Box, Button, Grid, TablePagination, Typography } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import { TextField, SwitchField, SelectField } from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

export default function PreillnessStep() {
  const notify = useNotifier();
  const theme = useTheme();

  const { getPreillness, postPreillness, updatePreillness } = usePreillness();

  const createPreillnessMutation = useMutation(postPreillness);
  const updatePreillnessMutation = useMutation(updatePreillness);

  const [edit, setEdit] = React.useState(false);
  const [preillness, setPreillness] = React.useState({});
  const [limit, setLimit] = React.useState(5);

  const columns = [
    {
      field: "number",
      name: "Código",
      type: "string",
    },
    {
      field: "describe",
      name: "Descrição",
      type: "string",
    },
    {
      field: "gender",
      name: "Gênero",
      type: "string",
    },
    {
      field: "required",
      name: "Obrigatório",
      type: "string",
      use: (value) => (value ? "Sim" : "Não"),
    },
    {
      field: "active",
      name: "Status",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
    },
  ];

  const genderOptions = [
    {
      value: "Masculino"
    },
    {
      value: "Feminino"
    },
    {
      value: "Ambos"
    },
  ]

  const preillnessQuery = useQuery(["preillness"],() => getPreillness(), {
    retry: false,
    initialData: [],
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  const { paginationInfo, handlePageChange } = usePagination(
    preillnessQuery.data,
    limit
  );

  const validator = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      describe: "",
      required: false,
      gender: {value:"Ambos"}
    },
    resolver: yupResolver(validator),
  });

  const editPreillnessForm = useForm({
    defaultValues: {
      describe: preillness?.describe,
      gender: {value: preillness?.gender},
      required: preillness?.required,
      active: preillness?.active,
    },
    resolver: yupResolver(validator),
  });

  const handleCloseDialog = () => {
    setEdit(false);
    setPreillness({});
  };

  const onSubmit = handleSubmit(({describe,required,gender}) => {
    createPreillnessMutation.mutate({describe,required,gender: gender.value}, {
      onSuccess: (data) => {
        notify(data.message, "success");
        reset();
        preillnessQuery.refetch();
      },
      onError: (err) => {
        notify(err.message, "error");
      },
    });
  });

  const onEditSubmit = editPreillnessForm.handleSubmit((values) => {
    updatePreillnessMutation.mutate(
      { id: preillness.id, data: {...values,gender: values.gender.value} },
      {
        onSuccess: (data) => {
          notify(data.message, "success");
          reset();
          setEdit(false);
          setPreillness({});
          preillnessQuery.refetch();
        },
        onError: (err) => {
          notify(err.message, "error");
        },
      }
    );
  });

  return (
    <>
      <DialogMedium
        open={edit}
        fullWidth
        maxWidth="sm"
        title="Edição de Comorbidades"
        handleClose={handleCloseDialog}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              control={editPreillnessForm.control}
              name="describe"
              label="Descrição"
              required
            />
          </Grid>
          <Grid item xs={12}>
          <SelectField
            control={editPreillnessForm.control}
            name={"gender"}
            label={"Gênero"}
            required
            options={genderOptions}
            optionLabelKey="value"
            optionCompareKey={"value"}
          />
        </Grid>
          <Grid item xs={3}>
            <SwitchField
              control={editPreillnessForm.control}
              name="required"
              label="Alterar Obrigatório"
            />
          </Grid>
          <Grid item xs={3}>
            <SwitchField
              control={editPreillnessForm.control}
              name="active"
              label="Alterar Status"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={onEditSubmit}
              onLoad={updatePreillnessMutation.isLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Os itens configurados nesta serão exibidos no Subjetivo do atendimento
          SOAP
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items}
          columns={columns}
          loading={preillnessQuery.isLoading}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit"]}
          shouldDisableFields={["id"]}
          actionHandleEdit={(_, item) => {
            setPreillness(item);
            editPreillnessForm.reset({
              describe: item.describe,
              active: item.active,
              required: item.required,
              gender: {value: item.gender}
            });
            setEdit(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Comorbidades
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            control={control}
            name={"describe"}
            label={"Descrição"}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <SelectField
            control={control}
            name={"gender"}
            label={"Gênero"}
            required
            options={genderOptions}
            optionLabelKey="value"
          />
        </Grid>
        <Grid item xs={2}>
          <SwitchField
            control={control}
            name="required"
            label="Obrigatório ?"
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={onSubmit}
            onLoad={createPreillnessMutation.isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
