import { differenceInMinutes, isToday, parseISO } from "date-fns";

export default function verifyTwentyFourHoursCompany(date) {
  const userData = localStorage.getItem("user-data");
  
  if (userData) {
    const parsedUserData = JSON.parse(userData);

    if (parsedUserData.company.type.TWENTY_FOUR_HOURS) {
      let betweenHours = differenceInMinutes(
        new Date(),
        parseISO(date)
      );
      return betweenHours > 24 * 60;
    }
  }

  return !isToday(parseISO(date));
}
