import React from "react";
import { useField, useFormikContext } from "formik";
import { Autocomplete, TextField } from "@mui/material";

function FormMultipleSelectField({
  name,
  label,
  options,
  keys,
  customHandleChange,
  disabled,
  shouldCleanInput,
  required,
  ...props
}) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const settings = {
    ...field,
    label,
    ...props,
    name,
  };

  if (meta && meta.touched && meta.error) {
    settings.error = true;
    settings.helperText = meta.error;
  }

  return (
    <Autocomplete
      {...settings}
      multiple
      value={shouldCleanInput ? [] : field.value}
      options={options}
      getOptionLabel={(option) => option[keys[1]]}
      onChange={(e, value) => {
        if (customHandleChange instanceof Function) {
          customHandleChange(field.value, value);
        }
        setFieldValue(field.name, value);
      }}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id ? value : ""
      }
      onOpen={field.onBlur}
      disabled={disabled}
      size="small"
      renderInput={(params) => (
        <TextField
          error={settings?.error}
          helperText={settings?.helperText}
          label={label}
          disabled={disabled}
          required={required}
          {...params}
          InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
        />
      )}
    />
  );
}
export default FormMultipleSelectField;
