import { cleanerString } from "../helper";

const numberRegex = /^\d+$/;
const cpfRegex = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/;

export default function searchByPatientIdentifiers(value, filters, filterKeys) {
  if ((numberRegex.test(value) && value.length <= 11) || cpfRegex.test(value)) {
    filters[filterKeys[0]] = cleanerString(value, "", [".", "-"]);
  } else if (numberRegex.test(value) && value.length > 11) {
    filters[filterKeys[1]] = value;
  } else {
    filters[filterKeys[2]] = value;
  }
}
