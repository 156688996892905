import { Box, Button, TablePagination } from "@mui/material";
import { BoxContainer } from "../../../components";
import TableFilter from "../../../components/Table/TableFilter";
import { useState } from "react";
import { DialogMedium } from "../../../helper";
import BillingForm from "./Form";
import usePermissions from "../../../config/usePermissions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useBilling from "../../../service/useBilling";
import { format, parseISO } from "date-fns";
import { useNotifier } from "../../../hooks";
import useRPA from "../../../service/useRPA";

export default function Billing() {
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({});
  const [openForm, setOpenForm] = useState();
  const [billingToEdit, setBillingToEdit] = useState(false);
  const permissions = usePermissions("/attendance/billing");
  const queryClient = useQueryClient();

  const { deleteBilling } = useBilling();
  const { getRPA } = useRPA();

  const deleteMutation = useMutation({
    mutationFn: deleteBilling,
    mutationKey: "deleteBilling",
  });

  const columns = [
    {
      name: "Data",
      field: "date",
      use: (date) => (date ? format(parseISO(date), "dd/MM/yyyy") : ""),
    },
    {
      name: "Hora",
      field: "hour",
    },
    {
      name: "Paciente",
      field: "patient?.name",
      filter: "patient_name",
      type: "text",
    },
    {
      name: "Profissional / Vinculo",
      use: (_, billing) =>
        `${billing?.employee.name ? billing?.employee.name : "--"} / ${
          billing?.specialty?.describe ? billing?.specialty?.describe : "--"
        }`,
    },
    {
      name: "Tipo de Atendimento",
      field: "attendance_type?.describe",
      filter: "attendance_type_describe",
      type: "text",
    },
    {
      name: "Cd. Procedimento",
      field: "procedure?.tuss_code",
      filter: "tuss_code",
      type: "text",
    },
    {
      name: "CID",
      field: "main_cid",
    },
  ];

  const tablefilters = [
    {
      name: "Prestador",
      type: "text",
      filter: "employee_name",
    },
    {
      name: "Especialidade",
      type: "text",
      filter: "specialty_describe",
    },
    {
      name: "CNS Prestador",
      type: "text",
      filter: "employee_cns",
    },
    {
      name: "CNS Paciente",
      type: "text",
      filter: "patient_cns",
    },
    {
      name: "CPF Paciente",
      type: "text",
      filter: "patient_cpf",
    },
    {
      name: "Data inicial",
      type: "date",
      field: "initial_date",
    },
    {
      name: "Data final",
      type: "date",
      field: "final_date",
    },
  ];

  const billingQuery = useQuery(
    ["rpa-data", limit, page, filters],
    () => getRPA({ page, limit, ...filters, integration_system: false }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      onError(data) {
        queryClient.setQueryData(["rpa-data", limit, page, filters], {
          items: [],
          totalItems: 0,
        });
        notify(data.message, "error");
      },
    }
  );

  function handleDelete(id) {
    deleteMutation.mutate(id, {
      onSuccess(data) {
        notify(data.message);
        billingQuery.refetch();
      },
      onError(data) {
        notify(data.message, "error");
      },
    });
  }

  function onCloseForm() {
    setOpenForm(false);
    setBillingToEdit(undefined);
    billingQuery.refetch();
  }
  return (
    <>
      <DialogMedium
        maxWidth={"xl"}
        open={!!openForm}
        handleClose={onCloseForm}
        title={"Adicionar Atendimento"}
      >
        <BillingForm closeForm={onCloseForm} rpaId={billingToEdit} />
      </DialogMedium>
      <BoxContainer title={"Registro de faturamento"}>
        <TableFilter
          columns={columns}
          actions
          actionsTypes={["edit", "delete"]}
          actionUniqueIdentifier={"id"}
          disableActions={(row) => {
            return row.invoiced;
          }}
          data={!billingQuery.isError ? billingQuery.data?.items : []}
          othersFilter={tablefilters}
          handleFilter={setFilters}
          emptyMessage={billingQuery.error?.message}
          actionHandleEdit={(id) => {
            setBillingToEdit(id);
            setOpenForm(true);
          }}
          actionHandleDelete={handleDelete}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={billingQuery.data?.totalItems || 0}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 40, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpenForm(true)}
          disabled={!permissions.create}
        >
          Novo Cadastro
        </Button>
      </BoxContainer>
    </>
  );
}
