import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

export function useCompany() {
  const path = "companies";

  async function getCompanies(params = {}) {
    try {
      const result = instance.get(path, { params: urlQuery(params) });
      return (await result).data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getCompanyByID(id) {
    try {
      const response = await instance.get(path + "/" + id);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
  return {
    getCompanies,
    getCompanyByID,
  };
}
