import { Box, Typography } from "@mui/material";
import { isArray } from "lodash";

export default function RPAFeedback({ data = {} }) {
  const { notes, confirmed } = data;
  function toArray() {
    try {
      return JSON.parse(notes?.replace(/'/g, '"')).flatMap((arr) =>
        isArray(arr[0]) ? arr : [arr]
      );
    } catch (error) {}
  }
  return (
    <Box>
      {confirmed ? (
        toArray()?.map((i) => (
          <Box>
            <Typography
              variant="p"
              fontSize={16}
              color="secondary"
              fontWeight="500"
            >
              {i[1]}:{" "}
              <Typography
                color="primary"
                component="span"
                fontWeight={500}
                fontSize={16}
              >
                {i[0]}
              </Typography>
            </Typography>
          </Box>
        ))
      ) : (
        <Box>
          <Typography
            variant="p"
            fontSize={16}
            color="secondary"
            fontWeight="500"
          >
            Motivo da Não exportação:{" "}
            <Typography
              color="primary"
              component="span"
              fontWeight={500}
              fontSize={16}
            >
              {notes}
            </Typography>
          </Typography>
        </Box>
      )}
    </Box>
  );
}
