import { Box, Divider, Grid, Typography } from "@mui/material";
import { FormTextField } from "../../../components";
import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";

export default function EndodonticsTab() {
  const { disableFields } = useContext(AppContext);
  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography
          fontWeight={"bold"}
          color={"primary.main"}
          component={"span"}
        >
          Especialidade de Endodontia
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box width={"20%"}>
          <FormTextField
            disabled={disableFields}
            label={"Grampo Utilizado"}
            name={"endodontics.rubber_dam_clamp"}
          />
        </Box>
      </Grid>
      <Grid item xs={4} display={"grid"} rowGap={1}>
        <Typography
          fontWeight={"bolder"}
          color={"primary.main"}
          component={"span"}
        >
          Fase:
        </Typography>
        <FormTextField
          disabled={disableFields}
          label={"Canal"}
          name={"endodontics.canal"}
        />
      </Grid>
      <Grid item xs={8} display={"grid"} rowGap={1}>
        <Typography
          fontWeight={"bolder"}
          color={"primary.main"}
          component={"span"}
        >
          Inicial:
        </Typography>
        <Box display={"flex"} columnGap={2}>
          <FormTextField
            disabled={disableFields}
            label={"Comp. Radiog."}
            name={"endodontics.radiographic_field"}
          />
          <FormTextField
            disabled={disableFields}
            label={"Curvatura"}
            name={"endodontics.curvature"}
          />
        </Box>
      </Grid>
      <Grid item xs={12} display={"grid"} rowGap={1}>
        <Typography
          fontWeight={"bolder"}
          color={"primary.main"}
          component={"span"}
        >
          Odontometria:
        </Typography>
        <Grid item xs={6}>
          <FormTextField
            disabled={disableFields}
            label={"Referência/Lima"}
            name={"endodontics.reference_lima"}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} display={"grid"} rowGap={1}>
        <Typography
          fontWeight={"bolder"}
          color={"primary.main"}
          component={"span"}
        >
          Preparo Químico Mecânico:
        </Typography>
        <Grid item xs={8}>
          <FormTextField
            disabled={disableFields}
            name={"endodontics.composite_work"}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} display={"grid"} rowGap={1}>
        <Typography
          fontWeight={"bolder"}
          color={"primary.main"}
          component={"span"}
        >
          Obturação:
        </Typography>
        <Box display={"flex"} columnGap={2}>
          <FormTextField
            disabled={disableFields}
            label={"Cone Principal"}
            name={"endodontics.master_cone"}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} display={"grid"} rowGap={2}>
        <FormTextField
          disabled={disableFields}
          label={"CIMENTO OBTURADOR:"}
          name={"endodontics.sealer"}
          size="medium"
        />
        <FormTextField
          disabled={disableFields}
          label={"TÉCNICA DE OBTURAÇÃO:"}
          name={"endodontics.obturation_technique"}
          size="medium"
        />
      </Grid>
    </Grid>
  );
}
