import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";
import accessObjectByString from "../../../utils/accessObjectByString";
import { useTheme } from "@emotion/react";

export default function CheckboxField({
  control,
  name,
  label,
  required,
  disabled,
  options = [],
  orientation = "row",
  optionLabelKey = "label",
  optionCompareKey,
  optionValueKey,
  disableOptions,
  customOnChange,
  onBlur,
  onChange,
  maxHeight,
  maxWidth,
  ...props
}) {
  const theme = useTheme();

  const optionIdentifier = useMemo(() => {
    return optionCompareKey || optionLabelKey;
  }, [optionCompareKey, optionLabelKey]);

  const getOptionValue = useCallback(
    (value) => {
      if (optionValueKey) {
        return value;
      }

      return options.find(
        (option) => accessObjectByString(option, optionIdentifier) === value
      );
    },
    [optionValueKey, optionIdentifier, options]
  );

  const handleChange = useCallback(
    (value, field) => {
      let optionsValue = [value];

      if (Array.isArray(field.value)) {
        optionsValue = [...field.value, ...optionsValue];

        const optionToRemove = field.value.find((option) => {
          if (optionValueKey) {
            return value === option;
          }

          return (
            accessObjectByString(option, optionIdentifier) ===
            accessObjectByString(value, optionIdentifier)
          );
        });

        if (optionToRemove) {
          optionsValue = field.value.filter((option) => {
            if (optionValueKey) {
              return value !== option;
            }

            return (
              accessObjectByString(option, optionIdentifier) !==
              accessObjectByString(value, optionIdentifier)
            );
          });
        }
      }

      if (onChange instanceof Function) {
        onChange(optionsValue);
      } else {
        field.onChange(optionsValue);
      }

      if (customOnChange instanceof Function) {
        customOnChange(optionsValue);
      }
    },
    [onChange, customOnChange, optionValueKey, optionIdentifier]
  );

  const verifySelectedOptions = useCallback(
    (item, fieldValue) => {
      return fieldValue?.some((option) => {
        if (optionValueKey) {
          return accessObjectByString(item, optionValueKey) === option;
        }

        return (
          accessObjectByString(option, optionIdentifier) ===
          accessObjectByString(item, optionIdentifier)
        );
      });
    },
    [optionIdentifier, optionValueKey]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormControl
          error={fieldState.invalid}
          disabled={disabled}
          onChange={(event) =>
            handleChange(getOptionValue(event.target.value), field)
          }
          onBlur={() => {
            if (onBlur instanceof Function) {
              onBlur(field.value);
            } else {
              field.onBlur(field.value);
            }
          }}
        >
          {label && (
            <FormLabel
              required={required}
              sx={{
                fontSize: "0.9rem",
                color: theme.palette.primary.light,
              }}
            >
              {label}
            </FormLabel>
          )}
          <FormGroup
            {...field}
            {...props}
            value={
              field.value instanceof Object
                ? accessObjectByString(field.value, optionIdentifier)
                : field.value
            }
            sx={{
              display: "flex",
              flexDirection: orientation,
              flexWrap: "wrap",
              maxHeight: maxHeight || "inherit",
              maxWidth: maxWidth || "inherit",
              "& .MuiFormControlLabel-label": {
                fontSize: "0.9rem",
                color: fieldState.error
                  ? theme.palette.error.main
                  : theme.palette.primary.light,
              },
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={accessObjectByString(option, optionIdentifier)}
                label={accessObjectByString(option, optionLabelKey)}
                sx={{
                  ml: label ? "-11px" : 0,
                  mr: label ? "16px" : 0,
                }}
                control={
                  <Checkbox
                    value={accessObjectByString(
                      option,
                      optionValueKey ?? optionIdentifier
                    )}
                    checked={verifySelectedOptions(option, field.value)}
                    disabled={
                      disableOptions ? disableOptions(option) : disabled
                    }
                  />
                }
              />
            ))}
          </FormGroup>
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
