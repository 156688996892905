import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import {
  FormDate,
  FormSearchSelectField,
  SubmitButton,
} from "../../../components/Form";
import FormSelectFieldWithPaginate from "../../../components/Form/FormSelectWithPaginate";
import Yup from "../../../config/yup";
import { Loading } from "../../../helper";
import {
  useProvider,
  useSector,
  useServices,
  useFilterScale,
} from "../../../service";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ScheduleCardConsultation from "./ScheduleCardConsultation";
import { BoxContainer } from "../../../components";
import { VerifyPermission } from "../../../components/RequireAuth/VerifyPermission";
import usePermissions from "../../../config/usePermissions";
import { useQuery } from "react-query";
import useNotifier from "../../../hooks/useNotifier";

export default function ScheduleConsultation() {
  const [page, setPage] = React.useState(0);
  const [enabledQuery, setEnabledQuery] = React.useState(false);
  const [filters, setFilters] = React.useState({});
  const [schedules, setSchedules] = React.useState([]);
  const [emptyResponse, setEmptyResponse] = React.useState(false);
  const notify = useNotifier();

  // requests
  const { getProviders } = useProvider();
  const { getSectors } = useSector();
  const { getServices } = useServices();
  const { filterScale } = useFilterScale();

  const queryConfig = {
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    initialData: [],
  };

  const querySector = useQuery(["sectors"], getSectors, queryConfig);
  const queryServices = useQuery(["services"], getServices, queryConfig);
  const querySchedules = useQuery(
    ["schedules", page],
    () => filterScale({ ...filters, page }),
    {
      ...queryConfig,
      enabled: enabledQuery,
      initialData: {
        data: { total: 1, items: [] },
      },
      onSuccess: (response) => {
        if (!emptyResponse) {
          const result =
            page === 0
              ? response.data.items
              : [...schedules, ...response.data.items];
          setSchedules(result);
        }
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  //Handle Functions
  async function handleSubmit(values) {
    const valuesToFilter = {
      initial_date: values.initial_date,
      final_date: values.final_date,
      id_sector: values?.sector?.id,
      id_service: values?.service?.id,
      id_employee: values?.employee?.id,
      limit: 10,
    };

    setPage(0);
    setFilters(valuesToFilter);
    setEnabledQuery(true);
  }

  useEffect(() => {
    if (enabledQuery) querySchedules.refetch();
  }, [filters]);

  const scrollEventSchedules = React.useCallback(
    ({ target }) => {
      if (target.scrollTop + target.clientHeight >= target.scrollHeight - 1) {
        if (querySchedules.data.data.current < querySchedules.data.data.total) {
          setPage((page) => ++page);
        } else {
          setEmptyResponse(true);
        }
      }
    },
    [querySchedules.data.data]
  );

  // Form config
  const initialValues = {
    employee: null,
    service: null,
    sector: null,
    initial_date: "",
    final_date: "",
  };

  const validations = Yup.object().shape({
    employee: Yup.object().nullable(),
    service: Yup.object().nullable(),
    sector: Yup.object().nullable(),
    initial_date: Yup.string().required("É Requerido").nullable(),
    final_date: Yup.string().required("É Requerido").nullable(),
  });

  const permissions = usePermissions();
  return (
    <BoxContainer title={"Consulta de Agendas"}>
      <VerifyPermission valid={permissions?.read} />
      <Formik
        initialValues={initialValues}
        validationSchema={validations}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormSelectFieldWithPaginate
                  service={async (params) =>
                    getProviders({
                      ...params,
                      active: true,
                    })
                  }
                  fields="name"
                  name="employee"
                  label="Profissional"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <FormSearchSelectField
                  name="sector"
                  label="Setor"
                  options={querySector.data}
                  getOptionLabel={(sector) => `${sector.describe}`}
                  isOptionEqualToValue={(sector, value) =>
                    value.id === sector.id
                  }
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <FormSearchSelectField
                  name="service"
                  label="Serviço"
                  required
                  options={queryServices.data}
                  getOptionLabel={(service) => `${service.describe} `}
                  isOptionEqualToValue={(service, value) =>
                    value.id === service.id
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormDate name="initial_date" label="Inicio" />
              </Grid>
              <Grid item xs={6}>
                <FormDate name="final_date" label="Fim" required />
              </Grid>
              <Grid item xs={12} alignItems="stretch">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "right",
                    justifyContent: "right",
                    pt: 2,
                  }}
                >
                  <Button
                    onClick={() => {
                      Object.keys(values).forEach((name) => {
                        setFieldValue(name, null);
                      });
                      setSchedules([]);
                      setEnabledQuery(false);
                      setEmptyResponse(false);
                    }}
                    sx={{ mr: 1 }}
                  >
                    Limpar os Dados
                  </Button>
                  <SubmitButton
                    disabled={!values.initial_date && !values.final_date}
                    loading={querySchedules.isFetching}
                    loadingMessage="Buscando"
                  >
                    <SearchOutlinedIcon /> Buscar
                  </SubmitButton>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Grid item xs={12} sx={{ paddingTop: 3 }}>
        <Stack
          onScrollCapture={scrollEventSchedules}
          gap={1}
          width={"100%"}
          maxHeight={"48vh"}
          overflow="auto"
          paddingBottom={3}
          paddingRight={2}
        >
          {!!schedules.length
            ? schedules.map((item, index, array) => {
                if (index === array.length - 1) {
                  return (
                    <ScheduleCardConsultation
                      onFeedbackClose={() => querySchedules.refetch()}
                      key={item.id_scale}
                      item={item}
                    />
                  );
                } else {
                  return (
                    <ScheduleCardConsultation
                      onFeedbackClose={() => querySchedules.refetch()}
                      key={item.id_scale}
                      item={item}
                    />
                  );
                }
              })
            : emptyResponse && (
                <Typography sx={{ textAlign: "center" }} color="primary.light">
                  Não foi possível encontrar a pesquisa solicitada &#x1F614;!
                </Typography>
              )}
          {page === querySchedules.data.data.total &&
            querySchedules.data.data.items.length > 0 &&
            !querySchedules.isFetching && (
              <Typography
                sx={{ display: "flex", justifyContent: "center" }}
                color="primary.light"
              >
                Não há mais agendas neste período!
              </Typography>
            )}
          {querySchedules.isFetching && <Loading sx={{ margin: "auto" }} />}
        </Stack>
      </Grid>
    </BoxContainer>
  );
}
