import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { ReactComponent as Logo } from "../assets/svg/tascomBlue.svg";
function Loading({ message = "Carregando...", maxWidth, backgroundColor }) {
  return (
    <Box
      sx={{
        zIndex: 3000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        margin: "0 auto",

        width: "100%",
        maxWidth: maxWidth ? maxWidth : "max-content",
        backgroundColor: backgroundColor ? backgroundColor : "transparent",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
      }}
    >
      <Box marginTop={1.5} position="absolute">
        <Logo />
      </Box>
      <CircularProgress size={80}></CircularProgress>
      <Box marginTop={20} width={"max-content"} position="absolute">
        <Typography>{message}</Typography>
      </Box>
    </Box>
  );
}

export default Loading;
