import React from "react";
import { BoxContainer, Tabs } from "../../../components";
import RegulationPendence from "./RegulationPendence";
import Regulator from "./Regulator";
import RegulationConfirmed from "./RegulationConfirmed";
import { TITLE_NAME } from "../../../config/config";
import { Helmet } from "react-helmet";

export default function SchedulingRegulation() {
  const tabs = [
    {
      label: "Pendentes",
      content: <RegulationPendence />,
    },
    {
      label: "Lista de Espera",
      content: <Regulator />,
    },
    {
      label: "Agendados",
      content: <RegulationConfirmed />,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Regulação</title>
      </Helmet>
      <BoxContainer title={"Regulação"}>
        <Tabs tabs={tabs} />
      </BoxContainer>
    </>
  );
}