import React from "react";
import { Box } from "@mui/material";
import AllowImportSchedule from "./SettingsProfileStep/AllowImportSchedule";
import SpecialtyProfileStep from "./SettingsProfileStep/SpecialtyProfileStep";
import ProfissionalTypesStep from "./SettingsProfileStep/ProfissionalTypesStep";
import { Tabs } from "../../components";

export default function SettingsProfissional() {
  const tabList = [
    {
      label: "Tipos de Profissionais",
      content: <ProfissionalTypesStep />,
    },
    {
      label: "Especialidade",
      content: <SpecialtyProfileStep />,
    },
    {
      label: "Automação: Importação de agendas",
      content: <AllowImportSchedule />,
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs tabs={tabList} />
      </Box>
    </>
  );
}
