export default function Badge({ content, position }) {
  return (
    <svg
      x={position?.cx}
      y={position?.cy}
      width="100"
      height="100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="10" fill="white" stroke="#73DB42" />

      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="#73DB42"
        fontSize="10"
      >
        {content}
      </text>
    </svg>
  );
}
