import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { BoxContainer, Tabs } from "../../../components";
import { usePresets } from "../../../service";
import { TablePagination, Box, Button } from "@mui/material";
import { Dialog } from "../../../helper";
import usePermissions from "../../../config/usePermissions";
import TableFilter from "../../../components/Table/TableFilter";
import { format, parseISO } from "date-fns";
import DrugsPreset from "../DrugsPreset/DrugsPreset";
import ExamsPreset from "../ExamsPreset/ExamsPreset";
import useNotifier from "../../../hooks/useNotifier";

function PresetsList() {
  const permission = usePermissions("/presets");
  const notify = useNotifier();
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [register, setRegister] = useState(false);
  const [edit, setEdit] = useState(false);
  const [filters, setFilters] = useState({ type: "medications_on_site" });
  const [preset, setPreset] = useState();

  const queryClient = useQueryClient();
  const { getPresets, deletePreset } = usePresets();

  const deletePresetMutation = useMutation(deletePreset);

  const presetsQuery = useQuery(
    ["presets", page, limit, filters],
    () => getPresets({ page, limit, ...filters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: {
        items: [],
        total: 0,
        totalItems: 0,
      },
      onError() {
        queryClient.setQueriesData("presets", {
          items: [],
          total: 0,
          totalItems: 0,
        });
      },
    }
  );

  function handleFilter(filters) {
    queryClient.cancelQueries("presets");
    setPage(0);
    setFilters(filters);
  }

  function handleDelete(_, row) {
    deletePresetMutation.mutate(row.id, {
      onSuccess(response) {
        notify(response.message, "success");
        queryClient.invalidateQueries("presets");
      },
      onError(error) {
        notify(error.message, "error");
      },
    });
  }

  const presets = {
    medications_on_site: {
      title: "Medicamentos",
      component: (
        <DrugsPreset
          type={edit ? "edit" : "register"}
          presetType="medications_on_site"
          data={preset}
        />
      ),
    },
    medications: {
      title: "Receituários",
      component: (
        <DrugsPreset
          type={edit ? "edit" : "register"}
          presetType="medications"
          data={preset}
        />
      ),
    },
    exams: {
      title: "Exames",
      component: (
        <ExamsPreset type={edit ? "edit" : "register"} data={preset} />
      ),
    },
  };

  const tableColumns = [
    {
      name: "Tipo",
      field: "type",
      use: (type) => presets[type].title,
    },
    {
      name: "Nome",
      field: "describe",
      type: "text",
    },
    {
      name: "Criado em",
      field: "createdAt",
      type: "date",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
    {
      name: "Atualizado em",
      field: "updatedAt",
      type: "date",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
  ];

  const tabs = [
    { label: "Medicamentos", value: "medications_on_site" },
    { label: "Receituários", value: "medications" },
    { label: "Exames", value: "exams" },
  ];

  return (
    <BoxContainer title="Predefinições">
      <Dialog
        open={register}
        title={`Cadastro de Pré Definição de ${presets[filters.type].title}`}
        handleClose={() => setRegister(false)}
      >
        {presets[filters.type].component}
      </Dialog>
      <Dialog
        open={edit}
        title={`Edição de Pré Definição de ${presets[filters.type].title}`}
        handleClose={() => setEdit(false)}
      >
        {presets[filters.type].component}
      </Dialog>
      <Tabs
        tabs={tabs}
        handleClick={(tab) =>
          setFilters((filters) => ({ ...filters, type: tab.value }))
        }
      />
      <TableFilter
        data={presetsQuery.data?.items}
        actions
        emptyMessage={presetsQuery.error?.message}
        columns={tableColumns}
        handleFilter={handleFilter}
        loading={presetsQuery.isFetching && presetsQuery.isLoading}
        actionsTypes={["edit", "delete"]}
        disableActions={(_, action) =>
          (action === "edit" && !permission.update) ||
          (action === "delete" && !permission.delete)
        }
        actionHandleEdit={(_, row) => {
          setPreset(row);
          setEdit(true);
        }}
        actionHandleDelete={handleDelete}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={presetsQuery.data.totalItems}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimit(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Box>
      <Button
        variant="contained"
        color="secondary"
        disabled={!permission.create}
        onClick={() => setRegister(true)}
      >
        Novo Cadastro
      </Button>
    </BoxContainer>
  );
}
export default PresetsList;
