import { format } from "date-fns";
import { searchByPatientIdentifiers } from "../utils";

export const mountFilters = ({
  patient,
  date,
  employeeOrSector,
  services,
  finished,
  notScheduled,
  regulated,
  slim_mode,
  in_reevaluation,
  ...others
}) => {
  let attendanceFilter = {};
  
  if (patient) {
    searchByPatientIdentifiers(patient, attendanceFilter, [
      "patient_cpf",
      "patient_sus_card",
      "patient_name",
    ]);
  }

  if (employeeOrSector) {
    if (employeeOrSector.name) {
      attendanceFilter.employee_number = employeeOrSector.number;
    } else {
      attendanceFilter.sector_number = employeeOrSector.number;
    }
  }
  return {
    filter: date ? format(new Date(date), "yyyy-MM-dd") : undefined,
    status: finished ? "unfinished" : undefined,
    type: notScheduled ? "unscheduled" : "scheduled",
    order_by: notScheduled ? "" : "scheduling",
    regulated: regulated || undefined,
    in_reevaluation: in_reevaluation || undefined,
    service_id: services.map((service) => service.id),
    ...attendanceFilter,
    ...others,
  };
};
