import React from "react";
import { Box, Grid, IconButton, Typography, Tooltip } from "@mui/material";
import {
  FormDate,
  FormSearchSelectField,
  SubmitButton,
} from "../../components/Form";
import { theme } from "../../config/theme";
import { Form, Formik } from "formik";
import { Loading } from "../../helper";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Yup from "../../config/yup";
import { useServices, usePreillness, useCockpit } from "../../service";
import { parseISO, format, startOfMonth } from "date-fns";
import { useQuery, useQueryClient, useMutation } from "react-query";
import useNotifier from "../../hooks/useNotifier";

const maxListNumber = 8;

function CockPitConfig({ setDialog }) {
  const queryClient = useQueryClient();
  const { getServices } = React.useMemo(useServices, []);
  const { getPreillness } = React.useMemo(usePreillness, []);
  const { getConfigCockpit, postCockpitConfig, putCockpitConfig } =
    React.useMemo(useCockpit, []);
  const [services, setServices] = React.useState([]);
  const [serviceArray, setServiceArray] = React.useState([]);
  const [illnessArray, setIllnessArray] = React.useState([]);
  const [preExistIllness, setPreExistIllness] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    initialDate: startOfMonth(new Date()),
    finalDate: new Date(),
    services: null,
    preExistIllness: null,
  });
  const [requestMethod, setRequestMethod] = React.useState("post");
  const notify = useNotifier();
  // Requests
  const queryService = useQuery("services", () => getServices(), {
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onSuccess: (response) => {
      setServices(response);
    },
    onError: (error) => {
      setServices([]);
      notify(error.message, "error");
    },
  });

  const queryIllness = useQuery("preIllnesses", () => getPreillness(), {
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onSuccess: (response) => {
      setPreExistIllness(response);
    },
    onError: (error) => {
      setPreExistIllness([]);
      notify(error.message, "error");
    },
  });

  const queryConfigCockpit = useQuery(
    "configCockpit",
    () => getConfigCockpit(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: ({ initialDate, finalDate, services, illness }) => {
        initialDate = parseISO(initialDate);
        finalDate = parseISO(finalDate);
        setIllnessArray(illness);
        setServiceArray(services);
        setInitialValues((initial) => ({ ...initial, initialDate, finalDate }));
        if (!!illness && !!services) {
          setRequestMethod("put");
        }
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  // Yup Validation
  const validator = Yup.object().shape({
    initialDate: Yup.date()
      .required("É requerido")
      .min(new Date("01/01/1900"), "O ano deve ser posterior ou igual a 1900")
      .typeError("Data Inválida"),
    finalDate: Yup.date()
      .required("É requerido")
      .max(new Date(), "A data final não pode ser maior que o dia de hoje")
      .typeError("Data Inválida"),
    services: Yup.object().nullable(),
    preExistIllness: Yup.object().nullable(),
  });

  // Add service into list
  function handleService(
    { initialDate, finalDate, services, preExistIllness },
    resetForm
  ) {
    setServiceArray((service) => [...service, services]);
    resetForm({
      values: { initialDate, finalDate, services: null, preExistIllness },
    });
  }

  // Add preIllness into list
  function handlePreIllness(
    { initialDate, finalDate, services, preExistIllness },
    resetForm
  ) {
    setIllnessArray((illness) => [...illness, preExistIllness]);
    resetForm({
      values: { initialDate, finalDate, services, preExistIllness: null },
    });
  }

  // Remove service of the list
  function handleDeleteService(i) {
    const serviceDel = serviceArray.filter((_, index) => index !== i);
    setServiceArray(serviceDel);
  }

  // Remove preIllness of the list
  function handleDeleteIllness(i) {
    const illnessDel = illnessArray.filter((_, index) => index !== i);
    setIllnessArray(illnessDel);
  }

  const postConfigMutation = useMutation(postCockpitConfig);
  const putConfigMutation = useMutation(putCockpitConfig);

  async function handleSubmit(data, { resetForm }) {
    const serviceArrayIds = serviceArray.map((service) => service.id);
    const illnessArrayIds = illnessArray.map((illness) => illness.id);
    const configObject = {
      services_ids: serviceArrayIds.length > 0 ? serviceArrayIds : null,
      preexistillness_ids: illnessArrayIds.length > 0 ? illnessArrayIds : null,
      initial_date: format(new Date(data.initialDate), "yyyy-MM-dd"),
      final_date: format(new Date(data.finalDate), "yyyy-MM-dd"),
    };
    if (requestMethod === "post") {
      postConfigMutation.mutate(configObject, {
        onSuccess: () => {
          queryClient.invalidateQueries("cockpit");
          notify("Painel configurado com sucesso!", "error");
          setDialog(false);
          resetForm();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      });
    } else {
      putConfigMutation.mutate(configObject, {
        onSuccess: () => {
          queryClient.invalidateQueries("cockpit");
          notify("Painel configurado com sucesso!", "success");
          setDialog(false);
          resetForm();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      });
    }
  }

  // Filter for options of the autocomplete
  function serviceFilter(options, service) {
    if (service.length > 0) {
      const filteredOptions = options.filter(({ id }) => {
        const notAddedService = !service.some((serv) => serv.id === id);
        return notAddedService;
      });

      return filteredOptions;
    } else {
      return options || [];
    }
  }

  function illnessFilter(options, illness) {
    if (illness.length > 0) {
      const filteredOptions = options.filter(({ id }) => {
        const notAddedIllness = !illness.some((illness) => illness.id === id);
        return notAddedIllness;
      });

      return filteredOptions;
    } else {
      return options || [];
    }
  }

  // Styled Components
  const AddButton = ({ handleClick, ...props }) => {
    return (
      <Tooltip title="Adicionar">
        <SubmitButton handleClick={handleClick} sx={{ height: 37 }} {...props}>
          <AddRoundedIcon color="#FFF" fontSize="large" />
        </SubmitButton>
      </Tooltip>
    );
  };

  const RemoveButton = ({ title, handleClick }) => {
    return (
      <Tooltip title={title}>
        <IconButton onClick={handleClick}>
          <CloseRoundedIcon sx={{ color: "#DB7369" }} />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <>
      {!queryService.isLoading ||
      !queryIllness.isLoading ||
      !queryConfigCockpit.isLoading ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, resetForm }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box my={1}>
                      <Typography
                        fontSize="19px"
                        fontWeight={500}
                        color={theme.palette.secondary.main}
                      >
                        Período
                      </Typography>
                      <Box
                        display="flex"
                        mt={1.5}
                        gap={1.5}
                        alignItems="center"
                        maxWidth={400}
                      >
                        <FormDate
                          name="initialDate"
                          label="Início"
                          maxDate={new Date()}
                        />
                        <FormDate
                          name="finalDate"
                          label="Fim"
                          maxDate={new Date()}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={5.8}
                    sx={{
                      borderRight: 1,
                      borderColor: theme.palette.primary.main,
                      mr: 1.5,
                    }}
                  >
                    <Typography
                      fontSize="19px"
                      fontWeight={500}
                      color={theme.palette.secondary.main}
                    >
                      Serviços:
                    </Typography>
                    <Grid
                      container
                      mt={2}
                      gap={2}
                      justifyItems="center"
                      alignItems="center"
                    >
                      <Grid item xs={9}>
                        <FormSearchSelectField
                          name="services"
                          disabled={serviceArray.length === maxListNumber}
                          label="Adicione um serviço"
                          options={serviceFilter(services, serviceArray)}
                          getOptionLabel={(services) => services.describe}
                          isOptionEqualToValue={(service, value) =>
                            service.id === value.id
                          }
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <AddButton
                          disabled={
                            serviceArray.length === maxListNumber ||
                            !values.services
                          }
                          handleClick={() => {
                            handleService(values, resetForm);
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          variant="span"
                          fontSize="14px"
                          fontWeight={400}
                          color={
                            serviceArray?.length >= maxListNumber
                              ? "#DB7369"
                              : theme.palette.secondary.main
                          }
                        >
                          {serviceArray?.length}/{maxListNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box my={1} pr={2}>
                      {serviceArray?.map((services, i) => (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          borderBottom={1}
                          borderColor={theme.palette.primary.main}
                          key={services + "-" + i}
                        >
                          <Typography
                            color={theme.palette.primary.light}
                            mt={1}
                            fontSize={14}
                          >
                            {services.describe}
                          </Typography>
                          <RemoveButton
                            title="Remover Serviço"
                            handleClick={() => handleDeleteService(i)}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={5.8}>
                    <Typography
                      fontSize="19px"
                      fontWeight={500}
                      color={theme.palette.secondary.main}
                    >
                      Comorbidades:
                    </Typography>
                    <Grid
                      container
                      mt={2}
                      gap={2}
                      justifyItems="center"
                      alignItems="center"
                    >
                      <Grid item xs={9}>
                        <FormSearchSelectField
                          name="preExistIllness"
                          disabled={illnessArray.length === maxListNumber}
                          label="Adicione uma comorbidade"
                          options={illnessFilter(preExistIllness, illnessArray)}
                          getOptionLabel={(preExistIllness) =>
                            preExistIllness.describe
                          }
                          isOptionEqualToValue={(preExistIllness, value) =>
                            preExistIllness.id === value.id
                          }
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <AddButton
                          disabled={
                            illnessArray.length === maxListNumber ||
                            !values.preExistIllness
                          }
                          handleClick={() =>
                            handlePreIllness(values, resetForm)
                          }
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          variant="span"
                          fontSize="14px"
                          fontWeight={400}
                          color={
                            illnessArray?.length >= maxListNumber
                              ? "#DB7369"
                              : theme.palette.secondary.main
                          }
                        >
                          {illnessArray?.length}/{maxListNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box my={1} pr={2}>
                      {illnessArray?.map((illnesses, i) => (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          borderBottom={1}
                          borderColor={theme.palette.primary.main}
                          key={illnesses + "-" + i}
                        >
                          <Typography
                            color={theme.palette.primary.light}
                            mt={1}
                            fontSize={14}
                          >
                            {illnesses.describe}
                          </Typography>
                          <RemoveButton
                            title="Remover Comorbidade"
                            handleClick={() => handleDeleteIllness(i)}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={11.6}
                    mt={2}
                    display="flex"
                    justifyContent="end"
                  >
                    <SubmitButton>Salvar Configurações</SubmitButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default CockPitConfig;
