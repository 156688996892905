import React from "react";
import { Grid, Typography, Box, TablePagination, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { DialogMedium } from "../../helper";
import { useMutation, useQuery } from "react-query";
import { usePagination, useStation } from "../../service";
import { format, parseISO } from "date-fns";
import Yup from "../../config/yup";
import TableFilter from "../../components/Table/TableFilter";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { TextField, SwitchField } from "../../components/FormFields";
import { useNotifier } from "../../hooks";

export default function SettingsStation() {
  const theme = useTheme();
  const notify = useNotifier();
  const [edit, setEdit] = React.useState(false);
  const [limit, setLimit] = React.useState(5);
  const [station, setStation] = React.useState({});
  const { getStations, postStation, updateStation, deleteStation } =
    useStation();

  const stationMutation = useMutation(postStation);
  const updateStationMutation = useMutation(updateStation);
  const deleteStationMutation = useMutation(deleteStation);

  const stationQuery = useQuery(["stations"], () => getStations(), {
    retry: false,
    initialData: [],
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  const { paginationInfo, handlePageChange } = usePagination(
    stationQuery.data,
    limit
  );

  const columns = [
    {
      field: "name",
      name: "Nome",
      type: "string",
    },
    {
      field: "active",
      name: "Ativo",
      type: "string",
    },
    {
      field: "ip",
      name: "Ip da estação",
      type: "string",
    },
    {
      field: "createdAt",
      name: "Criado em",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado",
      type: "date",
    },
    {
      field: "updatedAt",
      name: "Atualizado em",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado",
      type: "date",
    },
  ];

  const initialValues = {
    name: "",
    station_ip: "",
  };

  const editInitialValues = {
    name: station.name,
    active: station.active,
    station_ip: station.ip,
  };

  const editValidations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
    active: Yup.boolean().required("É requerido"),
  });

  const updateStationForm = useForm({
    defaultValues: editInitialValues,
    resolver: yupResolver(editValidations),
  });

  const validations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validations),
  });

  const onSubmit = handleSubmit((values) => {
    stationMutation.mutate(values, {
      onSuccess: (response) => {
        notify(response.message, "success");
        reset();
        stationQuery.refetch();
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    });
  });

  const handleEditSubmit = updateStationForm.handleSubmit((values) => {
    updateStationMutation.mutate(
      { id: station.id, data: values },
      {
        onSuccess: (response) => {
          notify(response.message, "success");
          stationQuery.refetch();
          handleClose();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  });

  const handleDelete = (id) => {
    deleteStationMutation.mutate(id, {
      onSuccess: (success) => {
        notify(success.message, "success");
        stationQuery.refetch();
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    });
  };

  function handleClose() {
    setEdit(false);
  }

  return (
    <>
      <DialogMedium
        open={edit}
        fullWidth
        maxWidth="sm"
        title="Edição de estação"
        handleClose={handleClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              control={updateStationForm.control}
              name="name"
              label="Nome"
              required
            />
          </Grid>
          <Grid item xs={4}>
            <SwitchField
              control={updateStationForm.control}
              name="active"
              label="Alterar Status"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              control={updateStationForm.control}
              name="station_ip"
              label="IP"
              required
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              onClick={handleEditSubmit}
              loading={false}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
      <Box>
        <TableFilter
          data={paginationInfo.items}
          columns={columns}
          loading={stationQuery.isLoading}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit", "delete"]}
          shouldDisableFields={["id"]}
          actionHandleEdit={(_, item) => {
            setStation(item);
            updateStationForm.setValue("name", item.name);
            updateStationForm.setValue("active", item.active);
            updateStationForm.setValue("station_ip", item.ip);
            setEdit(true);
          }}
          actionHandleDelete={handleDelete}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>

      <Grid container spacing={2} paddingX={1}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Estação
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField control={control} name="name" label="Nome" required />
        </Grid>
        <Grid item xs={4}>
          <TextField control={control} name="station_ip" label="IP" required />
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={onSubmit} loading={false}>
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
