import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

const path = "/screens/";

function useScreens() {
  async function getScreens(params = { limit: 5, page: 0 }) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });

      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getScreens,
  };
}

export default useScreens;
