import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

const path = "/attendances";
const path2 = `/attendances?status=finished`;

function useAttendaces() {
  async function getAttendances(params) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getAttendanceById(id) {
    try {
      const response = await instance.get(`${path}/${id}`);
      return response.data;
    } catch (err) {
      return err;
    }
  }

  async function postCallSystem(data) {
    try {
      const response = await instance.post(path2, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postAttendaces(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function finishAttendance(attendanceId) {
    try {
      const response = await instance.patch(`${path}/${attendanceId}/finish`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function deleteAttendance(data) {
    try {
      const response = await instance.post(`${path}/delete`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function transferAttendance({ attendanceId, data }) {
    try {
      const response = await instance.patch(
        `${path}/${attendanceId}/change-employee`,
        data
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  const createTypeAttendance = async (data) => {
    return instance.post("/treatments/type", data).then((res) => res.data);
  };

  const updateTypeAttendance = async ({ id, data }) => {
    return instance
      .put(`/treatments/type/update/${id}`, data)
      .then((res) => res.data);
  };

  async function getPreloadsByAttendance(id, params) {
    try {
      const response = await instance.get(`${path}/${id}/preloads`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getAttendances,
    getAttendanceById,
    postAttendaces,
    postCallSystem,
    finishAttendance,
    deleteAttendance,
    transferAttendance,
    createTypeAttendance,
    updateTypeAttendance,
    getPreloadsByAttendance,
  };
}
export default useAttendaces;
