import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { useFormikContext } from "formik";

const SubmitButton = ({
  children,
  loading,
  loadingMessage,
  fullWidth,
  handleClick = false,
  ...props
}) => {
  const { submitForm } = useFormikContext();
  const handleSubmit = () => {
    submitForm();
  };
  const settings = {
    variant: "contained",
    fullWidth: fullWidth,
    color: "secondary",
    onClick: handleClick || handleSubmit,
    disabled: loading,
    sx: {
      bgColor: loading && "red",
      color: !loading ? "white" : "black",
    },
    ...props,
  };
  return (
    <Button {...settings}>
      {loading ? (
        <>
          <CircularProgress color="primary" size={20} sx={{ mr: 1 }} />{" "}
          {loadingMessage}
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default SubmitButton;
