import React from "react";
import { BoxContainer } from "../../components";
import Yup from "../../config/yup";
import { Grid, Box, Typography, Button } from "@mui/material";
import {
  genders,
  countries,
  race,
  states,
  ufs2,
} from "../../config/standardSelects";
import { useCep } from "../../service";
import { useProvider } from "../../service";
import { useCouncils } from "../../service";
import ProviderFeedback from "../../helper/ProviderFeedback";
import { format, parseISO } from "date-fns";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../helper";
import {
  TextField,
  DateField,
  SelectField,
  AutocompleteField,
  SwitchField,
} from "../../components/FormFields";
import { useForm } from "react-hook-form";
import RegexOf from "../../config/regex";
import { useMutation, useQuery } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleanUpMask, formatWithMask } from "../../config/mask";
import useNotifier from "../../hooks/useNotifier";

function EmployeeForm() {
  const navigate = useNavigate();
  const {
    getSpecialtiesActive,
    getTypes,
    postProvider,
    getProvider,
    updateProvider,
  } = useProvider();
  const { getCouncils } = useCouncils();
  const { fetchCep } = useCep();
  const [specialties, setSpecialties] = React.useState([]);
  const [employeeTypes, setEmployeeTypes] = React.useState([]);
  const [dataEmployee, setDataEmployee] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [councils, setCouncils] = React.useState([]);
  const regexConfig = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]*$/;
  const notify = useNotifier();
  const { id } = useParams();
  const postProviderMutation = useMutation(postProvider);
  const updateProviderMutation = useMutation(updateProvider);

  const initialValues = {
    name: "",
    social_name: "",
    birth_date: "",
    genre: { describe: "" },
    nationality: { describe: "" },
    naturality: "",
    race: { describe: "" },
    active: true,
    marital_status: { describe: "" },
    cpfOrCnpj: "",
    ident_national: "",
    issuing_body: "",
    sus_card: null,
    employee_type: { description: "" },
    id_council_state: { describe: "" },
    council_registration_number: null,
    id_council: { description: "" },
    user_login: "",
    pass_login: "",
    pass_confirmation: "",
    specialties: [],
    street: "",
    district: "",
    address_number: null,
    address_complement: "",
    county: "",
    email: "",
    state: "",
    cep: "",
    phone: "",
    cell_phone: "",
  };

  const validator = Yup.object().shape({
    name: Yup.string()
      .min(5, "Muito Curto")
      .required("É requerido")
      .nullable()
      .matches(regexConfig, "Nome Inválido"),
    social_name: Yup.string().nullable().matches(regexConfig, "Nome Inválido"),
    birth_date: Yup.date()
      .typeError("Data Inválida")
      .min(new Date("01/01/1900"), "O Ano deve ser posterior ou igual a 1900")
      .required("É requerido")
      .nullable(),
    genre: Yup.object().required("É requerido").nullable(),
    nationality: Yup.object().nullable(),
    naturality: Yup.string().nullable(),
    race: Yup.object().nullable().required("É requerido"),
    marital_status: Yup.object().nullable(),
    cpfOrCnpj: Yup.string()
      .required("É requerido")
      .cpfOrCnpjValidate()
      .nullable(),
    ident_national: Yup.string().required("É requerido").nullable(),
    issuing_body: Yup.string().required("É requerido").nullable(),
    sus_card: Yup.string().nullable(),
    employee_type: Yup.object().required("É requerido").nullable(),
    council_registration_number: Yup.string()
      .required("É requerido")
      .matches(
        /^[A-Z0-9]+$/,
        "Somente letras maiúsculas ou números são permitidos"
      )
      .nullable(),
    active: Yup.boolean().required("É requerido").nullable(),
    id_council_state: Yup.object().required("É requerido").nullable(),
    id_council: Yup.object().required("É requerido").nullable(),
    user_login: Yup.string().when([], {
      is: () => id !== "new",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("É requerido").nullable(),
    }),
    pass_login: Yup.string().when([], {
      is: () => id !== "new",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("É requerido").nullable(),
    }),
    pass_login_confirmation: Yup.string().oneOf(
      [Yup.ref("pass_login"), null],
      "As senhas não condizem"
    ),
    street: Yup.string().nullable(),
    district: Yup.string().nullable(),
    county: Yup.string().nullable(),
    state: Yup.string().nullable(),
    cep: Yup.string().nullable().required("É requerido"),
    cell_phone: Yup.string().nullable().required("É requerido"),
    email: Yup.string().nullable().required("É requerido"),
    address_number: Yup.number().nullable().required("É requerido"),
    specialties: Yup.array().min(1).required("É requerido"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validator),
    defaultValues: initialValues,
  });
  const [cep, ident_national, cpfOrCnpj, employee_type] = watch([
    "cep",
    "ident_national",
    "cpfOrCnpj",
    "employee_type",
    "specialties",
  ]);

  const cleanerFields = () => {
    setValue("state", "");
    setValue("county", "");
    setValue("district", "");
    setValue("street", "");
  };

  useQuery(
    ["cep", cep],
    () => {
      if (!errors?.cep) {
        const cleanedCep = cleanUpMask(cep, "", ["-"]);
        return fetchCep(cleanedCep);
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: cep.length === 9,
      onSuccess(response) {
        if (!response?.erro) {
          setValue("state", response.data.uf);
          setValue("county", response.data.localidade);
          setValue("district", response.data.bairro);
          setValue("street", response.data.logradouro);
        } else {
          cleanerFields();
        }
      },
      onError() {
        cleanerFields();
      },
    }
  );

  useQuery("employee", () => getProvider(id), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== "new",
    onSuccess(response) {
      const cep = formatWithMask({
        mask: RegexOf.cep,
        text: response.contact?.cep,
      }).masked;

      const cpfOrCnpj = response?.physic_national
        ? formatWithMask({
            text: response?.physic_national,
            mask: RegexOf.cpf,
          }).masked
        : formatWithMask({
            text: response?.cnpj,
            mask: RegexOf.cnpj,
          }).masked;

      const phone = formatWithMask({
        mask: RegexOf.telephone,
        text: response.contact?.phone,
      }).masked;

      const cell_phone = formatWithMask({
        mask: RegexOf.phone,
        text: response.contact?.cell_phone,
      }).masked;

      reset({
        name: response?.name,
        social_name: response?.social_name || "",
        birth_date: response.birth_date ? parseISO(response.birth_date) : null,
        genre: { describe: response?.genre || "" },
        nationality: { describe: response.nationality || "" },
        naturality: response?.naturality,
        race: { describe: response.race || "" },
        active: response?.employee_Type?.active,
        marital_status: { describe: response?.marital_status },
        cpfOrCnpj,
        ident_national: response?.ident_national,
        issuing_body: response?.issuing_body,
        sus_card: response?.sus_card,
        employee_type: response?.employee_Type,
        id_council_state: { describe: response?.id_council_state || "" },
        council_registration_number: response?.council_registration_number,
        id_council: response?.employee_Type?.council || null,
        user_login: "",
        pass_login: "",
        pass_confirmation: "",
        specialties: response?.specialties || [],
        street: response?.contact?.street,
        district: response?.contact?.district,
        address_number: response?.contact?.address_number,
        address_complement: response?.contact?.complement,
        county: response?.contact?.county,
        email: response?.contact?.email,
        state: response?.contact?.state,
        cep,
        phone,
        cell_phone,
      });
    },
  });

  useQuery("employee_types", () => getTypes({ filter: "active" }), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!employeeTypes,
    onSuccess(response) {
      setEmployeeTypes(response.data);
    },
    onError(err) {
      notify(err.message, "error");
    },
  });

  useQuery("specialties", () => getSpecialtiesActive(), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!specialties,
    onSuccess(response) {
      setSpecialties(response.data);
    },
    onError(err) {
      notify(err.message, "error");
    },
  });

  useQuery("councils", () => getCouncils(), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!councils,
    onSuccess(response) {
      setCouncils(response.data);
    },
    onError(err) {
      notify(err.message, "error");
    },
  });

  async function onSubmit(values) {
    values.birth_date = format(values.birth_date, "yyyy-MM-dd");
    let physic_national =
      values.cpfOrCnpj.length < 15 ? values.cpfOrCnpj : null;
    physic_national = cleanUpMask(physic_national, "", ["_", "-", "."]);

    let cnpj = values.cpfOrCnpj.length > 15 ? values.cpfOrCnpj : null;
    cnpj = cleanUpMask(cnpj, "", [".", "/", "-"]);

    delete values.cpfOrCnpj;
    values.ident_national = cleanUpMask(values.ident_national, "", ["."]);
    values.cep = cleanUpMask(values.cep, "", ["-"]);
    values.phone = cleanUpMask(values.phone, "", ["-", " ", "+", "(", ")"]);
    values.cell_phone = cleanUpMask(values.cell_phone, "", [
      "-",
      " ",
      "+",
      "(",
      ")",
    ]);

    const data = {
      ...values,
      name: values.name,
      social_name: values.social_name,
      genre: values.genre.describe,
      nationality: values.nationality.describe,
      naturality: values.naturality,
      race: values.race.describe,
      marital_status: values.marital_status.describe,
      physic_national,
      cnpj,
      contact: {
        address_number: values.address_number,
        cell_phone: values.cell_phone,
        cep: values.cep,
        county: values.county,
        district: values.district,
        email: values.email,
        phone: values.phone,
        state: values.state,
        street: values.street,
        complement: values.address_complement,
      },
      employee_type: values.employee_type?.id,
      id_council: values.id_council?.id,
      council_registration_number: values.council_registration_number,
      issuing_body: values.issuing_body,
      ident_national: values.ident_national,
      user_login: values.user_login,
      pass_login: values.pass_login,
      pass_confirmation: values.pass_confirmation,
      id_council_state: values.id_council_state?.describe,
      specialties: values?.specialties.map((specialty) => specialty.id),
    };

    try {
      setLoading(true);
      if (id !== "new") {
        updateProviderMutation.mutate(
          { id, data },
          {
            onSuccess(response) {
              notify("Prestador atualizado com sucesso", "success");
              setDataEmployee(response.data);
              reset();
            },
            onError(error) {
              notify(error.message, "error");
            },
          }
        );
        return;
      } else {
        postProviderMutation.mutate(data, {
          onSuccess(response) {
            notify("Prestador cadastrado com sucesso", "success");
            setDataEmployee(response.data);
            reset();
          },
          onError(error) {
            notify(error.message, "error");
          },
        });
      }
    } catch (err) {
      setLoading(false);
      notify(err.message, "error");
    } finally {
      setLoading(false);
    }
  }

  function handleSearchCouncil(value) {
    let council = [];
    council.push(value.council);
    return council;
  }

  if (!!Object.keys(dataEmployee).length) {
    return (
      <>
        <BoxContainer title={"Feedback de Cadastro"}>
          <ProviderFeedback
            data={dataEmployee}
            showVisualization={true}
            button
          />
        </BoxContainer>
      </>
    );
  }

  if (postProviderMutation.isLoading || updateProviderMutation.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <BoxContainer
        title={id !== "new" ? "Edição Profissional" : "Cadastro Profissional"}
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={3.5} xs={12}>
              <TextField control={control} name="name" label="Nome" />
            </Grid>
            <Grid item md={3.5} xs={12} alignItems="stretch">
              <TextField
                control={control}
                name="social_name"
                label="Nome Social"
              />
            </Grid>
            <Grid item md={1.5} xs={6}>
              <DateField
                control={control}
                name="birth_date"
                label="Data de Nascimento"
                required={true}
                maxDate={new Date()}
                inputProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item md={1.5} xs={6} alignItems="stretch">
              <SelectField
                control={control}
                options={_.sortBy(genders)}
                optionLabelKey="describe"
                name="genre"
                required
                label="Gênero"
              />
            </Grid>
            <Grid item md={2} xs={6} alignItems="stretch">
              <SelectField
                control={control}
                options={_.sortBy(countries)}
                optionLabelKey="describe"
                name="nationality"
                label="Nacionalidade"
              />
            </Grid>
            <Grid item md={2} xs={6} alignItems="stretch">
              <TextField
                control={control}
                name="naturality"
                label="Naturalidade"
              />
            </Grid>
            <Grid item md={1.2} xs={6} alignItems="stretch">
              <SelectField
                control={control}
                options={_.sortBy(race)}
                optionLabelKey="describe"
                name="race"
                label="Raça"
                required
              />
            </Grid>
            <Grid item md={1.3} xs={6} alignItems="stretch">
              <SelectField
                control={control}
                options={_.sortBy(states)}
                optionLabelKey="describe"
                name="marital_status"
                label="Estado Civil"
              />
            </Grid>
            <Grid item md={2.2} xs={6} alignItems="stretch">
              <TextField
                control={control}
                name="sus_card"
                type="number"
                label="CNS"
              />
            </Grid>
            <Grid item md={2} xs={6} alignItems="stretch">
              <TextField
                control={control}
                name="cpfOrCnpj"
                label="CPF / CNPJ"
                mask={cpfOrCnpj?.length < 14 ? RegexOf.cpf : RegexOf.cnpj}
                required
              />
            </Grid>
            <Grid item md={1.7} xs={6} alignItems="stretch">
              <TextField
                control={control}
                name="ident_national"
                label="Registro Geral"
                mask={ident_national?.length < 9 ? RegexOf.rg : ""}
                required
              />
            </Grid>
            <Grid item md={1.6} xs={6} alignItems="stretch">
              <TextField
                control={control}
                name="issuing_body"
                label="Orgão Emissor"
                required
              />
            </Grid>
          </Grid>
          <Box my={2}>
            <Typography variant="h7" color="secondary" fontWeight="500" my={2}>
              Dados de Contato
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12} alignItems="stretch">
              <TextField
                control={control}
                name="email"
                type="email"
                label="Email"
                required
              />
            </Grid>
            <Grid item md={4} xs={6} alignItems="stretch">
              <TextField
                control={control}
                name="phone"
                label="Telefone"
                mask={RegexOf.telephone}
              />
            </Grid>
            <Grid item md={4} xs={6} alignItems="stretch">
              <TextField
                control={control}
                name="cell_phone"
                label="Celular"
                required
                mask={RegexOf.phone}
              />
            </Grid>
            <Grid item md={1.5} xs={6}>
              <TextField
                control={control}
                name="cep"
                label="CEP"
                required
                onBlur={() => !cep && cleanerFields()}
                mask={RegexOf.cep}
              />
            </Grid>
            <Grid item md={1} xs={6}>
              <TextField
                control={control}
                name="address_number"
                label="Número"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item md={1.5} xs={12}>
              <TextField
                control={control}
                name="street"
                fullWidth
                size="small"
                label="Logradouro"
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                control={control}
                name="district"
                fullWidth
                size="small"
                label="Bairro"
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                control={control}
                name="county"
                fullWidth
                size="small"
                label="Localidade"
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={1.5} xs={12}>
              <TextField
                control={control}
                name="state"
                fullWidth
                size="small"
                label="Estado"
                disabled={true}
              />
            </Grid>
            <Grid item md={2.5} xs={12}>
              <TextField
                control={control}
                name="address_complement"
                fullWidth
                size="small"
                label="Complemento"
              />
            </Grid>
          </Grid>
          {id === "new" && (
            <>
              <Box my={2}>
                <Typography variant="h7" color="secondary" fontWeight="500">
                  Dados de Acesso
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <TextField
                    control={control}
                    name="user_login"
                    required
                    label="Login:"
                    autoComplete="username"
                  />
                </Grid>
                <Grid item md={4} xs={6}>
                  <TextField
                    control={control}
                    name="pass_login"
                    label="Senha:"
                    required
                    type="password"
                  />
                </Grid>
                <Grid item md={4} xs={6}>
                  <TextField
                    control={control}
                    name="pass_login_confirmation"
                    label="Repita a Senha:"
                    required
                    type="password"
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Box my={2}>
            <Typography variant="h7" color="secondary" fontWeight="500">
              Dados Profissionais
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item md={5} xs={12}>
              <AutocompleteField
                control={control}
                name="employee_type"
                label="Tipo do Profissional :"
                options={employeeTypes}
                optionLabelKey="description"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <AutocompleteField
                multiple
                name="specialties"
                label="Especialidades"
                options={specialties}
                optionLabelKey="describe"
                control={control}
              />
            </Grid>
            <Grid item md={3} xs={4}>
              <SelectField
                control={control}
                name="id_council"
                label="Conselho :"
                options={
                  !!employee_type ? handleSearchCouncil(employee_type) : []
                }
                optionLabelKey="description"
              />
            </Grid>
            <Grid item md={1.5} xs={4}>
              <SelectField
                control={control}
                name="id_council_state"
                label="UF :"
                options={ufs2.sort()}
                optionLabelKey="describe"
              />
            </Grid>
            <Grid item md={2} xs={4}>
              <TextField
                name="council_registration_number"
                label="Número :"
                control={control}
              />
            </Grid>
            <Grid item md={1} xs={2}>
              <SwitchField name="active" label="Ativo?" control={control} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" gap={2} mt={2}>
              <Button
                type="submit"
                disabled={loading}
                color="secondary"
                variant="contained"
              >
                {id !== "new" ? "Atualizar" : "Cadastrar"}
              </Button>
              <Button
                onClick={() =>
                  navigate("/schedule/employee", {
                    replace: true,
                  })
                }
              >
                Voltar
              </Button>
            </Box>
          </Grid>
        </Box>
      </BoxContainer>
    </>
  );
}

export default EmployeeForm;
