function validateCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf === "") return false;
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}

function validateCNS12(cns) {
  if (cns.trim().length !== 15) {
    return false;
  }

  let soma = 0;
  let resto, dv;
  let pis = "";
  let resultado = "";

  pis = cns.substring(0, 11);

  soma =
    parseInt(pis.substring(0, 1)) * 15 +
    parseInt(pis.substring(1, 2)) * 14 +
    parseInt(pis.substring(2, 3)) * 13 +
    parseInt(pis.substring(3, 4)) * 12 +
    parseInt(pis.substring(4, 5)) * 11 +
    parseInt(pis.substring(5, 6)) * 10 +
    parseInt(pis.substring(6, 7)) * 9 +
    parseInt(pis.substring(7, 8)) * 8 +
    parseInt(pis.substring(8, 9)) * 7 +
    parseInt(pis.substring(9, 10)) * 6 +
    parseInt(pis.substring(10, 11)) * 5;

  resto = soma % 11;
  dv = 11 - resto;

  if (dv === 11) {
    dv = 0;
  }

  if (dv === 10) {
    soma =
      parseInt(pis.substring(0, 1)) * 15 +
      parseInt(pis.substring(1, 2)) * 14 +
      parseInt(pis.substring(2, 3)) * 13 +
      parseInt(pis.substring(3, 4)) * 12 +
      parseInt(pis.substring(4, 5)) * 11 +
      parseInt(pis.substring(5, 6)) * 10 +
      parseInt(pis.substring(6, 7)) * 9 +
      parseInt(pis.substring(7, 8)) * 8 +
      parseInt(pis.substring(8, 9)) * 7 +
      parseInt(pis.substring(9, 10)) * 6 +
      parseInt(pis.substring(10, 11)) * 5 +
      2;

    resto = soma % 11;
    dv = 11 - resto;
    resultado = pis + "001" + dv.toString();
  } else {
    resultado = pis + "000" + dv.toString();
  }

  if (cns !== resultado) {
    return false;
  } else {
    return true;
  }
}

function validateCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

const validateTimeSchema = (value) => {
  if (
    value.match(
      /^(?=\d+[ymwd])(( ?\d+y)?(?!\d))?(( ?\d+m)?(?!\d))?(( ?\d+w)?(?!\d))?(( ?\d+d)?(?!\d))?$/g
    )
  ) {
    return true;
  }

  return false;
};

function validateCNS789(cns) {
  if (cns.trim().length !== 15) {
    return false;
  }

  let soma = 0;
  for (let i = 0; i < 15; i++) {
    soma += parseInt(cns[i]) * (15 - i);
  }

  const resto = soma % 11;

  return resto === 0;
}

function validateCEP(cep) {
  return cep.match(/^[0-9]{5}-[0-9]{3}$/);
}

function validateEmail(email) {
  const regex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return email.match(regex);
}

function validatePhone(phone) {
  return phone.match(/^(\d{1,2})\s\d{4,5}-\d{4}/);
}

const validateSextantValue = (s) => {
  if (s?.rps?.match(/^[12340\*xX]$/)) {
    return true;
  } else {
    return false;
  }
};

export {
  validateCPF,
  validateCNPJ,
  validateTimeSchema,
  validateCEP,
  validateEmail,
  validatePhone,
  validateCNS12,
  validateCNS789,
  validateSextantValue,
};
