import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import propTypes from "prop-types";
import {
  IconButton,
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  DeleteRounded,
  EditRounded,
  VisibilityRounded,
  DoNotDisturbAlt,
  CampaignRounded,
  MedicationRounded,
} from "@mui/icons-material";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { ConfirmDialog } from "../../helper";
import { GridFilterListIcon } from "@mui/x-data-grid";
import Popover from "@mui/material/Popover";
import PaginatedCircularProgress from "../../helper/PaginatedLoading";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.light,
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.light,
  },
  [`&.${tableCellClasses.root}`]: {
    boxShadow: "none",
    borderBottom: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, customDisabledColor }) => ({
  border: "none",
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.medium,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.disabled": {
    backgroundColor: !customDisabledColor
      ? "rgba(216, 0, 0, 0.1)"
      : customDisabledColor,
  },
}));
export default function CustomTable({
  columns = [],
  data = [],
  size = "small",
  actions = false,
  pageSize = 0,
  actionsTypes = [],
  actionUniqueIdentifier,
  disableActions,
  disableRows,
  confirmMessage = "Você realmente deseja *action* este item?",
  onCancelConfirmation = () => {},
  actionHandleView,
  actionHandleEdit,
  actionHandlePrint,
  actionHandleChecklist,
  actionHandleDownload,
  actionHandleDelete,
  actionHandleSuspend,
  actionHandleCall,
  actionHandleResponse,
  actionHandleFinish,
  actionHandleMedicate,
  actionHandleUnmedicated,
  rowsAttrToHideOnView = [],
  formikFormHelper,
  loading,
  disabledColor,
  shouldDisableFields = [],
  searchable,
  height,
  onRowClick,
  ...props
}) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [text, setText] = React.useState("");
  const [select, setSelect] = React.useState("");
  const [rows, setRows] = React.useState(data);
  const [objectKeys, setObjecKeys] = React.useState([]);
  const actionDialog = React.useRef(() => {});
  const actionCancelDialog = React.useRef(() => {});

  const handleMessage = (row, action) => {
    const message = confirmMessage.replace("action", action);
    const splitedMessage = message.split(" ").map((str) => {
      if (str.match(/({\D+})/gi)) {
        const replacer = str.replace(/[()?]/g, "");
        const value = eval(replacer.replace(/[{}]/g, ""));
        const string = str.replace(replacer, value);
        return string;
      } else {
        return str;
      }
    });
    const completedMessage = splitedMessage.join(" ");
    setMessage(completedMessage);
  };

  const togleDialog = () => {
    if (openDialog) {
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setRows(data);
    if (data.length) {
      setObjecKeys(Object.keys(data[0]));
    }
    setText("");
  }, [data]);

  React.useEffect(() => {
    if (!text.length) {
      setRows(data);
    } else {
      setRows(
        data.filter((item) => {
          if (
            item[select].toString().toLowerCase().includes(text.toLowerCase())
          ) {
            return item;
          }
        })
      );
    }
  }, [text]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const popperProps = {
    popperOptions: {
      positionFixed: true,
      modifiers: [
        {
          name: "flip",
          enabled: true,
          options: {
            rootBoundary: "viewport",
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            rootBoundary: "viewport",
          },
        },
        {
          name: "offset",
          options: {
            offset: [-50, -50],
          },
        },
      ],
    },
  };

  return (
    <>
      <TableContainer
        component={Paper}
        {...props}
        sx={{
          overflow: loading ? "hidden" : "auto",
          minHeight: loading || data.length ? "120px" : "inherit",
          transition: "1s",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "6px 12px",
            display: "flex",
            gap: "10px",
            color: "white",
            backgroundColor: (theme) => theme.palette.primary.light,
          }}
        >
          <Button
            aria-describedby={id}
            onClick={handleClick}
            startIcon={<GridFilterListIcon />}
            sx={{
              color: "white",
            }}
          >
            Filtrar
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              padding: "5px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: "20px 20px",
                display: "flex",
                gap: "15px",
              }}
            >
              <TextField
                size="small"
                label="Campo:"
                value={select}
                select
                onChange={({ target }) => setSelect(target.value)}
                sx={{
                  minWidth: "150px",
                }}
              >
                {objectKeys.map((key, index) => {
                  if (columns[index]) {
                    return (
                      <MenuItem key={key} value={key}>
                        {columns[index]?.name}
                      </MenuItem>
                    );
                  }
                })}
              </TextField>
              <TextField
                size="small"
                label="Valor: "
                value={text}
                disabled={select ? false : true}
                sx={{
                  minWidth: "150px",
                }}
                onChange={({ target }) => setText(target.value)}
              />
            </Box>
          </Popover>
        </Box>
        <Table sx={{ minWidth: 700 }} aria-label="customized table" size={size}>
          <TableHead>
            <TableRow sx={{}}>
              {columns.map((column) => (
                <StyledTableCell
                  key={column?.name}
                  align={column.align ? column.align : "left"}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      position: "relative",
                      "&:before": {
                        position: "absolute",
                        content: "''",
                        width: "3px",
                        height: "100%",
                        background: "rgba(0, 0, 0, .08)",
                        right: 0,
                      },
                    }}
                  >
                    {column?.name}
                  </Typography>
                </StyledTableCell>
              ))}
              {actions ? (
                <StyledTableCell align="center" sx={{}}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {" "}
                    Ações
                  </Typography>
                </StyledTableCell>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              position: "relative",
              width: "100%",
              overflow: loading ? "hidden" : "auto",
            }}
          >
            {loading && (
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background: "rgba(255, 255, 255, 0.5)",
                  backdropFilter: "blur(2px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                  zIndex: 1000,
                  top: 0,
                }}
              >
                <PaginatedCircularProgress />
              </Box>
            )}
            {rows.map((row, index) => (
              <StyledTableRow
                key={`${Object.values(row)[index]} - ${index}`}
                className={!!disableRows && disableRows(row) ? "disabled" : ""}
                customDisabledColor={disabledColor ? disabledColor(row) : ""}
              >
                {Object.keys(row).map((attr, index) => {
                  if (!shouldDisableFields.includes(attr)) {
                    if (attr === "align") return "";
                    else {
                      return (
                        <StyledTableCell
                          key={row[attr] + "-" + index}
                          align={row.align ? row.align : "left"}
                          onClick={(event) =>
                            !!onRowClick ? onRowClick(row) : event
                          }
                        >
                          {row[attr]}
                        </StyledTableCell>
                      );
                    }
                  }
                })}
                {actions ? (
                  <StyledTableCell align="center">
                    {actionsTypes.map((item) => {
                      switch (item) {
                        case "view":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Visualizar"
                            >
                              <Box component="span">
                                <IconButton
                                  color="secondary"
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "view")
                                  }
                                  onClick={() => {
                                    actionHandleView(
                                      actionUniqueIdentifier
                                        ? row[actionUniqueIdentifier]
                                        : index,
                                      row,
                                      formikFormHelper ? formikFormHelper : ""
                                    );
                                  }}
                                >
                                  <VisibilityRounded />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        case "edit":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Editar"
                            >
                              <Box component="span">
                                <IconButton
                                  color="secondary"
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "edit")
                                  }
                                  onClick={() => {
                                    actionHandleEdit(
                                      actionUniqueIdentifier
                                        ? row[actionUniqueIdentifier]
                                        : index,
                                      row,
                                      formikFormHelper ? formikFormHelper : ""
                                    );
                                  }}
                                >
                                  <EditRounded />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        case "print":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Imprimir"
                            >
                              <Box component="span">
                                <IconButton
                                  color="secondary"
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "print")
                                  }
                                  onClick={() => {
                                    actionHandlePrint(
                                      actionUniqueIdentifier
                                        ? row[actionUniqueIdentifier]
                                        : index,
                                      row,
                                      formikFormHelper ? formikFormHelper : ""
                                    );
                                  }}
                                >
                                  <LocalPrintshopRoundedIcon />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        case "download":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Download"
                            >
                              <Box component="span">
                                <IconButton
                                  color="secondary"
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "download")
                                  }
                                  onClick={() => {
                                    actionHandleDownload(
                                      actionUniqueIdentifier
                                        ? row[actionUniqueIdentifier]
                                        : index,
                                      row,
                                      formikFormHelper ? formikFormHelper : ""
                                    );
                                  }}
                                >
                                  <FileDownloadRoundedIcon />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        case "response":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Responder"
                            >
                              <Box component="span">
                                <IconButton
                                  color="secondary"
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "response")
                                  }
                                  onClick={() => {
                                    actionHandleResponse(
                                      actionUniqueIdentifier
                                        ? row[actionUniqueIdentifier]
                                        : index,
                                      row,
                                      formikFormHelper ? formikFormHelper : ""
                                    );
                                  }}
                                >
                                  <AssignmentOutlinedIcon />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        case "checklist":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Checklist"
                            >
                              <Box component="span">
                                <IconButton
                                  color="secondary"
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "checklist")
                                  }
                                  onClick={() => {
                                    actionHandleChecklist(
                                      actionUniqueIdentifier
                                        ? row[actionUniqueIdentifier]
                                        : index,
                                      row,
                                      formikFormHelper ? formikFormHelper : ""
                                    );
                                  }}
                                >
                                  <InventoryRoundedIcon />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        case "finish":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Finalizar"
                            >
                              <Box component="span">
                                <IconButton
                                  color="secondary"
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "finish")
                                  }
                                  onClick={() => {
                                    handleMessage(row, "finalizar");
                                    togleDialog();

                                    actionDialog.current = () => {
                                      actionHandleFinish(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        row,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                    actionCancelDialog.current = () => {
                                      onCancelConfirmation(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        row,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                  }}
                                >
                                  <DoneRoundedIcon />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        case "medicate":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Administrar"
                            >
                              <Box component="span">
                                <IconButton
                                  color="secondary"
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "medicate")
                                  }
                                  onClick={() => {
                                    handleMessage(row, "medicar");
                                    togleDialog();

                                    actionDialog.current = () => {
                                      actionHandleMedicate(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        row,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                    actionCancelDialog.current = () => {
                                      onCancelConfirmation(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        row,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                  }}
                                >
                                  <MedicationRounded />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        case "unmedicate":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Não Administrar"
                            >
                              <Box component="span">
                                <IconButton
                                  color="error"
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "medicate")
                                  }
                                  onClick={() => {
                                    handleMessage(row, "medicar");
                                    togleDialog();

                                    actionDialog.current = () => {
                                      actionHandleUnmedicated(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        row,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                    actionCancelDialog.current = () => {
                                      onCancelConfirmation(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        row,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                  }}
                                >
                                  <MedicationRounded />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        case "delete":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Deletar"
                            >
                              <Box component="span">
                                <IconButton
                                  color="error"
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "delete")
                                  }
                                  onClick={() => {
                                    handleMessage(row, "deletar");
                                    togleDialog();

                                    actionDialog.current = () => {
                                      actionHandleDelete(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                    actionCancelDialog.current = () => {
                                      onCancelConfirmation(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        row,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                  }}
                                >
                                  <DeleteRounded />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        case "call":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Chamar"
                            >
                              <Box component="span">
                                <IconButton
                                  color="secondary"
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "call")
                                  }
                                  onClick={() => {
                                    handleMessage(row, "chamar");
                                    togleDialog();

                                    actionDialog.current = () => {
                                      actionHandleCall(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        row,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                    actionCancelDialog.current = () => {
                                      onCancelConfirmation(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        row,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                  }}
                                >
                                  <CampaignRounded />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        case "suspend":
                          return (
                            <Tooltip
                              key={`${item}-${index}`}
                              PopperProps={popperProps}
                              title="Suspender"
                            >
                              <Box component="span">
                                <IconButton
                                  disabled={
                                    !!disableActions &&
                                    disableActions(row, "suspend")
                                  }
                                  color="secondary"
                                  onClick={() => {
                                    handleMessage(row, "suspender");
                                    togleDialog();

                                    actionDialog.current = () => {
                                      actionHandleSuspend(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                    actionCancelDialog.current = () => {
                                      onCancelConfirmation(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        row,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                  }}
                                >
                                  <DoNotDisturbAlt />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          );
                        default:
                          return null;
                      }
                    })}
                  </StyledTableCell>
                ) : (
                  ""
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog
        message={message}
        hideBackDrop={false}
        open={openDialog}
        maxWidth="sm"
        fullWidth={true}
        handleClose={togleDialog}
        actions
        handleConfirm={actionDialog.current}
        handleCancel={actionCancelDialog.current}
      />
    </>
  );
}
CustomTable.propTypes = {
  size: propTypes.oneOf(["small", "medium", "large"]),
  columns: propTypes.arrayOf(propTypes.object),
  rows: propTypes.arrayOf(propTypes.object),
  actions: propTypes.bool,
  actionUniqueIdentifier: propTypes.string,
  actionsTypes: propTypes.arrayOf(propTypes.string),
  actionHandleView: propTypes.func,
  actionHandleEdit: propTypes.func,
  actionHandleDelete: propTypes.func,
  formikFormHelper: propTypes.object,
};
