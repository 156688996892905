import React, { useState } from "react";
import { Grid } from "@mui/material";
import UserRolesSettings from "./AccessSettings/UserRolesSettings";
import ScreenPermisions from "./AccessSettings/ScreenPermisions";
import { useProvider } from "../../../service";
import TAccordion from "../../../components/Accordion";
import EmployeeTypesRoles from "./AccessSettings/EmployeeTypesRoles";
import EmployeeUserAndPasswordReset from "./AccessSettings/EmployeeUserAndPasswordReset";
import UserCompanySettings from "./AccessSettings/UserCompanySettings";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  PaginatedAutocompleteField,
} from "../../../components/FormFields";
import Yup from "../../../config/yup";

const TabUsers = () => {
  const { getProviders } = useProvider();
  const [provider, setProvider] = useState();

  const validations = Yup.object().shape({
    employee: Yup.object().nullable().required("Selecione o profissional."),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      employee: null,
    },
    resolver: yupResolver(validations),
  });

  const settings = provider
    ? [
        {
          title: "Empresa(s)",
          body: <UserCompanySettings employee={provider} />,
        },
        {
          title: "Telas",
          body: <ScreenPermisions employee={provider} />,
        },
        {
          title: "Papéis",
          body: <UserRolesSettings employee={provider} />,
        },
        {
          title: `Roles para tipo ${provider.employee_Type.description}`,
          body: <EmployeeTypesRoles type={provider.employee_Type.id} />,
        },
        {
          title: "Usuário e Senha",
          body: <EmployeeUserAndPasswordReset employee={provider} />,
        },
      ]
    : [];

  function onSubmit(values) {
    setProvider(values.employee);
  }

  return (
    <Grid
      container
      xs={12}
      spacing={2}
      sx={{
        minHeight: "350px",
      }}
    >
      <Grid
        xs={12}
        item
        container
        spacing={2}
        sx={{
          marginTop: 4,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid xs={6} item>
          <PaginatedAutocompleteField
            name="employee"
            label="Profissional"
            control={control}
            customOnChange={(value) => setProvider(value)}
            filterKey="name"
            optionLabelKey="name"
            queryKey="get-providers"
            service={(paginate) => {
              return getProviders({
                ...paginate,
                active: true,
                show_all_employees: true,
              });
            }}
          />
        </Grid>
        <Grid xs={2} item>
          {/* <Button type="submit">Escolher</Button> */}
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <TAccordion configs={settings} />
      </Grid>
    </Grid>
  );
};

export default TabUsers;
