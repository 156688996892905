import React from "react";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import Helmet from "react-helmet";
import { FormTextField, SubmitButton } from "../../../components/Form";
import FormDate from "../../../components/Form/FormDate";
import { TITLE_NAME } from "../../../config/config";
import Yup from "../../../config/yup";
import { useManufacturer } from "../../../service";
import { useBatch } from "../../../service";
import { format } from "date-fns";
import { useVaccine } from "../../../service";
import FormSelectFieldWithPaginate from "../../../components/Form/FormSelectWithPaginate";
import useNotifier from "../../../hooks/useNotifier";

function ImmunizingForm({ handleClose }) {
  const { postBatches } = useBatch();
  const { getManufacturers } = useManufacturer();
  const { getVaccines } = useVaccine();
  const notify = useNotifier();

  const initialValues = {
    batch: "",
    vaccine: null,
    manufacturer: null,
    manufacturing_date: "",
    expiration_date: "",
    amount: "",
    bottles: "",
  };

  const validations = Yup.object().shape({
    vaccine: Yup.object().required("É requerido").nullable(),
    code: Yup.string().required("É requerido"),
    expiration_date: Yup.date()
      .required("É requerido")
      .typeError("Data Inválida"),
    amount: Yup.string().required("É requerido"),
  });

  async function handleSubmit(values) {
    const batchObject = {
      code: values.code,
      expiration_date: format(new Date(values.expiration_date), "yyyy-MM-dd"),
      amount: values.amount,
      minimumInterval: values.minimumInterval,
      id_manufacturer: values.manufacturer.id,
      id_vaccine: values.vaccine.id,
      bottles: values.bottles,
    };
    try {
      const response = await postBatches(batchObject);
      if (response) {
        notify("Lote cadastrado com Sucesso!", "success");
      }
    } catch (err) {
      notify(err.message, "error");
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Vacina</title>
      </Helmet>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
      >
        {({ values }) => (
          <Form>
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ marginBottom: 1 }}>
                  <FormDate
                    item
                    name="manufacturing_date"
                    label="Data De Fabricação"
                    maxDate={new Date()}
                    onBlur={(e) => {}}
                  />
                </Grid>
                <Grid item xs={6} sx={{ marginBottom: 1 }}>
                  <FormDate
                    item
                    name="expiration_date"
                    label="Data De Validade"
                    required
                    minDate={new Date()}
                    onBlur={(e) => {}}
                  />
                </Grid>
                <Grid xs={3} item>
                  <FormTextField name="code" label="Lote" required />
                </Grid>
                <Grid xs={3} item>
                  <FormTextField
                    name="bottles"
                    label="Qant. de Frascos"
                    required
                  />
                </Grid>
                <Grid xs={6} item>
                  <FormTextField
                    name="amount"
                    label="Quantidade de Doses"
                    required
                  />
                </Grid>
                <Grid xs={6} sx={{ marginTop: 2 }} item>
                  <FormSelectFieldWithPaginate
                    service={(params) => getManufacturers({ ...params })}
                    name="manufacturer"
                    fields="describe"
                    label="Fabricante"
                    required
                  />
                </Grid>
                <Grid xs={6} sx={{ marginTop: 2 }} item>
                  <FormSelectFieldWithPaginate
                    service={(params) => getVaccines({ ...params })}
                    name="vaccine"
                    fields="describe"
                    label="Imunobiológico"
                    disabled={!values.manufacturer}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "right",
                marginTop: 20,
                marginBottom: 4,
              }}
            >
              <SubmitButton>Salvar Cadastro</SubmitButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ImmunizingForm;
