import { Badge, Box, Button, Divider, Grid, Typography } from "@mui/material";
import {
  FormCheckbox,
  FormRadio,
  FormSelectWithPaginate,
  FormSwitch,
  FormTextField,
} from "../../../components/Form";
import { SubTitle, Tabs } from "../../../components";
import TeethTab from "./TeethTab";
import ArchTab from "./ArchTab";
import Sextant from "./Sextant";
import OthersTab from "./OthersTab";
import { Dialog, DialogMedium } from "../../../helper";
import Reference from "../Reference/Reference";
import Prescription from "../Prescription/Prescription";
import Declaration from "../Declaration/Declaration";
import Intervention from "../Interventions";
import { useCallback, useContext, useState } from "react";
import { AppContext } from "../../../contexts/AppContext";
import { useFormikContext } from "formik";
import EditorForms from "../../Attendance/EditorForms/EditorForms";
import useOdonto from "../../../service/useOdonto";
import { useQuery } from "react-query";
import { useProcedures, useSOAP } from "../../../service";
import OrthodonticTab from "./OrthodonticTab";
import EndodonticsTab from "./EndodonticsTab";
import usePermissions from "../../../config/usePermissions";
import { addMonths, format, isAfter, isValid, parseISO } from "date-fns";
import { verifyAttendanceFlow } from "../../../utils";

const types_attendance_urgency = [
  "Trauma",
  "Alveolite",
  "Abscesso",
  "Acesso a polpa + medicação intracanal",
  "Hemorragia",
  "Dor aguda",
];

export default function PlanOdonto({ odonto, ceo, conducts, attendance }) {
  const [referencesDialog, setReferencesDialog] = useState(false);
  const [prescriptionsDialog, setPrescriptionsDialog] = useState(false);
  const [declarationDialog, setDeclarationDialog] = useState(false);
  const [interventionDialog, setInterventionDialog] = useState(false);
  const [orientationDialog, setOrientationDialog] = useState(false);
  const drugPermissions = usePermissions("/soap/drugPrescription");
  const examsPermissions = usePermissions("/soap/examsPrescription");

  const {
    disableFields,
    drugOnSitePrescriptions,
    drugPrescriptions,
    examPrescriptions,
    againstReference,
    declarations,
    interventions,
    anesthesia,
    prosthesisCommand,
    patientData,
    disabledFieldNames,
  } = useContext(AppContext);
  const { setFieldValue, values: formvalues } = useFormikContext();
  const [archType, setArchType] = useState("default");
  const { getDentalAppointmentTypes } = useOdonto();
  const { getProcedures } = useProcedures();
  const { getAttendanceTypes } = useSOAP();

  const dentalAppointmentTypes = useQuery(
    "getDentalAppointmentTypes",
    () => getDentalAppointmentTypes(),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const attendanceTypes = useQuery(
    "getAttendanceTypes",
    () =>
      getAttendanceTypes({
        active: true,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const tabs = [
    {
      label: "Dentes",
      content: (
        <TeethTab
          attendance={attendance}
          archType={archType}
          handleArchType={setArchType}
        />
      ),
    },
    {
      label: "Arcada",
      content: (
        <ArchTab
          attendance={attendance}
          archType={archType}
          handleArchType={setArchType}
        />
      ),
    },
    { label: "Sextante", content: <Sextant attendance={attendance} /> },
    { label: "Outros", content: <OthersTab attendance={attendance} /> },
  ];

  if (ceo) {
    tabs.push(
      { label: "Ortodontia", content: <OrthodonticTab /> },
      { label: "Endodontia", content: <EndodonticsTab /> }
    );
  }

  function handleClose() {
    if (!againstReference.length) setFieldValue("references", false);
    setReferencesDialog(false);

    if (
      !drugOnSitePrescriptions.length &&
      !drugPrescriptions.length &&
      !examPrescriptions.length
    ) {
      setFieldValue("prescriptions", false);
    }
    setPrescriptionsDialog(false);

    if (!declarations.length) {
      setFieldValue("declarations", false);
    }
    setDeclarationDialog(false);
  }
  function handleCloseInterventionDialog() {
    setInterventionDialog(false);
  }

  const lengthPrescriptions =
    drugOnSitePrescriptions.length +
    drugPrescriptions.length +
    examPrescriptions.length;
  const lengthDeclarations = declarations.length;
  const lengthAgainstReference = againstReference.length;
  const lengthInterventions =
    interventions.length +
    (anesthesia.sterilization?.length || 0) +
    (anesthesia.types?.length || 0) +
    prosthesisCommand;

  const verify12MonthsCycle = useCallback(() => {
    let shouldDisable = false;
    if (
      attendance?.newest_odonto_initial_treatment_procedure_date &&
      isValid(
        new Date(attendance?.newest_odonto_initial_treatment_procedure_date)
      )
    ) {
      const today = format(new Date(), "yyyy-MM-dd");
      const twelveMonthsDate = format(
        addMonths(
          parseISO(attendance?.newest_odonto_initial_treatment_procedure_date),
          12
        ),
        "yyyy-MM-dd"
      );
      if (isAfter(parseISO(twelveMonthsDate), parseISO(today))) {
        shouldDisable = true;
      }
    } else {
      shouldDisable = false;
    }

    return shouldDisable;
  }, [attendance?.newest_odonto_initial_treatment_procedure_date]);

  return (
    <>
      <Dialog
        open={orientationDialog}
        title={"Orientações"}
        maxWidth="lg"
        fullWidth={true}
        handleClose={() => {
          setOrientationDialog(false);
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 5,
          }}
        >
          {patientData.name}
        </Typography>
        <EditorForms
          staticPatientId={patientData.id}
          showOnlyTable={true}
          staticType={"orientations"}
        />
      </Dialog>
      <DialogMedium
        open={referencesDialog}
        title={"Referência / Contrarreferência"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData.name}
        </Typography>
        <Reference patient={patientData} handleClose={handleClose} />
      </DialogMedium>
      <DialogMedium
        open={prescriptionsDialog}
        title={"Prescrição do Paciente"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData.name}
        </Typography>
        <Prescription handleClose={handleClose} />
      </DialogMedium>
      <DialogMedium
        open={declarationDialog}
        title={"Gerar Declaração"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData.name}
        </Typography>
        <Declaration
          handleClose={handleClose}
          defaultCids={formvalues.cid}
          patient={patientData}
          odonto={odonto}
          ceo={ceo}
        />
      </DialogMedium>
      <DialogMedium
        open={interventionDialog}
        title={"Intervenções e/ou procedimentos clínicos realizados"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleCloseInterventionDialog}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData.name}
        </Typography>
        <Intervention
          attendance={attendance}
          onClose={handleCloseInterventionDialog}
          odonto
          ceo={!!ceo}
        />
      </DialogMedium>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormTextField
            name="plan"
            multiline
            minRows={4}
            maxRows={4}
            label="Plano de Consulta : "
            sx={{ mb: 2 }}
            disabled={disableFields}
          />
        </Grid>
        <Grid xs={12} item>
          <Typography fontWeight={"bold"} component={"span"} color={"primary"}>
            Odontograma
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} display={"flex"} columnGap={2}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              fontWeight={"bold"}
              component={"span"}
              color={"primary"}
            >
              Possui aparelho?
            </Typography>
            <FormSwitch disabled={disableFields} name={"braces"} label={""} />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              fontWeight={"bold"}
              component={"span"}
              color={"primary"}
            >
              Possui contenção?
            </Typography>
            <FormSwitch disabled={disableFields} name={"retainer"} label={""} />
          </Box>
          <Box display={"flex"} flexDirection={"column"} rowGap={2}>
            <Typography
              fontWeight={"bold"}
              component={"span"}
              color={"primary"}
            >
              Prótese
            </Typography>
            <Box>
              <FormCheckbox
                disabled={disableFields}
                name={"upper_denture"}
                label={"Total Superior"}
              />
              <FormCheckbox
                disabled={disableFields}
                name={"lower_denture"}
                label={"Total Inferior"}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Tabs tabs={tabs} />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" gap={2}>
            <Button
              onClick={() => setPrescriptionsDialog(true)}
              variant="outlined"
              disabled={
                disableFields ||
                !(
                  drugPermissions.create ||
                  drugPermissions.read ||
                  examsPermissions.create ||
                  examsPermissions.read
                )
              }
            >
              Prescrições
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent={lengthPrescriptions}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              />
            </Button>
            <Button
              onClick={() => setReferencesDialog(true)}
              variant="outlined"
              disabled={disableFields}
            >
              Referência / Contrarreferência
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent={lengthAgainstReference}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              />
            </Button>
            <Button
              onClick={() => setDeclarationDialog(true)}
              variant="outlined"
              disabled={disableFields}
            >
              Declarações
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent={lengthDeclarations}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              />
            </Button>
            <Button
              onClick={() => setInterventionDialog(true)}
              variant="outlined"
              disabled={disableFields}
            >
              Intervenções e/ou procedimentos clínicos
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent={lengthInterventions}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              />
            </Button>
            <Button
              onClick={() => setOrientationDialog(true)}
              variant="outlined"
              disabled={disableFields}
            >
              Orientações
            </Button>
          </Box>
        </Grid>
        {(!!attendance?.scheduling || !attendance?.token_audit?.totem_id) && (
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <SubTitle
                text="Confirmação de procedimentos agendados"
                sx={{ mb: 2 }}
              />
              <Grid xs={12} container item>
                <Grid item xs={3} mt={2.5}>
                  <FormCheckbox
                    name="confirm_procedure"
                    label="Confirmar procedimento"
                    disabled={
                      disableFields ||
                      disabledFieldNames.includes("confirm_procedure")
                    }
                  />
                </Grid>
                <Grid item xs={9}>
                  <FormSelectWithPaginate
                    service={(params) =>
                      getProcedures({
                        ...params,
                        type: "administrative",
                        filter: "active",
                        patient_id: patientData?.id,
                        attendance_flow: verifyAttendanceFlow(
                          attendance.risk_classification_id
                        ),
                        cid_id: formvalues?.cid?.map(({ id }) => id),
                      })
                    }
                    getOptionLabel={(data) => {
                      let label = data?.name;

                      if (data?.tuss_code) {
                        label = `${data?.tuss_code} - ${label}`;
                      }

                      return label;
                    }}
                    searchBy="filters"
                    fields="describe"
                    customHandleChange={(value) => {
                      setFieldValue("plan_procedure", value);
                    }}
                    name="plan_procedure"
                    label="Procedimento administrativo:"
                    disabled={disableFields || formvalues.confirm_procedure}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid xs={12} item>
          <Typography fontWeight={"bold"} component={"span"} color={"primary"}>
            Finalização do Atendimento
          </Typography>
          <Divider />
        </Grid>
        {!ceo && (
          <Grid item xs={12} display={"flex"} columnGap={2}>
            <FormRadio
              disabled={disableFields}
              name="attendance_type"
              customChange={(attendance_type) => {
                if (attendance_type == 4 || attendance_type == 6) {
                  setFieldValue("treatment_type_conclusion_procedure_id", null);
                  setFieldValue("dental_appointment_type", null);
                }

                if (attendance_type !== 6) {
                  setFieldValue("types_attendance_urgency", []);
                }
              }}
              legend="Tipo de atendimento"
              radios={attendanceTypes.data}
              keys={["describe", "id"]}
              row
            />
          </Grid>
        )}
        {formvalues.attendance_type == 6 && (
          <Grid item xs={12}>
            <Box display={"flex"} flexDirection={"column"} rowGap={2}>
              <Box>
                {types_attendance_urgency.map((item) => (
                  <FormCheckbox
                    disabled={disableFields}
                    multiple
                    name={"types_attendance_urgency"}
                    label={item}
                    value={item}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        )}
        {!ceo && (
          <Grid item xs={12} display={"flex"} columnGap={2}>
            <FormRadio
              name="dental_appointment_type"
              legend="Tipo de consulta"
              radios={dentalAppointmentTypes.data}
              keys={["description", "id"]}
              // customChange={(value) => {
              //   if (+value === 1) {
              //     setFieldValue("treatment_type_procedure_id", 6840); // Tratamento Inicial
              //   } else {
              //     setFieldValue("treatment_type_procedure_id", null);
              //   }
              // }}
              disabledFn={(radio) => {
                if (
                  formvalues.attendance_type == 4 ||
                  formvalues.attendance_type == 6 ||
                  !formvalues.attendance_type ||
                  disableFields
                ) {
                  return true;
                }

                if (radio.id === 1 && verify12MonthsCycle()) {
                  return true;
                }
              }}
              row
            />
          </Grid>
        )}

        {!ceo && (
          <Grid item xs={12} columnGap={2}>
            <Typography fontSize={12} component={"span"} color={"primary"}>
              Tipo de Tratamento
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <Box marginTop={"10px"}>
              <FormCheckbox
                disabled={
                  disableFields ||
                  formvalues.attendance_type == 4 ||
                  formvalues.attendance_type == 6 ||
                  (formvalues.treatment_type_procedure_id === 6840 &&
                    verify12MonthsCycle())
                }
                name={"treatment_type_procedure_id"}
                label={"Tratamento Inicial"}
                value={6840}
              />
            </Box> */}
              <FormRadio
                name="treatment_type_conclusion_procedure_id"
                radios={[
                  {
                    describe: "Tratamento Concluído",
                    id: 6853,
                  },
                  {
                    describe: "Tratamento Completado",
                    id: 6838,
                  },
                ]}
                disabledFn={(check) => {
                  if (disableFields) {
                    return true;
                  }

                  if (
                    formvalues.attendance_type == 4 ||
                    formvalues.attendance_type == 6
                  ) {
                    return true;
                  }

                  if (check.id === 6840 && verify12MonthsCycle()) {
                    return true;
                  }
                }}
                keys={["describe", "id"]}
                row
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <Box display={"flex"} flexDirection={"column"} rowGap={2}>
            <Typography fontSize={12} component={"span"} color={"primary"}>
              Fornecimento
            </Typography>
            <Box>
              <FormCheckbox
                disabled={disableFields}
                multiple
                name={"dental_supplies"}
                label={"Escova dental"}
                value={1}
              />
              <FormCheckbox
                disabled={disableFields}
                multiple
                name={"dental_supplies"}
                label={"Creme dental"}
                value={2}
              />
              <FormCheckbox
                disabled={disableFields}
                multiple
                name={"dental_supplies"}
                label={"Fio dental"}
                value={3}
              />
            </Box>
          </Box>
        </Grid>

        {((!!conducts.length && !disableFields) ||
          (disableFields && !!formvalues.conducts?.length)) && (
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <SubTitle text="Condutas" sx={{ mb: 2 }} />
            </Grid>
            {conducts.map((data) => {
              return (
                <Grid key={data.id} item xs={6}>
                  <FormCheckbox
                    name="conducts"
                    label={data.description.firstCharToUp()}
                    value={data.id}
                    multiple
                    disabled={disableFields}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    </>
  );
}
