import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const stationPath = "/stations";

export default function useStation() {
  async function getStations(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(`${stationPath}?${urlQuery(params)}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  const postStation = async (data) => {
    try {
      const response = await instance.post(stationPath, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const updateStation = async ({ id, data }) => {
    try {
      const response = await instance.put(`${stationPath}/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };
  const deleteStation = async (id) => {
    return instance.delete(`${stationPath}/${id}`).then((res) => res.data);
  };
  return {
    getStations,
    postStation,
    updateStation,
    deleteStation,
  };
}
