import React from "react";
import {
  Dialog as MaterialDialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { SubTitle } from "../components";
import { CloseRounded, EmojiObjects } from "@mui/icons-material";
import { Box } from "@mui/system";
import { grey } from "@mui/material/colors";
import { useTheme } from "@emotion/react";
function Dialog({
  title,
  open,
  handleClose,
  children,
  icon,
  cancelClose,
  ...props
}) {
  const theme = useTheme();
  return (
    <MaterialDialog
      open={open}
      onClose={cancelClose ? () => {} : handleClose}
      fullScreen
      sx={{
        padding: 1,
        "& .MuiDialogTitle-root": {
          p: 2,
        },
      }}
      {...props}
    >
      <DialogTitle>
        <Box
          sx={{
            width: "100%",
            paddingY: 0.3,
            paddingX: 1,
            borderRadius: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            display={"flex"}
            sx={{ backgroundColor: grey[50] }}
            alignItems={"center"}
          >
            <EmojiObjects
              transition
              sx={{ color: (theme) => theme.palette.primary.main }}
            />
            <Typography fontSize={12} color={theme.palette.secondary.main}>
              Atalho: Utilize a tecla{" "}
              <Typography fontWeight="bold" component="span" fontSize={12}>
                Esc
              </Typography>{" "}
              para fechar.
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Box>
        </Box>
        <SubTitle
          text={
            <>
              <Box sx={{ display: "flex", alignItems: "center" }} gap={2}>
                <Box>{title}</Box>
                <Box>{icon}</Box>
              </Box>
            </>
          }
        />
      </DialogTitle>
      <DialogContent
        sx={{
          mt: -3,
          "&.MuiDialogContent-root": {
            padding: 2,
          },
        }}
      >
        {children}
      </DialogContent>
    </MaterialDialog>
  );
}

export default Dialog;
