import React from "react";
import { Form, Formik } from "formik";
import Yup from "../../../config/yup";
import { Grid } from "@mui/material";
import FormDate from "../../../components/Form/FormDate";
import {
  FormRadio,
  FormSearchSelectField,
  FormTextField,
  SubmitButton,
} from "../../../components/Form";
import { TITLE_NAME } from "../../../config/config";
import Helmet from "react-helmet";
import { useVaccine } from "../../../service";
import { usePatientBatch } from "../../../service";
import { useHealthUnit } from "../../../service";
import { useManufacturer } from "../../../service";
import FormSelectFieldWithPaginate from "../../../components/Form/FormSelectWithPaginate";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";
import { service_location } from "../../../config/standardSelects";
import _ from "lodash";
import useNotifier from "../../../hooks/useNotifier";

function ImmunizingNew({ handleClose }) {
  const { postPatientBatchs } = usePatientBatch();
  const { getVaccines, getVaccineHasBatch } = useVaccine();
  const { getHealthUnits } = useHealthUnit();
  const { getManufacturersByBatch } = useManufacturer();
  const params = useParams();
  const userData = JSON.parse(localStorage.getItem("user-data"));
  const { disableFields } = React.useContext(AppContext);

  const initialValues = {
    batch: "",
    vaccine: null,
    date_application: "",
    amount: "",
    healthUnits: "",
    manufacturers: "",
    trascription: "",
    service_location: "",
  };

  const validations = Yup.object().shape({
    batch: Yup.object().required("É requerido").nullable(),
    vaccine: Yup.object().required("É requerido").nullable(),
    amount: Yup.string().required("É requerido"),
    healthUnits: Yup.object().required("É requerido").nullable(),
    service_location: Yup.string().required("É requerido").nullable(),
  });

  const radios = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  const radios2 = [
    { label: "Manhã", value: "Manhã" },
    { label: "Tarde", value: "Tarde" },
    { label: "Noite", value: "Noite" },
  ];
  const radios3 = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  const notify = useNotifier();

  async function handleSubmit({
    batch,
    date_application,
    trascription,
    foreigner,
    shift,
    service_location,
  }) {
    const patientbatchObject = {
      id_batch: batch.id,
      id_patient: params.id,
      id_employee: userData.id,
      attendance_id: params.idattendance,
      shift,
      date_application,
      trascription: trascription === "true",
      foreigner: foreigner === "true",
      service_location,
    };

    try {
      const response = await postPatientBatchs(patientbatchObject);
      if (response) {
        notify(response.message, "success");
      }
    } catch (err) {
      notify(err.message, "error");
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Vacina</title>
      </Helmet>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid xs={6} item>
                  <FormSelectFieldWithPaginate
                    service={(params) =>
                      getVaccines({ ...params, has_batch: false })
                    }
                    name="vaccine"
                    fields="describe"
                    label="Imunobiológico"
                    required
                    customHandleChange={() => setFieldValue("code", null)}
                  />
                </Grid>
                <Grid xs={6} item>
                  <FormSelectFieldWithPaginate
                    service={(params) =>
                      getVaccineHasBatch(values.vaccine?.id, params)
                    }
                    name="batch"
                    fields="code"
                    label="Lotes"
                    required
                    refetch={[values.vaccine?.id]}
                  />
                </Grid>
                <Grid xs={6} item>
                  <FormSelectFieldWithPaginate
                    service={() =>
                      getManufacturersByBatch(values.batch?.id_manufacturer)
                    }
                    name="manufacturers"
                    fields="describe"
                    label="Fabricante"
                    required
                    refetch={values.batch?.id_manufacturer}
                  />
                </Grid>
                <Grid xs={6} item>
                  <FormSelectFieldWithPaginate
                    service={(params) => getHealthUnits({ ...params })}
                    name="healthUnits"
                    fields="describe"
                    label="Unidade de Saúde"
                  />
                </Grid>
                <Grid item xs={6} sx={{ marginBottom: 1 }}>
                  <FormDate
                    item
                    name="date_application"
                    label="Data De Aplicação"
                    maxDate={new Date()}
                  />
                </Grid>
                <Grid xs={6} item>
                  <FormTextField
                    name="amount"
                    label="Quantidade de Doses por ml"
                    required
                  />
                </Grid>
                <Grid xs={6} item>
                  <FormSearchSelectField
                    options={_.sortBy(service_location)}
                    getOptionLabel={(states) => (states ? states : "")}
                    name="service_location"
                    label="Local do Atendimento"
                    onClose={() => {
                      setFieldValue("service_location", null);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormRadio
                    name="trascription"
                    radios={radios}
                    legend="Transcrição: "
                    keys={["label", "value"]}
                    disabled={disableFields}
                    row={true}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormRadio
                    name="shift"
                    radios={radios2}
                    legend="Turno: "
                    keys={["label", "value"]}
                    disabled={disableFields}
                    row={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormRadio
                    name="foreigner"
                    radios={radios3}
                    legend="Paciente Viajante: "
                    keys={["label", "value"]}
                    disabled={disableFields}
                    row={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "right",
                marginTop: 15,
                marginBottom: 4,
              }}
            >
              <SubmitButton>Salvar Cadastro</SubmitButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ImmunizingNew;
