import React from "react";
import { useMutation, useQuery } from "react-query";
import { usePagination, useProvider } from "../../../service";
import { DialogMedium } from "../../../helper";
import { Box, Button, Grid, TablePagination, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import TableFilter from "../../../components/Table/TableFilter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../config/yup";
import {
  TextField,
  SwitchField,
  PaginatedAutocompleteField,
} from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";
export default function SpecialtyProfileStep() {
  const notify = useNotifier();
  const theme = useTheme();

  const { getSpecialties, postSpecialty, updateSpecialty, getCBOs } =
    useProvider();

  const [limit, setLimit] = React.useState(5);
  const [editSpecialtyDialog, setEditSpecialtyDialog] = React.useState(false);
  const [selectedSpecialty, setSelectedSpecialty] = React.useState();

  const createSpecialtyMutation = useMutation(postSpecialty);
  const updateSpecialtyMutation = useMutation(updateSpecialty);

  const specialtyQuery = useQuery(["specialty"], () => getSpecialties(), {
    retry: false,
    refetchOnWindowFocus: false,
    initialData: [],
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  const { paginationInfo, handlePageChange } = usePagination(
    specialtyQuery.data,
    limit
  );

  const columns = [
    {
      field: "number",
      name: "Codigo",
      type: "string",
    },
    {
      field: "describe",
      name: "Descrição",
      type: "string",
    },
    {
      field: "profession",
      name: "CBO",
      type: "string",
      use: (value) => (value ? value.cbo_code : "Não informado"),
    },
    {
      field: "active",
      name: "Status",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
      alignInRow: "center",
      alignInHead: "center",
    },
  ];

  const validator = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
    cbo_code: Yup.object().required("É requerido").nullable(),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      describe: "",
      cbo_code: null,
    },
    resolver: yupResolver(validator),
  });

  const updateSpecialtyForm = useForm({
    defaultValues: {
      describe: selectedSpecialty?.describe,
      cbo_code: selectedSpecialty?.profession,
      active: selectedSpecialty?.active,
    },
    resolver: yupResolver(validator),
  });

  const handleCloseDialog = () => {
    setSelectedSpecialty({});
    setEditSpecialtyDialog(false);
  };

  const onSubmit = handleSubmit((data) => {
    const sendData = {
      ...data,
      cbo_code: data.cbo_code?.cbo_code,
    };
    createSpecialtyMutation.mutate(sendData, {
      onSuccess: (res) => {
        specialtyQuery.refetch();
        notify(res.message || "Especialidade criada com sucesso!", "success");
        reset();
      },
      onError: (err) => {
        notify(err?.response?.data?.message, "error");
      },
    });
  });

  const onSubmitUpdate = updateSpecialtyForm.handleSubmit((data) => {
    const sendData = {
      ...data,
      cbo_code: data.cbo_code?.cbo_code,
    };
    updateSpecialtyMutation.mutate(
      { id: selectedSpecialty.id, data: sendData },
      {
        onSuccess: (res) => {
          specialtyQuery.refetch();
          notify(res.message, "success");
          reset();
          handleCloseDialog();
        },
        onError: (err) => {
          notify(err.message, "error");
        },
      }
    );
  });

  return (
    <>
      <DialogMedium
        open={editSpecialtyDialog}
        handleClose={handleCloseDialog}
        title="Edição de especialidade"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField
              control={updateSpecialtyForm.control}
              name="describe"
              label="Descrição"
            />
          </Grid>
          <Grid item xs={6}>
            <PaginatedAutocompleteField
              control={updateSpecialtyForm.control}
              name={"cbo_code"}
              label={"CBO"}
              service={(param) =>
                getCBOs({
                  ...param,
                })
              }
              queryKey={"cbos"}
              filterKey="describe"
              optionLabelKey={"describe"}
            />
          </Grid>
          <Grid item xs={8}>
            <SwitchField
              control={updateSpecialtyForm.control}
              name="active"
              label="Alterar Status"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              onClick={onSubmitUpdate}
              onLoad={updateSpecialtyMutation.isLoading}
            >
              Atualizar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>

      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Especialidades
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items}
          columns={columns}
          loading={specialtyQuery.isLoading}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit"]}
          shouldDisableFields={["id"]}
          actionHandleEdit={(_, item) => {
            setSelectedSpecialty(item);
            updateSpecialtyForm.setValue("describe", item.describe);
            updateSpecialtyForm.setValue("active", item.active);
            updateSpecialtyForm.setValue("cbo_code", item.profession);
            setEditSpecialtyDialog(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Especialidade
          </Typography>
        </Grid>
        <Grid item xs={4.5}>
          <TextField
            control={control}
            name={"describe"}
            label={"Descrição"}
            required
          />
        </Grid>
        <Grid item xs={4.5}>
          <PaginatedAutocompleteField
            control={control}
            name={"cbo_code"}
            label={"CBO"}
            service={(param) =>
              getCBOs({
                ...param,
              })
            }
            queryKey={"cbos"}
            filterKey="describe"
            optionLabelKey={"describe"}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={onSubmit}
            onLoad={createSpecialtyMutation.isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
