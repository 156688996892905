import { useTheme } from "@emotion/react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import useDocuments from "../../../service/useDocuments";
import { FormMakerContext } from "../../../contexts/FormMakerContext";
import { Print } from "@mui/icons-material";
import { FormMakerFeedback } from "../../../components/FormMaker";
import { format, parseISO } from "date-fns";
import { Dialog } from "../../../helper";
import { AppContext } from "../../../contexts/AppContext";

const ClassificationHistory = () => {
  const theme = useTheme();
  const [documentId, setDocumentId] = useState();
  const [displayClassification, setDisplayClassification] = useState(false);
  const { getDocumentsByType, getRecords } = useDocuments();
  const { patientData } = useContext(AppContext);
  const { setRecordDocument } = useContext(FormMakerContext);
  const [localClassificationDoc, setLocalClassifcationDoc] = useState({});
  const documentType = useQuery(
    ["documents-by-type-classification"],
    () => getDocumentsByType("af3668a2-f760-4540-9281-952525d083e8"),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: [],
      onSuccess: (response) => {
        setDocumentId(response[0]?.external_document_id);
      },
      onError: () => {
        setDocumentId(null);
      },
    }
  );

  useQuery(
    ["records-classification"],
    () =>
      getRecords({ patientId: patientData?.id, documentId, page: 0, limit: 1 }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!patientData?.id && !!documentId,
      onSuccess: (response) => {
        const record = response.items[0];
        setLocalClassifcationDoc(record);
        setRecordDocument(record);
      },
      onError: (error) => {
        setLocalClassifcationDoc(null);
        setRecordDocument(null);
      },
    }
  );

  const admeansurements = {
    "ab64e520-59f1-11ed-8747-49dcb4e47d35": "Pressão arterial: ",
    "ff8d8ad0-59f1-11ed-8747-49dcb4e47d35": "Temperatura: ",
    "17ce7f00-59f2-11ed-8747-49dcb4e47d35": "Frequência Cardíaca: ",
    "41cf5d60-59f2-11ed-8747-49dcb4e47d35": "Frequência Respiratória: ",
    "420eb050-59f2-11ed-8747-49dcb4e47d35": "Peso: ",
    "4252e540-59f2-11ed-8747-49dcb4e47d35": "Altura: ",
    "95b72980-59f2-11ed-8747-49dcb4e47d35": "Saturação: ",
    "24443d30-75ac-11ed-a2a9-c723afa59729": "Dextro: ",
  };

  const patientInformations = [
    {
      label: "Paciente",
      value: patientData?.social_prioritize
        ? patientData.social_name
        : patientData?.name || "Não informado" || "Não informado",
    },
    {
      label: "CPF",
      value: patientData?.physic_national || "Não informado",
    },
    { label: "CNS", value: patientData?.sus_card || "Não informado" },
    {
      label: "Data de nascimento",
      value: patientData?.birth_date
        ? format(parseISO(patientData.birth_date), "dd/MM/yyyy")
        : "Não informado",
    },
  ];

  function verifyAdmeasurements() {
    const admeansurementsKeys = Object.keys(admeansurements);

    const hasAdmeasurements = localClassificationDoc?.fields.some(
      ({ field_id }) => admeansurementsKeys.includes(field_id)
    );

    return hasAdmeasurements;
  }

  return (
    <>
      <Dialog
        title={localClassificationDoc?.document_id?.name}
        open={displayClassification}
        handleClose={() => setDisplayClassification(false)}
      >
        <FormMakerFeedback
          additionalData={patientInformations}
          signature
          signatureValidationPath="risk_classification"
        />
      </Dialog>
      <Box
        sx={{
          width: "calc(100% - 30px)",
        }}
      >
        <Typography variant="h6" color="secondary" fontWeight="700">
          Última Classificação
        </Typography>
        {!!localClassificationDoc?.fields?.length ? (
          <Grid
            container
            spacing={0}
            sx={{
              background: theme.palette.primary.medium,
              padding: 2,
              borderRadius: 2,
              position: "relative",
            }}
          >
            <IconButton
              color="primary"
              sx={{
                position: "absolute",
                top: 20,
                right: 15,
              }}
              onClick={() => {
                setRecordDocument(localClassificationDoc);
                setDocumentId(documentType.data[0]?.external_document_id);
                setDisplayClassification(true);
              }}
            >
              <Print fontSize="large" color="primary" />
            </IconButton>
            {!verifyAdmeasurements() ? (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: theme.palette.primary.main,
                    py: 2,
                  }}
                >
                  Nenhuma aferição encontrada.
                </Typography>
              </Grid>
            ) : (
              localClassificationDoc.fields.map(({ field_id, value }) =>
                !admeansurements[field_id] ? null : (
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: theme.palette.primary.main,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: theme.palette.primary.main,
                        }}
                        variant="span"
                      >
                        {admeansurements[field_id]}
                      </Typography>
                      {value ?? "Não informado"}
                    </Typography>
                  </Grid>
                )
              )
            )}
          </Grid>
        ) : (
          <Typography pl={2} color={theme.palette.primary.main}>
            O paciente não possui classificações
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ClassificationHistory;
