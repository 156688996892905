import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { BoxContainer } from "../../components";
import { usePatient } from "../../service";
import { TablePagination, Box, Button } from "@mui/material";
import { Dialog } from "../../helper";
import PatientFeedback from "../../helper/Feedback";
import { useLocation, useNavigate } from "react-router-dom";
import usePermissions from "../../config/usePermissions";
import TableFilter from "../../components/Table/TableFilter";
import { format, parseISO } from "date-fns";
import useTotem from "../../service/useTotem";
import { ptBR } from "date-fns/locale";
import { useDebounceCallback } from "../../hooks";
function PatientList() {
  const { getPatients } = usePatient();
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = React.useState({});
  const [open, setOpen] = React.useState(0);
  const [employee, setEmployee] = React.useState();
  const queryClient = useQueryClient();
  const permission = usePermissions("/attendance/patient");
  const navigate = useNavigate();
  const goBack = useDebounceCallback(() => navigate(-1), 1000);
  const { state } = useLocation();
  const { updateToken } = useTotem();
  const patientListQuery = useQuery(
    ["patientlist", [page, limit]],
    () => getPatients({ page, limit, ...filter }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {
        queryClient.setQueriesData("patientlist", []);
      },
    }
  );

  const tableColumns = [
    {
      name: "Cod. Paciente",
      field: "number",
      type: "number",
    },
    {
      name: "Nome",
      field: "name",
      type: "text",
      use: (_, patient) =>
        patient?.social_prioritize ? patient.social_name : patient?.name,
    },
    {
      name: "Mãe",
      field: "mother_name",
      type: "text",
    },
    {
      name: "Data de nascimento",
      field: "birth_date",
      use: (birth_date) => {
        if (birth_date)
          return format(parseISO(birth_date), "dd/MM/yyyy", {
            locale: ptBR,
          });
      },
      type: "date",
    },
  ];
  const additionalFilters = [
    {
      filter: "physic_national",
      name: "CPF",
      type: "text",
    },
    {
      filter: "ident_national",
      name: "Registro geral",
      type: "text",
    },
    {
      filter: "sus_card",
      name: "CNS",
      type: "text",
    },
  ];

  async function tokenPatientAssociate(patient_id) {
    updateToken(state.token_audit_id, { patient_id });
    goBack();
  }

  async function handleShow(id) {
    setOpen(true);
    setEmployee(
      patientListQuery.data.items.find((employee) => employee.id === id)
    );
  }
  async function handleUpdate(id) {
    navigate("/attendance/patient/" + id, { replace: true });
  }

  function handleFilter(filter) {
    queryClient.cancelQueries([`patientlist`]);
    setPage(0);
    setFilter(filter);
  }

  React.useEffect(() => {
    patientListQuery.refetch();
  }, [filter]);

  return (
    <BoxContainer title={"Lista de Pacientes"}>
      <>
        <Dialog
          open={open}
          title="Informações do Paciente"
          handleClose={() => setOpen(false)}
        >
          <PatientFeedback data={employee} showVisualization={true} />
        </Dialog>
        <TableFilter
          data={patientListQuery.data?.items}
          actions
          othersFilter={additionalFilters}
          emptyMessage={patientListQuery.error?.message}
          columns={tableColumns}
          handleFilter={handleFilter}
          loading={patientListQuery.isFetching && patientListQuery.isLoading}
          disableActions={(_, action) => {
            return action === "edit" && !permission.update;
          }}
          actionUniqueIdentifier={"id"}
          actionsTypes={["view", "edit", state?.token_audit_id ? "finish" : ""]}
          actionHandleView={handleShow}
          actionHandleEdit={handleUpdate}
          actionHandleFinish={(data) => tokenPatientAssociate(data)}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={patientListQuery.data?.totalItems}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          disabled={!permission.create}
          onClick={() => {
            navigate("/attendance/patient/new", {
              replace: true,
              state: { ...state },
            });
          }}
        >
          Novo Cadastro
        </Button>
      </>
    </BoxContainer>
  );
}
export default PatientList;
