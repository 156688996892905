import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import {
  drugOnSitePrescriptionPDF,
  drugPrescriptionPDF,
  openURL,
  sadtPDF,
} from "../../pdfModels";
import {
  EditRounded,
  VisibilityRounded,
  AssignmentOutlined,
  DoneRounded,
  LocalPrintshopRounded,
} from "@mui/icons-material";
import { ConfirmDialog } from "../../helper";
import { useState } from "react";

const infoActions = {
  view: {
    title: "Visualizar",
    icon: <VisibilityRounded />,
    confirmation: false,
  },
  edit: { title: "Editar", icon: <EditRounded />, confirmation: false },
  response: {
    title: "Responder",
    icon: <AssignmentOutlined />,
    confirmation: false,
  },
  finish: { title: "Finalizar", icon: <DoneRounded />, confirmation: true },
  print: {
    title: "Imprimir",
    icon: <LocalPrintshopRounded />,
    confirmation: false,
  },
};

export default function PrescriptionInfo({
  info,
  statusField,
  type,
  patientData,
  actions = [],
  exam_type,
  confirmMessage = "Você realmente deseja *action* este item?",
}) {
  const [message, setMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDialogConfig, setConfirmDialogConfig] = useState(null);

  const documentData = {
    createdAt: info.createdAt,
    employee: {
      ...info.employee_specialty?.employee,
      specialty: info.employee_specialty?.specialty,
    },
    company: info.company,
  };

  function printDrugPrescriptions() {
    if (info.signed_medications) return openURL(info.signed_medications.result);

    const pdf = drugPrescriptionPDF(
      info.medications,
      patientData,
      documentData
    );
    pdf.open();
  }

  function printDrugOnSitePrescriptions() {
    if (info.signed_meds_on_site)
      return openURL(info.signed_meds_on_site.result);

    const pdf = drugOnSitePrescriptionPDF(
      info.medications_on_site,
      patientData,
      documentData
    );
    pdf.open();
  }

  function printexamPrescriptions() {
    if (info.signed_sadts?.[exam_type])
      return openURL(info.signed_sadts?.[exam_type]?.result);
    const pdf = sadtPDF(
      exam_type,
      { sids: [], prescriptions: info.exams },
      patientData,
      true,
      documentData
    );
    pdf.open();
  }

  const printer = {
    exams: printexamPrescriptions,
    medications: printDrugPrescriptions,
    medications_on_site: printDrugOnSitePrescriptions,
  };

  const newActions = [
    {
      name: "print",
      handler: () => {
        printer[type]();
      },
    },
    ...actions,
  ];

  const status = {
    ongoing: "Em aberto",
    "partially finished": "Parcialmente finalizado",
    finished: "Finalizado",
  };

  const togleDialog = () => {
    setOpenDialog((openDialog) => !openDialog);
  };

  const handleMessage = (row, action) => {
    const message = confirmMessage.replace("action", action);
    const splitedMessage = message.split(" ").map((str) => {
      if (str.match(/({\D+})/gi)) {
        const replacer = str.replace(/[()?]/g, "");
        const value = eval(replacer.replace(/[{}]/g, ""));
        const string = str.replace(replacer, value);
        return string;
      } else {
        return str;
      }
    });
    const completedMessage = splitedMessage.join(" ");
    setMessage(completedMessage);
  };

  return (
    <Box sx={{ flex: 1 }}>
      <Grid container xs={12} alignItems="center">
        <Grid item xs={1}>
          Nº {info.number}
        </Grid>
        <Grid item xs={2}>
          Solicitado em: {new Date(info.createdAt).toLocaleDateString()}
        </Grid>
        <Grid item xs={3}>
          Profissional : {info.employee_specialty?.employee.name}
        </Grid>
        <Grid item xs={3}>
          Status : {status[info[statusField]]}
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 1,
            paddingRight: "1rem",
          }}
        >
          {newActions.map(
            ({ name, handler, cancelHandler, disableAction }, index) => (
              <Tooltip
                key={`${name}-${index}`}
                placement="top"
                title={infoActions[name].title}
                onClick={(event) => event.stopPropagation()}
              >
                <Box component="span">
                  <IconButton
                    sx={(theme) => ({
                      color: theme.palette.primary.medium,
                    })}
                    disabled={
                      disableAction instanceof Function
                        ? disableAction(info)
                        : false
                    }
                    onClick={() => {
                      if (infoActions[name].confirmation) {
                        handleMessage(
                          info,
                          infoActions[name].title.toLowerCase()
                        );

                        setConfirmDialogConfig({
                          confirmAction: () => handler(info),
                          cancelAction: () => {
                            if (cancelHandler instanceof Function) {
                              cancelHandler(info);
                            }
                          },
                        });
                        togleDialog();
                      } else {
                        handler(info);
                      }
                    }}
                  >
                    {infoActions[name].icon}
                  </IconButton>
                </Box>
              </Tooltip>
            )
          )}
        </Grid>
      </Grid>
      <ConfirmDialog
        message={message}
        hideBackDrop={false}
        open={openDialog}
        maxWidth="sm"
        fullWidth={true}
        handleClose={togleDialog}
        actions
        handleConfirm={confirmDialogConfig?.confirmAction}
        handleCancel={confirmDialogConfig?.cancelAction}
      />
    </Box>
  );
}
