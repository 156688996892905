import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/intervention";

export default function useInterventions() {
  async function getInterventions(filter) {
    try {
      const response = await instance.get(path, { params: urlQuery(filter) });
      return response?.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }
  async function updateInterventions(id, data) {
    try {
      const response = await instance.patch(`${path}/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getInterventions,
    updateInterventions,
  };
}
