function cleanerString(value, character, replacers) {
  let formattedValue = null;

  if (!!value) {
    formattedValue = value;
    replacers.forEach((replacer) => {
      formattedValue = formattedValue.replaceAll(replacer, character);
    });
  }
  return formattedValue;
}

export default cleanerString;
