import React from "react";
import { BoxContainer } from "../../../components";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import CarePlanFeedback from "./CarePlanFeedback";
import CarePlanForm from "./CarePlanForm";
import { Dialog } from "../../../helper";
import { Button, Box, TablePagination } from "@mui/material";
import useCarePlan from "../../../service/useCarePlan";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { format, parseISO } from "date-fns";
import usePermissions from "../../../config/usePermissions";
import useNotifier from "../../../hooks/useNotifier";

function CarePlanList() {
  // Hooks
  const notify = useNotifier();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [carePlans, setCarePlans] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [carePlan, setCarePlan] = React.useState({});
  const [displayCarePlan, setDisplayCarePlan] = React.useState(false);
  const [editCarePlan, setEditCarePlan] = React.useState(false);
  const [registerCarePlan, setRegisterCarePlan] = React.useState(false);
  const { getCarePlans, putCarePlan } = React.useMemo(useCarePlan, []);
  const permission = usePermissions("/program/carePlan");

  // Requests
  const queryClient = useQueryClient();

  const suspendCarePlanMutation = useMutation(putCarePlan);

  const carePlansQuery = useQuery(
    ["carePlans", [page, limit]],
    () => getCarePlans({ page, limit }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (response) => {
        setCarePlans(response.items);
        setCount(response.totalItems);

        const formatedCarePlans = response.items.map(
          ({
            number,
            name,
            suspendedAt,
            describe,
            exams,
            medications,
            services,
          }) => {
            const types = [
              { value: exams, type: "Exames" },
              { value: medications, type: "Medicamentos" },
              { value: services, type: "Consultas" },
            ].filter((type) => !!type.value.length);

            const type = types.reduce((acc, current, key, array) => {
              if (key === array.length - 1) {
                return acc + current.type;
              } else {
                return acc + current.type + ", ";
              }
            }, "");

            return {
              number,
              name,
              type,
              suspendedAt: !!suspendedAt
                ? format(parseISO(suspendedAt), "dd/MM/yyyy")
                : "",
              describe,
              suspended: !!suspendedAt,
            };
          }
        );
        setRows(formatedCarePlans);
      },
      onError: (error) => {
        setCarePlans([]);
        notify(error.message, "error");
      },
    }
  );

  function handleSuspend(carePlan) {
    suspendCarePlanMutation.mutate(
      { id: carePlan.id, data: { suspended: true } },
      {
        onSuccess: (success) => {
          notify(success.message, "success");
          queryClient.invalidateQueries("carePlans");
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  }

  function handleCloseDialog() {
    setDisplayCarePlan(false);
    setEditCarePlan(false);
    setRegisterCarePlan(false);
  }

  const tableColumns = [
    { name: "Cód. Plano" },
    { name: "Nome" },
    { name: "Tipo de Plano" },
    { name: "Suspenso em" },
    { name: "Descrição" },
  ];

  return (
    <>
      <Dialog
        open={displayCarePlan}
        title={"Visualização Plano de Cuidado"}
        handleClose={handleCloseDialog}
      >
        <CarePlanFeedback data={carePlan} />
      </Dialog>
      <Dialog
        open={editCarePlan}
        title={"Edição Plano de Cuidado"}
        handleClose={handleCloseDialog}
      >
        <CarePlanForm
          type="edit"
          data={carePlan}
          handleClose={handleCloseDialog}
        />
      </Dialog>
      <Dialog
        open={registerCarePlan}
        title={"Plano de Cuidados"}
        handleClose={handleCloseDialog}
      >
        <CarePlanForm type="register" handleClose={handleCloseDialog} />
      </Dialog>
      <BoxContainer title="Plano de Cuidado">
        <PaginatedTable
          data={rows}
          columns={tableColumns}
          actions
          actionsTypes={["view", "edit", "suspend"]}
          actionHandleEdit={(i) => {
            setCarePlan(carePlans[i]);
            setEditCarePlan(true);
          }}
          actionHandleSuspend={(i) => {
            handleSuspend(carePlans[i]);
          }}
          shouldDisableFields={["suspended"]}
          disableRows={(row) => row.suspended}
          disableActions={(row, action) =>
            ((row.suspended || !permission.update) && action === "suspend") ||
            ((row.suspended || !permission.update) && action === "edit")
          }
          actionHandleView={(i) => {
            setCarePlan(carePlans[i]);
            setDisplayCarePlan(true);
          }}
          confirmMessage="Você realmente deseja *action* este plano de cuidado?"
          loading={carePlansQuery.isRefetching}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={count}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          disabled={!permission.create}
          onClick={() => setRegisterCarePlan(true)}
        >
          Novo Cadastro
        </Button>
      </BoxContainer>
    </>
  );
}

export default CarePlanList;
