import React, { useContext, useMemo, useState } from "react";
import { Tabs } from "../../../components";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import { TablePagination, Box } from "@mui/material";
import { useQuery } from "react-query";
import { usePatient } from "../../../service";
import { AppContext } from "../../../contexts/AppContext";
import { format, parseISO } from "date-fns";
import {
  followUpStatementPDF,
  medicalStatementPDF,
  attendanceStatementPDF,
  medicalReportPDF,
  medicalOpinionPDF,
  maternityLeavePDF,
  openURL,
} from "../../../pdfModels";
import useNotifier from "../../../hooks/useNotifier";

function HistoryDeclaration({ patient }) {
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [type, setType] = useState("Atestado");
  const { patientData } = useContext(AppContext);
  const { getDeclarations } = useMemo(usePatient, []);
  const declarationsPrinters = {
    Atestado: medicalStatementPDF,
    Acompanhamento: followUpStatementPDF,
    Comparecimento: attendanceStatementPDF,
    Relatorio: medicalReportPDF,
    Laudo: medicalOpinionPDF,
    AtestadoMaternidade: maternityLeavePDF,
  };

  // Reequets
  const declarationsQuery = useQuery(
    ["declarations", [page, limit, type]],
    () =>
      getDeclarations(patient?.id || patientData.id, {
        page,
        limit,
        type,
      }),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: { items: [] },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const rows = declarationsQuery.data.items.map(
    ({ createdAt, soap, signed }) => ({
      created_at: format(parseISO(createdAt), "dd/MM/yyyy"),
      employee: soap?.employee_specialty.employee.name,
      signed,
    })
  );

  // handle funcrions
  function printDeclaration(index) {
    const declaration = declarationsQuery.data?.items[index];

    if (declaration?.signature) {
      openURL(declaration.signature.result);
    } else {
      const pdf = declarationsPrinters[type](
        declaration,
        patient || patientData
      );
      pdf.open();
    }
  }

  //config table
  const tableColumns = [
    { name: "Criado em" },
    { name: "Profissional responsável" },
  ];

  const tabs = [
    {
      label: "Atestado",
    },
    {
      label: "Acompanhamento",
    },
    {
      label: "Comparecimento",
    },
    {
      label: "Relatório",
      value: "Relatorio",
    },
    {
      label: "Laudo",
    },
    {
      label: "Atestado Maternidade",
      value: "AtestadoMaternidade",
    },
  ];

  return (
    <>
      <Tabs
        tabs={tabs}
        handleClick={(type) => {
          setType(type.value ?? type.label);
          setPage(0);
        }}
        sx={{ marginBottom: 1 }}
      />
      <PaginatedTable
        data={rows}
        columns={tableColumns}
        loading={declarationsQuery.isRefetching}
        actions
        actionsTypes={["print"]}
        confirmMessage="Você realmente deseja *action* este documento?"
        actionHandlePrint={printDeclaration}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={declarationsQuery.data?.totalItems ?? 0}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}

export default HistoryDeclaration;
