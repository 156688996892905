import React from "react";
import { Box, Typography } from "@mui/material";

function NotFound() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        color={"primary"}
        sx={{ fontSize: "4rem", fontFamily: "Montserrat,sans-serif" }}
      >
        404
      </Typography>
      <Typography
        sx={{ fontSize: "1.2em", fontFamily: "Montserrat,sans-serif" }}
      >
        A página solicitada não existe ou você não tem permissão para acessa-la.
      </Typography>
    </Box>
  );
}

export default NotFound;
