import React from "react";
import Box from "@mui/material/Box";
import { BoxContainer, Tabs } from "../../../components";
import ServiceFormRegister from "./ServiceFormRegister";
import ProviderFormRegister from "./ProviderFormRegister";

export default function ReportRegister() {
  const tabs = [
    { label: "Serviços", content: <ServiceFormRegister /> },
    { label: "Profissionais", content: <ProviderFormRegister /> }
  ];

  return (
    <BoxContainer title={"Relatórios Cadastrais"} sx={{ width: "100%" }}>
      <Tabs tabs={tabs} />
    </BoxContainer>
  );
}
