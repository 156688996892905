import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import {
  openURL,
  drugOnSitePrescriptionPDF,
  drugPrescriptionPDF,
  medicalStatementPDF,
  attendanceStatementPDF,
  followUpStatementPDF,
  againstReferencePDF,
  specialPrescriptionPDF,
  medicalReportPDF,
  medicalOpinionPDF,
  maternityLeavePDF,
  // lmePDF,
  capsPDF,
} from "../../pdfModels";
import DialogMedium from "../../helper/DialogMedium";
import { Box, Button } from "@mui/material";
import sadtPDF from "../../pdfModels/sadtPDF";
import { getUserData } from "../../pdfModels/pdfComponents";

function PrintDocsDialog({ open, handleClose, odonto, ceo }) {
  const {
    drugOnSitePrescriptions,
    drugPrescriptions,
    examPrescriptions,
    declarations,
    againstReference,
    signedDocuments,
    soapDocumentData,
    // drugLMEPrescriptions,
    patientData,
    specialPrescriptions,
  } = useContext(AppContext);
  const { prescription, documents, against_references } = signedDocuments;
  const transferUBSRequest = "4318ef76-8340-48dc-ba45-c7bd6fd3f513"; // Encaminhamento UBS
  const userData = getUserData();
  const rxExams = examPrescriptions?.filter(
    ({ describe, type }) =>
      describe.includes("RADIOGRAFIA") && type === "Imagem"
  );
  const imageExams = examPrescriptions?.filter(
    ({ type, describe }) =>
      type === "Imagem" && !describe.includes("RADIOGRAFIA")
  );

  function printSADT(examType, exams, order) {
    if (!!prescription.signed_sadts?.[examType]) {
      if (examType === "images") {
        openURL(prescription.signed_sadts.images[order]?.result);
      }

      openURL(prescription.signed_sadts[examType].result);
    } else {
      const pdf = sadtPDF(
        examType === "images" ? "xray" : examType,
        {
          cids: soapDocumentData?.assessment?.cids,
          prescriptions: exams ? exams : examPrescriptions,
        },
        patientData,
        true
      );
      pdf?.open();
    }
  }

  function printDrugPrescriptions() {
    if (!!prescription?.signed_medications) {
      openURL(prescription.signed_medications.result);
    } else {
      const pdf = drugPrescriptionPDF(drugPrescriptions, patientData);
      pdf.open();
    }
  }

  function printSpecialPrescriptions(special_prescription, order) {
    if (!!prescription?.signed_special_medications) {
      openURL(prescription.signed_special_medications?.[order].result);
    } else {
      const pdf = specialPrescriptionPDF([special_prescription], patientData);
      pdf.open();
    }
  }

  function printDrugOnSitePrescriptions() {
    if (!!prescription.signed_meds_on_site) {
      openURL(prescription.signed_meds_on_site.result);
    } else {
      const pdf = drugOnSitePrescriptionPDF(
        drugOnSitePrescriptions,
        patientData
      );
      pdf.open();
    }
  }
  // async function printLMEPrescription() {
  //   const pdf = lmePDF(
  //     soapDocumentData,
  //     drugLMEPrescriptions,
  //     patientData,
  //     userData
  //   );
  //   pdf.open();
  // }

  function printDeclarations(declaration) {
    if (declaration.type === "Comparecimento")
      attendanceStatementPDF(declaration, patientData).open();
    if (declaration.type === "Atestado")
      medicalStatementPDF(declaration, patientData, odonto, ceo).open();
    if (declaration.type === "Acompanhamento")
      followUpStatementPDF(declaration, patientData).open();
    if (declaration.type === "Relatorio")
      medicalReportPDF(declaration, patientData).open();
    if (declaration.type === "Laudo")
      medicalOpinionPDF(declaration, patientData).open();
    if (declaration.type === "AtestadoMaternidade")
      maternityLeavePDF(declaration, patientData).open();
  }

  function printCounterReference(reference) {
    againstReferencePDF(
      reference,
      patientData,
      reference?.cids_object || []
    ).open();
  }

  function printCapsReport(reference) {
    if (!!userData.caps) {
      capsPDF(reference, patientData, soapDocumentData).open();
    }
  }

  function hasExamByType(examType, hasRX) {
    return soapDocumentData?.plan?.prescription?.exams?.some(
      ({ type, describe }) => {
        if (hasRX) return type === examType && describe.includes("RADIOGRAFIA");

        return type === examType;
      }
    );
  }

  return (
    <DialogMedium
      open={open}
      fullWidth={true}
      maxWidth="lg"
      title={"Imprimir documento"}
      handleClose={handleClose}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {rxExams?.length > 0 && (
          <Button variant="outlined" onClick={() => printSADT("xray", rxExams)}>
            SADT(Raio-x)
          </Button>
        )}
        {imageExams?.map((exam, index) => (
          <Button
            variant="outlined"
            onClick={() => printSADT("images", [exam], index)}
          >
            SADT(Imagem) {index + 1}
          </Button>
        ))}
        {hasExamByType("Laboratorial") && (
          <Button variant="outlined" onClick={() => printSADT("lab")}>
            SADT(Laboratorial)
          </Button>
        )}
        {(!!prescription?.signed_medications ||
          !!drugPrescriptions?.length) && (
          <Button variant="outlined" onClick={printDrugPrescriptions}>
            Receituário
          </Button>
        )}
        {specialPrescriptions?.map((prescription, index) => (
          <Button
            variant="outlined"
            onClick={() => printSpecialPrescriptions(prescription, index)}
          >
            Receituário Especial {index + 1}
          </Button>
        ))}
        {(!!prescription?.signed_meds_on_site ||
          !!drugOnSitePrescriptions?.length) && (
          <Button variant="outlined" onClick={printDrugOnSitePrescriptions}>
            Medicamentos
          </Button>
        )}
        {/* {!!drugLMEPrescriptions.length && (
          <Button variant="outlined" onClick={printLMEPrescription}>
            LME
          </Button>
        )} */}
        {againstReference?.map((reference, index) => {
          const signedReference = against_references[index];
          if (reference.id_specialty === transferUBSRequest) {
            return (
              <Button
                variant="outlined"
                onClick={() => {
                  printCapsReport(reference);
                }}
              >
                Relatório de referência e Contrarreferência - SAÚDE MENTAL{" "}
                {index}
              </Button>
            );
          }

          return (
            <Button
              variant="outlined"
              onClick={
                !!signedReference?.signature
                  ? () => openURL(signedReference.signature.result)
                  : () => printCounterReference(reference)
              }
            >
              Referência / Contrarreferência {index + 1}
            </Button>
          );
        })}
        {declarations?.map((declaration, index) => {
          const signedDeclaration = documents[index];

          return (
            <Button
              variant="outlined"
              onClick={
                !!signedDeclaration?.signature
                  ? () => openURL(signedDeclaration.signature.result)
                  : () => printDeclarations(declaration)
              }
            >
              {declaration.type} {index + 1}
            </Button>
          );
        })}
      </Box>
    </DialogMedium>
  );
}

export default PrintDocsDialog;
