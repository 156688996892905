import { Box, Button, TablePagination } from "@mui/material";
import { Dialog } from "../../../helper";
import React from "react";
import useMonitoring from "../../../service/useMonitoring";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format, parseISO } from "date-fns";
import AgainstReferencesTable from "../AgainstReferences/AgainstReferencesTable";
import PatientListTable from "./PatientListTable";
import { PatientInfo } from "../PatientInfo";
import useNotifier from "../../../hooks/useNotifier";

export default function AgainstReferencesPatientList({ patientId }) {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [referencesPage, setReferencesPage] = React.useState(0);
  const [references, setReferences] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState({});
  const [limitOfReferences, setLimitOfReferences] = React.useState(10);
  const queryClient = useQueryClient();
  const [selectedMonitoring, setSelectedMonitoring] = React.useState();
  const {
    getPatientAgainstReferences,
    updateWorkList,
    updateAgainstReference,
    getMonitorings,
  } = useMonitoring();

  const notify = useNotifier();
  const updateWorkListStatusMutation = useMutation(updateWorkList);
  const patientListQuery = useQuery(
    ["getPatientsWithAgainstReferences"],
    () =>
      getMonitorings({
        page,
        work_list_status: patientId ? "finished" : "unfinished",
        ...searchFilter,
        limit,
        type: "against_reference",
        id_patient: patientId,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {
        queryClient.setQueriesData("getPatientsWithAgainstReferences", []);
      },
    }
  );

  const referencesQuery = useQuery(
    ["getPatientAgainstReferences", [selectedMonitoring]],
    () => {
      if (selectedMonitoring)
        return getPatientAgainstReferences(
          selectedMonitoring?.attendance.patient.id,
          {
            page: referencesPage,
            limit: limitOfReferences,
            ignore_specialties: true,
          }
        );
    },
    {
      onSuccess: (data) => {
        setReferences(
          data?.items.map(
            ({
              createdAt,
              soap,
              requested_specialty,
              number,
              priority,
              id,
              referred,
              signature,
            }) => {
              const dateReference = format(parseISO(createdAt), "dd/MM/yyyy");
              return {
                number,
                dateReference,
                requestor_name: soap.employee_specialty.employee.name,
                requested_specialty: requested_specialty.describe,
                priority,
                referred,
                signature,
                id,
              };
            }
          )
        );
      },
    }
  );

  React.useEffect(() => {
    referencesQuery.refetch();
  }, [selectedMonitoring, referencesPage, limitOfReferences]);

  React.useEffect(() => {
    patientListQuery.refetch();
  }, [searchFilter]);

  async function check(_, item) {
    const res = await updateAgainstReference(item.id, { referred: true });
    referencesQuery.refetch();
  }

  function handleClose() {
    setOpenDialog(false);
    setSelectedMonitoring();
    patientListQuery.refetch();
  }

  function handleView(index) {
    const {
      requested_employee,
      requested_specialty,
      priority,
      justification,
      number,
      response,
      id,
      createdAt,
      soap,
      signature,
    } = referencesQuery.data?.items?.[index];
    return {
      requestor_name: soap.employee_specialty.employee.name,
      requested_employee,
      requested_specialty: requested_specialty.describe,
      priority,
      justification,
      number,
      response,
      signature,
      id,
      dataReference: format(parseISO(createdAt), "dd/MM/yyyy"),
      patient: soap?.attendance?.patient,
      cids: soap?.assessment?.cids,
      soap,
    };
  }

  const updateWorkListStatus = async (
    attendanceID,
    work_list_status = "finished"
  ) => {
    updateWorkListStatusMutation.mutate(
      { attendanceID, changes: { against_reference: work_list_status } },
      {
        onSuccess: () => {
          patientListQuery.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  };
  return (
    <Box sx={{ pt: 5 }}>
      <Button
        variant="contained"
        sx={{
          marginBottom: "10px",
        }}
        disabled={patientListQuery.isLoading}
        onClick={() => {
          notify("Lista Atualizada");
          patientListQuery.refetch();
        }}
      >
        Atualizar
      </Button>
      <Dialog
        title={"Encaminhamentos"}
        open={openDialog}
        handleClose={handleClose}
      >
        <PatientInfo attendance={selectedMonitoring?.attendance} />
        <AgainstReferencesTable
          againstReferences={references}
          check={check}
          handleView={handleView}
        />

        <TablePagination
          count={referencesQuery.data?.totalItems || 0}
          component="div"
          page={referencesPage}
          onPageChange={(_, newPage) => {
            setReferencesPage(newPage);
          }}
          rowsPerPage={limitOfReferences}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimitOfReferences(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Dialog>
      <PatientListTable
        noEvolutionStatus={true}
        noOutstanding={true}
        data={patientListQuery.data?.items}
        loading={patientListQuery.isFetching}
        error={patientListQuery.failureCount}
        callType={"against_reference"}
        actionsTypes={patientId ? ["checklist", "response"] : undefined}
        {...{
          page,
          setSearchFilter,
          limit,
          setLimit,
          setPage,
          setOpenDialog,
          setSelectedMonitoring,
          updateWorkListStatus,
        }}
        totalItems={patientListQuery.data?.totalItems}
      />
    </Box>
  );
}
