import { useTheme } from "@emotion/react";
import React from "react";
import { DialogMedium } from "../../../helper";
import {
  useHealthInsurance,
  useHealthInsurancePlan,
  usePagination,
} from "../../../service";
import { useMutation, useQuery } from "react-query";
import Yup from "../../../config/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Box, Button, Grid, TablePagination, Typography } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import {
  TextField,
  SwitchField,
  AutocompleteField,
} from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

export default function HealthPlanStep() {
  const theme = useTheme();
  const notify = useNotifier();

  const [selectHealthPlan, setSelectHealthPlan] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [limit, setLimit] = React.useState(5);
  const [healthInsurances, setHealthInsurances] = React.useState([]);

  const {
    getHealthInsurancePlans,
    postHealthInsurancePlan,
    updateHealthInsurancePlan,
  } = useHealthInsurancePlan();
  const { getHealthInsurances } = useHealthInsurance();

  const createHealthPlanMutation = useMutation(postHealthInsurancePlan);
  const updateHealthPlanMutation = useMutation(updateHealthInsurancePlan);

  const healthPlanQuery = useQuery(
    ["health-insurance-plan"],
    getHealthInsurancePlans,
    {
      retry: false,
      initialData: [],
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  useQuery(
    ["healthInsurance"],
    () => getHealthInsurances({ filter: "active" }),
    {
      retry: false,
      initialData: [],
      onSuccess: (data) => {
        setHealthInsurances(data);
      },
    }
  );

  const { paginationInfo, handlePageChange } = usePagination(
    healthPlanQuery.data,
    limit
  );

  const columns = [
    {
      field: "number",
      name: "Código",
      type: "string",
    },
    {
      field: "describe",
      name: "Plano",
      type: "string",
    },
    {
      field: "insurance",
      name: "Convênio",
      type: "string",
      use: (value) => (value ? value.describe : "Não informado"),
    },
    {
      field: "active",
      name: "Status",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
    },
  ];

  const validator = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
    id_health_insurance: Yup.object().required("É requerido").nullable(),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      describe: "",
    },
    resolver: yupResolver(validator),
  });

  const editHealthPlanForm = useForm({
    defaultValues: {
      describe: selectHealthPlan?.describe,
      id_health_insurance: selectHealthPlan?.insurance,
      active: selectHealthPlan?.active,
    },
    resolver: yupResolver(validator),
  });

  const handleCloseDialog = () => {
    setEdit(false);
    setSelectHealthPlan({});
  };

  const onSubmit = handleSubmit((values) => {
    const dataSend = {
      ...values,
      id_health_insurance: values.id_health_insurance?.id,
    };

    createHealthPlanMutation.mutate(dataSend, {
      onSuccess: (data) => {
        notify(data.message, "success");
        reset();
        healthPlanQuery.refetch();
      },
      onError: (err) => {
        notify(err.message, "error");
      },
    });
  });

  const onEditSubmit = editHealthPlanForm.handleSubmit((values) => {
    const dataSend = {
      ...values,
      id_health_insurance: values.id_health_insurance?.id,
    };
    updateHealthPlanMutation.mutate(
      { id: selectHealthPlan.id, data: dataSend },
      {
        onSuccess: (data) => {
          notify(data.message, "success");
          reset();
          setEdit(false);
          setSelectHealthPlan({});
          healthPlanQuery.refetch();
        },
        onError: (err) => {
          notify(err.message, "error");
        },
      }
    );
  });

  return (
    <>
      <DialogMedium
        open={edit}
        fullWidth
        maxWidth="sm"
        title="Edição de Plano de Saúde"
        handleClose={handleCloseDialog}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              control={editHealthPlanForm.control}
              name="describe"
              label="Descrição"
              required
            />
          </Grid>
          <Grid item xs={8}>
            <AutocompleteField
              control={editHealthPlanForm.control}
              name="id_health_insurance"
              label="Convênio"
              options={healthInsurances}
              optionLabelKey="describe"
              required
            />
          </Grid>
          <Grid item xs={3}>
            <SwitchField
              control={editHealthPlanForm.control}
              name="active"
              label="Alterar Status"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={onEditSubmit}
              onLoad={updateHealthPlanMutation.isLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Planos de Saúde
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items}
          columns={columns}
          loading={healthPlanQuery.isLoading}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit"]}
          shouldDisableFields={["id"]}
          actionHandleEdit={(_, item) => {
            editHealthPlanForm.setValue("describe", item.describe);
            editHealthPlanForm.setValue("active", item.active);
            editHealthPlanForm.setValue("id_health_insurance", item.insurance);
            setSelectHealthPlan(item);
            setEdit(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Plano de Saúde
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            control={control}
            name={"describe"}
            label={"Descrição"}
            required
          />
        </Grid>
        <Grid item xs={5}>
          <AutocompleteField
            control={control}
            name="id_health_insurance"
            label="Convênio"
            options={healthInsurances}
            optionLabelKey="describe"
            required
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={onSubmit}
            onLoad={createHealthPlanMutation.isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
