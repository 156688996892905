import { Box } from "@mui/material";
import { useRef } from "react";
import { useState } from "react";
import { BoxContainer } from "../../../components";
import TStepper from "../../../components/TStepper";

import FinishStep from "./FinishStep";
import FirstStep from "./FisrtStep";
import SecondStep from "./SecondStep";

function AttendanceConfirmForm() {
  const [scheduling, setScheduling] = useState({});
  const [confirmationData, setConfirmationData] = useState({});

  const stepperRef = useRef();

  function HandleFinishFirstStep(data = {}) {
    stepperRef.current.next();
    setScheduling(data);
  }

  function HandleFinishSecondStep(data = {}) {
    setConfirmationData(data);
    setScheduling(data.scheduling);
    stepperRef.current.next();
  }

  function stepBack() {
    stepperRef.current.previous();
  }

  function stepReset() {
    stepperRef.current.reset();
  }

  const steps = [
    {
      label: "Paciente",
      body: <FirstStep handleFinish={HandleFinishFirstStep} />,
    },
    {
      label: "Atendimentos",
      body: (
        <SecondStep
          scheduling={scheduling}
          handleFinish={HandleFinishSecondStep}
          handleBack={stepBack}
        />
      ),
    },
    {
      label: "Confirmação",
      body: (
        <FinishStep
          confirm={confirmationData}
          handleBack={stepBack}
          handleReset={stepReset}
        />
      ),
    },
  ];

  return (
    <BoxContainer
      title="Confirmação de Atendimento"
      style={{ display: "flex" }}
    >
      <Box sx={{ width: "100%" }}>
        <TStepper steps={steps} ref={stepperRef} />
      </Box>
    </BoxContainer>
  );
}

export default AttendanceConfirmForm;
