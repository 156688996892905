import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/locations";

export default function useLocations() {
    async function getLocations(params) {
        try {
          const response = await instance.get(path, { params: urlQuery(params) });
          return response.data;
        } catch (err) {
          throw new Error(err.response.data.message);
        }
      }
      return{
        getLocations
      }
}
