import React from "react";
import {
  ResponsiveContainer,
  RadialBarChart,
  RadialBar,
  PolarAngleAxis,
} from "recharts";
import PropTypes from "prop-types";
import { Box, useTheme } from "@mui/material";

function PercentageChart({ data, size = 50 }) {
  const theme = useTheme();

  return (
    <>
      <Box width={size} height={size}>
        <ResponsiveContainer width={"110%"} height={"105%"}>
          <RadialBarChart
            innerRadius="110%"
            outerRadius="150%"
            data={data}
            startAngle={90}
            endAngle={450}
            cx="50%"
            cy="50%"
          >
            <RadialBar
              minAngle={15}
              label={{
                fontSize: "12x",
                fontWeight: "500",
                fill: `${theme.palette.primary.light}`,
                position: "center",
                formatter: (value) => value + "%",
              }}
              fill={theme.palette.primary.main}
              background
              clockWise={false}
              cornerRadius={50}
              isAnimationActive={true}
              angleAxisId={0}
              dataKey="percentage"
            />
            <PolarAngleAxis
              domain={[0, 100]}
              tick={false}
              angleAxisId={0}
              type="number"
            />
          </RadialBarChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}

PercentageChart.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  size: PropTypes.oneOfType([PropTypes.number]),
};

export default PercentageChart;
