import { Box, Grid, TablePagination } from "@mui/material";
import React from "react";
import { CheckBox } from "@mui/icons-material";
import { useMutation, useQuery } from "react-query";
import { useState } from "react";
import useScreens from "../../../../service/useScreens";
import useRoles from "../../../../service/useRoles";
import { useProvider } from "../../../../service";
import useNotifier from "../../../../hooks/useNotifier";
import TableFilter from "../../../../components/Table/TableFilter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  PaginatedAutocompleteField,
  SwitchField,
} from "../../../../components/FormFields";
import Yup from "../../../../config/yup";

const ScreenPermisions = ({ role, employee }) => {
  const notify = useNotifier();
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  const { getRoleScreens, postRoleScreens, deleteRoleScreens } = useRoles();
  const { getScreens } = useScreens();
  const { getProviderScreens, postProviderScreens, deleteProviderScreens } =
    useProvider();

  const insertRoleMutation = useMutation(postRoleScreens);
  const insertProviderMutation = useMutation(postProviderScreens);
  const deleteRoleMutation = useMutation(deleteRoleScreens);
  const deleteProviderMutation = useMutation(deleteProviderScreens);
  const getScreenDataQuery = useQuery(
    ["get-screens-data", [limit, page, employee]],
    getScreensData,
    {
      keepPreviousData: false,
      initialData: {
        items: [],
        totalItems: 0,
      },
      enabled: !!role,
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const validations = Yup.object().shape({
    screen: Yup.object().required("Escolha uma tela").nullable(),
    read: Yup.boolean().required("É requerido."),
    create: Yup.boolean(),
    update: Yup.boolean(),
    delete: Yup.boolean(),
  });

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      screen: null,
      read: true,
      create: false,
      update: false,
      delete: false,
    },
    resolver: yupResolver(validations),
  });

  const read = watch("read");

  async function getScreensData() {
    if (role) {
      return getRoleScreens(role?.id, { limit, page });
    }
    if (employee) {
      return getProviderScreens(employee.id, { limit, page });
    }
  }

  function onSubmit({ screen, ...actions }) {
    const insertion = {
      screen_id: screen.id,
      actions: actions.read ? actions : {},
    };

    if (role) {
      insertRoleMutation.mutate(
        { roleID: role.id, insertion },
        {
          onSuccess: (success) => {
            notify(success.message, "success");
            getScreenDataQuery.refetch();
            reset();
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    }

    if (employee) {
      insertProviderMutation.mutate(
        { providerID: employee.id, insertion },
        {
          onSuccess: (success) => {
            notify(success.message, "success");
            getScreenDataQuery.refetch();
            reset();
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    }
  }

  function handleDelete(deletion) {
    if (role) {
      deleteRoleMutation.mutate(
        { roleID: role.id, deletion },
        {
          onSuccess: (success) => {
            notify(success.message, "success");
            getScreenDataQuery.refetch();
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    }
    if (employee) {
      deleteProviderMutation.mutate(
        { providerID: employee.id, deletion },
        {
          onSuccess: (success) => {
            notify(success.message, "success");
            getScreenDataQuery.refetch();
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    }
  }

  function returnIcon(active) {
    return <CheckBox color={active ? "primary" : "disabled"} />
  }

  const tableColumns = [
    {
      name: "Tela",
      field: "describe",
      use: (describe) => describe.firstCharToUp(),
    },
    { name: "Visualizar", field: "actions?.read", use: returnIcon },
    { name: "Cadastrar", field: "actions?.create", use: returnIcon },
    { name: "Editar", field: "actions?.update", use: returnIcon },
    { name: "Deletar", field: "actions?.delete", use: returnIcon },
  ];

  return (
    <Grid container xs={12} spacing={2} width="100%">
      <Grid
        container
        item
        xs={12}
        spacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid xs={12} item>
          <PaginatedAutocompleteField
            name="screen"
            control={control}
            label="Nome da Tela"
            filterKey="describe"
            optionCompareKey="id"
            autoCompleteProps={{
              getOptionLabel: (option) => option.describe.firstCharToUp(),
            }}
            queryKey="get-screens-by-permissions"
            service={getScreens}
          />
        </Grid>
        <Grid
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10%",
            paddingTop: "3%",
          }}
          item
        >
          <SwitchField name="read" label="Visualizar" control={control} />
          <SwitchField
            name="create"
            label="Cadastrar"
            control={control}
            disabled={!read}
          />
          <SwitchField
            name="update"
            label="Editar"
            control={control}
            disabled={!read}
          />
          <SwitchField
            name="delete"
            label="Deletar"
            control={control}
            disabled={!read}
          />
          <Button type="submit">Cadastrar</Button>
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <TableFilter
          data={getScreenDataQuery.data?.items ?? []}
          loading={getScreenDataQuery.isFetching}
          columns={tableColumns}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["delete"]}
          shouldDisableFields={["id"]}
          actionHandleDelete={handleDelete}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={getScreenDataQuery.data?.totalItems ?? 0}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ScreenPermisions;
