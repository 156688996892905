import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/sectors";
function useSector() {
  async function getSectors(params = {}) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function getSector(id) {
    try {
      const response = await instance.get(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postSector(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function updateSector({ id, data }) {
    try {
      const response = await instance.put(`${path}/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getSectors,
    getSector,
    postSector,
    updateSector,
  };
}

export default useSector;
