import { useTheme } from "@emotion/react";
import React from "react";
import { DialogMedium} from "../../../helper";
import useTotem from "../../../service/useTotem";
import { useMutation, useQuery } from "react-query";
import { usePagination } from "../../../service";
import { format, parseISO } from "date-fns";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../config/yup";
import { Box, Button, Grid, TablePagination, Typography } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import { TextField, SwitchField } from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

export default function NewTabPrinter() {
  const theme = useTheme();
  const notify = useNotifier();

  const [edit, setEdit] = React.useState(false);
  const [printer, setPrinter] = React.useState(null);
  const [limit, setLimit] = React.useState(5);

  const { getPrinters, postPrinter, updatePrinter } = useTotem();

  const createPrinterMutation = useMutation(postPrinter);
  const updatePrinterMutation = useMutation(updatePrinter);

  const printerQuery = useQuery(["printers"], () => getPrinters(), {
    retry: false,
    initialData: [],
  });

  const { handlePageChange, paginationInfo } = usePagination(
    printerQuery.data,
    limit
  );

  const columns = [
    {
      field: "name",
      name: "Nome",
      width: 250,
      type: "string",
    },
    {
      field: "model",
      name: "Modelo",
      type: "string",
    },
    {
      field: "active",
      name: "Ativo",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
    },
    {
      field: "createdAt",
      name: "Criado em",
      type: "date",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado",
    },
    {
      field: "updatedAt",
      name: "Atualizado em",
      type: "date",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado",
    },
  ];

  const validations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
    model: Yup.string().required("É requerido"),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      model: "",
    },
    resolver: yupResolver(validations),
  });

  const editPrinterForm = useForm({
    defaultValues: {
      name: printer?.name,
      model: printer?.model,
      active: printer?.active,
    },
    resolver: yupResolver(validations),
  });

  const handleCloseDialog = () => {
    setEdit(false);
    setPrinter(null);
  };

  const onSubmit = handleSubmit((values) => {
    createPrinterMutation.mutate(values, {
      onSuccess: (response) => {
        setPrinter(null);
        notify(response.message, "success");
        reset();
        printerQuery.refetch();
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    });
  });

  const onEditSubmit = editPrinterForm.handleSubmit((values) => {
    updatePrinterMutation.mutate(
      { id: printer.id, data: values },
      {
        onSuccess: (response) => {
          setPrinter(null);
          notify(response.message, "success");
          editPrinterForm.reset();
          printerQuery.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  });

  return (
    <>
      <DialogMedium
        open={edit}
        fullWidth
        maxWidth="sm"
        title="Edição de Procedência"
        handleClose={handleCloseDialog}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              control={editPrinterForm.control}
              name="name"
              label="Nome"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              control={editPrinterForm.control}
              name="model"
              label="Modelo"
              required
            />
          </Grid>
          <Grid item xs={10}>
            <SwitchField
              control={editPrinterForm.control}
              name="active"
              label="Alterar Status"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              onClick={onEditSubmit}
              onLoad={updatePrinterMutation.isLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Impressoras
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items}
          columns={columns}
          loading={printerQuery.isLoading}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit"]}
          shouldDisableFields={["id"]}
          actionHandleEdit={(_, item) => {
            setPrinter(item);
            editPrinterForm.setValue("name", item.name);
            editPrinterForm.setValue("active", item.active);
            editPrinterForm.setValue("model", item.model);
            setEdit(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Impressora
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField control={control} name={"name"} label={"Nome"} required />
        </Grid>
        <Grid item xs={5}>
          <TextField
            control={control}
            name={"model"}
            label={"Modelo"}
            required
          />
        </Grid>

        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={onSubmit}
            onLoad={createPrinterMutation.isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
