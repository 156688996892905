import { Box, Typography } from "@mui/material";
import { mapObject } from "../../../../utils";

export default function ThriftErrorFeedback({ error, errorTypes }) {
  return (
    <Box>
      {mapObject(errorTypes, (value, key) => {
        if (error[key]) {
          return (
            <Box display={'flex'} flexDirection={'column'} paddingY={2} rowGap={1}> 
              <Typography fontSize={16} color={'primary.dark'}>{value}</Typography>
              <Box display={'flex'} flexDirection={'column'} rowGap={1}>

              {error[key].map((message) => (
                  <Typography fontSize={12} component={'span'} color={'primary.light'} >{message.firstCharToUp()}</Typography>
                  ))}
                  </Box>
            </Box>
          );
        }
      })}
    </Box>
  );
}
