import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { FileField } from "../../../../components/FormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../../config/yup";
import useThrift from "../../../../service/useThrift";
import { useMutation } from "react-query";
import { formDataObject, Loading } from "../../../../helper";
import { useNotifier } from "../../../../hooks";

export default function SendUpdateFile({ handleClose }) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      attachments: null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        attachments: Yup.mixed()
          .required("Escolha o arquivo a ser enviado.")
          .nullable(),
      })
    ),
  });
  const notify = useNotifier();
  const { sendUpdateFile } = useThrift();
  const sendFileMutation = useMutation({
    mutationFn: sendUpdateFile,
    mutationKey: "send-update-file",
  });

  const handleOnSubmit = handleSubmit((values) => {
    const data = formDataObject(values);

    sendFileMutation.mutate(data, {
      onSuccess(data) {
        notify(data.message, "success");
        handleClose(false);
      },
      onError(data) {
        notify(data.message, "error");
      },
    });
  });
  return (
    <>
      <Box
        component={"form"}
        onSubmit={handleOnSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 4,
        }}
      >
        {sendFileMutation.isLoading ? (
          <Loading
            maxWidth={"100%"}
            backgroundColor={"rgba(255,255,255,0.7)"}
            message={"Enviando Arquivo..."}
          />
        ) : null}
        <FileField
          control={control}
          name={"attachments"}
          label={"Escolha o arquivo a ser enviado"}
          acceptedFileTypes={[".zip"]}
        />
        <Button variant="contained" type="submit">
          Enviar
        </Button>
      </Box>
    </>
  );
}
