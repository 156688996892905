import { Box } from "@mui/material";
import { Tabs } from "../../../components";
import PrescriptionList from "./PrescriptionList";

function PrescriptionHistory({ patient, drugsPermission, examsPermission }) {
  const tabs = [
    {
      active: drugsPermission,
      label: "Receituários",
      content: <PrescriptionList type="medications" patient={patient} />,
    },
    {
      active: drugsPermission,
      label: "Prescrições de Medicamentos",
      content: (
        <PrescriptionList type="medications_on_site" patient={patient} />
      ),
    },
    {
      active: examsPermission,
      label: "Prescrições de Exames",
      content: <PrescriptionList type="exams" patient={patient} />,
    },
  ];

  return (
    <Box
      sx={{
        marginY: 1,
        marginX: "auto",
        maxWidth: "85%",
      }}
    >
      <Tabs tabs={tabs.filter((i) => i.active)} />
    </Box>
  );
}

export default PrescriptionHistory;
