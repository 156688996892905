import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";
import { format, parseISO } from "date-fns";
import { logoSPSUS, writeRotatedText } from "./pdfComponents";
import { formatPhoneNumber } from "../utils";

function sadtPDF(
  examType,
  data,
  patient,
  shouldPrintEmployeeInfo = false,
  documentData
) {
  const exam_types = {
    lab: "Laboratorial",
    xray: "Imagem",
    ecg: "Imagem",
  };
  const diagnostic = data?.prescriptions?.reduce((str, exam) => {
    if (exam.diagnostic_hypothesis) {
      return `${!!str ? `${str}, ` : str} ${exam.diagnostic_hypothesis}`;
    }

    return str;
  }, "");

  const exams = data?.prescriptions?.reduce(
    (str, { describe, type, notes }) => {
      if (exam_types[examType] === type) {
        return str + `${describe} , `;
      }

      return str;
    },
    ""
  );
  const segment_areas = data.prescriptions?.reduce((str, { segment_area }) => {
    if (segment_area) {
      return str + ` | ${segment_area} `;
    }
    return str;
  }, "");
  function quebrarLinha(texto, limite = 255) {
    const regex = new RegExp(`.{1,${limite}}|\\([^\\)]+\\)`, "g");
    const linhas = texto.match(regex);
    return linhas.join("\n");
  }

  if (exams === "") {
    return false;
  }

  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const layout = {
    paddingLeft: function (i, node) {
      return 0;
    },
    paddingRight: function (i, node) {
      return 0;
    },
    paddingTop: function (i, node) {
      return 0;
    },
    paddingBottom: function (i, node) {
      return 0;
    },
    defaultBorder: false,
  };

  const etnOptions = {
    Negro: "PRETA",
    Branco: "BRANCA",
    Amarelo: "AMARELA",
    Indígena: "INDIGENA",
    Pardo: "PARDA",
  };

  const userData = documentData
    ? documentData.employee
    : JSON.parse(localStorage.getItem("user-data"));

  const company = documentData?.company || userData?.company;

  const employee = shouldPrintEmployeeInfo
    ? {
        crm: `${
          userData?.council?.description ||
          userData?.employee_Type?.council?.description
        } ${
          userData?.council?.council_registration_number ||
          userData?.council_registration_number ||
          ""
        } `,
        specialty: userData?.specialty?.describe ,
        name: userData.name,
      }
    : "";

  const etnText = Object.keys(etnOptions)
    .map((a) => `( ${a === patient?.race ? "X" : ""} )  ` + etnOptions[a])
    .join("   ");

  const street = patient?.contact?.street || "";
  const district = patient?.contact?.district;
  const address_number = patient?.contact?.address_number;
  const county = patient?.contact?.county || "";
  const state = patient?.contact?.state || "";
  const cell_phone = patient?.contact?.cell_phone || "";

  const url = `${window.QRCODES_URL.replace(
    /\/$/,
    ""
  )}/tportal/validate/${pdfName}/types/sadt`;

  var dd = {
    pageSize: "A4",
    pageMargins: [25, 30, 10, 0],
    content: [
      {
        table: {
          widths: Array.from(Array(24).fill("*")),
          heights: Array.from(Array(24).fill(29).fill(70, 0, 1)),
          body: [
            Array.from(
              Array(24)
                .fill({})
                .fill(
                  {
                    colSpan: 24,
                    border: [1, 1, 1, 1],
                    stack: [
                      {
                        table: {
                          widths: [85, 103, 100, "*"],
                          body: [
                            [
                              logoSPSUS(),
                              {
                                margin: [25, 10, 0, 0],
                                fontSize: 6,
                                stack: [
                                  {
                                    text: "PREFEITURA DO MUNICÍPIO DE SÃO PAULO",
                                  },
                                  { text: "SECRETARIA MUNICIPAL DE SAÚDE" },
                                ],
                              },
                              {
                                margin: [10, 4, 0, 0],
                                stack: [
                                  {
                                    table: {
                                      widths: [15, "*"],
                                      heights: [15, 5, 15, 5, 15, 5],
                                      body: [
                                        [
                                          {
                                            border: [1, 1, 1, 1],
                                            alignment: "center",
                                            text: `${
                                              examType === "lab" ? "X" : ""
                                            }`,
                                          },
                                          {
                                            text: "ANALISES CLÍNICAS",
                                            margin: [5, 2, 0, 0],
                                            fontSize: 6,
                                          },
                                        ],
                                        [{}, {}],
                                        [
                                          {
                                            border: [1, 1, 1, 1],
                                            alignment: "center",
                                            text: `${
                                              examType === "xray" ? "X" : ""
                                            }`,
                                          },
                                          {
                                            text: "RADIOLOGIA",
                                            border: [0, 0, 0, 0],
                                            margin: [5, 2, 0, 0],
                                            fontSize: 6,
                                          },
                                        ],
                                        [{}, {}],
                                        [
                                          {
                                            border: [1, 1, 1, 1],
                                            alignment: "center",
                                            text: `${
                                              examType !== "xray" &&
                                              examType !== "lab"
                                                ? "X"
                                                : ""
                                            }`,
                                          },
                                          {
                                            text: "OUTROS",
                                            border: [0, 0, 0, 0],
                                            margin: [5, 2, 0, 0],
                                            fontSize: 6,
                                          },
                                        ],
                                      ],
                                    },
                                    layout: {
                                      defaultBorder: 0,
                                      ...layout,
                                    },
                                  },
                                ],
                              },
                              {
                                stack: [
                                  {
                                    table: {
                                      widths: ["*"],
                                      body: [
                                        [
                                          {
                                            text: "GUIA DE ENCAMINHAMENTO\nSADT",
                                            alignment: "center",
                                            fontSize: 13,
                                            margin: [0, 4, 0, 0],
                                            border: [0, 0, 0, 0],
                                          },
                                        ],
                                        [
                                          {
                                            border: [1, 1, 0, 0],
                                            margin: [0, 0, 0, 0],
                                            stack: [
                                              {
                                                table: {
                                                  widths: [
                                                    "*",
                                                    "*",
                                                    "*",
                                                    "*",
                                                    "*",
                                                    "*",
                                                    "*",
                                                    "*",
                                                    "*",
                                                  ],
                                                  heights: [5],
                                                  body: [
                                                    [
                                                      {
                                                        text: "PRONTUÁRIO",
                                                        fontSize: 6,
                                                        border: [0, 0, 0, 0],
                                                        margin: [1, 0, 0, 0],
                                                        colSpan: 9,
                                                      },
                                                      {},
                                                      {},
                                                      {},
                                                      {},
                                                      {},
                                                      {},
                                                      {},
                                                      {},
                                                    ],
                                                    [
                                                      {
                                                        text: "Nº",
                                                        border: [0, 0, 1, 0],
                                                        margin: [0, 0, 0, 0],
                                                      },
                                                      ...Array(8)
                                                        .fill()
                                                        .map((_, i) => ({
                                                          text:
                                                            patient?.number
                                                              ?.toString()
                                                              .split("")[i] ||
                                                            "",
                                                          border: [1, 0, 1, 0],
                                                          margin: [0, 0, 0, 0],
                                                          alignment: "center",
                                                        })),
                                                    ],
                                                  ],
                                                },
                                                layout: {
                                                  ...layout,
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          ],
                        },
                        layout: {
                          ...layout,
                          defaultBorder: 0,
                        },
                      },
                    ],
                  },
                  0,
                  1
                )
            ),
            Array.from(
              Array(24)
                .fill({})
                .fill(
                  {
                    stack: [
                      {
                        margin: [1, 0, 0, 0],
                        text: "Nome do Paciente",
                        fontSize: 8,
                        bold: 1,
                      },
                      {
                        margin: [1, 5, 0, 0],
                        fontSize: 8,
                        text: patient?.social_prioritize
                          ? patient?.social_name
                          : patient?.name,
                      },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 15,
                  },
                  0,
                  1
                )
                .fill(
                  {
                    stack: [
                      {
                        margin: [1, 0, 0, 0],
                        text: "Data de Nascimento",
                        fontSize: 8,
                        bold: 1,
                      },
                      {
                        margin: [1, 5, 0, 0],
                        fontSize: 6,
                        text: patient?.birth_date
                          ? format(parseISO(patient?.birth_date), "dd/MM/yyyy")
                          : null,
                      },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 9,
                  },
                  15,
                  16
                )
            ),
            Array.from(
              Array(24)
                .fill({})
                .fill(
                  {
                    stack: [
                      {
                        margin: [1, 0, 0, 0],
                        text: "CNS",
                        fontSize: 8,
                        bold: 1,
                      },
                      {
                        margin: [1, 5, 0, 0],
                        fontSize: 6,
                        text: patient?.sus_card,
                      },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 12,
                  },
                  0,
                  1
                )
                .fill(
                  {
                    stack: [
                      {
                        margin: [1, 0, 0, 0],
                        text: "RAÇA E COR",
                        fontSize: 8,
                        bold: 1,
                        alignment: "center",
                      },
                      {
                        margin: [1, 5, 0, 0],
                        fontSize: 6,
                        text: etnText,
                        alignment: "center",
                      },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 12,
                  },
                  12,
                  13
                )
            ),
            Array.from(
              Array(24)
                .fill({})
                .fill(
                  {
                    stack: [
                      {
                        margin: [1, 0, 0, 0],
                        text: "ENDEREÇO",
                        fontSize: 8,
                        bold: 1,
                      },
                      {
                        margin: [1, 5, 0, 0],
                        fontSize: 6,
                        text: `${street}${district ? "," + district : ""} ${
                          address_number ? "-" + address_number : ""
                        }`,
                      },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 11,
                  },
                  0,
                  1
                )
                .fill(
                  {
                    stack: [
                      {
                        margin: [1, 0, 0, 0],
                        text: "MUNICÍPIO",
                        fontSize: 8,
                        bold: 1,
                      },
                      { margin: [1, 5, 0, 0], fontSize: 6, text: county },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 6,
                  },
                  11,
                  12
                )
                .fill(
                  {
                    stack: [
                      {
                        margin: [1, 0, 0, 0],
                        text: "ESTADO",
                        fontSize: 8,
                        bold: 1,
                      },
                      { margin: [1, 5, 0, 0], fontSize: 6, text: state },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 2,
                  },
                  17,
                  18
                )
                .fill(
                  {
                    stack: [
                      {
                        margin: [1, 0, 0, 0],
                        text: "TELEFONE",
                        fontSize: 8,
                        bold: 1,
                      },
                      { margin: [1, 5, 0, 0], fontSize: 6, text: cell_phone },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 5,
                  },
                  19,
                  24
                )
            ),
            Array.from(
              Array(24)
                .fill({})
                .fill(
                  {
                    stack: [
                      {
                        text: "HIPOTESE DIAGNÓSTICA",
                        margin: [1, 0, 0, 0],
                        fontSize: 8,
                        bold: 1,
                      },
                      {
                        margin: [1, 5, 0, 0],
                        fontSize: 8,
                        text: diagnostic || "Não informado",
                      },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 24,
                  },
                  0,
                  1
                )
            ),
            Array.from(
              Array(24)
                .fill({})
                .fill(
                  {
                    stack: [
                      {
                        text: "AREA E/OU SEGMENTO ANATÔMICO A SER EXAMINADO",
                        margin: [1, 0, 0, 0],
                        fontSize: 8,
                        bold: 1,
                      },
                      {
                        text: segment_areas,
                        fontSize: 8,
                        margin: [2, 0, 0, 0],
                      },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 24,
                    rowSpan: 2,
                  },
                  0,
                  1
                )
            ),
            Array.from(Array(24).fill({})),
            Array.from(
              Array(24)
                .fill({})
                .fill(
                  {
                    stack: [
                      {
                        text: "EXAMES OU SERVIÇOS SOLICITADOS",
                        fontSize: 8,
                        margin: [1, 0, 0, 0],
                        bold: 1,
                      },
                      {
                        table: {
                          heights: 100,
                          body: [
                            [
                              {
                                fontSize: 8,
                                margin: [2, 0, 0, 0],
                                text: exams
                                  ? quebrarLinha(exams.replace(/, $/g, ""), 170)
                                  : "Não Informado",
                              },
                            ],
                          ],
                        },
                        layout: "noBorders",
                      },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 24,
                    rowSpan: 2,
                  },
                  0,
                  1
                )
            ),
            Array.from(Array(24).fill({})),
            Array.from(
              Array(24)
                .fill({})
                .fill(
                  {
                    stack: [
                      {
                        text: "ASSINATURA CRM E CARIMBO DO MÉDICO",
                        margin: [0, 0, 0, 55],
                        alignment: "center",
                        fontSize: 8,
                        bold: 1,
                      },
                      {
                        margin: [0, 0, 0, 55],
                        alignment: "center",

                        fontSize: 9,
                        text: employee
                          ? `${employee.name} \n\n ${employee.specialty} \n ${employee.crm}`
                          : "",
                      },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 11,
                    rowSpan: 2,
                  },
                  0,
                  1
                )
                .fill(
                  {
                    stack: [
                      {
                        text: "DATA DE EMISSÃO",
                        margin: [1, 0, 0, 0],
                        fontSize: 8,
                        bold: 1,
                      },
                      { fontSize: 8, text: format(new Date(), "dd/MM/yyyy") },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 5,
                  },
                  11,
                  12
                )
                .fill(
                  {
                    stack: [
                      {
                        text: "UNIDADE SOLICITANTE",
                        alignment: "center",
                        fontSize: 8,
                        bold: 1,
                      },
                      {
                        fontSize: 6,
                        margin: [3, 0, 0, 0],
                        text: `${company?.name || ""} | ${
                          company?.street || ""
                        },n° ${company?.address_number || ""}, ${
                          company?.county || ""
                        } - ${company?.uf || ""} | CEP: ${
                          company?.address_cep || ""
                        } - Telefone: ${formatPhoneNumber(company?.phone)}`,
                      },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 8,
                  },
                  16,
                  17
                )
            ),
            Array.from(
              Array(24)
                .fill({})
                .fill(
                  {
                    stack: [
                      {
                        text: "ENCAMINHADO PARA",
                        margin: [1, 0, 0, 0],
                        fontSize: 8,
                        bold: 1,
                      },
                      { text: "" },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 13,
                  },
                  11,
                  12
                )
            ),
            Array.from(
              Array(24)
                .fill({})
                .fill(
                  {
                    stack: [
                      {
                        text: "AUTORIZADO QUANDO SOLICITADO",
                        alignment: "center",
                        margin: [0, 10, 0, 0],
                        fontSize: 8,
                        bold: 1,
                      },
                      { text: "" },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 9,
                  },
                  0,
                  1
                )
                .fill(
                  {
                    stack: [
                      {
                        text: "ENDEREÇO",
                        margin: [1, 0, 0, 0],
                        fontSize: 8,
                        bold: 1,
                      },
                      { text: "" },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 9,
                    rowSpan: 3,
                  },
                  9,
                  10
                )
            ),
            Array.from(
              Array(24)
                .fill({})
                .fill(
                  {
                    stack: [
                      {
                        text: "CARIMBO E ASSINATURA",
                        margin: [1, 0, 0, 0],
                        fontSize: 8,
                        bold: 1,
                      },
                      { text: "" },
                    ],
                    border: [1, 0, 1, 1],
                    colSpan: 9,
                    rowSpan: 2,
                  },
                  0,
                  1
                )
            ),
            Array.from(Array(24).fill({})),
          ],
        },
        layout,
      },
      {
        margin: [0, 0, 0, 0],
        qr: url,
        version: 12,
        fit: "160",
        eccLevel: "H",
        absolutePosition: { x: 455, y: 600 },
      },
      writeRotatedText({
        text: [
          `Este documento é cópia do original, assinado digitalmente por ${userData.name}.`,
          `Para conferir o original acesse o site https://validar.iti.gov.br, e utilize o Validar (QRcode) disponível no documento e insira os primeiros quatro dígitos do seu CPF.`,
        ],
        fontSize: "8px",
        rotate: "-90deg",
        absolutePosition: { x: 2, y: 740 },
      })
    ],
  };

  const documentPDF = pdfMake.createPdf(dd);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default sadtPDF;
