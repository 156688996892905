import { Box, Grid, IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import { Add } from "@mui/icons-material";
import { useContext, useState } from "react";
import Accordion from "../../../../components/Accordion";
import TableFilter from "../../../../components/Table/TableFilter";
import { AppContext } from "../../../../contexts/AppContext";
import { useForm } from "react-hook-form";
import {
  Button,
  DateField,
  PaginatedAutocompleteField,
  SelectField,
  TextField,
} from "../../../../components/FormFields";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../../config/yup";
import useAnestheticTechnique from "../../../../service/useAnestheticTechnique";

const anesthetic_types_options = [
  { describe: "LIDOCAÍNA 2%" },
  { describe: "PRILOCAÍNA 3%" },
  { describe: "MEPIVACAÍNA 2%" },
  { describe: "MEPIVACAÍNA 3%" },
  { describe: "ARTICAÍNA 4%" },
];

export default function Anesthesia({ handleSave }) {
  const { setFieldValue, values: soapValues } = useFormikContext();
  const { disableFields, setAnesthesia } = useContext(AppContext);
  const [anestheticTypes, setAnestheticTypes] = useState(
    soapValues.anesthesia?.types || []
  );
  const [sterilizationTypes, setSterilizationTypes] = useState(
    soapValues.anesthesia?.sterilization || []
  );

  const { getAnestheticTechniques } = useAnestheticTechnique();

  const initialValuesTypes = {
    anesthetic_technique: null,
    typesDescribe: null,
    typesBatch: "",
    typesExpirationDate: null,
    typesQuantity: "",
  };

  const initialValuesSterilization = {
    sterilizationDescribe: "",
    sterilizationBatch: "",
    sterilizationExpirationDate: null,
  };

  const typesValidations = Yup.object().shape({
    anesthetic_technique: Yup.object().nullable().required("É requerido"),
    typesDescribe: Yup.object().nullable().required("É requerido"),
    typesBatch: Yup.string().required("É requerido"),
    typesExpirationDate: Yup.date().nullable().required("É requerido"),
    typesQuantity: Yup.string().nullable().required("É requerido"),
  });

  const sterilizationValidations = Yup.object().shape({
    sterilizationDescribe: Yup.string().required("É requerido"),
    sterilizationBatch: Yup.string().required("É requerido"),
    sterilizationExpirationDate: Yup.date().nullable().required("É requerido"),
  });

  const typesForm = useForm({
    resolver: yupResolver(typesValidations),
    defaultValues: initialValuesTypes,
  });

  const sterilizationForm = useForm({
    resolver: yupResolver(sterilizationValidations),
    defaultValues: initialValuesSterilization,
  });

  const [
    anesthetic_technique,
    typesDescribe,
    typesBatch,
    typesExpirationDate,
    typesQuantity,
  ] = typesForm.watch([
    "anesthetic_technique",
    "typesDescribe",
    "typesBatch",
    "typesExpirationDate",
    "typesQuantity",
  ]);

  const [
    sterilizationDescribe,
    sterilizationBatch,
    sterilizationExpirationDate,
  ] = sterilizationForm.watch([
    "sterilizationDescribe",
    "sterilizationBatch",
    "sterilizationExpirationDate",
  ]);

  function handleAddTypes() {
    const values = {
      anesthetic_technique,
      typesDescribe,
      typesBatch,
      typesExpirationDate,
      typesQuantity,
    };
    setAnestheticTypes((curr) => [...curr, values]);
    typesForm.reset();
  }

  function handleAddSterilization() {
    const values = {
      sterilizationDescribe,
      sterilizationBatch,
      sterilizationExpirationDate,
    };

    setSterilizationTypes((curr) => [...curr, values]);
    sterilizationForm.reset();
  }

  function handleRemoveSterilization(index) {
    const newValues = sterilizationTypes?.filter((_, i) => i !== index);
    setSterilizationTypes(newValues);
  }

  function handleRemoveAnestheticTypes(index) {
    const newValues = anestheticTypes?.filter((_, i) => i !== index);
    setAnestheticTypes(newValues);
  }

  function addHandleSubmit() {
    const toSend = {
      types: anestheticTypes,
      sterilization: sterilizationTypes,
    };
    setAnesthesia(toSend);
    setFieldValue("anesthesia", toSend);
    handleSave();
  }
  const AddedViewItems = ({
    title,
    data,
    handleRemove,
    defaultOpen = true,
    removeAnestheticType = false,
  }) => {
    return (
      <Accordion
        defaultOpen={true}
        configs={[
          {
            title,
            body: (
              <TableFilter
                data={data}
                noFilter
                actions
                actionsTypes={["delete"]}
                actionHandleDelete={handleRemove}
                columns={[
                  !removeAnestheticType && {
                    name: "Técnica Anestésica",
                    field: "anesthetic_technique?.describe",
                  },
                  {
                    name: !removeAnestheticType
                      ? "Tipo Anestésico"
                      : "Tipo Instrumental",
                    field: !removeAnestheticType
                      ? "typesDescribe.describe"
                      : "sterilizationDescribe",
                  },
                  !removeAnestheticType && {
                    name: "Quantidade",
                    field: "typesQuantity",
                  },
                  {
                    name: "Lote",
                    field: !removeAnestheticType
                      ? "typesBatch"
                      : "sterilizationBatch",
                  },
                  {
                    name: "Validade",
                    field: !removeAnestheticType
                      ? "typesExpirationDate"
                      : "sterilizationExpirationDate",
                    use: (typesExpirationDate, sterilizationExpirationDate) => {
                      if (typesExpirationDate || sterilizationExpirationDate)
                        return format(
                          typesExpirationDate || sterilizationExpirationDate,
                          "dd/MM/yyyy",
                          {
                            locale: ptBR,
                          }
                        );
                      else {
                        return "Não informado";
                      }
                    },
                  },
                ]}
              />
            ),
          },
        ]}
      />
    );
  };

  return (
    <Grid paddingTop={4}>
      <Grid container xs={12} item spacing={2}>
        <Grid item xs={12}>
          <PaginatedAutocompleteField
            control={typesForm.control}
            name={"anesthetic_technique"}
            label={"Técnica anestésica"}
            service={getAnestheticTechniques}
            optionLabelKey="describe"
            filterKey={"describe"}
            queryKey={"get-anesthetic-technique"}
            required
          />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={5}>
            <SelectField
              control={typesForm.control}
              name={"typesDescribe"}
              label={"Tipo de anestésico"}
              options={anesthetic_types_options}
              optionLabelKey="describe"
              required
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              control={typesForm.control}
              name={"typesQuantity"}
              type={"number"}
              label={"Quantidade"}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              control={typesForm.control}
              name={"typesBatch"}
              label={"Lote"}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <DateField
              control={typesForm.control}
              name={"typesExpirationDate"}
              label={"Validade"}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={typesForm.handleSubmit(handleAddTypes)}>
              <Add color="primary" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <AddedViewItems
              title={"Tipos de anestésicos adicionados"}
              data={anestheticTypes}
              handleRemove={handleRemoveAnestheticTypes}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={7}>
            <TextField
              control={sterilizationForm.control}
              name={"sterilizationDescribe"}
              label={"Esterilização instrumental"}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              control={sterilizationForm.control}
              name={"sterilizationBatch"}
              label={"Lote"}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <DateField
              control={sterilizationForm.control}
              name={"sterilizationExpirationDate"}
              label={"Validade"}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={sterilizationForm.handleSubmit(handleAddSterilization)}
            >
              <Add color="primary" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <AddedViewItems
              title={"Instrumentais"}
              removeAnestheticType
              data={sterilizationTypes}
              handleRemove={handleRemoveSterilization}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" margin="1.5rem auto">
          <Button
            type="submit"
            onClick={addHandleSubmit}
            disabled={disableFields}
          >
            Salvar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
