import React, { useContext } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import usePermissions from "../../config/usePermissions";
import { AuthContext } from "../../contexts/AuthContext";
import { Loading } from "../../helper";
import HasNoPermission from "./HasNoPermission";

function RequireAuth() {
  const location = useLocation();
  const permissions = usePermissions();

  const authContext = useContext(AuthContext);

  function RedirectTo({ validator }) {
    return (
      <>
        {validator === true ? (
          <Outlet />
        ) : validator === false ? (
          <Navigate to="/login" state={{ from: location }} replace />
        ) : (
          <Loading message={validator} />
        )}
      </>
    );
  }

  function HasAccess({ children }) {
    function verifyAccessPerimision() {
      if (
        permissions.read ||
        (permissions.parent?.create && permissions.parent?.from === "new") ||
        (permissions.parent?.update && permissions.parent?.from) ||
        location.pathname === "/" ||
        location.pathname === "/hasNoPermission"
      ) {
        return children;
      }

      return <HasNoPermission />;
    }

    return <>{verifyAccessPerimision()}</>;
  }

  return (
    <>
      <HasAccess>
        <RedirectTo validator={authContext.valid} />
      </HasAccess>
    </>
  );
}

export default React.memo(RequireAuth);
