import { useForm } from "react-hook-form";
import { TextField } from "../../../../../components/FormFields";
import { Button, Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../../../config/yup";

export default function DoubleCheckSuspendForm({onSuspend,toSuspend,handleClose}) {
  const form = useForm({
    defaultValues: {
      cancelation_notes: "",
    },
    resolver: yupResolver(Yup.object().shape({
      cancelation_notes: Yup.string().required("Campo é requerido.")
    }))
  });

  const handleSubmit = form.handleSubmit((values) => {
    onSuspend(toSuspend,values)
    handleClose()
  })
  return (
    <Grid container spacing={2} component={'form'} onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <TextField
          control={form.control}
          name="cancelation_notes"
          label="Justificativa de suspensão"
          required
          multiline
          minRows={8}
        />
      </Grid>
      <Grid item xs={2}>
        <Button type="submit" variant="contained">
          Suspender
        </Button>
      </Grid>
    </Grid>
  );
}
