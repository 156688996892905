import instance from "../config/api";
const path = "against-references/";

function useAgainstReferences() {
  async function getTotalAgainstReferences(page = 1, limit = 5) {
    try {
      const response = await instance.get(
        path + `?page=${page}&limit=${limit}`
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function putReferenceResponse(objectResponse) {
    try {
      const response = await instance.put(
        path + objectResponse.id + "/answer",
        { response: objectResponse.response }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function myReferences(page = 1, limit = 5) {
    try {
      const response = await instance.get(
        path + `?page=${page}&limit=${limit}&created_by=me`
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function suspendReference(id) {
    try {
      const response = await instance.put(path + id + "/suspend");
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  return {
    getTotalAgainstReferences,
    putReferenceResponse,
    myReferences,
    suspendReference,
  };
}
export default useAgainstReferences;
