import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/scales";
const path2 = "/scales/days";

function useScale() {
  async function postScale(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function putScale({ data, id }) {
    try {
      const response = await instance.put(
        `/scales/days/schedules/update/${id}`,
        data
      );
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function getScale() {
    try {
      const response = await instance.get(path);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getScaleId(id) {
    try {
      const response = await instance.get(`/scales/days/schedules${id}`);
      return response;
    } catch (err) {
      throw new Error(err.response.data);
    }
  }

  async function updateScheduleStatus({ id, status }) {
    try {
      const response = await instance.put(`/schedules/update/${id}/status`, {
        active: status,
      });
      return response;
    } catch (err) {
      return err;
    }
  }

  async function getScalesDays(params) {
    try {
      const response = await instance.get(`${path2}/dates`, { params });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  }
  async function getScalesServices(params) {
    try {
      const response = await instance.get(`${path2}/services`, { params });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  }

  async function getScalesTreatmentTypes(params) {
    try {
      const response = await instance.get(`${path2}/treatment-types`, {
        params,
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  }

  async function getScalesHours(params) {
    try {
      const response = await instance.get(`${path2}/only-schedules`, {
        params,
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  }

  async function getScalesEmployees(params) {
    try {
      const response = await instance.get(`${path2}/employees`, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  }

  async function getScalesSectors(params) {
    try {
      const response = await instance.get(`${path2}/sectors`, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  }

  return {
    postScale,
    putScale,
    getScale,
    getScalesDays,
    getScalesServices,
    getScalesTreatmentTypes,
    getScalesEmployees,
    getScalesSectors,
    getScalesHours,
    getScaleId,
    updateScheduleStatus,
  };
}
export default useScale;
