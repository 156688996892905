import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import { useCompany } from "../../../service/useCompany";
import { useQuery } from "react-query";
import { DownloadButton } from "../../../components/Form";

export default function RegulationViewModal({ schedule }) {
  const { getCompanyByID } = useCompany();

  const companyQuery = useQuery(
    ["company", schedule?.company_id],
    () => getCompanyByID(schedule.company_id),
    {
      enabled: !!schedule?.company_id,
      retry: false,
    }
  );

  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
        }}
      >
        <Typography
          variant="p"
          color="secondary"
          fontWeight="600"
          fontSize={15}
          sx={{ ml: 2 }}
        >
          Paciente - {schedule?.patient?.name}
        </Typography>
        <br />
        <Typography
          variant="p"
          color="secondary"
          fontWeight="600"
          fontSize={15}
          sx={{ ml: 2 }}
        >
          Cartão SUS -{" "}
          {schedule?.patient?.sus_card
            ? schedule?.patient?.sus_card
            : "Não informado"}
        </Typography>
        <br />
        <Typography
          variant="p"
          color="secondary"
          fontWeight="600"
          fontSize={15}
          sx={{ ml: 2 }}
        >
          Data de Nascimento -{" "}
          {format(parseISO(schedule.patient.birth_date), "dd/MM/yyyy")}
        </Typography>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", mt: "-15px" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Data de Solicitação
                    </Typography>{" "}
                    :{" "}
                    {schedule.createdAt
                      ? format(parseISO(schedule.createdAt), "dd/MM/yyyy")
                      : "Não informado"}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Data de Agendamento
                    </Typography>{" "}
                    :{" "}
                    {schedule.scheduling_date
                      ? `${format(
                          parseISO(schedule.scheduling_date),
                          "dd/MM/yyyy"
                        )} ${schedule.hour ? schedule.hour : ""}`
                      : "Não informado"}
                  </Typography>

                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Especialidade/Procedimento
                    </Typography>{" "}
                    :{" "}
                    {schedule?.specialty?.describe
                      ? schedule?.specialty?.describe
                      : schedule?.procedure?.name
                      ? schedule?.procedure?.name
                      : "Não informado"}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      CID
                    </Typography>{" "}
                    : {schedule?.cid_id ? schedule?.cid_id : "Não informado"}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Unidade
                    </Typography>{" "}
                    :{" "}
                    {!!schedule.company_id && companyQuery?.data?.name
                      ? companyQuery?.data.name
                      : "Não informado"}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Profissional
                    </Typography>{" "}
                    :{" "}
                    {schedule.requested_employee_name
                      ? schedule.requested_employee_name
                      : "Não informado"}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Agenda
                    </Typography>{" "}
                    : {schedule?.schedule_type.toUpperCase()}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Notas
                    </Typography>{" "}
                    : {schedule.notes ? schedule.notes : "Não informado"}
                  </Typography>
                  {!!schedule.attached_document && (
                    <DownloadButton
                      url={schedule.attached_document.url}
                      fileName={schedule.attached_document.name}
                    >
                      Anexo: {schedule.attached_document.name}
                    </DownloadButton>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
