import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
import { handleParam } from "../utils/handleParam";

const path = "/employees";
function useProvider() {
  async function getProviders({ filter, ...params } = { limit: 5, page: 0 }) {
    try {
      const filters = handleParam(filter, {
        employee_cpf: new RegExp(/^[0-9]{11}$/),
        employee_cns: new RegExp(/^[0-9]{11,}$/),
        name: new RegExp(/.*/),
      });
      const response = await instance.get(path, {
        params: urlQuery({ ...params, ...filters }),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  }

  async function getProvider(id) {
    try {
      const response = await instance.get(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getSpecialties(params) {
    try {
      const response = await instance.get(`/specialties`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getSpecialtiesActive() {
    try {
      const response = await instance.get(`/specialties?filter=active`);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getSpecialtiesByEmployee(data) {
    try {
      const response = await instance.post(
        `/auth/login/employees/specialties`,
        data
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getTypes(params = { page: 0, limit: 5, filter: "" }) {
    try {
      const response = await instance.get(`${path}/types`, {
        params: urlQuery(params),
      });
      return response;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }

  async function postProvider(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function updateProvider({ id, data }) {
    try {
      const response = await instance.put(`${path}/update/${id}`, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function allowImportSchedules({ id, data }) {
    try {
      const response = await instance.patch(
        `${path}/${id}/schedule-import`,
        data
      );
      return response;
    } catch (err) {
      throw new Error(err.response.data);
    }
  }

  async function getTypeRoles(id) {
    try {
      const response = await instance.get(`${path}/types/${id}/roles`);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getProviderScreens(
    employeeID,
    params = { limit: 5, page: 0 }
  ) {
    try {
      const response = await instance.get(`${path}/${employeeID}/screens`, {
        params: urlQuery(params),
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function postProviderScreens({ providerID, insertion }) {
    try {
      const response = await instance.post(
        `${path}/${providerID}/screens`,
        insertion
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function deleteProviderScreens({ providerID, deletion }) {
    try {
      const response = await instance.delete(
        `${path}/${providerID}/screens/${deletion}`
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getProviderRoles(employeeID, params = { limit: 5, page: 0 }) {
    try {
      const response = await instance.get(`${path}/${employeeID}/roles`, {
        params: urlQuery(params),
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function postEmployeeCompany(data) {
    try {
      const response = await instance.post(`${path}/companies`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getEmployeeCompany(employeeID, params) {
    try {
      const response = await instance.get(`${path}/${employeeID}/companies`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function deleteEmployeeCompany({ employeeId, companyId }) {
    try {
      const response = await instance.delete(
        `${path}/${employeeId}/companies/${companyId}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function updateProviderCompany({ employeeId, companyId, data }) {
    try {
      const response = await instance.patch(
        `${path}/update/${employeeId}/companies/${companyId}`,
        data
      );
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  const postSpecialty = async (data) => {
    return instance.post(`/specialties`, data).then((res) => res.data);
  };
  const updateSpecialty = async ({ id, data }) => {
    try {
      const response = await instance.put(`/specialties/update/${id}`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const postTypes = async (data) => {
    return instance.post(`${path}/types`, data).then((res) => res.data);
  };

  const updateTypes = async ({ id, data }) => {
    return instance
      .put(`${path}/types/update/${id}`, data)
      .then((res) => res.data);
  };

  const getCBOs = async (params = { limit: 5, page: 0 }) => {
    return instance
      .get("/professions", { params: urlQuery(params) })
      .then((res) => res.data);
  };

  return {
    getProviders,
    getProvider,
    getSpecialties,
    getTypes,
    getTypeRoles,
    postProvider,
    updateProvider,
    getSpecialtiesActive,
    getSpecialtiesByEmployee,
    allowImportSchedules,
    getProviderScreens,
    postProviderScreens,
    deleteProviderScreens,
    getProviderRoles,
    postEmployeeCompany,
    getEmployeeCompany,
    deleteEmployeeCompany,
    updateProviderCompany,
    postSpecialty,
    updateSpecialty,
    postTypes,
    updateTypes,
    getCBOs,
  };
}
export default useProvider;
