import { Button, Grid } from "@mui/material";
import { BoxContainer } from "../../../components";
import { PaginatedAutocompleteField } from "../../../components/FormFields";
import { useForm } from "react-hook-form";
import TableFilter from "../../../components/Table/TableFilter";
import { useMutation, useQuery } from "react-query";
import { usePatient } from "../../../service";
import { useState } from "react";
import { DialogMedium, Loading } from "../../../helper";
import GeneralAnnotationsForm from "./Form";
import useGeneralAnnotations from "../../../service/useGeneralAnnotations";
import { useNotifier } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../config/yup";
import formatDateString from "../../../utils/formatDateString";
import { UploadFile } from "@mui/icons-material";
import { AttachedDocuments } from "../..";
import usePermissions from "../../../config/usePermissions";
import { searchByPatientIdentifiers } from "../../../utils";

export default function GeneralAnnotations() {
  const form = useForm({
    defaultValues: {
      patient: null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        patient: Yup.object()
          .required("Escolha o paciente para começar.")
          .nullable(),
      })
    ),
  });
  const [patient] = form.watch(["patient"]);
  const [annotation, setAnnotation] = useState([]);
  const [annotationToEdit, setAnnotationToEdit] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [openAttachedDocuments, setOpenAttachedDocuments] = useState(false);
  const attachedDocumentPermissions = usePermissions("/attached-documents");
  const { getPatients } = usePatient();
  const { getGeneralAnnotationOpened, finishGeneralAnnotation } =
    useGeneralAnnotations();
  const finishMutation = useMutation({
    mutationFn: finishGeneralAnnotation,
    mutationKey: "finish-general-annotation",
  });
  const notify = useNotifier();
  const columns = [
    {
      name: "Paciente",
      field: "patient?.name",
    },
    {
      name: "Mãe",
      field: "patient?.mother_name",
    },
    {
      name: "Data de Nascimento",
      field: "patient?.birth_date",
      use: (birth_date) => (birth_date ? formatDateString(birth_date) : ""),
    },
    {
      name: "SUS",
      field: "patient?.sus_card",
    },
    {
      name: "CPF",
      field: "patient?.physic_national",
    },
  ];

  const patientAnnotationsQuery = useQuery(
    ["get-general-annotation-opened"],
    () => getGeneralAnnotationOpened(patient.id),
    {
      enabled: false,
      onSuccess(data) {
        setAnnotation([{ patient, ...data }]);
      },
      onError(data) {
        notify(data.message, "error");
      },
    }
  );

  const handleFinish = (id) => {
    finishMutation.mutate(id, {
      onSuccess(data) {
        notify(data.message);
        patientAnnotationsQuery.refetch();
      },
      onError(data) {
        notify(data.message, "error");
      },
    });
  };

  const search = form.handleSubmit(() => {
    patientAnnotationsQuery.refetch();
  });

  const handleClose = () => {
    setOpenForm(false);
    setAnnotationToEdit();
    patientAnnotationsQuery.refetch();
  };

  return (
    <>
      {patientAnnotationsQuery.isFetching || finishMutation.isLoading ? (
        <Loading maxWidth={"100%"} backgroundColor={"rgba(255,255,255,0.7)"} />
      ) : null}
      <DialogMedium
        title={"Anotações no Prontuário do Paciente"}
        children={
          <GeneralAnnotationsForm
            handleClose={handleClose}
            patientId={patient?.id}
            annotationId={annotationToEdit?.id}
          />
        }
        open={openForm}
        handleClose={handleClose}
        fullWidth
        maxWidth={"lg"}
      />
      <DialogMedium
        open={openAttachedDocuments}
        title={"Anexo ao prontuário"}
        maxWidth="lg"
        fullWidth
        handleClose={() => setOpenAttachedDocuments(false)}
      >
        <AttachedDocuments patient_id={patient?.id} />
      </DialogMedium>
      <BoxContainer title={"Anotações gerais"}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid item xs={4}>
            <PaginatedAutocompleteField
              control={form.control}
              service={({ filter, ...params }) => {
                searchByPatientIdentifiers(filter, params, [
                  "physic_national",
                  "sus_card",
                  "name",
                ]);

                return getPatients(params);
              }}
              filterKey={"filter"}
              optionLabelKey="name"
              queryKey={"getPatients"}
              name={"patient"}
              label={"CPF, CNS ou Nome do Paciente"}
              customOnChange={(value) => {
                if (!value) {
                  setAnnotation([]);
                }
              }}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" onClick={() => search()}>
              BUSCAR
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TableFilter
              data={annotation}
              columns={columns}
              noHeaderOptions
              actions
              actionsTypes={["response", "edit", "finish", "checklist"]}
              customizeActions={{
                response: {
                  title: "Criar Anotação",
                },
                checklist: {
                  title: "Anexos no prontuário",
                  icon: <UploadFile />,
                },
              }}
              disableActions={(annotation, action) => {
                switch (action) {
                  case "response":
                    return annotation.id;
                  case "edit":
                    return !annotation.id;
                  case "finish":
                    return !annotation.id;
                  case "checklist":
                    return !attachedDocumentPermissions.read;
                  default:
                    break;
                }
              }}
              actionUniqueIdentifier={"id"}
              actionHandleResponse={() => {
                setOpenForm(true);
              }}
              actionHandleEdit={(_, row) => {
                setAnnotationToEdit(row);
                setOpenForm(true);
              }}
              actionHandleFinish={(id) => {
                handleFinish(id);
              }}
              actionHandleChecklist={() => {
                setOpenAttachedDocuments(true);
              }}
            />
          </Grid>
        </Grid>
      </BoxContainer>
    </>
  );
}
