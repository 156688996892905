import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "../../../config/theme";
import Yup from "../../../config/yup";
import { Grid, Box, Button, Typography, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import Grow from "@mui/material/Grow";
import { AppContext } from "../../../contexts/AppContext";
import { usePresets, useProcedures } from "../../../service";
import { MedicationRounded } from "@mui/icons-material/";
import { drugAdmininstrations } from "../../../config/standardSelects";
import { add, format } from "date-fns";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  AutocompleteField,
  PaginatedAutocompleteField,
  SwitchField,
  TextField,
} from "../../../components/FormFields";
import { DeleteIcon } from "../../../components/Icons";
import { useLocalStorage, useNotifier } from "../../../hooks";
import AllergyButton from "../../Patient/AllergyHistory/AllergyButton";

function DrugPrescription({ type, handleSave, attendance, allergies }) {
  const { getProcedures } = useProcedures();

  const {
    setDrugPrescriptions,
    drugPrescriptions,
    drugOnSitePrescriptions,
    setDrugOnSitePrescriptions,
    specialPrescriptions,
    setSpecialPrescriptions,
    disabledFields,
  } = useContext(AppContext);

  const renderMedications = {
    medications: [...drugPrescriptions, ...specialPrescriptions],
    medications_on_site: drugOnSitePrescriptions,
  };

  const [userData] = useLocalStorage("user-data");

  const { getPresets } = usePresets();

  function presetChange(selectedItems) {
    const presets = selectedItems?.flatMap((value) => value.data) || [];

    function filterMedications(medications, special) {
      const filteredMedications = medications.filter(
        ({ notPreseted }) => notPreseted
      );

      const filteredPresets = presets.filter(
        (preset) => (special && preset.special) || (!special && !preset.special)
      );

      return [...filteredPresets, ...filteredMedications];
    }

    if (type === "medications") {
      setDrugPrescriptions((medications) => filterMedications(medications));
      setSpecialPrescriptions((medications) =>
        filterMedications(medications, true)
      );
    } else if (type === "medications_on_site") {
      setDrugOnSitePrescriptions((medications) =>
        filterMedications(medications)
      );
    }
  }

  const addPrescription = (values) => {
    const medication = {
      ...values,
      name: typeof values.name === "string" ? values.name : values.name?.name,
      tuss_code:
        userData.company.id === "55e1278d-8749-4576-896f-25df0167a478" &&
        !values.name?.tuss_code
          ? "0301100012"
          : values.name?.tuss_code,
      procedure_id: values.drug_administration?.procedure_id || null,
      lme: values.name?.tuss_code?.slice(0, 4) === "0604" ? true : false,
      special: values.special,
      notPreseted: true,
    };

    delete medication.drug_administration;
    delete medication.in_system;
    delete medication.preset;

    if (type === "medications") {
      if (medication.continuous_use) {
        medication.quantity = null;
      }
      if (medication.special) {
        setSpecialPrescriptions((special) => [...special, medication]);
      } else {
        setDrugPrescriptions((prescriptions) => [...prescriptions, medication]);
      }
    } else if (type === "medications_on_site") {
      delete medication.continuous_use;

      setDrugOnSitePrescriptions((prescriptions) => [
        ...prescriptions,
        medication,
      ]);
    }

    reset();
  };

  const removePrescription = (index) => {
    if (type === "medications") {
      const medication = renderMedications.medications[index];
      let medications = renderMedications.medications.filter(
        (_, i) => i !== index
      );

      if (medication.special) {
        const newSpecialPrescriptions = medications.filter(
          (value) => value.special
        );
        setSpecialPrescriptions(newSpecialPrescriptions);
      } else {
        const newPrescriptions = medications.filter((value) => !value.special);
        setDrugPrescriptions(newPrescriptions);
      }
    } else if (type === "medications_on_site") {
      const newPrescriptions = drugOnSitePrescriptions.filter(
        (_, i) => i !== index
      );
      setDrugOnSitePrescriptions(newPrescriptions);
    }
  };

  const initialValues = {
    in_system: true,
    name: null,
    quantity: "",
    presentation: "",
    continuous_use: false,
    special: false,
    posology: "",
    drug_administration: null,
    notes: "",
    medication_assisted_treatment: false,
    treatment_cycle: null,
  };

  const validations = Yup.object().shape({
    in_system: Yup.boolean(),
    name: Yup.mixed().when("in_system", {
      is: true,
      then: Yup.object().required("É requerido").nullable(),
      otherwise: Yup.string()
        .typeError("Valor inválido")
        .required("É requerido")
        .nullable(),
    }),
    quantity: Yup.string()
      .when("continuous_use", {
        is: false,
        then: (schema) =>
          schema
            .min(0.1, "A quantidade deve ser maior que 0")
            .required("É requerido"),
        otherwise: (schema) => schema.nullable(),
      })
      .nullable(),
    presentation: Yup.string().required("É requerido"),
    posology: Yup.string().required("É requerido"),
    continuous_use:
      type === "medications"
        ? Yup.boolean().required("É requerido")
        : Yup.boolean(),
    drug_administration: Yup.object().required("É requerido").nullable(),
    notes: Yup.string().nullable(),
    treatment_cycle: Yup.number().when("medication_assisted_treatment", {
      is: true,
      then: (schema) =>
        schema
          .min(1, "Quantidade de dias deve ser maior que 0")
          .required("Quantidade de dias requerido")
          .nullable(),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  //Styled Components
  const CardPrescription = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: 15,
    backgroundColor: theme.palette.primary.medium,
    borderRadius: 8,
    padding: "12px 20px 12px 12px",
    color: theme.palette.primary.light,
    position: "relative",
  }));

  const PrescriptionHeader = styled(Typography, {
    shouldForwardProp: (prop) => {
      return prop !== "color" && prop !== "sx";
    },
  })(({ theme }) => ({
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.light,
  }));

  const PrescriptionDesc = styled(Typography, {
    shouldForwardProp: (prop) => {
      return prop !== "color" && prop !== "sx";
    },
  })(({ theme }) => ({
    fontSize: 13,
    marginRight: 20,
    color: theme.palette.primary.light,
    wordBreak: "break-word",
  }));

  const AddPrescritptionButton = ({ children, ...props }) => {
    return (
      <Button
        {...props}
        variant="text"
        sx={{
          color: theme.palette.primary.light,
          fontWeight: "bold",
          padding: 0,
        }}
      >
        <AddCircleIcon sx={{ marginRight: 0.5 }} />
        <Typography variant="span">{children}</Typography>
      </Button>
    );
  };

  function submitDisable(type) {
    if (type === "medications") {
      return (
        !drugPrescriptions.length &&
        // !drugLMEPrescriptions.length &&
        !specialPrescriptions.length
      );
    } else {
      return !drugOnSitePrescriptions.length;
    }
  }

  const { handleSubmit, control, watch, setValue, reset } = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const [in_system, medication_assisted_treatment, continuous_use] = watch([
    "in_system",
    "medication_assisted_treatment",
    "continuous_use",
  ]);

  const notify = useNotifier();

  const [openAllergyAlert, setOpenAllergyAlert] = useState(false);

  useEffect(() => {
    const hasAllergy =
      allergies &&
      Object.entries(allergies?.allergy_substances).some(([_, value]) => value);

    if (hasAllergy) {
      setOpenAllergyAlert(true);
      notify("Paciente contém alergia.", "warning");
    }
  }, [allergies]);

  useEffect(() => {
    if (type === "medications") {
      setDrugPrescriptions((medications) => {
        return medications.map((medication) => ({
          ...medication,
          notPreseted: true,
        }));
      });
      setSpecialPrescriptions((medications) => {
        return medications.map((medication) => ({
          ...medication,
          notPreseted: true,
        }));
      });
    } else {
      setDrugOnSitePrescriptions((medications) => {
        return medications.map((medication) => ({
          ...medication,
          notPreseted: true,
        }));
      });
    }
  }, []);

  return (
    <>
      <Grid
        component="form"
        container
        spacing={3}
        marginTop="0.5rem"
        position={"relative"}
      >
        <Grid container item xs={12} spacing={2}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            paddingRight={5}
            alignItems={"start"}
          >
            <Typography fontSize={16} color="secondary" fontWeight="600">
              Selecionar pré definição
            </Typography>
            <AllergyButton
              openAllergyAlert={openAllergyAlert}
              setOpenAllergyAlert={setOpenAllergyAlert}
              attendance={attendance}
              allergies={allergies}
            />
          </Grid>
          <Grid md={5} xs={8} item>
            <PaginatedAutocompleteField
              multiple
              name="preset"
              control={control}
              queryKey={"presets"}
              label="Predefinição"
              optionLabelKey={"describe"}
              customOnChange={presetChange}
              service={async (params) => getPresets({ ...params, type }) || []}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={16} color="secondary" fontWeight="600">
              Medicamento
            </Typography>
          </Grid>
          <Grid item xs={3.7}>
            <SwitchField
              name="in_system"
              label="Medicamento no sistema ?"
              control={control}
              customOnChange={() => setValue("name", null)}
              required
            />
          </Grid>
          <Grid xs={8.3} item>
            {in_system ? (
              <PaginatedAutocompleteField
                control={control}
                name="name"
                label="Medicamento"
                queryKey={"preseting"}
                service={async (params) =>
                  getProcedures({
                    ...params,
                    type: "medications",
                    filter: "active",
                  })
                }
                getOptionLabel={(data) => {
                  let label = data?.name;

                  if (data?.tuss_code) {
                    label = `${data?.tuss_code} - ${label}`;
                  }

                  return label;
                }}
                filterKey="filters"
                required
                optionLabelKey={"describe"}
              />
            ) : (
              <TextField
                control={control}
                name="name"
                label="Medicamento"
                required
              />
            )}
          </Grid>
          <Grid xs={2.8} item>
            <TextField
              name="quantity"
              label="Quantidade"
              type="number"
              disabled={continuous_use}
              required={!continuous_use}
              control={control}
            />
          </Grid>
          <Grid xs={6} md={3} item>
            <TextField
              name="presentation"
              inputProps={{ maxLength: 50 }}
              label="Apresentação"
              required
              control={control}
            />
          </Grid>
          <Grid xs={type === "medications_on_site" ? 3 : 3.2} item>
            <AutocompleteField
              control={control}
              name="drug_administration"
              label="Via de admininstração"
              optionCompareKey="procedure_id"
              options={drugAdmininstrations}
              autoCompleteProps={{
                getOptionLabel: (data) => data.describe + " - " + data.title,
              }}
              required
            />
          </Grid>
          {type === "medications_on_site" && (
            <Grid item xs={3.2}>
              <SwitchField
                name="medication_assisted_treatment"
                label="Medicação Assistida ?"
                control={control}
              />
            </Grid>
          )}
          {type === "medications" && (
            <Grid xs={3} item container justifyContent="center">
              <SwitchField
                name="continuous_use"
                label="Uso contínuo ?"
                control={control}
              />
            </Grid>
          )}
          <Grid
            xs={
              type === "medications" || medication_assisted_treatment ? 9 : 12
            }
            item
          >
            <TextField
              name="posology"
              inputProps={{ maxLength: 100 }}
              label="Posologia"
              required
              control={control}
            />
          </Grid>
          {type === "medications_on_site" && medication_assisted_treatment && (
            <Grid xs={3} item>
              <TextField
                name="treatment_cycle"
                label="Quantidade em dias"
                type="number"
                placeholder="quantidade em dias ex: 20"
                required={medication_assisted_treatment}
                control={control}
              />
            </Grid>
          )}
          {type === "medications" && (
            <Grid xs={3} item container justifyContent="center">
              <SwitchField
                name="special"
                label="Receituário Especial?"
                control={control}
              />
            </Grid>
          )}
          <Grid xs={12} item>
            <TextField name="notes" label="Observação" control={control} />
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <AddPrescritptionButton onClick={handleSubmit(addPrescription)}>
            Adicionar Medicamento
          </AddPrescritptionButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {renderMedications[type]?.map(
          (
            {
              name,
              quantity,
              presentation,
              posology,
              notes,
              medication_assisted_treatment,
              treatment_cycle,
            },
            index
          ) => {
            return (
              <Grid key={name + "-" + index} xs={12} item>
                <Grow in={true} timeout={300}>
                  <CardPrescription>
                    {medication_assisted_treatment && (
                      <PrescriptionDesc component="h3">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <IconButton>
                            <MedicationRounded color="primary" />
                          </IconButton>{" "}
                          Medicação Assistida: Término Previsto{" "}
                          {format(
                            add(new Date(), {
                              days: treatment_cycle,
                            }),
                            "dd/MM/yyyy"
                          )}
                        </Box>
                      </PrescriptionDesc>
                    )}

                    <Box display={"flex"} gap={2}>
                      <PrescriptionHeader variant="h3" component="h1">
                        {`${name}, ${
                          quantity ? quantity.toString().replace(".", ",") : ""
                        } ${presentation || ""}`}
                      </PrescriptionHeader>
                      <PrescriptionDesc variant="p" component="p">
                        {`${posology || ""} - ${notes || ""}`}
                      </PrescriptionDesc>
                    </Box>
                    <DeleteIcon onClick={() => removePrescription(index)} />
                  </CardPrescription>
                </Grow>
              </Grid>
            );
          }
        )}
      </Grid>
      <Box display="flex" justifyContent="center" margin="1.5rem auto">
        <Button
          variant="contained"
          disabled={disabledFields || submitDisable(type)}
          onClick={handleSave}
        >
          Salvar
        </Button>
      </Box>
    </>
  );
}

export default DrugPrescription;
