import React from "react";
import { Box, Typography, CardContent, Card, Grid } from "@mui/material";

function RegisterImmuFeedback({ data }) {
  const minimumAge = `${
    data.minimumAge?.years ? data.minimumAge.years + " ano(s)" : " "
  } ${data.minimumAge?.months ? data.minimumAge.months + " meses" : ""} ${
    data.minimumAge?.weeks ? data.minimumAge.weeks + " semanas" : ""
  } ${data.minimumAge?.days ? "e " + data.minimumAge.days + " dias" : ""}`;
  const minimumInterval = `${
    data.minimumInterval?.years ? data.minimumInterval.years + " ano(s)" : ""
  } ${
    data.minimumInterval?.months ? data.minimumInterval.months + " meses" : ""
  } ${
    data.minimumInterval?.weeks ? data.minimumInterval.weeks + " semanas" : ""
  } ${
    data.minimumInterval?.days ? "e " + data.minimumInterval.days + " dias" : ""
  }`;
  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
        }}
      >
        <Typography
          variant="h1"
          color="gray"
          fontWeight="700"
          fontSize={12}
          sx={{ ml: 2, mb: 1 }}
        >
          #1 DADOS DOS IMUNOBIOLOGICOS
        </Typography>
        <Typography
          variant="p"
          color="secondary"
          fontWeight="600"
          fontSize={15}
          sx={{ ml: 2 }}
        >
          {data?.describe}
        </Typography>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", mt: "-15px" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Idade Minima
                      </Typography>{" "}
                      : {minimumAge}
                    </Typography>
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Intervalo Minimo
                    </Typography>{" "}
                    {minimumInterval}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Sigla
                    </Typography>{" "}
                    : {data?.abbreviation}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  ></Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Dose Recomendada
                    </Typography>{" "}
                    : {data?.recommendedDose}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  ></Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Reagentes Incompativeis
                    </Typography>{" "}
                    :{" "}
                    {data.incompatibleReagents
                      ? data.incompatibleReagents
                      : "Não consta"}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      {data.obligatory
                        ? "Dose Obrigatória"
                        : "Dose Não Obrigatória"}
                    </Typography>{" "}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default RegisterImmuFeedback;
