import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/preillness";

export default function usePreillness() {
  async function getPreillness(filter) {
    try {
      const response = await instance.get(path, { params: urlQuery(filter) });
      return response?.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }
  async function getPreillnessId(id) {
    try {
      const response = await instance.get(`${path}/${id}`);
      return response?.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function postPreillness(data) {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function updatePreillness({ id, data }) {
    try {
      const response = await instance.put(`${path}/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getPreillness,
    getPreillnessId,
    postPreillness,
    updatePreillness,
  };
}
