import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { BoxContainer } from "../../components";
import { useProvider } from "../../service";
import { TablePagination, Box, Button, Chip } from "@mui/material";
import { Dialog } from "../../helper";
import ProviderFeedback from "../../helper/ProviderFeedback";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../config/usePermissions";
import TableFilter from "../../components/Table/TableFilter/Table";
function EmployeeList() {
  const { getProviders } = useProvider();
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(0);
  const [employee, setEmployee] = React.useState();
  const [filter, setFilter] = React.useState({});
  const queryClient = useQueryClient();
  const permission = usePermissions("/schedule/employee");
  const navigate = useNavigate();
  const employeeQuery = useQuery(
    ["employees", [page, limit]],
    () => getProviders({ page, limit, ...filter }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {
        queryClient.setQueriesData("employees", []);
      },
    }
  );

  const allStatus = {
    true: "Ativo",
    false: "Inativo",
  };

  const tableColumns = [
    {
      name: "Cod. Profissional",
      field: "number",
      type: "number",
    },
    {
      name: "Nome",
      field: "name",
      type: "text",
    },
    {
      name: "Especialidades",
      field: "specialties",
      use: (specialties) => {
        return specialties.map((specialty) => {
          return (
            <Chip
              label={specialty?.describe}
              key={specialty?.id}
              color="secondary"
              sx={{
                margin: "5px",
              }}
            />
          );
        });
      },
      filter: "name_specialty",
      otherwise: () => "Não informado",
      type: "text",
    },
    {
      name: "Status",
      field: "active",
      type: "boolean",
      use: (active) => allStatus[active],
    },
  ];
  async function handleShow(id) {
    setOpen(true);
    setEmployee(
      employeeQuery.data.items.find((employee) => employee.id === id)
    );
  }

  function handleFilter(filter) {
    queryClient.cancelQueries("employees");
    setPage(0);
    setFilter(filter);
  }
  React.useEffect(() => {
    employeeQuery.refetch();
  }, [filter]);

  async function handleUpdate(id) {
    navigate("/schedule/employee/" + id, { replace: true });
  }

  return (
    <BoxContainer title={"Lista de Profissionais"}>
      <>
        <Dialog
          open={open}
          title="Informações do profissional"
          handleClose={() => setOpen(false)}
        >
          <ProviderFeedback data={employee} showVisualization={true} />
        </Dialog>
        <TableFilter
          data={employeeQuery.data?.items}
          columns={tableColumns}
          loading={employeeQuery.isFetching && employeeQuery.isLoading}
          actions
          disableActions={(_, action) => {
            return action === "edit" && !permission.update;
          }}
          handleFilter={handleFilter}
          actionUniqueIdentifier={"id"}
          actionsTypes={["view", "edit"]}
          shouldDisableFields={["id"]}
          actionHandleView={handleShow}
          actionHandleEdit={handleUpdate}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={employeeQuery.data?.totalItems || 0}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          disabled={!permission.create}
          onClick={() => {
            navigate("/schedule/employee/new");
          }}
        >
          Novo Cadastro
        </Button>
      </>
    </BoxContainer>
  );
}
export default EmployeeList;
