import { Card, CardActionArea, Container, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import { Dialog } from "../../../helper";
import SchedulingFeedBack from "../SchedulingFeedback";

export default function ScheduleCardConsultation({ item, onFeedbackClose }) {
  const [openDialog, setOpenDialog] = React.useState(false);

  function handleClose() {
    setOpenDialog(false);
    if (onFeedbackClose instanceof Function) onFeedbackClose();
  }
  return (
    <>
      <Dialog
        open={openDialog}
        title={"Manutenção de Agendamento"}
        handleClose={() => {
          handleClose();
        }}
      >
        <SchedulingFeedBack config={item} />
      </Dialog>
      <Container key={item.id}>
        <Card
          sx={{
            minWidth: 889,
            minHeight: 80,
            marginTop: 1,
            marginLeft: 5,
            marginRight: 5,
            borderRadius: 2,
            border: 1,
            borderColor: "#0899BA",
            boxShadow: "none"
          }}
        >
          <CardActionArea
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "flex-start"
              }}
            >
              <Container>
                <Typography
                  color="secondary.main"
                  variant="subtitle2"
                  fontSize={16}
                  fontWeight="bold"
                  sx={{
                    whiteSpace: "nowrap",
                    paddingTop: 1
                  }}
                >
                  AGENDA :
                </Typography>
                <Typography
                  color="secondary.main"
                  variant="subtitle2"
                  fontSize={24}
                  sx={{
                    whiteSpace: "nowrap"
                  }}
                >
                  {item.number}
                </Typography>
                <Typography
                  color="primary"
                  variant="subtitle2"
                  fontWeight="bold"
                ></Typography>
              </Container>

              <Container
                sx={{
                  borderLeft: 1,
                  borderLeftColor: "#CEEBF1",
                  paddingTop: 1
                }}
              >
                <Typography
                  color="secondary"
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Tipo de agenda: {item.regulated ? "Regulada" : "Local"}
                </Typography>
                <Typography
                  color="secondary"
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Status de Escala:{" "}
                  {item.active ? " Escala Ativa " : "Escala Inativa"}
                </Typography>
                <Typography
                  color="secondary"
                  variant="subtitle2"
                  fontWeight="bold"
                >
                  Tempo de atendimento:
                </Typography>
                <Typography
                  color="primary"
                  variant="subtitle2"
                  fontWeight="bold"
                >
                  {item.average_time} minutos
                </Typography>
              </Container>
              <Container
                sx={{
                  borderLeft: 1,
                  borderLeftColor: "#CEEBF1",
                  paddingTop: 1,
                  paddingBottom: 1
                }}
              >
                <Typography
                  color="secondary"
                  variant="subtitle2"
                  fontWeight="bold"
                >
                  Profissional: {item.employee ? item.employee.name : "Inativo"}
                </Typography>
                <Typography
                  color="secondary"
                  variant="subtitle2"
                  fontWeight="bold"
                >
                  Setor: {item.sector ? item.sector.describe : "Inativo"}
                </Typography>
                <Typography
                  color="secondary"
                  variant="subtitle2"
                  fontWeight="bold"
                >
                  Dia: {format(parseISO(item.date), "dd/MM/yyyy")}
                </Typography>
              </Container>
              <Container
                sx={{
                  borderLeft: 1,
                  borderLeftColor: "#CEEBF1",
                  paddingTop: 1,
                  paddingBottom: 1
                }}
              >
                {/* {item.service_treatment.map((list, i) => (
                  <Typography
                    key={list + "-" + i}
                    color="secondary"
                    variant="subtitle2"
                    fontWeight="bold"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Serviço: {list.service.describe}
                  </Typography>
                ))} */}
              </Container>
            </Container>
          </CardActionArea>
        </Card>
      </Container>
    </>
  );
}
