import React, { useContext, useState } from "react";
import Yup from "../../../config/yup";
import { Grid, Box, styled, Typography, Button, Grow } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import { AppContext } from "../../../contexts/AppContext";
import { useCids, useProvider } from "../../../service";
import { againstReferencePDF } from "../../../pdfModels";
import { AuthContext } from "../../../contexts/AuthContext";
import { DeleteIcon } from "../../../components/Icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AutocompleteField,
  PaginatedAutocompleteField,
  SelectField,
  TextField,
} from "../../../components/FormFields";
import { useQuery } from "react-query";
import { useNotifier } from "../../../hooks";
import PatientDataConfirm from "../PatientDataConfirm";

const CardReference = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: 15,
  backgroundColor: theme.palette.primary.medium,
  borderRadius: 8,
  padding: "0.8rem",
  color: theme.palette.primary.light,
  position: "relative",
  justifyContent: "space-around",
}));

const ReferenceDesc = styled(Typography, {
  shouldForwardProp: (prop) => {
    return prop !== "color" && prop !== "sx";
  },
})(({ theme }) => ({
  fontSize: 13,
  marginRight: 20,
  color: theme.palette.primary.light,
  wordBreak: "break-word",
}));

const EditIcon = styled(ModeEditOutlineIcon)(({ theme }) => ({
  "&:hover": {
    color: "#FF7777",
  },
  transition: "0.3s",
  marginRight: 10,
  width: 26,
  height: 26,
  cursor: "pointer",
  color: theme.palette.primary.light,
}));

const AddButton = ({ children, ...props }) => {
  return (
    <Button
      variant="text"
      sx={{
        color: (theme) => theme.palette.primary.light,
        fontWeight: "bold",
        paddingY: 0.5,
      }}
      {...props}
    >
      <AddCircleIcon sx={{ marginRight: 0.5 }} />
      <Typography variant="span">{children}</Typography>
    </Button>
  );
};

function NewReference({ handleClose, patient }) {
  const [isEdit, setIsEdit] = useState(false);
  const [indexReference, setIndexReference] = useState();
  const { getSpecialties, getProviders } = useProvider();
  const { getCids } = useCids();
  const {
    againstReference,
    setAgainstReference,
    patientData,
    setPatientData,
    disableFields,
  } = useContext(AppContext);
  const { userData } = useContext(AuthContext);
  const notify = useNotifier();
  const [updatePatientType, setUpdatePatientType] = useState(() =>
    !patientData?.race ? "race" : null
  );

  const validations = Yup.object().shape({
    specialty: Yup.object().required("É requerido").nullable(),
    employee: Yup.object().nullable(),
    priority: Yup.object().required("É requerido").nullable(),
    hyphotesis: Yup.string().required("É requerido"),
    cids_ids: Yup.array().nullable(),
    justification: Yup.string().required("É requerido"),
    exams: Yup.string().required("É requerido"),
  });

  const initialValues = {
    specialty: null,
    employee: null,
    priority: null,
    hyphotesis: "",
    cids_ids: [],
    justification: "",
    exams: "",
  };

  const { handleSubmit, control, watch, setValue, reset } = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const specialty = watch("specialty");

  const specialtiesQuery = useQuery(
    "specialty",
    () => getSpecialties({ caps: userData?.company?.type?.CAPS || false }),
    {
      onError: (err) => {
        notify(err.message, "error");
      },
    }
  );

  const removeReference = (index) => {
    const newReference = againstReference.filter((_, i) => i !== index);
    setAgainstReference(newReference);

    if (isEdit && indexReference === index) {
      reset(initialValues);
      setIsEdit(false);
    }
  };

  const addAgainstReference = async (values) => {
    const signSettingsNewReference = {
      visible_sign_x: 200,
      visible_sign_y: 730,
      visible_sign_page: "*",
    };

    const counterReference = {
      priority: values.priority?.describe,
      justification: values.justification,
      id_requested_employee: values.employee?.id || null,
      requested_employee: { name: values?.employee?.name },
      id_specialty: values.specialty?.id || null,
      requested_specialty: values.specialty.describe,
      requestor_name: userData?.name,
      details: { hyphotesis: values?.hyphotesis, exams: values?.exams },
      cids_ids: [],
      signature_settings: signSettingsNewReference,
      cids_object: values.cids_ids,
    };

    if (values.cids_ids?.length) {
      for (const cid of values.cids_ids) {
        counterReference.cids_ids.push(cid.id);
      }
    }

    const pdf = againstReferencePDF(
      counterReference,
      patientData,
      values.cids_ids || []
    );
    counterReference.id = pdf.name.replace(".pdf", "");
    counterReference.original_file_name = pdf.name;
    counterReference.data = await pdf.base64();

    if (isEdit) {
      setAgainstReference((againstReference) => {
        const references = [...againstReference];
        references[indexReference] = counterReference;
        return references;
      });
      setIsEdit(false);
    } else {
      setAgainstReference((counterReferences) => [
        ...counterReferences,
        counterReference,
      ]);
    }
    reset(initialValues);
  };

  const handleEdit = (index) => {
    setIsEdit(true);
    setIndexReference(index);

    const findedAgainstReference = againstReference[index];

    reset({
      specialty: {
        id: findedAgainstReference?.id_specialty,
        describe: findedAgainstReference?.requested_specialty,
      },
      employee: !!findedAgainstReference?.requested_employee?.name
        ? {
            id: findedAgainstReference?.id_requested_employee,
            name: findedAgainstReference?.requested_employee?.name,
          }
        : null,
      priority: { describe: findedAgainstReference?.priority },
      hyphotesis: findedAgainstReference?.details?.hyphotesis,
      cids_ids: findedAgainstReference?.cids_object,
      justification: findedAgainstReference?.justification,
      exams: findedAgainstReference?.details?.exams,
    });
  };

  const priorities = [
    { describe: "Baixa" },
    { describe: "Normal" },
    { describe: "Alta" },
  ];

  return (
    <>
      <PatientDataConfirm
        open={!!updatePatientType}
        patientId={patientData.id}
        type={updatePatientType}
        handleClose={() => setUpdatePatientType(null)}
        onSuccess={(data, _, type) => {
          setPatientData(() => {
            if (type === "phone") {
              return {
                ...patientData,
                contact: {
                  ...patientData.contact,
                  ...data.contact,
                },
              };
            } else {
              return { ...patientData, race: data.race };
            }
          });
        }}
      />
      <Box component="form">
        <Grid container spacing={2} mt="0.5rem">
          <Grid item xs={5}>
            <AutocompleteField
              control={control}
              options={specialtiesQuery.data || specialtiesQuery.data}
              optionLabelKey="describe"
              name="specialty"
              label="Especialidade"
              required
              customOnChange={() => setValue("employee", null)}
            />
          </Grid>
          <Grid item xs={5}>
            <PaginatedAutocompleteField
              control={control}
              service={(params) =>
                getProviders({
                  ...params,
                  active: true,
                  id_specialty: specialty?.id,
                  exclude_me: true,
                })
              }
              filterKey={"name"}
              name="employee"
              label="Profissional"
              optionLabelKey="name"
              queryKey="employee"
              refetchService={[specialty]}
              disabled={!specialty}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectField
              control={control}
              name="priority"
              label="Prioridade"
              required
              optionLabelKey="describe"
              options={priorities}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              control={control}
              multiline
              minRows={1}
              maxRows={5}
              name="hyphotesis"
              label="Hipótese diagnóstica/diagnóstico"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <PaginatedAutocompleteField
              control={control}
              label="CID"
              name="cids_ids"
              multiple
              service={(params) =>
                getCids({ ...params, patient_id: patient?.id })
              }
              autoCompleteProps={{
                getOptionLabel: (option) =>
                  `${option.id ? option.id : ""} ${option.description}`,
              }}
              optionCompareKey="id"
              filterKey="fieldValue"
              queryKey="cids"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              control={control}
              multiline
              minRows={5}
              maxRows={5}
              name="justification"
              label="Motivo de Encaminhamento"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              control={control}
              multiline
              minRows={1}
              maxRows={5}
              name="exams"
              label="Exames e procedimentos realizados"
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="flex-end"
            margin="1.2rem auto"
          >
            <AddButton onClick={handleSubmit(addAgainstReference)}>
              {isEdit ? "Atualizar" : "Adicionar"}
            </AddButton>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        {againstReference.map(
          ({ requested_employee, requested_specialty, priority }, index) => {
            return (
              <Grid key={requested_specialty + "-" + index} xs={12} item>
                <Grow in={true} timeout={300}>
                  <CardReference>
                    <ReferenceDesc
                      variant="p"
                      component="p"
                      display="flex"
                      gap={0.5}
                    >
                      <Typography
                        variant="span"
                        component="span"
                        sx={{
                          fontSize: 13,
                          fontWeight: 700,
                        }}
                      >
                        Especialidade:
                      </Typography>
                      {requested_specialty}
                    </ReferenceDesc>
                    <ReferenceDesc
                      variant="p"
                      component="p"
                      mr={10}
                      display="flex"
                      gap={0.5}
                    >
                      <Typography
                        variant="span"
                        component="span"
                        sx={{
                          fontSize: 13,
                          fontWeight: 700,
                        }}
                      >
                        Prioridade:
                      </Typography>
                      {priority}
                    </ReferenceDesc>
                    <ReferenceDesc
                      variant="p"
                      component="p"
                      mr={10}
                      display="flex"
                      gap={0.5}
                    >
                      <Typography
                        variant="span"
                        component="span"
                        sx={{
                          fontSize: 13,
                          fontWeight: 700,
                        }}
                      >
                        Profissional Requisitado:
                      </Typography>
                      {requested_employee?.name}
                    </ReferenceDesc>
                    <Box display="flex" justifyContent="right">
                      <EditIcon onClick={() => handleEdit(index)} />
                      <DeleteIcon onClick={() => removeReference(index)} />
                    </Box>
                  </CardReference>
                </Grow>
              </Grid>
            );
          }
        )}
      </Grid>
      <br />
      <Grid display="flex" justifyContent="center">
        <Button
          variant="contained"
          onClick={() => {
            if (againstReference.length && !patientData?.contact?.cell_phone) {
              return setUpdatePatientType("phone");
            }

            handleClose();
          }}
          disabled={disableFields || !againstReference.length}
        >
          Salvar
        </Button>
      </Grid>
    </>
  );
}

export default NewReference;
