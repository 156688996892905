import {
  add,
  parseISO,
  sub,
  differenceInDays,
  format,
  isValid,
} from "date-fns";

function calcByDUM(currentDate, date) {
  const firstDate = isValid(parseISO(currentDate)) ? parseISO(currentDate) : currentDate;
  const dumDate = isValid(parseISO(date)) ? parseISO(date) : date;
  const differenceOfDays = differenceInDays(firstDate, dumDate);

  const totalWeeks = Math.floor(differenceOfDays / 7) || 0;
  const totalDays = Math.floor(differenceOfDays % 7) || 0;

  const gestationalAge = `${totalWeeks} semanas e ${totalDays} dias`;
  const probableDateOfBirth = add(dumDate, { days: 280 });

  return {
    weeks: totalWeeks,
    days: totalDays,
    gestationalAge,
    probableDateOfBirth: isValid(probableDateOfBirth)
      ? format(probableDateOfBirth, "dd/MM/yyyy")
      : "Data inválida",
  };
}

function calcByUSG(currentDate, date, weeks, days) {
  const firstDate = isValid(parseISO(currentDate)) ? parseISO(currentDate) : currentDate;
  const usgDate = isValid(parseISO(date)) ? parseISO(date) : date;

  const differenceOfDays = differenceInDays(firstDate, usgDate);
  let totalWeeks = (Math.floor(differenceOfDays / 7) || 0) + weeks;
  let totalDays = (Math.floor(differenceOfDays % 7) || 0) + days;
  const totalDaysInExam = weeks * 7 + days;

  while (totalDays >= 7) {
    totalDays -= 7;
    totalWeeks += 1;
  }

  const gestationalAge = `${totalWeeks} semanas e ${totalDays} dias`;
  const probableDateOfBirth = add(sub(usgDate, { days: totalDaysInExam }), {
    days: 280,
  });

  return {
    weeks: totalWeeks,
    days: totalDays,
    gestationalAge,
    probableDateOfBirth: isValid(probableDateOfBirth)
      ? format(probableDateOfBirth, "dd/MM/yyyy")
      : "Data inválida",
  };
}

export { calcByDUM, calcByUSG };
