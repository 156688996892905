import React from "react";
import { BoxContainer } from "../../../components";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import { Loading, Error, Dialog } from "../../../helper";
import { Button, TablePagination, Box } from "@mui/material";
import ProgramForm from "./ProgramForm";
import ProgramFeedback from "./ProgramFeedback";
import { useQuery } from "react-query";
import { useProgram } from "../../../service";
import usePermissions from "../../../config/usePermissions";

function ProgramList() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);

  const [loading, setLoading] = React.useState(false);
  const [registerProgram, setRegisterProgram] = React.useState(false);
  const [displayProgram, setDisplayProgram] = React.useState(false);
  const [editProgram, setEditProgram] = React.useState(false);
  const [programs, setPrograms] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [program, setProgram] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const { getPrograms } = React.useMemo(useProgram, []);
  const permission = usePermissions("/program/-/");

  // Reequets
  const programQuery = useQuery(
    ["programs", [page, limit]],
    () => getPrograms({ page, limit }),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  React.useEffect(() => {
    if (
      programQuery.isSuccess &&
      programQuery.data &&
      programQuery.data.items
    ) {
      if (count === 0) {
        setCount(programQuery.data.totalItems);
      }
      setPrograms(programQuery.data.items);
      setRows(
        programQuery.data.items.map((index) => {
          return {
            number: index.number,
            name: index.name,
            describe: index.describe,
            gender: index.gender.join("/ "),
            age_group_initial: index.age_group_initial,
            age_group_final: index.age_group_final,
            active: index.active ? "Sim" : "Não",
          };
        })
      );
    }
  }, [programQuery.dataUpdatedAt]);

  function handleCloseDialog() {
    setRegisterProgram(false);
    setDisplayProgram(false);
    setEditProgram(false);
  }

  //config table
  const tableColumns = [
    { name: "Código" },
    { name: "Nome" },
    { name: "Descrição" },
    { name: "Gênero" },
    { name: "Idade Inicial" },
    { name: "Idade Final" },
    { name: "Ativo" },
  ];

  if (loading) return <Loading />;

  return (
    <>
      <BoxContainer title="Lista de Programas">
        <Dialog
          open={displayProgram}
          title={"Visualização de Programa"}
          handleClose={handleCloseDialog}
        >
          <ProgramFeedback data={program} />
        </Dialog>
        <Dialog
          open={registerProgram}
          title={"Cadastro de Programa"}
          handleClose={handleCloseDialog}
        >
          <ProgramForm type="register" />
        </Dialog>
        <Dialog
          open={editProgram}
          title={"Edição de Programa"}
          handleClose={handleCloseDialog}
        >
          <ProgramForm type="edit" data={program} />
        </Dialog>
        <PaginatedTable
          data={rows}
          columns={tableColumns}
          loading={programQuery.isRefetching}
          actions
          actionsTypes={["view", "edit"]}
          confirmMessage="Você realmente deseja *action* este programa?"
          disableActions={(_, action) =>
            action === "edit" && !permission.update
          }
          actionHandleEdit={(i) => {
            setProgram(programs[i]);
            setEditProgram(true);
          }}
          actionHandleView={(i) => {
            setProgram(programs[i]);
            setDisplayProgram(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={count}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          disabled={!permission.create}
          onClick={() => setRegisterProgram(true)}
        >
          Novo Cadastro
        </Button>
      </BoxContainer>
    </>
  );
}

export default ProgramList;
