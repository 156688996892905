import React, { useContext } from "react";
import { createPortal } from "react-dom";
import Notifier from "./Notifier";
import {
  HIDE_NOTIFICATION,
  NotifierContext,
} from "../contexts/NotifierContext";

const GlobalNotifier = () => {
  const [notifierState, dispatch] = useContext(NotifierContext);

  const closeNotify = () => {
    dispatch({
      type: HIDE_NOTIFICATION,
    });
  };
  const portalElement = document.getElementById("notify");
  const component = notifierState.show ? (
    <Notifier
      open={notifierState.show}
      message={notifierState.message}
      severity={notifierState.severity}
      close={closeNotify}
    />
  ) : null;

  return createPortal(component, portalElement);
};
export default GlobalNotifier;
