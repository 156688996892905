import { Button, Grid } from "@mui/material";
import {
  AutocompleteField,
  PaginatedAutocompleteField,
  TextField,
} from "../../../../components/FormFields";
import { useForm } from "react-hook-form";
import useGeneralAnnotations from "../../../../service/useGeneralAnnotations";
import { useMutation, useQuery } from "react-query";
import { useNotifier } from "../../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "./validations";
import { Loading } from "../../../../helper";
import useActivities from "../../../../service/useActivities";

export default function GeneralAnnotationsForm({
  handleClose,
  patientId,
  annotationId,
}) {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      activity: null,
      annotation: "",
    },
    resolver: yupResolver(validation),
  });

  const { postGeneralAnnotations, getGeneralAnnotation, putGeneralAnnotation } =
    useGeneralAnnotations();
  const { getActivities } = useActivities();
  const postMutation = useMutation({
    mutationFn: postGeneralAnnotations,
    mutationKey: "post-general-annotations",
  });
  const putMutation = useMutation({
    mutationFn: putGeneralAnnotation,
    mutationKey: "post-general-annotations",
  });

  const notify = useNotifier();

  const getGeneralAnnotationQuery = useQuery(
    ["get-general-annotation", annotationId],
    () => getGeneralAnnotation(annotationId),
    {
      enabled: !!annotationId,
      onSuccess(data) {
        reset({
          activity: data.activity,
          annotation: data.annotation,
        });
      },
      onError(data) {
        notify(data.message, "error");
      },
    }
  );

  const submitForm = handleSubmit((values) => {
    if (annotationId) {
      putMutation.mutate(
        {
          id: annotationId,
          data: { ...values, activity_id: values.activity.id },
        },
        {
          onSuccess(data) {
            notify(data.message);
            handleClose();
          },
          onError(data) {
            notify(data.message, "error");
          },
        }
      );
    } else {
      postMutation.mutate(
        { ...values, activity_id: values.activity.id, patient_id: patientId },
        {
          onSuccess(data) {
            notify(data.message);
            handleClose();
          },
          onError(data) {
            notify(data.message, "error");
          },
        }
      );
    }
  });

  return (
    <Grid container item xs={12} spacing={2}>
      {postMutation.isLoading ||
      putMutation.isLoading ||
      getGeneralAnnotationQuery.isLoading ? (
        <Loading maxWidth={"100%"} backgroundColor={"rgba(255,255,255,0.7)"} />
      ) : null}
      <Grid item xs={5}>
        <PaginatedAutocompleteField
          control={control}
          name={"activity"}
          label={"Atividade"}
          optionLabelKey="describe"
          service={getActivities}
          queryKey={"get-activities"}
          filterKey={"describe"}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          control={control}
          name={"annotation"}
          label={"Anotação"}
          multiline
          maxRows={8}
          minRows={8}
        />
      </Grid>
      <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
        <Button variant="contained" onClick={() => submitForm()}>
          Salvar Anotação
        </Button>
      </Grid>
    </Grid>
  );
}
