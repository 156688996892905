// import { Box, Typography } from '@material-ui/core';
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet";
import { TITLE_NAME } from "../../config/config";
import { Form, Formik } from "formik";
import SubmitButton from "../../components/Form/SubmitButton";
import { FormTextField } from "../../components";
import Yup from "../../config/yup";

function Home() {
  const [loading, setLoading] = React.useState(false);
  const initialValues = {
    question: "",
  };
  const validator = Yup.object().shape({
    question: Yup.string(),
  });
  const HomeContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }));
  const Title = styled("div")(({ theme }) => ({}));
  const FormContained = styled(Form)(({ theme }) => ({
    width: "100%",
    display: "flex",
    gap: 2,
    marginTop: 10,
  }));
  function handleSubmit(values) {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }
  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Início</title>
      </Helmet>
      <HomeContainer>
        <Title>
          <Typography variant="h4" fontWeight="bold" color="secondary">
            Olá!!
          </Typography>
          <Typography variant="h4" fontWeight="regular" color="secondary">
            Bem Vindo à Atenção Primária{" "}
            <Typography
              variant="h4"
              fontWeight="bold"
              color="secondary"
              component="span"
            >
              - Tascom
            </Typography>
          </Typography>
          <Typography variant="p" color="primary">
            Em caso de dúvidas , nossa equipe está a disposição =D
          </Typography>
          <Grid container>
            <Formik
              initialValues={{ ...initialValues }}
              validationSchema={validator}
              onSubmit={handleSubmit}
            >
              {() => (
                <FormContained>
                  <FormTextField
                    name="question"
                    sx={{ background: "white" }}
                    color="secondary"
                    placeholder="Digite sua dúvida.."
                  />
                  <br></br>
                  <SubmitButton loading={loading}>Enviar</SubmitButton>
                </FormContained>
              )}
            </Formik>
          </Grid>
        </Title>
      </HomeContainer>
    </>
  );
}

export default Home;
