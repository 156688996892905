import { Grid, IconButton, Stack, Box } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import Yup from "../../../config/yup";
import { Helmet } from "react-helmet";
import { FormDate, SubmitButton } from "../../../components";
import HistoryIcon from "@mui/icons-material/History";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import FormMultipleSelectField from "../../../components/Form/FormMultipleSelectField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/styles";

import { pt } from "yup-locale-pt";
import { Loading } from "../../../helper";
import { useProvider } from "../../../service";
import instance from "../../../config/api";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { format, parseISO } from "date-fns";
import returnObjectId from "../../../helper/returnObjectId";
import removeEmpty from "../../../helper/removeEmpty";
import { header, title, footer } from "../../../pdfModels/pdfComponents";
import useNotifier from "../../../hooks/useNotifier";

Yup.setLocale(pt);

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#9AC0D8",
    boxShadow: theme.shadows[1],
    fontSize: 8,
  },
}));

export default function EmployeeFormPeriod() {
  const [loadingButton, setLoadingButton] = React.useState(false);
  const notify = useNotifier();
  const [loading, setLoading] = React.useState(false);
  const { getProviders } = useProvider();
  const [employeeArray, setEmployeeArray] = React.useState([]);
  const [attendanceArray, setAttendanceArray] = React.useState([]);

  React.useEffect(() => {
    async function fetchEmployee() {
      try {
        setLoading(true);
        const response = await getProviders();
        if (response) {
          setEmployeeArray(response);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        notify(err.message, "error");
      }
    }

    async function fetchAttendance() {
      try {
        const response = await instance.get("/attendances?status=finished");
        if (response.data) {
          setAttendanceArray(response.data);
        }
      } catch (err) {
        notify(err.message, "error");
      }
    }

    fetchEmployee();
    fetchAttendance();
  }, []);

  const initialValues = {
    initialDate: null,
    finalDate: null,
    employees: [],
  };
  const validations = Yup.object({
    initialDate: Yup.date()
      .required("É requerido")
      .nullable()
      .default(undefined),
    finalDate: Yup.date().required("É requerido").nullable().default(undefined),
  });

  async function handleSubmit(values, { resetForm, setFieldValue }) {
    setLoadingButton(true);
    setTimeout(() => {
      setLoadingButton(false);
    }, 500);

    const initial_date = format(new Date(values.initialDate), "yyyy-MM-dd");
    const final_date = format(new Date(values.finalDate), "yyyy-MM-dd");
    const id_employee = values.employees?.map(returnObjectId);

    const response = await instance.post(
      `/reports/attendances/employees/`,
      removeEmpty({
        initial_date,
        final_date,
        id_employee,
      })
    );

    pdfAttendances(response.data, values);
  }

  function pdfAttendances(arrayObject, values) {
    if (!!arrayObject) {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      const filtro = [
        {
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text:
                    " Período de  " +
                      values.initiaDate +
                      " até " +
                      values.finalDate +
                      " | Profissional : " +
                      values.employee?.length ===
                    0
                      ? "Todos"
                      : values.employee,
                  fontSize: 8,
                  alignment: "center",
                  margin: [0, 5, 0, 5],
                },
              ],
            ],
          },
          layout: "noBorders",
        },
      ];

      const dados = arrayObject.map((cliente) => {
        return [
          {
            text: cliente?.attendance?.number,
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          },
          {
            text: cliente?.employee?.name,
            fontSize: 8,
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          },
          {
            text: cliente?.patient?.name,
            fontSize: 8,
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          },
          {
            text: cliente?.service?.describe,
            fontSize: 8,
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          },
          {
            text:
              format(parseISO(cliente.date), "dd/MM/yyyy") +
              " - " +
              cliente.hour,
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          },
          {
            text: cliente?.planCard?.insurancePlan?.describe
              ? cliente?.planCard?.insurancePlan?.describe +
                "/" +
                cliente?.planCard?.insurancePlan?.health_insurance?.describe
              : "SUS",
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          },
        ];
      });

      const details = [
        {
          table: {
            style: "tableExample",
            headerRows: 1,
            widths: [30, 110, 110, 60, 80, 70],

            body: [
              [
                {
                  text: "Atend.",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Profissional",
                  style: "tableHeader",
                  alignment: "center",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Paciente ",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Consulta",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Data/Hora ",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  alignment: "center",
                  border: [false, false, false, true],
                },
                {
                  text: "Plano/Convênio  ",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  alignment: "center",
                  border: [false, false, false, true],
                },
              ],

              ...dados,
            ],
          },

          layout: {
            hLineWidth: function (i, node) {
              return i === 1 || i === node.table.body.length ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 2 : 2;
            },
            hLineColor: function (i, node) {
              return i === 1 || i === node.table.body.length ? "black" : "gray";
            },

            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? "white"
                : "white";
            },
          },
        },
      ];

      var total = [
        {
          text: "Total de Atendimentos:" + dados.length,
          fontSize: 8,
          bold: true,
          margin: [10, 10, 0, 15],
        },
      ];

      const docDefinitios = {
        pageMargins: [40, 105, 40, 95],
        defaultStyle: {
          fontSize: 10,
        },
        content: [
          title("Relatório Atendimento Por Profissional"),
          filtro,
          details,
          total,
        ],
        ...header(),
        footer: footer(),
      };

      pdfMake.createPdf(docDefinitios).print();
    }
  }

  const options =
    !!employeeArray?.items && employeeArray?.items.length
      ? employeeArray?.items.map((option) => {
          const firstLetter = option.name[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            ...option,
          };
        })
      : [];

  return (
    <>
      {!loading ? (
        <>
          <Helmet>
            <title>{"APS | "} Relatório</title>
          </Helmet>
          <Formik
            initialValues={{ ...initialValues }}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: 2.5, align: "center" }}
                >
                  <Grid item xs={4}>
                    <FormDate name="initialDate" label="Inicio" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormDate
                      name="finalDate"
                      label="Fim"
                      minDate={new Date(values.initialDate)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <LightTooltip title="Analítico">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <AnalyticsIcon />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Série Histórica">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <HistoryIcon />
                        </IconButton>
                      </LightTooltip>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: 2.5 }}>
                  <Grid item xs={8}>
                    <FormMultipleSelectField
                      name={"employees"}
                      label={"Profissional: "}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      getOptionLabel={(employee) =>
                        employee.name ? employee.name : ""
                      }
                      isOptionEqualToValue={(myArrayEmployee, value) =>
                        value.id === myArrayEmployee.id ? true : false
                      }
                      groupBy={(option) => option.firstLetter}
                      keys={["id", "name"]}
                    />
                  </Grid>
                </Grid>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "right",
                    justifyContent: "right",
                    pt: 2,
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <SubmitButton loading={loadingButton}>
                      Gerar PDF/Impressão
                    </SubmitButton>
                  </Stack>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
