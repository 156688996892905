import { Grid, Button, Typography, Box, useTheme, Badge } from "@mui/material";
import React, { useContext, useState } from "react";
import {
  FormCheckbox,
  FormTextField,
  LunaAI,
  SubTitle,
} from "../../components";
import FormMultipleSelectField from "../../components/Form/FormMultipleSelectField";
import { DialogMedium, Dialog } from "../../helper";
import Reference from "./Reference/Reference";
import Prescription from "./Prescription/Prescription";
import Declaration from "./Declaration/Declaration";
import { AppContext } from "../../contexts/AppContext";
import { useFormikContext } from "formik";
import { useMutation, useQuery } from "react-query";
import { useLunaAI, usePatient, useProcedures } from "../../service";
import { useParams } from "react-router-dom";
import usePermissions from "../../config/usePermissions";
import { FormRadio, FormSelectWithPaginate } from "../../components/Form";
import Intervention from "./Interventions";
import EditorForms from "../Attendance/EditorForms/EditorForms";
import { ReactComponent as VitalSignIcon } from "../../assets/svg/vitalSign.svg";
import { useLocalStorage } from "../../hooks";
import { convertDateToBirthday, verifyAttendanceFlow } from "../../utils";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Drawer from "../../components/Drawer";
import { ReactComponent as LunaIconSVG } from "../../assets/svg/LUNA_IA_ICON.svg";

function Plan({ ciaps, attendance, conducts, allergies, admeasurment }) {
  const [referencesDialog, setReferencesDialog] = useState(false);
  const params = useParams();
  const idPatient = params.id;
  const [page] = useState(0);
  const [limit] = useState(5);
  const [prescriptionsDialog, setPrescriptionsDialog] = useState(false);
  const [declarationDialog, setDeclarationDialog] = useState(false);
  const [interventionDialog, setInterventionDialog] = useState(false);
  const [isLunaDrawerOpen, setIsLunaDrawerOpen] = useState(false);
  const {
    disableFields,
    drugOnSitePrescriptions,
    drugPrescriptions,
    examPrescriptions,
    againstReference,
    declarations,
    interventions,
    patientData,
    disabledFieldNames,
  } = useContext(AppContext);
  const { values } = useFormikContext();
  const drugPermissions = usePermissions("/soap/drugPrescription");
  const examsPermissions = usePermissions("/soap/examsPrescription");
  const lunaAIPermissions = usePermissions("/soap/lunaAI");
  const [userData] = useLocalStorage("user-data");
  const { postPatientSOA } = useLunaAI();
  const unitIsCaps = userData?.company?.type?.CAPS;

  const { getProgramsAvailableForPatient } = usePatient();
  const { getProcedures } = useProcedures();
  const [carePlanFormDialog, setCarePlanFormDialog] = useState(false);
  const [lunaResponse, setLunaResponse] = useState(null);
  const theme = useTheme();
  const programsQuery = useQuery(
    ["programsAvailableForPatient", [page, limit]],
    () => getProgramsAvailableForPatient(page, limit, idPatient),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      initialData: { items: [] },
    }
  );

  function handleReferenceClick() {
    setReferencesDialog(true);
  }

  function handlePrescriptionClick() {
    setPrescriptionsDialog(true);
  }

  function handleDeclarationClick() {
    setDeclarationDialog(true);
  }

  function handleInterventionClick() {
    setInterventionDialog(true);
  }

  function handleClose() {
    setReferencesDialog(false);
    setPrescriptionsDialog(false);
    setDeclarationDialog(false);
    setInterventionDialog(false);
  }

  const convertedBirthDate = convertDateToBirthday(
    patientData.birth_date,
    ["years"],
    false
  );

  const lengthPrescriptions =
    drugOnSitePrescriptions.length +
    drugPrescriptions.length +
    examPrescriptions.length;
  const lengthDeclarations = declarations.length;
  const lengthAgainstReference = againstReference.length;
  const lengthInterventions = interventions.length;

  function getAdmeasurmentId(name) {
    const admeasurmentId = admeasurment.find(
      (e) => e.describe.toLowerCase() === name.toLowerCase()
    )?.id;
    return admeasurmentId;
  }

  const lunaAIQuery = useMutation((payload) => postPatientSOA(payload), {
    onSuccess: (data) => {
      setLunaResponse(data);
    },
    onError: () => {
      setLunaResponse(null);
    },
  });

  async function handleLunaAIObj() {
    setIsLunaDrawerOpen(true);

    const lunaObj = {
      name: patientData.name,
      age: convertedBirthDate.years,
      gender: patientData.gender,
      subjective: {
        reason: values.reason,
        ciapSubjective: values.ciapSubjective,
      },
      objective: {
        physical_exams: values.physical_exams,
        preexisting_illnesses: values.doencasPreexistentes,
        vaccine: values.radioVacina,
        admeasurement_objective: {
          height: values.admeasurement_objective[getAdmeasurmentId("Altura")],
          weight: values.admeasurement_objective[getAdmeasurmentId("Peso")],
          hearth_rate:
            values.admeasurement_objective[
              getAdmeasurmentId("Frequência cardíaca")
            ],
          temperature:
            values.admeasurement_objective[getAdmeasurmentId("Temperatura")],
          saturation:
            values.admeasurement_objective[getAdmeasurmentId("Saturação")],
          respiratory_frequency:
            values.admeasurement_objective[
              getAdmeasurmentId("Frequência respiratória")
            ],
          capillary_blood_glucose:
            values.admeasurement_objective[
              getAdmeasurmentId("Glicemia capilar")
            ],
          abdominal_circumference:
            values.admeasurement_objective[
              getAdmeasurmentId("Circunferência abdominal")
            ],
          cephalic_perimeter:
            values.admeasurement_objective[
              getAdmeasurmentId("Perímetro cefálico")
            ],
          systolic_blood_pressure:
            values.admeasurement_objective[
              getAdmeasurmentId("Pressão arterial sistólica")
            ],
          diastolic_blood_pressure:
            values.admeasurement_objective[
              getAdmeasurmentId("Pressão arterial diastólica")
            ],
        },
        allergy_status: values.radioStatusAlergia,
        allergy_description: values.allergy_description,
        allergy_substances: values.allergy_substances,
        complementary_exams: values.complementary_exams,
      },
      evaluation: {
        consult: values.consult,
        ciapEvaluation: values.ciapEvaluation,
        cid: values.cid,
      },
    };
    lunaAIQuery.mutate(lunaObj);
  }

  function handleCloseLunaDrawer() {
    setIsLunaDrawerOpen(false);
  }

  function LunaIcon() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="10px"
      >
        <LunaIconSVG
          style={{
            width: "24px",
            paddingTop: "8px",
          }}
        />
        <Typography sx={{ fontWeight: "bold" }} variant="h5" component="h3">
          LunaAI
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {lunaAIPermissions.read && (
        <>
          <Grid item xs={12} justifyContent="flex-end" container>
            <Button
              variant="contained"
              style={{ color: "white" }}
              onClick={handleLunaAIObj}
              startIcon={<AutoFixHighIcon />}
              disabled={lunaAIQuery.isLoading}
            >
              Luna AI
            </Button>
          </Grid>

          <Drawer
            open={isLunaDrawerOpen}
            handleClose={handleCloseLunaDrawer}
            title={<LunaIcon />}
          >
            <LunaAI
              data={lunaResponse}
              isLoading={lunaAIQuery.isLoading}
              isError={lunaAIQuery.isError}
            />
          </Drawer>
        </>
      )}
      <Dialog
        open={carePlanFormDialog}
        title={"Plano de Cuidados"}
        maxWidth="md"
        fullWidth={true}
        handleClose={() => {
          setCarePlanFormDialog(false);
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 5,
          }}
        >
          {patientData?.social_prioritize
            ? patientData.social_name
            : patientData.name}
        </Typography>
        <EditorForms
          staticPatientId={patientData.id}
          showOnlyTable={true}
          staticType={"carePlan"}
        />
      </Dialog>
      <DialogMedium
        open={referencesDialog}
        title={"Referência / Contrarreferência"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData?.social_prioritize
            ? patientData.social_name
            : patientData.name}
        </Typography>
        <Reference patient={patientData} handleClose={handleClose} />
      </DialogMedium>
      <DialogMedium
        open={prescriptionsDialog}
        title={"Prescrição do Paciente"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData?.social_prioritize
            ? patientData.social_name
            : patientData.name}
        </Typography>
        <Prescription
          handleClose={handleClose}
          attendance={attendance}
          allergies={allergies}
        />
      </DialogMedium>
      <DialogMedium
        open={declarationDialog}
        title={"Gerar Declaração"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData?.social_prioritize
            ? patientData.social_name
            : patientData.name}
        </Typography>
        <Declaration
          handleClose={handleClose}
          defaultCids={values.cid}
          isPregnancy={values.pregnant}
          patient={patientData}
        />
      </DialogMedium>
      <DialogMedium
        open={interventionDialog}
        title={"Intervenções e/ou procedimentos clínicos realizados"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData?.social_prioritize
            ? patientData.social_name
            : patientData.name}
        </Typography>
        <Intervention attendance={attendance} onClose={handleClose} />
      </DialogMedium>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {userData?.company?.type?.CAPS && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => {
                  setCarePlanFormDialog(true);
                }}
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <VitalSignIcon fill={theme.palette.primary.main} />
                Plano de Cuidados
              </Button>
            </Grid>
          )}

          {!!values.pts ? (
            <Grid item xs={12} mt={1}>
              <FormTextField
                name="pts_describe"
                multiline
                minRows={3}
                maxRows={4}
                label="PTS descrição:"
                disabled={disableFields}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="plan"
            multiline
            minRows={4}
            maxRows={4}
            label="Plano de Consulta : "
            sx={{ mb: 2 }}
            disabled={disableFields}
          />
        </Grid>
        {attendance?.reevaluation === "Reavaliar" && (
          <Grid item xs={12}>
            <FormTextField
              name="reavaluation_comments"
              multiline
              minRows={3}
              maxRows={3}
              label="Reavaliação : "
              sx={{ mb: 2 }}
              disabled={disableFields}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormMultipleSelectField
            name="ciapPlan"
            options={ciaps || []}
            keys={["id", "description"]}
            label="CIAP : "
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={6}>
          <FormMultipleSelectField
            name="programs"
            options={programsQuery.data.items}
            keys={["id", "name"]}
            disabled={disableFields}
            label="Programas : "
          />
        </Grid>
        {(!!attendance?.scheduling || !attendance?.token_audit?.totem_id) && (
          <>
            <Grid item xs={3} mt={2.5}>
              <FormCheckbox
                name="confirm_procedure"
                label="Confirmar procedimento"
                disabled={
                  disableFields ||
                  disabledFieldNames.includes("confirm_procedure")
                }
              />
            </Grid>
            <Grid item xs={9}>
              <FormSelectWithPaginate
                service={(params) =>
                  getProcedures({
                    ...params,
                    type: "administrative",
                    filter: "active",
                    patient_id: patientData?.id,
                    attendance_flow: verifyAttendanceFlow(
                      attendance.risk_classification_id
                    ),
                    cid_id: values?.cid?.map(({ id }) => id),
                  })
                }
                searchBy="filters"
                name="plan_procedure"
                label="Procedimento administrativo:"
                getOptionLabel={(data) => {
                  let label = data?.name;

                  if (data?.tuss_code) {
                    label = `${data?.tuss_code} - ${label}`;
                  }

                  return label;
                }}
                disabled={disableFields || values.confirm_procedure}
                required
              />
            </Grid>
          </>
        )}
        {attendance?.service?.mab &&
          userData.employee_type.higher_education &&
          !attendance.scheduling && (
            <Grid item xs={6}>
              <FormRadio
                name="attendance_type"
                legend="Tipo de atendimento"
                radios={[
                  { label: "Consulta no dia", value: 5 },
                  { label: "Urgència", value: 6 },
                ]}
                keys={["label", "value"]}
                row={true}
                disabled={disableFields}
                required
              />
            </Grid>
          )}
      </Grid>
      <Box display="flex" gap={3} mt={2}>
        <Button
          variant="outlined"
          onClick={handlePrescriptionClick}
          disabled={
            disableFields ||
            !(
              drugPermissions.create ||
              drugPermissions.read ||
              examsPermissions.create ||
              examsPermissions.read
            )
          }
        >
          Prescrições
          <Badge
            color="secondary"
            overlap="circular"
            badgeContent={lengthPrescriptions}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          />
        </Button>
        <Button
          variant="outlined"
          onClick={handleReferenceClick}
          disabled={disableFields}
        >
          Referência / Contrarreferência
          <Badge
            color="secondary"
            overlap="circular"
            badgeContent={lengthAgainstReference}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          />
        </Button>
        <Button
          variant="outlined"
          onClick={handleDeclarationClick}
          disabled={disableFields}
        >
          Declarações
          <Badge
            color="secondary"
            overlap="circular"
            badgeContent={lengthDeclarations}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          />
        </Button>
        <Button
          variant="outlined"
          onClick={handleInterventionClick}
          disabled={disableFields}
        >
          Intervenções e/ou procedimentos clínicos
          <Badge
            color="secondary"
            overlap="circular"
            badgeContent={lengthInterventions}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          />
        </Button>
      </Box>
      {((!unitIsCaps && !!conducts.length && !disableFields) ||
        (disableFields && !!values.conducts?.length)) && (
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <SubTitle text="Condutas" sx={{ mb: 2 }} />
          </Grid>
          {conducts.map((data) => {
            return (
              <Grid key={data.id} item xs={6}>
                <FormCheckbox
                  name="conducts"
                  label={data.description.firstCharToUp()}
                  value={data.id}
                  multiple
                  disabled={disableFields}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
}

export default Plan;
