import React from "react";
import {
  BoxContainer,
  ColorInfoLabels,
  CustomizedBarChart,
} from "../../components";
import { useState } from "react";
import { DialogMedium, Loading } from "../../helper";
import useSIGA from "../../service/useCockPitSiga";
import { useQuery } from "react-query";
import usePermissions from "../../config/usePermissions";
import TableFilter from "../../components/Table/TableFilter";
import { Typography } from "@mui/material";
import { useCompany } from "../../service/useCompany";
import HasNoPermission from "../../components/RequireAuth/HasNoPermission";
import { format, parseISO } from "date-fns";
import { theme } from "../../config/theme";
import { useNotifier } from "../../hooks";

const CockPitSiga = () => {
  const { getCompanies } = useCompany();
  const [companies, setCompanies] = useState([]);
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [filters, setFilters] = useState({});
  const { getCockPitSiga } = useSIGA();
  const notify = useNotifier();
  const SigaPermission = usePermissions("/cockpit/siga");
  const [sigaIntegrations, setSigaIntegrations] = useState();

  const barConfig = {
    bars: [{ key: "number", fill: "url(#gdr2)" }],
    gradients: [
      {
        id: "gdr2",
        from: `${theme.palette.secondary.main}`,
        to: `${theme.palette.primary.main}`,
        x: "1",
      },
    ],
  };

  const HorizontalBarChart = ({ data }) => {
    return (
      <CustomizedBarChart
        sx={{ minWidth: 120 }}
        width={120}
        height={40}
        data={[{ number: data }]}
        config={barConfig}
        layout={"vertical"}
        x={{ hide: true, type: "number" }}
        y={{ hide: true, type: "category" }}
        radius={7}
        barSize={"100%"}
        limit={100}
        label={{
          position: "right",
          fontSize: "1rem",
          fontWeight: 800,
          fill: `${theme.palette.primary.light}`,
          formatter: (value) => value + "%",
        }}
        margin={{ bottom: -20 }}
      />
    );
  };

  const SIGADataQuery = useQuery(
    ["SIGAData", filters],
    () =>
      getCockPitSiga({
        initial_date: initialDate,
        final_date: finalDate,
        ...filters,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError(data) {
        notify(data.message, "error");
      },
      onSuccess: (data) => {
        setSigaIntegrations(data);
      },
    }
  );

  useQuery(["company"], () => getCompanies({ limit: 75 }), {
    onSuccess: (data) => {
      const conpanies = data?.items?.map((c) => ({
        label: c.name,
        value: c.id,
      }));
      setCompanies(conpanies);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  function handleFilter(values) {
    setFilters(values);
  }

  const filterOptions = [
    {
      name: "Data inicial",
      type: "date",
      field: "initial_date",
    },
    {
      name: "Data final",
      type: "date",
      field: "final_date",
    },
  ];

  const tableColumns = [
    {
      name: "Unidade(s) / Dias",
      field: "company",
      type: "select",
      options: companies,
      filter: "company_id",
    },
    {
      name: "Registro(s)",
      field: "quantity_attendance",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "A Integrar",
      field: "to_integrate",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "Integrado(s)",
      field: "success",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "Reprocessado(s)",
      field: "reprocessing",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "Não Processado",
      field: "failed",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "Manual",
      field: "processed_manually",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "Registro Pendente",
      field: "pending",
      tdColor: "rgba(216, 0, 0, 0.1)",
      use: (value) => (
        <Typography
          sx={{
            textAlign: "center",
            height: "100% ",
          }}
        >
          {value}
        </Typography>
      ),
    },
    {
      name: "% Processado(s)",
      field: "failed_percentage",
      use: (value) => <HorizontalBarChart data={value} />,
    },
  ];

  const tableColumnsCollapse = [
    {
      name: "Dia",
      field: "date",
      use: (value) => {
        return format(parseISO(value), "dd/MM/yyyy");
      },
    },
    {
      name: "Registro(s)",
      field: "quantity_attendance",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "A Integrar",
      field: "to_integrate",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "Integrado(s)",
      field: "success",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "Reprocessado(s)",
      field: "reprocessing",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "Não Processado",
      field: "failed",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "Manual",
      field: "processed_manually",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "Registro Pendente",
      field: "pending",
      tdColor: "rgba(216, 0, 0, 0.1)",
      use: (value) => (
        <Typography sx={{ textAlign: "center" }}>{value}</Typography>
      ),
    },
    {
      name: "% Processado(s)",
      field: "failed_percentage",
      use: (value) => (
        <HorizontalBarChart sx={{ textAlign: "center" }} data={value} />
      ),
    },
  ];
  return SigaPermission.read ? (
    <BoxContainer title={`Painel RPA`}>
      <ColorInfoLabels
        labels={[
          {
            text: "Registro Pendente",
            color: "rgba(216, 0, 0, 0.1)",
          },
        ]}
      />
      {SIGADataQuery.isLoading ? (
        <Loading maxWidth={"100%"} backgroundColor={"rgba(255,255,255,0.7)"} />
      ) : null}
      <TableFilter
        columns={tableColumns}
        data={sigaIntegrations}
        handleFilter={handleFilter}
        othersFilter={filterOptions}
        collapseColumns={tableColumnsCollapse}
        collapseDataKey={"data_by_company"}
      />
    </BoxContainer>
  ) : (
    <HasNoPermission />
  );
};

export default CockPitSiga;
