import { useRef, useState } from "react";

function useLocalStorage(key, initialValue) {
  const originalValue = useRef(null);

  const [storedValue, setStoredValue] = useState(() => {
    const isCallback = initialValue instanceof Function;
    let value = initialValue;

    if (typeof window === "undefined" && isCallback) {
      value = initialValue();
    }

    try {
      const item = window.localStorage.getItem(key);
      let parsedItem = null;

      if (item) {
        parsedItem = JSON.parse(item);
      }

      if (isCallback) {
        value = initialValue(parsedItem);
      }

      if (parsedItem) {
        value = parsedItem;
      }      
    } catch (error) {
      console.log(error);

      if (isCallback) {
        value = initialValue();
      }
    }

    localStorage.setItem(key, JSON.stringify(value));
    originalValue.current = value;
    return value;
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      originalValue.current = valueToStore;
      setStoredValue(valueToStore);

      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue, originalValue.current];
}

export default useLocalStorage;
