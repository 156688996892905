import { CssBaseline } from "@mui/material";
import {
  unstable_HistoryRouter as BrowserRouter,
  Route,
  Routes as Router,
} from "react-router-dom";
import routesObject from "./config/routesObject";
import { AppStorage } from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";
import { FormMakerProvider } from "./contexts/FormMakerContext";
import { styled } from "@mui/system";
import Image from "./assets/img/bgTexture.png";
import history from "./config/BrowserHistory";

const BodyBackground = styled("div")({
  backgroundImage: `url(${Image})`,
  height: "100vh",
});

const Routes = () => {
  function routesGenerator(locales) {
    if (!locales) {
      return;
    }
    const result = locales?.map(({ path, component, name, subRoutes }) => {
      return (
        <Route path={path} element={component} key={name || path}>
          {routesGenerator(subRoutes)}
        </Route>
      );
    });

    return result;
  }

  return (
    <BrowserRouter history={history}>
      <AuthProvider>
        <AppStorage>
          <FormMakerProvider>
            <CssBaseline>
              <BodyBackground>
                <Router>{routesGenerator(routesObject)}</Router>
              </BodyBackground>
            </CssBaseline>
          </FormMakerProvider>
        </AppStorage>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
