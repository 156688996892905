import TableFilter from "../../../../components/Table/TableFilter";
import { useQuery } from "react-query";
import { Box, Typography, TablePagination } from "@mui/material";
import useGeneralAnnotations from "../../../../service/useGeneralAnnotations";
import { format, parseISO } from "date-fns";
import { DialogMedium, Loading } from "../../../../helper";
import { useState } from "react";
export default function GeneralAnnotationsList({ patient }) {
  const [view, setView] = useState();
  const [filters, setFilters] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { getGeneralAnnotations } = useGeneralAnnotations();
  const annotations = useQuery(
    ["annotation-query", filters, page, limit],
    () =>
      getGeneralAnnotations({
        patient_id: patient.id,
        page,
        limit,
        ...filters,
      }),
  );

  const columns = [
    {
      name: "Data",
      field: "updatedAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
      filter: "date",
      type: "date",
    },
    {
      name: "Hora",
      field: "updatedAt",
      use: (date) => format(parseISO(date), "HH:mm"),
    },
    {
      name: "Profissional",
      field: "finished_by?.name",
      filter: "employee_name",
      type: "text",
    },
    {
      name: "Atividade",
      field: "activity?.describe",
      filter: "activity_describe",
      type: "text",
    },
  ];

  const otherFilters = [
    {
      name: "CNS Profissional",
      field: "employee_cns",
      type: "text",
    },
  ];

  return (
    <Box>
      {annotations.isFetching ? (
        <Loading
          maxWidth={"100%"}
          message="Buscando Anotações Gerais..."
          backgroundColor={"rgba(255,255,255,0.8)"}
        />
      ) : null}
      <DialogMedium
        title={view?.activity?.describe}
        open={!!view}
        handleClose={() => setView()}
      >
        <Typography textAlign={'justify'} color={'primary.light'}>{view?.annotation}</Typography>
      </DialogMedium>
      <TableFilter
        data={annotations.data?.items}
        columns={columns}
        othersFilter={otherFilters}
        handleFilter={setFilters}
        actions
        actionsTypes={["view"]}
        actionHandleView={(_, row) => {
          setView(row);
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={annotations.data?.totalItems}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </Box>
  );
}
