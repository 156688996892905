import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { FormTextField, SubTitle } from "../../components";
import FormCheckbox from "../../components/Form/FormCheckbox";
import FormMultipleSelectField from "../../components/Form/FormMultipleSelectField";
import { useCids } from "../../service";
import { AppContext } from "../../contexts/AppContext";
import { useQuery } from "react-query";
import { FormSelectWithPaginate } from "../../components/Form";
import useOdonto from "../../service/useOdonto";
import { useLocalStorage } from "../../hooks";
import { useFormikContext } from "formik";

export default function Evaluation({ ciapsEvaluation, odonto, patientID }) {
  const { values } = useFormikContext();
  const [filter, setFilter] = React.useState(false);
  const [ciapIds, setCiapIds] = React.useState(() => {
    const ciapIds = [];

    values?.ciapEvaluation?.forEach(({ id }) => {
      ciapIds.push(id);
    });

    return ciapIds;
  });
  const [userData] = useLocalStorage("user-data");
  const { disableFields, setActiveProblemsToInactive } =
    React.useContext(AppContext);
  const isNotWomanDiagnostic = !userData?.company.type.CEM;

  const { getCids } = useCids();
  const { getOralHealthSurveillance } = useOdonto();

  const oralHealthSurveillance = useQuery(
    "getOralHealthSurveillance",
    () => getOralHealthSurveillance(),
    {
      enabled: odonto,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  function handleInactive(previousActiveProblem, currentActiveProblem) {
    if (previousActiveProblem.length > currentActiveProblem.length) {
      const removedActiveProblem = previousActiveProblem.find((previous) =>
        currentActiveProblem.every((current) => previous.id !== current.id)
      );

      setActiveProblemsToInactive((activeProblems) => [
        ...activeProblems,
        removedActiveProblem,
      ]);
    }

    const ciapIds = currentActiveProblem.map(({ id }) => id);
    setCiapIds(ciapIds);
  }

  async function handleCodeAndDescriptionSearch(params) {
    console.log(filter, ciapIds);
    return await getCids({
      ...params,
      patient_id: patientID,
      ciap_id: filter ? ciapIds : [],
    });
  }

  return (
    <>
      <Grid item xs={12}>
        <FormTextField
          name="consult"
          multiline
          minRows={4}
          maxRows={4}
          label="Avaliação da Consulta : "
          sx={{ mb: 2 }}
          disabled={disableFields}
        />
      </Grid>
      <SubTitle text="Problemas Detectados" sx={{ mb: 2 }} />
      <Grid container item spacing={2.5}>
        <Grid item xs={12}>
          <FormMultipleSelectField
            required={
              isNotWomanDiagnostic &&
              (!userData.employee_type.use_cids ||
                [
                  "a384c778-b1d4-445e-9482-5ed440dac913",
                  "66dc5b43-bfba-4773-8ce4-a6df6c838368",
                ].includes(userData?.employee_type?.id))
            }
            name="ciapEvaluation"
            options={ciapsEvaluation ?? []}
            customHandleChange={handleInactive}
            keys={["id", "description"]}
            label="CIAP: "
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSelectWithPaginate
            service={handleCodeAndDescriptionSearch}
            fields="description"
            multiple={true}
            disablePortal={false}
            label={"Cid:"}
            name="cid"
            searchBy="fieldValue"
            maxItems={7}
            required={!!userData.employee_type.use_cids && isNotWomanDiagnostic}
            disabled={disableFields}
            getOptionLabel={(cid) =>
              `${cid.id ? cid.id : ""} - ${cid.description}`
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormCheckbox
            name="filter"
            customChange={(check) => setFilter(check)}
            label="Filtrar CID10 por CIAP"
            disabled={disableFields}
          />
          <FormCheckbox
            name="insert_in_active_problems"
            label="Inserir Na Lista de Problemas"
            disabled={disableFields}
          />
        </Grid>
        {odonto && (
          <>
            <Grid item xs={12} display={"grid"} rowGap={1}>
              <Typography color={"secondary"}>
                Vigilância em saúde bucal
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={6} display={"flex"} flexWrap={"wrap"} rowGap={1}>
              {oralHealthSurveillance.data?.map((i) => (
                <FormCheckbox
                  name="oral_health_surveillance"
                  value={i.id}
                  multiple
                  customChange={(check) => setFilter(check)}
                  label={i.description}
                  disabled={disableFields}
                />
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
