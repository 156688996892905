import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

function PaginatedArrayList({ title, data, limit }) {
  const [page, setPage] = useState(0);
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    const list = data?.slice(page * limit, page * limit + limit);
    setItemsList(list);
  }, [page, data]);

  useEffect(() => {
    setPage(0);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        padding: 1,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h6" color="secondary" fontWeight="700">
          {title}
        </Typography>

        <Divider
          sx={{
            backgroundColor: "#D3E3ED",
            mt: 1,
            mb: 1,
          }}
        />
        <TableContainer component={Paper}>
          <Table
            padding="none"
            sx={{ minWidth: "100%" }}
            aria-label="custom pagination table"
          >
            <TableBody>
              {itemsList?.map((item, index) => (
                <TableRow key={`listItem-${index}`}>
                  <TableCell component="th" scope="row" sx={{ pb: "5px" }}>
                    {item}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        count={data?.length}
        rowsPerPageOptions={[3]}
        onPageChange={(_, value) => setPage(value)}
        page={page}
        rowsPerPage={limit}
      />
    </Box>
  );
}

export default PaginatedArrayList;
