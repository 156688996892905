import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { format, parseISO } from "date-fns";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import { useAgainstReferences } from "../../../service";
import AgainstReferenceView from "./AgainstReferenceView";
import { DialogMedium, Loading } from "../../../helper";
import { Box, TablePagination } from "@mui/material";

import { againstReferencePDF, openURL } from "../../../pdfModels";
import useNotifier from "../../../hooks/useNotifier";

export default function MyAgainstReferences() {
  const { myReferences, suspendReference } = useAgainstReferences();
  const queryClient = useQueryClient();
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [unicReference, setUnicReference] = React.useState();
  const [openView, setOpenView] = React.useState(false);
  const [action, setAction] = React.useState();
  const notify = useNotifier();
  const againstReferenceQuery = useQuery(
    ["myAgainstReferences", [page, limit]],
    () => myReferences(page, limit),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      initialData: { items: [] },
      onSuccess: (response) => {
        setCount(response.totalItems);
      },
    }
  );

  const references = againstReferenceQuery.data.items.map(
    ({
      response,
      justification,
      number,
      requested_specialty,
      requested_employee,
      priority,
      id,
      createdAt,
      soap,
      suspendedAt,
      cids,
      details,
    }) => {
      return {
        createdAt,
        requestor: soap.employee_specialty.employee,
        requestor_name: soap.employee_specialty.employee.name,
        requested_employee,
        requested_specialty: requested_specialty.describe,
        priority,
        justification,
        suspendedAt,
        number,
        response,
        id,
        details,
        patient: soap?.attendance?.patient,
        cids: cids,
        soap,
      };
    }
  );

  const rows = againstReferenceQuery.data.items.map(
    ({
      createdAt,
      soap,
      requested_specialty,
      suspendedAt,
      response,
      signed,
    }) => {
      if (suspendedAt === null && response === null) {
        suspendedAt = "Aberto";
      } else if (suspendedAt) {
        suspendedAt = "Suspenso";
      } else if (response) {
        suspendedAt = "Fechado";
      }
      let disabled;
      if (suspendedAt === "Suspenso" || suspendedAt === "Fechado") {
        disabled = true;
      } else {
        disabled = false;
      }
      const dateReference = format(parseISO(createdAt), "dd/MM/yyyy");
      return {
        dateReference,
        patient_name: soap?.attendance?.patient?.name,
        requested_specialty: requested_specialty.describe,
        situation: suspendedAt,
        suspended: disabled,
        signed,
      };
    }
  );

  const tableColumns = [
    { name: "Data Solicitada" },
    { name: "Paciente" },
    { name: "Especialidade Requisitada" },
    { name: "Situação" },
  ];

  async function handleSuspend(index) {
    const id = index.id;
    if (!index.suspendedAt && !index.response) {
      try {
        await suspendReference(id);
        notify("Referência / Contrarreferência Suspensa", "success");
        queryClient.invalidateQueries("myAgainstReferences");
      } catch {
        notify("Falha de Comunicação com o Servidor", "error");
      }
    } else if (index.suspendedAt && !index.response) {
      notify("Esta Referência / Contrarreferência Já Foi Suspensa", "error");
    } else if (!index.suspendedAt && index.response) {
      notify(
        "Não é possível Suspender uma Referência / Contrarreferência já Respondida",
        "error"
      );
    }
  }

  function handleView() {
    setOpenView(true);
    setAction("view");
  }

  function printAgainstReference(index) {
    const againstReference = againstReferenceQuery.data?.items[index];

    if (againstReference?.signature) {
      openURL(againstReference.signature.result);
      return;
    }

    againstReference.requested_specialty =
      againstReference.requested_specialty.describe;

    againstReferencePDF(
      againstReference,
      againstReference.soap?.attendance?.patient,
      againstReference.cids
    ).open();
  }

  function handleClose() {
    setOpenView(false);
  }

  if (againstReferenceQuery.isLoading) return <Loading />;

  return (
    <>
      <DialogMedium
        title="Visualizar Referência / Contrarreferência"
        open={openView}
        type={action}
        handleClose={handleClose}
      >
        <AgainstReferenceView reference={unicReference} />
      </DialogMedium>
      <PaginatedTable
        columns={tableColumns}
        data={rows}
        loading={
          againstReferenceQuery.isFetching &&
          againstReferenceQuery.isPreviousData
        }
        actions
        actionsTypes={["view", "suspend", "print"]}
        disableRows={(row) => row.suspended}
        disableActions={(row, action) => {
          if (action === "suspend") return row.suspended;
        }}
        actionHandleView={(index) => {
          setUnicReference(references[index]);
          handleView(index);
        }}
        actionHandleSuspend={(index) => {
          handleSuspend(references[index]);
        }}
        actionHandlePrint={printAgainstReference}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={count}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimit(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}
