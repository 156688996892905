import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/care-plans";

function useCarePlan() {
  async function getCarePlans(params) {
    try {
      const response = await instance.get(path, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postCarePlan(data) {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function putCarePlan({ id, data }) {
    try {
      const response = await instance.put(path + `/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function deleteCarePlanItems({ id, data }) {
    try {
      const response = await instance.post(
        path + `/${id}/items/batch-delete`,
        data
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getCarePlans,
    postCarePlan,
    putCarePlan,
    deleteCarePlanItems,
  };
}
export default useCarePlan;
