import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { TextField } from "../../../../components/FormFields";
import { AppContext } from "../../../../contexts/AppContext";
import { useFormikContext } from "formik";
import { useContext } from "react";

export default function ProsthesisCommand({ handleSave }) {
  const { disableFields, setProsthesisCommand } = useContext(AppContext);
  const { values, setFieldValue } = useFormikContext();
  const form = useForm({
    defaultValues: values.prosthesisCommand || {
      box_number: "",
      work_type: "",
      tooth_color: "",
      notes: "",
    },
  });

  function clearValues() {
    form.reset({
      box_number: "",
      work_type: "",
      tooth_color: "",
      notes: "",
    });
  }

  function handleSubmit(values) {
    const hasValues = Object.values(values).some((field) => !!field);
    if (hasValues) {
      setProsthesisCommand(1);
      setFieldValue("prosthesisCommand", values);
      handleSave();
    } else {
      setProsthesisCommand(0);
      setFieldValue("prosthesisCommand", null);
      handleSave();
    }
  }
  return (
    <Grid container item xs={12} spacing={2} marginTop="0.5rem">
      <Grid item xs={12}>
        <TextField
          disabled={disableFields}
          control={form.control}
          name={"box_number"}
          label={"N° Caixa"}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={disableFields}
          control={form.control}
          name={"work_type"}
          label={"Tipo de Trabalho"}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={disableFields}
          control={form.control}
          name={"tooth_color"}
          label={"Cor do dente"}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={disableFields}
          control={form.control}
          name={"notes"}
          label={"Observações"}
          multiline
          minRows={4}
        />
      </Grid>
      <Grid item xs={12} display={"flex"} columnGap={3}>
        <Button variant="contained" onClick={form.handleSubmit(handleSubmit)}>
          Salvar
        </Button>
        <Button variant="outlined" onClick={clearValues}>
          Limpar
        </Button>
      </Grid>
    </Grid>
  );
}
