import { useTheme } from "@emotion/react";
import { Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/esm/locale/pt-BR";
import PatientServicesDialog from "../PatientServicesDialog";
import { AppContext } from "../../contexts/AppContext";
function AttendanceCard({
  attendance,
  old,
  handleClick,
  onDialog = false,
  ...props
}) {
  const [openPatientServices, setOpenPatientServices] = React.useState(false);
  const theme = useTheme();
  const { setEmployeeData, setPatientData } = React.useContext(AppContext);

  const BoxContainer = styled(Grid)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
  }));

  const BoxItem = styled(Grid)({
    borderRight: `1px solid ${theme.palette.primary.main}`,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    padding: "10px",
    "&:last-child": {
      position: "relative",
      borderRight: "none",
    },
  });

  const BoxAvatar = styled(Box)({
    display: "flex",
    alignItems: "center",
    position: "relative",
    gap: "20px",
  });

  function handleClose() {
    setOpenPatientServices({ isOpen: false });
  }
  return (
    <>
      <PatientServicesDialog
        title={"Atendimento Inicial"}
        handleClose={handleClose}
        open={openPatientServices}
      />
      <BoxContainer
        {...props}
        container
        onDoubleClick={
          !old
            ? () => {
                if (onDialog) return;
                setEmployeeData(attendance?.employee);
                setPatientData(attendance?.scheduling?.patient);
                setOpenPatientServices({ isOpen: true, attendance });
              }
            : () => {}
        }
        sx={{
          backgroundColor: theme.palette.secondary.light,
          transition: ".3s",
        }}
      >
        <BoxItem xs={12}>
          <BoxAvatar sx={{ width: "100%", height: "100%" }}>
            <Stack xs={12} sx={{ width: "100%", height: "100%" }}>
              <Typography
                component="span"
                fontWeight="bold"
                fontSize={15}
                color="secondary.main"
              >
                {attendance?.patient?.name}
              </Typography>

              <Typography fontSize={12} color="secondary.main">
                Cód. Paciente :{" "}
                <Typography component="span" fontWeight="550" fontSize={12}>
                  {attendance?.patient?.number}
                </Typography>
              </Typography>
              <Typography fontSize={12} color="secondary.main">
                CNS : {attendance?.patient?.sus_card}
              </Typography>
              <Typography fontSize={12} color="secondary.main">
                Nº Atendimento : {attendance?.number}
              </Typography>
              <Typography fontSize={12} color="secondary.main">
                Dt. Nascimento :{" "}
                {format(
                  parseISO(attendance?.patient.birth_date),
                  "dd/MM/yyyy",
                  {
                    locale: ptBR,
                  }
                )}
              </Typography>
              <Typography fontSize={12} color="secondary.main">
                Data e Hora Atend. :{" "}
                {format(
                  parseISO(attendance?.createdAt),
                  "dd/MM/yyyy | HH:mm:ss",
                  {
                    locale: ptBR,
                  }
                )}
              </Typography>
              <Typography fontSize={12} color="secondary.main">
                Serviço : {attendance?.service?.describe}
              </Typography>
              <Typography fontSize={12} color="secondary.main">
                Nome da Mãe : {attendance?.patient?.mother_name}
              </Typography>
            </Stack>
          </BoxAvatar>
        </BoxItem>
      </BoxContainer>
    </>
  );
}

export default React.memo(AttendanceCard);
