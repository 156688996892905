import React from "react";
import { Button as MUIButton, CircularProgress } from "@mui/material";
import { useTheme } from "@emotion/react";

const Button = ({
  loading,
  loadingMessage = "Enviando...",
  variant = "contained",
  disabled,
  children,
  ...props
}) => {
  const theme = useTheme();

  return (
    <MUIButton
      variant={variant}
      disabled={disabled || loading}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        "&:disabled": {
          color:
            variant === "contained" ? "white" : theme.palette.primary.light,
          background:
            variant === "contained"
              ? theme.palette.primary.light
              : "transparent",
          opacity: 0.4,
        },
        ...props.sx,
      }}
      {...props}
    >
      {loading ? loadingMessage : children}
      {loading && (
        <CircularProgress
          sx={{
            color:
              variant === "contained" ? "white" : theme.palette.primary.light,
          }}
          size={20}
        />
      )}
    </MUIButton>
  );
};

export default Button;
