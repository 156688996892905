import React, { useContext } from "react";
import HistoryDeclaration from "./HistoryDeclaration";
import { Tabs } from "../../../components";
import usePermissions from "../../../config/usePermissions";
import { AppContext } from "../../../contexts/AppContext";
import { Badge } from "@mui/material";
import DeclarationForm from "./DeclarationForm";

function Declaration({
  handleClose,
  patient,
  defaultCids,
  historyOnly,
  isPregnancy,
  odonto,
  ceo,
}) {
  const permission = usePermissions("/soap/statement");
  const { declarations } = useContext(AppContext);

  let tabs = [];

  if (permission.create) {
    tabs = [
      {
        label: "Declarações",
        content: (
          <DeclarationForm
            handleClose={handleClose}
            defaultCids={defaultCids}
            isPregnancy={isPregnancy}
            patient={patient}
            odonto={odonto}
            ceo={ceo}
          />
        ),
        icon: (
          <Badge
            color="secondary"
            overlap="circular"
            badgeContent={declarations?.length ?? 0}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          />
        ),
      },
      {
        label: "Histórico de Declarações",
        content: <HistoryDeclaration handleClose={handleClose} />,
      },
    ];
  }

  return historyOnly ? (
    <HistoryDeclaration handleClose={handleClose} patient={patient} />
  ) : (
    <Tabs tabs={tabs} />
  );
}

export default Declaration;
