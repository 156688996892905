import React from "react";
import { usePagination, useSector } from "../../../service";
import { useMutation, useQuery } from "react-query";
import { DialogMedium} from "../../../helper";
import { Box, Button, Grid, TablePagination, Typography } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import Yup from "../../../config/yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@emotion/react";
import { TextField, SwitchField } from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

export default function SectorsStep() {
  const notify = useNotifier();
  const theme = useTheme();

  const { getSectors, postSector, updateSector } = useSector();
  const [limit, setLimit] = React.useState(5);
  const [selectedSector, setSelectedSector] = React.useState();
  const [editSectorDialog, setEditSectorDialog] = React.useState(false);

  const sectorMutation = useMutation(postSector);
  const updateSectorMutation = useMutation(updateSector);

  const sectorsQuery = useQuery(["sectors"], getSectors, {
    retry: false,
    initialData: [],
    onError: (err) => {
      notify(err.message, "error");
    },
  });

  const { paginationInfo, handlePageChange } = usePagination(
    sectorsQuery.data,
    limit
  );

  const columnsSector = [
    {
      field: "number",
      name: "Codigo",
      type: "string",
    },
    {
      field: "describe",
      name: "Descrição",
      type: "string",
    },
    {
      field: "active",
      name: "Status",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
      alignInRow: "center",
      alignInHead: "center",
    },
  ];

  const validator = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      describe: "",
    },
    resolver: yupResolver(validator),
  });

  const updateSectorForm = useForm({
    defaultValues: {
      describe: selectedSector?.describe,
      active: selectedSector?.active,
    },
    resolver: yupResolver(validator),
  });

  const onSubmit = handleSubmit((data) => {
    sectorMutation.mutate(data, {
      onSuccess: (res) => {
        reset();
        notify(res.message, "success");
        sectorsQuery.refetch();
      },
      onError: (err) => {
        notify(err.message, "error");
      },
    });
  });

  const onSubmitUpdate = updateSectorForm.handleSubmit((data) => {
    updateSectorMutation.mutate(
      { id: selectedSector.id, data },
      {
        onSuccess: (res) => {
          updateSectorForm.reset();
          notify(res.message, "success");
          sectorsQuery.refetch();
          handleCloseDialog();
        },
        onError: (err) => {
          notify(err.message, "error");
        },
      }
    );
  });

  const handleCloseDialog = () => {
    setSelectedSector({});
    setEditSectorDialog(false);
  };

  return (
    <>
      <DialogMedium
        open={editSectorDialog}
        handleClose={handleCloseDialog}
        title="Edição de setor"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField
              control={updateSectorForm.control}
              name="describe"
              label="Descrição"
            />
          </Grid>
          <Grid item xs={3}>
            <SwitchField
              control={updateSectorForm.control}
              name="active"
              label="Alterar Status"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={onSubmitUpdate}
              onLoad={updateSectorMutation.isLoading}
            >
              Atualizar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>

      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Setores
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items}
          columns={columnsSector}
          loading={sectorsQuery.isLoading}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit"]}
          shouldDisableFields={["id"]}
          actionHandleEdit={(_, item) => {
            setSelectedSector(item);
            updateSectorForm.setValue("describe", item.describe);
            updateSectorForm.setValue("active", item.active);
            setEditSectorDialog(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Setor
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            control={control}
            name={"describe"}
            label={"Descrição"}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={onSubmit}
            onLoad={sectorMutation.isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
