import { Box, Button, TablePagination, Typography } from "@mui/material";
import TableFilter from "../../../../components/Table/TableFilter";
import { useState } from "react";
import { DialogMedium } from "../../../../helper";
import { format, parseISO } from "date-fns";
import DoubleCheckForm from "./Form";
import useDoubleCheck from "../../../../service/useDoubleCheck";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNotifier } from "../../../../hooks";
import DoubleCheckSuspendForm from "./Suspend";

export default function DoubleCheckEvolution({ medicationOnSiteID }) {
  const notify = useNotifier();
  const [openView, setOpenView] = useState(false);
  const [toView, setToView] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState();
  const [suspendEvolution, setSuspendEvolution] = useState();
  const [doubleCheckToEdit, setDoubleCheckToEdit] = useState();
  const queryClient = useQueryClient();
  const { getDoubleChecks, patchDoubleCheck, deleteDoubleCheck } =
    useDoubleCheck();
  const patchMutation = useMutation({
    mutationKey: "patch-double-check",
    mutationFn: patchDoubleCheck,
  });
  const deleteMutation = useMutation({
    mutationKey: "delete-double-check",
    mutationFn: deleteDoubleCheck,
  });
  const columns = [
    {
      name: "Data",
      field: "finishedAt",
      condition: "row.finishedAt",
      otherwise: (row) =>
        row.createdAt
          ? format(parseISO(row.createdAt), "dd/MM/yyyy 'às' HH:mm")
          : "",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy 'às' HH:mm") : "",
    },
    {
      name: "Profissional",
      use: (_, row) => row.finished_by?.name || row.created_by.name,
      type: "text",
      filter: "employee_name",
    },
    {
      name: "Status",
      field: "canceledAt",
      condition: "row.canceledAt",
      type: "select",
      filter: "status",
      use: () => "Suspenso",
      otherwise: (row) =>
        row.status === "filling" ? "Preenchendo" : "Finalizado",
      options: [
        {
          label: "Suspendido",
          value: "suspended",
        },
        {
          label: "Preenchendo",
          value: "filling",
        },
        {
          label: "Finalizado",
          value: "finished",
        },
      ],
    },
  ];

  const otherFilter = [
    {
      name: "CNS Profissional",
      filter: "employee_cns",
      type: "text",
    },
    {
      name: "Data inicial",
      filter: "initial_date",
      type: "date",
    },
    {
      name: "Data final",
      filter: "final_date",
      type: "date",
    },
  ];

  const doubleCheckQuery = useQuery(
    ["get-double-checks", page, limit, medicationOnSiteID, filter],
    () =>
      getDoubleChecks({
        page,
        limit,
        medication_on_site_id: medicationOnSiteID,
        ...filter,
      }),
    {
      enabled: !!medicationOnSiteID,
    },
    {
      onError() {
        queryClient.setQueryData(
          ["get-double-checks", page, limit, medicationOnSiteID],
          null
        );
      },
    }
  );

  function handleCloseView() {
    setOpenView(false);
    setToView("");
  }

  function updateDoubleCheck(id, data) {
    patchMutation.mutate(
      { id, data },
      {
        onSuccess(data) {
          notify(data.message);
          doubleCheckQuery.refetch();
        },
        onError(data) {
          notify(data.message, "error");
        },
      }
    );
  }

  function handleDelete(id) {
    deleteMutation.mutate(id, {
      onSuccess(data) {
        notify(data.message);
        doubleCheckQuery.refetch();
      },
      onError(data) {
        notify(data.message, "error");
      },
    });
  }

  function handleCloseForm() {
    setOpenForm(false);
    setSuspendEvolution();
    doubleCheckQuery.refetch();
  }

  return (
    <Box>
      <DialogMedium
        open={!!suspendEvolution}
        title={`Suspensão de dupla checagem`}
        maxWidth="md"
        handleClose={handleCloseForm}
      >
        <DoubleCheckSuspendForm
          onSuspend={updateDoubleCheck}
          toSuspend={suspendEvolution}
          handleClose={handleCloseForm}
        />
      </DialogMedium>
      <DialogMedium open={openView} handleClose={handleCloseView}>
        <Typography>{toView}</Typography>
      </DialogMedium>
      <DialogMedium
        title={"Dupla checagem"}
        open={openForm}
        fullWidth
        handleClose={handleCloseForm}
      >
        <DoubleCheckForm
          medicationOnSiteID={medicationOnSiteID}
          handleClose={handleCloseForm}
          toEdit={doubleCheckToEdit}
        />
      </DialogMedium>
      <TableFilter
        data={doubleCheckQuery.data?.items}
        actions
        actionsTypes={["view", "edit", "delete", "suspend", "finish"]}
        disableActions={(row, action) => {
          return (
            (["finish", "delete", "edit"].includes(action) &&
              !!row.finishedAt) ||
            (!["view"].includes(action) && row.canceledAt) ||
            (["suspend"].includes(action) && row.status === "filling")
          );
        }}
        handleFilter={setFilter}
        columns={columns}
        othersFilter={otherFilter}
        actionUniqueIdentifier={"id"}
        actionHandleView={(_, row) => {
          setOpenView(true);
          setToView(row.notes);
        }}
        actionHandleFinish={(id) => {
          updateDoubleCheck(id, { status: "finished" });
        }}
        actionHandleSuspend={(id) => {
          setSuspendEvolution(id);
        }}
        actionHandleDelete={(id) => {
          handleDelete(id);
        }}
        actionHandleEdit={(id, row) => {
          setDoubleCheckToEdit({ id, notes: row.notes });
          setOpenForm(true);
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={doubleCheckQuery.data?.totalItems || 0}
          component="div"
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value), 10);
            setPage(0);
          }}
        />
      </Box>
      <Box>
        <Button variant="contained" onClick={() => setOpenForm(true)}>
          Novo Cadastro
        </Button>
      </Box>
    </Box>
  );
}
