import {
  Box,
  Typography,
  CardHeader,
  CardContent,
  Card,
  Avatar,
  Chip,
  Tooltip,
  Grid,
  Button,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React from "react";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { formatWithMask } from "../config/mask";
import RegexOf from "../config/regex";
import convertDateTobirthday from "../utils/convertDateToBirthday";
function Feedback({ data, setData, showVisualization, token }) {
  const [copiedText, setCopiedText] = React.useState("");

  const navigate = useNavigate();

  return (
    <>
      {showVisualization === true ? (
        <>
          <Card
            sx={{
              marginTop: -3.2,
              boxShadow: "none",
            }}
          >
            <CardHeader
              title={
                <CopyToClipboard
                  text={data?.number}
                  onCopy={() => setCopiedText(data?.number)}
                >
                  <Tooltip
                    title={copiedText === data?.number ? "Copiado!!" : "Copiar"}
                    color="primary"
                    placement="top"
                  >
                    <Chip
                      avatar={
                        <Avatar
                          sx={{
                            bgcolor: (theme) => theme.palette.primary.main,
                          }}
                        >
                          <Typography color="White" fontSize={12}>
                            {data && data?.name?.slice(0, 2).toUpperCase()}
                          </Typography>
                        </Avatar>
                      }
                      label={`ID - # ${data?.number}`}
                      variant="outlined"
                      color="primary"
                      clickable
                    />
                  </Tooltip>
                </CopyToClipboard>
              }
            ></CardHeader>
            <Typography
              variant="h1"
              color="gray"
              fontWeight="700"
              fontSize={12}
              sx={{ ml: 2, mb: 1 }}
            >
              #1 DADOS PESSOAIS
            </Typography>
            <Typography
              variant="p"
              color="secondary"
              fontWeight="500"
              fontSize={15}
              sx={{ ml: 2 }}
            >
              {data?.name}
            </Typography>
            <CardContent
              sx={{ display: "flex", flexDirection: "column", mt: "-10px" }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={3}
                  sx={{
                    marginTop: 2,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          CNS
                        </Typography>{" "}
                        : {data?.sus_card || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Sexo
                          </Typography>{" "}
                          : {data?.gender || "Não informado"}
                        </Typography>
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Idade
                        </Typography>{" "}
                        :{" "}
                        {convertDateTobirthday(data?.birth_date) ||
                          "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Data de Nascimento
                        </Typography>{" "}
                        :{" "}
                        {data?.birth_date
                          ? format(parseISO(data.birth_date), "dd/MM/yyyy")
                          : "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Raça
                        </Typography>{" "}
                        : {data?.race || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          RG{" "}
                        </Typography>{" "}
                        : {data?.ident_national || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Orgão Emissor
                        </Typography>{" "}
                        : {data?.issuing_body || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          CPF
                        </Typography>{" "}
                        :{" "}
                        {formatWithMask({
                          mask: RegexOf.cpf,
                          text: data?.physic_national,
                        }).masked || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Profissão
                        </Typography>{" "}
                        : {data?.profession || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Estado civil{" "}
                        </Typography>{" "}
                        : {`${data?.marital_status || "Não informado"}`}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Cônjuge{" "}
                        </Typography>{" "}
                        : {data?.spouse_name || "Não informado"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    marginTop: 2,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Nacionalidade
                      </Typography>{" "}
                      : {data?.nationality || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Naturalidade
                      </Typography>{" "}
                      : {data?.naturality || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        CEP
                      </Typography>{" "}
                      :{" "}
                      {formatWithMask({
                        mask: RegexOf.cep,
                        text: data?.contact?.cep,
                      }).masked || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Número
                      </Typography>{" "}
                      : {data?.contact?.address_number || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Bairro
                      </Typography>{" "}
                      : {data?.contact?.district || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Logradouro
                      </Typography>{" "}
                      : {data?.contact?.street || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Localidade
                      </Typography>{" "}
                      : {data?.contact?.county || "Não informado"} -{" "}
                      {data?.contact?.state || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Complemento
                      </Typography>{" "}
                      : {data?.contact?.complement || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Tipo Sanguíneo
                      </Typography>{" "}
                      : {data?.type_blood || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Doador ?{" "}
                      </Typography>{" "}
                      : {data?.organ_donor ? "Sim" : "Não"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Nome da Mãe
                      </Typography>{" "}
                      : {data?.mother_name || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Nome do Pai
                      </Typography>{" "}
                      : {data?.dad_name || "Não informado"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    marginTop: 2,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                  }}
                  xs={3}
                >
                  <Box>
                    <Typography
                      variant="h1"
                      color="gray"
                      fontWeight="700"
                      fontSize={12}
                      sx={{ mb: 1.5 }}
                    >
                      #2 DADOS DO RESPONSÁVEL
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        CPF
                      </Typography>{" "}
                      :{" "}
                      {formatWithMask({
                        mask: RegexOf.cpf,
                        text: data?.responsible?.physic_national,
                      }).masked || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Nome
                      </Typography>{" "}
                      : {data?.responsible?.name || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Parentesco
                      </Typography>{" "}
                      : {data?.responsible?.kinship || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Telefone
                      </Typography>{" "}
                      : {data?.responsible?.phone || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Telefone Celular
                      </Typography>{" "}
                      :{" "}
                      {formatWithMask({
                        mask: RegexOf.phone,
                        text: data?.responsible?.cell_phone,
                      }).masked || "Não informado"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{ marginTop: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="h1"
                      color="gray"
                      fontWeight="700"
                      fontSize={12}
                      sx={{ mb: 1.5 }}
                    >
                      #3 DADOS DE CONTATO
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Telefone
                      </Typography>{" "}
                      : {data?.contact?.phone || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Telefone Celular
                      </Typography>{" "}
                      :{" "}
                      {formatWithMask({
                        mask: RegexOf.phone,
                        text: data?.contact?.cell_phone,
                      }).masked || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Email
                      </Typography>{" "}
                      : {data?.contact?.email || "Não informado"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      ) : (
        <>
          <Card
            sx={{
              marginTop: -3.2,
              boxShadow: "none",
            }}
          >
            <CardHeader
              title={
                <CopyToClipboard
                  text={data?.number}
                  onCopy={() => setCopiedText(data?.number)}
                >
                  <Tooltip
                    title={copiedText === data?.number ? "Copiado!!" : "Copiar"}
                    color="primary"
                    placement="top"
                  >
                    <Chip
                      avatar={
                        <Avatar
                          sx={{
                            bgcolor: (theme) => theme.palette.primary.main,
                          }}
                        >
                          <Typography color="White" fontSize={12}>
                            {data && data?.name?.slice(0, 2).toUpperCase()}
                          </Typography>
                        </Avatar>
                      }
                      label={`ID - # ${data?.number}`}
                      variant="outlined"
                      color="primary"
                      clickable
                    />
                  </Tooltip>
                </CopyToClipboard>
              }
            ></CardHeader>
            <Typography
              variant="h1"
              color="gray"
              fontWeight="700"
              fontSize={12}
              sx={{ ml: 2, mb: 1 }}
            >
              #1 DADOS PESSOAIS
            </Typography>
            <Typography
              variant="p"
              color="secondary"
              fontWeight="500"
              fontSize={15}
              sx={{ ml: 2 }}
            >
              {data?.name}
            </Typography>
            <CardContent
              sx={{ display: "flex", flexDirection: "column", mt: "-10px" }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={3}
                  sx={{
                    marginTop: 2,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center",
                      }}
                    >
                      {" "}
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          CNS
                        </Typography>{" "}
                        : {data?.sus_card || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Sexo
                          </Typography>{" "}
                          : {data?.gender || "Não informado"}
                        </Typography>
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Idade
                        </Typography>{" "}
                        :{" "}
                        {convertDateTobirthday(data?.birth_date) ||
                          "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Data de Nascimento
                        </Typography>{" "}
                        :{" "}
                        {data?.birth_date
                          ? format(parseISO(data.birth_date), "dd/MM/yyyy")
                          : "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Raça
                        </Typography>{" "}
                        : {data?.race || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          RG{" "}
                        </Typography>{" "}
                        : {data?.ident_national || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Orgão Emissor
                        </Typography>{" "}
                        : {data?.issuing_body || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          CPF
                        </Typography>{" "}
                        :{" "}
                        {formatWithMask({
                          mask: RegexOf.cpf,
                          text: data?.physic_national,
                        }).masked || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Profissão
                        </Typography>{" "}
                        : {data?.profession || "Não informado"}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Estado civil{" "}
                        </Typography>{" "}
                        : {`${data?.marital_status || "Não informado"}`}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        fontWeight="500"
                        fontSize={13}
                      >
                        <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                        >
                          Cônjuge{" "}
                        </Typography>{" "}
                        : {data?.spouse_name || "Não informado"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    marginTop: 2,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Nacionalidade
                      </Typography>{" "}
                      : {data?.nationality || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Naturalidade
                      </Typography>{" "}
                      : {data?.naturality || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        CEP
                      </Typography>{" "}
                      :{" "}
                      {formatWithMask({
                        mask: RegexOf.cep,
                        text: data?.contact?.cep,
                      }).masked || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Número
                      </Typography>{" "}
                      : {data?.contact?.address_number || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Bairro
                      </Typography>{" "}
                      : {data?.contact?.district || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Logradouro
                      </Typography>{" "}
                      : {data?.contact?.street || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Localidade
                      </Typography>{" "}
                      : {data?.contact?.county || "Não informado"} -{" "}
                      {data?.contact?.state || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Complemento
                      </Typography>{" "}
                      : {data?.contact?.complement || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Tipo Sanguíneo
                      </Typography>{" "}
                      : {data?.type_blood || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Doador ?{" "}
                      </Typography>{" "}
                      : {data?.organ_donor ? "Sim" : "Não"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Nome da Mãe
                      </Typography>{" "}
                      : {data?.mother_name || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Nome do Pai
                      </Typography>{" "}
                      : {data?.dad_name || "Não informado"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    marginTop: 2,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                  }}
                  xs={3}
                >
                  <Box>
                    <Typography
                      variant="h1"
                      color="gray"
                      fontWeight="700"
                      fontSize={12}
                      sx={{ mb: 1.5 }}
                    >
                      #2 DADOS DO RESPONSÁVEL
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        CPF
                      </Typography>{" "}
                      :{" "}
                      {formatWithMask({
                        mask: RegexOf.cpf,
                        text: data?.responsible?.physic_national,
                      }).masked || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Nome
                      </Typography>{" "}
                      : {data?.responsible?.name || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Parentesco
                      </Typography>{" "}
                      : {data?.responsible?.kinship || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Telefone
                      </Typography>{" "}
                      : {data?.responsible?.phone || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Telefone Celular
                      </Typography>{" "}
                      :{" "}
                      {formatWithMask({
                        mask: RegexOf.phone,
                        text: data?.responsible?.cell_phone,
                      }).masked || "Não informado"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{ marginTop: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="h1"
                      color="gray"
                      fontWeight="700"
                      fontSize={12}
                      sx={{ mb: 1.5 }}
                    >
                      #3 DADOS DE CONTATO
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Telefone
                      </Typography>{" "}
                      : {data?.contact?.phone || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Telefone Celular
                      </Typography>{" "}
                      :{" "}
                      {formatWithMask({
                        mask: RegexOf.phone,
                        text: data?.contact?.cell_phone,
                      }).masked || "Não informado"}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Email
                      </Typography>{" "}
                      : {data?.contact?.email || "Não informado"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
                </Grid>
                <Grid item container xs={12} spacing={1} mt={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setData({});
                        navigate("/attendance/patient/new");
                      }}
                    >
                      Cadastrar Outro
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => navigate("/schedule/scheduling")}
                    >
                      Agendar
                    </Button>
                  </Grid>
                  {token && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() =>
                          navigate("/attendance/patientUnregistered")
                        }
                      >
                        Voltar para o pré-cadastro
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
}

export default Feedback;
