import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const DEFAULT_PATH = "/exams";
const VIEWED_DOCUMENT_PATH = "/viewed-documents-histories/";
const VIEWED_DOCUMENT_GENERATION_PATH = '/external-services/mv/exams/patients/'
export default function useExamIntegrations() {
  const getImageExamsByPatient = async ({ id, params }) => {
    try {
      const response = await instance.get(
        `${DEFAULT_PATH}/images/patients/${id}`,
        {
          params: urlQuery(params),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const getEletronicDocumentsByPatient = async ({ id, params }) => {
    try {
      const response = await instance.get(
        `${DEFAULT_PATH}/electronics/patients/${id}`,
        {
          params: urlQuery(params),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const viewedDocumentHistoryGenerate = async ({ patient_id, ...params }) => {
    try {
      const response = await instance.get(VIEWED_DOCUMENT_GENERATION_PATH + patient_id, {
        params,
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const viewedDocumentHistoryByEmployee = async ({ id, params }) => {
    try {
      const response = await instance.get(
        VIEWED_DOCUMENT_PATH + `/employee/${id}`,
        {
          params: urlQuery(params),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const viewedDocumentHistoryByDocument = async ({ id, params }) => {
    try {
      const response = await instance.get(
        VIEWED_DOCUMENT_PATH + `documents/${id}`,
        {
          params: urlQuery(params),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  return {
    getImageExamsByPatient,
    getEletronicDocumentsByPatient,
    viewedDocumentHistoryGenerate,
    viewedDocumentHistoryByEmployee,
    viewedDocumentHistoryByDocument,
  };
}
