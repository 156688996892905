import instance from "../config/api";
const path = "/schedulings";
function useSchedule() {
  async function getSchedule(params) {
    try {
      const response = await instance.get(path, {
        params,
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function patchScheduling({ id, data }) {
    try {
      const response = await instance.patch(`${path}/${id}`, data);
      return response.data;   
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function postScheduling(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  return {
    postScheduling,
    patchScheduling,
    getSchedule,
  };
}
export default useSchedule;
