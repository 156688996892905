import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/admeasurement";

export default function useAdmeasurement() {
  async function getAdmeasurement(params) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response?.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }

  async function getAdmeasurementId(id) {
    try {
      const response = await instance.get(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postAdmeasurement(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updateAdmeasurement({ id, data }) {
    try {
      const response = await instance.put(`${path}/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getAdmeasurement,
    getAdmeasurementId,
    postAdmeasurement,
    updateAdmeasurement,
  };
}
