import React, { useContext, useState } from "react";
import { theme } from "../../../config/theme";
import { Box, Typography, Grid, TablePagination, Button } from "@mui/material";
import Yup from "../../../config/yup";
import TableFilter from "../../../components/Table/TableFilter";
import { format, parseISO } from "date-fns";
import { AppContext } from "../../../contexts/AppContext";
import { useQuery, useMutation, useQueryClient } from "react-query";
import usePrescriptions from "../../../service/usePrescriptions";
import useNotifier from "../../../hooks/useNotifier";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AutocompleteField } from "../../../components/FormFields";

const ExamList = ({ patient }) => {
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [statusFilter, setStatusFilter] = useState("ongoing");
  const [examFilter, setExamFilter] = useState("all");
  const { patientData } = useContext(AppContext);
  const queryClient = useQueryClient();

  const { getExams, putExams } = usePrescriptions();

  const examsQuery = useQuery(
    ["exams", [examFilter, statusFilter, page, limit]],
    () =>
      getExams(patient?.id || patientData.id, {
        type: examFilter,
        status: statusFilter,
        page,
        limit,
      }),
    {
      enabled: !!(patient?.id || patientData.id),
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const examsMutation = useMutation(putExams);

  function handleExamStatus(id, data) {
    examsMutation.mutate(
      { id, data: { [data]: true } },
      {
        onSuccess: (success) => {
          queryClient.invalidateQueries("exams");
          notify(success.message, "success");
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  }

  const handleFilter = ({ type, status }) => {
    setExamFilter(type.type);
    setStatusFilter(status.status);
  };

  const initialValues = {
    type: { type: "all", describe: "Todos" },
    status: { status: "ongoing", describe: "Pendente" },
  };
  const validations = Yup.object().shape({
    type: Yup.object().required("É requerido").nullable(),
    status: Yup.object().required("É requerido").nullable(),
  });

  const examTypes = [
    { type: "all", describe: "Todos" },
    { type: "lab", describe: "Laboratorial" },
    { type: "image", describe: "Imagem" },
  ];

  const status = [
    { status: "ongoing", describe: "Pendente" },
    { status: "evaluated", describe: "Avaliado" },
    { status: "suspended", describe: "Suspenso" },
  ];

  const suspendedAtColumn =
    statusFilter === "suspended"
      ? {
          name: "Suspenso em",
          field: "suspendedAt",
          use: (suspendedAt) =>
            suspendedAt ? format(parseISO(suspendedAt), "dd/MM/yyyy") : "",
        }
      : {};

  const clms = [
    {
      name: "Tipo",
      field: "type",
    },
    {
      name: "Exame",
      field: "describe",
    },
    {
      name: "Status",
      use: (_, e) =>
        !!e?.suspendedAt
          ? "Suspenso"
          : !!e?.evaluated
          ? "Avaliado"
          : "Pendente",
    },
    {
      ...suspendedAtColumn,
    },
    {
      name: "Observação",
      field: "notes",
    },
    {
      name: "Criado em",
      field: "createdAt",
      use: (createdAt) =>
        createdAt ? format(parseISO(createdAt), "dd/MM/yyyy") : "",
    },
  ];

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });
  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        marginY="1rem"
        gap={2}
      >
        <Grid component="form" container minWidth={600} spacing={2}>
          <Grid item xs={2}>
            <Typography
              component="h2"
              sx={{
                color: theme.palette.primary.light,
                fontWeight: 600,
              }}
            >
              Lista de Exames
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <AutocompleteField
              required
              name="type"
              label="Tipo de exame"
              options={examTypes}
              control={control}
              optionLabelKey="describe"
            />
          </Grid>
          <Grid xs={4} item>
            <AutocompleteField
              required
              name="status"
              label="Status do exame"
              options={status}
              control={control}
              optionLabelKey="describe"
            />
          </Grid>
          <Grid xs={2} item>
            <Button onClick={handleSubmit(handleFilter)}>Filtrar</Button>
          </Grid>
        </Grid>
      </Box>
      <TableFilter
        columns={clms}
        data={examsQuery.data?.items}
        actions={statusFilter === "ongoing"}
        loading={examsQuery.isFetching}
        actionsTypes={["suspend", "finish"]}
        customizeActions={{
          finish: {
            title: "Avaliado",
            verb: "Marcar como Avaliado",
          },
        }}
        actionUniqueIdentifier="id"
        actionHandleSuspend={(id) => handleExamStatus(id, "suspended")}
        actionHandleFinish={(id) => handleExamStatus(id, "evaluated")}
        rowStatus={(row) => (row.suspended ? "disabled" : "")}
        disableActions={(row, action) => row.suspended && action === "suspend"}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={examsQuery.data?.totalItems || 0}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
};

export default ExamList;
