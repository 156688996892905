import { format } from "date-fns";

const defaultColumns = [
  {
    name: "Data / Hora",
    field: "date",
    use: (value,row) =>
      value
        ? `${format(new Date(value), "dd/MM/yyyy")} ${row.hour}`
        : "Não encontrado.",
  },
  {
    name: "CNS Profissional",
    field: "employee?.sus_card",
    use: (value) => (value ? value : "Não encontrado."),
    type: "text",
    filter: "employee_sus_card",
  },
  {
    name: "Profissional",
    field: "employee?.name",
    use: (value) => (value ? value : "Não encontrado."),
    type: "text",
    filter: "employee_name",
  },

  {
    name: "Código CBO",
    field: "specialty?.cbo_code",
    use: (value) => (value ? value : "Não encontrado."),
    type: "text",
    filter: "cbo_code",
  },
  {
    name: "CBO",
    field: "specialty?.describe",
    use: (value) => (value ? value : "Não encontrado."),
    type: "text",
    filter: "specialty_describe",
  },
  {
    name: "CNS Paciente",
    field: "patient?.sus_card",
    use: (value) => (value ? value : "Não encontrado."),
    filter: "patient_sus_card",
    type: "text",
  },
  {
    name: "Paciente",
    field: "patient?.name",
    use: (value) => (value ? value : "Não encontrado."),
    filter: "patient_name",
    type: "text",
  },
  {
    name: "Cd. Procedimento",
    field: "procedure?.tuss_code",
    use: (value) => (value ? value : "Não encontrado."),
    type: "number",
    filter: "tuss_code",
  },
  {
    name: "Ds. Procedimento",
    field: "procedure?.name",
    use: (value) => (value ? value : "Não encontrado."),
    type: "text",
    filter: "procedure_name",
  },
  {
    name: "QTD",
    field: "procedure?.quantity",
    use: (value) => (value ? value : "Não encontrado."),
    type: "number",
    filter: "procedure_quantity",
  },
  {
    name: "AMA / UBS",
    field: "ama_or_ubs",
    use: (value) => (value ? value : "Não encontrado."),
    type: "select",
    options: [
      {
        label: "AMA",
        value: "ama",
      },
      {
        label: "UBS",
        value: "ubs",
      },
      {
        label: "VIRTUAL",
        value: "virtual",
      },
    ],
  },
];

export { defaultColumns };
