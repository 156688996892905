import instance from "../config/api";
const path = "/schedules";
function useSchedules() {
  async function getSchedules() {
    try {
      const response = await instance.get(path);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getSchedules,
  };
}
export default useSchedules;
