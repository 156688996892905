import React, { useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useQuery } from "react-query";
import { BoxContainer } from "../../../components";
import TableFilter from "../../../components/Table/TableFilter";
import { Dialog } from "../../../helper";
import { useMicroArea } from "../../../service";
import MicroAreaForm from "./MicroAreaForm";
import MicroAreaFeedback from "./MicroAreaFeedback";
import usePermissions from "../../../config/usePermissions";
import useNotifier from "../../../hooks/useNotifier";

const MicroAreaList = () => {
  const permission = usePermissions("/program/team");
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [register, setRegister] = useState(false);
  const [microArea, setMicroArea] = useState({});

  const { getMicroArea } = useMicroArea();

  const microAreaQuery = useQuery(
    ["micro-areas", page, limit],
    () => getMicroArea({ page, limit }),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: { items: [] },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  function handleEdit(id) {
    const microArea = microAreaQuery.data.items.find(
      (microArea) => microArea.id === id
    );

    setMicroArea(microArea);
    setEdit(true);
  }

  function handleView(id) {
    const microArea = microAreaQuery.data.items.find(
      (microArea) => microArea.id === id
    );

    setMicroArea(microArea);
    setView(true);
  }

  const tableColumns = [
    {
      name: "Nome",
      field: "name",
    },
    {
      name: "Criado em",
      field: "createdAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
    {
      name: "Atualizado em",
      field: "updatedAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
    {
      name: "Agentes Comunitários de Saúde",
      field: "employees[0]?.name",
    },
  ];

  return (
    <BoxContainer title="Lista de microáreas">
      <Dialog
        open={register}
        title="Cadastro de Microárea"
        handleClose={() => setRegister(false)}
      >
        <MicroAreaForm type="register" />
      </Dialog>
      <Dialog
        open={edit}
        title="Alteração de Microárea"
        handleClose={() => setEdit(false)}
      >
        <MicroAreaForm type="edit" microArea={microArea} />
      </Dialog>
      <Dialog
        open={view}
        title="Visualização de Microárea"
        handleClose={() => setView(false)}
      >
        <MicroAreaFeedback data={microArea} />
      </Dialog>
      <TableFilter
        data={microAreaQuery.data?.items}
        actions
        emptyMessage={microAreaQuery.error?.message}
        actionUniqueIdentifier="id"
        columns={tableColumns}
        loading={microAreaQuery.isFetching}
        actionsTypes={["view", "edit"]}
        actionHandleEdit={handleEdit}
        actionHandleView={handleView}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={microAreaQuery.data?.totalItems}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimit(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Box>
      <Button
        variant="contained"
        color="secondary"
        disabled={!permission.create}
        onClick={() => setRegister(true)}
      >
        Novo Cadastro
      </Button>
    </BoxContainer>
  );
};

export default MicroAreaList;
