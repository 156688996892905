import React, { useState } from "react";
import { BoxContainer, PercentageChart } from "../../components";
import { Box, Button, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/system";
import {
  MaleRounded,
  FemaleRounded,
  TransgenderRounded,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useCockpit } from "../../service";
import { useQuery } from "react-query";

const DashBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: `${theme.palette.primary.light}`,
  fontWeight: 600,
  width: "100%",
  fontSize: "2em",
  borderRadius: 5,
  padding: 13,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ScaleBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: `${theme.palette.primary.light}`,
  fontWeight: 600,
  fontSize: "2em",
  borderRadius: 5,
  padding: 8,
  width: "100%",
  textAlign: "center",
}));

const DashTypography = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  marginBottom: 3,
  fontWeight: 600,
  color: `${theme.palette.primary.light}`,
  fontSize: "0.8em",
  whiteSpace: "nowrap",
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.primary.light}`,
  fontWeight: 500,
  fontSize: "0.8em",
  textAlign: "center",
  whiteSpace: "nowrap",
}));

const PercentageTypography = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.primary.light}`,
  backgroundColor: `${theme.palette.primary.medium}`,
  maxWidth: 300,
  width: "100%",
  padding: 6,
  textAlign: "center",
  fontWeight: 600,
  fontSize: "0.8em",
  borderRadius: 5,
}));

const DashButton = styled(Button)(({ theme }) => ({
  maxWidth: 115,
  width: "100%",
  textTransform: "capitalize",
  marginRight: "7px",
  color: `${theme.palette.primary.light}`,
  fontSize: "0.8em",
}));

function CockPitDataAnalysis() {
  const { getDataAnalisys } = useCockpit();
  const [data, setData] = useState();

  useQuery(["dataAnalisysQuery"], () => getDataAnalisys(), {
    keepPreviousData: true,
    retry: false,
    onSuccess: (result) => {
      setData(result);
    },
  });

  return (
    <>
      <BoxContainer
        title={
          <Typography fontSize={12} fontWeight={500}>
            APS - OPERAÇÃO
          </Typography>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <DashTypography>Beneficiários</DashTypography>
            <DashBox>{data?.patients?.total}</DashBox>
          </Grid>
          <Grid item xs={3}>
            <DashTypography>Sem Programa de saúde</DashTypography>
            <DashBox>
              {data
                ? data?.patients?.total - data?.patients?.in_programs
                : "Carregando..."}
              <Typography fontSize="0.4em">
                <PercentageChart
                  data={[
                    {
                      percentage: (
                        ((data?.patients?.total - data?.patients?.in_programs) *
                          100) /
                        data?.patients?.total
                      ).toFixed(),
                    },
                  ]}
                  size={43}
                />
              </Typography>
            </DashBox>
          </Grid>
          <Grid item xs={3}>
            <DashTypography>Com programa de saúde</DashTypography>
            <DashBox>
              {data?.patients?.in_programs}
              <Typography fontSize="0.4em">
                <PercentageChart
                  data={[
                    {
                      percentage: (
                        (data?.patients?.in_programs * 100) /
                        data?.patients?.total
                      ).toFixed(),
                    },
                  ]}
                  size={43}
                />
              </Typography>
            </DashBox>
          </Grid>
          <Grid item xs={2}>
            <DashTypography>Urgência</DashTypography>
            <DashBox>
              -
              <Typography fontSize="0.4em">
                <PercentageChart data={[{ percentage: 0 }]} size={43} />
              </Typography>
            </DashBox>
          </Grid>
          <Grid item xs={2}>
            <DashTypography>Internados</DashTypography>
            <DashBox>
              -
              <Typography fontSize="0.4em">
                <PercentageChart data={[{ percentage: 0 }]} size={43} />
              </Typography>
            </DashBox>
          </Grid>
        </Grid>
        <Grid item spacing={0} xs={12}>
          <Divider variant="fullWidth" sx={{ marginY: 1 }} />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                color: (theme) => theme.palette.primary.light,
                marginBottom: 1.2,
              }}
            >
              CLASSIFICAÇÃO
            </Typography>
          </Box>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.light,
                fontWeight: "600",
                fontSize: "14px",
                marginRight: "80px",
                marginLeft: "65px",
              }}
            >
              RISCOS
            </Typography>
            <Grid item xs={1.5}>
              <LabelTypography>Não Identificado</LabelTypography>
              <ScaleBox>-</ScaleBox>
            </Grid>
            <Grid item xs={1.5}>
              <LabelTypography>Sem risco</LabelTypography>
              <ScaleBox>-</ScaleBox>
            </Grid>
            <Grid item xs={1.5}>
              <LabelTypography>Muito Baixo</LabelTypography>
              <ScaleBox>-</ScaleBox>
            </Grid>
            <Grid item xs={1.5}>
              <LabelTypography>Baixo</LabelTypography>
              <ScaleBox>-</ScaleBox>
            </Grid>
            <Grid item xs={1.5}>
              <LabelTypography>Médio</LabelTypography>
              <ScaleBox>-</ScaleBox>
            </Grid>
            <Grid item xs={1.5}>
              <LabelTypography>Alto</LabelTypography>
              <ScaleBox>-</ScaleBox>
            </Grid>
            <Grid item xs={1.5} marginRight="7px">
              <LabelTypography>Muito Alto</LabelTypography>
              <ScaleBox>-</ScaleBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: 0.6 }}>
          {data?.programs?.map((program) => (
            <Grid item xs={12}>
              <Divider variant="fullWidth" sx={{ marginY: 1 }} />
              <Box display="flex" alignItems="center" gap={0.6}>
                <Link
                  to="/cockpit/comorbidity"
                  state={{ id: program.id }}
                  style={{ textDecoration: "none", width: "85%" }}
                >
                  <DashButton size="small" variant="outlined">
                    {program.name}
                  </DashButton>
                </Link>
                <Box
                  display="flex"
                  alignItems="center"
                  maxWidth={70}
                  width="100%"
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.light,
                      fontWeight: "600",
                      fontSize: "13px",
                    }}
                  >
                    {program.patients} -
                  </Typography>
                  <Typography fontSize={9}>
                    <PercentageChart
                      data={[
                        {
                          percentage: (
                            (program.patients * 100) /
                            data?.patients?.total
                          ).toFixed(),
                        },
                      ]}
                      size={35}
                    />
                  </Typography>
                </Box>
                <PercentageTypography>-</PercentageTypography>
                <PercentageTypography>-</PercentageTypography>
                <PercentageTypography>-</PercentageTypography>
                <PercentageTypography>-</PercentageTypography>
                <PercentageTypography>-</PercentageTypography>
                <PercentageTypography sx={{ backgroundColor: "#EDD3D3" }}>
                  -
                </PercentageTypography>
                <PercentageTypography>-</PercentageTypography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Divider variant="middle" sx={{ marginY: "10px" }} />
        <Grid container>
          <Grid item xs={12} display="flex" gap={1}>
            <DashButton
              variant="outlined"
              sx={{
                color: (theme) => theme.palette.primary.light,
                fontWeight: "600",
                fontSize: "14px",
                display: "flex",
                justifyContent: "space-between",
                paddingX: 0.8,
                maxWidth: 90,
                width: "100%",
                height: 30,
              }}
            >
              <MaleRounded />
              {data?.patients?.male && data?.patients?.total
                ? (
                    (data?.patients?.male * 100) /
                    data?.patients?.total
                  ).toFixed()
                : 0}
              %
            </DashButton>
            <DashButton
              variant="outlined"
              sx={{
                color: (theme) => theme.palette.primary.light,
                fontWeight: "600",
                fontSize: "14px",
                display: "flex",
                justifyContent: "space-between",
                paddingX: 0.8,
                maxWidth: 90,
                width: "100%",
                height: 30,
              }}
            >
              <FemaleRounded color="primary" />
              {data?.patients?.female && data?.patients?.total
                ? (
                    (data?.patients?.female * 100) /
                    data?.patients?.total
                  ).toFixed()
                : 0}
              %
            </DashButton>
            <DashButton
              variant="outlined"
              sx={{
                color: (theme) => theme.palette.primary.light,
                fontWeight: "600",
                fontSize: "14px",
                display: "flex",
                justifyContent: "space-between",
                padding: 0.8,
                maxWidth: 90,
                width: "100%",
                height: 30,
              }}
            >
              <TransgenderRounded color="secondary" />
              {data?.patients?.other && data?.patients?.total
                ? (
                    (data?.patients?.other * 100) /
                    data?.patients?.total
                  ).toFixed()
                : 0}
              %
            </DashButton>
          </Grid>
        </Grid>
      </BoxContainer>
    </>
  );
}

export default React.memo(CockPitDataAnalysis);
