import { lunaInstance } from "../config/api";

function useLunaAI() {
  async function postPatientSOA(payload) {
    try {
      const response = await lunaInstance.post("/", payload);
      return response.data;
    } catch (err){
      throw new Error(err.response.data);
    }
  }

  return {
    postPatientSOA,
  }
}

export default useLunaAI