import React from "react";
import { useNotifier } from "../../hooks";
import { useExamIntegrations } from "../../service";
import { useQuery } from "react-query";
import { Box, TablePagination } from "@mui/material";
import TableFilter from "../../components/Table/TableFilter";
import { format, parseISO } from "date-fns";

export default function ViewedDocumentHistory({ document, documentType }) {
  const notify = useNotifier();
  const { viewedDocumentHistoryByDocument } = useExamIntegrations();

  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);

  let id;

  console.log(document);

  switch (documentType) {
    case "MV_ELECTRONIC_DOC":
      id = document.registerEditorId;
      break;
    case "MV_IMAGE_DOC":
      id = document.accessNumber;
      break;
    case "MV_LAB_DOC":
      id = document.external_id;
      break;
    default:
      id = document.id;
      break;
  }

  const historyQuery = useQuery(
    ["viewedDocumentHistoryByDocument", document, page, limit],
    () =>
      viewedDocumentHistoryByDocument({
        id: id,
        params: { page: page, limit: limit, type: documentType },
      }),
    {
      retry: false,
      enabled: !!id,
      onError: (err) => {
        notify(err.message, "error");
      },
    }
  );

  const columns = [
    {
      field: "name",
      name: "Nome",
      type: "text",
    },
    {
      field: "user_login",
      name: "Usúario",
      type: "text",
    },
    {
      field: "viewedAt",
      name: "Data/Hora",
      type: "date",
      use: (date) => {
        return date
          ? format(parseISO(date), "dd/MM/yyyy 'às' HH:mm")
          : "Não informado";
      },
    },
  ];

  return (
    <>
      <Box>
        <TableFilter
          data={historyQuery.data?.items || []}
          columns={columns}
          loading={historyQuery.isLoading}
          noHeaderOptions
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={historyQuery.data?.totalItems ?? 0}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
      </Box>
    </>
  );
}
