import instance from "../config/api";

const DPath = '/general-annotations'
export default function useGeneralAnnotations(){
    async function getGeneralAnnotations(params){
        try {
            const result = await instance.get(DPath,{params})
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function getGeneralAnnotationOpened(patient_id,params){
        try {
            const result = await instance.get(`${DPath}/${patient_id}/opened`,{params})
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function getGeneralAnnotation(id,params){
        try {
            const result = await instance.get(`${DPath}/${id}`,{params})
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function postGeneralAnnotations(data){
        try {
            const result = await instance.post(DPath,data)
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function putGeneralAnnotation({id,data}){
        try {
            const result = await instance.put(`${DPath}/${id}`,data)
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function finishGeneralAnnotation(id){
        try {
            const result = await instance.patch(`${DPath}/${id}/finish`)
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    return {
        getGeneralAnnotations,
        postGeneralAnnotations,
        getGeneralAnnotationOpened,
        getGeneralAnnotation,
        putGeneralAnnotation,
        finishGeneralAnnotation
    }
}