import React from "react";
import { useReducer } from "react";

export const ConfirmContext = React.createContext();
export const SHOW_CONFIRM = "SHOW_CONFIRM";
export const HIDE_CONFIRM = "HIDE_CONFIRM";

export const initialState = {
  show: false,
  text: "",
  confirmAction: () => null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        show: true,
        text: action.payload.text,
        confirmAction: action.confirmAction,
      };
    case HIDE_CONFIRM:
      return initialState;
    default:
      return initialState;
  }
};

export const ConfirmContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ConfirmContext.Provider value={[state, dispatch]}>
      {children}
    </ConfirmContext.Provider>
  );
};
