import React from "react";
import { Box } from "@mui/material";
import AttendanceTypeStep from "./SettingsScheduleSteps/AttendanceTypeStep";
import ServicesStep from "./SettingsScheduleSteps/ServicesStep";
import SectorsStep from "./SettingsScheduleSteps/SectorsStep";
import { Tabs } from "../../components";

export default function SettingsSchedule() {
  const tabs = [
    {
      label: "Tipo de Atendimento",
      content: <AttendanceTypeStep />,
    },
    {
      label: "Serviços",
      content: <ServicesStep />,
    },
    {
      label: "Setores",
      content: <SectorsStep />,
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs tabs={tabs} />
      </Box>
    </>
  );
}
