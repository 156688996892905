import React from "react";
import { TITLE_NAME } from "../../config/config";
import { Helmet } from "react-helmet";
import { Box, TablePagination, Button } from "@mui/material";
import { Dialog, DialogMedium, Error, Loading, Success } from "../../helper";
import { useVaccine } from "../../service";
import { useQuery, useQueryClient } from "react-query";
import PaginatedTable from "../../components/Table/PaginatedTable";
import RegisterImmuFeedback from "./Feedback/RegisterImmuFeedback";
import RegisterImmuForm from "../Vaccine/Form/RegisterImmuForm";
import RegisterImmuEdit from "./Edit/RegisterImmuEdit";
import { BoxContainer } from "../../components";
import usePermissions from "../../config/usePermissions";

function VaccineRegisterImmu() {
  const queryClient = useQueryClient();

  const { getVaccines } = useVaccine();
  const [registerImmu, setRegisterImmu] = React.useState([]);
  const [visualizationRegisterImmu, setVisualizationRegisterImmu] =
    React.useState(false);
  const [editRegisterImmu, setEditRegisterImmuDialog] = React.useState(false);
  const [registerImmuDialog, setRegisterImmuDialog] = React.useState(false);
  const [showVisualization] = React.useState(true);
  const [unicRegisterImmus, setUnicRegisterImmus] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const permission = usePermissions("/vaccine/immunizing");

  const registerImmuQuery = useQuery(
    ["registerImmu", [page, limit]],
    () => getVaccines({ page, limit }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  React.useEffect(() => {
    if (
      registerImmuQuery.isSuccess &&
      registerImmuQuery.data &&
      registerImmuQuery.data.items
    ) {
      if (count === 0) {
        setCount(registerImmuQuery.data.totalItems);
      }
      setRegisterImmu(
        registerImmuQuery.data.items.map((index) => {
          return index;
        })
      );
      setRows(
        registerImmuQuery.data.items.map((index) => {
          const minimumAge = `${
            !!index.minimumAge?.years ? index.minimumAge.years + " ano(s)" : ""
          } ${
            !!index.minimumAge?.months
              ? index.minimumAge.months + " mese(s)"
              : ""
          } ${
            !!index.minimumAge?.weeks
              ? index.minimumAge.weeks + " semana(s)"
              : ""
          } ${
            !!index.minimumAge?.days ? index.minimumAge.days + " dia(s)" : ""
          }`;
          const maximumAge = `${
            !!index.maximumAge?.years ? index.maximumAge.years + " ano(s)" : ""
          } ${
            !!index.maximumAge?.months
              ? index.maximumAge.months + " mese(s)"
              : ""
          } ${
            !!index.maximumAge?.weeks
              ? index.maximumAge.weeks + " semana(s)"
              : ""
          } ${
            !!index.maximumAge?.days ? index.maximumAge.days + " dia(s)" : ""
          }`;
          const minimumInterval = `${
            !!index.minimumInterval?.years
              ? index.minimumInterval.years + " ano(s)"
              : ""
          } ${
            !!index.minimumInterval?.months
              ? index.minimumInterval.months + " mese(s)"
              : ""
          } ${
            !!index.minimumInterval?.weeks
              ? index.minimumInterval.weeks + " semana(s)"
              : ""
          } ${
            !!index.minimumInterval?.days
              ? index.minimumInterval.days + " dia(s)"
              : ""
          }`;

          return {
            id: index.id,
            describe: index.describe,
            abbreviation: index.abbreviation,
            minimumAge: minimumAge,
            maximumAge: maximumAge,
            minimumInterval: minimumInterval,
            recommendedDose: index.recommendedDose,
            abbreviation: index.abbreviation,
          };
        })
      );
    }
  }, [registerImmuQuery.dataUpdatedAt]);

  const columns = [
    { name: "Imunobiológicos" },
    { name: "Sigla" },
    { name: "Idade Minima" },
    { name: "Idade Máxima" },
    { name: "Intervalo Min" },
    { name: "Dose por ml" },
  ];

  function handleVisualization() {
    setVisualizationRegisterImmu(true);
  }
  function handleEditDialog() {
    setEditRegisterImmuDialog(true);
  }
  function handleRegisterImmuDialog() {
    setRegisterImmuDialog(true);
  }
  function handleCloseDialog() {
    setEditRegisterImmuDialog(false);
    setRegisterImmuDialog(false);
    setVisualizationRegisterImmu(false);
    queryClient.invalidateQueries("registerImmu");
  }
  if (registerImmuQuery.isLoading) return <Loading />;
  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Vacina</title>
      </Helmet>

      <DialogMedium
        open={editRegisterImmu}
        title={"Editar Imunobiologico"}
        handleClose={handleCloseDialog}
      >
        <RegisterImmuEdit
          unicRegisterImmus={unicRegisterImmus}
          handleCloseDialog={handleCloseDialog}
        />
      </DialogMedium>
      <DialogMedium
        open={registerImmuDialog}
        title={"Cadastrar Imunobiologico"}
        handleClose={handleCloseDialog}
      >
        <RegisterImmuForm
          handleCloseDialog={handleCloseDialog}
          showVisualization={showVisualization}
        />
      </DialogMedium>
      <DialogMedium
        fullWidth
        open={visualizationRegisterImmu}
        title={"Visualização de Imunobiologico"}
        handleClose={handleCloseDialog}
      >
        <RegisterImmuFeedback data={unicRegisterImmus} />
      </DialogMedium>
      <BoxContainer title="Imunobiológicos">
        <PaginatedTable
          data={rows}
          columns={columns}
          actions
          actionsTypes={["view", "edit"]}
          shouldDisableFields={["id"]}
          disableActions={(_, action) =>
            action === "edit" && !permission.update
          }
          actionHandleEdit={(i) => {
            setUnicRegisterImmus(registerImmu[i]);
            handleEditDialog(i);
          }}
          actionHandleView={(i) => {
            setUnicRegisterImmus(registerImmu[i]);
            handleVisualization(i);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={count}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            color="secondary"
            disabled={!permission.create}
            onClick={() => handleRegisterImmuDialog(registerImmuDialog)}
          >
            Novo Cadastro
          </Button>
        </Box>
      </BoxContainer>
    </>
  );
}

export default VaccineRegisterImmu;
