import React from "react";
import { BoxContainer } from "../../../components";
import ScalesForm from "./ScalesForm";

export default function Scales() {
  return (
    <BoxContainer title={"Cadastro de Escalas"}>
      <ScalesForm />
    </BoxContainer>
  );
}
