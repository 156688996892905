import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

const path = "/teams";
const updatePath = "/teams/update/";
function useTeam() {
  async function getTeam(params = { limit: 5, page: 0 }) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postTeam(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updateTeam({ teamId, teamData }) {
    try {
      const response = await instance.put(updatePath + teamId, teamData);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function deleteEmployeeOfTeam({ teamId, employeesToDelete }) {
    try {
      const response = await instance.post(
        path + "/" + teamId + "/employees/batch-delete",
        employeesToDelete
      );
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  return {
    getTeam,
    postTeam,
    updateTeam,
    deleteEmployeeOfTeam,
  };
}
export default useTeam;
