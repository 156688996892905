import instance from "../config/api";

const DPath = "anesthetic-technique"
export default function useAnestheticTechnique(){
    async function getAnestheticTechniques(params){
        try {
            const result = await instance.get(DPath,{params})
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    return {
        getAnestheticTechniques
    }
}