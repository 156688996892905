import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { BoxContainer, FormDate, SubmitButton } from "../../components";
import { useProvider } from "../../service";
import useIntegratedSheduling from "../../service/useIntegratedSheduling";
import FormSelectFieldWithPaginate from "../../components/Form/FormSelectWithPaginate";
import { useMutation } from "react-query";
import Yup from "../../config/yup";
import { Loading } from "../../helper";
import { Helmet } from "react-helmet";
import { TITLE_NAME } from "../../config/config";
import { FormRadio } from "../../components/Form";
import useNotifier from "../../hooks/useNotifier";

function IntegrationScheduling() {
  const { getProviders } = useProvider();
  const { postIntegratedSheduling } = useIntegratedSheduling();
  const postIntegrateMutation = useMutation(postIntegratedSheduling);
  const notify = useNotifier();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(
    { all_employees, employees, initial_date, final_date, schedule_type },
    { resetForm }
  ) {
    const employee_ids = employees.map((employee) => employee.id);
    const data = {
      employee_id: all_employees === "true" ? "*" : employee_ids,
      initial_date,
      final_date,
      schedule_type
    };
    setLoading(true);
    postIntegrateMutation.mutate(data, {
      onSuccess: (data) => {
        notify(data.message, "success");
        setLoading(false);
        resetForm();
      },
      onError: (error) => {
        setLoading(false);
        notify(error.message, "error");
      },
    });
  }

  const validations = Yup.object().shape({
    employees: Yup.array().when("all_employees", {
      is: "false",
      then: Yup.array()
        .min(1, "É requerido")
        .required("É requerido")
        .nullable(),
      otherwise: Yup.array().nullable(),
    }),
    schedule_type: Yup.string().required("É requerido").nullable(),
    initial_date: Yup.string().required("É requerido").nullable(),
    final_date: Yup.string().required("É requerido").nullable(),
  });

  const initialValues = {
    all_employees: "false",
    employees: [],
    initial_date: null,
    final_date: null,
    schedule_type: null,
  };

  const radios = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  const scheduleType = [
    { label: "Agenda Local", value: "local" },
    { label: "Agenda Virtual", value: "virtual" },
  ];

  return (
    <>
      {loading && (
        <Loading message="Aguardando processamento de busca da agenda SIGA..." />
      )}
      <Helmet>
        <title>{TITLE_NAME} Agendamentos Integrados</title>
      </Helmet>
      <BoxContainer
        title={"Agendas Integradas"}
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "400px",
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validations}
          enableReinitialize={true}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container spacing={2} xs={"auto"}>
                <Grid item xs={"auto"}>
                  <FormRadio
                    name="all_employees"
                    legend="Aplicar para todos os profissionais ?"
                    radios={radios}
                    keys={["label", "value"]}
                    row
                    required
                    customChange={(value) => {
                      if (value === "true") setFieldValue("employee", []);
                    }}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <FormRadio
                    name="schedule_type"
                    legend="Tipo de Agenda"
                    radios={scheduleType}
                    keys={["label", "value"]}
                    row
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormSelectFieldWithPaginate
                    service={(params) =>
                      getProviders({
                        ...params,
                        active: true,
                      })
                    }
                    fields="name"
                    name="employees"
                    label="Profissional"
                    multiple
                    required
                    disabled={values.all_employees === "true"}
                    customHandleChange={(value) => {
                      value.forEach((v) => {
                        if (!!v && !v.sus_card) {
                          notify(
                            `O profissional ${
                              v.name.split(" ")[0]
                            } não possui CNS, por favor, atualizar os dados cadastrais`,
                            "error"
                          );
                        }
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <FormDate
                    name="initial_date"
                    label="Data"
                    minDate={new Date()}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <FormDate
                    name="final_date"
                    label="Data"
                    minDate={values.initial_date}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <SubmitButton
                    disabled={
                      (values.employees?.find((e) => !e.sus_card) &&
                        values.all_employees === "false") ||
                      values.employees?.find((e) => !e.sus_card) ||
                      loading
                    }
                  >
                    Importar
                  </SubmitButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </BoxContainer>
    </>
  );
}

export default IntegrationScheduling;
