import React from "react";
import {
  SubmitButton,
  FormSearchSelectField,
  DateTime,
} from "../../../../components/Form";
import { Formik, Form } from "formik";
import Yup from "../../../../config/yup";
import PaginatedTable from "../../../../components/Table/PaginatedTable";
import { Grid } from "@mui/material";
import useServices from "../../../../service/useServices";
import { useQuery } from "react-query";
import useNotifier from "../../../../hooks/useNotifier";

const ServicesForm = ({ rows = [], setRows, setRowsToDelete }) => {
  const [internRows, setInternRows] = React.useState(() => {
    return formatRows(rows);
  });
  const [services, setServices] = React.useState([]);
  const { getServices } = React.useMemo(useServices, []);
  const notify = useNotifier();

  // requests
  useQuery("services", () => getServices(), {
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onSuccess: (response) => {
      setServices(response);
    },
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  // Handle Functions
  function formatRows(rows) {
    return rows?.map(({ id, service, frequency }) => ({
      service: service.describe,
      frequency,
      id,
    }));
  }

  function handleDelete(index) {
    const filteredRows = rows.filter((_, key) => key !== index);
    const filteredInternRows = internRows.filter((_, key) => key !== index);
    setRows(filteredRows);
    setInternRows(filteredInternRows);

    if (!!rows[index]?.id) {
      setRowsToDelete((deletedRows) => [...deletedRows, rows[index].id]);
    }
  }

  function filterServices() {
    const filteredServices = services.filter(({ id }) => {
      return (
        !internRows.length ||
        internRows.some((internService) => internService.id !== id)
      );
    });

    return filteredServices;
  }

  function addRows(row, { resetForm }) {
    setRows((previousrows) => [...previousrows, row]);
    setInternRows((previousrows) => [
      ...previousrows,
      {
        service: row.service.describe,
        frequency: row.frequency,
      },
    ]);
    resetForm();
  }

  // Config form
  const initialValues = {
    service: null,
    frequency: "",
  };

  const validations = Yup.object().shape({
    service: Yup.object().required("É requerido").nullable(),
    frequency: Yup.string().required("É requerido"),
  });

  const collumns = [{ name: "Serviço" }, { name: "Frequência" }];

  return (
    <>
      <Formik
        onSubmit={addRows}
        initialValues={initialValues}
        validationSchema={validations}
      >
        {() => (
          <>
            <Form>
              <Grid
                xs={12}
                spacing={3}
                container
                item
                sx={{
                  marginTop: -2,
                  marginBottom: "20px",
                }}
              >
                <Grid xs={3} item>
                  <FormSearchSelectField
                    name="service"
                    label="Serviços"
                    required
                    options={filterServices()}
                    getOptionLabel={(service) => service.describe}
                  />
                </Grid>
                <Grid xs={3} item>
                  <DateTime title="Frequência" name="frequency" />
                </Grid>
                <Grid item xs={2}>
                  <SubmitButton>Adicionar</SubmitButton>
                </Grid>
              </Grid>
            </Form>
            <PaginatedTable
              sx={{ marginTop: 3 }}
              columns={collumns}
              data={internRows}
              actions
              actionsTypes={["delete"]}
              shouldDisableFields={["id"]}
              actionHandleDelete={handleDelete}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default ServicesForm;
