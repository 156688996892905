import React from "react";
import TableFilter from "../../components/Table/TableFilter";
import { Box, TablePagination } from "@mui/material";
import { addHours, format, parseISO } from "date-fns";
import useNotifier from "../../hooks/useNotifier";
import { useQuery } from "react-query";
import { useExamIntegrations } from "../../service";
import PreviewIcon from "@mui/icons-material/Preview";
import { DialogMedium, Loading } from "../../helper";
import ViewedDocumentHistory from "./ViewedDocumentHistory";

export default function ImageExamsHistory({ patientData }) {
  const [filter, setFilter] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [exams, setExams] = React.useState([]);
  const [document, setDocument] = React.useState(null);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [queryData, setQueryData] = React.useState(null);
  const notify = useNotifier();
  const { getImageExamsByPatient, viewedDocumentHistoryGenerate } =
    useExamIntegrations();

  const viewedDocumentHistoryQuery = useQuery(
    ["documentHistory", queryData],
    () => viewedDocumentHistoryGenerate(queryData),
    {
      onSuccess: (data) => {
        window.open(data.url, "_blank");
      },
      onError: (err) => notify(err.message, "error"),
      onSettled: () => {
        setQueryData(null);
      },
      enabled: !!queryData,
    }
  );

  const tableColumns = [
    {
      name: "Nº da Solicitação",
      field: "accessNumber",
      type: "text",
    },
    {
      name: "Exame",
      field: "exam",
      type: "text",
    },
    {
      name: "Empresa",
      field: "company",
      type: "text",
    },
    {
      name: "Data/Hora da Realização",
      field: "requestHour",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy HH:mm") : "Não informado",
      type: "date",
    },
  ];

  const imageExamsQuery = useQuery(
    ["image-exams", filter, page, limit],
    () =>
      getImageExamsByPatient({
        id: patientData.id,
        params: {
          ...filter,
          page: page + 1,
          limit,
        },
      }),
    {
      onSuccess: (data) => {
        setExams(data.items);
        setCount(data.totalItems);
      },
      onError: (err) => {
        notify(err.message, "error");
        setExams([]);
      },
      retry: false,
      enabled: !!patientData,
    }
  );

  function handleFilter(filter) {
    setPage(0);
    setFilter(filter);
  }

  return (
    <div>
      {viewedDocumentHistoryQuery.isFetching || imageExamsQuery.isFetching ? (
        <Loading
          backgroundColor={"rgba(255,255,255,0.8)"}
          maxWidth={"100%"}
          message={
            viewedDocumentHistoryQuery.isFetching
              ? "Solicitando dados ..."
              : "Sincronizando dados ..."
          }
        />
      ) : null}

      <DialogMedium
        title={`Histórico de visualização - ${document?.exam}`}
        open={openHistory}
        handleClose={() => setOpenHistory(false)}
        maxWidth={"lg"}
      >
        <ViewedDocumentHistory
          document={document}
          documentType="MV_IMAGE_DOC"
        />
      </DialogMedium>
      <TableFilter
        data={exams}
        columns={tableColumns}
        actions
        loading={imageExamsQuery.isLoading}
        emptyMessage={imageExamsQuery.error?.message}
        actionUniqueIdentifier={"id"}
        actionsTypes={["view", "image"]}
        disableActions={() => {
          if (viewedDocumentHistoryQuery.isFetching) {
            return true;
          }
        }}
        handleFilter={handleFilter}
        actionHandleImage={(_, a) => {
          setQueryData({
            document_id: a.accessNumber.toString(),
            type: "MV_IMAGE_DOC",
            patient_id: patientData.id,
            date: format(addHours(parseISO(a.requestDate), 3), "yyyy-MM-dd"),
          });
        }}
        actionHandleView={(_, a) => {
          setDocument(a);
          setOpenHistory(true);
        }}
        customizeActions={{
          view: {
            title: "Histórico de visualização",
            icon: <PreviewIcon />,
          },
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={count}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </div>
  );
}
