import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
import { handleParam } from "../utils/handleParam";
const path = "/patients";
const path2 = "/patients/?filters=name,id";

function usePatient() {
  async function getPatients({filter,...params}) {
    try {
      const filters = handleParam(filter,{
        physic_national: new RegExp(/^[0-9]{11}$/),
        sus_card: new RegExp(/^[0-9]{11,}$/),
        name: new RegExp(/.*/)
      })
      const response = await instance.get(path, { params: urlQuery({...params,...filters}) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getPatient(id) {
    try {
      const response = await instance.get(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getPatientsNameID() {
    try {
      const response = await instance.get(path2);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postPatient(data) {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updatePatient({id, data}) {
    try {
      const response = await instance.put(`${path}/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getAgainstReferences(page = 0, limit = 10, idPatient) {
    try {
      const response = await instance.get(
        `${path}/${idPatient}/against-references?page=${page}&limit=${limit}`
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getProgramsAvailableForPatient(
    page = 0,
    limit = 10,
    idPatient
  ) {
    try {
      const response = await instance.get(
        `${path}/${idPatient}/programs/available?page=${page}&limit=${limit}`
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getPatientsProgramsHistory(idPatient, idProgram) {
    try {
      const response = await instance.get(
        `${path}/${idPatient}/programs/${idProgram}/history`
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getPatientsPreillness(idPatient) {
    try {
      const response = await instance.get(`${path}/${idPatient}/preillness`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getActiveProblems(idPatient, filters) {
    try {
      const response = await instance.get(
        `${path}/${idPatient}/active-problems`,
        { params: urlQuery(filters) }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function putActiveProblems({ idPatient, activeProblemId }) {
    try {
      const response = await instance.put(
        `${path}/${idPatient}/active-problems/${activeProblemId}/inactivate`
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getDeclarations(idPatient, filters) {
    try {
      const response = await instance.get(
        `${path}/${idPatient}/documentations`,
        { params: urlQuery(filters) }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getPatientImmunizationRecord(idPatient, filters) {
    try {
      const response = await instance.get(
        `${path}/${idPatient}/immunization-record`,
        { params: urlQuery(filters) }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getTriagesByPatient(idPatient, params) {
    try {
      const result = await instance.get(`${path}/${idPatient}/triages`, { params })
      return result.data
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getPatientEvolution (id, params)  {
    try {
      const response = await instance.get(`${path}/${id}/families/records`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
  async function getPatientAllergies (id, params)  {
    try {
      const response = await instance.get(`${path}/${id}/allergies`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  return {
    getPatients,
    getTriagesByPatient,
    getPatient,
    getPatientsNameID,
    postPatient,
    updatePatient,
    getAgainstReferences,
    getProgramsAvailableForPatient,
    getPatientsProgramsHistory,
    getActiveProblems,
    putActiveProblems,
    getPatientsPreillness,
    getDeclarations,
    getPatientImmunizationRecord,
    getPatientEvolution,
    getPatientAllergies
  };
}
export default usePatient;
