import { format, isValid } from "date-fns";
import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

const path = "/soap";

function useSOAP(
  subjectivePermissions = {},
  objectvePermissions = {},
  assessmentPermissions = {},
  planPermissions = {}
) {
  async function postSOAP(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getSOAP(id) {
    try {
      const response = await instance.get(`${path}/${id}`);

      return response?.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getSoapsByPatient(id, params) {
    try {
      const response = await instance.get(`patients/${id}/soaps`, {
        params: urlQuery(params),
      });

      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function getAttendanceTypes(params) {
    try {
      const result = await instance.get("/attendances-types", {
        params,
      });
      return result.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function signSoap(id, pdfData) {
    try {
      const response = await instance.post(`${path}/${id}/sign`, pdfData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  function ModelDataSOAP(values, years) {
    try {
      //Inicializando arrays
      const admeasurement_objective = [];
      const ciapSubjective = [];
      const PreExistIllness = [];
      const ciapEvaluation = [];
      const cidEvaluation = [];
      const ciapPlan = [];
      const allergy_substances = {
        medication: false,
        food: false,
        others: false,
      };
      let pregnancy_record = null;
      let child_record = null;
      let ampi_data = null;

      if (!!values.admeasurement_objective && subjectivePermissions.create) {
        Object.entries(values.admeasurement_objective).forEach(
          ([id, value]) => {
            if (!!value) {
              admeasurement_objective.push({
                id,
                value,
              });
            }
          }
        );
      }

      if (values.doencasPreexistentes) {
        for (let i = 0; values.doencasPreexistentes.length > i; i++) {
          const id_preIllness = values.doencasPreexistentes[i].id;
          PreExistIllness.push({ id_preIllness });
        }
      }

      if (values.allergy_substances?.length) {
        const keys = Object.keys(allergy_substances);

        values.allergy_substances.forEach((value) => {
          if (keys.includes(value)) {
            allergy_substances[value] = true;
          }
        });
      }

      if (values.ciapSubjective) {
        for (let i = 0; values.ciapSubjective.length > i; i++) {
          ciapSubjective.push(values.ciapSubjective[i].id);
        }
      }

      if (values.ciapEvaluation) {
        for (let i = 0; values.ciapEvaluation.length > i; i++) {
          ciapEvaluation.push(values.ciapEvaluation[i].id);
        }
      }

      if (values.ciapPlan) {
        for (let i = 0; values.ciapPlan.length > i; i++) {
          ciapPlan.push(values.ciapPlan[i].id);
        }
      }
      if (values.cid) {
        for (let i = 0; values.cid.length > i; i++) {
          cidEvaluation.push(values.cid[i].id);
        }
      }

      if (values.pregnant) {
        console.log(values.last_menstrual_period, values.obstetric_usg_date)

        pregnancy_record = {
          last_menstrual_period: values.last_menstrual_period
            ? format(new Date(values.last_menstrual_period), "yyyy-MM-dd")
            : null,
          obstetric_usg_date: values.obstetric_usg_date
            ? format(new Date(values.obstetric_usg_date), "yyyy-MM-dd")
            : null,
          obstetric_usg_weeks: values.obstetric_usg_weeks,
          obstetric_usg_days: values.obstetric_usg_days,
          fetal_movements: values.fetal_movements,
          folic_acid_supplementation: values.folic_acid_supplementation,
          calcium_carbonate_supplementation:
            values.calcium_carbonate_supplementation,
          edema: values.edema === "Sem edema" ? null : values.edema,
          gestational_risk: values.gestational_risk,
          fetal_presentation: values.fetal_presentation,
          normal_clinical_exam: values.normal_clinical_exam,
          normal_gynecological_exam: values.normal_gynecological_exam,
          normal_breast_exam: values.normal_breast_exam,
          ferrous_sulfate_supplementation:
            values.ferrous_sulfate_supplementation,
          previous_pregnancies: null,
          gravidity: Number(values.gravidity),
          parity: Number(values.parity),
          vaginal_deliveries_number: Number(values.vaginal_deliveries_number),
          cesarean_deliveries_number: Number(values.cesarean_deliveries_number),
          abortions_number: Number(values.abortions_number),
          planned_pregnancy: values.planned_pregnancy,
        };

        Object.entries(values).forEach(([key, value]) => {
          if (key.match(/previous_pregnancies/g)) {
            if (Array.isArray(pregnancy_record.previous_pregnancies)) {
              pregnancy_record.previous_pregnancies.push(value);
            } else {
              pregnancy_record.previous_pregnancies = [value];
            }
          }
        });
      }

      if (years <= 6) {
        child_record = {
          breastfeeding: values.breastfeeding,
          ortolani: values.ortolani,
          neuropsychomotor_development: values.neuropsychomotor_development,
          red_reflex_exam: values.red_reflex_exam,
        };
      }

      if (years >= 60) {
        ampi_data = {
          ampi_last_classification:
            values.ampi_last_classification &&
            isValid(new Date(values.ampi_last_classification))
              ? format(values.ampi_last_classification, "yyyy-MM-dd")
              : null,
          ampi_classification: values.ampi_classification,
        };
      }

      console.log(pregnancy_record)

      //Contrução do json final de envio
      const soapData = {
        id_attendance: values.idAttendance,
        id_employee: values.idEmployee,
        subjective: subjectivePermissions.create
          ? {
              reason: values.reason,
              ciaps_ids: ciapSubjective,
            }
          : null,
        admeasurement_objective: admeasurement_objective,
        objective: objectvePermissions.create
          ? {
              physical_exams: values.physical_exams,
              allergy_status: values.radioStatusAlergia,
              allergy_substances,
              allergy_description: values.allergy_description,
              complementary_exams: values.complementary_exams,
              risk_stratification: values.risk_stratification,
              vaccine_up_to_date:
                typeof values.radioVacina === "boolean"
                  ? values.radioVacina
                  : undefined,
              pregnancy_record,
              child_record,
              ...ampi_data,
            }
          : null,
        PreExistIllness: objectvePermissions.create ? PreExistIllness : null,
        assessment: assessmentPermissions.create
          ? {
              query_assessment: values.consult,
              ciaps_ids: ciapEvaluation?.length ? ciapEvaluation : null,
              cids_ids: cidEvaluation,
              activeProblems: values.insert_in_active_problems,
              oral_health_surveillance: values.oral_health_surveillance,
            }
          : null,
        plan: planPermissions.create
          ? {
              query_plan: values.plan,
              ciaps_ids: ciapPlan,
              prescription: values.prescriptions,
              programs:
                values.programs && values.programs.length
                  ? values.programs.map((program) => program.id)
                  : [],
              documents: values.declarations,
              interventions: values.interventions,
              anesthesia: values.anesthesia,
              prosthesisCommand: values.prosthesisCommand,
              procedure_id: values.plan_procedure?.id || null,
              pts: values.pts,
              pts_describe: values.pts_describe,
              teeth: values.teeth,
              sextant: values.sextant,
              arch: values.arch,
              others: values.others,
              braces: values.braces,
              retainer: values.retainer,
              lower_denture: values.lower_denture,
              upper_denture: values.upper_denture,
              orthodontic: values.orthodontic,
              endodontics: values.endodontics,
              dental_supplies: values.dental_supplies,
              // treatment_type_procedure_id: values.treatment_type_procedure_id,
              treatment_type_conclusion_procedure_id:
                values.treatment_type_conclusion_procedure_id,
              reavaluation_comments: values.reavaluation_comments,
            }
          : null,
        against_reference: planPermissions.create ? values.references : null,
        reevaluate: values.reevaluate,
        conducts: values.conducts,
        attendance_type_id: values.attendance_type,
        dental_appointment_type: values.dental_appointment_type,
        types_attendance_urgency: values?.types_attendance_urgency,
      };

      return soapData;
    } catch (err) {
      console.log(err, "SOAP_ERR_USE_SOAP_HOOK");
      throw err;
    }
  }

  async function deleteSoap(id) {
    try {
      const result = await instance.delete(`${path}/${id}`);
      return result.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    postSOAP,
    getSOAP,
    ModelDataSOAP,
    deleteSoap,
    getSoapsByPatient,
    signSoap,
    getAttendanceTypes,
  };
}
export default useSOAP;
