import React from "react";
import { SubmitButton, FormTextField } from "../../../../components/Form";
import { Formik, Form } from "formik";
import Yup from "../../../../config/yup";
import PaginatedTable from "../../../../components/Table/PaginatedTable";
import { Grid } from "@mui/material";

const MedicationForm = ({ rows = [], setRows, setRowsToDelete }) => {
  // Handle Functions
  function handleDelete(index) {
    const filteredrows = rows.filter((_, key) => key !== index);
    setRows(filteredrows);

    if (!!rows[index]?.id) {
      setRowsToDelete((deletedRows) => [...deletedRows, rows[index].id]);
    }
  }

  function addRows(row, { resetForm }) {
    setRows((previousrows) => [...previousrows, row]);
    resetForm();
  }

  const initialValues = {
    name: "",
    quantity: null,
    unit: "",
    interval: "",
    notes: "",
  };

  const validations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
    quantity: Yup.number()
      .min(0.1, "A quantidade deve ser maior que 0")
      .required("É r  equerido").nullable(),
    unit: Yup.string().required("É requerido"),
    interval: Yup.string().required("É requerido"),
    notes: Yup.string(),
  });

  const collumns = [
    { name: "Medicamento" },
    { name: "Quantidade" },
    { name: "Unidade" },
    { name: "Frequência" },
    { name: "Descrição" },
  ];

  return (
    <Formik
      onSubmit={addRows}
      initialValues={initialValues}
      validationSchema={validations}
    >
      {() => (
        <>
          <Form>
            <Grid
              xs={12}
              spacing={3}
              container
              item
              sx={{
                marginTop: -2,
                marginBottom: "20px",
              }}
            >
              <Grid xs={4} md={3} item>
                <FormTextField name="name" label="Medicamento" required />
              </Grid>
              <Grid xs={3} md={2} item>
                <FormTextField
                  name="quantity"
                  label="Quantidade"
                  type="number"
                  required
                />
              </Grid>
              <Grid xs={3} md={2} item>
                <FormTextField name="unit" label="Unidade" required />
              </Grid>
              <Grid xs={12} item container spacing={3}>
                <Grid xs={3} md={2} item>
                  <FormTextField label="Frequência" name="interval" required />
                </Grid>
                <Grid xs={7} md={5} item>
                  <FormTextField name="notes" label="Descrição" required />
                </Grid>
                <Grid item xs={3} md={2}>
                  <SubmitButton>Adicionar</SubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
          <PaginatedTable
            sx={{ marginTop: 3 }}
            columns={collumns}
            data={rows}
            actions
            actionsTypes={["delete"]}
            shouldDisableFields={["id"]}
            actionHandleDelete={handleDelete}
          />
        </>
      )}
    </Formik>
  );
};

export default MedicationForm;
