import { Button } from "@mui/material";
import { ReactComponent as Emergency } from "../../../../assets/svg/emergency.svg";
import AllergyHistory from "..";
import { ConfirmDialog, DialogMedium } from "../../../../helper";
import { useState } from "react";
export default function AllergyButton({
  attendance,
  openAllergyAlert,
  setOpenAllergyAlert,
  allergies,
}) {
  const [openAllergy, setOpenAllergy] = useState(false);
  let hasAllergy = false;

  if (allergies?.allergy_substances) {
    hasAllergy = Object.entries(allergies.allergy_substances).some(
      ([_, value]) => value
    );
  }

  function mountMessage(allergySubstances) {
    const substances = [];
    if (allergySubstances.medication) {
      substances.push("*Medicamento*");
    }
    if (allergySubstances.food) {
      substances.push("*Comida*");
    }
    if (allergySubstances.others) {
      substances.push("*Outros*");
    }

    return substances.reduce((prev, curr, index, all) => {
      if (!index) return `${prev} a ${curr}`;

      if (index === all.length - 1) {
        return `${prev} e ${curr}`;
      }
      return `${prev}, ${curr}`;
    }, "Paciente contém alergia");
  }

  if (!hasAllergy) return null;
  return (
    <>
      <ConfirmDialog
        message={mountMessage(allergies?.allergy_substances)}
        open={openAllergyAlert}
        maxWidth="sm"
        fullWidth
        handleClose={() => {
          return;
        }}
        actions
        handleConfirm={() => {
          setOpenAllergyAlert(false);
        }}
      />
      <DialogMedium
        fullWidth
        maxWidth={"lg"}
        open={openAllergy}
        handleClose={() => setOpenAllergy(false)}
        children={<AllergyHistory attendanceID={attendance?.id} />}
      />

      <Button
        sx={{
          bgcolor: "rgba(244,64,64,1)",
          color: "#FFF",
          display: "flex",
          alignItems: "center",
          "&:hover": {
            bgcolor: "rgba(244,64,64,0.6)",
          },
        }}
        variant="contained"
        startIcon={<Emergency />}
        onClick={() => setOpenAllergy(true)}
      >
        Alergia
      </Button>
    </>
  );
}
