import { useEffect, useRef } from "react";
import Badge from "./Badge";

const missingToothProblemId = "e23b2b2a-3515-46fc-8902-e775b30b3bd9";

export default function Tooth({
  onCrownClick,
  onRootClick,
  id,
  dRoot,
  dCrown,
  badgePosition,
  strokeWidth,
  hasEvolutions,
  svg,
  disabled,
}) {
  const crownRef = useRef(null);
  const rootRef = useRef(null);
  const style = {
    cursor: svg || disabled ? "" : "pointer",
  };

  const hasMissingToothProblem = hasEvolutions?.problems?.find(e => e === missingToothProblemId);

  function handleMouseEvent(ref, type) {
    if (svg || disabled) return;
    if (hasEvolutions?.crown && crownRef === ref) return;
    if (hasEvolutions?.root && rootRef === ref) return;

    if (type === "leave") {
      ref.current.style.fill = "";
    } else if (type === "over") {
      // ref.current.style.fill = "#73DB42";
    }
  }

  useEffect(() => {
    if (hasEvolutions?.crown && crownRef) {
      // crownRef.current.style.fill = "#73DB42";
      // crownRef.current.style.stroke = "#73DB42";
    } else {
      crownRef.current.style.fill = "";
      crownRef.current.style.stroke = "#7564EF";
    }
    if (hasEvolutions?.root && rootRef) {
      // rootRef.current.style.fill = "#73DB42";
      // rootRef.current.style.stroke = "#73DB42";
    } else {
      rootRef.current.style.fill = "";
      rootRef.current.style.stroke = "#7564EF";
    }
    if (disabled) {
      crownRef.current.style.stroke = "#f1f1f1";
      rootRef.current.style.stroke = "#f1f1f1";
    }
  }, [hasEvolutions, disabled]);

  return (
    <g id={`@Tooth${id}`}>
      <path
        d={dRoot}
        strokeWidth={strokeWidth}
        stroke="#7564EF"
        fill={hasEvolutions && hasMissingToothProblem ? "#c3c3c3" : "#fff"}
        ref={rootRef}
        onClick={() => {
          if (!svg && !disabled) onRootClick(rootRef, id);
        }}
        onMouseOver={() => handleMouseEvent(rootRef, "over")}
        onMouseLeave={() => handleMouseEvent(rootRef, "leave")}
        style={style}
      />
      <path
        d={dCrown}
        stroke="#7564EF"
        fill={hasEvolutions && hasMissingToothProblem ? "#c3c3c3" : "#fff"}
        strokeWidth={strokeWidth}
        ref={crownRef}
        onClick={() => {
          if (!svg && !disabled) onCrownClick(crownRef, id);
        }}
        onMouseOver={() => handleMouseEvent(crownRef, "over")}
        onMouseLeave={() => handleMouseEvent(crownRef, "leave")}
        style={style}
      />
      {hasEvolutions && hasEvolutions?.crown + hasEvolutions?.root > 0 && (
        <Badge
          content={hasEvolutions?.crown + hasEvolutions?.root}
          position={badgePosition}
        />
      )}
    </g>
  );
}
