import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
function BoxContainer({
  title,
  align,
  children,
  shadowOff,
  backTo,
  backState,
  hideBackButton = false,
  sx
}) {
  const boxShadow = {
    border: "3px solid transparent",
    borderColor: "white",
    borderWidth: "3px",
    boxShadow: "0 0 0 1.5px #FFFFFF",
  };

  const navigate = useNavigate();

  function goBack() {
    navigate(backTo || -1, { state: backState });
  }
  return (
    <>
      <Card sx={!shadowOff ? {
        ...sx
      } : boxShadow}>
        <CardHeader
          sx={{ textAlign: `${align}` }}
          title={
            <Typography
              variant="h7"
              color="secondary"
              fontWeight="500"
              textAlign={align}
              display={"flex"}
              alignItems={"center"}
            >
              {!hideBackButton && (
                <IconButton onClick={goBack}>
                  <ArrowBack />
                </IconButton>
              )}
              {title}
            </Typography>
          }
        />
        <Divider variant="middle" sx={{ marginTop: "-10px" }} />
        <CardContent sx={{ position: "relative" }}>{children}</CardContent>
      </Card>
    </>
  );
}

BoxContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  align: PropTypes.oneOf(["left", "right", "center", "justify"]),
  children: PropTypes.node,
};

export default BoxContainer;
