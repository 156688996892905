import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/programs";

export default function useProgram() {
  async function getPrograms(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getProgramsOfPatient(id) {
    try {
      const response = await instance.get(`/patients/${id}/programs`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getProgramsPatientHealth(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(`/programs/patients/`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postPrograms(data) {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function putPrograms({ programId, data }) {
    try {
      const response = await instance.put(`${path}/${programId}/update`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getPrograms,
    postPrograms,
    putPrograms,
    getProgramsOfPatient,
    getProgramsPatientHealth,
  };
}
