import React from "react";
import { Box } from "@mui/material";
import AdmeasurementsStep from "./SettingsSoapStep/AdmeasurementsStep";
import PreillnessStep from "./SettingsSoapStep/PreillnessStep";
import { Tabs } from "../../components";

export default function SettingsSoap() {
  const tabList = [
    {
      label: "Itens de Aferição",
      content: <AdmeasurementsStep />,
    },
    {
      label: "Comorbidades",
      content: <PreillnessStep />,
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs tabs={tabList} />
      </Box>
    </>
  );
}
