import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import PatientServicesDialog from "../../../components/PatientServicesDialog";
import { BoxContainer } from "../../../components";
import { useLocation } from "react-router-dom";
import useAttendances from "../../../service/useAttendaces";
import AttendanceCard from "../../../components/AttendanceCard/AttendanceCard";
import { useConfirm } from "../../../helper/BlockNavigation";
import usePermissions from "../../../config/usePermissions";
import { VerifyPermission } from "../../../components/RequireAuth/VerifyPermission";
import { useForm } from "react-hook-form";
import Yup from "../../../config/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TextField } from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

function DeleteAttendance() {
  const { confirm } = useConfirm();
  const notify = useNotifier();
  const [attendance, setAttendance] = useState(null);
  const [numberToFilter, setNumberToFilter] = useState(null);
  const location = useLocation();
  const [openServices, setOpenServices] = useState({ isOpen: false });
  const permissions = usePermissions("/attendance/delete");

  const deleteFormValidations = Yup.object().shape({
    exclusion_notes: Yup.string().nullable().required("É requerido"),
  });

  const filterFormValidations = Yup.object().shape({
    number: Yup.number()
      .nullable()
      .required("Preencha o número do atendimento"),
  });

  const deleteForm = useForm({
    defaultValues: {
      exclusion_notes: "",
    },
    resolver: yupResolver(deleteFormValidations),
  });

  const filterForm = useForm({
    defaultValues: { number: "" },
    resolver: yupResolver(filterFormValidations),
  });

  const number = filterForm.watch("number");

  const { getAttendances, deleteAttendance, getAttendanceById } =
    useAttendances();

  const deleteAttendanceMutation = useMutation(deleteAttendance);

  useQuery("attendance-by-id", () => getAttendanceById(location.state.use), {
    enabled: !!location?.state?.use,
    onSuccess(response) {
      setOpenServices({ isOpen: true, patient: response });
    },
  });

  const attendancesQuery = useQuery(
    ["attendances", numberToFilter],
    () => getAttendances({ number: numberToFilter }),
    {
      enabled: !!numberToFilter,
      onSuccess(response) {
        const attendance = response?.items[0];

        if (attendance && attendance.risk_classification_id) {
          notify(
            "Não é possível cancelar um atendimento já classificado",
            "info"
          );
        } else {
          setAttendance(response.items[0]);
        }

        setNumberToFilter(numberToFilter);
      },
      onError(error) {
        setAttendance(null);
        notify(error.message, "error");
      },
    }
  );

  function handleDelete(values) {
    confirm("Tem certeza que deseja *excluir* este Atendimento?", () => {
      deleteAttendanceMutation.mutate(
        { id: attendance.id, ...values },
        {
          onSuccess: () => {
            notify("Esse atendimento foi removido com sucesso.", "success");
            setAttendance(null);
            deleteForm.reset();
            filterForm.reset();
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    });
  }

  return (
    <VerifyPermission valid={permissions.delete} redirect={false}>
      <PatientServicesDialog
        handleClose={() => setOpenServices({ isOpen: false })}
        open={openServices}
        title="Atendimento Inicial"
      />
      <BoxContainer title="Exclusão de Atendimento">
        <Grid
          component="form"
          container
          spacing={2}
          onSubmit={deleteForm.handleSubmit(handleDelete)}
        >
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Número de Atendimento"
                name="number"
                control={filterForm.control}
                type="number"
                placeholder="Buscar atendimento..."
                required
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                disabled={!number}
                loading={attendancesQuery.isFetching}
                onClick={filterForm.handleSubmit(({ number }) =>
                  setNumberToFilter(number)
                )}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            {attendance && (
              <Grid item xs={7}>
                <AttendanceCard attendance={attendance} />
              </Grid>
            )}
            <Grid item xs={7} mt={3}>
              <TextField
                name="exclusion_notes"
                control={deleteForm.control}
                multiline
                minRows={7}
                maxRows={7}
                label="Motivo da Exclusão"
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              loading={deleteAttendanceMutation.isLoading}
              disabled={!!attendance?.risk_classification_id}
            >
              Excluir atendimento
            </Button>
          </Grid>
        </Grid>
      </BoxContainer>
    </VerifyPermission>
  );
}

export default DeleteAttendance;
