import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const priorityPath = "/totems/tokens/priorities";

export default function useTotemPriorities() {
  async function getPriorities(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(
        `${priorityPath}?${urlQuery(params)}`
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  const postPriority = async (data) => {
    try {
      const response = await instance.post(priorityPath, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const updatePriority = async ({ id, data }) => {
    try {
      const response = await instance.put(`${priorityPath}/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };
  const deletePriority = async (id) => {
    return instance.delete(`${priorityPath}/${id}`).then((res) => res.data);
  };
  return {
    getPriorities,
    postPriority,
    updatePriority,
    deletePriority,
  };
}
