import { Grid } from "@mui/material";
import React from "react";
import { BoxContainer } from "../../components";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/system";
import SettingsSchedule from "./SettingsSchedule";
import SettingsProfissional from "./SettingsProfissional";
import SettingsSoap from "./SettingsSoap";
import SettingsAttendance from "./SettingsAttendance";
import SettingsDocuments from "./SettingsDocuments";
import SettingsCaller from "./SettingsCaller";
import SettingsAccess from "./SettingsAccess";
import SettingsTotem from "./SettingsTotem";
import SettingStation from "./SettingsStation";
import usePermissions from "../../config/usePermissions";
import { Helmet } from "react-helmet";

const TabBackground = styled(Tab)(({ theme }) => ({
  marginBottom: 10,
  background: theme.palette.secondary.medium,
  color: theme.palette.primary.light,
  borderRadius: 5,
  transition: ".3s",
  textTransform: "capitalize",
  minHeight: 10,
  padding: 10,
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    borderColor: "transparent",
  },
}));

function Settings() {
  const permissions = usePermissions("all");

  function handleChange(event, value) {
    setTab(value);
  }

  const tabsHeader = [
    {
      label: "Agenda",
      active: permissions["/settings/schedule"],
    },
    {
      label: "Profissional",
      active: permissions["/settings/employee"],
    },
    {
      label: "SOAP",
      active: permissions["/settings/soap"],
    },
    {
      label: "Atendimento",
      active: permissions["/settings/attendance"],
    },
    {
      label: "Documentos",
      active: permissions["/settings/documents"],
    },
    {
      label: "Chamador",
      active: permissions["/settings/caller"],
    },
    {
      label: "Acesso",
      active: permissions["/settings/access"],
    },
    {
      label: "Totem",
      active: permissions["/settings/totem"],
    },
    {
      label: "Estação",
      active: permissions["/settings/station"],
    },
  ].filter((t) => t.active);

  const [tab, setTab] = React.useState(
    tabsHeader.findIndex((tab) => tab.active)
  );
  const getIndex = (label) => {
    return tabsHeader.findIndex((v) => v.label === label);
  };

  return (
    <Grid container spacing={2}>
      <Helmet>
        <title>Configurações | {tabsHeader[tab].label}</title>
      </Helmet>
      <TabContext value={tab}>
        <Grid item xs={3}>
          <BoxContainer title="Menu" align="center">
            <TabList
              orientation="vertical"
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{ "& .MuiTabs-indicator": { display: "none" } }}
            >
              {tabsHeader.map((item, key) => (
                <TabBackground label={item.label} value={key} />
              ))}
            </TabList>
          </BoxContainer>
        </Grid>
        <Grid item xs={9}>
          {!!tabsHeader.length && (
            <BoxContainer
              title={`Configuração do(a) ${tabsHeader[tab]?.label}`}
            >
              <TabPanel value={getIndex("Agenda")}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <SettingsSchedule />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={getIndex("Profissional")}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <SettingsProfissional />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={getIndex("SOAP")}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <SettingsSoap />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={getIndex("Atendimento")}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <SettingsAttendance />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={getIndex("Documentos")}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <SettingsDocuments />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={getIndex("Chamador")}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <SettingsCaller />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={getIndex("Acesso")}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <SettingsAccess />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={getIndex("Totem")}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <SettingsTotem />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={getIndex("Estação")}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <SettingStation />
                  </Grid>
                </Grid>
              </TabPanel>
            </BoxContainer>
          )}
        </Grid>
      </TabContext>
    </Grid>
  );
}

export default Settings;
