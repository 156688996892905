import React from "react";
import { useQuery, useQueryClient } from "react-query";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import { DialogMedium, Loading } from "../../../helper";
import { useAgainstReferences } from "../../../service";
import { format, parseISO } from "date-fns";
import { Box, TablePagination } from "@mui/material";
import AgainstReferenceView from "./AgainstReferenceView";
import AgainstReferenceResponse from "./AgainstReferenceResponse";

export default function AgainstReference() {
  const queryClient = useQueryClient();
  const [openView, setOpenView] = React.useState(false);
  const [openResponse, setOpenResponse] = React.useState(false);
  const [action, setAction] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [references, setReferences] = React.useState();
  const [unicReference, setUnicReference] = React.useState();

  const { getTotalAgainstReferences } = useAgainstReferences();

  const againstReferenceQuery = useQuery(
    ["againstReferences", [page, limit]],
    () => getTotalAgainstReferences(page, limit),
    { keepPreviousData: true, refetchOnWindowFocus: false, retry: false }
  );

  React.useEffect(() => {
    if (
      againstReferenceQuery.isSuccess &&
      againstReferenceQuery.data &&
      againstReferenceQuery.data.items
    ) {
      if (count === 0) {
        setCount(againstReferenceQuery.data.totalItems);
      }
      setReferences(
        againstReferenceQuery.data.items.map(
          ({
            response,
            justification,
            number,
            requested_specialty,
            requested_employee,
            priority,
            id,
            createdAt,
            soap,
            cids,
            details,
          }) => {
            const dataReference = format(parseISO(createdAt), "dd/MM/yyyy");
            return {
              requestor_name: soap.employee_specialty.employee.name,
              requested_employee,
              requested_specialty: requested_specialty.describe,
              priority,
              justification,
              number,
              response,
              id,
              details,
              dataReference,
              patient: soap.attendance?.patient,
              soap,
              cids: cids,
            };
          }
        )
      );
      setRows(
        againstReferenceQuery.data.items.map(
          ({ createdAt, soap, requested_specialty, concluded }) => {
            if (concluded === false) {
              concluded = "Aberto";
            } else if (concluded === true) {
              concluded = "Fechado";
            }
            let disabled;
            if (concluded === "Fechado") {
              disabled = true;
            } else {
              disabled = false;
            }
            const dateReference = format(parseISO(createdAt), "dd/MM/yyyy");
            return {
              dateReference,
              patient_name: soap?.attendance?.patient?.name,
              requestor_name: soap.employee_specialty.employee.name,
              requested_specialty: requested_specialty.describe,
              situation: concluded,
              suspended: disabled,
            };
          }
        )
      );
    }
  }, [againstReferenceQuery.dataUpdatedAt]);
  function handleView() {
    setOpenView(true);
    setAction("view");
  }
  function handleResponse() {
    setOpenResponse(true);
    setAction("edit");
  }
  function handleClose() {
    setOpenResponse(false);
    setOpenView(false);
    queryClient.invalidateQueries("againstReferences");
  }

  const tableColumns = [
    { name: "Data Solicitada" },
    { name: "Paciente" },
    { name: "Profissional Solicitante" },
    { name: "Especialidade Requisitada" },
    { name: "Situação" },
  ];
  if (againstReferenceQuery.isLoading) return <Loading />;
  return (
    <>
      <DialogMedium
        title="Visualizar Referência / Contrarreferência"
        open={openView}
        type={action}
        handleClose={handleClose}
      >
        <AgainstReferenceView reference={unicReference} />
      </DialogMedium>
      <DialogMedium
        title="Responder Referência / Contrarreferência"
        open={openResponse}
        type={action}
        handleClose={handleClose}
      >
        <AgainstReferenceResponse
          handleClose={handleClose}
          reference={unicReference}
        />
      </DialogMedium>
      <PaginatedTable
        data={rows}
        columns={tableColumns}
        loading={
          againstReferenceQuery.isFetching &&
          againstReferenceQuery.isPreviousData
        }
        actions
        actionsTypes={["view", "response"]}
        disableRows={(row) => row.suspended}
        disableActions={(row, action) => row.suspended && action === "response"}
        actionHandleResponse={(index) => {
          setUnicReference(references[index]);
          handleResponse(index);
        }}
        actionHandleView={(index) => {
          setUnicReference(references[index]);
          handleView(index);
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={count}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimit(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}
