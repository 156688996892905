import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/system";
import { useTheme } from "@emotion/react";

const TabBackground = styled(Tab)(({ theme }) => ({
  background: theme.palette.primary.medium,
  color: theme.palette.primary.font,
  borderRadius: 5,
  marginRight: 5,
  transition: ".3s",
  textTransform: "capitalize",
  minHeight: 10,
  padding: 10,
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    borderColor: "transparent",
  },
}));
function Tabs({
  tabs,
  labelIdentifier,
  content = true,
  orientation = "horizontal",
  variant,
  scrollButtons,
  sx = {},
  handleClick,
}) {
  const theme = useTheme();
  const [tab, setTab] = React.useState(0);

  function handleChange(_, value) {
    setTab(value);
  }

  return (
    <TabContext value={tab}>
      <TabList
        variant={variant}
        scrollButtons={scrollButtons}
        orientation={orientation}
        onChange={handleChange}
        sx={{
          "&, & .MuiTabs-fixed, & .MuiTabs-flexContainer, & .MuiTab-root": {
            overflow: 'unset !important'
          },
          "& .MuiTabs-indicator": { display: "none" },
          "& .MuiTabs-scrollButtons": {
            "&.Mui-disabled": { opacity: 0.3 },
            color: theme.palette.primary,
          },
          maxWidth: 1540,
          margin: 0,
          display: "flex",
          alignItems: "center",
          ...sx,
        }}
      >
        {tabs?.map(({ onClick, icon, ...tab }, index) => {
          const label = labelIdentifier ? tab[labelIdentifier] : tab.label;

          return (
            <TabBackground
            key={`${label} - ${index}`}
            label={label}
            value={index}
            icon={icon}
            onClick={() => {
                if (handleClick) handleClick(tab);
                else if (onClick) onClick(tab);
              }}
            />
          );
        })}
      </TabList>
      {content &&
        tabs?.map((tab, index) => (
          <TabPanel
            key={`${tab} - ${index}`}
            value={index}
            sx={{
              "&.MuiTabPanel-root": {
                p: 0,
              },
            }}
          >
            {tab.content}
          </TabPanel>
        ))}
    </TabContext>
  );
}

export default React.memo(Tabs);
