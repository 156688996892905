import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import React from "react";
import { useField, useFormikContext } from "formik";
import { TextField } from "@mui/material";
import { ptBR } from "date-fns/locale";
function FormTimePicker({ name, label, disabled }) {
  const [field, meta] = useField(name);
  const settings = {
    error: undefined,
    helperText: "",
  };
  if (meta && meta.touched && meta.error) {
    settings.error = true;
    settings.helperText = meta.error;
  }
  const { setFieldValue, setFieldTouched } = useFormikContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
      <TimePicker
        {...field}
        name={field.name}
        onChange={(value) => setFieldValue(field.name, value)}
        label={label}
        size="small"
        {...settings}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            type="time"
            {...params}
            onBlur={() => {
              setFieldTouched(field.name, true);
            }}
            onChange={() => {
              setFieldTouched(field.name, true);
            }}
            error={settings.error ? true : false}
            helperText={settings.error ? settings.helperText : ""}
            size="small"
          />
        )}
      ></TimePicker>
    </LocalizationProvider>
  );
}

export default FormTimePicker;
