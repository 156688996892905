import { useCallback } from "react";
import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "medical-records";

function useMedicalRecords() {
  const postMedicalRecords = useCallback(async (data) => {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const getMedicalRecords = useCallback(async (attendanceId, params) => {
    try {
      const response = await instance.get(
        `/attendances/${attendanceId}/${path}`,
        {
          params: urlQuery(params),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const patchMedicalRecords = useCallback(async ({ id, data }) => {
    try {
      const response = await instance.patch(`${path}/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const deleteMedicalRecords = useCallback(async (id) => {
    try {
      const response = await instance.delete(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  return {
    postMedicalRecords,
    getMedicalRecords,
    patchMedicalRecords,
    deleteMedicalRecords,
  };
}
export default useMedicalRecords;
