import { header, footer, title } from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";
import { format, isValid, parseISO } from "date-fns";

function formMakerPDF(
  data,
  additionalData,
  signature,
  signatureValidationPath
) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const additionalInformations = {
    margin: [-10, 0, 0, 0],
    columnGap: -25,
    columns: [
      {
        type: "none",
        ul: [],
      },
      {
        type: "none",
        ul: [],
      },
    ],
  };

  additionalData?.forEach(({ label, value }, index) => {
    const formatedField = {
      text: [{ text: `${label}: `, bold: true }, { text: value }],
      margin: [0, 0, 0, 5],
    };

    if (index % 2 === 0) {
      additionalInformations.columns[0].ul.push(formatedField);
    } else {
      additionalInformations.columns[1].ul.push(formatedField);
    }
  });

  const documentInformations = [
    {
      margin: [0, 30, 0, 10],
      fontSize: 14,
      text: "Dados do documento",
      bold: true,
      style: "header",
    },
    {
      margin: [-10, 0, 0, 0],
      columnGap: -25,
      columns: [
        {
          type: "none",
          ul: [
            {
              text: [
                { text: "Profissional: ", bold: true },
                { text: data.employee.name },
              ],
              margin: [0, 0, 0, 5],
            },
            {
              text: [{ text: "Status: ", bold: true }, { text: data.status }],
              margin: [0, 0, 0, 5],
            },
          ],
        },
        {
          type: "none",
          ul: [
            {
              text: [
                { text: "Data de resposta: ", bold: true },
                { text: format(parseISO(data.created_at), "dd/MM/yyyy") },
              ],
              margin: [0, 0, 0, 5],
            },
            {
              text: [{ text: "Versão: ", bold: true }, { text: data.version }],
              margin: [0, 0, 0, 5],
            },
          ],
        },
      ],
    },
  ];

  const records = [
    {
      margin: [0, 30, 0, 10],
      fontSize: 14,
      text: "Campos do documento",
      bold: true,
      style: "header",
    },
    {
      columnGap: 5,
      columns: [],
    },
  ];

  if (!data.fields.length) {
    records[1].columns.push({
      text: "Nenhum campo com resposta encontrado",
      margin: [0, 0, 0, 5],
    });
  }

  data.fields.forEach(({ label, value, type, options }) => {
    let formatedValue = type === "label" ? "" : "Não informado";

    if (value === "true" || value === true) {
      formatedValue = "Sim";
    } else if (value === "false" || value === false) {
      formatedValue = "Não";
    } else if (type === "date") {
      formatedValue = isValid(parseISO(value))
        ? format(parseISO(value), "dd/MM/yyyy")
        : null;
    } else if (type === "select") {
      if (value?.label) {
        formatedValue = value?.label;
      }

      const option = options?.find((option) => option.value === value);

      if (option) formatedValue = option.label;
    } else if (value) {
      formatedValue = value;
    }

    let data;
    const formattedLabel = { text: label.replace(/:$/, ""), bold: true };

    if (type === "checkbox") {
      if (Array.isArray(formatedValue)) {
        data = [
          formattedLabel,
          {
            margin: [10, 5, 0, 5],
            ul: formatedValue.map((option) => ({
              text: option,
              margin: [0, 0, 0, 2],
            })),
          },
        ];
      } else {
        data = {
          margin: [0, 0, 0, 5],
          text: [formattedLabel, { text: "Não informado" }],
        };
      }
    } else {
      data = {
        text: [formattedLabel, { text: formatedValue }],
        margin: [0, 0, 0, 5],
      };
    }

    const lastDataInRecord = records[records.length - 1];

    if (type === "label") {
      data.fontSize = 12;
      data.margin = [0, 0, 0, 10];
      records.push(data);
    } else if ("columns" in lastDataInRecord && !lastDataInRecord.columns[1]) {
      if (Array.isArray(data)) {
        data[0].text += ": ";
      } else {
        data.text[0].text += ": ";
      }

      records[records.length - 1].columns.push(data);
    } else {
      if (Array.isArray(data)) {
        data[0].text += ": ";
      } else {
        data.text[0].text += ": ";
      }

      records.push({
        columnGap: 5,
        columns: [data],
      });
    }
  });

  let content = [];

  if (additionalData) {
    content = [
      title(data.name),
      additionalInformations,
      documentInformations,
      records,
    ];
  } else {
    content = [title(data.name), documentInformations, records];
  }

  let pageMargins = [40, 105, 40, 120];

  let pageFooter;

  if (signature) {
    pageMargins[3] = 300;

    pageFooter = footer(
      data?.created_at,
      true,
      data?.employee,
      data?.company,
      pdfName,
      [0, 200, 0, 0],
      signatureValidationPath
    );
  } else {
    pageFooter = footer(data?.created_at, false, data?.employee, data?.company);
  }

  const definitions = {
    info: { title: pdfName },
    pageMargins,
    defaultStyle: {
      fontSize: 10,
    },
    ...header(),
    footer: pageFooter,
    content,
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default formMakerPDF;
