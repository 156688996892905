import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";

const Steps = ({
  steps,
  orientation = "vertical",
  connector,
  sx,
  ...props
}) => {
  const theme = useTheme();
  const [active, setActive] = React.useState(0);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`& .${stepConnectorClasses.line}`]: {
      marginLeft: "38px",
      fill: "red",
      marginTop: "-12px",
      marginBottom: "-12px",
      borderColor: theme.palette.primary.font,
      borderWidth: "2px",
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    zIndex: 1,
    color: "#FFF",
    width: 100,
    height: 100,
    display: "flex",
    borderRadius: "100%",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fill: "#FFF",
    fontSize: 44,
    ...(ownerState.active
      ? {
          backgroundColor: theme.palette.primary.light,
          border: "4px solid #FFF",
          boxShadow: `0 0 0 1.5px ${theme.palette.primary.light}`,
          transform: "scale(1)",
          "&:hover": {
            animation: "scaleBorder 0s forwards",
          },
          "@keyframes scaleBorder": {
            "0%": {
              borderWidth: "initial",
            },
            "100%": {
              borderWidth: "1.8px",
            },
          },
        }
      : {
          backgroundColor: theme.palette.primary.medium,
          transform: "scale(0.95)",
          "&:hover": {
            animation: "scale 0.3s forwards",
          },
          "@keyframes scale": {
            "0%": {
              transform: "initial",
            },
            "100%": {
              transform: "scale(1)",
            },
          },
        }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = steps.reduce((icons, { icon }, key) => {
      icons[key + 1] = icon;
      return icons;
    }, {});

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection={orientation === "vertical" ? "row" : "column"}
      alignItems="flex-start"
      justifyContent="space-between"
      gap={5}
      sx={sx}
    >
      <Stepper
        activeStep={active}
        nonLinear
        orientation={orientation}
        connector={connector || <ColorlibConnector />}
        sx={{
          marginTop: 6,
        }}
        {...props}
      >
        {steps.map((step, key) => (
          <Step key={`${step.describe}-${key}`} onClick={() => setActive(key)}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              sx={{
                ".MuiStepLabel-label": {
                  color: theme.palette.primary.font,
                  fontSize: 14,
                },
                "& .Mui-active": {
                  color: theme.palette.primary.light,
                },
              }}
            >
              {step?.describe}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box flex="1">{steps[active]?.element}</Box>
    </Box>
  );
};

export default Steps;
