import { useTheme } from "@emotion/react";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import PatientServicesDialog from "../PatientServicesDialog";
import { AppContext } from "../../contexts/AppContext";
import { ptBR } from "date-fns/locale";
import { format, parseISO } from "date-fns";
import CopyToClipboardField from "../CardPatient/CopyToClipboardField";
import convertDateTobirthday from "../../utils/convertDateToBirthday";
import RegexOf from "../../config/regex";
import { formatWithMask } from "../../config/mask";
import { UploadFile } from "@mui/icons-material";
import { DialogMedium } from "../../helper";
import { AttachedDocuments } from "../../pages";
import usePermissions from "../../config/usePermissions";
import AllergyButton from "../../pages/Patient/AllergyHistory/AllergyButton";

function SoapPatientCard({ attendance, allergies }) {
  const [openPatientServices, setOpenPatientServices] = useState({
    isOpen: false,
  });
  const [openAttachedDocuments, setOpenAttachedDocuments] = useState(false);
  const theme = useTheme();
  const { patientData } = useContext(AppContext);
  const attachedDocumentPermissions = usePermissions("/attached-documents");
  const BoxContainer = styled(Grid)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
  }));

  const BoxItem = styled(Grid)({
    borderRight: `1px solid ${theme.palette.primary.main}`,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    padding: "10px",
    "&:last-child": {
      position: "relative",
      borderRight: "none",
    },
  });

  const BoxAvatar = styled(Box)({
    display: "flex",
    alignItems: "center",
    position: "relative",
    gap: "20px",
  });

  return (
    <>
      <PatientServicesDialog
        title={"Atendimento Inicial"}
        handleClose={() => setOpenPatientServices({ isOpen: false })}
        open={openPatientServices}
      />
      <DialogMedium
        open={openAttachedDocuments}
        title={"Anexo ao prontuário"}
        maxWidth="lg"
        fullWidth
        handleClose={() => setOpenAttachedDocuments(false)}
      >
        <AttachedDocuments
          patient_id={patientData?.id}
          attendance_id={attendance?.id}
        />
      </DialogMedium>
      <BoxContainer
        container
        onDoubleClick={() => {
          setOpenPatientServices({ isOpen: true, patient: attendance });
        }}
        sx={{
          backgroundColor: theme.palette.secondary.light,
          transition: ".3s",
        }}
      >
        <BoxItem width={"100%"}>
          <BoxAvatar width={"100%"}>
            <Stack xs={12} sx={{ width: "100%" }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography
                  component="span"
                  fontWeight="bold"
                  fontSize={15}
                  color="secondary.main"
                >
                  {patientData?.social_prioritize
                    ? patientData.social_name
                    : patientData?.name || "Não identificado"}
                </Typography>
                <Box display="flex" alignItems="center" gap="8px">
                  {!!attachedDocumentPermissions.view && (
                    <IconButton
                      color="primary"
                      onClick={() => setOpenAttachedDocuments(true)}
                    >
                      <UploadFile />
                    </IconButton>
                  )}
                  <AllergyButton
                    attendance={attendance}
                    allergies={allergies}
                  />
                </Box>
              </Box>
              {patientData?.pending_medication && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  mb={"10px"}
                  mt={"10px"}
                >
                  <Typography
                    fontSize={13}
                    fontWeight={"bold"}
                    color="secondary.main"
                  >
                    Paciente Sobre Medicamento(s) Assistido(s):
                    <Typography component={"span"}>
                      {" "}
                      {patientData.medication_assisted_date
                        ? `Última Administração ${format(
                            parseISO(patientData.medication_assisted_date),
                            "dd/MM/yyyy 'às' HH:mm"
                          )}`
                        : "Nenhuma medicação foi administrada ainda."}
                    </Typography>
                  </Typography>
                </Box>
              )}

              <Typography fontSize={12} color="secondary.main" key={1}>
                Cód. Paciente :{" "}
                <Typography component="span" fontWeight="550" fontSize={12}>
                  {patientData?.number || "Não informado"}
                </Typography>
              </Typography>
              <Typography key={2} fontSize={12} color="secondary.main">
                Gênero : {patientData?.gender || "Não informado"}
              </Typography>
              <Typography key={3} fontSize={12} color="secondary.main">
                Idade :{" "}
                {!patientData?.birth_date
                  ? "Não informado"
                  : convertDateTobirthday(patientData.birth_date, [
                      "years",
                      "months",
                      "days",
                    ])}
              </Typography>
              <Typography fontSize={12} key={4} color="secondary.main">
                Data de nascimento:{" "}
                {!patientData?.birth_date
                  ? "Não informado"
                  : format(parseISO(patientData?.birth_date), "dd/MM/yyyy", {
                      locale: ptBR,
                    })}
              </Typography>
              <CopyToClipboardField
                textColor="primary"
                label="Mãe"
                text={patientData.mother_name}
              />
              <CopyToClipboardField
                textColor="primary"
                label="CPF"
                text={patientData.physic_national}
              />
              <CopyToClipboardField
                textColor="primary"
                label="CNS"
                text={patientData.sus_card}
              />
              <Typography fontSize={12} color="secondary.main">
                Número de contato: :{" "}
                {formatWithMask({
                  mask: RegexOf.phone,
                  text: patientData?.contact?.cell_phone,
                }).masked || "Não informado"}
              </Typography>
            </Stack>
          </BoxAvatar>
        </BoxItem>
      </BoxContainer>
    </>
  );
}

export default React.memo(SoapPatientCard);
