import { useContext, useEffect, useCallback } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import {
  ConfirmContext,
  HIDE_CONFIRM,
  SHOW_CONFIRM,
} from "../contexts/ConfirmContext";

let resolveCallback;
export function useConfirm(handleCancel = () => null) {
  const [confirmState, dispatch] = useContext(ConfirmContext);
  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
    if (confirmState.confirmAction instanceof Function) {
      confirmState.confirmAction();
    }
  };

  const onCancel = () => {
    handleCancel();
    closeConfirm();
    resolveCallback(false);
  };
  const confirm = (text, confirmAction = () => null) => {
    dispatch({
      type: SHOW_CONFIRM,
      payload: {
        text,
      },
      confirmAction,
    });
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  const closeConfirm = () => {
    dispatch({
      type: HIDE_CONFIRM,
    });
  };

  return { confirm, onConfirm, onCancel, confirmState };
}

function useBlocker(blocker, when = true) {
  const { navigator } = useContext(NavigationContext);
  const { confirm } = useConfirm();

  useEffect(() => {
    if (!when) return;
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        confirm,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message, when = true) {
  const blocker = useCallback(
    (tx) => {
      // eslint-disable-next-line no-alert
      tx.confirm(message, tx.retry);
    },
    [message]
  );

  useBlocker(blocker, when);
}
