import { Box, Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import Accordion from "../../components/Accordion";
import { ReactComponent as StMarcelinaLogo } from "../../assets/svg/stMarcelina.svg";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import useTPortal from "../../service/useTPortal";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Loading } from "../../helper";
import DocumentNotFound from "./DocumentNotFound";

export default function TPortal() {
  const theme = useTheme();
  const {documentId,documentType} = useParams();
  const { getDocumentDetailsById } = useTPortal();
  const documentTypeKey = {
    special: 'signed_special_medications',
    medications: 'signed_medications'
  }
  const validPath = new RegExp(/^\/tportal\/.*\/[a-z0-9-]+$/)
  const location = useLocation()
  const {
    data: documentData,
    error,
    isLoading,
  } = useQuery(
    "getDocumentByIdAndType",
    () => getDocumentDetailsById(documentId,{type:documentType}),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: validPath.test(location.pathname)
    }
  );

  function formatCPF(cpf) {
    return String(cpf).replaceAll(/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/g,"***.$2.$3-**");
}

  return (
    <Box
      width={"100%"}
      minHeight={"100%"}
      padding={"1% 2%"}
      rowGap={"20px"}
      display={"flex"}
      flexDirection={"column"}
    >
      {isLoading ? (
        <Loading
          maxWidth={"100%"}
          backgroundColor={"rgba(255,255,255,0.7)"}
          message="Verificando dados do documento."
        />
      ) : error ? (
        <DocumentNotFound />
      ) : (
        <>
          <StMarcelinaLogo />
          <Paper
            sx={{
              width: "100%",
              backgroundColor: "rgba(255,255,255,1)",
              boxShadow: theme.shadows[24],
              borderRadius: "20px",
              padding: "20px",
            }}
          >
            <Grid item container xs={12} rowGap={"29px"}>
              <Grid item xs={12} width={"100%"}>
                <Typography
                  sx={{
                    background: theme.palette.primary.main,
                    width: "fit-content",
                    padding: "4px 12px",
                    borderRadius: "10px",
                    color: "#fff",
                  }}
                >
                  As receitas assinadas digitalmente estão em conformidade com o
                  valor do ICP-Brasil.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color={"primary"}
                  fontSize={"25px"}
                  fontWeight={500}
                  lineHeight={"18px"}
                >
                  Prontuário Compartilhado
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                bgcolor={"rgba(154, 192, 216, 0.1)"}
                minHeight={"89px"}
                display={"flex"}
                alignItems={"center"}
                paddingLeft={"13px"}
              >
                <Typography
                  variant="span"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  fontSize={"18px"}
                  sx={{ marginTop: "20px", flex: 2 }}
                >
                  Assinado por
                  <Typography>
                    {documentData?.plan?.soap.created_by.name}
                  </Typography>
                </Typography>
                <Typography
                  variant="span"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  fontSize={"18px"}
                  sx={{ marginTop: "20px", flex: 1 }}
                >
                  CRM
                  <Typography>
                    {
                      documentData?.plan?.soap.created_by
                        .council_registration_number
                    }
                  </Typography>
                </Typography>
                <Typography
                  variant="span"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  fontSize={"18px"}
                  sx={{ marginTop: "20px", flex: 3 }}
                >
                  Assinado em
                  <Typography>
                    {!!documentData?.createdAt &&
                      format(new Date(documentData?.createdAt), "dd/MM/yyyy", {
                        locale: ptBR,
                      })}
                  </Typography>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                bgcolor={"rgba(154, 192, 216, 0.1)"}
                minHeight={"89px"}
                display={"flex"}
                alignItems={"center"}
                paddingLeft={"13px"}
              >
                <Typography
                  variant="span"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  fontSize={"18px"}
                  sx={{ flex: 1 }}
                >
                  Nome do paciente
                  <Typography>
                    {documentData?.plan?.soap.attendance.patient
                      .social_prioritize
                      ? documentData?.plan.soap.attendance.patient.social_name
                      : documentData?.plan.soap.attendance.patient.name}
                  </Typography>
                </Typography>
                <Typography
                  variant="span"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  fontSize={"18px"}
                  sx={{ flex: 2 }}
                >
                  CPF
                  <Typography>
                    {
                      formatCPF(documentData?.plan?.soap.attendance.patient
                        .physic_national)
                    }
                  </Typography>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                bgcolor={"rgba(154, 192, 216, 0.1)"}
                minHeight={"89px"}
                display={"flex"}
                alignItems={"center"}
                paddingLeft={"13px"}
              >
                <Typography
                  variant="span"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  fontSize={"18px"}
                  sx={{ flex: 1 }}
                >
                  Nome do documento
                  <Typography>{documentData?.[documentTypeKey[documentType]]?.id}</Typography>
                </Typography>
                <Typography
                  variant="span"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  fontSize={"18px"}
                  sx={{ flex: 2 }}
                >
                  Criado
                  <Typography>
                    {!!documentData?.createdAt &&
                      format(
                        new Date(documentData?.createdAt),
                        "dd/MM/yyyy HH:mm",
                        { locale: ptBR }
                      )}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Accordion
            configs={[
              {
                title: (
                  <Typography
                    sx={{
                      fontSize: "25px",
                      fontWeight: 500,
                      color: theme.palette.primary.main,
                    }}
                  >
                    Detalhes
                  </Typography>
                ),
                bgColor: "#fff",
                expandColor: theme.palette.primary.main,
                body: (
                  <Box
                    fontSize={"20px"}
                    lineHeight={"30px"}
                    fontWeight={300}
                    sx={{
                      "*": {
                        color: theme.palette.primary.light,
                        fontWeight: 300,
                      },
                    }}
                  >
                    <ul>
                      <li>
                        O certificado utilizado está em conformidade com a
                        ICP-Brasil.
                      </li>
                      <li>
                        O certificado utilizado está assinado corretamente pelo
                        Emissor.
                      </li>
                      <li>
                        O certificado utilizado é um certificado de assinatura.
                      </li>
                      <li>Emissor: SOLUTI</li>
                    </ul>
                  </Box>
                ),
              },
            ]}
          />
          <Box display={"flex"} columnGap={"24px"} alignItems={"center"}>
            <Typography
              fontWeight={500}
              fontSize={"21px"}
              flex={1}
              color={"primary"}
            >
              SITE DO GOVERNO PARA VALIDAÇÃO{" "}
              <Button
                href="https://validar.iti.gov.br"
                target="_blank"
                sx={{ fontSize: "14px", fontWeight: 400 }}
              >
                https://validar.iti.gov.br{" "}
              </Button>
            </Typography>
            <Button
              variant="contained"
              href={documentData?.[documentTypeKey[documentType]]?.result}
              target="_blank"
              download={documentData?.[documentTypeKey[documentType]]?.original_file_name}
            >
              Baixar Documento Assinado
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
