import React from "react";
import {
  Stack,
  IconButton,
  Grid,
  Typography,
  Box,
  TablePagination,
  Button,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { DialogMedium, ConfirmDialog } from "../../../helper";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useMutation, useQuery } from "react-query";
import { usePagination, useTotemPriorities } from "../../../service";
import { format, parseISO } from "date-fns";
import Yup from "../../../config/yup";
import { DeleteRounded } from "@mui/icons-material";
import TableFilter from "../../../components/Table/TableFilter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, SwitchField } from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

export default function SettingsPriority() {
  const theme = useTheme();
  const notify = useNotifier();
  const [edit, setEdit] = React.useState(false);
  const [priority, setPriority] = React.useState(null);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [limit, setLimit] = React.useState(5);

  const { getPriorities, updatePriority, deletePriority } =
    useTotemPriorities();

  const updatePriorityMutation = useMutation(updatePriority);
  const deletePriorityMutation = useMutation(deletePriority);

  const priorityQuery = useQuery(["tokensPriorities"], () => getPriorities(), {
    retry: false,
    initialData: [],
  });

  const { paginationInfo, handlePageChange } = usePagination(
    priorityQuery.data,
    limit
  );

  const editInitialValues = {
    prefix: priority?.prefix,
    describe: priority?.describe,
    active: priority?.active === "Sim" ? true : false,
  };

  const editValidations = Yup.object().shape({
    prefix: Yup.string().required("É requerido"),
    describe: Yup.string().required("É requerido"),
  });

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: editInitialValues,
    resolver: yupResolver(editValidations),
  });

  const onSubmit = handleSubmit((values) => {
    updatePriorityMutation.mutate(
      { id: priority?.id, data: values },
      {
        onSuccess: (success) => {
          notify(success.message, "success");
          priorityQuery.refetch();
          setEdit(false);
          reset();
          setPriority(null);
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  });

  const columns = [
    {
      field: "prefix",
      name: "Prefixo",
      width: 250,
      type: "string",
    },
    {
      field: "describe",
      name: "Descrição",
      type: "string",
    },
    {
      field: "active",
      name: "Ativo",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
    },
    {
      field: "createdAt",
      name: "Criado em",
      type: "string",
      use: (value) => format(parseISO(value), "dd/MM/yyyy"),
    },
    {
      field: "updatedAt",
      name: "Atualizado em",
      type: "string",
      use: (value) => format(parseISO(value), "dd/MM/yyyy"),
    },
    {
      field: "id",
      name: "Ações",
      type: "string",
      use: (_, row) => {
        return row.company_id ? (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <IconButton
              color="primary"
              onClick={() => {
                setEdit(true);
                setValue("describe", row.describe);
                setValue("active", row.active);
                setValue("prefix", row.prefix);
                setPriority(row);
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => {
                setConfirmDialog(true);
                setPriority(row);
              }}
            >
              <DeleteRounded />
            </IconButton>
          </Stack>
        ) : (
          <Typography>Não editável</Typography>
        );
      },
      alignInRow: "center",
      alignInHead: "center",
    },
  ];

  return (
    <>
      <ConfirmDialog
        message={`Você realmente deseja excluir esta prioridade ? `}
        hideBackDrop={false}
        open={confirmDialog}
        maxWidth="sm"
        fullWidth={true}
        handleClose={() => {
          setConfirmDialog(false);
          setPriority({});
        }}
        actions
        handleConfirm={() =>
          deletePriorityMutation.mutate(priority.id, {
            onSuccess: (success) => {
              notify(success.message, "success");
              priorityQuery.refetch();
              setConfirmDialog(false);
            },
            onError: (error) => {
              notify(error.message, "error");
            },
          })
        }
        handleCancel={() => {
          setConfirmDialog(false);
          setPriority({});
        }}
      />
      <DialogMedium
        open={edit}
        fullWidth
        maxWidth="sm"
        title="Alteração de estação"
        handleClose={() => {
          setEdit(false);
          setPriority({});
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              control={control}
              name="describe"
              label="Descrição"
              required
              placeholder={"Ex: Idosos"}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              control={control}
              name="prefix"
              label="Prefixo"
              required
              placeholder={"Ex : AA, BB, CC"}
            />
          </Grid>
          <Grid item xs={10}>
            <SwitchField
              control={control}
              name="active"
              label="Alterar Status"
            />
          </Grid>

          <Grid item xs={1}>
            <Button
              variant="contained"
              loading={updatePriorityMutation.isLoading}
              onClick={onSubmit}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Tabela de Prioridades
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items}
          columns={columns}
          loading={priorityQuery.isLoading}
          actionUniqueIdentifier={"id"}
          shouldDisableFields={["id"]}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>
    </>
  );
}
