import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Formik, Form } from "formik";
import { Grid } from "@mui/material";
import Yup from "../../../config/yup";
import { FormTextField, SubmitButton } from "../../../components/Form";
import { Tabs } from "../../../components";
import ExamsForm from "./components/ExamsForm";
import MedicationForm from "./components/MedicationForm";
import ServicesForm from "./components/ServicesForm";
import useCarePlan from "../../../service/useCarePlan";
import { dateTimeFormater } from "../../../utils";
import useNotifier from "../../../hooks/useNotifier";

function CarePlanForm({ type = "register", data, handleClose }) {
  const [medications, setMedications] = React.useState([]);
  const [swapMedications, setSwapMedications] = React.useState([]);
  const [medicationsToDelete, setMedicationsToDelete] = React.useState([]);
  const [exams, setExams] = React.useState([]);
  const [swapExams, setSwapExams] = React.useState([]);
  const [examsToDelete, setExamsToDelete] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [swapServices, setSwapServices] = React.useState([]);
  const [servicesToDelete, setServicesToDelete] = React.useState([]);
  const queryClient = useQueryClient();
  const { postCarePlan, putCarePlan, deleteCarePlanItems } = React.useMemo(
    useCarePlan,
    []
  );
  const notify = useNotifier();

  // Requests
  const carePlanMutation = useMutation(postCarePlan);
  const putCarePlanMutation = useMutation(putCarePlan);
  const deleteItemsMutation = useMutation(deleteCarePlanItems);

  // Handle Functions
  function formatCarePlans(values) {
    const formatedServices = services.map((item) => ({
      service_id: item.service.id,
      frequency: item.frequency,
    }));

    let carePlan;

    if (type === "edit") {
      const filteredMedications = medications.filter(({ id }) => {
        return !swapMedications.some((swap) => swap.id === id);
      });

      const filteredExams = exams.filter(({ id }) => {
        return !swapExams.some((swap) => swap.id === id);
      });

      const filteredServices = formatedServices.filter(({ service_id }) => {
        return !swapServices.some((swap) => swap.id === service_id);
      });

      carePlan = {
        ...values,
        medications: filteredMedications,
        exams: filteredExams,
        services: filteredServices,
      };
    } else {
      carePlan = {
        ...values,
        medications: medications,
        exams: exams,
        services: formatedServices,
      };
    }

    return carePlan;
  }

  function resetEdition(resetForm) {
    resetForm();
    setMedications([]);
    setSwapMedications([]);
    setMedicationsToDelete([]);
    setExams([]);
    setSwapExams([]);
    setExamsToDelete([]);
    setServices([]);
    setSwapServices([]);
    setServicesToDelete([]);
    queryClient.invalidateQueries("carePlans");
  }

  async function handleSubmit(values, { resetForm }) {
    if (type === "edit") {
      const carePlan = formatCarePlans(values);
      const itemsToDelete = {
        medications: medicationsToDelete,
        exams: examsToDelete,
        services: servicesToDelete,
      };

      if (
        medicationsToDelete.length > 0 ||
        examsToDelete.length > 0 ||
        servicesToDelete.length > 0
      ) {
        deleteItemsMutation.mutate(
          { id: data.id, data: itemsToDelete },
          {
            onSuccess: (success) => {
              notify(success.message, "success");
              resetForm();
              queryClient.invalidateQueries("carePlans");
            },
            onError: (error) => {
              notify(error.message, "error");
            },
          }
        );
      }

      putCarePlanMutation.mutate(
        { id: data.id, data: carePlan },
        {
          onSuccess: (success) => {
            notify(success.message, "success");
            resetForm();
            queryClient.invalidateQueries("carePlans");
            handleClose();
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    } else {
      const carePlan = formatCarePlans(values);

      carePlanMutation.mutate(carePlan, {
        onSuccess: (success) => {
          notify(success.message, "success");
          resetEdition(resetForm);
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      });
    }
  }

  React.useEffect(() => {
    if (!!data && type === "edit") {
      const formatedMedications = data.medications.map((medication) => ({
        name: medication.name,
        quantity: medication.quantity,
        unit: medication.unit,
        interval: medication.interval,
        notes: medication.notes,
        id: medication.id,
      }));

      const formatedExams = data.exams.map((exam) => ({
        type: exam.type,
        describe: exam.describe,
        frequency: dateTimeFormater(exam.frequency),
        notes: exam.notes,
        id: exam.id,
      }));

      const formatedServices = data.services.map((service) => ({
        service,
        frequency: dateTimeFormater(service.frequency),
        id: service.id,
      }));

      setSwapMedications(formatedMedications);
      setMedications(formatedMedications);
      setSwapExams(formatedExams);
      setExams(formatedExams);
      setSwapServices(formatedServices);
      setServices(formatedServices);
    }
  }, [data, type]);

  //field configs
  const initialValues = {
    name: !!data ? data.name : "",
    describe: !!data ? data.describe : "",
  };

  const validations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
    describe: Yup.string().required("É requerido"),
  });

  const tabs = [
    {
      label: "Medicações",
      content: (
        <MedicationForm
          rows={medications}
          setRows={setMedications}
          setRowsToDelete={setMedicationsToDelete}
        />
      ),
    },
    {
      label: "Exames",
      content: (
        <ExamsForm
          rows={exams}
          setRows={setExams}
          setRowsToDelete={setExamsToDelete}
        />
      ),
    },
    {
      label: "Serviços",
      content: (
        <ServicesForm
          rows={services}
          setRows={setServices}
          setRowsToDelete={setServicesToDelete}
        />
      ),
    },
  ];

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
      >
        {() => (
          <>
            <Form>
              <Grid container spacing={3} marginTop="0.5rem" marginBottom={2}>
                <Grid item xs={4} md={3}>
                  <FormTextField
                    name="name"
                    label="Nome"
                    type="text"
                    required
                  />
                </Grid>
                <Grid item xs={8} md={5}>
                  <FormTextField
                    name="describe"
                    label="Descrição"
                    type="text"
                  />
                </Grid>
              </Grid>
            </Form>
            <Tabs tabs={tabs} />
            <SubmitButton sx={{ marginTop: 8 }} loading={false}>
              Salvar
            </SubmitButton>
          </>
        )}
      </Formik>
    </>
  );
}

export default CarePlanForm;
