import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Formik, Form } from "formik";
import { Grid, Typography, Box } from "@mui/material";
import Yup from "../../../config/yup";
import { theme } from "../../../config/theme";
import {
  FormTextField,
  FormRadio,
  SubmitButton,
  FormMultipleSelectField,
} from "../../../components/Form";
import { Tabs } from "../../../components";
import ProgramCarePlanForm from "./components/ProgramCarePlanForm";
import ProgramQuestionnairesForm from "./components/ProgramQuestionnairesForm";
import ProgramHealthTipsForm from "./components/ProgramHealthTipsForm";
import ProgramEntryCriteriaForm from "./components/ProgramEntryCriteriaForm";
import useProgram from "../../../service/useProgram";
import useNotifier from "../../../hooks/useNotifier";

function ProgramForm({ type = "register", data }) {
  const [questionnaires, setQuestionnaires] = React.useState([]);
  const [carePlans, setCarePlans] = React.useState([]);
  const [genderArray, setGenderArray] = React.useState([]);
  const [healthTips, setHealthTips] = React.useState([]);
  const [entryCriteria, setEntryCriteria] = React.useState([]);
  const [exitCriteria, setExitCriteria] = React.useState([]);
  const queryClient = useQueryClient();
  const { postPrograms, putPrograms } = React.useMemo(useProgram, []);
  const notify = useNotifier();

  // Requests
  const postProgramMutation = useMutation(postPrograms);
  const putProgramMutation = useMutation(putPrograms);

  // Handle Functions
  async function handleSubmit(values, { resetForm }) {
    const documentTypeIds = questionnaires.map(({ id }) => id);
    const carePlansIds = carePlans.map(({ id }) => id);
    const healthTipsIds = healthTips.map(({ id }) => id);
    const genders = values.gender.map(({ describe }) => describe);
    const program = {
      ...values,
      gender: genders,
      active: values.active,
      documents: documentTypeIds,
      care_plans: carePlansIds,
      health_tips: healthTipsIds,
    };

    if (type === "edit") {
      putProgramMutation.mutate(
        { programId: data.id, data: program },
        {
          onSuccess: (success) => {
            setQuestionnaires([]);
            setCarePlans([]);
            setHealthTips([]);
            setEntryCriteria([]);
            setExitCriteria([]);

            resetForm();
            notify(success.message, "success");
            queryClient.invalidateQueries("programs");
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    } else {
      postProgramMutation.mutate(program, {
        onSuccess: (success) => {
          setQuestionnaires([]);
          setCarePlans([]);
          setHealthTips([]);
          setEntryCriteria([]);
          setExitCriteria([]);

          resetForm();
          notify(success.message, "success");
          queryClient.invalidateQueries("programs");
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      });
    }
  }

  //field configs
  React.useEffect(() => {
    if (type === "edit") {
      const formatedCarePlans = data?.carePlans?.map(
        ({ number, name, id }) => ({
          number,
          name,
          id,
        })
      );
      setCarePlans(formatedCarePlans);
      const formatedGenders = data?.gender?.map((describe, index) => ({
        id: index,
        describe: describe,
      }));
      setGenderArray(formatedGenders);
    }
  }, [type]);

  React.useEffect(() => {
    if (type === "edit") {
      const formatedHealthTips = data?.healthTips?.map(
        ({ number, name, id }) => ({
          number,
          name,
          id,
        })
      );
      setHealthTips(formatedHealthTips);
      const formatedGenders = data?.gender?.map((describe, index) => ({
        id: index,
        describe: describe,
      }));
      setGenderArray(formatedGenders);
    }
  }, [type]);

  React.useEffect(() => {
    if (type === "edit") {
      const formatedQuestionnaires = data?.documents?.map(({ name, id }) => ({
        name,
        id,
      }));
      setQuestionnaires(formatedQuestionnaires);
      const formatedGenders = data?.gender?.map((describe, index) => ({
        id: index,
        describe: describe,
      }));
      setGenderArray(formatedGenders);
    }
  }, [type]);

  const initialValues = {
    name: data?.name || "",
    describe: data?.describe || "",
    gender: genderArray,
    age_group_initial: data?.age_group_initial || "",
    age_group_final: data?.age_group_final || "",
    active: data?.active,
  };
  const validations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
    describe: Yup.string(),
    gender: Yup.array().of(Yup.object()).min(1, "Selecione ao menos um Gênero"),
    age_group_initial: Yup.number().required("É requerido"),
    age_group_final: Yup.number().required("É requerido"),
    active: Yup.boolean().required("É requerido"),
  });

  const radioConfig = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  const genderOptions = [
    { id: 0, describe: "Masculino" },
    { id: 1, describe: "Feminino" },
    { id: 2, describe: "Indefinido" },
  ];

  const tabs = [
    {
      label: "Questionário",
      content: (
        <ProgramQuestionnairesForm
          rows={questionnaires}
          setRows={setQuestionnaires}
        />
      ),
    },
    {
      label: "Plano de Cuidado",
      content: <ProgramCarePlanForm rows={carePlans} setRows={setCarePlans} />,
    },
    {
      label: "Dicas de Saúde",
      content: (
        <ProgramHealthTipsForm rows={healthTips} setRows={setHealthTips} />
      ),
    },
    {
      label: "Critérios",
      content: (
        <ProgramEntryCriteriaForm
          rows={entryCriteria}
          setRows={setEntryCriteria}
        />
      ),
    },
  ];

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
        enableReinitialize
      >
        {({ values }) => (
          <>
            <Form>
              <Grid container spacing={3} marginTop="0.5rem" marginBottom={1}>
                <Grid xs={12} spacing={3} container item>
                  <Grid item xs={4} md={3}>
                    <FormTextField
                      name="name"
                      label="Nome"
                      type="text"
                      required
                    />
                  </Grid>
                  <Grid item xs={8} md={6}>
                    <FormTextField
                      name="describe"
                      label="Adicionar descrição"
                      type="text"
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} spacing={3} container item>
                  <Grid xs={12} item>
                    <Typography
                      sx={{
                        color: theme.palette.primary.light,
                        fontSize: 17,
                        fontWeight: 500,
                      }}
                    >
                      Configurações Gerais
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormMultipleSelectField
                      id="gender"
                      name="gender"
                      label="Gênero"
                      multiple={true}
                      keys={["describe", "describe"]}
                      options={genderOptions}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormTextField
                      type="number"
                      name="age_group_initial"
                      label="Faixa etária inicial"
                      required
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormTextField
                      type="number"
                      name="age_group_final"
                      label="Faixa etária final"
                      required
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormRadio
                      id="active"
                      name="active"
                      legend={"Programa encontra-se ativo?"}
                      radios={radioConfig}
                      keys={["label", "value"]}
                      row
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
            <Box
              sx={{
                marginTop: "20px",
              }}
            >
              <Tabs tabs={tabs} />
            </Box>
            <SubmitButton sx={{ marginTop: 8 }} loading={false}>
              Salvar
            </SubmitButton>
          </>
        )}
      </Formik>
    </>
  );
}

export default ProgramForm;
