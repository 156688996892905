import React from "react";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import { BoxContainer } from "../../../components";
import { Dialog } from "../../../helper";
import PatientHealthFeedback from "./PatientHealthFeedback";
import { Box } from "@mui/system";
import { TablePagination } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import useProgram from "../../../service/useProgram";
import { format, parseISO } from "date-fns";
import useNotifier from "../../../hooks/useNotifier";

function PatientHealth() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [visualizationPatientHealth, setVisualizationPatientHealth] =
    React.useState(false);
  const params = useParams();
  const idPatient = params.id;
  const [programsAvailableForPatient, setProgramsAvailableForPatient] =
    React.useState([]);
  const [programs, setPrograms] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const { getProgramsPatientHealth } = useProgram();
  const notify = useNotifier();
  const programsQuery = useQuery(
    ["healthPrograms", [page, limit]],
    () => getProgramsPatientHealth(page, limit, idPatient),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (response) => {
        setCount(response.totalItems);
        const programsPatientHealth = response.items.map((programHealth) => {
          return {
            patient_id: programHealth.patient.id,
            patient_number: programHealth.patient.number,
            patient_name: programHealth.patient?.social_prioritize
              ? programHealth.patient.social_name
              : programHealth.patient.name,
            program_number: programHealth.program.number,
            program_name: programHealth.program.name,
            validity_date: format(
              parseISO(programHealth.inserted_at),
              "dd/MM/yyyy"
            ),
            vailidity_removed_date: !!programHealth.removed_at
              ? format(parseISO(programHealth.removed_at), "dd/MM/yyyy")
              : "",
          };
        });
        setRows(programsPatientHealth);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  React.useEffect(() => {
    if (
      programsQuery.isSuccess &&
      programsQuery.data &&
      programsQuery.data.items
    ) {
      if (count === 0) {
        setCount(programsQuery.data.totalItems);
      }
      setPrograms(
        programsQuery.data.items.map((index) => {
          return index;
        })
      );
      setRows(
        programsQuery.data.items.map((index) => {
          return {
            patient_number: index?.patient?.number,
            patient_name: index?.patient?.name,
            program_number: index?.program?.number,
            program_name: index?.program?.name,
            validity_date:
              index?.inserted_at &&
              format(parseISO(index?.inserted_at), "dd/MM/yyyy"),
            vailidity_removed_date: !!index?.removed_at
              ? format(parseISO(index?.removed_at), "dd/MM/yyyy")
              : "",
          };
        })
      );
    }
  }, [programsQuery.dataUpdatedAt]);

  const tableColumns = [
    { name: "Código" },
    { name: "Paciente" },
    { name: "Cód. Programa" },
    { name: "Programa Saúde" },
    { name: "Início Vigência" },
    { name: "Fim Vigência" },
  ];

  function handleVisualization() {
    setVisualizationPatientHealth(true);
  }
  function handleCloseDialog() {
    setVisualizationPatientHealth(false);
  }

  return (
    <>
      <BoxContainer title={"Paciente Programa Saúde"}>
        <Dialog
          fullWidth
          open={visualizationPatientHealth}
          title={"Log Paciente Programa Saúde"}
          handleClose={handleCloseDialog}
        >
          <PatientHealthFeedback data={programsAvailableForPatient} />
        </Dialog>
        <PaginatedTable
          data={rows}
          columns={tableColumns}
          loading={programsQuery.isRefetching}
          actions
          actionsTypes={["view"]}
          shouldDisableFields={["id"]}
          actionHandleView={(i) => {
            handleVisualization(i);
            setProgramsAvailableForPatient(programs[i]);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={count}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
      </BoxContainer>
    </>
  );
}

export default PatientHealth;
