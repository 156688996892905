import React from "react";
import {
  SubmitButton,
  FormSelectWithPaginate,
} from "../../../../components/Form";
import { Formik, Form } from "formik";
import Yup from "../../../../config/yup";
import PaginatedTable from "../../../../components/Table/PaginatedTable";
import { Grid, Box } from "@mui/material";
import useCarePlan from "../../../../service/useCarePlan";

const ProgramCarePlanForm = ({ rows = [], setRows }) => {
  const { getCarePlans } = React.useMemo(useCarePlan, []);

  // Handle Functions
  function handleDelete(index) {
    const filteredrows = rows.filter((_, key) => key !== index);
    setRows(filteredrows);
  }

  function addRows({ care_plan: { number, name, id } }, { resetForm }) {
    setRows((previousrows) => [...previousrows, { number, name, id }]);
    resetForm();
  }

  const initialValues = {
    care_plan: "",
  };

  const validations = Yup.object().shape({
    care_plan: Yup.object().required("É requerido").nullable(),
  });

  const collumns = [{ name: "Código" }, { name: "Plano de cuidado" }];

  return (
    <Formik
      onSubmit={addRows}
      initialValues={initialValues}
      validationSchema={validations}
    >
      {() => (
        <>
          <Form>
            <Grid xs={12} spacing={3} container item marginTop={-2}>
              <Grid xs={2} item>
                <FormSelectWithPaginate
                  service={getCarePlans}
                  fields="name"
                  label="Plano de cuidado"
                  name="care_plan"
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <SubmitButton>Adicionar</SubmitButton>
              </Grid>
            </Grid>
          </Form>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <PaginatedTable
              sx={{ marginTop: 3 }}
              columns={collumns}
              data={rows}
              actions
              actionsTypes={["delete"]}
              shouldDisableFields={["id"]}
              actionHandleDelete={handleDelete}
            />
          </Box>
        </>
      )}
    </Formik>
  );
};

export default ProgramCarePlanForm;
