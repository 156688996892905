import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

const DPATH = "/risk-stratifications";

export default function useRiskStratification() {
  async function getRiskStratificationStatus(patientID, params) {
    try {
      const result = await instance.get(`${DPATH}/${patientID}`, {
        params: urlQuery(params),
      });
      return result.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  return {
    getRiskStratificationStatus,
  };
}
