import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import ListBox from "./ListBox";
import { useDebounceCallback } from "../../hooks/";

function FormSelectFieldWithPaginate({
  name,
  service,
  customHandleChange,
  fields,
  searchBy,
  refetch = [],
  label,
  disablePortal = true,
  maxItems,
  multiple = false,
  required,
  otherwise,
  getOptionLabel,
  filterOptionsCondition,
  noOptionsText = "",
  ...otherProps
}) {
  const [page, setPage] = useState(0);
  const limit = maxItems || 10;
  const [params, setParams] = useState({ page, limit });
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const [optionsState, setOptionsState] = useState([]);
  const queryClient = useQueryClient();
  const debounceSearch = useDebounceCallback(
    (nextValue) =>
      setParams({ [searchBy || fields]: nextValue, page: 0, limit }),
    700
  );

  const optionsQuery = useQuery(
    [`Query${name}`, params],
    ({ queryKey }) => service(queryKey[1]),
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const data = response?.items || response || [];

        setOptionsState((options) => {
          return !response?.current ? data : [...options, ...data];
        });
      },
      onError: () => {
        setOptionsState([]);
      },
    }
  );

  const handleScroll = ({ target }) => {
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 2) {
      if (page < optionsQuery.data?.total && !optionsQuery.isFetching) {
        setPage(page + 1);
      }
    }
  };

  const renderRef = useRef(true);
  useEffect(() => {
    if (renderRef.current) {
      return;
    }
    if (!page) {
      setParams({ page, limit });
      return;
    }
    setParams({ ...params, page, limit });
  }, [page, limit]);

  async function handleClose() {
    setPage(0);
  }
  async function handleOpen() {
    queryClient.cancelQueries([`Query${name}`]);
    optionsQuery.refetch();
  }

  function handleSearch(value) {
    debounceSearch(value);
  }
  useEffect(() => {
    if (renderRef.current) {
      renderRef.current = false;
      return;
    }
    queryClient.cancelQueries([`Query${name}`]);
    optionsQuery.refetch();
    return () => {
      renderRef.current = false;
      queryClient.cancelQueries([`Query${name}`]);
    };
  }, [params, ...refetch, name]);

  const settings = {
    ...field,
    ...otherProps,
    size: "small",
    select: true,
    fullWidth: true,
    variant: "outlined",
    inputlabelprops: { shrink: true },
  };

  if (meta && meta.touched && meta.error) {
    settings.error = true;
    settings.helperText = meta.error;
  }

  function handleChange(_, value) {
    setFieldValue(field.name, value);
    if (customHandleChange instanceof Function) customHandleChange(value);
  }

  function filterOptions(options) {
    const filteredOptions = options?.filter((option) => {
      if (multiple) {
        return field.value?.every((value) =>
          filterOptionsCondition(value, option)
        );
      } else {
        return filterOptionsCondition(field.value, option);
      }
    });

    return filteredOptions;
  }

  return (
    <React.Fragment>
      <Autocomplete
        {...settings}
        disablePortal={disablePortal}
        options={optionsState}
        ListboxProps={{
          style: { maxHeight: "200px" },
          onScrollCapture: handleScroll,
        }}
        getOptionLabel={
          getOptionLabel
            ? getOptionLabel
            : (option) => option[fields] || option[otherwise] || ""
        }
        isOptionEqualToValue={(option, value) =>
          !!value ? option === value : false
        }
        filterOptions={
          filterOptionsCondition instanceof Function ? filterOptions : undefined
        }
        loading={optionsQuery.isFetching}
        ListboxComponent={ListBox}
        noOptionsText={optionsQuery.error?.message || noOptionsText}
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        blurOnSelect={false}
        multiple={multiple}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            error={settings?.error}
            helperText={settings?.helperText}
            InputLabelProps={{
              shrink: true,
            }}
            label={label}
            onChange={(_) => handleSearch(_.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {optionsQuery.isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </React.Fragment>
  );
}

export default React.memo(FormSelectFieldWithPaginate);
