import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/totems/tokens";
const printerPath = "/printers";
const totemPath = "/totems";
export default function useTotem() {
  async function getTokens(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getTotems(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(totemPath, urlQuery(params));
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  const postTotem = async (data) => {
    try {
      const response = await instance.post(totemPath, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const updateTotem = async ({ id, data }) => {
    try {
      const response = await instance.put(`${totemPath}/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const updateToken = async (id, data) => {
    try {
      const response = await instance.patch(`${path}/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  async function getPrinters(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(printerPath, urlQuery(params));
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  const postPrinter = async (data) => {
    try {
      const response = await instance.post(printerPath, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const updatePrinter = async ({ id, data }) => {
    try {
      const response = await instance.put(`${printerPath}/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const confirmToken = async (id) => {
    try {
      const response = await instance.patch(`${path}/${id}/confirm`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const cancelToken = async (id) => {
    try {
      const response = await instance.delete(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  return {
    getTokens,
    confirmToken,
    getPrinters,
    getTotems,
    postTotem,
    updateTotem,
    updatePrinter,
    postPrinter,
    updateToken,
    cancelToken,
  };
}
