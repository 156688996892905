import React from "react";
import { CardHeader, Tooltip, Typography } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyToClipboardField = ({ label, text, textColor, ...props }) => {
  const [copiedText, setCopiedText] = React.useState("");

  return (
    <CardHeader
      sx={{ display: "flex", margin: 0, padding: 0 }}
      title={
        <CopyToClipboard
          text={text}
          onCopy={() => {
            if (text && text !== "Não informado") {
              setCopiedText(text);
            }
          }}
        >
          <Tooltip
            title={copiedText === text ? "Copiado!!" : text ? "Copiar" : ""}
            color="primary"
            placement="top"
          >
            <Typography
              color={textColor}
              fontWeight="medium"
              component="span"
              fontSize={props?.fontSize ? props.fontSize : 12}
              display={"flex"}
              gap={0.4}
            >
              {!label ? (
                <Typography
                  color={textColor}
                  component="span"
                  fontSize={props?.fontSize ? props.fontSize : 12}
                >
                  {text || "Não informado"}
                </Typography>
              ) : (
                <>
                  {label}:{" "}
                  <Typography
                    color={textColor}
                    component="span"
                    fontSize={props?.fontSize ? props.fontSize : 12}
                  >
                    {text || "Não informado"}
                  </Typography>
                </>
              )}
            </Typography>
          </Tooltip>
        </CopyToClipboard>
      }
    ></CardHeader>
  );
};

export default CopyToClipboardField;
