import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip, Grid, Typography, useTheme, Button } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { AvatarGender, Steps } from "../../components";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import SoapContainer from "../../components/SoapContainer/SoapContainer";
import { DialogMedium } from "../../helper";
import { ReactComponent as Notebook } from "../../assets/svg/notebook.svg";
import { ReactComponent as FinishedVaccine } from "../../assets/svg/finishedVaccine.svg";
import { ReactComponent as UnFinishedVaccine } from "../../assets/svg/unfinishedVaccine.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useMutation, useQuery } from "react-query";
import ImmunizingNewForm from "../Vaccine/Form/ImmunizingNewForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAttendaces, usePatient } from "../../service";
import useNotifier from "../../hooks/useNotifier";
import { useDebounceCallback } from "../../hooks";
import convertDateTobirthday from "../../utils/convertDateToBirthday";

function VaccineBooklet() {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [immunizingNewDialog, setImmunizingNewDialog] = useState(false);
  const [immunizingNew, setImmunizingNew] = useState(false);
  const [showVisualization] = useState(true);
  const [immunizingNewForm, setImmunizingNewForm] = useState(false);
  const [period, setPeriod] = useState("RN-12");
  const { id, idattendance } = useParams();
  const location = useLocation();
  const { getPatient, getPatientImmunizationRecord } = usePatient();
  const { finishAttendance } = useAttendaces();

  const patientQuery = useQuery(["patient", id], () => getPatient(id), {
    keepPreviousData: true,
  });

  const finishAttendanceMutation = useMutation(finishAttendance);

  const ImmunizationRecordQuery = useQuery(
    ["immunization-record", period],
    () => getPatientImmunizationRecord(id, { period }),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: { vaccines: [] },
    }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const notify = useNotifier();

  const backToInital = useDebounceCallback(() => goBack(), 1000);

  function finishAttendanceVaccine() {
    finishAttendanceMutation.mutate(idattendance, {
      onSuccess: () => {
        notify("Atendimento finalizado com sucesso!", "success");
        backToInital();
      },
      onError: (err) => notify(err.message, "error"),
    });
  }

  function handleImmunizingNew() {
    setImmunizingNew(true);
    setImmunizingNewForm(false);
  }
  function handleClose() {
    setImmunizingNewDialog(false);
    setImmunizingNew(false);
    setImmunizingNewForm(false);
  }

  const years = [
    {
      label: "RN A 12 MESES",
      inicio: "RN",
      fim: 12,
    },
    {
      label: "1 A 13 ANOS",
      inicio: 1,
      fim: 13,
    },
    {
      label: "14 A 21 ANOS",
      inicio: 14,
      fim: 21,
    },
    {
      label: "22 A 59 ANOS",
      inicio: 22,
      fim: 59,
    },
    {
      label: "+60 ANOS",
      inicio: 60,
      fim: 199,
    },
    {
      label: "+Gestantes",
      inicio: 60,
      fim: 199,
    },
    {
      label: "Campanhas Epidêmicas",
      inicio: 60,
      fim: 199,
    },
  ];

  function goBack() {
    navigate(location.state?.backTo || -1, {
      state: {
        menuServiceOpen: true,
        use: idattendance,
      },
      replace: true,
    });
  }
  const steps = [
    {
      icon: <Notebook />,
      element: (
        <SoapContainer title="Caderneta de Vacinação" vaccine>
          <Grid container item xs={12}>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "right",
              }}
            >
              <AvatarGender gender={patientQuery.data?.gender} />
            </Grid>
            <Grid
              item
              xs={8}
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#337EAD",
              }}
            >
              <span>
                {" "}
                {`Nome do paciente : `}
                {patientQuery.data?.name}
              </span>{" "}
              <span>
                {`Idade : `}
                {convertDateTobirthday(patientQuery.data?.birth_date, [
                  "years",
                ])}
              </span>
              <span>
                {`CNS : `}
                {patientQuery.data?.sus_card}
              </span>{" "}
              <span>
                {`Nome da mãe : `} {patientQuery.data?.mother_name}
              </span>{" "}
            </Grid>
            <Grid item xs={3} spacing={2} gap={5}>
              <Button
                display="flex"
                variant="contained"
                color="secondary"
                alignItems="center"
                onClick={() => handleImmunizingNew(immunizingNewForm)}
              >
                <AddCircleIcon style={{ marginRight: 5 }} />
                Registro de Vacinação
              </Button>
              <Grid
                xs={8}
                item
                marginTop={3}
                columnGap={2}
                display={"flex"}
                flexWrap={"wrap"}
              >
                <Button
                  display="flex"
                  variant="contained"
                  color="secondary"
                  alignItems="center"
                  onClick={() => finishAttendanceVaccine()}
                >
                  <CheckRoundedIcon style={{ marginRight: 6 }} />
                  Finalizar
                </Button>
                <Button onClick={() => goBack()}>Voltar</Button>
              </Grid>
            </Grid>
          </Grid>
          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {years.map((year, index) => (
                <Tab
                  label={year.label}
                  value={index}
                  onClick={() => setPeriod(`${year.inicio}-${year.fim}`)}
                />
              ))}
            </TabList>
          </TabContext>

          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ background: theme.palette.primary.light }}>
                <TableRow>
                  <TableCell
                    sx={{ color: theme.palette.primary.medium, fontSize: 13 }}
                  >
                    Vacinas
                  </TableCell>
                  {ImmunizationRecordQuery.data.headers?.map((header) => {
                    return (
                      <TableCell
                        sx={{
                          color: theme.palette.primary.medium,
                          fontSize: 13,
                        }}
                      >
                        {header}
                      </TableCell>
                    );
                  })}
                  <DialogMedium
                    fullWidth={8}
                    open={immunizingNew}
                    title={"Adicionar"}
                    handleClose={handleClose}
                  >
                    <ImmunizingNewForm
                      handleClose={handleClose}
                      showVisualization={showVisualization}
                    />
                  </DialogMedium>
                </TableRow>
              </TableHead>
              <TableBody>
                {ImmunizationRecordQuery.data.vaccines?.map((vaccine) => {
                  return (
                    <TableRow>
                      <TableCell>{vaccine.describe}</TableCell>
                      {vaccine?.dates?.map((date) => {
                        return (
                          <TableCell>
                            {date.doses.length
                              ? date.doses.map((dose) => {
                                  return dose.status ? (
                                    <Tooltip
                                      title={
                                        <>
                                          <Typography variant="subtitle1">
                                            Vacina : {vaccine.describe}
                                          </Typography>
                                          <Typography variant="subtitle1">
                                            Dia da Aplicação:{" "}
                                            {dose?.date_application}
                                          </Typography>
                                          <Typography variant="subtitle1">
                                            Administrada por:{" "}
                                            {dose?.administered_by?.name}
                                          </Typography>
                                          <Typography variant="subtitle1">
                                            Fabricante :{" "}
                                            {
                                              dose?.batch?.manufacturer
                                                ?.describe
                                            }
                                          </Typography>
                                          <Typography variant="subtitle1">
                                            Lote: {dose?.batch?.code}
                                          </Typography>
                                        </>
                                      }
                                    >
                                      <FinishedVaccine />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={
                                        <>
                                          <Typography variant="subtitle1">
                                            Vacina : {vaccine.describe}
                                          </Typography>
                                          <Typography variant="subtitle1">
                                            Dose em Atraso
                                          </Typography>
                                        </>
                                      }
                                    >
                                      <UnFinishedVaccine />
                                    </Tooltip>
                                  );
                                })
                              : ""}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </SoapContainer>
      ),
    },
  ];

  return <Steps steps={steps} />;
}

export default VaccineBooklet;
