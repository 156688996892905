import React, { useContext } from "react";
import { Tabs } from "../../../components";
import DrugPrescription from "./DrugPrescription";
import ExamPrescription from "./ExamPrescription";
import DrugList from "./DrugList";
import ExamList from "./ExamList";
import PrescriptionHistory from "./PrescriptionHistory";
import { AppContext } from "../../../contexts/AppContext";
import {
  drugOnSitePrescriptionPDF,
  drugPrescriptionPDF,
  specialPrescriptionPDF,
} from "../../../pdfModels";
import usePermissions from "../../../config/usePermissions";
import { Badge } from "@mui/material";

function Prescription({ handleClose, patient, attendance, allergies }) {
  const {
    patientData,
    setPrescriptionsToSign,
    specialPrescriptions,
    drugOnSitePrescriptions,
    drugPrescriptions,
    examPrescriptions,
  } = useContext(AppContext);
  const drugPermissions = usePermissions("/soap/drugPrescription");
  const examsPermissions = usePermissions("/soap/examsPrescription");

  const signSettingsDrugPresc = {
    visible_sign_x: 215,
    visible_sign_y: 590,
    visible_sign_page: "*",
  };

  const signPrescriptions = async () => {
    const prescriptionsToSign = [];

    if (!!drugOnSitePrescriptions.length) {
      const pdf = drugOnSitePrescriptionPDF(
        drugOnSitePrescriptions,
        patientData
      );
      const drugOnSitePrescriptionsToSign = {
        id: pdf.name.replace(".pdf", ""),
        original_file_name: pdf.name,
        data: await pdf.base64(),
        type: "medications_on_site",
        signature_settings: {
          visible_sign_x: 180,
          visible_sign_y: 600,
          visible_sign_page: "*",
        },
      };

      prescriptionsToSign.push(drugOnSitePrescriptionsToSign);
    }

    if (!!drugPrescriptions.length) {
      const pdf = drugPrescriptionPDF(drugPrescriptions, patientData);
      const drugPrescriptionsToSign = {
        id: pdf.name.replace(".pdf", ""),
        original_file_name: pdf.name,
        data: await pdf.base64(),
        type: "medications",
        signature_settings: signSettingsDrugPresc,
      };
      prescriptionsToSign.push(drugPrescriptionsToSign);
    }

    if (!!specialPrescriptions.length) {
      const prescritionsPromise = specialPrescriptions.map(
        async (prescription) => {
          const pdf = specialPrescriptionPDF([prescription], patientData);
          return {
            id: pdf.name.replace(".pdf", ""),
            original_file_name: pdf.name,
            data: await pdf.base64(),
            type: "special_medications",
            signature_settings: signSettingsDrugPresc,
          };
        }
      );
      const pdfs = await Promise.all(prescritionsPromise);
      prescriptionsToSign.push(...pdfs);
    }

    setPrescriptionsToSign(prescriptionsToSign);
    handleClose();
  };

  const lengthOnSitePrescription = drugOnSitePrescriptions.length;
  const lengthPrescriptions = drugPrescriptions.length;
  const lengthExamPrescriptions = examPrescriptions.length;

  const registers = [
    {
      active: drugPermissions.create,
      label: "Medicamentos",
      content: (
        <DrugPrescription
          type="medications_on_site"
          handleSave={signPrescriptions}
          attendance={attendance}
          allergies={allergies}
        />
      ),
      icon: (
        <Badge
          color="secondary"
          overlap="circular"
          badgeContent={lengthOnSitePrescription}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
      ),
    },
    {
      active: drugPermissions.create,
      label: "Receituário",
      content: (
        <DrugPrescription
          type="medications"
          handleSave={signPrescriptions}
          attendance={attendance}
          allergies={allergies}
        />
      ),
      icon: (
        <Badge
          color="secondary"
          overlap="circular"
          badgeContent={lengthPrescriptions}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
      ),
    },
    {
      active: examsPermissions.create,
      label: "Prescrição de Exames",
      content: (
        <ExamPrescription
          attendance={attendance}
          handleSave={signPrescriptions}
        />
      ),
      icon: (
        <Badge
          color="secondary"
          overlap="circular"
          badgeContent={lengthExamPrescriptions}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
      ),
    },
  ];

  const lists = [
    {
      active: drugPermissions.read,
      label: "Lista de Medicamentos",
      content: <DrugList patient={patient} />,
    },
    {
      active: examsPermissions.read,
      label: "Lista de Exames",
      content: <ExamList patient={patient} />,
    },
    {
      active: drugPermissions.read || examsPermissions.read,
      label: "Histórico de Prescrições",
      content: (
        <PrescriptionHistory
          patient={patient}
          drugsPermission={drugPermissions.read}
          examsPermission={examsPermissions.read}
        />
      ),
    },
  ];

  const tabs = patient ? lists : [...registers, ...lists];

  return (
    <>
      <Tabs tabs={tabs.filter((i) => i.active)} />
    </>
  );
}

export default Prescription;
