import { useCallback } from "react";
import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/risk-classifications";

function useRiskClassification() {
  const getRiskClassifications = useCallback(async (filters) => {
    try {
      const response = await instance.get(path, { params: urlQuery(filters) });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  return {
    getRiskClassifications,
  };
}
export default useRiskClassification;
