import { useCallback } from "react";
import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/presets";

function useRiskClassification() {
  const getPresets = useCallback(async (filters) => {
    try {
      const response = await instance.get(path, { params: urlQuery(filters) });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  const postPresets = useCallback(async (data) => {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  const patchPreset = useCallback(async ({ presetId, data }) => {
    try {
      const response = await instance.patch(`${path}/update/${presetId}`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  const deletePreset = useCallback(async (presetId) => {
    try {
      const response = await instance.delete(`${path}/${presetId}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  return {
    getPresets,
    postPresets,
    patchPreset,
    deletePreset,
  };
}
export default useRiskClassification;
