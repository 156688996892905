import { Box, TablePagination } from "@mui/material";
import { Grid } from "@mui/material";
import { useState } from "react";
import useProvider from "../../../../service/useProvider";
import { useQuery } from "react-query";
import TableFilter from "../../../../components/Table/TableFilter";
const EmployeeTypesRoles = ({ type }) => {
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  const { getTypeRoles } = useProvider();

  const getTypeRolesQuery = useQuery(
    ["type-roles", type],
    () => getTypeRoles(type),
    {
      totalItems: 0,
      items: [],
    }
  );

  const tableColumns = [
    { name: "Código", field: "number" },
    { name: "Papel", field: "name" },
  ];

  return (
    <Box>
      <Grid xs={12} item>
        <Box>
          <TableFilter
            data={getTypeRolesQuery.data?.items ?? []}
            columns={tableColumns}
            loading={getTypeRolesQuery.isFetching}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <TablePagination
              count={getTypeRolesQuery.data?.totalItems ?? 0}
              page={page}
              onPageChange={(_, newPage) => {
                setPage(newPage);
              }}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 20, 75]}
              onRowsPerPageChange={({ target }) => {
                setLimit(parseInt(target.value, 10));
                setPage(0);
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default EmployeeTypesRoles;
