import React, { useEffect } from "react";
import {
  Box,
  Typography,
  CardHeader,
  CardContent,
  Card,
  Avatar,
  Chip,
  Tooltip,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { dateTimeFormater } from "../utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ptBR from "date-fns/esm/locale/pt-BR";
import { Link, Navigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AnimatePresence, motion } from "framer-motion";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
const variants = {
  hidden: {
    opacity: 0,
    y: -5,
  },
  visible: {
    opacity: 1,
    y: -2,
  },
  exit: {
    display: "none",
    opacity: 0,
    y: 0,
  },
};
function HealthTipsFeedback({ data, preventClose }) {
  const [copiedText, setCopiedText] = React.useState("");
  const [showFiles, setShowFiles] = React.useState(false);
  function openInNewTab(href) {
    return <Navigate to={{ pathname: href }} replace />;
  }
  const AnimatedBox = motion(Box);
  return (
    <>
      <Card
        sx={{
          border: "3px solid transparent",
          borderColor: "white",
          borderWidth: "3px",
          boxShadow: "0 0 0 1.5px #FFFFFF",
          transition: ".3s",
        }}
      >
        <CardHeader
          title={
            <CopyToClipboard
              text={data?.number}
              onCopy={() => setCopiedText(data?.number)}
            >
              <Tooltip
                title={copiedText === data?.number ? "Copiado!!" : "Copiar"}
                color="primary"
                placement="top"
              >
                <Chip
                  avatar={
                    <Avatar
                      sx={{
                        bgcolor: (theme) => theme.palette.primary.main,
                      }}
                    >
                      <Typography color="White" fontSize={12}>
                        {data && data?.name.slice(0, 2).toUpperCase()}
                      </Typography>
                    </Avatar>
                  }
                  label={`ID - # ${data?.number}`}
                  variant="outlined"
                  color="primary"
                  clickable
                />
              </Tooltip>
            </CopyToClipboard>
          }
        ></CardHeader>

        <Typography
          variant="h1"
          color="gray"
          fontWeight="700"
          fontSize={12}
          sx={{ ml: 2, mb: 1, mt: 1 }}
        >
          #1 DADOS DA DICA DE SAÚDE
        </Typography>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", mt: "-10px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Cód.Dica de saúde
                    </Typography>{" "}
                    : {!!data?.number ? data?.number : ""}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Dica de saúde
                    </Typography>{" "}
                    : {!!data?.name ? data?.name : ""}
                  </Typography>

                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Descrição
                    </Typography>{" "}
                    : {!!data?.describe ? data?.describe : ""}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Observação
                    </Typography>{" "}
                    : {!!data?.observation ? data?.observation : ""}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      PDF ou Imagem
                    </Typography>{" "}
                    <IconButton
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                        padding: 0,
                      })}
                      onClick={() => setShowFiles((show) => !show)}
                    >
                      <ArrowDropDownIcon
                        sx={{
                          fontSize: "30px",
                          transform: showFiles && "rotate(180deg)",
                          transition: ".5s",
                        }}
                      />
                    </IconButton>
                    <AnimatePresence>
                      {showFiles && (
                        <AnimatedBox
                          variants={variants}
                          initial="hidden"
                          exit="exit"
                          animate="visible"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          {data?.attachments.map((item) => {
                            return (
                              <AnimatedBox
                                key={item.name}
                                sx={{
                                  display: "flex",
                                  marginLeft: "20px",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                {item.type.split("/")[1] === "pdf" ? (
                                  <PictureAsPdfIcon />
                                ) : (
                                  <ImageIcon />
                                )}
                                <Typography
                                  component={"a"}
                                  href={item?.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {!!item?.name ? item?.name : ""}
                                </Typography>
                              </AnimatedBox>
                            );
                          })}
                        </AnimatedBox>
                      )}
                    </AnimatePresence>
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Link
                    </Typography>{" "}
                    :{" "}
                    <a
                      href={data?.link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {" "}
                      {!!data?.link ? data?.link : ""}
                    </a>
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Data de ativação
                    </Typography>{" "}
                    :{" "}
                    {!!data?.activation_date
                      ? format(parseISO(data?.activation_date), "dd/MM/yyyy", {
                          locale: ptBR,
                        })
                      : ""}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Data de inativação
                    </Typography>{" "}
                    :{" "}
                    {!!data?.termination_date
                      ? format(parseISO(data?.termination_date), "dd/MM/yyyy", {
                          locale: ptBR,
                        })
                      : ""}
                  </Typography>

                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Frequência de envio
                    </Typography>{" "}
                    :{" "}
                    {!!dateTimeFormater(data?.sending_frequency)
                      ? dateTimeFormater(data?.sending_frequency)
                      : ""}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default HealthTipsFeedback;
