import React, { useMemo, useState } from "react";
import { BoxContainer, FormTextField, SubmitButton } from "../../../components";
import { Dialog, DialogMedium } from "../../../helper";
import { useFamily } from "../../../service";
import FamilyForm from "./FamilyForm";
import FamilyFeedback from "../../../helper/FamilyFeedback";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Box } from "@mui/system";
import { Button, Grid, TablePagination } from "@mui/material";
import usePermissions from "../../../config/usePermissions";
import TableFilter from "../../../components/Table/TableFilter/Table";
import { Formik } from "formik";
import {
  RadioButtonChecked,
  PersonRemoveAlt1Rounded,
} from "@mui/icons-material";
import Yup from "../../../config/yup";
import FamilyRemovalHistory from "./FamilyRemovalHistory";
import useNotifier from "../../../hooks/useNotifier";

function FamilyList() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [registerFamily, setRegisterFamily] = useState(false);
  const [displayFamily, setDisplayFamily] = useState(false);
  const [editFamily, setEditFamily] = useState(false);
  const [idToInactive, setIdToInactive] = useState(false);
  const [displayExclusions, setDisplayExclusions] = useState(false);
  const [filter, setFilter] = useState({});
  const [family, setFamily] = useState();
  const queryClient = useQueryClient();
  const permission = usePermissions("/attendance/family");
  const { getFamily, toggleStatus } = useMemo(useFamily, []);
  const statusMutation = useMutation(toggleStatus);
  const notify = useNotifier();

  // Request
  const familyQuery = useQuery(
    ["family", page, limit, filter],
    () => getFamily({ page, limit, ...filter }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const tableColumns = [
    { name: "Código", field: "number", type: "number" },
    { name: "Família", field: "name", type: "text" },
    {
      name: "Microárea",
      field: "microarea?.name",
      type: "text",
      filter: "microarea_name",
    },
    {
      name: "Equipe",
      field: "microarea?.team?.name",
      type: "text",
      filter: "team_name",
    },
    {
      name: "Status",
      field: "active",
      use: (status) => (status ? "Ativa" : "Inativa"),
      type: "boolean",
    },
  ];

  function handleClose() {
    setRegisterFamily(false);
    setDisplayFamily(false);
    setEditFamily(false);
    setDisplayExclusions(false);
  }

  const changeStatus = (id, inactive_note) => {
    statusMutation.mutate(
      { familyId: id, familyData: { inactive_note } },
      {
        onSuccess: (data) => {
          notify(data.message);
          familyQuery.refetch();
          setIdToInactive();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  };

  function handleFilter(newfilter) {
    queryClient.cancelQueries([`patientlist`]);
    setFilter(newfilter);
    setPage(0);
  }

  const ExclusionForm = ({ id }) => {
    const initial = {
      inactive_note: "",
    };

    const schema = Yup.object().shape({
      inactive_note: Yup.string()
        .min(5, "Forneça um texto maior.")
        .required("É requerido"),
    });

    const handleSubmit = ({ inactive_note }) => {
      changeStatus(id, inactive_note);
    };
    return (
      <Formik
        initialValues={initial}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Grid xs={12} gap={3} container item>
            <Grid xs={12} item>
              <FormTextField
                name={"inactive_note"}
                label={"Escreva o motivo"}
                required
                multiline
                minRows={8}
              />
            </Grid>
            <Grid item xs={2}>
              <SubmitButton>Inativar</SubmitButton>
            </Grid>
          </Grid>
        )}
      </Formik>
    );
  };

  return (
    <>
      <BoxContainer title="Lista de Famílias">
        <DialogMedium
          open={!!idToInactive}
          handleClose={() => setIdToInactive(false)}
          title={"Motivo de inativação"}
          maxWidth="md"
          fullWidth={true}
          fullScreen={false}
        >
          <ExclusionForm id={idToInactive} />
        </DialogMedium>
        <Dialog
          open={registerFamily}
          title={"Cadastro de Família"}
          handleClose={handleClose}
        >
          <FamilyForm type="register" />
        </Dialog>
        <Dialog
          open={displayFamily}
          title={"Visualização de Família"}
          handleClose={handleClose}
        >
          <FamilyFeedback data={family} />
        </Dialog>
        <Dialog
          open={editFamily}
          title={"Edição de família"}
          handleClose={handleClose}
        >
          <FamilyForm type="edit" data={family} />
        </Dialog>
        <Dialog
          open={displayExclusions}
          title="Histórico de remoções"
          handleClose={handleClose}
        >
          <FamilyRemovalHistory familyId={family?.id} />
        </Dialog>
        <TableFilter
          data={familyQuery.data?.items}
          columns={tableColumns}
          loading={familyQuery.isFetching}
          actions
          handleFilter={handleFilter}
          disableActions={(_, action) =>
            (action === "edit" && !permission.update) ||
            (action === "suspend" && !permission.update)
          }
          actionsTypes={["view", "edit", "suspend", "checklist"]}
          customizeActions={{
            suspend: {
              title: "Mudar status",
              icon: <RadioButtonChecked />,
              verb: "Ativar/Desativar",
            },
            checklist: {
              title: "Mostrar remoções",
              icon: <PersonRemoveAlt1Rounded />,
              verb: "Remoções",
            },
          }}
          actionHandleEdit={(_, i) => {
            setFamily(i);
            setEditFamily(true);
          }}
          actionHandleSuspend={(_, i) => {
            if (i.active) {
              setIdToInactive(i.id);
            } else {
              changeStatus(i.id);
            }
          }}
          actionHandleView={(_, i) => {
            setFamily(i);
            setDisplayFamily(true);
          }}
          actionHandleChecklist={(_, i) => {
            setFamily(i);
            setDisplayExclusions(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={familyQuery.data?.totalItems || 0}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          disabled={!permission.create}
          onClick={() => setRegisterFamily(true)}
        >
          Novo Cadastro
        </Button>
      </BoxContainer>
    </>
  );
}

export default FamilyList;
