import React from "react";
import { useQuery, useMutation } from "react-query";
import usePatient from "../../service/usePatient";
import PaginatedTable from "../Table/PaginatedTable";
import useNotifier from "../../hooks/useNotifier";
import { Box, TablePagination } from "@mui/material";
import { format, parseISO } from "date-fns";
import usePermissions from "../../config/usePermissions";
import { VerifyPermission } from "../../components/RequireAuth/VerifyPermission";

function ActiveProblemsList({ patientId }) {
  const notify = useNotifier();
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const permission = usePermissions("/activeProblems");

  const { getActiveProblems, putActiveProblems } = usePatient();

  const activeProblemsMutation = useMutation(putActiveProblems);

  const activeProblemsQuery = useQuery(
    ["active-problems"],
    () => getActiveProblems(patientId),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const formatedActiveProblems = response.map((activeProblem) => ({
          id: activeProblem.id,
          name: activeProblem.ciap.description,
          active: !activeProblem.inactivated_at ? "Sim" : "Não",
          createdAt: format(parseISO(activeProblem.createdAt), "dd/MM/yyyy"),
          inactivated_at: !!activeProblem.inactivated_at
            ? format(parseISO(activeProblem.inactivated_at), "dd/MM/yyyy")
            : null,
          inactivated_by: activeProblem.inactivated_by?.name,
        }));

        setRows(formatedActiveProblems);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  function handleInactive(activeProblemId) {
    activeProblemsMutation.mutate(
      {
        idPatient: patientId,
        activeProblemId,
      },
      {
        onSuccess: (response) => {
          notify(response.message, "success");
          activeProblemsQuery.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  }

  const tableColumns = [
    { name: "Nome" },
    { name: "Ativo" },
    { name: "Criado em" },
    { name: "Inativado em" },
    { name: "Inativado por" },
  ];

  return (
    <>
      <VerifyPermission valid={permission.read} redirect={false}>
        <PaginatedTable
          data={rows}
          columns={tableColumns}
          loading={activeProblemsQuery.isRefetching}
          actions
          shouldDisableFields={["id"]}
          actionUniqueIdentifier="id"
          actionsTypes={["suspend"]}
          disableRows={(row) => !!row.inactivated_at}
          disableActions={(row) => !!row.inactivated_at || !permission.delete}
          actionHandleSuspend={handleInactive}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={0}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
      </VerifyPermission>
    </>
  );
}

export default ActiveProblemsList;
