import { PageWrapper } from "../components";
import RequireAuth from "../components/RequireAuth/RequireAuth";
import { Home, Login, Patient, Monitoring, Settings, Soap } from "../pages";
import InitialAttendance from "../pages/Attendance/InitialAttendance";
import PatientList from "../pages/Attendance/PatientList";
import EmployeeForm from "../pages/Schedule/EmployeeForm";
import EmployeeList from "../pages/Schedule/EmployeeList";
import Scales from "../pages/Schedule/Scales/Scales";
import Scheduling from "../pages/Schedule/Scheduling";
import PatientForm from "../pages/Attendance/PatientForm";
import FamilyList from "../pages/Attendance/Family/FamilyList";
import AgainstReferences from "../pages/Attendance/AgainstReferences/AgainstReferences";
import Classification from "../pages/Attendance/Classification/Classification";
import VaccineImmunizing from "../pages/Vaccine/VaccineImmunizing";
import VaccineRegisterImmu from "../pages/Vaccine/VaccineRegisterImmu";
import VaccineManufacturer from "../pages/Vaccine/VaccineManufacturer";
import VaccineUnitHealth from "../pages/Vaccine/VaccineUnitHealth";
import VaccineCampaign from "../pages/Vaccine/VaccineCampaign";
import VaccineBooklet from "../pages/Vaccine/VaccineBooklet";
import CarePlanList from "../pages/Program/CarePlan/CarePlanList";
import HealthTips from "../pages/Program/HealthTips/HealthTips";
import ProgramList from "../pages/Program/Programs/ProgramList";
import PatientHealth from "../pages/Program/PatientHealth/PatientHealth";
import TeamList from "../pages/Program/TeamList";
import CockPitSoap from "../pages/CockPit/CockPitSoap";
import CockPitDataAnalysis from "../pages/CockPit/CockPitDataAnalysis";
import CockPitComorbidity from "../pages/CockPit/CockPitComorbidity";
import { NotFound } from "../helper";
import AttendanceConfirmForm from "../pages/Attendance/AttendanceForm/AttendanceConfirmForm";
import EditorForms from "../pages/Attendance/EditorForms/EditorForms";
import PatientDischargeList from "../pages/Attendance/PatientDischarge/PatientDischargeList";
import ScheduleConsultation from "../pages/Schedule/Scales/ScheduleConsultation";
import ReportOperational from "../pages/Report/ReportOperational/ReportOperational";
import ReportStatistical from "../pages/Report/ReportStatistical/ReportStatistical";
import ReportRegister from "../pages/Report/ReportRegister/ReportRegister";
import HasNoPermission from "../components/RequireAuth/HasNoPermission";
import IntegrationScheduling from "../pages/Schedule/IntegrationScheduling";
import PatientUnregistered from "../pages/Attendance/PatientUnregistered";
import DeleteAttendance from "../pages/Attendance/DeleteAttendance/DeleteAttendance";
import MicroAreaList from "../pages/Program/MicroAreas/MicroAreaList";
import RPA from "../pages/RPA";
import PresetsList from "../pages/Presets/PresetsList/PresetsList";
import TPortal from "../pages/TPortal";
import SchedulingRegulation from "../pages/Schedule/Regulation/SchedulingRegulation";
import CockPitOperationIndicator from "../pages/CockPit/CockPitOperationIndicator/CockPitOperationIndicator";
import CockPitSiga from "../pages/CockPit/CockPitSiga";
import CockPitPrevine from "../pages/CockPit/CockPitPrevine";
import CockPitCem from "../pages/CockPit/CockPitCEM";
import Exams from "../pages/Exams";
import Triage from "../pages/Attendance/Triage";
import Billing from "../pages/Attendance/Billing";
import CockPitSchedule from "../pages/CockPit/CockPitSchedule/CockPitSchedule";
import WhatsAppSchedulings from "../pages/Schedule/WhatsApp/WhatsAppSchedulings";
import Thrift from "../pages/Attendance/Thrift";
import GeneralAnnotations from "../pages/Attendance/GeneralAnnotations";
const routesObject = [
  {
    path: "/login",
    component: <Login />,
    name: "Login",
  },
  {
    path: "/tportal/:documentType/:documentId",
    component: <TPortal />,
    name: "TPortal",
  },
  {
    component: (
      <PageWrapper>
        <RequireAuth />
      </PageWrapper>
    ),
    name: "Wrapper",
    subRoutes: [
      {
        path: "",
        component: <Home />,
      },
      {
        path: "patient",
        component: <Patient />,
      },
      {
        path: "schedule",
        subRoutes: [
          {
            path: "employee",
            subRoutes: [
              {
                path: "",
                component: <EmployeeList />,
              },
              {
                path: ":id",
                component: <EmployeeForm />,
              },
            ],
          },
          {
            path: "scales",
            component: <Scales />,
          },
          {
            path: "consultation",
            component: <ScheduleConsultation />,
          },
          {
            path: "scheduling",
            component: <Scheduling />,
          },
          {
            path: "integration",
            component: <IntegrationScheduling />,
          },
          {
            path: "regulation",
            component: <SchedulingRegulation />,
          },
          {
            path: "whatsapp-schedulings",
            component: <WhatsAppSchedulings />,
          },
        ],
      },
      {
        path: "attendance",
        subRoutes: [
          {
            path: "patientUnregistered",
            component: <PatientUnregistered />,
          },
          {
            path: "exams/:id",
            component: <Exams />,
          },
          {
            path: "initial",
            component: <InitialAttendance />,
          },
          {
            path: "delete",
            component: <DeleteAttendance />,
          },
          {
            path: "patient",
            subRoutes: [
              {
                path: "",
                component: <PatientList />,
              },
              {
                path: ":id",
                component: <PatientForm />,
              },
              {
                path: "new/:token",
                component: <PatientForm />,
              },
            ],
          },
          {
            path: "confirm",
            component: <AttendanceConfirmForm />,
          },
          {
            path: "family",
            component: <FamilyList />,
          },
          {
            path: "monitoring",
            component: <Monitoring />,
          },
          {
            path: "againstReference",
            component: <AgainstReferences />,
          },
          {
            path: "classification",
            component: <Classification />,
          },
          {
            path: "billing",
            component: <Billing />,
          },
          {
            path: "docs/:type/:patientId",
            component: <EditorForms />,
          },
          {
            path: "triage/:patientId/:attendanceId",
            component: <Triage />,
          },
          {
            path: "patientDisCharge/:id",
            component: <PatientDischargeList />,
          },
          {
            path: "thrift",
            component: <Thrift />,
          },
          {
            path: "general-annotations",
            component: <GeneralAnnotations />,
          },
        ],
      },
      {
        path: "vaccine",
        subRoutes: [
          {
            path: "lots",
            component: <VaccineImmunizing />,
          },
          {
            path: "immunizing",
            component: <VaccineRegisterImmu />,
          },
          {
            path: "manufacturer",
            component: <VaccineManufacturer />,
          },
          {
            path: "unitHealth",
            component: <VaccineUnitHealth />,
          },
          {
            path: "campaign",
            component: <VaccineCampaign />,
          },
          {
            path: "boocklet/:id/:idattendance",
            component: <VaccineBooklet />,
          },
        ],
      },
      {
        path: "program",
        subRoutes: [
          {
            path: "carePlan",
            component: <CarePlanList />,
          },
          {
            path: "healthtips",
            component: <HealthTips />,
          },
          {
            path: "-/",
            component: <ProgramList />,
          },
          {
            path: "patientHealth",
            component: <PatientHealth />,
          },
          {
            path: "team",
            component: <TeamList />,
          },
          {
            path: "microarea",
            component: <MicroAreaList />,
          },
        ],
      },
      {
        path: "soap/:id/:attendance",
        component: <Soap />,
      },
      {
        path: "soap/odonto/:id/:attendance",
        component: <Soap odonto />,
      },
      {
        path: "soap/ceo/:id/:attendance",
        component: <Soap ceo />,
      },
      {
        path: "reports",
        subRoutes: [
          {
            path: "management",
            component: <></>,
          },
          {
            path: "operational",
            component: <ReportOperational />,
          },
          {
            path: "statistical",
            component: <ReportStatistical />,
          },
          {
            path: "cadastral",
            component: <ReportRegister />,
          },
          {
            path: "RPA",
            component: <RPA />,
          },
        ],
      },
      {
        path: "settings",
        component: <Settings />,
      },
      {
        path: "presets",
        component: <PresetsList />,
      },
      {
        path: "cockpit",
        subRoutes: [
          {
            path: "soap",
            component: <CockPitSoap />,
          },
          {
            path: "siga",
            component: <CockPitSiga />,
          },
          {
            path: "cem",
            component: <CockPitCem />,
          },
          {
            path: "dataAnalysis",
            component: <CockPitDataAnalysis />,
          },
          {
            path: "comorbidity",
            component: <CockPitComorbidity />,
          },
          {
            path: "operationIndicator",
            component: <CockPitOperationIndicator />,
          },
          {
            path: "schedules",
            component: <CockPitSchedule />,
          },
          {
            path: "previne",
            component: <CockPitPrevine />,
          },
        ],
      },
      {
        path: "hasNoPermission",
        component: <HasNoPermission />,
      },
    ],
  },
  {
    path: "*",
    component: (
      <PageWrapper>
        <NotFound />
      </PageWrapper>
    ),
  },
];

export default routesObject;
