export default function formatOdontoProcedures(data) {
  const reduceArrayToString = (items, fieldName) => {
    return items?.reduce((prev, curr, index, array) => {
      if (array.length - 1 === index) {
        return prev + curr[fieldName];
      }
      return prev + curr[fieldName] + ", ";
    }, "");
  };

  const mappedOdontoProcedures = [];

  if (data?.teeth) {
    data.teeth.forEach((value) => {
      if (
        value.root?.problems ||
        value.root?.procedures.length ||
        value.root?.notes
      ) {
        const newItem = {
          local: `Raiz do dente ${value.teeth_id}`,
          problems: reduceArrayToString(value.root_problems, "describe") || "-",
          procedures: reduceArrayToString(value.root.procedures, "name") || "-",
          notes: value.root.notes,
        };
        mappedOdontoProcedures.push(newItem);
      }
      if (
        value.crown?.problems ||
        value.crown?.procedures?.length ||
        value.crown?.notes
      ) {
        const newItem = {
          local: `Coroa do dente ${value.teeth_id}`,
          problems:
            reduceArrayToString(value.crown_problems, "describe") || "-",
          procedures:
            reduceArrayToString(value.crown.procedures, "name") || "-",
          notes: value.crown.notes,
        };
        mappedOdontoProcedures.push(newItem);
      }
    });
  }

  if (data?.arch) {
    if (data.arch.top) {
      const newItem = {
        local: `Arcada Superior`,
        problems: "-",
        procedures:
          reduceArrayToString(data.arch.top.procedures, "name") || "-",
        notes: data.arch.top.notes,
      };
      mappedOdontoProcedures.push(newItem);
    }

    if (data.arch.bottom) {
      const newItem = {
        local: `Arcada Inferior`,
        problems: "-",
        procedures:
          reduceArrayToString(data.arch.bottom.procedures, "name") || "-",
        notes: data.arch.bottom.notes,
      };
      mappedOdontoProcedures.push(newItem);
    }
  }

  if (data?.others) {
    data.others.forEach((value) => {
      const newItem = {
        local: value.local,
        problems: "-",
        procedures: reduceArrayToString(value.procedures, "name") || "-",
        notes: value.notes,
      };
      mappedOdontoProcedures.push(newItem);
    });
  }

  if (data?.sextant) {
    Object.entries(data.sextant).forEach(([key, value]) => {
      if (value?.procedures?.length) {
        const newItem = {
          local: key,
          problems: "-",
          procedures: reduceArrayToString(value.procedures, "name") || "-",
          notes: value.notes,
        };
        mappedOdontoProcedures.push(newItem);
      }
    });
  }

  return mappedOdontoProcedures;
}
