import { TextField, useTheme } from "@mui/material";
import React from "react";

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedContainer = ({ children, label, InputProps, height }) => {
  const theme = useTheme();

  return (
    <TextField
      style={{ width: "100%", height: height }}
      variant="outlined"
      label={label}
      multiline
      InputLabelProps={{
        shrink: true,
        style: {
          color: theme.palette.primary.light,
          fontWeight: "500",
          fontSize: 16,
        },
      }}
      InputProps={{
        inputComponent: InputComponent,
        ...InputProps,
      }}
      inputProps={{ children: children }}
    />
  );
};

export default OutlinedContainer;
