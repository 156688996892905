import instance from "../config/api";
const path = "/health-insurances/plans";

function useHealthInsurancePlan() {
  async function getHealthInsurancePlans() {
    try {
      const response = await instance.get(path);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function getHealthInsurancePlan(id) {
    try {
      const response = await instance.get(`/health-insurances/${id}/plans`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function postHealthInsurancePlan(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function updateHealthInsurancePlan({ id, data }) {
    try {
      const response = await instance.put(`${path}/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  return {
    getHealthInsurancePlans,
    getHealthInsurancePlan,
    postHealthInsurancePlan,
    updateHealthInsurancePlan,
  };
}
export default useHealthInsurancePlan;
