import instance from "../config/api"

export default function useOdonto() {
    async function getOralHealthSurveillance(params) {
        try {
            const result = await instance.get('/oral-health-surveillances',{params: {active: true,...params}})
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
    async function getDentalAppointmentTypes(params) {
        try {
            const result = await instance.get('/dental-appointment-types',{params: {active: true,...params}})
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function getTeethProblems(params) {
        try {
            const result = await instance.get('/teeth-problems', { params: { active: true,...params} })
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }


    return { getOralHealthSurveillance, getDentalAppointmentTypes, getTeethProblems }
}