import { Grid, Typography, InputAdornment, Box, Button } from "@mui/material";
import React from "react";
import Yup from "../../config/yup";
import useNotifier from "../../hooks/useNotifier";
import { AppContext } from "../../contexts/AppContext";
import { AuthContext } from "../../contexts/AuthContext";
import { Business, MedicalInformation } from "@mui/icons-material";
import { theme } from "../../config/theme";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AutocompleteField from "../../components/FormFields/AutocompleteField";

function LoginCompany({ companies, userData }) {
  const notify = useNotifier();
  const { login } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const { setCompany, company } = React.useContext(AppContext);
  const loginMutation = useMutation(login);
  const CompanySchema = Yup.object().shape({
    company: Yup.object().nullable().required("É requerido"),
    specialty: Yup.object().nullable().required("É requerido"),
  });

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(CompanySchema),
    defaultValues: {
      company: null,
      specialty: null,
    },
  });

  async function handleCompany(value) {
    setLoading(true);
    setCompany(value.company);
    const dataLogin = {
      ...userData,
      company_id: value.company?.id,
      specialty_id: value?.specialty.id,
    };
    loginMutation.mutate(dataLogin, {
      onError: (error) => {
        notify(error.message, "error");
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  }

  function handleCompanyOnchange(value) {
    if (!value) {
      setValue("specialty", null);
      return;
    }

    if (value?.related_specialties?.length === 1) {
      setValue("specialty", value?.related_specialties[0]);
    } else {
      setValue("specialty", null);
    }
    setCompany(value);
  }

  React.useEffect(() => {
    if (companies.length === 1) {
      setValue("company", companies[0]);
      setCompany(companies[0]);
    }
  }, [companies]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleCompany)}
      style={{
        marginTop: 2,
      }}
    >
      <Grid container gap={4} marginTop={1}>
        <Grid item xs={12}>
          <AutocompleteField
            options={companies || []}
            optionLabelKey="name"
            name="company"
            label="Selecione uma empresa"
            customOnChange={handleCompanyOnchange}
            control={control}
            disabled={companies?.length === 1}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Business style={{ color: theme.palette.primary.main }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {company && (
          <AutocompleteField
            options={company?.related_specialties}
            optionLabelKey="describe"
            name="specialty"
            label={`Selecione uma especialidade da empresa ${company?.name} `}
            control={control}
            disabled={company?.related_specialties?.length === 1}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MedicalInformation
                    style={{ color: theme.palette.primary.main }}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
        <Grid item xs={12}>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            fullWidth
            sx={{ backgroundColor: theme.palette.primary.light }}
          >
            <Typography fontWeight={"bold"} margin={2}>
              Entrar
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LoginCompany;
