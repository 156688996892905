import React, { useState } from "react";
import { Tabs } from "../../components/index.js";
import TableFilter from "../../components/Table/TableFilter/index.js";
import { Box, Button, TablePagination } from "@mui/material";
import useAttachedDocuments from "../../service/useAttachedDocuments.js";
import { useQuery, useQueryClient } from "react-query";
import { DialogMedium } from "../../helper/index.js";
import AttachedDocumentsForm from "./AttachedDocumentsForm/index.jsx";
import { format, isToday, parseISO } from "date-fns";
import AttachedDocumentSuspendForm from "./AttachedDocumentSuspendForm/index.jsx";
import useLocalStorage from "../../hooks/useLocalStorage.js";
import DynamicFeedback from "../../components/DynamicFeedback/index.jsx";
import openURL from "../../pdfModels/openURL.js";
import usePermissions from "../../config/usePermissions.js";
export default function AttachedDocuments({ patient_id, attendance_id }) {
  const { getAttachedDocuments } = useAttachedDocuments();
  const [openForm, setOpenForm] = useState(false);
  const [toView, setToView] = useState();
  const [toSuspend, setToSuspend] = useState();
  const attachedDocumentPermissions = usePermissions("/attached-documents");
  const [userData] = useLocalStorage("user-data");
  const [params, setParams] = useState({
    page: 0,
    limit: 10,
    type: "Exame de Imagem",
    patient_id,
  });

  const queryClient = useQueryClient();

  const documentTypes = [
    {
      label: "Exame de Imagem",
    },
    {
      label: "Exame Laboratorial",
    },
    {
      label: "Documento Clínico",
    },
    {
      label: "Guias",
    },
    {
      label: "Solicitações",
    },
    {
      label: "Encaminhamento",
    },
    {
      label: "Documento Jurídico",
    },
    {
      label: "Termo",
    },
  ];

  const columns = [
    {
      name: "Nº Atendimento",
      field: "attendance?.number",
      filter: "attendance_number",
      type: "number",
    },
    {
      name: "Data",
      field: "createdAt",
      use: (createdAt) =>
        createdAt ? format(parseISO(createdAt), "dd/MM/yyyy 'às' HH:mm") : "",
      filter: "created_at",
      type: "date",
    },
    {
      name: "Profissional",
      field: "created_by?.name",
      filter: "employee_name",
      type: "text",
    },
    {
      name: "Tipo Profissional",
      field: "created_by?.employee_Type.description",
      filter: "employee_type",
      type: "text",
    },
  ];

  const initialData = {
    items: [],
    totalItems: 0,
  };

  const attachedDocuments = useQuery(
    ["get-external-documents", params],
    () => getAttachedDocuments(params),
    {
      initialData,
      onError() {
        queryClient.setQueryData(
          ["get-external-documents", params],
          initialData
        );
      },
    }
  );

  function handleCloseForm() {
    setOpenForm(false);
    attachedDocuments.refetch();
  }
  function handleCloseView() {
    setToView();
  }

  function handleTableFilter(filters) {
    setParams(({ limit, page, patient_id, type }) => ({
      limit,
      page,
      patient_id,
      type,
      ...filters,
    }));
  }

  const feedbackConfig = [
    {
      type: "header",
      label: "Descrição",
    },
    {
      type: "title",
      formatter: (_, attachedDocument) => attachedDocument?.description,
    },
    {
      type: "header",
      label: "Ver Anexo",
    },
    {
      type: "header",
      formatter: (_, attachedDocument) => (
        <Button
          variant="outlined"
          onClick={() => openURL(attachedDocument?.document_data.url)}
        >
          {attachedDocument?.document_data.name}
        </Button>
      ),
    },
  ];

  return (
    <>
      <DialogMedium
        title={`Visualizar ${params.type}`}
        fullWidth
        maxWidth={"md"}
        open={!!toView}
        handleClose={handleCloseView}
      >
        <DynamicFeedback data={toView} shape={feedbackConfig} />
      </DialogMedium>
      <DialogMedium
        title={`Suspender ${params.type}`}
        fullWidth
        maxWidth={"md"}
        open={!!toSuspend}
        handleClose={() => setToSuspend()}
      >
        <AttachedDocumentSuspendForm
          attachedDocumentId={toSuspend?.id}
          handleClose={() => {
            setToSuspend();
            attachedDocuments.refetch();
          }}
        />
      </DialogMedium>
      <DialogMedium
        title={`Anexar ${params.type}`}
        fullWidth
        maxWidth={"md"}
        open={openForm}
        handleClose={handleCloseForm}
      >
        <AttachedDocumentsForm
          attendance_id={attendance_id}
          patient_id={patient_id}
          type={params.type}
          handleClose={handleCloseForm}
        />
      </DialogMedium>
      <Tabs
        tabs={documentTypes}
        labelIdentifier="label"
        handleClick={({ label }) => {
          setParams((curr) => ({ ...curr, type: label }));
        }}
        variant="scrollable"
        scrollButtons={true}
        sx={{ marginBottom: 1 }}
      />
      <TableFilter
        columns={columns}
        actions
        actionsTypes={["view", "suspend"]}
        data={attachedDocuments.data.items}
        handleFilter={handleTableFilter}
        rowStatus={(row) => {
          if (!!row.suspendedAt) {
            return "disabled";
          }
        }}
        disableActions={(row, action) => {
          return (
            action === "suspend" &&
            (!!row.suspendedAt ||
              !isToday(parseISO(row.createdAt)) ||
              row.created_by.id !== userData.id ||
              !attachedDocumentPermissions.delete)
          );
        }}
        actionHandleView={(_, row) => {
          setToView(row);
        }}
        actionHandleSuspend={(_, row) => {
          setToSuspend(row);
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={attachedDocuments.data.totalItems}
          component="div"
          page={params.page}
          onPageChange={(_, newPage) => {
            setParams((params) => ({ ...params, page: newPage }));
          }}
          rowsPerPage={params.limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setParams((params) => ({
              ...params,
              page: 0,
              limit: parseInt(target.value, 10),
            }));
          }}
        />
      </Box>
      <Box>
        <Button
          disabled={!attachedDocumentPermissions.create}
          variant="contained"
          onClick={() => setOpenForm(true)}
        >
          Anexar documento
        </Button>
      </Box>
    </>
  );
}
