import {
  Box,
  Button,
  Grid,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import TableFilter from "../../../components/Table/TableFilter";
import { useProvider } from "../../../service";
import { PaginatedAutocompleteField } from "../../../components/FormFields";
import { useForm } from "react-hook-form";
import Yup from "../../../config/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNotifier } from "../../../hooks";

export default function AllowImportSchedule() {
  const { getProviders, allowImportSchedules } = useProvider();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const theme = useTheme();
  const notify = useNotifier();
  const allowImportSchedulesMutation = useMutation(allowImportSchedules);
  const employeeQuery = useQuery(
    ["employeeAllowedToImportSchedules", [page, limit]],
    () => getProviders({ page, limit, can_import_schedule: true }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: [],
    }
  );
  const columns = [
    {
      name: "Profissional",
      field: "name",
    },
  ];

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      employee: null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        employee: Yup.object().required("É requerido").nullable(),
      })
    ),
  });

  const onSubmit = handleSubmit((value) => {
    allowImportSchedulesMutation.mutate(
      { id: value.employee.id, data: { can_import_schedule: true } },
      {
        onSuccess: (res) => {
          employeeQuery.refetch();
          notify(res.data.message, "success");
          reset();
        },
        onError: (err) => {
          notify(err.message, "error");
        },
      }
    );
  });

  return (
    <Grid container spacing={1} rowGap={2} xs={12}>
      <Grid item xs={12} justifyContent={"center"}>
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          Permitir a Importação de Agendas
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableFilter
          columns={columns}
          actions
          actionsTypes={["delete"]}
          data={employeeQuery.data?.items}
          actionUniqueIdentifier={"id"}
          actionHandleDelete={(id) => {
            allowImportSchedulesMutation.mutate(
              { id: id, data: { can_import_schedule: false } },
              {
                onSuccess: (res) => {
                  employeeQuery.refetch();
                  notify(res.data.message, "success");
                },
                onError: (err) => {
                  notify(err.message, "error");
                },
              }
            );
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={employeeQuery.data?.totalItems || 0}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnGap={2} alignItems={"center"}>
          <Grid xs={8}>
            <PaginatedAutocompleteField
              service={(param) =>
                getProviders({
                  ...param,
                  exclude_me: true,
                  can_import_schedule: false,
                })
              }
              control={control}
              fields="name"
              queryKey={"providers"}
              optionCompareKey="id"
              filterKey="name"
              optionLabelKey="name"
              name={"employee"}
              label={"Profissional: "}
            />
          </Grid>
          <Grid xs={"auto"}>
            <Button
              variant="contained"
              onClick={onSubmit}
              onLoad={!!allowImportSchedulesMutation.isLoading}
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
