import { Box, TablePagination, Typography } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import { useContext, useState } from "react";
import { theme } from "../../../config/theme";
import { useQuery } from "react-query";
import { AppContext } from "../../../contexts/AppContext";
import { DialogMedium } from "../../../helper";
import useInterventions from "../../../service/useInterventions";

export default function InterventionList({ patient }) {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [note, setNote] = useState(false);
  const { patientData } = useContext(AppContext);
  const { getInterventions } = useInterventions();
  const columns = [
    {
      name: "Procedimento",
      field: "procedure?.name",
    },
  ];

  const interventions = useQuery(
    ["getInterventions", [patient?.id || patientData.id, page, limit]],
    () =>
      getInterventions({
        page,
        limit,
        patient_id: patient?.id || patientData.id,
      })
  );

  return (
    <>
      <DialogMedium
        open={note}
        handleClose={() => setNote(false)}
        title={"Anotação"}
      >
        <Typography
          component={"span"}
          sx={{ color: theme.palette.primary.light }}
        >
          {note}
        </Typography>
      </DialogMedium>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        marginY="1rem"
        gap={2}
      >
        <Typography
          component="h2"
          sx={{
            color: theme.palette.primary.light,
            fontWeight: 600,
          }}
        >
          Lista de Medicamentos
        </Typography>
      </Box>
      <TableFilter
        columns={columns}
        data={interventions?.data?.items}
        actions
        noHeaderOptions
        minHeight={300}
        actionsTypes={["view"]}
        customizeActions={{
          view: {
            title: "Visualizar anotação",
          },
        }}
        actionUniqueIdentifier="id"
        disableActions={(row, action) => !row.notes && action === "view"}
        actionHandleView={(_, row) => {
          setNote(row.notes);
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={interventions.data?.totalItems || 0}
          component="div"
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value), 10);
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}
