import React from "react";
import { TITLE_NAME } from "../../../config/config";
import { Form, Formik } from "formik";
import Yup from "../../../config/yup";
import { Helmet } from "react-helmet";
import { Grid, Box } from "@mui/material";
import {
  DateTime,
  FormTextField,
  SubmitButton,
} from "../../../components/Form";
import FormRadio from "../../../components/Form/FormRadio";
import FormDate from "../../../components/Form/FormDate";
import { useCampaign, useVaccine } from "../../../service";
import { format } from "date-fns";
import FormSelectFieldWithPaginate from "../../../components/Form/FormSelectWithPaginate";
import useNotifier from "../../../hooks/useNotifier";

function CampaignFrom(handleCloseDialog) {
  const { postCampaigns } = useCampaign();
  const { getVaccines } = useVaccine();
  const notify = useNotifier();
  const initalvalues = {
    describe: "",
    vaccine: "",
    minimumAge: "",
    maximumAge: "",
    initialDate: "",
    finalDate: "",
    status: "",
  };

  const validations = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
    minimumAge: Yup.string()
      .required("É requerido")
      .matches(
        /^(?=\d+[ymwd])(( ?\d+y)?(?!\d))?(( ?\d+m)?(?!\d))?(( ?\d+w)?(?!\d))?(( ?\d+d)?(?!\d))?$/g,
        "Formato Incorreto, Esperado : 0y 0m 0w 0d"
      ),
    maximumAge: Yup.string()
      .required("É requerido")
      .matches(
        /^(?=\d+[ymwd])(( ?\d+y)?(?!\d))?(( ?\d+m)?(?!\d))?(( ?\d+w)?(?!\d))?(( ?\d+d)?(?!\d))?$/g,
        "Formato Incorreto, Esperado : 0y 0m 0w 0d"
      ),
    vaccine: Yup.object().required("É requerido").nullable(),
    initialDate: Yup.string().required("É requerido"),
    finalDate: Yup.string().required("É requerido"),
    status: Yup.string().required("É requerido"),
  });
  async function handleSubmit(values) {
    const campaignObject = {
      describe: values.describe,
      id_vaccine: values.vaccine.id,
      minimumAge: values.minimumAge,
      maximumAge: values.maximumAge,
      initialDate: format(new Date(values.initialDate), "yyyy-MM-dd"),
      finalDate: format(new Date(values.finalDate), "yyyy-MM-dd"),
      status: values.status,
    };

    try {
      const response = await postCampaigns(campaignObject);
      if (response) {
        notify("Campanha cadastrada com Sucesso!", "success");
      }
    } catch (err) {
      notify(err.message, "error");
    }
  }
  const search = [
    { label: "Andamento", value: "Andamento" },
    { label: "Embargado", value: "Embargado" },
    { label: "Finalizado", value: "Finalizado" },
  ];

  const date = new Date();
  date.setHours(0, 0, 0, 0);

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Vacina</title>
      </Helmet>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initalvalues}
        validationSchema={validations}
      >
        {({ setFieldValue }) => (
          <Form>
            <Grid container mt={0.1} spacing={2}>
              <Grid item sx={{ marginBottom: 2 }} xs={4}>
                <FormDate
                  item
                  name="initialDate"
                  label="Início"
                  required
                  minDate={date}
                  onBlur={(e) => {}}
                />
              </Grid>
              <Grid item sx={{ marginBottom: 2 }} xs={4}>
                <FormDate
                  item
                  name="finalDate"
                  label="Fim"
                  required
                  minDate={date}
                  onBlur={(e) => {}}
                />
              </Grid>
              <Grid xs={4} item>
                <FormSelectFieldWithPaginate
                  service={getVaccines}
                  name="vaccine"
                  fields="describe"
                  label="Imunobiológico"
                  required
                />
              </Grid>
              <Grid xs={4} item>
                <DateTime title="Idade Minima" name="minimumAge" />
              </Grid>
              <Grid xs={4} item>
                <DateTime title="Idade Máxima" name="maximumAge" />
              </Grid>
              <Grid xs={12} sx={{ marginBottom: 3 }} item>
                <FormTextField name="describe" label="Descrição" required />
              </Grid>
              <Grid item xs={12}>
                <FormRadio
                  name="status"
                  radios={search}
                  row
                  legend="Status..."
                  required
                  keys={["label", "value"]}
                  sx={{
                    marginBottom: 14,
                  }}
                />
              </Grid>
            </Grid>
            <Grid display="flex" justifyContent="end" marginTop={2}>
              <SubmitButton>Salvar Cadastro</SubmitButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CampaignFrom;
