import React from "react";
import { useField, useFormikContext } from "formik";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

function FormSearchSelectField({
  name,
  label,
  list,
  handleChange = () => null,
  item,
  newOptions,
  required,
  InputProps = {},
  ...props
}) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const [open, setOpen] = React.useState(false);

  const settings = {
    ...field,
    ...props,
    size: "small",
    fullWidth: true,
    variant: "outlined",
  };

  if (meta && meta.touched && meta.error) {
    settings.error = true;
    settings.helperText = meta.error;
  }

  const filter = createFilterOptions();
  const newValues = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;

    const isExisting = options.some(
      (option) => inputValue === option[newOptions]
    );

    if (inputValue && !isExisting) {
      filtered.push({
        [newOptions]: `Adicionar "${inputValue}"`,
        inputValue,
      });
    }

    return filtered;
  };

  function onChange(_, value) {
    if (!!newOptions && !!value && !!value.inputValue) {
      setFieldValue(field.name, { [newOptions]: value.inputValue });
    } else {
      setFieldValue(field.name, value);
    }
    handleChange(value);
  }

  return (
    <Autocomplete
      {...settings}
      onChange={onChange}
      clearText="Limpar Campo"
      autoComplete={props.autoComplete}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        setFieldValue(null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={settings?.error}
          helperText={settings?.helperText}
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
          }}
          InputLabelProps={{ shrink: true }}
        />
      )}
      filterOptions={!!newOptions ? newValues : createFilterOptions()}
    />
  );
}

export default FormSearchSelectField;
