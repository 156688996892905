import React, { useEffect, useMemo, useState } from "react";
import { FormDate, SubmitButton } from "../../../components";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import { useMutation, useQueryClient } from "react-query";
import { Formik, Form } from "formik";
import { Grid, Typography } from "@mui/material";
import { DialogMedium } from "../../../helper";
import Yup from "../../../config/yup";
import { theme } from "../../../config/theme";
import FormTextField from "../../../components/Form/FormTextField";
import FormRadio from "../../../components/Form/FormRadio";
import FormSelectField from "../../../components/Form/FormSelectField";
import { useFamily, usePatient, useMicroArea } from "../../../service";
import { format, parseISO } from "date-fns";
import { kinships2 } from "../../../config/standardSelects";
import FormSelectFieldWithPaginate from "../../../components/Form/FormSelectWithPaginate";
import FormSelectWithPaginate from "../../../components/Form/FormSelectWithPaginate";
import useNotifier from "../../../hooks/useNotifier";

function FamilyForm({ data, type = "edit" }) {
  const [patients, setPatients] = useState([]);
  const [patientsToDelete, setPatientsToDelete] = useState([]);
  const [indexPatient, setIndexPatient] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openReason, setOpenReason] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const { getMicroArea } = useMicroArea();
  const { putFamily, deleteFamily, postFamily } = useMemo(useFamily, []);
  const { getPatients } = useMemo(usePatient, []);
  const queryClient = useQueryClient();
  const notify = useNotifier();

  // config form
  const [initialValues, setInitialValues] = useState({
    family_name: "",
    team: null,
    patient: null,
    responsible: "Não",
    role: null,
    microarea: null,
  });
  const [validations, setValidations] = useState({});
  const [actions, setActions] = useState([]);

  const reasonValidations = Yup.object().shape({
    exclusion_reason: Yup.string().nullable().required("É requerido"),
    death_date: Yup.date().when("exclusion_reason", {
      is: "Óbito",
      then: (schema) => schema.nullable().required(),
      otherwise: (schema) => schema.nullable(),
    }),
    death_cerficate_number: Yup.number().nullable(),
  });

  useEffect(() => {
    if (type === "edit") {
      const editInitialValues = {
        family_name: data.name,
        patient: null,
        responsible: "Não",
        role: null,
        edit_role: null,
        edit_responsible: "Sim",
        microarea: data.microarea,
        team: data.microarea?.team?.name || "",
      };

      const editValidations = Yup.object().shape({
        family_name: Yup.string().required("É requerido"),
        team: Yup.string().nullable(),
        patient: Yup.object().required("É requerido").nullable(),
        responsible: Yup.string().required("É requerido"),
        role: Yup.string().required("É requerido").nullable(),
        edit_responsible: Yup.string().required("É requerido"),
        edit_role: Yup.string()
          .nullable()
          .when("edit_responsible", {
            is: "Não",
            then: Yup.string().nullable().required("É requerido"),
          }),
        microarea: Yup.object().nullable().required("Selecione a microárea"),
      });

      setInitialValues(editInitialValues);
      setValidations(editValidations);
      setActions(["delete", "edit"]);

      if (!!data) {
        const { responsible, members } = data;

        const formatedPatients = [
          {
            patient_number: responsible.number,
            patient_name: responsible.name,
            patient_id: responsible.id,
            patient_family_id: null,
            birth_date: responsible.birth_date
              ? format(parseISO(responsible.birth_date), "dd/MM/yyyy")
              : "Não informado",
            responsible: "Sim",
            role: "",
          },
        ];

        members?.forEach(({ id, patient }) => {
          formatedPatients.push({
            patient_number: patient.number,
            patient_name: patient.name,
            patient_id: patient.id,
            birth_date: patient.birth_date
              ? format(parseISO(patient.birth_date), "dd/MM/yyyy")
              : "Não informado",
            responsible: "Não",
            role: patient.role,
            patient_family_id: id,
          });
        });

        setPatients(formatedPatients);
      }
    } else {
      const registerValidations = Yup.object().shape({
        family_name: Yup.string().required("É requerido"),
        team: Yup.string().nullable(),
        patient: Yup.object()
          .test("patient", "", validateAddedPatient)
          .required("É requerido")
          .nullable(),
        responsible: Yup.string().required("É requerido"),
        role: Yup.string()
          .nullable()
          .when("responsible", {
            is: "Não",
            then: Yup.string().nullable().required("É requerido"),
          }),
        microarea: Yup.object().nullable().required("Selecione a microárea"),
      });

      setValidations(registerValidations);
      setActions(["delete"]);
      setCanSave(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, data]);

  // Requests
  const registerFamilyMutation = useMutation(postFamily);
  const EditFamilyMutation = useMutation(putFamily);
  const deleteFamilyMutation = useMutation(deleteFamily);

  // Handle Functions
  function validateAddedPatient(option) {
    if (option) {
      const addedPatient = patients.some(
        (patient) => patient.patient_id === option.id
      );
      if (addedPatient) {
        notify("Paciente já adicionado", "success");
      }
      return !addedPatient;
    }
  }

  function editFamily(role, responsible, index) {
    patients[index].role = responsible === "Sim" ? "" : role;
    patients[index].responsible = responsible;

    setPatients(patients);
    if (!canSave) setCanSave(true);
    setOpenEdit(false);
  }

  function verifyHasResponsible() {
    const hasLeader = patients.some(({ responsible }) => responsible === "Sim");
    return hasLeader;
  }

  function handleDelete(index) {
    const patient = patients[index];

    if (type === "edit" && patient.patient_family_id) {
      setIndexPatient(index);
      setOpenReason(true);
    } else {
      const newPatients = patients.filter((patient, i) => i !== index);
      setPatients(newPatients);
    }
  }

  function handleDeleteWithReasons(values) {
    const patient = patients[indexPatient];

    const patientsRegistered = [
      data.responsible.id,
      ...data.members.map(({ patient }) => patient.id),
    ];

    const isRegistered = patientsRegistered.some((id) => {
      return patient.patient_id === id;
    });

    if (isRegistered) {
      patient.exclusion_reason = values.exclusion_reason;
      patient.death_date = values.death_date
        ? format(values.death_date, "yyyy-MM-dd")
        : null;
      patient.death_cerficate_number = values.death_cerficate_number || null;

      setPatientsToDelete((patientsDeleteds) => [...patientsDeleteds, patient]);
    }

    const newPatients = patients.filter((patient, i) => i !== indexPatient);
    setPatients(newPatients);
    setOpenReason(false);
    if (!canSave) setCanSave(true);
  }

  function handleEdit(index, setFieldValue) {
    setIndexPatient(index);
    setFieldValue("edit_responsible", patients[index].responsible);
    setFieldValue("edit_role", patients[index].role);
    setOpenEdit(true);
  }

  function addMember(
    { family_name, patient, responsible, role, team, microarea },
    { resetForm }
  ) {
    if (type === "edit") {
      const newPatientsToDelete = patientsToDelete?.filter(({ patient_id }) => {
        return patient.id !== patient_id;
      });

      const patientDeleted = patientsToDelete?.filter(({ patient_id }) => {
        return patient.id === patient_id;
      })[0];

      setPatientsToDelete(newPatientsToDelete);

      setPatients((previousPatient) => [
        ...previousPatient,
        {
          patient_number: patient.number,
          patient_name: patient.name,
          birth_date: format(parseISO(patient.birth_date), "dd/MM/yyyy"),
          responsible,
          role: role || "",
          patient_id: patient.id,
          patient_family_id: patientDeleted
            ? patientDeleted.patient_family_id
            : null,
        },
      ]);

      if (!canSave) setCanSave(true);
    } else {
      patients.push({
        patient_number: patient.number,
        patient_name: patient.name,
        birth_date: format(parseISO(patient.birth_date), "dd/MM/yyyy"),
        responsible,
        role: role || "",
        patient_id: patient.id,
      });
    }
    resetForm({
      values: {
        family_name,
        team,
        microarea,
        patient: null,
        responsible: "Não",
        role: null,
      },
    });
  }

  function formatFamily(family_name, microarea_id, patients) {
    const formatedFamily = {
      name: family_name,
      microarea_id,
      id_responsible: null,
      members: [],
    };

    patients.forEach(({ patient_id, role, responsible, patient_family_id }) => {
      if (responsible === "Sim") {
        formatedFamily.id_responsible = patient_id;
      } else {
        formatedFamily.members.push({
          id: patient_family_id,
          id_patient: patient_id,
          role,
        });
      }
    });

    return formatedFamily;
  }

  async function handleSubmit(family_name, microarea, setFieldValue) {
    const family = formatFamily(family_name, microarea.id, patients);

    if (type === "edit") {
      const formatedPatientsToDelete = patientsToDelete.map((patient) => ({
        id: patient.patient_id,
        reason: patient.exclusion_reason,
        death_date: patient.death_date,
        death_cerficate_number: patient.death_cerficate_number,
      }));

      EditFamilyMutation.mutate(
        { familyId: data.id, familyData: family },
        {
          onSuccess: (response) => {
            notify(response.data.message, "success");
            if (patientsToDelete.length) {
              deleteFamilyMutation.mutate(
                {
                  familyId: data.id,
                  patientsToDelete: { members: formatedPatientsToDelete },
                },
                {
                  onSuccess: (response) => {
                    notify(response.data.message, "success");
                  },
                  onError: (error) => {
                    notify(error.message, "error");
                  },
                }
              );
            }

            queryClient.invalidateQueries("family");
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    } else {
      registerFamilyMutation.mutate(family, {
        onSuccess: (response) => {
          setPatients([]);

          notify(response.data.message, "success");

          queryClient.invalidateQueries("family");

          setFieldValue("family_name", "");
          setFieldValue("team", null);
          setFieldValue("microarea", null);
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      });
    }
  }

  //field configs
  const collumnsNames = [
    { name: "Código" },
    { name: "Paciente" },
    { name: "Nascimento" },
    { name: "Responsável" },
    { name: "Parentesco" },
  ];

  const exclusionOptions = {
    Óbito: "Óbito",
    "Mudança de território": "Mudança de território",
    Outros: "Outros",
  };

  return (
    <>
      <DialogMedium
        fullWidth
        maxWidth="sm"
        open={openReason}
        title="Motivo de remoção"
        handleClose={() => setOpenReason(false)}
      >
        <Formik
          onSubmit={handleDeleteWithReasons}
          initialValues={{
            exclusion_reason: null,
            death_date: null,
            death_cerficate_number: null,
          }}
          validationSchema={reasonValidations}
        >
          {({ values: { exclusion_reason }, setFieldValue }) => (
            <Form>
              <Grid container spacing={3} marginTop="0.5rem">
                <Grid xs={12} item>
                  <FormSelectField
                    name="exclusion_reason"
                    label="Motivo de remoção"
                    options={exclusionOptions}
                    required
                    customHandleChange={(value) => {
                      if (value !== "Óbito") {
                        setFieldValue("death_date", null);
                        setFieldValue("death_cerficate_number", "");
                      }
                    }}
                  />
                </Grid>
                <Grid xs={12} item>
                  <FormDate
                    name="death_date"
                    label="Data do óbito"
                    required
                    disabled={exclusion_reason !== "Óbito"}
                  />
                </Grid>
                <Grid xs={12} item>
                  <FormTextField
                    name="death_cerficate_number"
                    label="Número da D.O."
                    type="number"
                    disabled={exclusion_reason !== "Óbito"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SubmitButton>Confirmar</SubmitButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogMedium>
      <Formik
        onSubmit={addMember}
        initialValues={initialValues}
        validationSchema={validations}
        enableReinitialize={true}
      >
        {({
          values: {
            family_name,
            microarea,
            responsible,
            edit_role,
            edit_responsible,
          },
          setFieldValue,
          resetForm,
        }) => (
          <>
            <Form>
              <Grid container spacing={3} marginTop="0.5rem">
                <Grid xs={12} spacing={3} container item>
                  <Grid item md={4} xs={8}>
                    <FormTextField
                      name="family_name"
                      label="Família"
                      type="text"
                      onBlur={() => setCanSave(true)}
                      required
                    />
                  </Grid>
                  <Grid item md={3} xs={8}>
                    <FormSelectWithPaginate
                      name="microarea"
                      label="Microáreas"
                      fields="name"
                      service={(params) =>
                        getMicroArea({ ...params, team_id: "not_null" })
                      }
                      customHandleChange={(microarea) => {
                        setFieldValue("team", microarea.team?.name);
                        setCanSave(true);
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={8}>
                    <FormTextField
                      fields="name"
                      name="team"
                      label="Equipe"
                      required
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    sx={{
                      color: theme.palette.primary.light,
                      fontSize: 15,
                      fontWeight: 500,
                    }}
                  >
                    Dados da Família
                  </Typography>
                </Grid>
                <Grid xs={8} md={4} item>
                  <FormSelectFieldWithPaginate
                    service={(paginate) =>
                      getPatients({ ...paginate, filter: "hasNoFamily" })
                    }
                    fields="name"
                    name="patient"
                    label="Paciente: "
                    filterOptionsCondition={(_, option) =>
                      !!patients.length
                        ? patients.every(
                            ({ patient_id }) => patient_id !== option.id
                          )
                        : true
                    }
                  />
                </Grid>
                <Grid item xs={2} md={2}>
                  <FormRadio
                    name="responsible"
                    legend="É o responsável:"
                    radios={[
                      {
                        label: "Sim",
                        value: "Sim",
                        disabled: verifyHasResponsible(),
                      },
                      { label: "Não", value: "Não" },
                    ]}
                    keys={["label", "value"]}
                    row
                  />
                </Grid>
                <Grid xs={8} md={4} item>
                  <FormSelectField
                    name="role"
                    label="Parentesco"
                    required
                    options={kinships2}
                    disabled={responsible === "Sim"}
                  />
                </Grid>
                <Grid item md={2} xs={4}>
                  <SubmitButton>Adicionar</SubmitButton>
                </Grid>
              </Grid>
              <DialogMedium
                title={patients[indexPatient]?.patient_name}
                open={openEdit}
                handleClose={() => setOpenEdit(false)}
                fullWidth
              >
                {!!patients[indexPatient] && (
                  <Grid container spacing={3} marginTop="0.5rem">
                    <Grid item xs={8}>
                      <FormSelectField
                        name="edit_role"
                        label="Parentesco"
                        required
                        options={kinships2}
                        disabled={edit_responsible === "Sim"}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormRadio
                        name="edit_responsible"
                        legend="Responsável pela família:"
                        radios={[
                          {
                            label: "Sim",
                            value: "Sim",
                            disabled: verifyHasResponsible(),
                          },
                          { label: "Não", value: "Não" },
                        ]}
                        keys={["label", "value"]}
                        row
                      />
                    </Grid>
                    <Grid container item xs={12} justifyItems="center">
                      <Grid item xs={4}>
                        <SubmitButton
                          handleClick={() =>
                            editFamily(
                              edit_role,
                              edit_responsible,
                              indexPatient,
                              resetForm
                            )
                          }
                        >
                          Salvar
                        </SubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </DialogMedium>
            </Form>
            <PaginatedTable
              sx={{ marginTop: 3 }}
              columns={collumnsNames}
              data={patients}
              actions
              actionsTypes={actions}
              shouldDisableFields={["patient_id", "patient_family_id"]}
              actionHandleDelete={handleDelete}
              actionHandleEdit={(index) => handleEdit(index, setFieldValue)}
            />
            <SubmitButton
              sx={{ marginTop: 8 }}
              handleClick={() =>
                handleSubmit(family_name, microarea, setFieldValue)
              }
              disabled={
                !verifyHasResponsible() ||
                !microarea ||
                !family_name ||
                !canSave
              }
              loading={
                EditFamilyMutation.isLoading || registerFamilyMutation.isLoading
              }
            >
              Salvar
            </SubmitButton>
          </>
        )}
      </Formik>
    </>
  );
}

export default FamilyForm;
