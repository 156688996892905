export const addSignPrefixAndSuffix = (value, options) => {
  const { prefix, sign, suffix, signPosition } = options;

  switch (signPosition) {
    case "beforePrefix":
      return `${sign}${prefix}${value}${suffix}`;
    case "afterPrefix":
      return `${prefix}${sign}${value}${suffix}`;
    default:
      return null;
  }
};
export const convertStringMaskArray = (bidimensionalArrayOfStringMasks) => {
  const arrayOfRegexMasks = [];
  if (bidimensionalArrayOfStringMasks) {
    bidimensionalArrayOfStringMasks.forEach((stringMaskArr) => {
      const regexMaskArray = stringMaskArr.map((mask) => {
        if (mask?.includes("/") && mask.length > 1) {
          return new RegExp(mask.replace(/\//g, ""));
        }
        return mask;
      });
      arrayOfRegexMasks.push(regexMaskArray);
    });

    if (arrayOfRegexMasks.length === 1) {
      return arrayOfRegexMasks[0];
    }

    return arrayOfRegexMasks.sort(
      (current, next) => current.length - next.length
    );
  }
  return null;
};

export const formatCurrency = (input, options) => {
  const {
    precision,
    separator = ",",
    delimiter = ".",
    prefix = "",
    suffix = "",
    ignoreNegative,
    showPositiveSign,
    signPosition = "afterPrefix",
  } = options || {};

  const negative = ignoreNegative ? false : input < 0;
  const sign = negative ? "-" : showPositiveSign ? "+" : "";

  const string = Math.abs(input).toFixed(precision);

  const parts = string.split(".");
  const buffer = [];

  let number = parts[0];
  while (number.length > 0) {
    buffer.unshift(number.substr(Math.max(0, number.length - 3), 3));
    number = number.substr(0, number.length - 3);
  }

  let formattedNumber = "";
  formattedNumber = buffer.join(delimiter);

  const decimals = parts[1];
  if (!!precision && decimals) {
    formattedNumber += separator + decimals;
  }

  return addSignPrefixAndSuffix(formattedNumber, {
    prefix,
    suffix,
    sign,
    signPosition,
  });
};

export const multipleArrayMaskFormatter = (props) => {
  const { mask: masks } = props;
  const text = props.text || "";

  if (Array.isArray(masks[0])) {
    const targetMask =
      masks.find((mask) => cleanUpMask(text).length <= mask.length) ||
      masks[masks.length - 1];

    const formattedValueObject = formatWithMask({
      text,
      mask: targetMask,
    });

    formattedValueObject.valid = verifyIfMaskMatch(
      formattedValueObject.masked,
      formattedValueObject.mask
    );

    return formattedValueObject;
  }

  const formattedValueObject = formatWithMask({
    text,
    masks,
  });

  formattedValueObject.valid = verifyIfMaskMatch(
    formattedValueObject.masked,
    formattedValueObject.mask
  );

  return formattedValueObject;
};
export function formatWithMask(props) {
  const { text, mask, obfuscationCharacter = "*" } = props;

  // make sure it'll not break with null or undefined inputs
  if (!text) return { masked: "", unmasked: "", obfuscated: "", mask };
  if (!mask)
    return {
      masked: text || "",
      unmasked: text || "",
      obfuscated: text || "",
    };

  const maskArray = typeof mask === "function" ? mask(text) : mask;

  let masked = "";
  let obfuscated = "";
  let unmasked = "";

  let maskCharIndex = 0;
  let valueCharIndex = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    // if mask is ended, break.
    if (maskCharIndex === maskArray.length) {
      break;
    }

    // if value is ended, break.
    if (valueCharIndex === text.length) {
      break;
    }

    const maskChar = maskArray[maskCharIndex];
    const valueChar = text[valueCharIndex];

    // value equals mask: add to masked result and advance on both mask and value indexes
    if (maskChar === valueChar) {
      masked += maskChar;
      obfuscated += maskChar;

      valueCharIndex += 1;
      maskCharIndex += 1;
      continue;
    }

    const unmaskedValueChar = text[valueCharIndex];

    if (typeof maskChar === "object") {
      // advance on value index
      valueCharIndex += 1;

      const shouldObsfucateChar = Array.isArray(maskChar);

      const maskCharRegex = Array.isArray(maskChar) ? maskChar[0] : maskChar;

      const matchRegex = RegExp(maskCharRegex).test(valueChar);

      // value match regex: add to masked and unmasked result and advance on mask index too
      if (matchRegex) {
        masked += valueChar;
        obfuscated += shouldObsfucateChar ? obfuscationCharacter : valueChar;
        unmasked += unmaskedValueChar;

        maskCharIndex += 1;
      }

      continue;
    } else {
      masked += maskChar;
      obfuscated += maskChar;

      maskCharIndex += 1;
      continue;
    }
  }

  return { masked, unmasked, obfuscated, mask };
}

export function cleanUpMask(value = "", character = "", replacers) {
  let formattedValue = "";
  if (typeof value === "string") {
    if (!replacers) {
      formattedValue = value?.replace(/[^0-9a-zA-Z]/g, character);
      return formattedValue;
    }

    if (!!value) {
      formattedValue = value;
      if (Array.isArray(replacers)) {
        replacers.forEach((replacer) => {
          const escapedReplacer = replacer.replace(
            /[.*+?^${}()|[\]\\]/g,
            "\\$&"
          );
          const regex = new RegExp(escapedReplacer, "g");
          formattedValue = formattedValue.replace(regex, character);
        });
      } else {
        formattedValue = formattedValue.replace(replacers, character);
      }
    }
  }

  return formattedValue || value;
}

export function createNumberMask(props) {
  const {
    delimiter = ".",
    precision = 2,
    prefix = [],
    separator = ",",
  } = props || {};

  return (value) => {
    const numericValue = value?.replace(/\D+/g, "") || "";

    const mask = numericValue.split("").map(() => /\d/);

    const shouldAddSeparatorOnMask = precision > 0 && !!separator;

    if (mask.length > precision && shouldAddSeparatorOnMask) {
      mask.splice(-precision, 0, separator);
    }

    const amountOfDelimiters =
      Math.ceil((numericValue.length - precision) / 3) - 1;

    if (delimiter) {
      for (let i = 0; i < amountOfDelimiters; i++) {
        const precisionOffset = precision;
        const separatorOffset = shouldAddSeparatorOnMask ? 1 : 0;
        const thousandOffset = 3 + (delimiter ? 1 : 0);
        const delimiterPosition =
          -precisionOffset - separatorOffset - i * thousandOffset - 3;

        mask.splice(delimiterPosition, 0, delimiter);
      }
    }

    return [...prefix, ...mask];
  };
}

export function verifyIfMaskMatch(maskedValue, mask) {
  let valid = false;

  mask?.forEach((pattern, index) => {
    if (pattern !== undefined && maskedValue[index]) {
      if (maskedValue.length !== mask.length) {
        valid = false;
      } else if (pattern === maskedValue[index]) {
        valid = true;
      } else if (pattern.test(maskedValue[index])) {
        valid = true;
      } else {
        valid = false;
      }
    }
  });

  return valid;
}

export function createPhoneNumberMask(value) {
  const newValue = cleanUpMask(value, "", ["+", " ", "(", ")", "-"]);

  if (newValue.length <= 11) {
    return [
      "(",
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  } else {
    return [
      "+",
      /\d/,
      /\d/,
      " (",
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
}
