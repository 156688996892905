import React from "react";
import {
  useAttendaces,
  usePagination,
  useTreatmentTypes,
} from "../../../service";
import { useForm } from "react-hook-form";
import Yup from "../../../config/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TablePagination, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { DialogMedium } from "../../../helper";
import TableFilter from "../../../components/Table/TableFilter";
import { useTheme } from "@emotion/react";
import { TextField, SwitchField } from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

export default function AttendanceTypeStep() {
  const theme = useTheme();
  const notify = useNotifier();
  const { createTypeAttendance, updateTypeAttendance } = useAttendaces();
  const { getTreatmentTypes } = useTreatmentTypes();

  const [selectedType, setSelectedType] = React.useState();
  const [editTypeDialog, setEditTypeDialog] = React.useState(false);
  const [limit, setLimit] = React.useState(5);
  const typeAttendanceMutation = useMutation(createTypeAttendance);
  const updateTypeAttendanceMutation = useMutation(updateTypeAttendance);

  const typeAttenanceQuery = useQuery(
    ["getTreatmentTypes"],
    getTreatmentTypes,
    {
      onError: (error) => {
        notify(error.message, "error");
      },
      initialData: [],
      retry: false,
    }
  );

  const { paginationInfo, handlePageChange } = usePagination(
    typeAttenanceQuery.data,
    limit
  );

  const columnsType = [
    {
      field: "number",
      name: "Codigo",
      type: "string",
    },
    {
      field: "describe",
      name: "Descrição",
      type: "string",
    },
    {
      field: "active",
      name: "Status",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
      alignInRow: "center",
      alignInHead: "center",
    },
  ];

  const handleCloseDialog = () => {
    setSelectedType({});
    setEditTypeDialog(false);
  };

  const validator = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      describe: "",
    },
    resolver: yupResolver(validator),
  });

  const updateAtendanceForm = useForm({
    defaultValues: {
      describe: selectedType?.describe,
      active: selectedType?.active,
    },
    resolver: yupResolver(validator),
  });

  const onSubmit = handleSubmit((data) => {
    typeAttendanceMutation.mutate(data, {
      onSuccess: (res) => {
        reset();
        notify(res.message, "success");
        typeAttenanceQuery.refetch();
      },
      onError: (err) => {
        notify(err.message, "error");
      },
    });
  });

  const onSubmitUpdate = updateAtendanceForm.handleSubmit((data) => {
    updateTypeAttendanceMutation.mutate(
      { id: selectedType.id, data },
      {
        onSuccess: (res) => {
          updateAtendanceForm.reset();
          notify(res.message, "success");
          typeAttenanceQuery.refetch();
          handleCloseDialog();
        },
        onError: (err) => {
          notify(err.message, "error");
        },
      }
    );
  });

  return (
    <>
      <DialogMedium
        open={editTypeDialog}
        handleClose={handleCloseDialog}
        title="Edição de tipo de atendimento"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField
              control={updateAtendanceForm.control}
              name="describe"
              label="Descrição"
            />
          </Grid>
          <Grid item xs={3}>
            <SwitchField
              control={updateAtendanceForm.control}
              name="active"
              label="Alterar Status"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={onSubmitUpdate}
              onLoad={updateTypeAttendanceMutation.isLoading}
            >
              Atualizar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Tipos de Atendimento
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items}
          columns={columnsType}
          loading={typeAttenanceQuery.isLoading}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit"]}
          shouldDisableFields={["id"]}
          actionHandleEdit={(_, item) => {
            setSelectedType(item);
            updateAtendanceForm.setValue("describe", item.describe);
            updateAtendanceForm.setValue("active", item.active);
            setEditTypeDialog(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Tipo de Atendimento
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            control={control}
            name={"describe"}
            label={"Descrição"}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={onSubmit}
            onLoad={typeAttendanceMutation.isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
