import { Box, Button } from "@mui/material"
import { TextField } from "../../../../../components/FormFields"
import useDoubleCheck from "../../../../../service/useDoubleCheck"
import { useNotifier } from "../../../../../hooks"
import { useMutation } from "react-query"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Yup from "../../../../../config/yup"

export default function DoubleCheckForm({handleClose,toEdit ={},medicationOnSiteID}){
    const notify = useNotifier()
    const {postDoubleCheck,patchDoubleCheck} = useDoubleCheck()
    const postMutation = useMutation({
      mutationFn: postDoubleCheck,
      mutationKey: "post-double-check"
    })
    const patchMutation = useMutation({
      mutationFn: patchDoubleCheck,
      mutationKey: "patch-double-check"
    })
    const form = useForm({
      defaultValues: {
        notes: toEdit.notes || '',
        status: 'filling',
        medication_on_site_id: medicationOnSiteID
      },
      resolver: yupResolver(Yup.object().shape({
        notes: Yup.string().required("Campo é requerido.")
      }))
    })

    const handleSubmit = form.handleSubmit((values) => {
      if(!!toEdit.id){
        patchMutation.mutate({id:toEdit.id,data:values},{
          onSuccess(data){
            notify(data.message)
            handleClose()
        },
          onError(data){
            notify(data.message,'error')
          }
        })
        return
      }
      postMutation.mutate(values,{
        onSuccess(data){
          notify(data.message)
          handleClose()
        },
        onError(data){
          notify(data.message,'error')
        }
      })
    })
    return (
        <Box display={'flex'} flexDirection={'column'} component={'form'} onSubmit={handleSubmit} rowGap={2}>
            <TextField control={form.control} name={'notes'} label={"Anotação"} multiline minRows={4} />
            <Box display={'flex'} columnGap={2}>
                <Button variant="contained" type="submit">Salvar</Button>
                <Button variant="contained" onClick={() => {
                  form.setValue('status', 'finished')
                  handleSubmit()
                }}>Finalizar</Button>
            </Box>
        </Box>
    )
  }