import instance from "../config/api";

export default function useAttendanceGroup(){
    const DPath = '/attendance-group'
    async function getAttendanceGroups(params) {
        try {
            const result = await instance.get(`${DPath}`, { params })
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    return {getAttendanceGroups}
}