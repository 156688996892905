import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { TextField } from "../../../components/FormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../config/yup";
import useAttachedDocuments from "../../../service/useAttachedDocuments";
import { useNotifier } from "../../../hooks";
import { useMutation } from "react-query";

export default function AttachedDocumentSuspendForm({ attachedDocumentId,handleClose }) {
  const form = useForm({
    defaultValues: {
      suspended_notes: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        suspended_notes: Yup.string().required(
          "Informe o motivo da suspensão."
        ),
      })
    ),
  });
  const notify = useNotifier()
  const { suspendAttachedDocument } = useAttachedDocuments();

  const suspendMutation = useMutation({
    mutationFn: suspendAttachedDocument,
    mutationKey: "suspende-attached-document",
  });
  const suspend = form.handleSubmit(({suspended_notes}) => {
    suspendMutation.mutate(
      { id: attachedDocumentId, suspended_notes },
      {
        onSuccess(data) {
          notify(data.message);
          handleClose()
        },
        onError(data) {
          notify(data.message, "error");
        },
      }
    );
  });
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <TextField
          control={form.control}
          name={"suspended_notes"}
          label={"Motivo da suspensão"}
          multiline
          minRows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={() => suspend()}>
          Suspender
        </Button>
      </Grid>
    </Grid>
  );
}
