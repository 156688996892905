import React from "react";
import { useField, useFormikContext } from "formik";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from "@mui/material";
function FormCheckbox({
  name,
  label,
  legend,
  customChange,
  checks,
  id,
  value,
  multiple,
  disabled,
  onClick,
  mt,
  ...props
}) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleOnClick = (event) => {
    if (typeof onClick === "function") {
      onClick(event);
    }
  };

  const handleChange = (e) => {
    const { checked } = e.target;

    if (multiple) {
      if (checked === true) {
        if (Array.isArray(field.value)) {
          const newValue = [...field.value, value];

          setFieldValue(name, newValue);

          if (customChange instanceof Function) {
            customChange(newValue);
          }
        } else {
          setFieldValue(name, [value]);
          if (customChange instanceof Function) {
            customChange([value]);
          }
        }
      } else {
        const newValue = field.value?.filter((item) => item !== value) || [];

        setFieldValue(name, newValue);

        if (customChange instanceof Function) {
          customChange(newValue);
        }
      }
    } else {
      if (value) {
        setFieldValue(name, checked ? value : null);
      } else {
        setFieldValue(name, checked);
      }

      if (customChange instanceof Function) {
        customChange(checked);
      }
    }
  };

  const settings = {
    ...field,
    value: field.value === undefined ? null : field.value,
    onClick: handleOnClick,
    onChange: handleChange,
  };

  const error = {};

  if (meta && meta.touched && meta.error) {
    error.error = true;
    error.helperText = meta.error;
  }

  return (
    <>
      {multiple}
      <FormControl
        component="fieldset"
        variant="outlined"
        {...error}
        sx={{
          mt: mt ? mt : "-20px",
          color: "#9AC0D8",
          fontWeight: "regular",
          fontFamily: "Montserrat",
        }}
      >
        <FormGroup size="small">
          <FormControlLabel
            control={<Checkbox {...settings} />}
            disabled={disabled}
            label={label}
            id={id}
            name={name}
            checked={
              multiple
                ? field.value?.some((item) => (item === value ? true : false))
                : field.value
            }
            sx={{
              whiteSpace: "nowrap",
              "& span": {
                color: (theme) => theme.palette.secondary.main,
                fontSize: "0.9em",
              },
            }}
          />
        </FormGroup>
        <FormHelperText>{error.helperText}</FormHelperText>
      </FormControl>
    </>
  );
}

export default FormCheckbox;
