import { Box, Button, TablePagination } from "@mui/material";
import { BoxContainer } from "../../components";
import TableFilter from "../../components/Table/TableFilter";
import { defaultColumns } from "./tableColumns";
import { useState } from "react";
import { DialogMedium, Loading } from "../../helper";
import RPAFeedback from "./RPAFeedback";
import { useMutation, useQuery } from "react-query";
import useRPA from "../../service/useRPA";
import { AttachMoney, Update } from "@mui/icons-material";
import { useCompany } from "../../service/useCompany";
import RPAStatusInfo from "./RPAStatusInfo";
import { openURL } from "../../pdfModels";
import { v4 as uuidv4 } from "uuid";
import BillingForm from "../Attendance/Billing/Form";
import { useLocalStorage } from "../../hooks";
import useNotifier from "../../hooks/useNotifier";
import useTableChecks from "../../hooks/useTableChecks";
import usePermissions from "../../config/usePermissions";

export default function RPA() {
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({});
  const [companies, setCompanies] = useState([]);
  const [showInFeedback, setShowInFeedback] = useState();
  const [openBilling, setOpenBilling] = useState(false);
  const [rpaToBilling, setRpaToBilling] = useState();
  const [userData] = useLocalStorage("user-data");
  const billingPermissions = usePermissions("/attendance/billing");

  const { getRPA, updateRPA, importRPA, exportRPAToSiga } = useRPA();
  const { getCompanies } = useCompany();
  const updateMutation = useMutation(updateRPA);

  const RPAData = useQuery(
    ["rpa-data", limit, page, filters],
    () => getRPA({ page, limit, ...filters, integration_system: true }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError(data) {
        notify(data.message, "error");
      },
    }
  );

  const { checkController, handleCheckAll, checksData } = useTableChecks(
    RPAData.data?.items ?? [],
    page,
    "id"
  );

  const importMutation = useMutation({
    mutationKey: "import-rpa",
    mutationFn: importRPA,
  });

  const processMutation = useMutation({
    mutationKey: "export-rpa",
    mutationFn: exportRPAToSiga,
  });

  function handleFilter(values) {
    setPage(0);
    setFilters(values);
  }

  function importData() {
    importMutation.mutate(filters, {
      onSuccess(data) {
        const fileUrl = URL.createObjectURL(
          new Blob([data.data], { type: "text/csv" })
        );
        const Content_Dispostition = data.headers?.["content-disposition"];
        const match = Content_Dispostition?.match(/filename\s*?="(.+)"/);

        let filename = `rpa_${uuidv4()}`; // Default filename

        if (match && match[1]) {
          filename = match[1];
        }
        openURL(fileUrl, filename);
      },
    });
  }

  useQuery(["company"], () => getCompanies({ limit: 75 }), {
    onSuccess: (data) => {
      let companiesFormatted = data?.items?.map((c) => ({
        label: c.name,
        value: c.id,
      }));

      companiesFormatted = [
        {
          label: "Todas",
          value: "*",
        },
        ...companiesFormatted,
      ];

      setCompanies(companiesFormatted);
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !userData?.company.master_id,
  });

  const UpdateRPAReprocessingStatus = (id, data) => {
    updateMutation.mutate(
      { id, data: data },
      {
        onSuccess: (data) => {
          notify(data?.message || "Operação realizada com sucesso", "success");
          RPAData.refetch();
        },
        onError: (data) => {
          notify(data.message, "error");
        },
      }
    );
  };

  const companyOptions = companies.length
    ? [
        {
          name: "Companhia",
          field: "company_id",
          type: "select",
          options: companies,
        },
      ]
    : [];

  const filterOptions = [
    {
      name: "Data inicial",
      type: "date",
      field: "initial_date",
    },
    {
      name: "Data final",
      type: "date",
      field: "final_date",
    },
    {
      name: "Motivo",
      type: "text",
      field: "note",
    },
    {
      name: "Exportado ( SIGA )",
      type: "select",
      field: "confirmed",
      options: [
        {
          label: "Sim",
          value: true,
        },
        {
          label: "Não",
          value: false,
        },
      ],
    },
    // {
    //   name: "Contas Aprovadas",
    //   type: "select",
    //   field: "confirmed",
    //   options: [
    //     {
    //       label: "Sim",
    //       value: true,
    //     },
    //     {
    //       label: "Não",
    //       value: false,
    //     },
    //   ],
    // },
    {
      name: "Reprocessamento",
      type: "select",
      field: "reprocessing_status",
      options: [
        {
          label: "Reprocessado",
          value: "success",
        },
        {
          label: "Com falha",
          value: "failed",
        },
        {
          label: "Para reprocessar",
          value: "reprocess",
        },
        {
          label: "Não reprocessado",
          value: "null",
        },
      ],
    },
    ...companyOptions,
  ];

  function closeBilling() {
    setOpenBilling(false);
    setRpaToBilling(undefined);
    RPAData.refetch();
  }

  const processChecked = checkController.handleSubmit((value) => {
    processMutation.mutate(value.checksData, {
      onSuccess(data) {
        RPAData.refetch();
        checkController.reset();
        notify(data.message);
      },
      onError(err) {
        notify(err.message, "error");
      },
    });
  });

  return (
    <BoxContainer title={`RPA - Produção`}>
      {RPAData.isLoading || processMutation.isLoading ? (
        <Loading maxWidth={"100%"} backgroundColor={"rgba(255,255,255,0.7)"} />
      ) : null}
      <DialogMedium
        open={!!showInFeedback}
        handleClose={() => setShowInFeedback(undefined)}
        fullWidth
        title={"Detalhes RPA"}
      >
        <RPAFeedback data={showInFeedback} />
      </DialogMedium>
      <DialogMedium
        maxWidth={"xl"}
        open={!!openBilling}
        handleClose={closeBilling}
        title={"Adicionar Atendimento"}
      >
        <BillingForm
          closeForm={closeBilling}
          rpaId={rpaToBilling?.id}
          isClassification={!!rpaToBilling?.attendance?.risk_classification_id}
        />
      </DialogMedium>
      <RPAStatusInfo />
      <TableFilter
        columns={defaultColumns}
        data={RPAData.data?.items}
        rowStatus={(i) =>
          i.processed_manually
            ? "attention"
            : i.reprocessing_status === "failed"
            ? "disabled"
            : i.reprocessing_status === "success" && i.confirmed
            ? "active"
            : i.reprocessing_status === "reprocess"
            ? "info"
            : !i.confirmed
            ? "warning"
            : ""
        }
        handleFilter={handleFilter}
        othersFilter={filterOptions}
        actions
        actionsTypes={["view", "suspend", "finish", "response", "checkbox"]}
        checkboxActionController={checkController}
        handleCheckAll={handleCheckAll}
        actionUniqueIdentifier={"id"}
        customizeActions={{
          view: {
            title: "Ver detalhes",
          },
          finish: {
            title: "Realizado Manualmente",
            verb: "Marcar como realizado manualmente",
          },
          suspend: {
            title: "Reprocessar / Não Reprocessar",
            icon: <Update />,
            verb: "Reprocessar / Não Reprocessar",
          },
          response: {
            title: "Registro de faturamento",
            icon: <AttachMoney />,
          },
        }}
        actionHandleResponse={(_, row) => {
          setOpenBilling(true);
          setRpaToBilling(row);
        }}
        actionHandleView={(_, row) => {
          setShowInFeedback(row);
        }}
        actionHandleFinish={(_, row) => {
          UpdateRPAReprocessingStatus(row.id, {
            processed_manually: !row.processed_manually,
          });
        }}
        actionHandleSuspend={(_, row) => {
          const statuses = {
            reprocess: null,
            failed: "reprocess",
            [null]: "reprocess",
          };
          const newStatus = statuses[row.reprocessing_status];
          UpdateRPAReprocessingStatus(row.id, {
            reprocessing_status: newStatus,
          });
        }}
        disableActions={(_, action) => {
          return (
            (["suspend", "finish", "response", "checkbox"].includes(action) &&
              _.confirmed) ||
            (["suspend", "finish", "response"].includes(action) &&
              _.reprocessing_status === "success") ||
            (["view"].includes(action) && !_.notes) ||
            (["finish"].includes(action) &&
              _.reprocessing_status === "reprocess") ||
            (["suspend", "response"].includes(action) &&
              _.processed_manually) ||
            (["checkbox", "response"].includes(action) &&
              !billingPermissions.create)
          );
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box display={"flex"} columnGap={1}>
          <Button
            disabled={
              importMutation.isLoading ||
              importMutation.isFetching ||
              !RPAData.data
            }
            variant="contained"
            onClick={() => importData()}
          >
            Exportar Registros
          </Button>
          <Button
            disabled={
              !billingPermissions.create ||
              !checksData.length ||
              processMutation.isLoading
            }
            onClick={() => processChecked()}
            variant="contained"
          >
            Processar marcados
          </Button>
        </Box>
        <TablePagination
          count={RPAData.data?.totalItems || 0}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimit(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </BoxContainer>
  );
}
