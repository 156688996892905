import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/patients/vaccines";

export default function usePatientBatch() {
  async function getPatientBatchs(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postPatientBatchs(objectValues) {
    try {
      const response = await instance.post(path, objectValues);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updatePatientBatchs(id, data) {
    try {
      const response = await instance.put(`${path}/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  return {
    getPatientBatchs,
    postPatientBatchs,
    updatePatientBatchs,
  };
}
