import React from "react";
import Yup from "../../../config/yup";
import { Grid, Box } from "@mui/material";
import { againstReferencePDF } from "../../../pdfModels";
import openURL from "../../../pdfModels/openURL";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AutocompleteField,
  Button,
  TextField,
} from "../../../components/FormFields";

const AgainstReferenceView = ({ type = "view", reference }) => {
  const sector = reference?.sector ? reference.sector : "Não especificado";
  const initialValues = {
    justification: reference?.justification,
    responsible: reference?.requestor_name,
    attachment: null,
    response: reference?.response,
    patient: reference.patient?.name,
    requested_specialty: reference.requested_specialty,
    sectorEspecific: sector,
    cids: reference?.cids || [],
  };

  const validations = Yup.object().shape({
    justification: Yup.string().required("É requerido"),
    responsible: Yup.string().required("É requerido").nullable(),
    response: Yup.string().required("É requerido").nullable(),
  });

  const { control, handleSubmit, watch, reset } = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const cidOption = (cid) =>
    cid.description ? `${cid.id} - ${cid.description}` : "";

  const handlePrint = () => {
    if (reference?.signature) {
      openURL(reference.signature.result);
      return;
    }

    againstReferencePDF(
      reference,
      reference.patient,
      reference?.cids || []
    ).open();
  };
  const onSubmit = () => {
    reset();
  };
  const [
    patient,
    responsible,
    requested_specialty,
    justification,
    cids,
    response,
  ] = watch([
    "patient",
    "responsible",
    "requested_specialty",
    "employee_type",
    "cids",
    "response",
  ]);
  return (
    <>
      <Grid component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} marginTop="0.5rem">
          <Grid item xs={4}>
            <TextField
              control={control}
              name="patient"
              label="Paciente"
              required
              disabled={type === "view"}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              control={control}
              name="responsible"
              label="Realizada por"
              required
              disabled={type === "view"}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              control={control}
              name="requested_specialty"
              label="Especialidade Requisitada"
              required
              disabled={type === "view"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              control={control}
              multiline
              minRows={5}
              maxRows={5}
              name="justification"
              label="Justificativa"
              required
              disabled={type === "view"}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              control={control}
              name="requested_specialty"
              label="Setor"
              required
              disabled={type === "view"}
            />
          </Grid>
          <Grid item xs={8}>
            <AutocompleteField
              control={control}
              optionLabelKey="description"
              options={reference?.cids || []}
              autoCompleteProps={{ getOptionLabel: cidOption }}
              multiple={true}
              label={"Cids:"}
              name="cids"
              disabled={type === "view"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              minRows={5}
              maxRows={5}
              name="response"
              label="Resposta da Referência / Contrarreferência"
              required
              disabled={type === "view"}
              control={control}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" margin="1.5rem auto">
          {type === "view" ? (
            <Button onClick={handlePrint}>Imprimir</Button>
          ) : (
            <Button
              type="submit"
              disabled={
                !!patient ||
                !!responsible ||
                !!requested_specialty ||
                !!justification ||
                !!cids ||
                !!response
              }
            >
              Salvar
            </Button>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default AgainstReferenceView;
