import { Tabs } from "../../../components";
import usePermissions from "../../../config/usePermissions";
import HasNoPermission from "../../../components/RequireAuth/HasNoPermission";
import InterventionList from "./InterventionList";
import InterventionForm from "./InterventionForm";
import Anesthesia from "./Anesthesia";
import { Badge } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import ProsthesisCommand from "./ProsthesisCommand";

export default function Intervention({ onClose, odonto, ceo, attendance }) {
  const procedurePermissions = usePermissions("/soap/interventions");
  const { interventions, anesthesia,prosthesisCommand } = useContext(AppContext);

  procedurePermissions.create = true;
  procedurePermissions.read = true;

  const lengthInterventions = interventions.length;
  const lengthAnesthesia =
    (anesthesia.sterilization?.length || 0) + (anesthesia.types?.length || 0);

  const tabs = [
    {
      active: procedurePermissions.create && (odonto || ceo),
      label: "Anestesia ",
      content: <Anesthesia handleSave={onClose} />,
      icon: (
        <Badge
          color="secondary"
          overlap="circular"
          badgeContent={lengthAnesthesia}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
      ),
    },
    {
      active: procedurePermissions.create,
      label: "Intervenções",
      content: (
        <InterventionForm attendance={attendance} handleSave={onClose} />
      ),
      icon: (
        <Badge
          color="secondary"
          overlap="circular"
          badgeContent={lengthInterventions}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
      ),
    },
    {
      active: odonto,
      label: "Comanda Prótese",
      content: (
        <ProsthesisCommand handleSave={onClose} />
      ),
      icon: (
        <Badge
          color="secondary"
          overlap="circular"
          badgeContent={prosthesisCommand}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
      ),
    },
    {
      active: procedurePermissions.read,
      label: "Histórico de Intervenções",
      content: <InterventionList />,
    },
  ];

  return tabs.filter((i) => i.active).length ? (
    <Tabs tabs={tabs.filter((i) => i.active)} />
  ) : (
    <HasNoPermission position="relative" />
  );
}
