import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

const path = "external-services/tpanels/integration-summaries";

export default function useCockPitSiga() {
    async function getCockPitSiga(params = {}) {
        try {
            const result = await instance.get(path, { params: urlQuery(params) });
            return result.data;
          } catch (error) {
            throw new Error(error.response.data.message);
          }
    }

    const importCockPitSiga = async (params = {}) => {
        try {
            const response = await instance.get(path, {
              responseType: 'arraybuffer',
              params: urlQuery({ ...params, export_file: true })
            });
            return response
          } catch (error) {
            throw new Error(error.response.data.message);
          }
    };

    const updateCockPitSiga = async ({ id, data }) => {
        try {
            const response = await instance.patch(`${path}/${id}`, data);
            return response
          } catch (error) {
            throw new Error(error.response.data.message);
          }
    };

    return {
        getCockPitSiga,
        importCockPitSiga,
        updateCockPitSiga,
    };
}