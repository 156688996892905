import { Square } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { ColorInfoLabels } from "../../components";
const statusColor = [
  {
    text: "Processado",
    color: "rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Erro no processamento",
    color: "rgba(255, 223, 186, 1)",
  },
  {
    text: "Para reprocessar",
    color: "rgba(187, 222, 251, 0.6)",
  },
  {
    text: "Reprocessado",
    color: "rgba(0, 216, 0, 0.1)",
  },
  {
    text: "Erro no reprocessamento",
    color: "rgba(216, 0, 0, 0.1)",
  },
  {
    text: "Realizado manualmente.",
    color: "rgba(255, 246, 168, 0.6)",
  },
];

export default function RPAStatusInfo() {
  return <ColorInfoLabels labels={statusColor} />;
}
