import React from "react";
import { BoxContainer, Tabs } from "../../../components";
import OperationalFormPeriod from "./OperationalFormPeriod";
import EmployeeFormPeriod from "./EmployeeFormPeriod";
import ServiceFormPeriod from "./ServiceFormPeriod";
import ScheduledPatientsFormPeriod from "./ScheduledPatientsFormPeriod";
import MissingPatientsFormPeriod from "./MissingPatientsFormPeriod";
import DiarrheaReport from "./DiarrheaReport";

export default function ReportOperational() {
  const tabs = [
    { label: "Atendimento Por Período", content: <OperationalFormPeriod /> },
    { label: "Atendimento Por Profissional", content: <EmployeeFormPeriod /> },
    { label: "Atendimento Por Serviço", content: <ServiceFormPeriod /> },
    { label: "Pacientes Agendados", content: <ScheduledPatientsFormPeriod /> },
    { label: "Pacientes Faltosos", content: <MissingPatientsFormPeriod /> }
    // { label: "Diarréia", content: <DiarrheaReport /> },
  ];
  return (
    <BoxContainer sx={{ width: "100%" }} title={"Relatórios operacionais"}>
      <Tabs tabs={tabs} />
    </BoxContainer>
  );
}
