import React from "react";
import { useReducer } from "react";

export const SignaturePasswordContext = React.createContext();
export const SHOW_SIGNATURE_DIALOG = "SHOW_SIGNATURE_DIALOG";
export const HIDE_SIGNATURE_DIALOG = "HIDE_SIGNATURE_DIALOG";

export const initialState = {
  show: false,
  validToken: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SIGNATURE_DIALOG:
      return {
        show: true,
      };
    case HIDE_SIGNATURE_DIALOG:
      return initialState;
    default:
      return initialState;
  }
};

export const SignaturePasswordContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SignaturePasswordContext.Provider value={[state, dispatch]}>
      {children}
    </SignaturePasswordContext.Provider>
  );
};
