import React from "react";
import { Form, Formik } from "formik";
import Yup from "../../../config/yup";
import { Grid } from "@mui/material";
import {
  FormRadio,
  FormTextField,
  SubmitButton,
} from "../../../components/Form";
import { useBatch } from "../../../service";
import FormDate from "../../../components/Form/FormDate";
import { format, parseISO } from "date-fns";
import { useVaccine } from "../../../service";
import FormSelectFieldWithPaginate from "../../../components/Form/FormSelectWithPaginate";
import useNotifier from "../../../hooks/useNotifier";

function ImmunizingEdit({ unicImmunizing, handleClose }) {
  const { updateBatches } = useBatch();
  const { getVaccines } = useVaccine();
  const notify = useNotifier();
  const initialValues = {
    code: unicImmunizing.code,
    expiration_date: parseISO(unicImmunizing.expiration_date),
    amount: unicImmunizing.amount,
    id_manufacturer: unicImmunizing.manufacturer.id,
    manufacturing_date: parseISO(unicImmunizing.manufacturing_date),
    id_vaccine: unicImmunizing.vaccine.id,
    vaccine_name: unicImmunizing.vaccine.describe,
    active: unicImmunizing.active,
    manufacturer_name: unicImmunizing.manufacturer.describe,
    bottles: unicImmunizing.bottles,
  };
  const validations = Yup.object().shape({
    vaccine_name: Yup.string().required("É requerido").nullable(),
    manufacturer_name: Yup.string().required("É requerido").nullable(),
    code: Yup.string().required("É requerido").typeError("Código Inválido"),
    manufacturing_date: Yup.date()
      .required("É requerido")
      .typeError("Data Inválida"),
    expiration_date: Yup.date()
      .required("É requerido")
      .typeError("Data Inválida"),
  });

  const search = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];

  async function handleSubmit(values) {
    const batchObject = {
      id: unicImmunizing.id,
      code: values.code,
      expiration_date: format(new Date(values.expiration_date), "yyyy-MM-dd"),
      id_manufacturer: values.id_manufacturer,
      id_vaccine: values.id_vaccine,
      manufacturing_date: format(
        new Date(values.manufacturing_date),
        "yyyy-MM-dd"
      ),
      amount: values.amount,
      active: values.active,
      bottles: values.bottles,
    };

    try {
      const response = await updateBatches(unicImmunizing.id, batchObject);
      if (response) {
        notify("Lote atualizado com Sucesso!", "success");
      }
    } catch (err) {
      notify(err.message, "error");
    }
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
      >
        {({}) => (
          <Form>
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormDate
                    item
                    name="manufacturing_date"
                    label="Data De Fabricação"
                    required
                    onBlur={(e) => {}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormDate
                    item
                    name="expiration_date"
                    label="Data De Validade"
                    required
                    minDate={new Date()}
                    onBlur={(e) => {}}
                  />
                </Grid>
                <Grid item sx={{ marginBottom: 1 }} xs={4}>
                  <FormRadio
                    name="active"
                    radios={search}
                    row
                    legend="Status..."
                    required
                    keys={["label", "value"]}
                  />
                </Grid>
                <Grid xs={3} item>
                  <FormTextField name="code" label="Lote" required />
                </Grid>
                <Grid xs={3} item>
                  <FormTextField
                    name="bottles"
                    label="Qant. de Frascos"
                    required
                  />
                </Grid>
                <Grid xs={4} item>
                  <FormTextField
                    name="amount"
                    label="Quantidade de Doses"
                    required
                  />
                </Grid>
                <Grid xs={6} sx={{ marginTop: 2 }} item>
                  <FormTextField
                    name="manufacturer_name"
                    label="Fabricante"
                    required
                  />
                </Grid>
                <Grid xs={6} sx={{ marginTop: 2 }} item>
                  <FormSelectFieldWithPaginate
                    service={getVaccines}
                    name="vaccine"
                    fields="describe"
                    label="Imunobiológico"
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "right",
                marginTop: 20,
                marginBottom: 4,
              }}
            >
              <SubmitButton>Editar Cadastro</SubmitButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ImmunizingEdit;
