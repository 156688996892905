import { Button, TextField, InputAdornment, useTheme } from "@mui/material";
import { useField } from "formik";
import React from "react";
import InputMask from "react-input-mask";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { FaLock } from "react-icons/fa";
const FormPassword = ({ name, mask, ...props }) => {
  const [field, meta] = useField(name);
  const theme = useTheme();
  // Show/Hide password
  const [passwordShown, setPasswordShown] = React.useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const icon = passwordShown ? (
    <FiEyeOff style={{ width: "20px", height: "20px" }} />
  ) : (
    <FiEye style={{ width: "20px", height: "20px" }} />
  );

  //

  const settings = {
    ...field,
    mask,
    ...props,
    size: "medium",
    fullWidth: true,
    variant: "outlined",
    color: "secondary",
    placeholder: "Digite sua senha...",
    InputLabelProps: { shrink: true },
  };
  if (meta && meta.touched && meta.error) {
    settings.error = true;
    settings.helperText = meta.error;
  }
  return (
    <>
      {mask ? (
        <InputMask {...settings}>{() => <TextField {...settings} />}</InputMask>
      ) : (
        <TextField
          type={passwordShown ? "text" : "password"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaLock style={{ color: theme.palette.primary.main }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={togglePassword}>{icon}</Button>
              </InputAdornment>
            ),
          }}
          {...settings}
        />
      )}
    </>
  );
};

export default FormPassword;
