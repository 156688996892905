import React from "react";
import TableFilter from "../../../components/Table/TableFilter";
import { useRegulation } from "../../../service";
import { format, parseISO } from "date-fns";
import { useMutation, useQuery } from "react-query";
import { Box, TablePagination } from "@mui/material";
import { DialogMedium } from "../../../helper";
import RegulationViewModal from "./RegulationViewModal";
import useNotifier from "../../../hooks/useNotifier";
import limitName from "../../../utils/limitName";

export default function RegulationConfirmed() {
  const [filter, setFilter] = React.useState();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [schedulings, setSchedulings] = React.useState();
  const [openModalView, setOpenModalView] = React.useState(false);
  const [scheduleSected, setScheduleSected] = React.useState();

  const { getRegulations, cancelRegulation, confirmRegulation } =
    useRegulation();
  const notify = useNotifier();

  const cancelRegulationMutation = useMutation(cancelRegulation);
  const confirmRegulationMutation = useMutation(confirmRegulation);

  const tableColumns = [
    {
      name: "Nome",
      field: "patient",
      type: "text",
      use: (value, row) =>
        limitName(
          row.patient?.social_prioritize ? row.patient.social_name : value.name,
          3,
          "Não informado"
        ),
    },
    {
      name: "Data de Nascimento",
      field: "birth_date",
      use: (date) =>
        date?.birth_date
          ? format(parseISO(date?.birth_date), "dd/MM/yyyy")
          : "Não informado",
      type: "date",
    },
    {
      name: "Especialidade/Procedimento",
      field: "specialty",
      type: "text",
      use: (data, row) =>
        data?.describe
          ? data?.describe
          : row?.procedure?.name
          ? row?.procedure?.name
          : "Não informado",
    },
    {
      name: "Data de Solicitação",
      field: "createdAt",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado",
      type: "date",
    },
    {
      name: "Data de Agendamento",
      field: "scheduling_date",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado",
      type: "date",
    },
    {
      name: "Agenda",
      field: "schedule_type",
      use: (data) => (data ? data.toUpperCase() : "Não informado"),
      type: "text",
    },
    // {
    //   name: "Dias de espera",
    //   field: "createdAt",
    //   use: (date) =>
    //     date ? differenceInDays(new Date(), parseISO(date)) : "Não informado",
    //   type: "text",
    // },
  ];

  const regulationsQuery = useQuery(
    ["regulations", page, limit, filter],
    () =>
      getRegulations({
        page: page,
        limit: limit,
        status: "scheduled",
        ...filter,
      }),
    {
      onSuccess: (response) => {
        setSchedulings(response.items);
        setCount(response.totalItems);
      },
      onError: (error) => {
        setSchedulings([]);
      },
      retry: false,
    }
  );

  function handleFilter(filter) {
    setPage(0);
    setFilter((previous) => ({ ...previous, ...filter }));
  }

  function handleCloseModal() {
    setOpenModalView(false);
  }

  return (
    <div>
      <DialogMedium
        fullWidth
        open={openModalView}
        title={"Agendamento"}
        handleClose={handleCloseModal}
        children={
          <RegulationViewModal
            schedule={scheduleSected}
            handleClose={handleCloseModal}
          />
        }
      />

      <TableFilter
        data={schedulings}
        columns={tableColumns}
        loading={regulationsQuery.isLoading}
        actions
        handleFilter={handleFilter}
        actionUniqueIdentifier={"id"}
        actionsTypes={["view", "suspend", "finish"]}
        shouldDisableFields={["id"]}
        actionHandleSuspend={(item) => {
          cancelRegulationMutation.mutate(item, {
            onSuccess: () => {
              regulationsQuery.refetch();
              notify("Agendamento suspenso", "success");
            },
            onError: (error) => {
              notify(error.message, "error");
            },
          });
        }}
        actionHandleView={(schedule) => {
          setScheduleSected(
            schedulings.filter((item) => item.id === schedule)[0]
          );
          setOpenModalView(true);
        }}
        actionHandleFinish={(item) => {
          confirmRegulationMutation.mutate(item, {
            onSuccess: () => {
              regulationsQuery.refetch();
              notify("Agendamento confirmado", "success");
            },
            onError: (error) => {
              notify(error.message, "error");
            },
          });
        }}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={count}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </div>
  );
}
