import { Box, Grid, TablePagination } from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useProvider, useRoles } from "../../../../service";
import useNotifier from "../../../../hooks/useNotifier";
import TableFilter from "../../../../components/Table/TableFilter";
import {
  Button,
  PaginatedAutocompleteField,
} from "../../../../components/FormFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../../config/yup";

const UserRolesSettings = ({ employee }) => {
  const notify = useNotifier();
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  const validations = Yup.object().shape({
    role: Yup.object().nullable().required("Selecione um papél."),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      role: null,
    },
    resolver: yupResolver(validations),
  });

  const { getRoles, postRoleEmployee, deleteRoleEmployee } = useRoles();
  const { getProviderRoles } = useProvider();

  const insertMutation = useMutation(postRoleEmployee);
  const deleteMutation = useMutation(deleteRoleEmployee);

  const getProviderRolesQuery = useQuery(
    ["provide-roles", limit, page, employee],
    getRolesData,
    {
      initialData: {
        totalItems: 0,
        items: [],
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const tableColumns = [{ name: "Papel", field: "name" }];

  async function getRolesData() {
    if (employee) {
      return getProviderRoles(employee?.id, { limit, page, active: true });
    }
  }

  function onSubmit({ role: { id } }, { resetForm }) {
    insertMutation.mutate(
      { roleID: id, insertion: { employee_id: employee.id } },
      {
        onSuccess: (success) => {
          notify(success.message, "success");
          getProviderRolesQuery.refetch();
          resetForm();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  }

  function handleDelete(role) {
    deleteMutation.mutate(
      { roleID: role, deletion: employee.id },
      {
        onSuccess: (success) => {
          notify(success.message, "success");
          getProviderRolesQuery.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  }

  return (
    <Grid xs={12} container spacing={2}>
      <Grid
        container
        xs={12}
        gap={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid xs={6} item>
          <PaginatedAutocompleteField
            name="role"
            label="Papel"
            control={control}
            filterKey="name"
            optionLabelKey="name"
            optionCompareKey="id"
            query="get-roles"
            service={(params) => getRoles({ active: true, ...params })}
          />
        </Grid>
        <Grid xs={2} item>
          <Button type="submit">Adicionar</Button>
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <Box>
          <TableFilter
            data={getProviderRolesQuery.data?.items ?? []}
            columns={tableColumns}
            actions
            actionUniqueIdentifier={"id"}
            actionsTypes={["delete"]}
            shouldDisableFields={["id"]}
            actionHandleDelete={handleDelete}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <TablePagination
              count={getProviderRolesQuery.data?.totalItems ?? 0}
              page={page}
              onPageChange={(_, newPage) => {
                setPage(newPage);
              }}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 20, 75]}
              onRowsPerPageChange={({ target }) => {
                setLimit(parseInt(target.value, 10));
                setPage(0);
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserRolesSettings;
