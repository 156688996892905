import React, { useState } from "react";
import useDocuments from "../../../service/useDocuments";
import { Form, Formik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { SubmitButton } from "../../../components";
import Yup from "../../../config/yup";
import { FormSelectWithPaginate } from "../../../components/Form";
import useNotifier from "../../../hooks/useNotifier";
import { Grid } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";

const SettingsEditDocumentsTypes = ({ documentType }) => {
  const [documents, setDocuments] = useState(documentType?.tdocs_documents);
  const [documentsToDelete, setDocumentsToDelete] = useState([]);
  const queryClient = useQueryClient();
  const notify = useNotifier();

  const { getExternalDocuments, postTypeDocuments, deleteTypeDocuments } =
    useDocuments();

  const typeDocumentsMutation = useMutation(postTypeDocuments);
  const deletetypeDocumentsMutation = useMutation(deleteTypeDocuments);

  function removeTypeDocuments() {
    if (documentsToDelete.length) {
      const deleteData = documentsToDelete.map(({ id }) => id);
      deletetypeDocumentsMutation.mutate(
        {
          typeId: documentType.id,
          data: { tdocs_documents: deleteData },
        },
        {
          onSuccess: (success) => {
            notify(success.message, "success");
            queryClient.invalidateQueries("documents-types");
          },
          onError(error) {
            notify(error.message, "error");
          },
        }
      );
    }
  }

  function handleSubmit() {
    const documentsData = documents.filter((document) => !document.id);

    if (documentsData.length) {
      typeDocumentsMutation.mutate(
        { typeId: documentType.id, data: { tdocs_documents: documentsData } },
        {
          onSuccess: (success) => {
            notify(success.message, "success");

            queryClient.invalidateQueries("documents-types");
            removeTypeDocuments();
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    } else {
      removeTypeDocuments();
    }
  }

  function addDocument({ document }, { resetForm }) {
    const documentDeleted = documentsToDelete.find(
      ({ external_document_id }) => {
        return external_document_id === document._id;
      }
    );

    if (documentDeleted) {
      const filteredDocumentsToDelete = documentsToDelete.filter(
        ({ external_document_id }) => {
          return documentDeleted.external_document_id !== external_document_id;
        }
      );

      setDocumentsToDelete(filteredDocumentsToDelete);
      setDocuments((documents) => [...documents, documentDeleted]);
    } else {
      setDocuments((documents) => [
        ...documents,
        {
          ...document,
          external_document_id: document._id,
        },
      ]);
    }

    resetForm();
  }

  function handleDelete(id) {
    let documentToDelete;
    const filteredDocuments = [];

    documents.forEach((document) => {
      if (document.external_document_id === id) {
        documentToDelete = document;
      } else {
        filteredDocuments.push(document);
      }
    });

    if (documentToDelete.id) {
      setDocumentsToDelete((documents) => [...documents, documentToDelete]);
    }

    setDocuments(filteredDocuments);
  }

  const initialValues = {
    document: null,
  };

  const validations = Yup.object().shape({
    document: Yup.object().required("É requerido").nullable(),
  });

  const tableColumns = [{ name: "Nome", field: "name" }];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validations}
        onSubmit={addDocument}
      >
        {() => (
          <Form>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={10}>
                <FormSelectWithPaginate
                  name="document"
                  label="Documento"
                  fields="name"
                  service={async (params) => {
                    const documentOptions = await getExternalDocuments(params);

                    documentOptions.items = documentOptions.items.filter(
                      (option) =>
                        documents?.every(
                          (document) =>
                            document.external_document_id !== option._id
                        )
                    );

                    return documentOptions;
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <SubmitButton loading={false}>Adicionar</SubmitButton>
              </Grid>
              <Grid item xs={12}>
                <TableFilter
                  data={documents}
                  columns={tableColumns}
                  actions
                  actionsTypes={["delete"]}
                  actionHandleDelete={(_, item) =>
                    handleDelete(item.external_document_id)
                  }
                  confirmMessage="Você realmente deseja *action* este plano de cuidado?"
                />
              </Grid>
              <Grid item xs={12}>
                <SubmitButton
                  loading={
                    typeDocumentsMutation.isLoading ||
                    deletetypeDocumentsMutation.isLoading
                  }
                  onClick={handleSubmit}
                >
                  Salvar
                </SubmitButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SettingsEditDocumentsTypes;
