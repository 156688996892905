function dateTimeFormater(time) {
  if (!(time instanceof Object)) return;
  const object = Object.entries(time).reverse();
  let dateString = "";

  object.forEach(([key, value], index, array) => {
    const existsNextValue = index < array.length - 1;
    const delimiter = ", ";

    switch (key) {
      case "years":
        dateString +=
          value +
          ` ${value > 1 && value > 0 ? "anos" : "ano"}${
            existsNextValue ? delimiter : ""
          }`;
        break;
      case "months":
        dateString +=
          value +
          ` ${value > 1 && value > 0 ? "meses" : "mês"}${
            existsNextValue ? delimiter : ""
          }`;
        break;
      case "weeks":
        dateString +=
          value +
          ` ${value > 1 && value > 0 ? "semanas" : "semana"}${
            existsNextValue ? delimiter : ""
          }`;
        break;
      case "days":
        dateString +=
          value +
          ` ${value > 1 && value > 0 ? "dias" : "dia"}${
            existsNextValue ? delimiter : ""
          }`;
        break;
      default:
        dateString += "";
    }
  });

  return dateString;
}

export default dateTimeFormater;
