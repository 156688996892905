import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import Helmet from "react-helmet";
import { FormTextField } from "../../../components";
import { SubmitButton } from "../../../components/Form";
import { TITLE_NAME } from "../../../config/config";
import Yup from "../../../config/yup";

import { useHealthUnit } from "../../../service";
import useNotifier from "../../../hooks/useNotifier";

function UnitHealthForm({ handleClose }) {
  const notify = useNotifier();
  const { postHealthUnits } = useHealthUnit();

  const initialValues = {
    describe: "",
    cnes: null,
    code: null,
  };

  const validations = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
    cnes: Yup.number()
      .nullable()
      .required("É requerido")
      .typeError("Número Inválido"),
  });

  async function handleSubmit(values) {
    const objectValues = {
      describe: values.describe,
      cnes: values.cnes,
      code: values.code,
    };

    try {
      const response = await postHealthUnits(objectValues);
      if (response) {
        notify("Unidade Básica cadastrada com Sucesso!", "success");
      }
    } catch (err) {
      notify(err.message, "error");
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Vacina</title>
      </Helmet>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
      >
        {({}) => (
          <Form>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid xs={6} item>
                <FormTextField
                  name="code"
                  label="Cód. Unidade Básica"
                  required
                />
              </Grid>
              <Grid xs={6} item>
                <FormTextField name="describe" label="Descrição" required />
              </Grid>
              <Grid xs={6} item>
                <FormTextField
                  name="cnes"
                  label="Cadastro Nacional de Estabelecimentos de Saúde."
                  required
                />
              </Grid>
            </Grid>
            <Grid
              sx={{ display: "flex", justifyContent: "right", marginTop: 1 }}
            >
              <SubmitButton>Salvar Cadastro</SubmitButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default UnitHealthForm;
