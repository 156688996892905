import { Box } from "@mui/system";
import { format, parseISO } from "date-fns";
import React, { useEffect } from "react";
import CustomTable from "../../../components/Table/PaginatedTable";
import {
  attendanceStatementPDF,
  followUpStatementPDF,
  medicalStatementPDF,
  medicalReportPDF,
  medicalOpinionPDF,
  maternityLeavePDF,
  openURL,
} from "../../../pdfModels";

export default function DeclarationsTable({ documents, patient }) {
  const columns = [
    { name: "Atendimento" },
    { name: "Data de emissão" },
    { name: "Profissional" },
    { name: "Tipo do documento" },
  ];

  const declarationNames = {
    Atestado: "Atestado",
    Acompanhamento: "Acompanhamento",
    Comparecimento: "Comparecimento",
    Relatorio: "Relatório",
    Laudo: "Laudo Médico",
    AtestadoMaternidade: "Atestado Maternidade",
  };

  const [data, setData] = React.useState([]);

  const handlePrint = (item) => {
    if (item.signed) return openURL(item.signature.result);
    switch (item.type) {
      case "Comparecimento":
        attendanceStatementPDF(item, patient).open();
        break;
      case "Atestado":
        medicalStatementPDF(item, patient).open();
        break;
      case "Acompanhamento":
        followUpStatementPDF(item, patient).open();
        break;
      case "Relatorio":
        medicalReportPDF(item, patient).open();
        break;
      case "Laudo":
        medicalOpinionPDF(item, patient).open();
        break;
      case "AtestadoMaternidade":
        maternityLeavePDF(item, patient).open();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setData(
      documents?.map(({ attendance, createdAt, type, id }) => {
        return {
          attendance_number: attendance?.number,
          createdAt: format(parseISO(createdAt), "dd/MM/yyyy"),
          employee_name: attendance?.employee?.name,
          name: declarationNames[type],
          type,
          id,
        };
      })
    );
  }, [documents]);
  return (
    <Box>
      <CustomTable
        data={data || []}
        columns={columns}
        actions
        actionsTypes={["print"]}
        disableRows={(item) => item.done}
        disableActions={(item, action) => action === "finish" && item.done}
        shouldDisableFields={["id", "type"]}
        actionHandlePrint={(_, item) =>
          handlePrint(documents.find((i) => i.id === item.id))
        }
      />
    </Box>
  );
}
