import React from "react";
import { List, ListItem, Typography, Box } from "@mui/material";
import ProgramHistoryCard from "./ProgramHistoryCard";
import useProgram from "../../../service/useProgram";
import { useQuery } from "react-query";
import { AppContext } from "../../../contexts/AppContext";
import { useTheme } from "@emotion/react";

const ProgramHistory = () => {
  const theme = useTheme();
  const { patientData } = React.useContext(AppContext);

  const { getProgramsOfPatient } = useProgram();
  const queryPrograms = useQuery(
    ["programs-of-patient", patientData],
    () => getProgramsOfPatient(patientData.id),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      // enabled: !!patientData?.id,
      enabled: false,
    }
  );

  return (
    <Box>
      <Typography variant="h6" color="secondary" fontWeight="700">
        Histórico Clínico
      </Typography>
      <List
        sx={{
          maxHeight: 180,
          overflowY: "auto",
        }}
      >
        {!!queryPrograms.data?.length ? (
          queryPrograms.data?.map((data) => (
            <ListItem
              key={data.id}
              sx={{
                paddingLeft: 0,
                paddingRight: 1,
                paddingY: 0.5,
              }}
            >
              <ProgramHistoryCard data={data} />
            </ListItem>
          ))
        ) : (
          <ListItem>
            <Typography
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              Não há programas atrelados a esse paciente
            </Typography>
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default ProgramHistory;
