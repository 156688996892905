import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";
import { format, isValid } from "date-fns";
import { SM_LOGO } from "./pdfSources";

function capsPDF(_, patientData, soapDocumentData) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const counterReferenceReport = {
    table: {
      widths: [110, 400],
      body: [
        [
          { text: "Nome:", border: [1, 1, 0, 0] },
          { text: patientData.name, border: [0, 1, 1, 0] },
        ],
        [
          { text: "Data de nascimento:", border: [1, 1, 0, 0] },
          {
            text: isValid(new Date(patientData.birth_date))
              ? format(
                  new Date(patientData.birth_date + "T00:00:00Z"),
                  "dd/MM/yyyy"
                )
              : "Não Informado",
            border: [0, 1, 1, 0],
          },
        ],
        [
          { text: "CNS:", border: [1, 1, 0, 0] },
          { text: patientData.sus_card, border: [0, 1, 1, 0] },
        ],
        [
          { text: "Tel:", border: [1, 1, 0, 0] },
          { text: patientData.contact.cell_phone, border: [0, 1, 1, 0] },
        ],
        [
          { text: "End", border: [1, 1, 0, 0] },
          {
            text: `${patientData.contact.address_number} ${patientData.contact.district}, ${patientData.contact.county}`,
            border: [0, 1, 1, 0],
          },
        ],
        [
          { text: "Ubs de Referência:", border: [1, 1, 0, 0] },
          { text: "Ecaminhado para UBS", border: [0, 1, 1, 0] },
        ],
        [
          { text: "Prontuário:", border: [1, 1, 0, 1] },
          { text: soapDocumentData.number_soap, border: [0, 1, 1, 1] },
        ],
      ],
    },
  };

  const patientCurrentStatus = {
    margin: [0, 10, 0, 0],
    stack: [
      {
        text: [
          {
            text: "1.H.D / QUEIXA/ ESTADO ATUAL DO PACIENTE",
            fontSize: 12,
            bold: true,
            decoration: "underline",
          },
          {
            text: " (Impressões objetivas – breve relato: problemática que gerou o encaminhamento):",
            italics: true,
            fontSize: 9,
          },
        ],
      },
      {
        margin: [0, 2, 0, 0],
        table: {
          heights: ["*"],
          widths: [518],
          body: [[" "], [" "], [" "], [" "], [" "], [" "]],
        },
      },
    ],
  };

  const mentalHealth = {
    margin: [0, 10, 0, 0],
    stack: [
      {
        text: "2.HISTÓRICO DE SAÚDE CLÍNICA / SAÚDE MENTAL",
        fontSize: 12,
        bold: true,
        decoration: "underline",
      },
      {
        margin: [0, 1, 0, 0],
        table: {
          heights: ["*"],
          widths: [518],
          body: [[" "], [" "], [" "], [" "], [" "], [" "]],
        },
      },
    ],
  };

  const interventionsCarriedOut = {
    margin: [0, 10, 0, 0],
    stack: [
      {
        text: [
          {
            text: "3.INTERVENÇÕES REALIZADAS",
            fontSize: 12,
            bold: true,
            decoration: "underline",
          },
          {
            text: " (histórico de tratamentos anteriores / internações; aderência ao tratamento; tratamentos concomitantes; medicações em uso e se está inserido em atividades no serviço de origem de encaminhamento)",
            italics: true,
            fontSize: 9,
          },
        ],
      },
      {
        margin: [0, 5, 0, 0],
        table: {
          heights: ["*"],
          widths: [518],
          body: [
            [" "],
            [" "],
            [" "],
            [" "],
            [" "],
            [" "],
            [" "],
            [" "],
            [" "],
            [" "],
            [" "],
            [" "],
            [" "],
            [" "],
          ],
        },
      },
    ],
  };

  const riskProtectiveFactors = {
    margin: [0, 10, 0, 0],
    stack: [
      {
        text: [
          {
            text: "4.FATORES DE RISCO E PROTEÇÃO",
            fontSize: 12,
            bold: true,
            decoration: "underline",
          },
          {
            text: "  (Dinâmica/ continência familiar; redes de suporte; participação em outros equipamentos, vulnerabilidade social, condições financeiras, desemprego., uso de substâncias, tentativas de suicídio, migração):",
            italics: true,
            fontSize: 9,
          },
        ],
      },
      {
        margin: [0, 5, 0, 0],
        table: {
          heights: ["*"],
          widths: [518],
          body: [[" "], [" "], [" "], [" "], [" "], [" "], [" "]],
        },
      },
    ],
  };

  const familyBackground = {
    margin: [0, 5, 0, 0],
    stack: [
      {
        text: [
          {
            text: "5. ANTECEDENTES FAMILIARES",
            fontSize: 12,
            bold: true,
            decoration: "underline",
          },
          {
            text: " (Transtorno mental na família)",
            italics: true,
            fontSize: 9,
          },
        ],
      },
      {
        margin: [0, 2, 0, 0],
        table: {
          heights: ["*"],
          widths: [518],
          body: [[" "], [" "], [" "]],
        },
      },
    ],
  };

  const SuggestedTherapyFollowUp = {
    margin: [0, 10, 0, 0],
    stack: [
      {
        text: [
          {
            text: "6. TERAPÊUTICA SUGERIDA PARA SEGUIMENTO NA UNIDADE DE REFERÊNCIA",
            fontSize: 12,
            bold: true,
            decoration: "underline",
          },
          {
            text: " (plano farmacológico e atividades terapêuticas):",
            italics: true,
            fontSize: 9,
          },
        ],
      },
      {
        margin: [0, 5, 0, 0],
        table: {
          heights: ["*"],
          widths: [518],
          body: [[" "], [" "], [" "], [" "], [" "], [" "], [" "]],
        },
      },
    ],
  };

  const title = {
    text: "RELATÓRIO DE REFERÊNCIA E CONTRARREFERÊNCIA - SAÚDE MENTAL",
    fontSize: 12,
    bold: true,
    alignment: "center",
    margin: [0, 20, 0, 10],
  };

  const reasonReferal = {
    margin: [10, 10, 0, 0],
    stack: [
      {
        text: "7. MOTIVO DO ENCAMINHAMENTO",
        fontSize: 12,
        bold: true,
        decoration: "underline",
      },
      {
        margin: [-10, 10, 0, 0],
        text: [
          "(  ) ",
          "P/ ciência da unidade de Referência;\n",
          "(  ) ",
          "P/ Avaliação e possível acompanhamento do CAPS;\n",
          "(  ) ",
          "P/ Avaliação e possível acompanhamento da UBS de referência;\n",
          "(  ) ",
          "P/ Avaliação e possível acompanhamento Terapêutico, sendo que aguarda acesso para psiquiatria na UBS de referência;\n",
          "(  ) ",
          "P/ Avaliação e possível acompanhamento Psiquiátrico;\n",
          "(  ) ",
          "P/ Acompanhamento médico clínico/da família/pediatra/ generalista (Imprescindível encaminhamento detalhado do Médico Psiquiatra que acompanhou paciente anteriormente).\n",
        ],
      },
    ],
  };

  const complementarInfo = {
    margin: [10, 10, 0, 0],
    stack: [
      {
        text: "8.INFORMAÇÕES COMPLEMENTARES:",
        fontSize: 12,
        bold: true,
        decoration: "underline",
      },
      {
        margin: [-10, 10, 0, 0],
        text: [
          "(  )  ",
          "Alta de serviço especializado com agendamento médico, sendo enviado Data e horário da consulta na UBS de referência\n",
          "Data:",
          " ______________________________________\n",
          "Horário da consulta: ",
          "__________________________\n",
          "Carimbo/ Ass. do Profissional responsável __________________________________ Data _________\n",
          "Local de Origem do Encaminhamento: __________________________________________________",
        ],
      },
    ],
  };

  const updateVersion = {
    margin: [185, 50, 0, 0],
    text: "Versão atualizada agosto/2023",
  };

  const clinicalReport = {
    stack: [
      title,
      counterReferenceReport,
      patientCurrentStatus,
      mentalHealth,
      interventionsCarriedOut,
      riskProtectiveFactors,
      familyBackground,
      SuggestedTherapyFollowUp,
      reasonReferal,
      complementarInfo,
      updateVersion,
    ],
  };
  const logo = {
    image: "santaMarcelina",
    width: 130,
    height: 35,
    margin: [5, -50, 0, 5],
  };
  const definitions = {
    info: { title: pdfName },
    pageMargins: [40, 80, 40, 60],
    defaultStyle: {
      fontSize: 10,
    },
    content: [logo, clinicalReport],
    images: {
      santaMarcelina: SM_LOGO,
    },
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default capsPDF;
