import React, { useContext } from "react";
import { Grid } from "@mui/material";
import { FormTextField } from "../../components";
import FormMultipleSelectField from "../../components/Form/FormMultipleSelectField";
import { AppContext } from "../../contexts/AppContext";

function Subjective({ ciaps }) {
  const { disableFields } = useContext(AppContext);

  return (
    <>
      <FormTextField
        name="reason"
        multiline
        minRows={4}
        maxRows={4}
        label="Motivo da Consulta : "
        sx={{ mb: 2 }}
        disabled={disableFields}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormMultipleSelectField
            name="ciapSubjective"
            label="CIAP :"
            options={ciaps}
            keys={["id", "description"]}
            disabled={disableFields}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Subjective;
