import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

export function useClinicalRecords() {
  const path = "clinical-records";
  async function postClinicalRecords(data, params) {
    try {
      const result = await instance.post(path, data, {
        params: urlQuery(params),
      });
      return result.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
  async function getClinicalRecordsByPatientId(id, params) {
    try {
      const result = await instance.get(`patients/${id}/clinical-records`, {
        params: urlQuery(params),
      });
      return result.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  return {
    postClinicalRecords,
    getClinicalRecordsByPatientId,
  };
}
