function formatPhoneNumber(phone) {
  if (typeof phone === "string") {
    const splitedPhoneNumber = phone.split("");

    const formatedPhoneNumber = splitedPhoneNumber.reduce(
      (phone, character, index) => {
        if (index === 1) return phone + character + ") ";

        if (index === 5) return phone + character + "-";

        return phone + character;
      },
      "("
    );

    return formatedPhoneNumber;
  }
}

export default formatPhoneNumber;
