import React from "react";
import { Box } from "@mui/material";
import HealthInsurancesStep from "./SettingsAttendanceSteps/HealthInsurancesStep";
import HealthPlanStep from "./SettingsAttendanceSteps/HealthPlanStep";
import ProvenanceStep from "./SettingsAttendanceSteps/ProvenanceStep";
import { Tabs } from "../../components";

export default function SettingsAttendance() {
  const tabList = [
    {
      label: "Convênio",
      content: <HealthInsurancesStep />,
    },
    {
      label: "Planos",
      content: <HealthPlanStep />,
    },
    {
      label: "Procedência",
      content: <ProvenanceStep />,
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs tabs={tabList} />
      </Box>
    </>
  );
}
