import React, { useContext, useRef, useState } from "react";
import { BoxContainer, Tabs } from "../../../components";
import { Loading, Dialog } from "../../../helper";
import {
  TablePagination,
  Box,
  Grid,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { RenderFormMaker } from "../../../components/FormMaker";
import { FormMakerFeedback } from "../../../components/FormMaker";
import { format, isToday, parseISO } from "date-fns";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useDocuments from "../../../service/useDocuments";
import { FormMakerContext } from "../../../contexts/FormMakerContext";
import { useRiskClassification, useServices } from "../../../service";
import { AutocompleteField } from "../../../components/FormFields";
import usePermissions from "../../../config/usePermissions";
import useTotem from "../../../service/useTotem";
import { prioritiesIcon } from "../../../config/config";
import TableFilter from "../../../components/Table/TableFilter";
import { classificationColors } from "../../../config/standardSelects";
import useNotifier from "../../../hooks/useNotifier";
import { useCallSystem } from "../../../hooks";
import convertDateTobirthday from "../../../utils/convertDateToBirthday";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../config/yup";
import { formMakerPDF } from "../../../pdfModels";
import useSignaturePassword from "../../../hooks/useSignaturePassword";
import { verifyTwentyFourHoursCompany } from "../../../utils";

const othersFilters = [
  {
    name: "Deletados",
    type: "boolean",
    filter: "deleted",
  },
  {
    name: "Data Inicial",
    type: "date",
    filter: "initial_date",
    disableFuture: true,
  },
  {
    name: "Data Final",
    type: "date",
    filter: "final_date",
  },
  {
    name: "CPF",
    type: "text",
    filter: "patient_physic_national",
    mask: "999.999.999-99",
  },
  {
    name: "CNS",
    mask: "999999999999999",
    type: "text",
    filter: "patient_sus_card",
  },
];

const Classification = () => {
  const permission = usePermissions("/attendance/classification");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [filter, setFilter] = useState({ classified: false });
  const [classificationFilter, setClassificationFilter] = useState({});
  const [registerRecord, setRegisterRecord] = useState(false);
  const [displayRecord, setDisplayRecord] = useState(false);
  const [editRecord, setEditRecord] = useState(false);
  const [patientId, setPatientId] = useState();
  const [patientData, setPatientData] = useState();
  const [classificationId, setClassificationId] = useState();
  const ValidateSignature = useSignaturePassword();
  const [documentId, setDocumentId] = useState();
  const [recordId, setRecordId] = useState();
  const notify = useNotifier();
  const actionType = useRef();

  const {
    feedbackFormatter,
    document,
    setDocument,
    setRecord,
    setRecordDocument,
  } = useContext(FormMakerContext);

  const validations = Yup.object().shape({
    service: Yup.object().nullable(),
  });

  const { control } = useForm({
    defaultValues: {
      service: null,
    },
    resolver: yupResolver(validations),
  });

  const { cancelToken } = useTotem();
  const { getServices } = React.useMemo(useServices, []);
  const tokenMutation = useMutation(cancelToken);
  const handleCallToken = useCallSystem("classification");

  const servicesQuery = useQuery(
    "services",
    () => getServices({ filter: "active", ama_type: "classification_risk" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      initialData: [],
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const queryClient = useQueryClient();
  const { getRiskClassifications } = useRiskClassification();

  const allStatus = {
    filling: "Preenchendo",
    finished: "Finalizado",
    null: "Não inicializado",
  };

  const riskClassificationsQuery = useQuery(
    [
      "risk-classifications",
      documentId,
      page,
      limit,
      filter,
      classificationFilter,
    ],
    () =>
      getRiskClassifications({
        page,
        limit,
        ...filter,
        ...classificationFilter,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: { items: [], totalItems: 0 },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const {
    getDocumentsByType,
    getExternalDocumentById,
    getRecords,
    patchRecords,
  } = useDocuments();

  const documentTypeQuery = useQuery(
    ["documents-by-type"],
    () => getDocumentsByType("af3668a2-f760-4540-9281-952525d083e8"),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: [],
      onSuccess: (response) => {
        setDocumentId(response[0]?.external_document_id);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const documentQuery = useQuery(
    ["document", documentId],
    () => getExternalDocumentById(documentId),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!documentId,
      initialData: [],
      onSuccess: (response) => {
        setDocument(response);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const recordsQuery = useQuery(
    ["records", recordId],
    () => getRecords({ recordId }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!recordId,
      initialData: [],
      onSuccess: async (response) => {
        const recordShape = response.find(({ id }) => id === recordId);

        if (actionType.current === "view") {
          setRecordDocument(recordShape);
          setDisplayRecord(true);
        } else if (actionType.current === "edit") {
          setRecordDocument(recordShape);
          setRecord(recordShape.fields, document);
          setEditRecord(true);
        } else if (actionType.current === "finish") {
          await ValidateSignature(async () => {
            const formattedRecord = feedbackFormatter(recordShape);

            const documentDataToPDF = {
              name: recordShape.document_id?.name,
              status: allStatus[recordShape.status],
              version: recordShape.document_id.version,
              employee: {
                ...recordShape.employee_specialty.employee,
                specialty: recordShape.employee_specialty.specialty,
              },
              created_at: recordShape.createdAt,
              fields: formattedRecord,
            };

            const pdfConfig = await riskClassificationPDF(documentDataToPDF);

            patchRecordsQuery.mutate(
              {
                patientId,
                documentRecordId: recordShape?.id,
                data: {
                  ...recordShape,
                  status: "finished",
                  risk_classification: {
                    id: classificationId,
                    ...pdfConfig,
                  },
                },
              },
              {
                onSuccess: () => {
                  setRecord([]);
                  queryClient.invalidateQueries("risk-classifications");
                },
                onError: (error) => {
                  notify(error.message, "error");
                },
              }
            );
          });
        }
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const patchRecordsQuery = useMutation(patchRecords);

  const riskClassificationPDF = async (documentData) => {
    const pdfInfos = formMakerPDF(
      documentData,
      patientData,
      true,
      "risk_classification"
    );

    return {
      to_sign: [
        {
          original_file_name: pdfInfos.name,
          id: pdfInfos.name.split(".")[0],
          data: await pdfInfos.base64(),
          type: "risk_classification",
          signature_settings: {
            visible_sign_page: "*",
            visible_sign_x: 170,
            visible_sign_y: 615,
          },
        },
      ],
    };
  };

  // handle functions
  const handleCloseDialog = () => {
    setRecord([]);
    setRegisterRecord(false);
    setDisplayRecord(false);
    setEditRecord(false);
    riskClassificationsQuery.refetch();
  };

  function tokenCancel(_, classification) {
    tokenMutation.mutate(classification?.token_audit.id, {
      onError: (err) => {
        notify(err.message, "error");
      },
      onSuccess: () => {
        riskClassificationsQuery.refetch();
      },
    });
  }

  const addValuesToSubmit = async (values, recordDocument) => {
    // if (!Object.keys(recordDocument).length) return {};

    const colors = ["Vermelho", "Verde", "Azul", "Amarelo"];
    const classification = !values
      ? null
      : Object.values(values).find((value) => colors.includes(value));

    const valuesToSubmit = {
      risk_classification: {
        id: classificationId,
        classification: classification,
      },
    };

    if (
      !!Object.keys(recordDocument).length &&
      recordDocument.status === "finished"
    ) {
      const formattedRecord = feedbackFormatter(recordDocument);

      const documentDataToPDF = {
        name: recordDocument.document_id.name,
        status: allStatus[recordDocument.status],
        version: recordDocument.document_id?.version,
        employee: {
          ...recordDocument.employee_specialty.employee,
          specialty: recordDocument.employee_specialty.specialty,
        },
        created_at: recordDocument.createdAt,
        fields: formattedRecord,
      };

      const pdfConfig = await riskClassificationPDF(documentDataToPDF);

      valuesToSubmit.risk_classification = {
        ...valuesToSubmit.risk_classification,
        ...pdfConfig,
      };
    }

    return valuesToSubmit;
  };

  function handleFilter(newfilter) {
    setClassificationFilter(newfilter);
    setPage(0);
  }

  //config table
  const tableColumns = [
    {
      name: "Paciente",
      field: "patient",
      use: (patient) =>
        patient?.social_prioritize
          ? patient.social_name
          : patient?.name || "Não informado",
    },
    {
      name: "Gênero",
      field: "patient",
      use: (patient) => patient?.gender || "Não informado",
    },
    {
      name: "Data de nascimento",
      field: "patient",
      use: (patient) =>
        patient?.birth_date
          ? format(parseISO(patient.birth_date), "dd/MM/yyyy")
          : "Não informado",
    },
    {
      name: "Idade",
      field: "patient",
      use: (patient) =>
        patient?.birth_date
          ? convertDateTobirthday(patient.birth_date, ["years"])
          : "Não informado",
    },
    {
      name: "Data",
      field: "updatedAt",
      use: (updatedAt) => format(parseISO(updatedAt), "dd/MM/yyyy"),
    },
    {
      name: "Hora",
      field: "updatedAt",
      use: (updatedAt) => format(parseISO(updatedAt), "HH:mm"),
    },
    {
      name: "Senha",
      field: "token_audit",
      use: (token_audit) => (
        <Tooltip
          title={token_audit?.priority?.describe}
          placement="right-start"
        >
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "space-between",
              maxWidth: 110,
            }}
          >
            <Typography>{token_audit.token_describe}</Typography>
            {prioritiesIcon[token_audit.priority?.describe.toLowerCase()]}
          </Box>
        </Tooltip>
      ),
    },
    {
      name: "Classificação",
      field: "classification",
      use: (classification) => (
        <Box
          sx={{
            width: 20,
            height: 20,
            borderRadius: "100%",
            ml: 3,
            backgroundColor: classificationColors[classification],
          }}
        />
      ),
    },
    {
      name: "Status",
      field: "document_record",
      use: (document_record) => allStatus[document_record?.status || null],
    },
  ];

  const tabs = [
    {
      describe: "Não classificado",
      value: { classified: false, status: null },
    },
    { describe: "Preenchendo", value: { classified: true, status: "filling" } },
    {
      describe: "Finalizados",
      value: { classified: true, status: "finished" },
    },
  ];

  if (documentQuery.isLoading) return <Loading />;
  if (documentTypeQuery.isError)
    return (
      <BoxContainer title="Classificação de risco">
        <Typography
          sx={{
            fontSize: 16,
            color: (theme) => theme.palette.error.main,
          }}
        >
          Classificação de risco indisponível para esta unidade :{" "}
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="span"
          >
            {" "}
            {documentTypeQuery.error.message}
          </Typography>
        </Typography>
      </BoxContainer>
    );
  return (
    <>
      <BoxContainer title="Classificação de risco">
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <Tabs
              tabs={tabs}
              labelIdentifier="describe"
              handleClick={(tab) => {
                setPage(0);
                setFilter((filter) => ({ ...filter, ...tab.value }));
              }}
              variant="scrollable"
              sx={{ marginBottom: 1 }}
            />
          </Grid>
          <Grid item md={1} xs={2}>
            <Button
              variant="contained"
              sx={{
                marginBottom: "10px",
              }}
              disabled={riskClassificationsQuery.isLoading}
              onClick={() => {
                notify("Lista Atualizada");
                riskClassificationsQuery.refetch();
              }}
            >
              Atualizar
            </Button>
          </Grid>
          <Grid item md={2} xs={4}>
            <AutocompleteField
              name="service"
              label="Serviço"
              control={control}
              placeholder="Selecione um serviço"
              optionCompareKey="id"
              optionLabelKey="describe"
              options={servicesQuery.data}
              customOnChange={(service) => {
                setFilter((filter) => ({
                  ...filter,
                  service_id: service?.id || null,
                }));
              }}
            />
          </Grid>
        </Grid>
        <Dialog
          open={displayRecord}
          title={document?.name}
          handleClose={handleCloseDialog}
        >
          <FormMakerFeedback
            additionalData={patientData}
            signature
            signatureValidationPath="risk_classification"
          />
        </Dialog>
        <Dialog
          open={registerRecord}
          title={document?.name}
          handleClose={handleCloseDialog}
        >
          <RenderFormMaker
            refetch={false}
            handleClose={handleCloseDialog}
            patientId={patientId}
            addvaluesToSubmit={addValuesToSubmit}
            onSuccess={() =>
              queryClient.invalidateQueries("risk-classifications")
            }
          />
        </Dialog>
        <Dialog
          open={editRecord}
          title={document?.name}
          handleClose={handleCloseDialog}
        >
          <RenderFormMaker
            type="edit"
            refetch={false}
            handleClose={handleCloseDialog}
            patientId={patientId}
            addvaluesToSubmit={addValuesToSubmit}
            onSuccess={() =>
              queryClient.invalidateQueries("risk-classifications")
            }
          />
        </Dialog>
        <TableFilter
          data={riskClassificationsQuery.data.items}
          columns={tableColumns}
          loading={
            riskClassificationsQuery.isLoading ||
            recordsQuery.isLoading ||
            patchRecordsQuery.isLoading
          }
          actions
          othersFilter={othersFilters}
          handleFilter={handleFilter}
          actionsTypes={[
            "view",
            "call",
            "response",
            "edit",
            "finish",
            "delete",
          ]}
          disableActions={(row, action) => {
            if (
              riskClassificationsQuery.isLoading ||
              recordsQuery.isLoading ||
              patchRecordsQuery.isLoading
            ) {
              return true;
            }

            if (action !== "view" && !isToday(parseISO(row.createdAt))) {
              return true;
            }

            const status = allStatus[row.document_record?.status || null];

            if (
              verifyTwentyFourHoursCompany(row.createdAt) &&
              action !== "delete"
            ) {
              return true;
            }
            if (!permission.create && action === "response") {
              return true;
            }
            if (!permission.update && action === "edit") {
              return true;
            }
            if (!permission.read && action === "view") {
              return true;
            }
            if (row?.token_audit?.deletedAt) {
              if (
                ["edit", "finish", "delete", "response", "call"].includes(
                  action
                )
              ) {
                return true;
              }
            }
            if (status === "Não inicializado" && action === "response") {
              return false;
            }
            if (action === "view" && status !== "Não inicializado") {
              return false;
            }
            if (status === "Preenchendo" && action !== "response") {
              return false;
            }
            if (status === "Não inicializado" && action === "call") {
              return false;
            }
            if (
              permission.delete &&
              status === "Não inicializado" &&
              action === "delete"
            ) {
              return false;
            }

            return true;
          }}
          rowStatus={(row) => (row?.token_audit?.deletedAt ? "disabled" : "")}
          confirmMessage={`Você realmente deseja *action* este paciente?`}
          actionHandleDelete={tokenCancel}
          actionHandleResponse={(_, classification) => {
            setClassificationId(classification.id);
            setPatientId(classification.patient?.id);
            setRegisterRecord(true);
          }}
          actionHandleView={(_, classification) => {
            const patient = classification.patient;
            const patientData = [
              {
                label: "Paciente",
                value: patient?.social_prioritize
                  ? patient.social_name
                  : patient?.name || "Não informado",
              },
              {
                label: "CPF",
                value: patient?.physic_national || "Não informado",
              },
              {
                label: "Ficha",
                value: classification.token_audit.token_describe,
              },
              { label: "CNS", value: patient?.sus_card || "Não informado" },
              {
                label: "Data da classificação",
                value: format(parseISO(classification.updatedAt), "dd/MM/yyyy"),
              },
              {
                label: "Data de nascimento",
                value: patient?.birth_date
                  ? format(parseISO(patient.birth_date), "dd/MM/yyyy")
                  : "Não informado",
              },
              {
                label: "Horário da classificação",
                value: format(parseISO(classification.updatedAt), "HH:mm"),
              },
            ];

            actionType.current = "view";
            setRecordId(classification.document_record?.id || null);
            setPatientId(patient?.id);
            setPatientData(patientData);
            queryClient.invalidateQueries("records");
          }}
          actionHandleCall={(_, classification) => {
            handleCallToken(classification.token_audit.id);
          }}
          actionHandleEdit={(_, classification) => {
            actionType.current = "edit";
            setClassificationId(classification.id);
            setRecordId(classification.document_record?.id || null);
            setPatientId(classification.patient?.id);
            queryClient.invalidateQueries("records");
          }}
          actionHandleFinish={(_, classification) => {
            const patient = classification.patient;
            const patientData = [
              {
                label: "Paciente",
                value: patient?.social_prioritize
                  ? patient.social_name
                  : patient?.name || "Não informado",
              },
              {
                label: "CPF",
                value: patient?.physic_national || "Não informado",
              },
              {
                label: "Ficha",
                value: classification.token_audit.token_describe,
              },
              { label: "CNS", value: patient?.sus_card || "Não informado" },
              {
                label: "Data da classificação",
                value: format(parseISO(classification.updatedAt), "dd/MM/yyyy"),
              },
              {
                label: "Data de nascimento",
                value: patient?.birth_date
                  ? format(parseISO(patient.birth_date), "dd/MM/yyyy")
                  : "Não informado",
              },
              {
                label: "Horário da classificação",
                value: format(parseISO(classification.updatedAt), "HH:mm"),
              },
            ];
            actionType.current = "finish";
            setClassificationId(classification.id);
            setRecordId(classification.document_record?.id || null);
            setPatientId(classification.patient?.id);
            setPatientData(patientData);
            queryClient.invalidateQueries("records");
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={riskClassificationsQuery.data.totalItems || 0}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
      </BoxContainer>
    </>
  );
};

export default Classification;
