import { Box, TablePagination } from "@mui/material";
import { PatientsCard } from "../../../components";
import TableFilter from "../../../components/Table/TableFilter";
import { CheckBox } from "@mui/icons-material";
import { useAttendaces, usePatient } from "../../../service";
import { useQuery } from "react-query";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { Loading } from "../../../helper";

export default function AllergyHistory({ attendanceID }) {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { getAttendanceById } = useAttendaces();
  const { getPatientAllergies } = usePatient();
  const { data: attendance, isFetching } = useQuery(
    ["get-attendance-by-id", attendanceID],
    () => getAttendanceById(attendanceID),
    {
      enabled: !!attendanceID,
    }
  );

  
  const columns = [
    {
      name: "Profissional",
      field: "employee_name",
    },
    {
      name: "Data",
      field: "createdAt",
      use: (createdAt) =>
        createdAt ? format(parseISO(createdAt), "dd/MM/yyyy") : "",
    },
    {
      name: "Hora",
      field: "createdAt",
      use: (createdAt) =>
        createdAt ? format(parseISO(createdAt), "HH:mm") : "",
    },
    {
      name: "Descrição",
      field: "allergy_description",
    },
    {
      name: "Medicamento",
      field: "allergy_substances",
      use: (value) => <Check active={value?.medication} />,
      alignInRow: "center",
      alignInHead: "center",
    },
    {
      name: "Alimento",
      field: "allergy_substances",
      use: (value) => <Check active={value?.food} />,
      alignInRow: "center",
      alignInHead: "center",
    },
    {
      name: "Outros",
      field: "allergy_substances",
      use: (value) => <Check active={value?.others} />,
      alignInRow: "center",
      alignInHead: "center",
    },
  ];

  const Check = ({ active }) => {
    return <CheckBox color={active ? "primary" : "disabled"} />;
  };

  const allergiesQuery = useQuery(
    ["get-patient-allergies", attendance?.id, page, limit],
    () => getPatientAllergies(attendance.patient?.id, { page,limit}),
    {
      enabled: !!attendance?.id
    }
  );
  return (
    <>
      {isFetching || allergiesQuery.isFetching? (
        <Loading
          backgroundColor={"rgba(255,255,255,0.8)"}
          maxWidth={"100%"}
          message={
            isFetching
              ? "Solicitando dados dos pacientes..."
              : "Verificando alergias..."
          }
        />
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 3,
        }}
      >
        <PatientsCard
          patient={attendance}
          onDialog
          hideOptions
          history
        />
        <TableFilter columns={columns} data={allergiesQuery.data?.items} noHeaderOptions />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={allergiesQuery.data?.totalItems || 0}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Box>
      </Box>
    </>
  );
}
