import React, { useEffect, useState } from "react";

export default function CountUp({ finalValue, delay, sx }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (delay === 0) {
      setCount(finalValue);
    } else {
      const interval = setInterval(() => {
        if (count < finalValue) {
          setCount(count + 1);
        }
      }, 20);

      return () => {
        clearInterval(interval);
      };
    }
  }, [count, finalValue, delay]);

  return (
    <text
      style={sx}
      x={"50%"}
      y={"50%"}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {count}
    </text>
  );
}
