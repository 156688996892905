import { header, footer, title, patientInformations } from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";

function evolutionsPDF(data, patient, documentData) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const evolutionData = [
    {
      margin: [0, 30, 0, 10],
      fontSize: 12,
      text: "Dados da evolução",
      bold: true,
      style: "header",
    },
    {
      margin: [-10, 0, 0, 0],
      columnGap: 0,
      width: 250,
      type: "none",
      ul: [],
    },
  ];

  const labels = {
    order_number: "Número de ordem: ",
    tag: "Etiqueta: ",
    demand_type: "Demanda: ",
    chief_complaint: "Queixa principal: ",
    notes: "Anotações: ",
  };
  const demandOptions = [
    { label: "Espontânea", value: "spontaneous" },
    { label: "Referenciada", value: "referred" },
    { label: "Ocorrência Policial", value: "police_report" },
    { label: "Acidente de trabalho", value: "workplace_accident" },
    { label: "Outros", value: "others" },
  ];

  Object.entries(data).forEach(([key, value]) => {
    const comparations = [
      "order_number",
      "tag",
      "demand_type",
      "chief_complaint",
      "notes",
    ];

    if (comparations.includes(key)) {
      if (value) {
        evolutionData[1].ul.push({
          text: [
            { text: labels[key], bold: true },
            {
              text:
                demandOptions.find((opt) => opt?.value === value)?.label ?? value,
            },
          ],
          margin: [0, 0, 0, 5],
        });
      }
    }
  });

  let procedures = null;

  if (data.procedures?.length) {
    procedures = [
      {
        margin: [0, 30, 0, 10],
        fontSize: 12,
        text: "Exames e Procedimentos",
        bold: true,
        style: "header",
      },
      {
        columnGap: 0,
        width: 250,
        ol: [],
      },
    ];

    data.procedures.forEach(({ name, tuss_code }) => {
      procedures[1].ol.push({
        text: `${name} (${tuss_code})`,
        margin: [0, 0, 0, 5],
      });
    });
  }

  const urlType = pdfName + "/types/medical_record";
  const definitions = {
    info: { title: pdfName },
    pageMargins: [40, 105, 40, 220],
    defaultStyle: {
      fontSize: 10,
    },
    ...header(),
    footer: footer(
      documentData?.createdAt,
      true,
      documentData?.employee,
      documentData?.company,
      urlType,
      [0, 100, 0, 0]
    ),
    content: [
      title(documentData.title),
      patientInformations(patient),
      evolutionData,
      procedures,
    ],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default evolutionsPDF;
