import React from "react";
import {
  Box,
  Typography,
  CardHeader,
  CardContent,
  Card,
  Avatar,
  Chip,
  Tooltip,
  Grid,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { format, parseISO } from "date-fns";

function ProgramFeedback({ data }) {
  const [copiedText, setCopiedText] = React.useState("");
  return (
    <>
      <Card
        sx={{
          border: "3px solid transparent",
          borderColor: "white",
          borderWidth: "3px",
          boxShadow: "0 0 0 1.5px #FFFFFF",
        }}
      >
        <CardHeader
          title={
            <CopyToClipboard
              text={data.number}
              onCopy={() => setCopiedText(data.number)}
            >
              <Tooltip
                title={copiedText === data.number ? "Copiado!!" : "Copiar"}
                color="primary"
                placement="top"
              >
                <Chip
                  avatar={
                    <Avatar
                      sx={{
                        bgcolor: (theme) => theme.palette.primary.main,
                      }}
                    >
                      <Typography color="White" fontSize={12}>
                        {data && data.name.slice(0, 2).toUpperCase()}
                      </Typography>
                    </Avatar>
                  }
                  label={`ID - # ${data.number}`}
                  variant="outlined"
                  color="primary"
                  clickable
                />
              </Tooltip>
            </CopyToClipboard>
          }
        ></CardHeader>
        <Typography
          variant="p"
          color="secondary"
          fontWeight="600"
          fontSize={16}
          sx={{ ml: 2 }}
        >
          {data.name}
        </Typography>
        <Typography
          variant="h1"
          color="gray"
          fontWeight="700"
          fontSize={12}
          sx={{ ml: 2, mt: 2 }}
        >
          #1 Dados do programa
        </Typography>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", mt: "-10px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Cód. Programa
                    </Typography>{" "}
                    : {data.number}
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Gênero
                      </Typography>{" "}
                      : {data.gender.join(", ")}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Criado em
                      </Typography>{" "}
                      :{" "}
                      {!!data.createdAt &&
                        format(parseISO(data.createdAt), "dd/MM/yyyy")}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Descrição
                      </Typography>{" "}
                      : {data.describe}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h1"
                  color="gray"
                  fontWeight="700"
                  fontSize={12}
                  sx={{ mb: 1 }}
                >
                  #2 Questionários
                </Typography>
                 {data?.documents.map(
                  ({ name }) => {
                    return (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            mb: 1.5,
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="primary"
                            fontWeight="500"
                            fontSize={13}
                          >
                            <Typography
                              fontSize={13}
                              color="secondary"
                              fontWeight="500"
                              component="span"
                            >
                              Questionário
                            </Typography>{" "}
                            : {name}
                          </Typography>
                         </Box>
                      </>
                    );
                  }
                )} 
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h1"
                  color="gray"
                  fontWeight="700"
                  fontSize={12}
                  sx={{ mb: 1 }}
                >
                  #3 Planos de cuidados
                </Typography>
                {data?.carePlans.map(
                  ({ name }) => {
                    return (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            mb: 1.5,
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="primary"
                            fontWeight="500"
                            fontSize={13}
                          >
                            <Typography
                              fontSize={13}
                              color="secondary"
                              fontWeight="500"
                              component="span"
                            >
                              Plano de cuidado
                            </Typography>{" "}
                            : {name}
                          </Typography>
                        </Box>
                      </>
                    );
                  }
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h1"
                  color="gray"
                  fontWeight="700"
                  fontSize={12}
                  sx={{ mb: 1 }}
                >
                  #4 Dicas de saúde
                </Typography>
                {data?.healthTips.map(({ name }) => {
                  return (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          mb: 1.5,
                        }}
                      >
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Dica de Saúde
                          </Typography>{" "}
                          : {name}
                        </Typography>
                      </Box>
                    </>
                  );
                })}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h1"
                  color="gray"
                  fontWeight="700"
                  fontSize={12}
                  sx={{ mb: 1 }}
                >
                  #5 Critérios de entrada
                </Typography>
                {/* {data?.questionnaires.map(
                  ({ name, quantity, unit, interval, notes }) => {
                    return (
                      <>
                      <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        mb: 1.5,
                      }}
                      >
                          <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                          >
                          <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                          >
                              Medicamento
                            </Typography>{" "}
                            : {name}
                          </Typography>
                          <Typography
                          variant="h5"
                          color="primary"
                            fontWeight="500"
                            fontSize={13}
                            >
                            <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                            >
                            Quantidade
                            </Typography>{" "}
                            : {`${quantity} ${unit}`}
                            </Typography>
                            <Typography
                            variant="h5"
                            color="primary"
                            fontWeight="500"
                            fontSize={13}
                            >
                            <Typography
                              fontSize={13}
                              color="secondary"
                              fontWeight="500"
                              component="span"
                              >
                              Frequência
                              </Typography>{" "}
                              : {interval}
                              </Typography>
                              <Typography
                              variant="h5"
                              color="primary"
                              fontWeight="500"
                              fontSize={13}
                              >
                              <Typography
                              fontSize={13}
                              color="secondary"
                              fontWeight="500"
                              component="span"
                              >
                              Observação
                            </Typography>{" "}
                            : {notes}
                          </Typography>
                          </Box>
                      </>
                    );
                  }
                )} */}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h1"
                  color="gray"
                  fontWeight="700"
                  fontSize={12}
                  sx={{ mb: 1 }}
                >
                  #6 Critérios de saída
                </Typography>
                {/* {data?.questionnaires.map(
                  ({ name, quantity, unit, interval, notes }) => {
                    return (
                      <>
                      <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        mb: 1.5,
                      }}
                      >
                      <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                            fontSize={13}
                          >
                          <Typography
                          fontSize={13}
                          color="secondary"
                          fontWeight="500"
                          component="span"
                          >
                          Medicamento
                          </Typography>{" "}
                          : {name}
                          </Typography>
                          <Typography
                            variant="h5"
                            color="primary"
                            fontWeight="500"
                            fontSize={13}
                            >
                            <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                            >
                            Quantidade
                            </Typography>{" "}
                            : {`${quantity} ${unit}`}
                            </Typography>
                            <Typography
                            variant="h5"
                            color="primary"
                            fontWeight="500"
                            fontSize={13}
                            >
                            <Typography
                            fontSize={13}
                            color="secondary"
                              fontWeight="500"
                              component="span"
                            >
                            Frequência
                            </Typography>{" "}
                            : {interval}
                          </Typography>
                          <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                          >
                            <Typography
                              fontSize={13}
                              color="secondary"
                              fontWeight="500"
                              component="span"
                              >
                              Observação
                              </Typography>{" "}
                              : {notes}
                              </Typography>
                              </Box>
                              </>
                              );
                            }
                          )} */}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
export default ProgramFeedback;
