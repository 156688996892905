import React from "react";

import { Grid, IconButton, Stack, Box } from "@mui/material";
import { Form, Formik } from "formik";
import { FormDate, SubmitButton } from "../../../components";
import HistoryIcon from "@mui/icons-material/History";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import FormMultipleSelectField from "../../../components/Form/FormMultipleSelectField";
import _ from "lodash";
import Yup from "../../../config/yup";
import { styled } from "@mui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#9AC0D8",
    boxShadow: theme.shadows[1],
    fontSize: 8
  }
}));

export default function ProviderForm() {
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [servicoArray, setServicoArray] = React.useState([]);

  const initialValues = {
    initiaDate: null,
    finalDate: null,
    // teste: [],
    servicos: []
  };

  const validations = Yup.object({
    initiaDate: Yup.date()
      .required("É requerido")
      .nullable()
      .default(undefined),
    finalDate: Yup.date().required("É requerido").nullable().default(undefined)
    //teste: Yup.array().min(1).required("É requerido"),
    //teste2: Yup.array().min(1).required("É requerido"),
  });

  async function handleSubmit(values, { resetForm, setFieldValue }) {
    setLoadingButton(true);
    setTimeout(() => {
      setLoadingButton(false);
    }, 500);
  }

  const options =
    !!servicoArray && servicoArray.length
      ? servicoArray.map((option) => {
          const firstLetter = option.describe[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            ...option
          };
        })
      : [];

  return (
    <Formik
      initialValues={{ ...initialValues }}
      validationSchema={validations}
      onSubmit={handleSubmit}
    >
      {({ resetForm, values, setFieldValue }) => (
        <Form>
          {/* <Grid container spacing={2} sx={{ marginTop: 2.5, align: "center" }}>
                                     <Grid item xs={4}>
                                        <FormDate
                                            name="initiaDate"
                                            label="Inicio"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormDate
                                            name="finalDate"
                                            label="Fim"
                                            minDate={new Date(values.initiaDate)}
                                        />
                                    </Grid> */}
          {/* <Grid item xs={2}>
                                        <Stack direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}>
                                            <LightTooltip title="Analítico">
                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                    <AnalyticsIcon />
                                                </IconButton>
                                            </LightTooltip>
                                            <LightTooltip title="Série Histórica">
                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                    <HistoryIcon />
                                                </IconButton>
                                            </LightTooltip>
                                        </Stack>
                                    </Grid> 
                                </Grid>*/}
          <Grid container spacing={2} sx={{ marginTop: 2.5 }}>
            <Grid item xs={8}>
              <FormMultipleSelectField
                name={"servicos"}
                label={"Tipo de Profissional: "}
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                keys={["id", "describe"]}
              />
              <Grid item xs={2}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <LightTooltip title="Analítico">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <AnalyticsIcon />
                    </IconButton>
                  </LightTooltip>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <FormMultipleSelectField
                name={"servicos"}
                label={"Especialidade: "}
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                keys={["id", "describe"]}
              />
              <Grid item xs={4}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <LightTooltip title="Analítico">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <AnalyticsIcon />
                    </IconButton>
                  </LightTooltip>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "right",
              justifyContent: "right",
              pt: 2
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <SubmitButton loading={loadingButton}>
                Gerar PDF/Impressão
              </SubmitButton>
              {/* <LightTooltip title="Imprimir Relatório">
                                            <IconButton color="primary" aria-label="upload picture" component="span" >
                                                <PrintIcon />
                                            </IconButton>
                                        </LightTooltip> */}
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
