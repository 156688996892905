import React from "react";
import {
  ResponsiveContainer,
  ReferenceLine,
  BarChart,
  Bar,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";

const AgeGroupLabel = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  fontSize: 12,
  display: "flex",
  justifyContent: "center",
  color: theme.palette.primary.light,
  letterSpacing: "0.4em",
  marginBottom: 2,
}));

function ReflectionBarChart({
  width = 800,
  height = 220,
  gradientConfig = [],
  barConfig = [],
  title = "",
  data,
}) {
  const theme = useTheme();

  // const obj = [
  //   {
  //     label: "60-65",
  //     masc: -20,
  //     fem: 12,
  //   },

  const gradients = (
    <defs>
      {gradientConfig.map(({ id, from, to, x, y1, y2 }) => {
        return (
          <linearGradient
            key={id}
            id={id}
            x1={x || 0}
            y1={y1 || 0}
            x2="0"
            y2={y2 || 0}
          >
            <stop offset="5%" stopColor={to} stopOpacity={0.6} />
            <stop offset="90%" stopColor={from} stopOpacity={0.3} />
          </linearGradient>
        );
      })}
    </defs>
  );

  const bar = barConfig.map(({ key, barId }) => {
    return (
      <Bar
        barSize={30}
        dataKey={key}
        fill={`url(#${barId})`}
        radius={[0, 0, 0, 0]}
        stackId={1}
        // label={{
        //   fill: `${theme.palette.primary.light}`,
        //   fontSize: ".6rem",
        //   position: "top",
        //   fontWeight: "600",
        // }}
      />
    );
  });

  return (
    <>
      <Box maxWidth={width} width="100%" height={height}>
        <AgeGroupLabel>{title}</AgeGroupLabel>
        <Box
          width="100%"
          height="100%"
          sx={{ border: `1px solid #9AC0D833`, paddingX: 4, paddingY: 1 }}
        >
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <BarChart data={data} stackOffset="sign">
              {bar}
              {gradients}
              <XAxis
                dataKey="label"
                tickLine={false}
                tick={{ fill: `${theme.palette.primary.light}` }}
                stroke="false"
              />
              <YAxis hide />
              <ReferenceLine y={0} fill="#000" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </>
  );
}

export default ReflectionBarChart;
