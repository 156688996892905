import React from "react";
import {
  FormMultipleSelectField,
  SubmitButton,
} from "../../../../components/Form";
import { Formik, Form } from "formik";
import Yup from "../../../../config/yup";
import PaginatedTable from "../../../../components/Table/PaginatedTable";
import { Grid } from "@mui/material";
import { useHealthTips } from "../../../../service";
import { useQuery } from "react-query";
import { Box } from "@mui/system";

const ProgramHealthTipsForm = ({ rows = [], setRows }) => {
  const [healthTips, setHealthTips] = React.useState([]);
  const { getHealthTips } = React.useMemo(useHealthTips, []);

  const queryHealthTips = useQuery(["queryHealthTips"], () => getHealthTips(), {
    onSuccess: (data) => {
      const healthTipsRemove = data.items.filter(
        (ar) => !rows.find((rm) => rm.id === ar.id)
      );
      setHealthTips(healthTipsRemove);
    },
  });

  function handleDelete(index) {
    const filteredrows = rows.filter((_, key) => key !== index);
    setRows(filteredrows);
  }

  function addRows({ health_tips }, { resetForm }) {
    if (!!health_tips) {
      const rows = health_tips.map(({ number, name, id }) => ({
        number,
        name,
        id,
      }));
      setRows((previousrows) => [...previousrows, ...rows]);
      resetForm();
    }
    queryHealthTips.refetch();
  }

  const initialValues = {
    health_tips: [],
  };

  const validations = Yup.object().shape({
    health_tips: Yup.array()
      .of(Yup.object())
      .min(1, "Selecione ao menos uma uma Dica de Saúde"),
  });

  const collumns = [{ name: "Código" }, { name: "Dica de saúde" }];

  return (
    <Formik
      onSubmit={addRows}
      initialValues={initialValues}
      validationSchema={validations}
    >
      {() => (
        <>
          <Form>
            <Grid xs={12} spacing={3} container item marginTop={-2}>
              <Grid xs={2} item>
                <FormMultipleSelectField
                  label="Dica de saúde"
                  name="health_tips"
                  keys={["id", "name"]}
                  options={healthTips}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <SubmitButton>Adicionar</SubmitButton>
              </Grid>
            </Grid>
          </Form>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <PaginatedTable
              sx={{ marginTop: 3 }}
              columns={collumns}
              data={rows}
              actions
              actionsTypes={["delete"]}
              shouldDisableFields={["id"]}
              actionHandleDelete={handleDelete}
            />
          </Box>
        </>
      )}
    </Formik>
  );
};

export default ProgramHealthTipsForm;
