import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import accessObjectByString from "../../../utils/accessObjectByString";
import { useQuery } from "react-query";
import { useDebounceCallback } from "../../../hooks";
import ListBox from "./ListBox";

const PaginatedAutocompleteField = ({
  control,
  name,
  label,
  placeholder,
  required,
  disabled,
  multiple = false,
  listKey = "items",
  optionLabelKey = "label",
  optionCompareKey,
  service,
  refetchService,
  filterKey,
  queryKey,
  limit = 10,
  customOnChange,
  onBlur,
  onChange,
  onFocus,
  inputProps,
  autoCompleteProps,
}) => {
  const [options, setOptions] = useState([]);
  const [enableQuery, setEnableQuery] = useState(false);
  const [filters, setFilters] = useState({
    page: 0,
    text: null,
  });
  const [totalPage, setTotalPage] = useState(0);

  const optionIdentifier = useMemo(() => {
    return optionCompareKey || optionLabelKey;
  }, [optionCompareKey, optionLabelKey]);

  useEffect(() => {
    setFilters({
      page: 0,
    });
  }, [refetchService]);

  const serviceQuery = useQuery(
    [queryKey, enableQuery, filters, limit, refetchService],
    () => {
      if (filterKey) {
        return service({
          page: filters.page,
          limit,
          [filterKey]: filters.text,
        });
      }

      return service({ page: filters.page, limit });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !disabled && enableQuery && !!queryKey,
      onSuccess(response) {
        const items = accessObjectByString(response, listKey) || response;

        if (items) {
          setTotalPage(response.total);

          if (filters.page === 0) {
            setOptions(items);
          } else {
            setOptions((options) => [...options, ...items]);
          }
        } else {
          setOptions((options) => [...options, ...response]);
        }
      },
      onError() {
        setOptions([]);
      },
    }
  );
  const debouncedSearch = useDebounceCallback((event) => {
    setFilters({ text: event.target.value, page: 0 });
  }, 500);

  const debouncedScroll = useDebounceCallback(({ target }) => {
    const isEndScroll =
      target.scrollTop + target.clientHeight >= target.scrollHeight - 2;
    const enableToPaginate =
      totalPage > filters.page &&
      !serviceQuery.isFetching &&
      !serviceQuery.error &&
      options.length;

    if (isEndScroll && enableToPaginate) {
      setFilters((filters) => ({
        ...filters,
        page: filters.page + 1,
      }));
    }
  }, 500);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          size="small"
          variant="outlined"
          fullWidth
          multiple={multiple}
          disabled={disabled}
          options={options}
          filterOptions={(options) => options}
          getOptionLabel={(option) =>
            accessObjectByString(option, optionLabelKey)
          }
          isOptionEqualToValue={(option, value) =>
            accessObjectByString(option, optionIdentifier) ===
            accessObjectByString(value, optionIdentifier)
          }
          {...autoCompleteProps}
          disablePortal
          componentsProps={{
            popper: {
              modifiers: [
                {
                  name: "flip",
                  enabled: true,
                  options: {
                    altBoundary: true,
                    rootBoundary: "document",
                    padding: 8,
                  },
                },
                {
                  name: "preventOverflow",
                  enabled: true,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    rootBoundary: "document",
                    padding: 8,
                  },
                },
              ],
            },
          }}
          ListboxComponent={ListBox}
          renderInput={(params) => (
            <TextField
              {...inputProps}
              {...params}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              label={label}
              placeholder={placeholder}
              required={required}
              InputLabelProps={{
                shrink: true,
                ...params.InputLabelProps,
              }}
              InputProps={{
                ...params.InputProps,
                required: required && field?.length === 0,
                endAdornment: (
                  <>
                    {serviceQuery.isFetching && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              onChange={debouncedSearch}
            />
          )}
          ListboxProps={{
            style: { maxHeight: "200px" },
            onScroll: debouncedScroll,
          }}
          onChange={(_, value) => {
            if (onChange instanceof Function) {
              onChange(value);
            } else {
              field.onChange(value);
            }

            if (customOnChange instanceof Function) {
              customOnChange(value);
            }
          }}
          onBlur={(_, value) => {
            if (onBlur instanceof Function) {
              onBlur(value);
            } else {
              field.onBlur(value);
            }
          }}
          onFocus={(event) => {
            setEnableQuery(true);

            if (onFocus instanceof Function) {
              onFocus(event);
            }
          }}
          {...autoCompleteProps}
        />
      )}
    />
  );
};

export default PaginatedAutocompleteField;
