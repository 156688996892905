import { Box, Grid, TablePagination } from "@mui/material";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useState } from "react";
import { useProvider, useRoles } from "../../../../service";
import useNotifier from "../../../../hooks/useNotifier";
import TableFilter from "../../../../components/Table/TableFilter";
import Yup from "../../../../config/yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, SelectField } from "../../../../components/FormFields";

const RoleEmployeeType = ({ role }) => {
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const notify = useNotifier();

  const {
    getRoleEmployeeTypes,
    postRoleEmployeeTypes,
    deleteRoleEmployeeType,
  } = useRoles();
  const { getTypes } = useProvider();

  const insertMutation = useMutation(postRoleEmployeeTypes);
  const deleteMutation = useMutation(deleteRoleEmployeeType);

  const getRoleEmployeeTypesQuery = useQuery(
    ["get-role-employee-types", [limit, page]],
    () => getRoleEmployeeTypes(role?.id, { limit, page }),
    {
      keepPreviousData: false,
      enabled: !!role,
      initialData: {
        items: [],
        totalItems: 0,
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const getEmployeeTypesQuery = useQuery(["get-employee-types"], getTypes, {
    initialData: { data: [] },
  });

  const validations = Yup.object().shape({
    type: Yup.object().required("Escolha uma tela").nullable(),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      type: null,
    },
    resolver: yupResolver(validations),
  });

  function onSubmit(values) {
    const insertion = {
      employeeType_id: values.type.id,
    };

    insertMutation.mutate(
      { roleID: role.id, insertion },
      {
        onSuccess: () => {
          notify("Tipo de profissional vinculado com sucesso.", "success");
          getRoleEmployeeTypesQuery.refetch();
          reset();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  }

  function handleDelete(deletion) {
    deleteMutation.mutate(
      { roleID: role.id, deletion },
      {
        onSuccess: () => {
          notify(
            "Vinculo com tipo de profissional removido com sucesso.",
            "success"
          );
          getRoleEmployeeTypesQuery.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  }

  return (
    <Grid container xs={12} spacing={2} width="100%">
      <Grid
        container
        item
        xs={12}
        spacing={2}
        style={{ display: "flex", alignItems: "center" }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid xs={10} item>
          <SelectField
            name="type"
            label="Tipo de profissional"
            control={control}
            options={getEmployeeTypesQuery.data.data ?? []}
            optionCompareKey="id"
            optionLabelKey="description"
          />
        </Grid>
        <Grid xs={2} item>
          <Button type="submit" loading={insertMutation.isLoading}>
            Cadastrar
          </Button>
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <TableFilter
          loading={getRoleEmployeeTypesQuery.isFetching}
          data={getRoleEmployeeTypesQuery.data?.items ?? []}
          columns={[{ name: "Tipo de profissional", field: "description" }]}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["delete"]}
          shouldDisableFields={["id"]}
          actionHandleDelete={handleDelete}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={getRoleEmployeeTypesQuery.data?.totalItems ?? 0}
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default RoleEmployeeType;
