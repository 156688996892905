import { Grid, TablePagination, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import TableFilter from "../../../components/Table/TableFilter/Table";
import { prioritiesIcon } from "../../../config/config";
import { AutorenewRounded, ModeStandbyOutlined } from "@mui/icons-material";
import { classificationColors } from "../../../config/standardSelects";
import { useCallSystem } from "../../../hooks";
import { verifyTwentyFourHoursCompany } from "../../../utils";

export default function PatientListTable({
  data,
  setSelectedMonitoring,
  setOpenEvolutionWithAttendance,
  setOpenDialog,
  updateWorkListStatus,
  totalItems,
  page,
  setPage,
  limit,
  setLimit,
  noActions,
  actionsTypes,
  setSearchFilter,
  callType,
  noEvolutionStatus = false,
  noOutstanding = false,
  error,
  loading,
}) {
  const evolutionStatusEnum = {
    finished: "Finalizado",
    filling: "Preenchendo",
    null: "Não inicializado",
  };
  const columns = [
    {
      name: "Senha",
      field: "attendance?.token_audit?.token_describe",
      use: (value, row) => {
        return (
          <Tooltip
            title={row.attendance?.token_audit?.priority?.describe}
            placement="right-start"
          >
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "space-between",
                maxWidth: 110,
              }}
            >
              <Typography>{value}</Typography>
              <Box>
                {
                  prioritiesIcon[
                    row.attendance?.token_audit?.priority?.describe.toLowerCase()
                  ]
                }
              </Box>
            </Box>
          </Tooltip>
        );
      },
    },
    {
      name: "Data/Hora",
      field: "createdAt",
      nowrap: true,
      use: (value) =>
        value ? format(new Date(value), "dd/MM/yyyy HH:mm") : "-",
    },
    {
      name: "Cod. paciente",
      field: "attendance?.patient?.number",
    },
    {
      name: "Paciente",
      field: "attendance?.patient?.name",
      otherwise: "attendance?.patient?.social_name",
      condition: "!row.attendance?.patient?.social_prioritize",
      type: "text",
      filter: "patient_name",
    },
    {
      name: "Nome da mãe",
      field: "attendance?.patient?.mother_name",
    },
    {
      name: "Atendimento",
      field: "attendance?.number",
    },
    {
      name: "Classificação/Reavaliação",
      field: "attendance?.risk_classification",
      use: (riskClassification, row) => (
        <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: "100%",
              ml: 3,
              backgroundColor:
                classificationColors[
                  riskClassification?.classification || "null"
                ],
            }}
          />
          <Tooltip title={row?.attendance?.reevaluation} placement="top">
            <AutorenewRounded
              color={
                row?.attendance?.reevaluation === "Em monitoramento"
                  ? "primary"
                  : "disabled"
              }
            />
          </Tooltip>
        </Box>
      ),
    },
    {
      name: "Status de evolução",
      field: "medical_record_status",
      use: (value) => evolutionStatusEnum[value],
      active: noEvolutionStatus,
    },
  ];

  const othersFilters = [
    {
      name: "Status",
      filter: "work_list_status",
      type: "select",
      options: [
        {
          label: "Finalizados",
          value: "finished",
        },
        {
          label: "Em aberto",
          value: "unfinished",
        },
        {
          label: "Deletados",
          value: "deleted",
        },
        {
          label: "Pendente",
          value: "outstanding",
        },
      ],
    },
    {
      name: "SOAP",
      filter: "from_soap",
      type: "boolean",
    },
    {
      name: "Data Inicial",
      filter: "initial_date",
      type: "date",
    },
    {
      name: "Data Final",
      filter: "final_date",
      type: "date",
    },
  ];

  if (callType === "lab") {
    othersFilters.push({
      name: "UBS/AMA",
      filter: "attendance_flow",
      type: "select",
      options: [
        {
          label: "AMA",
          value: "ama",
        },
        {
          label: "UBS",
          value: "ubs",
        },
      ],
    });
  }

  const handleCallToken = useCallSystem(callType);

  const CallFunction = (_, data) => {
    handleCallToken(data.attendance?.token_audit?.id);
  };

  return (
    <Box>
      <Grid container item xs={12} spacing={2} mb={2}>
        <Grid item xs={12}>
          <TableFilter
            data={data || []}
            loading={loading}
            columns={columns.filter((i) => !i.active)}
            othersFilter={othersFilters}
            handleFilter={(filter) => {
              setSearchFilter(filter);
            }}
            actions={!noActions}
            error={error}
            emptyMessage={"Nenhum paciente para atendimento."}
            actionsTypes={
              actionsTypes || [
                "checklist",
                "response",
                "call",
                "finish",
                "delete",
                ...[noOutstanding ? undefined : "suspend"],
              ]
            }
            callsCountField={"attendance.token_audit.calls_count"}
            customizeActions={{
              suspend: {
                title: "Pendente",
                verb: "Marcar como Pendente",
                icon: <ModeStandbyOutlined />,
              },
            }}
            disableActions={(row, action) => {
              if (
                verifyTwentyFourHoursCompany(row.createdAt) &&
                row.status !== "outstanding" &&
                !["delete", "checklist", "response"].includes(action)
              ) {
                return true;
              }

              if (action === "checklist" && !row.has_plan) return true;

              if (action === "response" && row.has_plan) return true;

              if (
                ["call", "delete", "suspend", "finish"].includes(action) &&
                row.status === "finished"
              ) {
                return true;
              }

              if (row.status === "deleted") return true;

              if (
                ["suspend", "delete"].includes(action) &&
                row.status === "outstanding"
              ) {
                return true;
              }

              if (
                action === "delete" &&
                row.medical_record_status !== null &&
                !noEvolutionStatus
              ) {
                return true;
              }

              if (
                action === "finish" &&
                row.medical_record_status !== "finished" &&
                !noEvolutionStatus
              ) {
                return true;
              }
            }}
            actionHandleChecklist={(_, data) => {
              setSelectedMonitoring(data);
              setOpenDialog(true);
            }}
            actionHandleCall={CallFunction}
            actionHandleFinish={(_, i) => {
              updateWorkListStatus(i.attendance.id);
            }}
            actionHandleDelete={(_, i) => {
              updateWorkListStatus(i.attendance.id, "deleted");
            }}
            actionHandleSuspend={(_, i) => {
              updateWorkListStatus(i.attendance.id, "outstanding");
            }}
            actionHandleResponse={(_, data) => {
              setSelectedMonitoring(data);
              setOpenEvolutionWithAttendance(true);
            }}
            actionHandleView={(_, data) => {
              setSelectedMonitoring(data);
            }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <TablePagination
              count={totalItems || 0}
              component="div"
              page={page}
              onPageChange={(_, newPage) => {
                setPage(newPage);
              }}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 20, 75]}
              onRowsPerPageChange={(event) => {
                setLimit(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
