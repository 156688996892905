import {
  newHeader,
  locations,
  identUser,
  describeFooter,
} from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";
import { drugAdmininstrations } from "../config/standardSelects";

function drugPrescriptionPDF(data, patient, documentData) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let medications;

  if (!data?.length) {
    medications = {
      margin: [0, 40, 0, 20],
      text: "Nenhum medicamento encontrado para esta prescrição.",
    };
  } else {
    const proceduresTitles = drugAdmininstrations.reduce(
      (previous, { title, procedure_id }) => {
        return { ...previous, [procedure_id]: title };
      },
      {}
    );

    const groupedMedications = [];
    const notGroupedMedications = [];

    data.forEach((medication) => {
      const groupIndex = groupedMedications.findIndex(
        ({ procedure_id }) => medication.procedure_id === procedure_id
      );

      if (!medication.procedure_id) {
        notGroupedMedications.push(medication);
      } else if (groupIndex !== -1) {
        groupedMedications[groupIndex].medications.push(medication);
      } else {
        groupedMedications.push({
          title: proceduresTitles[medication.procedure_id],
          procedure_id: medication.procedure_id,
          medications: [medication],
        });
      }
    });

    if (notGroupedMedications.length) {
      medications = {
        margin: [0, 40, 140, 20],
        ol: notGroupedMedications.map(
          ({
            name,
            presentation,
            quantity,
            posology,
            notes,
            continuous_use,
          }) => ({
            text: [
              {
                text: `${name} ${quantity} ${
                  continuous_use ? "(Uso contínuo)" : presentation
                }`,
                bold: true,
              },
              { text: ` (${posology}) \n` },
              { text: notes ? notes.toUpperCase() : "" },
            ],
            margin: [0, 0, 0, 10],
          })
        ),
      };
    } else {
      medications = groupedMedications.map((group, index) => [
        {
          margin: [0, index === 0 ? 30 : 10, 140, 10],
          fontSize: 12,
          text: group.title,
          bold: true,
          style: "header",
        },
        {
          margin: [0, 0, 140, 0],
          ol: group.medications.map(
            ({
              name,
              presentation,
              quantity,
              posology,
              notes,
              continuous_use,
            }) => {
              for (let i = name.length; i < 60; i++) {
                name += ".";
              }

              return [
                {
                  text: `${name} | ${quantity ? `${quantity} |` : ""} ${
                    continuous_use ? "Uso contínuo" : presentation
                  }`,
                  bold: true,
                },
                {
                  ul: [
                    {
                      text: posology,
                    },
                    {
                      text: notes || "",
                    },
                  ],
                  margin: [0, 0, 0, 10],
                },
              ];
            }
          ),
        },
      ]);
    }
  }
  const urlType = pdfName + "/types/medications";

  const definitions = {
    info: { title: pdfName },
    pageMargins: [30, 255, 30, 240],
    defaultStyle: {
      fontSize: 10,
    },
    ...newHeader("Receituario", patient, documentData?.company),
    footer: {
      table: {
        body: [
          [
            locations(
              documentData?.createdAt,
              true,
              documentData?.employee,
              urlType
            ),
          ],
          [identUser()],
          [describeFooter()],
        ],
      },
      layout: { defaultBorder: false },
    },
    content: [
      medications,
      {
        margin: [0, -180, 0, 0],
        qr: `${window.TPORTAL_URL.replace(/\/$/, "")}/medications/${pdfName}`,
        fit: "130",
        eccLevel: "H",
        version: 12,
        absolutePosition: { x: 443, y: 395 },
      },
      {
        margin: [0, -10, 0, 0],
        text: "Acessar o receituário",
        absolutePosition: { x: 462, y: 380 },
      },
      {
        margin: [0, -180, 0, 0],
        text: "Validar ( QRCode )",
        absolutePosition: { x: 464, y: 528 },
      },
    ],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default drugPrescriptionPDF;
