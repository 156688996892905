import { Box, Divider } from "@mui/material";
import React, { useState } from "react";
import { PatientsCard, Tabs } from "../../../components";
import ListEvolutions from "./Evolutions/ListEvolutions";

function VisitHystory({ attendance, patient }) {
  const [tabType, setTabType] = useState("patient")
  const tabs = [
    {
      label: "Paciente",
      type: "patient",
      content: <ListEvolutions patient={patient} type={tabType} />,
    },
    {
      label: "Família",
      type: "family",
      content: <ListEvolutions patient={patient} type={tabType}/>
    },
  ];

  return (
    <Box>
      <PatientsCard
        patient={attendance}
        key={"pattientCard"}
        handleClick={() => null}
        old={false}
        onDialog={true}
      />
      <Divider variant="fullWidth" sx={{ mt: 2, mb: 1 }} />
      <Tabs tabs={tabs} handleClick={(tab) => setTabType(tab.type)} />
    </Box>
  );
}

export default VisitHystory;
