import React, { useContext } from "react";
import { Grid, Box, Button, Typography, Divider } from "@mui/material";
import Yup from "../../../config/yup";
import { theme } from "../../../config/theme";
import { format, parseISO } from "date-fns";
import { AppContext } from "../../../contexts/AppContext";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import {
  attendanceStatementPDF,
  followUpStatementPDF,
  maternityLeavePDF,
  medicalOpinionPDF,
  medicalReportPDF,
  medicalStatementPDF,
} from "../../../pdfModels";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DateField,
  PaginatedAutocompleteField,
  SelectField,
  TextField,
} from "../../../components/FormFields";
import DeclarationCard from "./DeclarationCard";
import { useCids } from "../../../service";

const AddButton = ({ children, disabled, ...props }) => {
  return (
    <Button
      variant="text"
      sx={{
        color: theme.palette.primary.light,
        fontWeight: "bold",
        padding: 0,
      }}
      disabled={disabled}
      {...props}
    >
      <AddCircleIcon sx={{ marginRight: 0.5 }} />
      <Typography variant="span">{children}</Typography>
    </Button>
  );
};

export default function DeclarationForm({
  isPregnancy,
  defaultCids,
  patient,
  handleClose,
  odonto,
  ceo,
}) {
  const { setDeclarations, declarations, disableFields } =
    useContext(AppContext);

  const { getCids } = useCids();

  const initialValues = {
    declaration_type: "Atestado",
    arrival_time: null,
    departure_time: null,
    days: "",
    cid: defaultCids?.[0] || null,
    companion_name: "",
    observations: "",
    ctps: "",
    ctps_series: "",
    weeks_of_pregnancy: "",
    maternity_leave: "",
    maternity_license_date: null,
  };

  const validations = Yup.object().shape(
    {
      declaration_type: Yup.string().required("Selecione o tipo de declaração"),
      arrival_time: Yup.date().when(["declaration_type", "days"], {
        is: (declaration_type, days) => {
          return (
            ["Comparecimento", "Acompanhamento"].includes(declaration_type) ||
            (declaration_type === "Atestado" && days <= 0)
          );
        },
        then: Yup.date()
          .typeError("Horário inválido")
          .required("É requerido")
          .nullable(),
        otherwise: Yup.date().typeError("Horário inválido").nullable(),
      }),
      departure_time: Yup.date().when(["declaration_type", "days"], {
        is: (declaration_type, days) => {
          return (
            ["Comparecimento", "Acompanhamento"].includes(declaration_type) ||
            (declaration_type === "Atestado" && days <= 0)
          );
        },
        then: Yup.date()
          .typeError("Horário inválido")
          .required("É requerido")
          .nullable()
          .when("arrival_time", (arrival_time, schema) => {
            return schema.test({
              test: (departure_time) =>
                !!arrival_time && departure_time > arrival_time,
              message: "Escolha um horário final posterior ao horário inicial",
            });
          }),
        otherwise: Yup.date().typeError("Horário inválido").nullable(),
      }),
      days: Yup.string().when(
        ["arrival_time", "departure_time", "declaration_type"],
        {
          is: (arrival_time, departure_time, declaration_type) => {
            return (
              declaration_type === "Atestado" &&
              (!arrival_time || !departure_time)
            );
          },
          then: (schema) =>
            schema
              .min(1, "A quantidade deve ser maior que 0")
              .required("É requerido")
              .typeError("O valor do campo deve ser um número"),
        }
      ),
      cid: Yup.object().nullable(),
      companion_name: Yup.string().when("declaration_type", {
        is: "Acompanhamento",
        then: (schema) => schema.required("É requerido"),
      }),
      observations: Yup.string().when("declaration_type", {
        is: (declaration_type) =>
          ["Relatorio", "Laudo"].includes(declaration_type),
        then: (schema) => schema.required("É requerido"),
      }),
      ctps: Yup.string(),
      ctps_series: Yup.string(),
      weeks_of_pregnancy: Yup.string().when("declaration_type", {
        is: "AtestadoMaternidade",
        then: (schema) => schema.required("É requerido"),
      }),
      maternity_leave: Yup.string().when("declaration_type", {
        is: "AtestadoMaternidade",
        then: (schema) => schema.required("É requerido"),
      }),
      maternity_license_date: Yup.date()
        .nullable()
        .when("declaration_type", {
          is: "AtestadoMaternidade",
          then: (schema) =>
            schema.required("É Requerido").typeError("Data Inválida"),
        }),
    },
    [
      ["arrival_time", "days"],
      ["departure_time", "days"],
      ["departure_time", "arrival_time"],
      ["days", "arrival_time"],
      ["days", "departure_time"],
    ]
  );

  const { handleSubmit, control, reset, watch } = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const [selectedDeclarationType, arrivalTime, departureTime, days] = watch([
    "declaration_type",
    "arrival_time",
    "departure_time",
    "days",
  ]);

  const declarationTypes = {
    Atestado: {
      label: odonto || ceo ? "Atestado" : "Atestado Médico",
      descriptions: [
        {
          labelFormatter: (data) => {
            return data.days ? "Dias de atestado" : "Hora de chegada";
          },
          valueFormatter: (data) => (data.days ? data.days : data.arrival_time),
        },
        {
          label: "Hora de saída",
          valueKey: "departure_time",
          visible: (data) => !data.days,
        },
        {
          label: "CID 10",
          valueKey: "cid_id",
          visible: (data) => data.cid_id,
        },
      ],
      handler: (data) => {
        const declaration = { cid_id: data.cid?.id || null };

        if (!!data.days) {
          declaration.days = data.days;
        } else {
          declaration.arrival_time = format(data.arrival_time, "HH:mm");
          declaration.departure_time = format(data.departure_time, "HH:mm");
        }

        const pdf = medicalStatementPDF(declaration, patient);
        return { pdf, ...declaration };
      },
    },
    Comparecimento: {
      label: "Declaração de Comparecimento",
      descriptions: [
        {
          label: "Hora de chegada",
          valueKey: "arrival_time",
        },
        {
          label: "Hora de saída",
          valueKey: "departure_time",
        },
      ],
      handler: (data) => {
        const declaration = {
          arrival_time: format(data.arrival_time, "HH:mm"),
          departure_time: format(data.departure_time, "HH:mm"),
        };

        const pdf = attendanceStatementPDF(declaration, patient);

        return { pdf, ...declaration };
      },
    },
    Acompanhamento: {
      label: "Declaração de Acompanhante",
      descriptions: [
        {
          label: "Hora de chegada",
          valueKey: "arrival_time",
        },
        {
          label: "Hora de saída",
          valueKey: "departure_time",
        },
        {
          label: "Acompanhante",
          valueKey: "companion_name",
        },
      ],
      handler: (data) => {
        const declaration = {
          arrival_time: format(data.arrival_time, "HH:mm"),
          departure_time: format(data.departure_time, "HH:mm"),
          companion_name: data.companion_name,
        };

        const pdf = followUpStatementPDF(declaration, patient);

        return { pdf, ...declaration };
      },
    },
    Relatorio: {
      label: "Relatório Médico",
      descriptions: [
        {
          label: "Observação",
          valueKey: "observations",
        },
      ],
      handler: (data) => {
        const declaration = { observations: data.observations };

        const pdf = medicalReportPDF(declaration, patient);

        return { pdf, ...declaration };
      },
    },
    Laudo: {
      label: "Laudo Médico",
      descriptions: [
        {
          label: "CID 10",
          valueKey: "cid_id",
        },
        {
          label: "Observação",
          valueKey: "observations",
        },
      ],
      handler: (data) => {
        const declaration = {
          observations: data.observations,
          cid_id: data.cid?.id || null,
        };

        const pdf = medicalOpinionPDF(declaration, patient);
        return { pdf, ...declaration };
      },
    },
  };

  if (isPregnancy) {
    declarationTypes["AtestadoMaternidade"] = {
      label: "Licença Maternidade",
      descriptions: [
        {
          label: "Número da carteiro profissional",
          valueKey: "ctps",
          grid: 12,
          visible: (data) => !!data.ctps,
        },
        {
          label: "Número Carteira Serie",
          valueKey: "ctps_series",
          grid: 12,
          visible: (data) => !!data.ctps_series,
        },
        {
          label: "Tempo de Gestação",
          valueKey: "weeks_of_pregnancy",
          grid: 12,
        },
        {
          label: "Duração da Licença",
          valueKey: "maternity_leave",
          grid: 12,
        },
        {
          label: "Início da lincença",
          valueFormatter: (data) =>
            format(parseISO(data.maternity_license_date), "dd/MM/yyyy"),
          grid: 12,
        },
      ],
      handler: (data) => {
        const declaration = {
          ctps: data.ctps,
          ctps_series: data.ctps_series,
          weeks_of_pregnancy: data.weeks_of_pregnancy,
          maternity_leave: data.maternity_leave,
          maternity_license_date: format(
            data.maternity_license_date,
            "yyyy-MM-dd"
          ),
        };

        const pdf = maternityLeavePDF(declaration, patient);

        return { pdf, ...declaration };
      },
    };
  }

  const declarationOptions = Object.entries(declarationTypes).map(
    ([key, value]) => ({
      label: value.label,
      value: key,
    })
  );

  async function addDeclaration(values) {
    const declarationType = declarationTypes[values.declaration_type];
    const { pdf, ...newDeclaration } = declarationType.handler(values);

    newDeclaration.type = values.declaration_type;
    newDeclaration.id = pdf.name.replace(".pdf", "");
    newDeclaration.original_file_name = pdf.name;
    newDeclaration.data = await pdf.base64();
    newDeclaration.signature_settings = {
      visible_sign_page: "*",
      visible_sign_x: 170,
      visible_sign_y: 595,
    };

    setDeclarations((declarations) => [...declarations, newDeclaration]);
    reset(
      { declaration_type: values.declaration_type },
      { keepDefaultValues: true }
    );
  }

  function removeDeclaration(key) {
    const newDeclarations = declarations.filter((_, index) => key !== index);
    setDeclarations(newDeclarations);
  }

  const attendanceStatementDeclarations = declarations.reduce(
    (declarations, nextDeclaration, index) => {
      if (nextDeclaration.type === selectedDeclarationType) {
        declarations.push({ ...nextDeclaration, key: index });
      }

      return declarations;
    },
    []
  );

  const numberMask = Array.from({ length: 7 }).fill(/\d/);

  return (
    <>
      <Box component="form">
        <Grid container spacing={2} marginTop={1} alignItems="center">
          <Grid container item xs={12} marginBottom={2} spacing={2}>
            <Grid item xs={4}>
              <SelectField
                control={control}
                label="Tipo de Declaração"
                name="declaration_type"
                options={declarationOptions}
                optionLabelKey="label"
                optionValueKey="value"
                customOnChange={(value) =>
                  reset(
                    { declaration_type: value },
                    { keepDefaultValues: true }
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          {["Atestado", "Acompanhamento", "Comparecimento"].includes(
            selectedDeclarationType
          ) && (
            <>
              {" "}
              <Grid item xs={4}>
                <DateField
                  control={control}
                  name="arrival_time"
                  label="Horário de Chegada"
                  type="time"
                  required={!days}
                />
              </Grid>
              <Grid item xs={4}>
                <DateField
                  control={control}
                  name="departure_time"
                  label="Horário de Saída"
                  type="time"
                  required={!days}
                />
              </Grid>
            </>
          )}
          {selectedDeclarationType === "Atestado" && (
            <>
              <Grid item xs={1}>
                <Typography>ou</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  control={control}
                  name="days"
                  label="Dias"
                  required={!arrivalTime && !departureTime}
                  disabled={!!arrivalTime || !!departureTime}
                />
              </Grid>
            </>
          )}
          {["Atestado", "Laudo"].includes(selectedDeclarationType) && (
            <Grid item xs={12}>
              <PaginatedAutocompleteField
                control={control}
                label="CID"
                name="cid"
                service={(params) =>
                  getCids({ ...params, patient_id: patient?.id })
                }
                autoCompleteProps={{
                  getOptionLabel: (option) =>
                    `${option.id ? option.id : ""} ${option.description}`,
                }}
                optionCompareKey="id"
                filterKey="fieldValue"
                queryKey="cids"
              />
            </Grid>
          )}
          {selectedDeclarationType === "Acompanhamento" && (
            <Grid item xs={4}>
              <TextField
                control={control}
                name="companion_name"
                type="text"
                label="Nome do Acompanhante"
                required
              />
            </Grid>
          )}
          {["Relatorio", "Laudo"].includes(selectedDeclarationType) && (
            <Grid item xs={12}>
              <TextField
                control={control}
                name="observations"
                type="text"
                label="Observação"
                multiline
                minRows={4}
                maxRows={4}
                required
              />
            </Grid>
          )}
          {selectedDeclarationType === "AtestadoMaternidade" && (
            <>
              <Grid item xs={8}>
                <TextField
                  control={control}
                  name="ctps"
                  type="number"
                  label="Número da Carteira de Trabalho"
                  mask={numberMask}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  control={control}
                  name="ctps_series"
                  type="number"
                  label="Série da Carteira de Trabalho"
                  mask={numberMask.slice(0, 4)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  control={control}
                  name="weeks_of_pregnancy"
                  type="number"
                  label="Tempo de Gestação"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  control={control}
                  name="maternity_leave"
                  type="number"
                  label="Duração da Licença"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <DateField
                  control={control}
                  name="maternity_license_date"
                  label="Início da Licença"
                  required
                />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="flex-end"
            margin="1.2rem auto"
          >
            <AddButton onClick={handleSubmit(addDeclaration)}>
              Adicionar declaração
            </AddButton>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        {attendanceStatementDeclarations?.map((data) => (
          <DeclarationCard
            key={`${data.type}-${data.key}`}
            data={data}
            descriptions={
              declarationTypes[selectedDeclarationType].descriptions
            }
            handleRemove={() => removeDeclaration(data.key)}
          />
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" margin="1.5rem auto">
        <Button
          onClick={handleClose}
          disabled={disableFields || !declarations.length}
        >
          Salvar
        </Button>
      </Box>
    </>
  );
}
