import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

export default function useMonitoring() {
  
  const monitoring = "/monitorings";
  const patients = "/patients";
  const prescriptions = "/prescriptions";
  const againstReferences = "/against-references";
  const documents = "/documentations";


  async function getMonitorings(params = {}) {
    try {
      const response = await instance.get(`${monitoring}`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getPatientPrescriptions(patientId, params = {}) {
    try {
      const response = await instance.get(
        patients + "/" + patientId + prescriptions,
        { params: urlQuery(params) }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getPatientDocuments(patientId, params = {}) {
    try {
      const response = await instance.get(
        patients + "/" + patientId + documents,
        { params: urlQuery(params) }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getPatientAgainstReferences(patientId, params = {}) {
    try {
      const response = await instance.get(
        patients + "/" + patientId + againstReferences,
        { params: urlQuery(params) }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updatePrescriptionsItems({ prescriptionId, type, id, value }) {
    try {
      const response = await instance.put(
        `${prescriptions}/${prescriptionId}/${type}/update/${id}`,
        { done: value }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updateAgainstReference(againstReferenceId, changes) {
    try {
      const response = await instance.put(
        againstReferences + "/" + againstReferenceId + "/patient-referred",
        changes
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updatePatientList(patientId, changes) {
    try {
      const response = await instance.put(
        againstReferences + patients + "/" + patientId,
        changes
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updateWorkList({ attendanceID, changes }) {
    try {
      const response = await instance.patch(
        `/attendances/${attendanceID}/worklist-status/finish`,
        changes
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getPatientPrescriptions,
    updatePrescriptionsItems,
    getPatientAgainstReferences,
    updateAgainstReference,
    updatePatientList,
    getPatientDocuments,
    updateWorkList,
    getMonitorings
  };
}
