import { Avatar } from "@mui/material";
import { ReactComponent as Male } from "../../assets/svg/male.svg";
import { ReactComponent as Female } from "../../assets/svg/female.svg";
import React from "react";

function AvatarGender({ gender, size }) {
  const gen = gender?.toLowerCase();
  return (
    <Avatar
      sx={{
        bgcolor: "#fafafa",
        width: size,
        height: size,
      }}
    >
      {gen === "masculino" ? (
        <Male />
      ) : gen === "feminino" ? (
        <Female />
      ) : (
        <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.light }}>
          IN
        </Avatar>
      )}
    </Avatar>
  );
}

export default AvatarGender;
