import React, { useState } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import useNotifier from "../../../hooks/useNotifier";
import { useMutation, useQueryClient } from "react-query";
import { useMicroArea, useProvider } from "../../../service";
import { FormTextField, SubmitButton } from "../../../components";
import FormSelectFieldWithPaginate from "../../../components/Form/FormSelectWithPaginate";
import TableFilter from "../../../components/Table/TableFilter";
import Yup from "../../../config/yup";

export default function MicroAreaForm({ microArea, type = "register" }) {
  const theme = useTheme();
  const notify = useNotifier();
  const [employees, setEmployees] = useState(() => microArea?.employees || []);

  const queryClient = useQueryClient();
  const { postMicroArea, patchMicroArea } = useMicroArea();
  const { getProviders } = useProvider();
  const microAreaMutation = useMutation(postMicroArea);
  const patchMicroAreaMutation = useMutation(patchMicroArea);

  function handleSubmit({ name }, { resetForm }) {
    const employeesIds = employees.map(({ id }) => id);
    const data = {
      name,
      employees_ids: employeesIds,
    };

    if (type === "register") {
      microAreaMutation.mutate(data, {
        onSuccess: (response) => {
          notify(response.message, "success");
          resetForm();
          setEmployees([]);
          queryClient.invalidateQueries("micro-areas");
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      });
    } else if (type === "edit") {
      patchMicroAreaMutation.mutate(
        { id: microArea.id, data },
        {
          onSuccess: (response) => {
            notify(response.message, "success");
            queryClient.invalidateQueries("micro-areas");
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    }
  }

  function addEmployee(employee, setFieldValue) {
    if (employee) {
      setEmployees((employees) => [...employees, employee]);
      setFieldValue("employee", null);
    }
  }

  function removeEmployee(id) {
    const filteredEmployees = employees.filter(
      (employee) => employee.id !== id
    );
    setEmployees(filteredEmployees);
  }

  const initialValues = {
    name: microArea?.name || "",
    employee: null,
  };

  const validations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
  });

  const employeeTableColumns = [
    {
      name: "Código",
      field: "number",
    },
    {
      name: "Nome",
      field: "name",
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validations}
      onSubmit={handleSubmit}
    >
      {({ values: { employee }, setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormTextField name="name" label="Nome da micrárea" required />
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography
                variant="h2"
                sx={{
                  color: theme.palette.primary.light,
                  fontSize: 15,
                  fontWeight: 500,
                }}
              >
                Agentes Comunitários de Saúde
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormSelectFieldWithPaginate
                service={(params) =>
                  getProviders({
                    ...params,
                    id_employee_type: "3e2f7167-4f0e-4602-a24d-a8cf58e4c596",
                    active: true,
                  })
                }
                fields="name"
                label="Profissional"
                name="employee"
                required
                noOptionsText="Nenhum profissional encontrado"
                filterOptionsCondition={(_, option) =>
                  !!employees.length
                    ? employees.some(({ id }) => id !== option.id)
                    : true
                }
              />
            </Grid>
            <Grid item xs={2}>
              <SubmitButton
                handleClick={() => addEmployee(employee, setFieldValue)}
              >
                Adicionar
              </SubmitButton>
            </Grid>
            <Grid xs={12} mt={2} ml={2}>
              <TableFilter
                data={employees}
                actions
                actionUniqueIdentifier="id"
                emptyMessage="Nenhum profissional adicionado"
                columns={employeeTableColumns}
                actionsTypes={["delete"]}
                actionHandleDelete={removeEmployee}
                confirmMessage="Você realmente deseja *action* este profissional ?"
              />
            </Grid>
            <Grid item xs={2}>
              <SubmitButton disabled={!employees.length}>Salvar</SubmitButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
