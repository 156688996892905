import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

export default function useRegulation() {
  const path = "/regulations";
  const getRegulations = async (params) => {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response?.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const updateRegulation = async ({ id, data }) => {
    try {
      const response = await instance.put(`${path}/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const cancelRegulation = async (id) => {
    try {
      const response = await instance.patch(`${path}/cancel`, { id: id });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const confirmRegulation = async (id) => {
    try {
      const response = await instance.post(`${path}/confirmation`, {
        scheduleItemId: id,
        confirmPresence: "P",
        step: 3,
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  return {
    getRegulations,
    updateRegulation,
    cancelRegulation,
    confirmRegulation,
  };
}
