import React, { useContext } from "react";
import { Tabs } from "../../../components";
import ListReference from "./ListReference";
import NewReference from "./NewReference";
import { Badge } from "@mui/material";
import { AppContext } from "../../../contexts/AppContext";

function Reference({ patient, handleClose }) {
  const { againstReference } = useContext(AppContext);

  const lengthAgainstReference = againstReference.length;

  const tabs = [
    {
      label: "Cadastrar",
      content: <NewReference handleClose={handleClose} patient={patient} />,
      icon: (
        <Badge
          color="secondary"
          overlap="circular"
          badgeContent={lengthAgainstReference}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
      ),
    },
    {
      label: "Lista de Referência / Contrarreferências ",
      content: <ListReference patient={patient} handleClose={handleClose} />,
    },
  ];
  return (
    <>
      <Tabs tabs={tabs} />
    </>
  );
}

export default Reference;
