import { Box, Grid, Typography } from "@mui/material";
import LunaLoading from "../../assets/svg/LUNA_IA_Search.svg";
import { ReportProblem } from "@mui/icons-material";

function LunaAI({ data, isLoading, isError }) {
  const borderStyle = "1px solid #d3d3d3";
  const titleStyle = { fontWeight: "bold", marginTop: "2rem" };
  const textStyle = { fontStyle: "italic" };

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{ minWidth: "425px", height: "85vh" }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="2rem"
        >
          <img
            style={{ maxHeight: "300px", margin: "auto" }}
            src={LunaLoading}
            alt="Analisando dados do paciente"
          />
          <Typography
            color="primary"
            component="h4"
            variant="h5"
            sx={{ textAlign: "center" }}
          >
            Analisando dados do paciente...
          </Typography>
        </Box>
      </Box>
    );
  }

  if (isError || (!isLoading && !data)) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{ minWidth: "425px", paddingTop: "3rem" }}
      >
        <ReportProblem
          sx={{ fontSize: 80, marginBottom: "1rem", color: "#ff5252" }}
        />
        <Typography color="#ff5252" variant="h5" component="h4">
          Não foi possível realizar a requisição
        </Typography>
      </Box>
    );
  }

  const formatText = (text) => {
    const parts = text.split(/\*\*(.*?)\*\*/g);
    return parts.map((part, index) => {
      const isTitle = index % 2 !== 0;
      return (
        <Typography
          key={index}
          sx={isTitle ? titleStyle : textStyle}
          component={isTitle ? "h6" : "p"}
        >
          {part}
        </Typography>
      );
    });
  };

  const safelySplitText = (text) => {
    if (Array.isArray(text)) {
      return text.flatMap((t) => t.split("\n\n"));
    }
    return text ? text.split("\n\n") : ["Sem informações"];
  };

  const processAttribute = (attribute) => {
    return safelySplitText(attribute).map((line, index) => (
      <Typography color="primary" key={index}>
        {formatText(line)}
      </Typography>
    ));
  };

  const dataObjs = [
    {
      title: "Alertas",
      data: data.alerts,
      titleColor: "#ff5252",
    },
    {
      title: "Resumo",
      data: data.text,
      titleColor: "primary",
    },
    {
      title: "Plano",
      data: data.plan,
      titleColor: "primary",
    },
    {
      title: "Procedimentos",
      data: data.procedures,
      titleColor: "primary",
    },
    {
      title: "Sugestão de Prescrição",
      data: data.prescription,
      titleColor: "primary",
    },
    {
      title: "Observações",
      data: data.observations,
      titleColor: "primary",
    },
  ];

  return (
    <Grid sx={{ maxWidth: "425px", p: "0.5rem" }}>
      <Grid
        sx={{
          m: "2rem auto",
          p: "1rem",
          border: borderStyle,
          borderRadius: "1rem",
        }}
      >
        <Typography
          color="primary"
          component="h4"
          variant="h5"
          sx={{ fontWeight: "bold" }}
        >
          Paciente:
        </Typography>
        <hr style={{ borderTop: borderStyle }} />
        <Typography color="primary" sx={{ fontWeight: "bold" }}>
          {data.patient.name}, {data.patient.age} anos
          <br />
        </Typography>
        <Typography color="primary">{data.case}</Typography>
      </Grid>

      {dataObjs.map((obj, index) => {
        return (
          <Grid
            sx={{
              m: "2rem auto",
              p: "1rem",
              border: borderStyle,
              borderRadius: "1rem",
            }}
            key={index}
          >
            <Typography
              color={obj.titleColor}
              component="h4"
              variant="h5"
              sx={{ fontWeight: "bold" }}
            >
              {obj.title}:
            </Typography>
            <hr style={{ borderTop: borderStyle }} />
            <Typography sx={{ fontWeight: "bold" }}>
              {processAttribute(obj.data)}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default LunaAI;
