import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/treatments/type";
const pathUpdate = "/treatments/type/update";
function useTreatmentTypes() {
  async function getTreatmentTypes(params = {}) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function getTreatmentType(id) {
    try {
      const response = await instance.get(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postTreatmentType(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function updateTreatmentType(id, data) {
    try {
      const response = await instance.put(`${pathUpdate}/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getTreatmentTypes,
    getTreatmentType,
    postTreatmentType,
    updateTreatmentType,
  };
}

export default useTreatmentTypes;
