import { Grid, Stack, Box } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import Yup from "../../../config/yup";
import { FormSelectField, SubmitButton } from "../../../components";
import FormMultipleSelectField from "../../../components/Form/FormMultipleSelectField";
import { pt } from "yup-locale-pt";
import { Loading } from "../../../helper";
import _ from "lodash";
import instance from "../../../config/api";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import useProvider from "../../../service/useProvider";
import removeEmpty from "../../../helper/removeEmpty";
import { header, title, footer } from "../../../pdfModels/pdfComponents";
import useNotifier from "../../../hooks/useNotifier";

Yup.setLocale(pt);

export default function ProviderFormRegister() {
  const [loadingButton, setLoadingButton] = React.useState(false);
  const notify = useNotifier();
  const [loading, setLoading] = React.useState(false);
  const { getTypes } = useProvider();
  const [servicoArray, setServicoArray] = React.useState([]);

  React.useEffect(() => {
    async function fetchEmployees() {
      try {
        setLoading(true);
        const response = await getTypes();
        setServicoArray(response.data);
      } catch (err) {
        notify(err.message, "error");
      } finally {
        setLoading(false);
      }
    }

    fetchEmployees();
  }, []);

  const initialValues = {
    printOrder: null,
    employee_Type: [],
  };
  const validations = Yup.object({
    printOrder: Yup.string().required("É requerido").nullable(),
    employee_Type: Yup.array().nullable(),
  });

  async function handleSubmit(values) {
    setLoadingButton(true);
    setTimeout(() => {
      setLoadingButton(false);
    }, 500);

    try {
      setLoading(true);
      const response = await instance.post(
        `/reports/employees`,
        removeEmpty({
          order_by: values.printOrder,
          id_employeeType: values.employee_Type.map((employee) => employee.id),
        })
      );

      values.printOrder =
        values.printOrder === "employee_number"
          ? "Ordem Númerica"
          : "Ordem Alfabética";

      if (response.data && response.data.length) {
        pdfAttendances(response.data, values);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      notify(err.message, "error");
    }
  }

  function pdfAttendances(arrayObject, values) {
    if (!!arrayObject) {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      const filtro = [
        {
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text: "Ordem de agrupamento: " + values.printOrder,
                  fontSize: 8,
                  alignment: "center",
                  margin: [0, 5, 0, 5],
                },
              ],
            ],
          },
          layout: "noBorders",
        },
      ];

      let employees = [];

      if (values.employee_Type.lentgth) {
        employees = arrayObject.filter(({ employee_Type: { description } }) => {
          return values.employee_Type.some(
            (type) => description === type.description
          );
        });
      } else {
        employees = arrayObject;
      }

      const dados = employees.map((cliente) => {
        return [
          {
            text: cliente?.number,
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          },
          {
            text: cliente?.name,
            fontSize: 8,
            alignment: "left",
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          }, // e?.number+" - "+e?.name,
          {
            text: cliente?.employee_Type?.description,
            fontSize: 8,
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          }, //e?.employee?.employee_Type?.description,
          {
            text: cliente?.council_registration_number,
            fontSize: 8,
            margin: [0, 1, 0, 1],
            alignment: "center",
            border: [false, false, false, true],
          }, // e?.council_registration_number
          {
            text: cliente?.physic_national,
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          }, //e?e?.physic_national,
          {
            text: cliente?.contact?.email,
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          }, //e?.contact.email,
          {
            text: cliente?.contact?.cell_phone,
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
            border: [false, false, false, true],
          }, //e?.contact.cell_phone,
        ];
      });

      const details = [
        {
          table: {
            style: "tableExample",
            headerRows: 1,
            widths: [40, "*", 73, 40, 70, 80, 80],

            body: [
              [
                {
                  text: "Cod. Profissional",
                  style: "tableHeader",
                  fontSize: 8,
                  alignment: "center",
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Profissional ",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Tipo Profissional",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Cod. Conselho ",
                  style: "tableHeader",
                  fontSize: 8,
                  alignment: "center",
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "CPF/CNPJ",
                  style: "tableHeader",
                  alignment: "center",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "E-mail",
                  style: "tableHeader",
                  alignment: "center",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Telefone",
                  style: "tableHeader",
                  alignment: "center",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
              ],

              ...dados,
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              //horizontal
              return i === 1 || i === node.table.body.length ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              //vertical
              return i === 0 || i === node.table.widths.length ? 2 : 2;
            },
            hLineColor: function (i, node) {
              return i === 1 || i === node.table.body.length ? "black" : "gray";
            },

            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? "white"
                : "white";
            },
          },
        },
      ];
      var total = [
        {
          text: "Total de Profissionais:" + " " + dados.length,
          fontSize: 8,
          bold: true,
          margin: [10, 10, 0, 15],
        },
      ];

      const docDefinitios = {
        pageMargins: [40, 105, 40, 95],
        defaultStyle: {
          fontSize: 10,
        },
        ...header(),
        footer: footer(),
        content: [title("Relatório de Profissional"), filtro, details, total],
      };

      pdfMake.createPdf(docDefinitios).print();
    } else {
      return [];
    }
  }

  const options =
    !!servicoArray && servicoArray.length
      ? servicoArray.map(({ description, id }) => {
          const firstLetter = description[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            id,
            description,
          };
        })
      : [];
  //----------------------Inicio do codigo do front-end----------------------

  return (
    <>
      {!loading ? (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validations}
          >
            {() => (
              <Form>
                <Grid container spacing={2} sx={{ marginTop: 2.5 }}>
                  <Grid item xs={8}>
                    <FormSelectField
                      name="printOrder"
                      label="Ordem de impressão"
                      options={{
                        employee_describe:
                          "Ordem do Tipo de Profissional: Alfabética(A - Z)",
                        employee_number:
                          "Ordenar Tipo de Profissional: Numérica (1, 2, 3 ...) ",
                      }}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: 2.5 }}>
                  <Grid item xs={8}>
                    <FormMultipleSelectField
                      name="employee_Type"
                      label={"Tipo de Profissional: "}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      keys={["id", "description"]}
                    />
                  </Grid>
                </Grid>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "right",
                    justifyContent: "right",
                    pt: 2,
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <SubmitButton loading={loadingButton}>
                      Gerar PDF/Impressão
                    </SubmitButton>
                  </Stack>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
