import { Box, TextField, Typography } from "@mui/material";
import { useField } from "formik";
import React from "react";
import InputMask from "react-input-mask";

function FormTextField({
  name,
  mask,
  size = "small",
  customHandleChange,
  maxLength,
  error,
  min,
  max,
  ...props
}) {
  const [field, meta] = useField(name);

  function onChange(event) {
    const value = event.target.value;
    const numberRegex = /^-?\d*\.?\d+$/;

    if (maxLength && value?.length > maxLength) {
      return;
    }

    if (numberRegex.test(value) && (min || max)) {
      if (min > value || max < value) return;
    }

    field.onChange(event);

    if (customHandleChange instanceof Function) {
      customHandleChange(value);
    }
  }

  const settings = {
    ...field,
    onChange,
    mask,
    ...props,
    size: size,
    fullWidth: true,
    variant: "outlined",
    InputLabelProps: { shrink: true },
  };

  if ((meta && meta.touched && meta.error) || error) {
    settings.error = true;
    settings.helperText = meta.error || error;
  }

  return (
    <Box
      component={"span"}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {mask ? (
        <InputMask {...settings}>{() => <TextField {...settings} />}</InputMask>
      ) : (
        <TextField {...settings} />
      )}
      {maxLength && (
        <Typography
          sx={{
            marginTop: 1,
            alignSelf: "flex-end",
            maxWidth: "max-content",
            fontSize: "10px",
            color: (theme) => theme.palette.primary.light,
          }}
        >
          {(field?.value?.length || 0) + " / " + maxLength}
        </Typography>
      )}
    </Box>
  );
}

export default FormTextField;
