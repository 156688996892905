import { Grid, Typography, useTheme, Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet";
import { BACK_IPS, TITLE_NAME, IPS_REGEX } from "../../config/config";
import { FaLock, FaUserAlt } from "react-icons/fa";
import Yup from "../../config/yup";
import useNotifier from "../../hooks/useNotifier";
import { AuthContext } from "../../contexts/AuthContext";
import { ReactComponent as TascomLogo } from "../../assets/svg/tascomBlue.svg";
import { ReactComponent as BackLogo } from "../../assets/svg/backlogo.svg";
import { ReactComponent as ZurichLogo } from "../../assets/svg/zurich.svg";
import { grey } from "@mui/material/colors";
import LoginCompany from "./LoginCompany";
import { useMutation } from "react-query";
import TextField from "../../components/FormFields/TextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

function Login() {
  const notify = useNotifier();
  const [userData, setUserData] = React.useState(null);
  const [companies, setCompanies] = React.useState([]);
  const { loginVerified, setLoginVerified, verifyLogin, login } =
    React.useContext(AuthContext);
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const postVerifyLogin = useMutation(verifyLogin);
  const initialValues = { userLogin: "", passLogin: "" };
  const isIpValid = window.API_URL?.match(IPS_REGEX);
  const ipPortExtractedFromApiUrl = isIpValid ? isIpValid[0] : "default";
  const loginMutation = useMutation(login);

  const LoginSchema = Yup.object().shape({
    userLogin: Yup.string()
      .max(50, "Número de caracteres excedido")
      .required("* Digite seu login"),
  });

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: initialValues,
  });

  async function onSubmit(values) {
    postVerifyLogin.mutate(values, {
      onSuccess: (response) => {
        if (
          response?.data.companies.length === 1 &&
          response?.data.companies[0].related_specialties.length === 1
        ) {
          setLoading(true);

          const company = response.data.companies[0];

          loginMutation.mutate(
            {
              ...values,
              specialty_id: company.related_specialties[0].id,
              company_id: company.id,
            },
            {
              onError: (error) => {
                notify(error.message, "error");
              },
              onSettled: () => {
                setLoading(false);
              },
            }
          );
          return;
        }
        setCompanies(response.data.companies);
        setLoginVerified(true);
        setUserData(values);
      },
      onError: (error) => {
        notify(error.message, "error");
        setValue("passLogin", "");
      },
    });
  }

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Login</title>
      </Helmet>
      <Grid>
        <Grid container>
          <Grid container>
            <Grid
              item
              container
              md={6}
              xs={12}
              sx={{
                position: "relative",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  bottom: 50,
                  margin: "auto",
                  maxWidth: "max-content",
                  right: 0,
                  fontSize: "1.2em",
                  fontWeight: "bold",
                  color: (theme) => theme.palette.primary.main,
                  left: 0,
                }}
              >
                {`@TSaude Versão ${
                  window.TSAUDE_VERSION ? window.TSAUDE_VERSION : "DEV"
                } - Ambiente (${BACK_IPS[ipPortExtractedFromApiUrl]})`}
              </Typography>
              <Box width={"100%"} padding={10}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TascomLogo />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 12,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      fontSize={32}
                      fontWeight={"medium"}
                      color={grey[700]}
                    >
                      Bem Vindo ao
                    </Typography>
                    <ZurichLogo width={120} />
                  </Box>
                  <Typography color={grey[700]} fontSize={16}>
                    Medicina Preventiva | Tascom
                  </Typography>
                </Box>
                {!loginVerified && (
                  <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container rowSpacing={3} marginTop={1}>
                      <Grid item xs={12}>
                        <TextField
                          control={control}
                          leftIcon={
                            <FaUserAlt
                              style={{
                                color: theme.palette.primary.main,
                              }}
                            />
                          }
                          name="userLogin"
                          sx={{ backgroundColor: "white" }}
                          placeholder="Digite seu login.."
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          control={control}
                          leftIcon={
                            <FaLock
                              style={{ color: theme.palette.primary.main }}
                            />
                          }
                          placeholder="*******"
                          name="passLogin"
                          sx={{ backgroundColor: "white", color: "secondary" }}
                          password
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          disabled={loading}
                          variant="contained"
                          type="submit"
                          fullWidth
                          sx={{ backgroundColor: theme.palette.primary.light }}
                        >
                          <Typography fontWeight={"bold"} margin={2}>
                            Entrar
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {!!companies?.length && (
                  <LoginCompany companies={companies} userData={userData} />
                )}
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              xs={0}
              sx={{
                backgroundColor: (theme) => theme.palette.secondary.main,
                height: "111vh",
                overflow: "hidden",
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              <BackLogo
                style={{
                  marginLeft: "-400px",
                  marginTop: "100px",
                  opacity: 0.2,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
