import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/vaccines/";

export default function useVaccine() {
  async function getVaccines(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  }

  async function getVaccineHasBatch(id, params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(path + id + "/batches", {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  }

  async function postVaccines(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  }

  async function updateVaccines(id, data) {
    try {
      const response = await instance.put(`${path}update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message);
    }
  }
  return {
    getVaccines,
    postVaccines,
    updateVaccines,
    getVaccineHasBatch,
  };
}
