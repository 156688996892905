import { Box, Button } from "@mui/material";
import React from "react";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { useQuery } from "react-query";
import { useExamIntegrations } from "../../service";
import { useNotifier } from "../../hooks";
import ViewedDocumentHistory from "./ViewedDocumentHistory";
import { DialogMedium, Loading } from "../../helper";

export default function LaboratorialExams({ patient }) {
  const notify = useNotifier();
  const [openHistory, setOpenHistory] = React.useState(false);

  const { viewedDocumentHistoryGenerate } = useExamIntegrations();
  const [queryData, setQueryData] = React.useState(null);

  const viewedDocumentHistoryQuery = useQuery(
    ["documentHistory", queryData],
    () => viewedDocumentHistoryGenerate(queryData),
    {
      onSuccess: (data) => {
        window.open(data.url, "_blank");
      },
      onError: (err) => notify(err.message, "error"),
      onSettled: () => {
        setQueryData(null);
      },
      enabled: !!queryData,
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 5,
        mb: 5,
      }}
    >
      {viewedDocumentHistoryQuery.isFetching ? (
        <Loading
          backgroundColor={"rgba(255,255,255,0.8)"}
          maxWidth={"100%"}
          message={"Solicitando dados ..."}
        />
      ) : null}

      <DialogMedium
        title={`Histórico de visualização - Exames Laboratoriais - ${patient.name}`}
        open={openHistory}
        handleClose={() => setOpenHistory(false)}
        maxWidth={"lg"}
      >
        <ViewedDocumentHistory document={patient} documentType="MV_LAB_DOC" />
      </DialogMedium>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            setQueryData({
              patient_id: patient.id,
              type: "MV_LAB_DOC",
            });
          }}
        >
          Abrir Exames Laboratoriais
          {" - "}
          <LaunchOutlinedIcon />
        </Button>

        <Button variant="outlined" onClick={() => setOpenHistory(true)}>
          Histórico de Visualizações
        </Button>
      </Box>
    </Box>
  );
}
