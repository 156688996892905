import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Table } from "../../../components";
import { addDays, eachMinuteOfInterval } from "date-fns";
import { hourtype, days } from "../../../config/standardSelects";
import {
  useProvider,
  useScales,
  useSector,
  useServices,
  useTreatmentTypes,
} from "../../../service";
import usePermissions from "../../../config/usePermissions";
import useNotifier from "../../../hooks/useNotifier";
import format from "date-fns/format";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import SwitchField from "../../../components/FormFields/SwitchField";
import DateField from "../../../components/FormFields/DateField";
import SelectField from "../../../components/FormFields/SelectField";
import Yup from "../../../config/yup";
import TextField from "../../../components/FormFields/TextField";
import {
  AutocompleteField,
  PaginatedAutocompleteField,
} from "../../../components/FormFields";

export default function ScalesForm() {
  const { getProviders } = useProvider();
  const { getServices } = useServices();
  const { getSectors } = useSector();
  const { getTreatmentTypes } = useTreatmentTypes();
  const { postScale } = useScales();
  const postScaleMutation = useMutation(postScale);
  const [services, setServices] = React.useState([]);
  const [sectors, setSectors] = React.useState([]);
  const [treatmentType, setTreatmentType] = React.useState([]);
  const [list, setList] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const notify = useNotifier();
  const permissions = usePermissions("/schedule/scales");
  const date = new Date();
  date.setHours(0, 0, 0, 0);

  const initialValues = {
    initial_date: null,
    final_date: null,
    initial_hour: null,
    final_hour: null,
    average_time: null,
    active: true,
    amount: null,
    appointment_type: "",
    service: null,
    dayWeek: null,
    employee: null,
    sector: null,
    treatment_type: null,
  };

  const validator = Yup.object().shape(
    {
      initial_date: Yup.date()
        .typeError("Data Inválida")
        .required("É Requerido")
        .min(date, "A data deve ser posterior ou igual a hoje!"),
      final_date: Yup.date().required("É Requerido").typeError("Data Inválida"),
      initial_hour: Yup.date()
        .typeError("hora invalida")
        .required("requerido!"),
      final_hour: Yup.date().typeError("hora invalida").required("requerido!"),
      amount: Yup.number()
        .min(1, "Deve Haver Um ou Mais Atendimentos")
        .required("É Requerido"),
      dayWeek: Yup.object().required("É Requerido").nullable(),
      service: Yup.object().required("É Requerido").nullable(),
      employee: Yup.object()
        .when("sector", function (v) {
          if (v == null) return this.required("requerido!");
        })
        .nullable(),
      sector: Yup.object()
        .when("employee", function (v) {
          if (v == null) return this.required("requerido!");
        })
        .nullable(),
      treatment_type: Yup.object().required("É Requerido").nullable(),
    },
    [["sector", "employee"]]
  );

  const { handleSubmit, control, watch, setValue, reset } = useForm({
    resolver: yupResolver(validator),
    defaultValues: initialValues,
  });

  const [
    employee,
    treatment_type,
    service,
    initial_date,
    final_date,
    initial_hour,
    final_hour,
    dayWeek,
    sector,
  ] = watch([
    "employee",
    "treatment_type",
    "service",
    "initial_date",
    "final_date",
    "initial_hour",
    "final_hour",
    "dayWeek",
    "sector",
  ]);

  useQuery(["sectors"], () => getSectors({ filter: "active" }), {
    refetchOnWindowFocus: false,
    retry: false,
    keepPreviousData: true,
    onSuccess: (response) => {
      setSectors(response);
    },
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  useQuery(["service"], () => getServices({ filter: "active" }), {
    refetchOnWindowFocus: false,
    retry: false,
    keepPreviousData: true,
    onSuccess: (response) => {
      setServices(response);
    },
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  useQuery(["treatmentTypes"], () => getTreatmentTypes({ filter: "active" }), {
    refetchOnWindowFocus: false,
    retry: false,
    keepPreviousData: true,
    onSuccess: (response) => {
      setTreatmentType(response);
    },
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  function handleDelete(index) {
    const filteredRows = rows.filter((_, i) => i !== index);
    const filteredList = list.filter((_, i) => i !== index);
    setRows(filteredRows);
    setList(filteredList);
  }

  function clearField() {
    reset();
    setRows([]);
    setList([]);
    setValue("amount", "");
    setValue("average_time", "");
    setValue("sector", null);
    setValue("employee", null);
  }

  async function onSubmit(values) {
    if (!permissions.create) return;
    const objectToSend = {
      initial_date: format(new Date(values.initial_date), "yyyy-MM-dd"),
      final_date: format(new Date(values.final_date), "yyyy-MM-dd"),
      initial_hour: format(values.initial_hour, "HH:mm:ss"),
      final_hour: format(values.final_hour, "HH:mm:ss"),
      id_employee: values.employee?.id,
      id_sector: values.sector?.id,
      day: parseInt(values.dayWeek.value),
      active: values.active,
      average_time: values.average_time,
      service_treatment: list,
    };
    postScaleMutation.mutate(objectToSend, {
      onSuccess() {
        notify("Escala cadastrada com sucesso", "success");
        clearField();
      },
      onError(error) {
        notify(error.message, "error");
      },
    });
  }

  function filtredServiceByProvider(employee) {
    if (!employee) return services;

    const filteredServices = services.filter(({ id }) =>
      employee?.employee_Type.services.some((service) => service.id === id)
    );

    return filteredServices;
  }

  function handleAdd(values) {
    setRows((rows) => [
      ...rows,
      {
        service: values.service.describe,
        treatment_type: values.treatment_type.describe,
      },
    ]);
    setList((list) => [
      ...list,
      {
        service_id: values.service.id,
        treatment_type_id: values.treatment_type.id,
      },
    ]);
  }

  function averageBlur(amount) {
    if (initial_hour && final_hour && amount) {
      if (initial_hour > final_hour) {
        return notify(
          "O horário inicial deve ser menor que o horário final",
          "error"
        );
      }

      const minutes = eachMinuteOfInterval({
        start: initial_hour,
        end: final_hour,
      });

      let average = minutes.length / amount;

      setValue("average_time", Math.trunc(average));
    }
  }

  function clearProfissionarOrSector() {
    if (!!employee) {
      setValue("sector", null);
    } else {
      setValue("employee", null);
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mb={1}>
          <Grid item xs={3}>
            <DateField
              fullWidth
              name="initial_date"
              label="Início"
              required
              minDate={new Date()}
              control={control}
            />
          </Grid>
          <Grid item xs={3}>
            <DateField
              fullWidth
              name="final_date"
              label="Fim"
              required
              minDate={addDays(new Date(), 1)}
              control={control}
            />
          </Grid>
          <Grid item xs={3}>
            <DateField
              fullWidth
              name="initial_hour"
              label="Horário Inicial"
              type="time"
              required
              maxDate={new Date()}
              control={control}
            />
          </Grid>
          <Grid item xs={3}>
            <DateField
              fullWidth
              name="final_hour"
              label="Horário Final"
              type="time"
              required
              maxDate={new Date()}
              control={control}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField
              name="dayWeek"
              label="Dia da Semana"
              required
              options={days}
              control={control}
              optionLabelKey="describe"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="amount"
              type="number"
              label="Quantidade de Atendimentos"
              onBlur={averageBlur}
              control={control}
              disabled={
                !initial_date ||
                !final_date ||
                !initial_hour ||
                !final_hour ||
                !dayWeek
              }
              required
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField
              name="appointment_type"
              label="Tipo de Horário"
              options={hourtype}
              required
              control={control}
              optionLabelKey="describe"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="number"
              name="average_time"
              label="Tempo Médio de Atendimento"
              control={control}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h7" color="secondary" fontWeight="500">
              Profissional ou Setor
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <PaginatedAutocompleteField
              control={control}
              name={"employee"}
              label={"Profissional"}
              queryKey={"providers"}
              disabled={!!sector}
              required={!sector}
              onBlur={clearProfissionarOrSector}
              service={async (params) =>
                getProviders({
                  ...params,
                  active: true,
                })
              }
              optionLabelKey={"name"}
            />
          </Grid>
          <Grid item xs={3}>
            <AutocompleteField
              name="sector"
              label="Setor"
              options={sectors}
              control={control}
              onBlur={clearProfissionarOrSector}
              disabled={!!employee}
              optionLabelKey="describe"
              required={!employee}
            />
          </Grid>
          <Grid item xs={3}>
            <SwitchField name="active" label="Agenda Ativa" control={control} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h7" color="secondary" fontWeight="500">
              Serviço e Tipo de Atendimento
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <AutocompleteField
              name="service"
              label="Serviço"
              control={control}
              required
              options={filtredServiceByProvider(employee)}
              optionCompareKey={"id"}
              optionLabelKey="describe"
              customHandleChange={(employee) => {
                if (!employee?.employee_Type?.services.length) {
                  notify(
                    "Não há serviços para o profissional selecionado",
                    "success"
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteField
              name="treatment_type"
              label="Tipo de Atendimento"
              required
              control={control}
              options={treatmentType}
              optionCompareKey={"id"}
              optionLabelKey="describe"
            />
          </Grid>
          <Grid item>
            <Button xs={2} onClick={() => clearField()}>
              Limpar Dados
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleAdd(watch())}
              disabled={!service || !treatment_type}
              xs={2}
            >
              Adicionar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Table
              columns={[{ name: "Serviço" }, { name: "Tipo de Atendimento" }]}
              rows={rows}
              actions
              actionHandleDelete={handleDelete}
              confirmMessage={"Deseja excluir essa escala adicionada ?"}
              actionsTypes={["delete"]}
            />
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sx={{ display: "flex", justifyContent: "right", marginRight: 2 }}
        >
          <Button
            type="submit"
            disabled={!permissions.create}
            variant="contained"
          >
            Cadastrar
          </Button>
        </Grid>
      </form>
    </>
  );
}
