import {
  ArticleRounded,
  FastForwardRounded,
  HealingRounded,
  MedicalServicesRounded,
  MedicationRounded,
  MonitorHeartRounded,
  ScienceRounded,
} from "@mui/icons-material";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { ReactComponent as XRay } from "../../assets/svg/xray.svg";
import { useState } from "react";
import { useNotifier } from "../../hooks";
import { useQuery } from "react-query";
import { usePatient } from "../../service";
import AllergyButton from "../Patient/AllergyHistory/AllergyButton";
import { Loading } from "../../helper";
export function PatientInfo({ attendance, viewAllergies }) {
  const theme = useTheme();
  const notify = useNotifier();
  const { getPatientAllergies } = usePatient();
  const [openAllergyAlert, setOpenAllergyAlert] = useState(false);
  const StatusToolTip = ({ status, title, children }) => {
    const color =
      status === "unfinished"
        ? theme.palette.action.disabled
        : theme.palette.secondary.main;
    return (
      <Tooltip key={title} sx={{ color: color }} title={title}>
        {children}
      </Tooltip>
    );
  };

  const allergiesQuery = useQuery(
    ["get-patient-allergies", attendance?.id],
    () => getPatientAllergies(attendance.patient?.id, { limit: 1 }),
    {
      enabled: !!attendance?.id,
      onSuccess(data) {
        const hasAllergy = Object.entries(
          data.items[0]?.allergy_substances
        ).some(([_, value]) => value);
        if (hasAllergy) {
          setOpenAllergyAlert(true);
          notify("Paciente contém alergia.", "warning");
        }
      },
    }
  );

  return (
    <>
      {allergiesQuery.isFetching ? (
        <Loading
          backgroundColor={"rgba(255,255,255,1)"}
          maxWidth={"100%"}
          message={"Verificando alergias..."}
        />
      ) : null}
      <Box sx={{ pb: 2, width: "100%", display: "flex", columnGap: 4 }}>
        <Box sx={{ display: "flex", columnGap: 4, flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              color: theme.palette.primary.light,
            }}
          >
            <Typography
              sx={{ color: theme.palette.primary.light, fontWeight: "bolder" }}
            >
              Paciente:
            </Typography>
            <Typography>
              {attendance?.patient?.social_prioritize
                ? attendance?.patient?.social_name
                : attendance?.patient?.name}
            </Typography>
          </Box>
          {attendance?.patient?.mother_name ? (
            <Box
              sx={{
                display: "flex",
                columnGap: 1,
                color: theme.palette.primary.light,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.primary.light,
                  fontWeight: "bolder",
                }}
              >
                Mãe:
              </Typography>
              <Typography>{attendance?.patient?.mother_name}</Typography>
            </Box>
          ) : null}
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              color: theme.palette.primary.light,
            }}
          >
            <Typography
              sx={{ color: theme.palette.primary.light, fontWeight: "bolder" }}
            >
              Data de nascimento:
            </Typography>
            <Typography>
              {attendance?.patient?.birth_date
                ?.split("-")
                .reverse()
                .join("/") || "Não Informado"}
            </Typography>
          </Box>

          {attendance?.patient?.sus_card ? (
            <Box
              sx={{
                display: "flex",
                columnGap: 1,
                color: theme.palette.primary.light,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.primary.light,
                  fontWeight: "bolder",
                }}
              >
                CNS:
              </Typography>
              <Typography>{attendance?.patient?.sus_card}</Typography>
            </Box>
          ) : null}
        </Box>

        {attendance?.monitorings?.length ? (
          <Box
            justifySelf={"end"}
            color={theme.palette.secondary.main}
            paddingRight={2}
            display={"flex"}
            columnGap={2}
          >
            {attendance?.monitorings?.map((monitoring) => {
              if (monitoring.status !== "deleted") {
                switch (monitoring.type) {
                  case "medications":
                    return (
                      <StatusToolTip
                        status={monitoring.status}
                        title={"Fármacia"}
                      >
                        <MedicalServicesRounded />
                      </StatusToolTip>
                    );
                  case "medications_on_site":
                    return (
                      <StatusToolTip
                        status={monitoring.status}
                        title={"Medicação"}
                      >
                        <MedicationRounded />
                      </StatusToolTip>
                    );
                  case "documentation":
                    return (
                      <StatusToolTip
                        status={monitoring.status}
                        title={"Declarações / Atestados"}
                      >
                        <ArticleRounded />
                      </StatusToolTip>
                    );
                  case "lab":
                    return (
                      <StatusToolTip
                        status={monitoring.status}
                        title={"Exames laboratoriais"}
                      >
                        <ScienceRounded />
                      </StatusToolTip>
                    );
                  case "xray":
                    return (
                      <StatusToolTip
                        status={monitoring.status}
                        title={"Raio-x"}
                      >
                        <XRay
                          width={16}
                          height={20}
                          fill={
                            monitoring.status === "unfinished"
                              ? theme.palette.action.disabled
                              : theme.palette.secondary.main
                          }
                        />
                      </StatusToolTip>
                    );
                  case "ecg":
                    return (
                      <StatusToolTip status={monitoring.status} title={"ECG"}>
                        <MonitorHeartRounded />
                      </StatusToolTip>
                    );
                  case "against_reference":
                    return (
                      <StatusToolTip
                        status={monitoring.status}
                        title={"Regulação"}
                      >
                        <FastForwardRounded />
                      </StatusToolTip>
                    );
                  case "procedure":
                    return (
                      <StatusToolTip
                        status={monitoring.status}
                        title={"Procedimentos"}
                      >
                        <HealingRounded />
                      </StatusToolTip>
                    );
                  default:
                    break;
                }
              }
            })}
          </Box>
        ) : null}
        {viewAllergies ? (
          <AllergyButton
            openAllergyAlert={openAllergyAlert}
            setOpenAllergyAlert={setOpenAllergyAlert}
            attendance={attendance}
            allergies={allergiesQuery.data?.items[0]}
          />
        ) : null}
      </Box>
    </>
  );
}
