function removeEmpty(obj) {
  const newObj = {};
  Object.keys(obj).forEach(function (k) {
    if (obj[k] && typeof obj[k] === "object" && obj[k].length > 0) {
      newObj[k] = obj[k];
    } else if (obj[k] !== undefined && typeof obj[k] !== "object") {
      newObj[k] = obj[k];
    }
  });
  return newObj;
}

export default removeEmpty;
