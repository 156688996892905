import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import Helmet from "react-helmet";
import { FormTextField } from "../../../components";
import { SubmitButton } from "../../../components/Form";
import { TITLE_NAME } from "../../../config/config";
import Yup from "../../../config/yup";
import { useVaccine } from "../../../service";
import { DateTime } from "../../../components/Form/FormFieldTime";
import useNotifier from "../../../hooks/useNotifier";

function RegisterImmuForm({ handleCloseDialog }) {
  const { postVaccines } = useVaccine();
  const notify = useNotifier();
  const initialValues = {
    describe: "",
    minimumAge: "",
    maximumAge: "",
    minimumInterval: "",
    recommendedDose: "",
    incompatibleReagents: "",
    abbreviation: "",
  };

  const validations = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
    minimumAge: Yup.string()
      .required("É requerido")
      .matches(
        /^(?=\d+[ymwd])(( ?\d+y)?(?!\d))?(( ?\d+m)?(?!\d))?(( ?\d+w)?(?!\d))?(( ?\d+d)?(?!\d))?$/g,
        "Formato Incorreto, Esperado : 0y 0m 0w 0d"
      ),
    maximumAge: Yup.string()
      .required("É requerido")
      .matches(
        /^(?=\d+[ymwd])(( ?\d+y)?(?!\d))?(( ?\d+m)?(?!\d))?(( ?\d+w)?(?!\d))?(( ?\d+d)?(?!\d))?$/g,
        "Formato Incorreto, Esperado : 0y 0m 0w 0d"
      ),
    minimumInterval: Yup.string()
      .required("É requerido")
      .matches(
        /^(?=\d+[ymwd])(( ?\d+y)?(?!\d))?(( ?\d+m)?(?!\d))?(( ?\d+w)?(?!\d))?(( ?\d+d)?(?!\d))?$/g,
        "Formato Incorreto, Esperado : 0y 0m 0w 0d"
      ),
    recommendedDose: Yup.string().required("É requerido"),
    incompatibleReagents: Yup.string().required("É requerido"),
    abbreviation: Yup.string().required("É requerido"),
  });

  async function handleSubmit(values) {
    const vaccineObject = {
      describe: values.describe,
      minimumAge: values.minimumAge,
      maximumAge: values.maximumAge,
      minimumInterval: values.minimumInterval,
      recommendedDose: values.recommendedDose,
      incompatibleReagents: values.incompatibleReagents,
      abbreviation: values.abbreviation,
    };
    try {
      const response = await postVaccines(vaccineObject);
      if (response) {
        notify("Vacina criada com Sucesso!", "success");
      }
    } catch (err) {
      notify(err.message, "error");
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Vacina</title>
      </Helmet>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
      >
        {({ values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <FormTextField
                  name="describe"
                  label="Imunobiológicos"
                  required
                />
              </Grid>
              <Grid xs={7} item>
                <FormTextField name="abbreviation" label="Sigla" required />
              </Grid>
              <Grid xs={5} item>
                <FormTextField
                  name="recommendedDose"
                  label="Dose Recomendada"
                  required
                />
              </Grid>
              <Grid xs={4} item>
                <DateTime title="Idade Minima" name="minimumAge" />
              </Grid>
              <Grid xs={5} item>
                <DateTime title="Idade Máxima" name="maximumAge" />
              </Grid>
              <Grid xs={4} item>
                <DateTime title="Intervalo Minimo" name="minimumInterval" />
              </Grid>
              <Grid xs={12} item>
                <FormTextField
                  name="incompatibleReagents"
                  required
                  multiline
                  minRows={4}
                  maxRows={4}
                  label="Obs: Reag. Imcompativeis : "
                  sx={{ mb: 4 }}
                />
              </Grid>
            </Grid>
            <Grid
              sx={{ marginTop: 2, display: "flex", justifyContent: "right" }}
            >
              <SubmitButton>Salvar Cadastro</SubmitButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default RegisterImmuForm;
