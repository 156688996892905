import React from "react";
import {
  FormSearchSelectField,
  FormSelectField,
  SubmitButton,
} from "../../../../components/Form";
import { Formik, Form } from "formik";
import Yup from "../../../../config/yup";
import PaginatedTable from "../../../../components/Table/PaginatedTable";
import { Grid, Box } from "@mui/material";

const ProgramEntryCriteriaForm = ({ rows = [], setRows }) => {
  // Handle Functions
  function handleDelete(index) {
    const filteredrows = rows.filter((_, key) => key !== index);
    setRows(filteredrows);
  }

  function addRows({ criterion, condition, value, items }, { resetForm }) {
    setRows((previousrows) => [
      ...previousrows,
      {
        items: items.name,
        condition,
        value: value.name,
        criterion_name: criterion.name,
      },
    ]);
    resetForm();
  }

  const initialValues = {
    criterion: null,
    condition: "",
    value: null,
    items: null,
  };

  const validations = Yup.object().shape({
    criterion: Yup.object().required("É requerido").nullable(),
    condition: Yup.string().required("É requerido"),
    value: Yup.object().required("É requerido").nullable(),
    items: Yup.object().required("É requerido").nullable(),
  });

  const collumns = [
    { name: "Items" },
    { name: "Condição" },
    { name: "Valor" },
    { name: "Critérios" },
  ];

  return (
    <Formik
      onSubmit={addRows}
      initialValues={initialValues}
      validationSchema={validations}
    >
      {() => (
        <>
          <Form>
            <Grid xs={12} spacing={3} container item marginTop={-2}>
              <Grid xs={4} item>
                <FormSearchSelectField
                  label="Items"
                  name="items"
                  options={[
                    { id: 1, number: 1, name: "Hemograma" },
                    { id: 2, number: 2, name: "Colesterol e Triglicerídeos" },
                    {
                      id: 3,
                      number: 3,
                      name: "Avaliação clínica diária parental",
                    },
                    { id: 4, number: 4, name: "Ecocardiograma de stress" },
                    { id: 5, number: 5, name: "Ecocardiograma5" },
                  ]}
                  getOptionLabel={(healthTips) => healthTips.name}
                  isOptionEqualToValue={(healthTips, value) => {
                    return healthTips.id === value.id;
                  }}
                  required
                />
              </Grid>
              <Grid xs={2} item>
                <FormSelectField
                  label="Condição"
                  name="condition"
                  options={{
                    Igual: "Igual",
                    "Maior que": "Maior que",
                    "Maior ou igual": "Maior ou igual",
                    "Menor que": "Menor que",
                    "Menor ou igual": "Menor ou igual",
                  }}
                  required
                />
              </Grid>
              <Grid xs={2} item>
                <FormSearchSelectField
                  label="Valor"
                  name="value"
                  options={[
                    { id: 1, name: "Valor 1" },
                    { id: 2, name: "valor 2" },
                    { id: 3, name: "valor 3" },
                    { id: 4, name: "valor 4" },
                    { id: 5, name: "valor 5" },
                  ]}
                  getOptionLabel={(healthTips) => healthTips.name}
                  isOptionEqualToValue={(healthTips, value) => {
                    return healthTips.id === value.id;
                  }}
                  required
                />
              </Grid>
              <Grid xs={2} item>
                <FormSearchSelectField
                  label="Critérios"
                  name="criterion"
                  options={[
                    { id: 1, name: "Entrada" },
                    { id: 2, name: "Saída" },
                  ]}
                  getOptionLabel={(healthTips) => healthTips.name}
                  isOptionEqualToValue={(healthTips, value) => {
                    return healthTips.id === value.id;
                  }}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <SubmitButton>Adicionar</SubmitButton>
              </Grid>
            </Grid>
          </Form>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <PaginatedTable
              sx={{ marginTop: 3 }}
              columns={collumns}
              data={rows}
              actions
              actionsTypes={["delete"]}
              shouldDisableFields={["id"]}
              actionHandleDelete={handleDelete}
            />
          </Box>
        </>
      )}
    </Formik>
  );
};

export default ProgramEntryCriteriaForm;
