import { Box, Divider, IconButton, Typography } from "@mui/material";
import { ReactComponent as Dental } from "../../../../../assets/svg/dental.svg";
import { HomeWork, ListAlt, Upload, Visibility } from "@mui/icons-material";
import { FaUserAlt } from "react-icons/fa";
import { format, parseISO } from "date-fns";
import formatDateString from "../../../../../utils/formatDateString";
import { isNull } from "lodash";
export default function ThriftHistoryCard({
  qty_inconsistencies,
  qty_consistencies,
  duplicates,
  qty_overall,
  createdAt,
  company,
  esus_record,
  handleView,
  start_date,
  end_date,
  analyzed,
  id,
}) {
  const types = {
    "65e06e41d56969dd8fc49487": <Dental width={"18px"} height={"19px"} />,
    "65df7122efb3171dc91d0fef": <HomeWork width={"18px"} height={"19px"} />,
    "65df8b32c00e958f6955acba": <HomeWork width={"18px"} height={"19px"} />,
    "65e227aaaafaf2a78442f9a9": <FaUserAlt width={"18px"} height={"19px"} />,
    "65df62323c800da55f6006ed": <FaUserAlt width={"18px"} height={"19px"} />,
    "65e070c8d56969dd8fc49498": <ListAlt width={"18px"} height={"19px"} />,
    "65e227aaaafaf2a78442f9a9": <Upload width={"18px"} height={"19px"} />,
  };
  const valid = qty_consistencies + duplicates;
  return (
    <Box paddingLeft={"5px"} paddingRight={"5px"}>
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        bgcolor={"#f7f7f7"}
        borderRadius={"6px"}
        padding={"15px"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          rowGap={"8px"}
          justifyContent={"start"}
          width={"100%"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                color: (t) => t.palette.primary.light,
                display: "flex",
                columnGap: "7px",
                alignItems: "center",
              }}
            >
              {types[esus_record?.external_document_id] ?? (
                <FaUserAlt width={"18px"} height={"19px"} />
              )}{" "}
              {esus_record?.name}
            </Typography>
            <Typography
              sx={{
                color: (t) => t.palette.primary.light,
                alignItems: "center",
                fontSize: "10px",
                whiteSpace: "nowrap",
                paddingLeft: "3px",
              }}
            >
              {createdAt
                ? format(parseISO(createdAt), "dd/MM/yyyy - HH:mm")
                : ""}
            </Typography>
          </Box>
          <Divider />
          <Box
            display={"flex"}
            columnGap={1}
            alignItems={"center"}
            justifyContent={"space-between"}
            textAlign={"center"}
            width={"100%"}
          >
            <Typography
              color={"primary.light"}
              component={"span"}
              flex={2}
              lineHeight={0.5}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              flexWrap={"wrap"}
              alignItems={"center"}
            >
              <Typography>
                {start_date ? formatDateString(start_date) : ""}
              </Typography>
              {" - "}
              <Typography>
                {end_date ? formatDateString(end_date) : ""}
              </Typography>
            </Typography>
            <Divider orientation="vertical" flexItem />
            {analyzed ? (
              <>
                <Typography color={"#2EDE34"} flex={1}>{`${
                  valid ?? 0
                } validas`}</Typography>
                <Divider orientation="vertical" flexItem />
                <Typography color={"#E85252"} flex={1}>{`${
                  qty_inconsistencies ?? 0
                } invalidas`}</Typography>
              </>
            ) : (
              <Typography
                color={"primary.light"}
                flex={2}
              >{isNull(qty_overall) ? "Envio de fichas"  :`${qty_overall} Fichas Geradas` }</Typography>
            )}
            <Divider orientation="vertical" flexItem />
            <Typography color={"primary.light"} flex={2}>
              {company?.name}
            </Typography>
            <Box display={"flex"} alignItems={"center"} flex={1}>
              <IconButton
                disabled={!qty_inconsistencies}
                onClick={() => handleView(id)}
              >
                <Visibility
                  color={!qty_inconsistencies ? "disabled" : "primary"}
                  sx={{ fontSize: "30px" }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
