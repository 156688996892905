import React from "react";
import PaginatedTable from "../Table/PaginatedTable";
import useNotifier from "../../hooks/useNotifier";
import { TablePagination, Box } from "@mui/material";
import { useQuery } from "react-query";
import { useProgram } from "../../service";

function PatientProgramList({ patientId }) {
  const notify = useNotifier();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const { getProgramsOfPatient } = React.useMemo(useProgram, []);

  // Reequets
  const queryPrograms = useQuery(
    ["programs-of-patient"],
    () => getProgramsOfPatient(patientId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      initialData: [],
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const rows = queryPrograms.data.map((index) => {
    return {
      number: index.number,
      name: index.name,
      describe: index.describe,
      gender: index.gender.join("/ "),
      age_group_initial: index.age_group_initial,
      age_group_final: index.age_group_final,
      active: index.active ? "Sim" : "Não",
    };
  });

  //config table
  const tableColumns = [
    { name: "Código" },
    { name: "Nome" },
    { name: "Descrição" },
    { name: "Gênero" },
    { name: "Idade Inicial" },
    { name: "Idade Final" },
    { name: "Ativo" },
  ];

  return (
    <>
      <PaginatedTable
        data={rows}
        columns={tableColumns}
        loading={queryPrograms.isRefetching}
        actions
        actionsTypes={[]}
        confirmMessage={`Você realmente deseja *action* este programa?`}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={count}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}

export default PatientProgramList;
