import React, { useState } from "react";
import { useFamily } from "../../../service";
import { useQuery } from "react-query";
import { Box } from "@mui/system";
import { TablePagination } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter/Table";
import { format, parseISO } from "date-fns";
import limitName from "../../../utils/limitName";

function FamilyRemovalHistory({ familyId }) {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { getFamilyExclusions } = useFamily();

  const familyExclusionsQuery = useQuery(
    ["family-exclusions", familyId, page, limit],
    () => getFamilyExclusions(familyId, { page, limit }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!familyId,
    }
  );

  const tableColumns = [
    {
      name: "Paciente",
      field: "patient.name",
      use: (value, row) =>
        limitName(
          row.patient?.social_prioritize ? row.patient.social_name : value,
          3,
          "Não informado"
        ),
      type: "text",
    },
    {
      name: "Removido por",
      field: "employee.name",
    },
    {
      name: "Data de óbito",
      field: "death_date",
      use: (date) => (date ? format(parseISO(date), "dd/MM/yyyy") : null),
    },
    {
      name: "Número do D.O.",
      field: "death_cerficate_number",
      use: (value) => value || "Não informado",
    },
    {
      name: "Removido em",
      field: "createdAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
    {
      name: "Motivo de remoção",
      field: "reason",
    },
  ];

  return (
    <>
      <TableFilter
        data={familyExclusionsQuery.data?.items}
        columns={tableColumns}
        loading={familyExclusionsQuery.isFetching}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={familyExclusionsQuery.data?.totalItems || 0}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}

export default FamilyRemovalHistory;
