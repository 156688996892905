export default function formatOdontoRps(data) {
  const mappedRps = [];
  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      if (key === "notes") return;
      
      if (value.rps) {
        mappedRps.push(`${key} - ${value.rps}`);
      }
    });
  }

  return mappedRps;
}
