import React from "react";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import { DialogMedium } from "../../../helper";
import AgainstReferenceView from "../../Attendance/AgainstReferences/AgainstReferenceView";

export default function AgainstReferencesTable({
  againstReferences,
  check,
  handleView,
}) {
  const columns = [
    { name: "Nº Atendimento" },
    { name: "Data Solicitada" },
    { name: "Profissional Solicitante" },
    { name: "Especialidade Requisitada" },
    { name: "Prioridade" },
  ];

  const [unicReference, setUnicReference] = React.useState();
  const [openView, serOpenView] = React.useState(false);

  function handleDialogView(index) {
    setUnicReference(handleView(index));
    serOpenView(true);
  }

  function handleClose() {
    serOpenView(false);
  }

  return (
    <>
      <DialogMedium
        title="Visualizar Contrarreferência"
        open={openView}
        type={"view"}
        handleClose={handleClose}
      >
        <AgainstReferenceView reference={unicReference} />
      </DialogMedium>
      <PaginatedTable
        data={againstReferences || []}
        columns={columns}
        actions
        actionsTypes={["finish", "view"]}
        disableRows={(item) => item.referred}
        disableActions={(item, action) =>
          action === "finish" ? item.referred : false
        }
        shouldDisableFields={["id", "referred", "signature"]}
        actionHandleFinish={check}
        actionHandleView={handleDialogView}
      />
    </>
  );
}
