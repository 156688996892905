import React from "react";
import { BoxContainer, Tabs } from "../../../components";
import AgainstReferenceTable from "./AgainstReferenceTable";
import MyAgainstReferences from "./MyAgainstReferences";

export default function AgainstReferences() {
  const tabs = [
    {
      label: "Listagem Externa",
      content: <AgainstReferenceTable />,
    },
    {
      label: "Listagem de Emissões",
      content: <MyAgainstReferences />,
    },
  ];
  return (
    <BoxContainer title="Referência / Contrarreferências">
      <Tabs tabs={tabs} />
    </BoxContainer>
  );
}
