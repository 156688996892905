import instance from "../config/api";
import useRPA from "./useRPA";

export default function useBilling() {
  const DPath = "/rpa";
  const { getRPA } = useRPA();

  async function postBilling(data) {
    try {
      const result = await instance.post(`${DPath}`, data);
      return result.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function updateBilling({ id, data }) {
    try {
      const result = await instance.put(`${DPath}/${id}`, data);
      return result.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function deleteBilling(id) {
    try {
      const result = await instance.delete(`${DPath}/${id}`);
      return result.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getBillingByRPAId(id, params) {
    try {
      const result = await instance.get(`${DPath}/${id}`, { params });
      return result.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  return {
    postBilling,
    getRPA,
    updateBilling,
    getBillingByRPAId,
    deleteBilling,
  };
}
