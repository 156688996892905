import { Box, Grid, TablePagination } from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import TableFilter from "../../../../components/Table/TableFilter";
import { useProvider } from "../../../../service";
import { useCompany } from "../../../../service/useCompany";
import { DialogMedium } from "../../../../helper";
import Yup from "../../../../config/yup";
import useNotifier from "../../../../hooks/useNotifier";
import {
  Button,
  PaginatedAutocompleteField,
  RadioField,
} from "../../../../components/FormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const UserCompanySettings = ({ employee }) => {
  const {
    deleteEmployeeCompany,
    getEmployeeCompany,
    postEmployeeCompany,
    updateProviderCompany,
    getSpecialties,
  } = useProvider();
  const { getCompanies } = useCompany();
  const notify = useNotifier();

  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [companyToEdit, setCompanyToEdit] = useState(null);

  const insertMutation = useMutation(postEmployeeCompany);
  const deleteMutation = useMutation(deleteEmployeeCompany);
  const updateMutation = useMutation(updateProviderCompany);

  const validations = Yup.object().shape({
    company: Yup.object().required("Escolha uma empresa").nullable(),
    can_import_schedule: Yup.boolean().required("É requerido."),
    specialties: Yup.array()
      .min(1)
      .required("Ao menos uma especialidade deve ser informada."),
  });

  const editValidations = Yup.object().shape({
    can_import_schedule: Yup.boolean().required("É requerido."),
    chief_radiologist: Yup.boolean().nullable(),
    active_chief_radiologist: Yup.boolean().nullable(),
    active: Yup.boolean().nullable(),
  });

  const registerForm = useForm({
    defaultValues: {
      company: null,
      can_import_schedule: true,
      specialties: [],
    },
    resolver: yupResolver(validations),
  });

  const editForm = useForm({
    resolver: yupResolver(editValidations),
  });

  const getEmployeeCompanyQuery = useQuery(
    ["get-employee-company", employee, [limit, page]],
    () => getEmployeeCompany(employee.id, { limit, page }),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: [],
    }
  );

  const tableColumns = [
    { name: "Empresa", field: "company_name" },
    {
      name: `Importa Escala`,
      field: "can_import_schedule",
      use: (can_import_schedule) => (can_import_schedule ? "Sim" : "Não"),
    },
    {
      name: "Radiologista Chefe",
      field: "chief_radiologist",
      use: (chief_radiologist) => (chief_radiologist ? "Sim" : "Não"),
    },
    {
      name: `Radiologista Ativo`,
      field: "active_chief_radiologist",
      use: (active_chief_radiologist) =>
        active_chief_radiologist ? "Sim" : "Não",
    },
    {
      name: `Ativo`,
      field: "active",
      use: (active) => (active ? "Sim" : "Não"),
    },
  ];

  const radios = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  function onSubmit(values) {
    if (companyToEdit) {
      updateMutation.mutate(
        {
          employeeId: employee.id,
          companyId: companyToEdit.company_id,
          data: {
            can_import_schedule: values.can_import_schedule,
            chief_radiologist: values.chief_radiologist,
            active_chief_radiologist: values.active_chief_radiologist,
            active: values.active,
          },
        },
        {
          onSuccess: () => {
            notify(`Atualização realizada com sucesso`, "success");
            getEmployeeCompanyQuery.refetch();
            setCompanyToEdit(false);
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    } else {
      const specialtiesIds = values.specialties.map(({ id }) => id);

      insertMutation.mutate(
        {
          company_id: values.company.id,
          employee_id: employee.id,
          can_import_schedule: values.can_import_schedule,
          specialties: specialtiesIds,
        },
        {
          onSuccess: () => {
            notify("Empresa adicionada com sucesso", "success");
            registerForm.reset();
            getEmployeeCompanyQuery.refetch();
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    }
  }

  function handleDelete(_, company) {
    deleteMutation.mutate(
      {
        employeeId: employee.id,
        companyId: company.company_id,
      },
      {
        onSuccess: () => {
          getEmployeeCompanyQuery.refetch();
          notify(`Empresa excluida com sucesso`, "success");
        },
        onError: (err) => {
          notify(err.message, "error");
        },
      }
    );
  }

  function handleUpdate(_, company) {
    setCompanyToEdit(company);
    editForm.reset({
      can_import_schedule: company.can_import_schedule,
      active_chief_radiologist: company.active_chief_radiologist,
      chief_radiologist: company.chief_radiologist,
      active: company.active,
    });
  }

  function handleClose() {
    setCompanyToEdit(null);
    getEmployeeCompanyQuery.refetch();
  }

  return (
    <Box>
      <DialogMedium
        open={!!companyToEdit}
        title={"Editar Empresa"}
        handleClose={handleClose}
        maxWidth={"100vw"}
      >
        <Grid
          container
          xs={12}
          component="form"
          onSubmit={editForm.handleSubmit(onSubmit)}
        >
          <Grid item xs={3}>
            <RadioField
              name="can_import_schedule"
              label="Importa Agenda ?"
              control={editForm.control}
              options={radios}
              optionValueKey="value"
            />
          </Grid>
          <Grid item xs={3}>
            <RadioField
              name="chief_radiologist"
              label="Radiologista Chefe ?"
              control={editForm.control}
              options={radios}
              optionValueKey="value"
            />
          </Grid>
          <Grid item xs={3}>
            <RadioField
              name="active_chief_radiologist"
              label="Radiologista Chefe Ativo ?"
              control={editForm.control}
              options={radios}
              optionValueKey="value"
            />
          </Grid>
          <Grid item xs={3}>
            <RadioField
              name="active"
              label="Ativo ?"
              control={editForm.control}
              options={radios}
              optionValueKey="value"
            />
          </Grid>
          <Grid xs={2} item>
            <Button type="submit">Atualizar</Button>
          </Grid>
        </Grid>
      </DialogMedium>
      <Grid xs={12} container spacing={2}>
        <Grid
          xs={12}
          item
          container
          spacing={2}
          component="form"
          onSubmit={registerForm.handleSubmit(onSubmit)}
        >
          <Grid xs={4} item>
            <PaginatedAutocompleteField
              name="company"
              label="Empresa"
              required
              control={registerForm.control}
              filterKey="name"
              optionLabelKey="name"
              optionCompareKey="id"
              queryKey="companies"
              service={getCompanies}
            />
          </Grid>
          <Grid xs={4} item>
            <PaginatedAutocompleteField
              name="specialties"
              label="Especialidades"
              multiple
              required
              control={registerForm.control}
              filterKey="describe"
              optionLabelKey="describe"
              optionCompareKey="id"
              queryKey="specialties"
              service={getSpecialties}
            />
          </Grid>
          <Grid item xs={2}>
            <RadioField
              name="can_import_schedule"
              label="Pode importar escala?"
              control={registerForm.control}
              options={radios}
              optionValueKey="value"
              required
            />
          </Grid>
          <Grid xs={2} item>
            <Button type="submit">Adicionar</Button>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Box>
            <TableFilter
              data={getEmployeeCompanyQuery?.data?.items}
              columns={tableColumns}
              actions
              noHeaderOptions
              actionsTypes={["delete", "edit"]}
              actionHandleDelete={handleDelete}
              actionHandleEdit={handleUpdate}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <TablePagination
                count={getEmployeeCompanyQuery?.data?.totalItems ?? 0}
                component="div"
                page={page}
                onPageChange={(_, newPage) => {
                  setPage(newPage);
                }}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 20, 75]}
                onRowsPerPageChange={({ target }) => {
                  setLimit(parseInt(target.value, 10));
                  setPage(0);
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserCompanySettings;
