import { Grid, IconButton, Stack, Box } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import Yup from "../../../config/yup";
import { Helmet } from "react-helmet";
import { FormDate, SubmitButton } from "../../../components";
import HistoryIcon from "@mui/icons-material/History";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import FormMultipleSelectField from "../../../components/Form/FormMultipleSelectField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/styles";
import useNotifier from "../../../hooks/useNotifier";
import { pt } from "yup-locale-pt";
import { Loading } from "../../../helper";
import { useProvider } from "../../../service";
import _ from "lodash";
import instance from "../../../config/api";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { format } from "date-fns";
import { header, title, footer } from "../../../pdfModels/pdfComponents";

Yup.setLocale(pt);

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#9AC0D8",
    boxShadow: theme.shadows[1],
    fontSize: 8,
  },
}));

export default function EmployeeFormPeriod() {
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { getProviders } = useProvider();
  const [employeeArray, setEmployeeArray] = React.useState([]);
  const [attendanceArray, setAttendanceArray] = React.useState([]);
  const [attendanceArray2, setAttendanceArray2] = React.useState([]);
  const notify = useNotifier();
  React.useEffect(() => {
    async function fetchEmployee() {
      try {
        setLoading(true);
        const response = await getProviders();
        if (response) {
          setEmployeeArray(response);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        notify(err.message, "error");
      }
    }

    async function fetchAttendance() {
      try {
        const response = await instance.get("/attendances?status=finished");
        if (response.data) {
          setAttendanceArray(response.data);
        }
      } catch (err) {
        notify(err.message, "error");
      }
    }

    fetchEmployee();
    fetchAttendance();
  }, []);

  const initialValues = {
    initialDate: null,
    finalDate: null,
    teste: [],
    employee: [],
  };
  const validations = Yup.object({
    initialDate: Yup.date()
      .required("É requerido")
      .nullable()
      .default(undefined),
    finalDate: Yup.date().required("É requerido").nullable().default(undefined),
  });

  function toDate(texto) {
    let partes = texto.split("/");
    return new Date(partes[2], partes[1] - 1, partes[0]);
  }

  function toString(date) {
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  }

  async function handleSubmit(values, { resetForm, setFieldValue }) {
    setLoadingButton(true);
    setTimeout(() => {
      setLoadingButton(false);
    }, 500);

    const newValues = _.cloneDeep(values);

    if (!!newValues.teste && newValues.teste.length) {
      newValues.teste.map((employee) => {
        newValues.employee.push(employee);
      });
    } else {
      newValues.employee = [];
    }

    let employee2 = newValues.employee;
    employee2 = employee2.map((row) => row.id);

    let query = "";

    employee2.map((row) => {
      query += "&" + "id_employee=" + row;
    });

    let initialdate = format(newValues.initialDate, "yyyy-MM-dd");
    let finaldate = format(newValues.finalDate, "yyyy-MM-dd");

    try {
      setLoading(true);
      const response = await instance.get(
        `/attendances?final_date=${finaldate}&initial_date=${initialdate}${query}`
      );
      if (response.data) {
        setAttendanceArray2(response.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      notify(err.message, "error");
    }

    newValues.initialDate = newValues.initialDate
      .split("/")
      .reverse()
      .join("/");
    newValues.finalDate = newValues.finalDate.split("/").reverse().join("/");

    let d1 = toDate(newValues.initialDate),
      d2 = toDate(newValues.finalDate),
      intervalos = [];

    intervalos.push(toString(d1).split("/").reverse().join("-"));

    while (d1 < d2) {
      d1.setDate(d1.getDate() + 1);
      intervalos.push(toString(d1).split("/").reverse().join("-"));
    }

    let array2 = [];

    intervalos.map((row) => {
      array2.push(
        _.filter(attendanceArray, function (o) {
          return o.scheduling?.date === row;
        })
      );
    });

    const mediaTypes =
      !!array2 &&
      array2
        .map((dataItem) => dataItem) // get all media types
        .filter(
          (mediaType, index, array) => array.indexOf(mediaType) === index
        ); // filter out duplicates

    array2 = mediaTypes.reduce((acc, val) => acc.concat(val), []);

    let array3 = [];

    if (newValues.employee.length > 0) {
      newValues.employee.map((row) => {
        array3.push(
          _.filter(array2, function (o) {
            return o.employee?.id === row.id;
          })
        );
      });
      array3 = array3.reduce((acc, val) => acc.concat(val), []);
    }

    newValues.employee = newValues.employee.map((row) => row.name);

    if (array3.length === 0) {
      if (newValues.employee.length === 0) {
        pdfAttendances(array2, newValues);
      } else {
        pdfAttendances([], newValues);
      }
    }

    if (array3.length > 0) {
      pdfAttendances(array3, newValues);
    }
  }

  function pdfAttendances(arrayObject, values) {
    let array = [];
    if (!!arrayObject) {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      const filtro = [
        {
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text:
                    " Período de  " +
                    values.initialDate +
                    " até " +
                    values.finalDate +
                    " | Profissional : " +
                    (values.employee.length === 0 ? "Todos" : values.employee),
                  fontSize: 8,
                  alignment: "center",
                  margin: [0, 5, 0, 5],
                },
              ],
            ],
          },
          layout: "noBorders",
        },
      ];

      const dados = arrayObject.map((cliente) => {
        return [
          {
            text: cliente.number,
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
          },
          {
            text: format(new Date(cliente.createdAt), "dd/MM/yyyy "),
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
          },
          {
            text: cliente.scheduling?.patient?.name,
            fontSize: 8,
            margin: [0, 1, 0, 1],
          },
          { text: cliente.employee?.name, fontSize: 8, margin: [0, 1, 0, 1] },
          {
            text: cliente.plan_card
              ? cliente.plan_card.insurance_plan.name
              : "SUS",
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
          },
          {
            text: cliente.plan_card?.insurance_plan?.health_insurance?.name,
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
          },
          {
            text: cliente?.service?.describe,
            fontSize: 8,
            alignment: "center",
            margin: [0, 1, 0, 1],
          },
        ];
      });

      const details = [
        {
          table: {
            style: "tableExample",
            headerRows: 1,
            widths: [40, 70, 90, 120, 50, 50, 70],

            body: [
              [
                {
                  text: "Atend.",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Data",
                  style: "tableHeader",
                  alignment: "center",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Paciente ",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Profissional",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Convênio ",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  alignment: "center",
                  border: [false, false, false, true],
                },
                {
                  text: "Plano  ",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  alignment: "center",
                  border: [false, false, false, true],
                },
                {
                  text: "Serviço  ",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  alignment: "center",
                  border: [false, false, false, true],
                },
              ],

              ...dados,
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              //horizontal
              return i === 1 || i === node.table.body.length ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              //vertical
              return i === 0 || i === node.table.widths.length ? 2 : 2;
            },
            hLineColor: function (i, node) {
              return i === 1 || i === node.table.body.length ? "black" : "gray";
            },

            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? "white"
                : "white";
            },
          },
        },
      ];

      var total = [
        {
          text: "Total de Atendimentos:" + dados.length,
          fontSize: 8,
          bold: true,
          margin: [10, 10, 0, 15],
        },
      ];

      const docDefinitios = {
        pageSize: "A4",
        pageMargins: [15, 70, 15, 40],
        ...header(),
        footer: footer(),
        content: [
          title("Relatório Atendimento Por Período "),
          filtro,
          details,
          total,
        ],
      };

      pdfMake.createPdf(docDefinitios).print();
    } else {
      return array;
    }
  }

  const options =
    !!employeeArray?.items && employeeArray?.items.length
      ? employeeArray?.items.map((option) => {
          const firstLetter = option.name[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            ...option,
          };
        })
      : [];

  return (
    <>
      {!loading ? (
        <>
          <Helmet>
            <title>{"APS | "} Relatório</title>
          </Helmet>
          <Formik
            initialValues={{ ...initialValues }}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ resetForm, values, setFieldValue }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: 2.5, align: "center" }}
                >
                  <Grid item xs={4}>
                    <FormDate name="initialDate" label="Inicio" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormDate
                      name="finalDate"
                      label="Fim"
                      minDate={new Date(values.initialDate)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <LightTooltip title="Analítico">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <AnalyticsIcon />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Série Histórica">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <HistoryIcon />
                        </IconButton>
                      </LightTooltip>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: 2.5 }}>
                  <Grid item xs={8}>
                    <FormMultipleSelectField
                      name={"teste"}
                      label={"Programas: "}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      getOptionLabel={(employee) =>
                        employee.name ? employee.name : ""
                      }
                      isOptionEqualToValue={(myArrayEmployee, value) =>
                        value.id === myArrayEmployee.id ? true : false
                      }
                      groupBy={(option) => option.firstLetter}
                      keys={["id", "name"]}
                    />
                  </Grid>
                </Grid>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "right",
                    justifyContent: "right",
                    pt: 2,
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <SubmitButton loading={loadingButton}>
                      Gerar PDF/Impressão
                    </SubmitButton>
                    {/* <LightTooltip title="Imprimir Relatório">
                                            <IconButton color="primary" aria-label="upload picture" component="span" >
                                                <PrintIcon />
                                            </IconButton>
                                        </LightTooltip> */}
                  </Stack>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
