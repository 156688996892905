import { useContext } from "react";
import { useMutation } from "react-query";
import { useCaller } from "../service";
import useNotifier from "./useNotifier";
import { AuthContext } from "../contexts/AuthContext";

export default function useCallSystem(type) {
  const { callToken } = useCaller();
  const callerMutation = useMutation(callToken);
  const { userData } = useContext(AuthContext);
  const notify = useNotifier();

  function call(id) {
    try {
      callerMutation.mutate(
        {
          token_audit_id: id,
          station_ip: userData.station_ip,
          type,
        },
        {
          onSuccess: () => {
            notify(`Paciente chamado(a) com sucesso`, "success");
          },
          onError: (err) => {
            notify(err.message, "error");
          },
        }
      );
    } catch (error) {
      notify(error.message, "error");
    }
  }

  return call;
}
