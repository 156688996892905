import React from "react";
import { BoxContainer, PercentageChart } from "../../components";
import { Box, Typography, Grid, Divider, Button } from "@mui/material";
import { styled } from "@mui/system";
import {
  MaleRounded,
  FemaleRounded,
  TransgenderRounded,
  BarChartRounded,
  SettingsRounded,
} from "@mui/icons-material";
import { CustomizedBarChart } from "../../components";
import { theme } from "../../config/theme";
import { useCockpit } from "../../service";
import { Dialog, Loading } from "../../helper";
import { useQuery } from "react-query";
import CockPitConfig from "./CockPitConfig";
import useNotifier from "../../hooks/useNotifier";

// Styled Components
const DashTypography = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  marginBottom: 6,
  fontWeight: "600",
  color: `${theme.palette.primary.light}`,
  fontSize: "0.8em",
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.primary.light}`,
  fontWeight: "600",
  fontSize: "0.8em",
}));

const PercentageTypography = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.primary.light}`,
  backgroundColor: `${theme.palette.primary.medium}`,
  maxWidth: 200,
  width: "100%",
  padding: 3.8,
  textAlign: "center",
  fontWeight: "600",
  fontSize: "12px",
  borderRadius: "5px",
}));

const DashBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: `${theme.palette.primary.light}`,
  fontWeight: 600,
  fontSize: "2em",
  borderRadius: `5px`,
  padding: 5,
  display: "flex",
  width: "100%",
  maxWidth: 200,
  justifyContent: "center",
}));

const ScaleBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: `${theme.palette.primary.light}`,
  fontWeight: "600",
  fontSize: "2em",
  height: 48,
  padding: 0.5,
  borderRadius: `5px`,
  marginRight: "8px",
  maxWidth: "110px",
  width: "100%",
  textAlign: "center",
}));

const TableBox = styled(Box)(({ theme }) => ({
  maxWidth: 400,
  width: "100%",
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "5px",
  padding: "2px 0 2px 8px",
  display: "flex",
  alignItems: "center",
  fontSize: "13px",
  fontWeight: "600",
  justifyContent: "space-between",
}));

const TableBoxCell = styled(Box)(({ theme }) => ({
  maxWidth: 110,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  borderLeft: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "5px",
  fontWeight: "500",
  fontSize: "16px",
}));

// Settings to chart
const barConfig = {
  bars: [{ key: "number", fill: "url(#gdr2)" }],
  gradients: [
    {
      id: "gdr2",
      from: `${theme.palette.secondary.main}`,
      to: `${theme.palette.primary.main}`,
      x: "1",
    },
  ],
};

const HorizontalBarChart = ({ data }) => {
  return (
    <CustomizedBarChart
      sx={{ minWidth: 120 }}
      width={120}
      height={25}
      data={[{ number: data }]}
      config={barConfig}
      layout={"vertical"}
      x={{ hide: true, type: "number" }}
      y={{ hide: true, type: "category" }}
      radius={7}
      barSize={"100%"}
      limit={100}
      label={{
        position: "right",
        fontSize: ".8rem",
        fontWeight: 600,
        fill: `${theme.palette.primary.light}`,
        formatter: (value) => value + "%",
      }}
      margin={{ bottom: -20 }}
    />
  );
};

function CockPitSoap() {
  const notify = useNotifier();
  const { getCockpitSoap } = React.useMemo(useCockpit, []);
  const [attendance, setAttendance] = React.useState(0);
  const [patient, setPatient] = React.useState(0);
  const [service, setService] = React.useState();
  const [preExistIllness, setPreExistIllness] = React.useState();
  const [settingsDialog, setSettingsDialog] = React.useState(false);

  const queryCockpit = useQuery("cockpit", () => getCockpitSoap(), {
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onSuccess: ({ attendances, patients, services, preExistIllness }) => {
      setAttendance(attendances);
      setPatient(patients);
      setService(services);
      setPreExistIllness(preExistIllness);
    },
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  function handleSettingsDialog() {
    setSettingsDialog(true);
  }

  function handleCloseDialog() {
    setSettingsDialog(false);
  }

  return (
    <>
      {!queryCockpit.isFetching ? (
        <>
          <Dialog
            open={settingsDialog}
            title={"Configuração do Painel"}
            handleClose={handleCloseDialog}
            fullWidth
          >
            <CockPitConfig setDialog={setSettingsDialog} />
          </Dialog>
          <BoxContainer
            title={
              <>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Painel - SOAP</Typography>
                  <Button
                    onClick={handleSettingsDialog}
                    sx={{ height: 25 }}
                    variant="outlined"
                  >
                    <SettingsRounded fontSize="small" color="primary" />
                  </Button>
                </Box>
              </>
            }
          >
            <Grid container>
              <Grid item xs={4}>
                <DashTypography>Atendimento</DashTypography>
                <Box display="flex">
                  <ScaleBox>{attendance.count}</ScaleBox>
                  <Box display="flex">
                    <Box
                      display="flex"
                      width={"100%"}
                      sx={{ color: (theme) => theme.palette.primary.light }}
                    >
                      <MaleRounded sx={{ marginTop: "30px" }} />
                      <Typography fontSize="0.8em">
                        <PercentageChart
                          data={[{ percentage: attendance.mascPercent }]}
                          size={43}
                        />
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      sx={{ color: (theme) => theme.palette.primary.font }}
                    >
                      <FemaleRounded sx={{ marginTop: "30px", fontSize: 22 }} />
                      <Typography fontSize="0.8em">
                        <PercentageChart
                          data={[{ percentage: attendance.femPercent }]}
                          size={43}
                        />
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      sx={{ color: (theme) => theme.palette.secondary.main }}
                    >
                      <TransgenderRounded
                        sx={{ marginTop: "30px", marginLeft: 1 }}
                      />
                      <Typography fontSize="0.8em">
                        <PercentageChart
                          data={[{ percentage: attendance.other }]}
                          size={43}
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <DashTypography>Pacientes</DashTypography>
                <Box display="flex">
                  <ScaleBox>{patient.count}</ScaleBox>
                  <Box display="flex" gap="3px">
                    <Box
                      display="flex"
                      sx={{ color: (theme) => theme.palette.primary.light }}
                    >
                      <MaleRounded sx={{ marginTop: "30px" }} />
                      <Typography fontSize="0.8em">
                        <PercentageChart
                          data={[{ percentage: patient.mascPercent }]}
                          size={43}
                        />
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      sx={{ color: (theme) => theme.palette.primary.font }}
                    >
                      <FemaleRounded sx={{ marginTop: "30px", fontSize: 23 }} />
                      <Typography fontSize="0.8em">
                        <PercentageChart
                          data={[{ percentage: patient.femPercent }]}
                          size={43}
                        />
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      sx={{ color: (theme) => theme.palette.secondary.main }}
                    >
                      <TransgenderRounded
                        sx={{ marginTop: "30px", marginLeft: 1 }}
                      />
                      <Typography fontSize="0.8em">
                        <PercentageChart
                          data={[{ percentage: patient.otherPercent }]}
                          size={43}
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ marginY: "8px" }} />
            <Grid container spacing={2}>
              {service?.map(({ count, describe }, i) => (
                <Grid item key={count + "-" + i} xs={1.5}>
                  <LabelTypography>{describe}</LabelTypography>
                  <DashBox position="relative">
                    {count}
                    <BarChartRounded
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        position: "absolute",
                        right: 8,
                        top: 1,
                      }}
                    />
                  </DashBox>
                </Grid>
              ))}
            </Grid>
            <Divider variant="fullWidth" sx={{ marginY: "10px" }} />
            <Box display="flex" justifyContent="center" marginY="12px">
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "12px",
                  color: (theme) => theme.palette.primary.light,
                }}
              >
                COMORBIDADES
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={12} marginTop={1}>
                {preExistIllness?.map(
                  (
                    {
                      describe,
                      count,
                      generalPercent,
                      femPercent,
                      mascPercent,
                      otherPercent,
                    },
                    i
                  ) => (
                    <Box
                      key={count + "-" + i}
                      display="flex"
                      justifyContent="space-between"
                      gap={1.2}
                      maxWidth={2000}
                      width="100%"
                      sx={{
                        color: `${theme.palette.primary.light}`,
                        marginBottom: "3px",
                      }}
                    >
                      <TableBox>
                        {describe}
                        <TableBoxCell>{count}</TableBoxCell>
                      </TableBox>
                      <HorizontalBarChart data={generalPercent} />
                      <Box position="relative" width="100%" maxWidth={210}>
                        {i === 0 && (
                          <MaleRounded
                            sx={{
                              color: `${theme.palette.primary.light}`,
                              position: "absolute",
                              top: -25,
                              right: 25,
                            }}
                          />
                        )}
                        <PercentageTypography>
                          {mascPercent}%
                        </PercentageTypography>
                      </Box>
                      <Box position="relative" width="100%" maxWidth={210}>
                        {i === 0 && (
                          <FemaleRounded
                            sx={{
                              color: `${theme.palette.primary.font}`,
                              position: "absolute",
                              top: -25,
                              right: 25,
                              fontSize: 23,
                            }}
                          />
                        )}
                        <PercentageTypography>
                          {femPercent}%
                        </PercentageTypography>
                      </Box>
                      <Box position="relative" width="100%" maxWidth={210}>
                        {i === 0 && (
                          <TransgenderRounded
                            sx={{
                              color: `${theme.palette.secondary.main}`,
                              position: "absolute",
                              top: -25,
                              right: 25,
                            }}
                          />
                        )}
                        <PercentageTypography>
                          {otherPercent}%
                        </PercentageTypography>
                      </Box>
                    </Box>
                  )
                )}
              </Grid>
            </Grid>
          </BoxContainer>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default CockPitSoap;
