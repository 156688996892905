import React, { useEffect } from "react";
import { TITLE_NAME } from "../../config/config";
import { Helmet } from "react-helmet";
import { Grid, Box, Button, TablePagination } from "@mui/material";
import { useAgeGroup, useBatch } from "../../service/";
import { Dialog, DialogMedium, Error, Loading, Success } from "../../helper";
import { useQuery, useQueryClient } from "react-query";
import ImmunizingForm from "../Vaccine/Form/ImmunizingForm";
import ImmunizingEdit from "./Edit/ImmunizingEdit";
import ImmunizingFeedback from "../Vaccine/Feedback/ImmunizingFeedback";
import PaginatedTable from "../../components/Table/PaginatedTable";
import _ from "lodash";
import { format, parseISO } from "date-fns";
import { BoxContainer } from "../../components";
import usePermissions from "../../config/usePermissions";

function VaccineImmunizing() {
  const { getBatches } = useBatch();
  const queryClient = useQueryClient();

  const [immunizing, setImmunizing] = React.useState([]);
  const [visualizationImmunizing, setVisualizationImmunizing] =
    React.useState(false);
  const [editImmunizing, setEditImmunizing] = React.useState(false);
  const [newImmunizing, setNewImmunizing] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);
  const [unicImmunizing, setUnicImmunizing] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const permission = usePermissions("/vaccine/lots");

  const immunizingQuery = useQuery(
    ["immunizing", [page, limit]],
    () => getBatches({ page, limit }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  React.useEffect(() => {
    if (
      immunizingQuery.isSuccess &&
      immunizingQuery.data &&
      immunizingQuery.data.items
    ) {
      if (count === 0) {
        setCount(immunizingQuery.data.totalItems);
      }
      setImmunizing(
        immunizingQuery.data.items.map((index) => {
          return index;
        })
      );
      setRows(
        immunizingQuery.data.items.map((index) => {
          const expirationDate = format(
            parseISO(index.expiration_date),
            "dd/MM/yyyy"
          );
          return {
            id: index.id,
            code: index.code,
            manufacturer: index.manufacturer.describe,
            vaccine: index.vaccine.describe,
            bottles: index.bottles,
            active: index.active ? "Ativo " : "Inativo",
            expiration_date: expirationDate,
          };
        })
      );
    }
  }, [immunizingQuery.dataUpdatedAt]);

  function handleView() {
    setVisualizationImmunizing(true);
  }
  function handleEdit() {
    setEditImmunizing(true);
  }
  function handleNewImmunizing() {
    setNewImmunizing(true);
  }
  function handleClose() {
    setEditImmunizing(false);
    setVisualizationImmunizing(false);
    setNewImmunizing(false);
    queryClient.invalidateQueries("immunizing");
  }
  const columns = [
    { name: "Lote" },
    { name: "Fabricante" },
    { name: "Vacina" },
    { name: "Frascos" },
    { name: "Status" },
    { name: "Data de validade" },
  ];
  if (immunizingQuery.isLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Vacina</title>
      </Helmet>

      <DialogMedium
        open={editImmunizing}
        title={"Editar Lote"}
        handleClose={handleClose}
      >
        <ImmunizingEdit
          handleClose={handleClose}
          unicImmunizing={unicImmunizing}
        />
      </DialogMedium>
      <DialogMedium
        open={newImmunizing}
        title={"Cadastrar Lotes"}
        handleClose={handleClose}
      >
        <ImmunizingForm handleClose={handleClose} />
      </DialogMedium>
      <DialogMedium
        open={visualizationImmunizing}
        title={"Visualização de Lotes"}
        handleClose={handleClose}
        fullWidth
      >
        <ImmunizingFeedback data={unicImmunizing} />
      </DialogMedium>
      <BoxContainer title="Lotes">
        <PaginatedTable
          data={rows}
          columns={columns}
          actions
          actionsTypes={["view", "edit"]}
          disableActions={(_, action) =>
            action === "edit" && !permission.update
          }
          shouldDisableFields={["id"]}
          actionHandleEdit={(i) => {
            setUnicImmunizing(immunizing[i]);
            handleEdit(i);
          }}
          actionHandleView={(i) => {
            setUnicImmunizing(immunizing[i]);
            handleView(i);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={count}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "right", marginTop: 4 }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!permission.create}
            onClick={() => handleNewImmunizing(newImmunizing)}
          >
            Novo Cadastro
          </Button>
        </Box>
      </BoxContainer>
    </>
  );
}

export default VaccineImmunizing;
