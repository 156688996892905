import React from "react";
import { Form, Formik } from "formik";
import Yup from "../../../config/yup";
import { Box, Grid } from "@mui/material";
import {
  FormRadio,
  FormTextField,
  SubmitButton,
} from "../../../components/Form";
import { useVaccine } from "../../../service";
import { DateTime } from "../../../components/Form/FormFieldTime";
import { AppContext } from "../../../contexts/AppContext";
import useNotifier from "../../../hooks/useNotifier";

function RegisterImmuEdit({ unicRegisterImmus, handleCloseDialog }) {
  const { updateVaccines } = useVaccine();
  const { disableFields } = React.useContext(AppContext);
  const [selectedValue, setSelectedValue] = React.useState("");
  const notify = useNotifier();
  function handleChange(check) {
    setSelectedValue(check);
  }

  const minimumAge = `${
    unicRegisterImmus.minimumAge?.years
      ? unicRegisterImmus.minimumAge.years + "y"
      : ""
  } ${
    unicRegisterImmus.minimumAge?.months
      ? unicRegisterImmus.minimumAge.months + "m"
      : ""
  } ${
    unicRegisterImmus.minimumAge?.weeks
      ? unicRegisterImmus.minimumAge.weeks + "w"
      : ""
  } ${
    unicRegisterImmus.minimumAge?.days
      ? unicRegisterImmus.minimumAge.days + "d"
      : ""
  }`;
  const minimumInterval = `${
    unicRegisterImmus.minimumInterval?.years
      ? unicRegisterImmus.minimumInterval.years + "y"
      : ""
  } ${
    unicRegisterImmus.minimumInterval?.months
      ? unicRegisterImmus.minimumInterval.months + "m"
      : ""
  } ${
    unicRegisterImmus.minimumInterval?.weeks
      ? unicRegisterImmus.minimumInterval.weeks + "w"
      : ""
  } ${
    unicRegisterImmus.minimumInterval?.days
      ? unicRegisterImmus.minimumInterval.days + "d"
      : ""
  }`;
  const maximumAge = `${
    unicRegisterImmus.maximumAge?.years
      ? unicRegisterImmus.maximumAge.years + "y"
      : ""
  } ${
    unicRegisterImmus.maximumAge?.months
      ? unicRegisterImmus.maximumAge.months + "m"
      : ""
  } ${
    unicRegisterImmus.maximumAge?.weeks
      ? unicRegisterImmus.maximumAge.weeks + "w"
      : ""
  } ${
    unicRegisterImmus.maximumAge?.days
      ? unicRegisterImmus.maximumAge.days + "d"
      : ""
  }`;
  const initialValues = {
    describe: unicRegisterImmus.describe,
    minimumAge: minimumAge.replace(/( ){2,}/g, "$1").trim(),
    minimumInterval: minimumInterval.replace(/( ){2,}/g, "$1").trim(),
    maximumAge: maximumAge.replace(/( ){2,}/g, "$1").trim(),
    recommendedDose: unicRegisterImmus.recommendedDose,
    incompatibleReagents: unicRegisterImmus.incompatibleReagents,
    abbreviation: unicRegisterImmus.abbreviation,
  };
  const validations = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
    minimumAge: Yup.string()
      .required("É requerido")
      .matches(
        /^(?=\d+[ymwd])(( ?\d+y)?(?!\d))?(( ?\d+m)?(?!\d))?(( ?\d+w)?(?!\d))?(( ?\d+d)?(?!\d))?$/g,
        "Formato Incorreto, Esperado : 0y 0m 0w 0d"
      ),
    minimumInterval: Yup.string().matches(
      /^(?=\d+[ymwd])(( ?\d+y)?(?!\d))?(( ?\d+m)?(?!\d))?(( ?\d+w)?(?!\d))?(( ?\d+d)?(?!\d))?$/g,
      "Formato Incorreto, Esperado : 0y 0m 0w 0d"
    ),
    mmaximumAge: Yup.string().matches(
      /^(?=\d+[ymwd])(( ?\d+y)?(?!\d))?(( ?\d+m)?(?!\d))?(( ?\d+w)?(?!\d))?(( ?\d+d)?(?!\d))?$/g,
      "Formato Incorreto, Esperado : 0y 0m 0w 0d"
    ),
    recommendedDose: Yup.string().required("É requerido"),
    incompatibleReagents: Yup.string(),
  });
  async function handleSubmit(values) {
    const vaccineObject = {
      id: unicRegisterImmus.id,
      describe: values.describe,
      minimumAge: values.minimumAge,
      maximumAge: values.maximumAge,
      minimumInterval: values.minimumInterval,
      recommendedDose: values.recommendedDose,
      incompatibleReagents: values.incompatibleReagents,
      abbreviation: values.abbreviation,
    };

    const id = unicRegisterImmus.id;

    try {
      const response = await updateVaccines(id, vaccineObject);
      if (response) {
        notify("Imunobiologico atualizado com Sucesso!", "success");
      }
    } catch (err) {
      notify(err.message, "error");
    }
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ ...initialValues }}
        validationSchema={validations}
      >
        {({}) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <FormTextField
                  name="describe"
                  label="Imunobiológicos"
                  required
                />
              </Grid>
              <Grid xs={7} item>
                <FormTextField name="abbreviation" label="Sigla" required />
              </Grid>
              <Grid xs={5} item>
                <FormTextField
                  name="recommendedDose"
                  label="Dose Recomendada"
                  required
                />
              </Grid>
              <Grid xs={4} item>
                <DateTime title="Idade Minima" name="minimumAge" />
              </Grid>
              <Grid xs={5} item>
                <DateTime title="Idade Máxima" name="maximumAge" />
              </Grid>
              <Grid xs={4} item>
                <DateTime title="Intervalo Minimo" name="minimumInterval" />
              </Grid>
              <Grid xs={12} item>
                <FormTextField
                  name="incompatibleReagents"
                  required
                  multiline
                  minRows={4}
                  maxRows={4}
                  label="Obs: Reag. Imcompativeis : "
                  sx={{ mb: 4 }}
                />
              </Grid>
            </Grid>
            <Grid
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "right",
              }}
            >
              <SubmitButton>Salvar Alterações</SubmitButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default RegisterImmuEdit;
