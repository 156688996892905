import React from "react";
import {
  SubmitButton,
  FormTextField,
  DateTime,
  FormSelectField,
} from "../../../../components/Form";
import { Formik, Form } from "formik";
import Yup from "../../../../config/yup";
import PaginatedTable from "../../../../components/Table/PaginatedTable";
import { Grid } from "@mui/material";

const ExamsForm = ({ rows = [], setRows, setRowsToDelete }) => {
  // Handle Functions
  function handleDelete(index) {
    const filteredrows = rows.filter((_, key) => key !== index);
    setRows(filteredrows);

    if (!!rows[index]?.id) {
      setRowsToDelete((deletedRows) => [...deletedRows, rows[index].id]);
    }
  }

  function addRows(row, { resetForm }) {
    setRows((previousrows) => [...previousrows, row]);
    resetForm();
  }

  const initialValues = {
    type: null,
    describe: "",
    frequency: "",
    notes: "",
  };

  const validations = Yup.object().shape({
    type: Yup.string().required("É requerido").nullable(),
    describe: Yup.string().required("É requerido"),
    frequency: Yup.string().validateTimeSchema(),
    notes: Yup.string(),
  });

  const examTypes = {
    Laboratorial: "Exame laboratorial",
    Imagem: "Exame de imagem",
  };

  const collumns = [
    { name: "Tipo" },
    { name: "Exame" },
    { name: "Frequência" },
    { name: "Descrição" },
  ];

  return (
    <Formik
      onSubmit={addRows}
      initialValues={initialValues}
      validationSchema={validations}
    >
      {() => (
        <>
          <Form>
            <Grid
              xs={12}
              spacing={3}
              container
              item
              sx={{
                marginTop: -2,
                marginBottom: "20px",
              }}
            >
              <Grid xs={3} md={2} item>
                <FormSelectField
                  name="type"
                  label="Tipo de exame"
                  required
                  options={examTypes}
                />
              </Grid>
              <Grid xs={4} md={3} item>
                <FormTextField name="describe" label="Exame" required />
              </Grid>
              <Grid xs={3} md={2} item>
                <DateTime title="Frequência" name="frequency" />
              </Grid>
              <Grid xs={12} item container spacing={3}>
                <Grid xs={7} item>
                  <FormTextField name="notes" label="Descrição" required />
                </Grid>
                <Grid item xs={3} md={2}>
                  <SubmitButton>Adicionar</SubmitButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
          <PaginatedTable
            sx={{ marginTop: 3 }}
            columns={collumns}
            data={rows}
            actions
            actionsTypes={["delete"]}
            shouldDisableFields={["id"]}
            actionHandleDelete={handleDelete}
          />
        </>
      )}
    </Formik>
  );
};

export default ExamsForm;
