import { useTheme } from "@emotion/react";
import React from "react";
import { DialogMedium } from "../../../helper";
import { usePagination, useProvenance } from "../../../service";
import { useMutation, useQuery } from "react-query";
import Yup from "../../../config/yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TablePagination, Typography } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import { TextField, SwitchField } from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

export default function ProvenanceStep() {
  const theme = useTheme();
  const notify = useNotifier();

  const [selectProvenance, setSelectProvenance] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [limit, setLimit] = React.useState(5);

  const { getProvenances, postProvenances, updateProvenance } = useProvenance();

  const createProvenanceMutation = useMutation(postProvenances);
  const updateProvenanceMutation = useMutation(updateProvenance);

  const provenanceQuery = useQuery(["provenance"], getProvenances, {
    retry: false,
    initialData: [],
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  const { paginationInfo, handlePageChange } = usePagination(
    provenanceQuery.data,
    limit
  );

  const columns = [
    {
      field: "number",
      name: "Codigo",
      type: "string",
    },
    {
      field: "describe",
      name: "Procedência",
      type: "string",
    },

    {
      field: "active",
      name: "Status",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
    },
  ];

  const validator = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      describe: "",
    },
    resolver: yupResolver(validator),
  });

  const editProvenanceForm = useForm({
    defaultValues: {
      describe: selectProvenance?.describe,
      active: selectProvenance?.active,
    },
    resolver: yupResolver(validator),
  });

  const handleCloseDialog = () => {
    setEdit(false);
    setSelectProvenance({});
  };

  const onSubmit = handleSubmit((values) => {
    createProvenanceMutation.mutate(values, {
      onSuccess: (data) => {
        notify(data.message, "success");
        reset();
        provenanceQuery.refetch();
      },
      onError: (err) => {
        notify(err.message, "error");
      },
    });
  });

  const onEditSubmit = editProvenanceForm.handleSubmit((values) => {
    updateProvenanceMutation.mutate(
      { id: selectProvenance.id, data: values },
      {
        onSuccess: (data) => {
          notify(data.message, "success");
          reset();
          setEdit(false);
          setSelectProvenance({});
          provenanceQuery.refetch();
        },
        onError: (err) => {
          notify(err.message, "error");
        },
      }
    );
  });

  return (
    <>
      <DialogMedium
        open={edit}
        fullWidth
        maxWidth="sm"
        title="Edição de Procedência"
        handleClose={handleCloseDialog}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              control={editProvenanceForm.control}
              name="describe"
              label="Descrição"
              required
            />
          </Grid>
          <Grid item xs={3}>
            <SwitchField
              control={editProvenanceForm.control}
              name="active"
              label="Alterar Status"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={onEditSubmit}
              onLoad={updateProvenanceMutation.isLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Procedência
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items}
          columns={columns}
          loading={provenanceQuery.isLoading}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit"]}
          shouldDisableFields={["id"]}
          actionHandleEdit={(_, item) => {
            editProvenanceForm.setValue("describe", item.describe);
            editProvenanceForm.setValue("active", item.active);
            setSelectProvenance(item);
            setEdit(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Procedência
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            control={control}
            name={"describe"}
            label={"Descrição"}
            required
          />
        </Grid>

        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={onSubmit}
            onLoad={createProvenanceMutation.isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
