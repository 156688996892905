import React, { useState } from "react";
import Yup from "../../../config/yup";
import { Grid, Typography } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import { usePresets, useProcedures } from "../../../service";
import { useMutation, useQueryClient } from "react-query";
import useNotifier from "../../../hooks/useNotifier";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  PaginatedAutocompleteField,
  SelectField,
  TextField,
} from "../../../components/FormFields";
import { useForm } from "react-hook-form";

function ExamsPreset({ type, data }) {
  const notify = useNotifier();
  const [exams, setExams] = useState(() => (type === "edit" ? data.data : []));

  const queryClient = useQueryClient();
  const { getProcedures } = useProcedures();
  const { postPresets, patchPreset } = usePresets();

  const postPresetsMutation = useMutation(postPresets);
  const patchPresetsMutation = useMutation(patchPreset);

  const validations = Yup.object().shape({
    preset_name: Yup.string().required("É requerido"),
    type: Yup.string().required("É requerido"),
    in_system: Yup.string(),
    describe: Yup.mixed().when("in_system", {
      is: "true",
      then: Yup.object().required("É requerido").nullable(),
      otherwise: Yup.string()
        .typeError("Valor inválido")
        .required("É requerido")
        .nullable(),
    }),
    notes: Yup.string().nullable(),
  });

  const { control, watch, handleSubmit, reset } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      preset_name: type === "edit" ? data.describe : "",
      type: "",
      in_system: "true",
      describe: null,
      notes: "",
    },
  });

  const [inSystem, presetName, examType] = watch([
    "in_system",
    "preset_name",
    "type",
  ]);

  const addPrescription = (values) => {
    const exam = {
      ...values,
      tuss_code: values.describe?.tuss_code || null,
      describe:
        typeof values.describe === "string"
          ? values.describe
          : values.describe.name,
    };

    delete exam.preset_name;
    delete exam.in_system;

    setExams((prescriptions) => [...prescriptions, exam]);

    reset({ preset_name: values.preset_name });
  };

  const removePrescription = (index) => {
    const newPrescriptions = exams.filter(
      (_, examIndex) => examIndex !== index
    );
    setExams(newPrescriptions);
  };

  const submitPresets = () => {
    const presetData = {
      describe: presetName,
      type: "exams",
      data: exams,
    };

    if (type === "edit") {
      patchPresetsMutation.mutate(
        { presetId: data.id, data: presetData },
        {
          onSuccess(response) {
            notify(response.message, "success");
            queryClient.invalidateQueries("presets");
          },
          onError(error) {
            notify(error.message, "error");
          },
        }
      );
    } else {
      postPresetsMutation.mutate(presetData, {
        onSuccess(response) {
          reset({ preset_name: "" });
          setExams([]);
          notify(response.message, "success");
          queryClient.invalidateQueries("presets");
        },
        onError(error) {
          notify(error.message, "error");
        },
      });
    }
  };

  const examsFilters = {
    Laboratorial: "lab",
    Imagem: "image",
  };

  const examTypes = [
    {
      label: "Exame laboratorial",
      value: "Laboratorial",
    },
    {
      label: "Exame de imagem",
      value: "Imagem",
    },
  ];

  const tableColumns = [
    { name: "Nome", field: "describe" },
    { name: "Tipo", field: "type" },
    { name: "Descrição", field: "notes" },
  ];

  return (
    <Grid container spacing={2} component="form" marginTop="0.5rem">
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography fontSize={18} color="secondary" fontWeight="600">
            Dados da pré definição
          </Typography>
        </Grid>
        <Grid md={3} xs={4} item>
          <TextField
            name="preset_name"
            label="Nome da pré definição"
            control={control}
            required
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography fontSize={18} color="secondary" fontWeight="600">
            Dados dos exames
          </Typography>
        </Grid>
        <Grid md={3} xs={4} item>
          <SelectField
            name="type"
            label="Tipo de exame"
            control={control}
            optionLabelKey="label"
            optionValueKey="value"
            options={examTypes}
            required
          />
        </Grid>
        <Grid md={3} xs={5} item>
          {inSystem ? (
            <PaginatedAutocompleteField
              optionLabelKey="describe"
              name="describe"
              label="Exame"
              required
              control={control}
              filterKey="filters"
              refetchService={[examType]}
              queryKey="procedures"
              service={(params) =>
                getProcedures({
                  ...params,
                  type: examsFilters[examType],
                  filter: "active",
                })
              }
              autoCompleteProps={{
                getOptionLabel: (data) => {
                  let label = data?.name;

                  if (data?.tuss_code) {
                    label = `${data?.tuss_code} - ${label}`;
                  }

                  return label;
                },
              }}
            />
          ) : (
            <TextField
              name="describe"
              label="Exame"
              control={control}
              required
            />
          )}
        </Grid>
        <Grid md={7} xs={9} item>
          <TextField name="notes" label="Observação" control={control} />
        </Grid>
        <Grid item xs={3}>
          <Button onClick={handleSubmit(addPrescription)}>
            Adicionar exame
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableFilter
          data={exams}
          columns={tableColumns}
          noHeaderOptions
          actions
          actionsTypes={["delete"]}
          confirmMessage="Você realmente deseja *remover* esse medicamento?"
          actionHandleDelete={removePrescription}
        />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={submitPresets} disabled={!exams.length || !presetName}>
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}

export default ExamsPreset;
