import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/healthUnits";

export default function useHealthUnit() {
  async function getHealthUnits(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postHealthUnits(objectValues) {
    try {
      const response = await instance.post(path, objectValues);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updateHealthUnits(id, data) {
    try {
      const response = await instance.put(`${path}/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  return {
    getHealthUnits,
    postHealthUnits,
    updateHealthUnits,
  };
}
