import { Box, Grid } from "@mui/material";
import { Teeth } from "../../../components/Arch";
import { useContext } from "react";
import { useFormikContext } from "formik";
import { FormSwitch } from "../../../components/Form";
import { ColorInfoLabels } from "../../../components";
import { AppContext } from "../../../contexts/AppContext";

export default function TeethTab({ attendance, archType, handleArchType }) {
  const { values } = useFormikContext();
  const { disableFields } = useContext(AppContext);

  return (
    <Grid item xs={12}>
      <Box display={"flex"} width={"100%"} flexDirection={"row-reverse"}>
        <FormSwitch
          name={"archType"}
          label={"Decíduos"}
          customHandleChange={(value) => {
            // setFieldValue("teeth", []);
            if (value) {
              handleArchType("deciduous");
            } else {
              handleArchType("default");
            }
          }}
        />
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Teeth
          name={"teeth"}
          archType={archType}
          size="90%"
          disabledTop={values.upper_denture}
          disabledBottom={values.lower_denture}
          disabled={disableFields}
          attendance={attendance}
        />
      </Box>
      <Grid item xs={12}>
        <ColorInfoLabels
          labels={[
            {
              text: "Tratado",
              color: "#6749ED",
            },
            {
              text: "Não Tratado",
              color: "#FF5C5C",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
