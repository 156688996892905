import { Box } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from "recharts";
import { theme } from "../../config/theme";

const CustomizedBarChart = ({
  data,
  config,
  width,
  height,
  sx,
  layout = "horizontal",
  x,
  y,
  radius,
  margin = {},
  barSize,
  label = false,
  limit = 100,
  cartesianGrid = {},
  legend = {},
}) => {
  const gradients =
    config &&
    config.gradients?.map((gradient) => {
      return (
        <defs key={gradient.id}>
          <linearGradient
            id={gradient.id || ""}
            x1={gradient.x || "0"}
            y1="0"
            x2="0"
            y2={gradient.y || "0"}
          >
            <stop
              offset="5%"
              stopColor={gradient.to || "#151A20"}
              stopOpacity={0.9}
            />
            <stop
              offset="95%"
              stopColor={gradient.from || "#fff"}
              stopOpacity={0.7}
            />
          </linearGradient>
        </defs>
      );
    });

  const bars =
    config &&
    config.bars?.map((bar, i) => {
      if (label?.formatter) {
        const oldFormatter = label.formatter;
        label.formatter = (value) => {
          return oldFormatter(value, data[i]);
        };
      }

      return (
        <Bar
          key={bar.key}
          dataKey={bar.key}
          fill={bar.fill || "#fff"}
          radius={radius}
          maxBarSize={barSize}
          label={label}
          isAnimationActive={false}
          background={{ fill: `${theme.palette.primary.medium}`, radius }}
        />
      );
    });

  return (
    <Box width={width} height={height} sx={sx}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} layout={layout} margin={margin}>
          {!!Object.keys(cartesianGrid)?.length && (
            <CartesianGrid {...cartesianGrid} />
          )}
          {!!Object.keys(legend)?.length && <Legend {...legend} />}
          {bars}
          {gradients}
          <XAxis {...x} domain={[0, limit || x?.dataKey]} />
          <YAxis {...y} domain={[0, limit || y?.dataKey]} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CustomizedBarChart;
