import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

export function useConducts() {
  const path = "/conducts";

  async function getConducts(params = {}) {
    try {
      const result = instance.get(path, { params: urlQuery(params) });
      return (await result).data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  return {
    getConducts
  };
}
