import { Box, LinearProgress, Typography, useTheme } from "@mui/material";

export default function PercentBar({percent = 0 , colors = [], labels =[], }) {

  return (
    <Box width={"100%"} height={'100%'} >
      <Box display={"flex"} justifyContent={"space-between"} paddingX={'8px'}>
        <Typography
        component={'span'}
          sx={{ fontWeight: "500", letterSpacing: 0.2, fontSize: "10px" }}
        >
          {
            labels[0]
          }
        </Typography>
        <Typography
        component={'span'}
          sx={{ fontWeight: "500", letterSpacing: 0.2, fontSize: "10px" }}
        >
          {
            labels[1]
          }
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={percent}
        sx={{
          background: colors[0] || "#FF5F5F",
          minHeight: "20px",
          borderRadius: '30px',
          ".MuiLinearProgress-bar1Determinate": {
            clipPath: (percent && percent < 100) ? "polygon(0% 0%, 100% 0%, 0% 5000%)" : 'inherit',
            backgroundColor: colors[1] || "#4DD952",
          },
        }}
      />
    </Box>
  );
}
