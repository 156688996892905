import React from "react";
import { TITLE_NAME } from "../../config/config";
import { Helmet } from "react-helmet";
import { BoxContainer } from "../../components";
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/system";
import ReportManagement from "./ReportManagement/ReportManagement";
import ReportOperational from "./ReportOperational/ReportOperational";
import ReportStatistical from "./ReportStatistical/ReportStatistical";
import ReportRegister from "./ReportRegister/ReportRegister";

const TabBackground = styled(Tab)(({ theme }) => ({
  marginBottom: 10,
  background: "#E1F1FB",
  color: "#9AC0D8",
  borderRadius: 5,
  transition: ".3s",
  textTransform: "capitalize",
  minHeight: 10,
  padding: 10,
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    borderColor: "transparent",
  },
}));

function Report() {
  const [tab, setTab] = React.useState(0);

  function handleChange(event, value) {
    setTab(value);
  }
  const tabsHeader = [
    "Gerenciais",
    "Operacionais",
    "Estatísticos",
    "Cadastrais",
  ];

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Relatórios</title>
      </Helmet>
      <Grid container spacing={2}>
        <TabContext value={tab}>
          <Grid item xs={3}>
            <BoxContainer title="Relatório" align="center">
              <TabList
                orientation="vertical"
                onChange={handleChange}
                aria-label="lab API tabs example"
                sx={{ "& .MuiTabs-indicator": { display: "none" } }}
              >
                {tabsHeader.map((item, key) => (
                  <TabBackground label={item} value={key} />
                ))}
              </TabList>
            </BoxContainer>
          </Grid>
          <Grid item xs={9}>
            <BoxContainer title={`Relatórios do(a) ${tabsHeader[tab]}`}>
              {/* <TabPanel value={0}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>  
                  <Grid item xs={12}>
                    <ReportManagement />
                  </Grid>
                </Grid>
              </TabPanel> */}
              <TabPanel value={1}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <ReportOperational />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={2}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <ReportStatistical />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={3}>
                <Grid container spacing={2} sx={{ ml: -5, mt: -5 }}>
                  <Grid item xs={12}>
                    <ReportRegister />
                  </Grid>
                </Grid>
              </TabPanel>
            </BoxContainer>
          </Grid>
        </TabContext>
      </Grid>
    </>
  );
}

export default Report;
