import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/services";
function useService() {
  async function getServices(params) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function getService(id) {
    try {
      const response = await instance.get(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postService(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function deleteEmployeeTypeInService({ id, ...data }) {
    try {
      const response = await instance.post(
        `/services/${id}/employee-types/batch-delete`,
        data
      );
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updateService({ id, data }) {
    try {
      const response = await instance.put(`${path}/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getServices,
    getService,
    postService,
    updateService,
    deleteEmployeeTypeInService,
  };
}

export default useService;
