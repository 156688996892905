import React from "react";
import { Form, Formik } from "formik";
import Yup from "../../../config/yup";
import { Grid } from "@mui/material";
import { FormTextField, SubmitButton } from "../../../components/Form";
import { useHealthUnit } from "../../../service";
import useNotifier from "../../../hooks/useNotifier";

function UnitHealthEdit({ unicUnitHealth, handleCloseDialog }) {
  const { updateHealthUnits } = useHealthUnit();
  const notify = useNotifier();
  const initialValues = {
    id: unicUnitHealth.id,
    code: unicUnitHealth.code,
    describe: unicUnitHealth.describe,
    cnes: unicUnitHealth.cnes,
  };
  const validations = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
    code: Yup.number().required("É requerido").typeError("Código Inválido"),
    cnes: Yup.number().required("É requerido").typeError("CNES Inválida"),
  });
  async function handleSubmit(values) {
    const healthUnitsObject = {
      id: values.id,
      describe: values.describe,
      code: values.code,
      cnes: values.cnes,
    };

    try {
      const response = await updateHealthUnits(
        unicUnitHealth.id,
        healthUnitsObject
      );
      if (response) {
        notify("Unidade atualizada com sucesso!", "success");
      }
    } catch (err) {
      notify(err.message, "error");
    }
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
      >
        {({}) => (
          <Form>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid xs={6} item>
                <FormTextField
                  name="code"
                  label="Cód. Unidade Básica"
                  required
                />
              </Grid>
              <Grid xs={6} item>
                <FormTextField name="describe" label="Descrição" required />
              </Grid>
              <Grid xs={6} item>
                <FormTextField
                  name="cnes"
                  label="Cadastro Nacional de Estabelecimentos de Saúde."
                  required
                />
              </Grid>
            </Grid>
            <Grid
              sx={{ display: "flex", justifyContent: "right", marginTop: 1 }}
            >
              <SubmitButton>Salvar Cadastro</SubmitButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default UnitHealthEdit;
