import {
  headerHorizontalStyle,
  footer,
  title,
  patientInformations,
} from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";
import { drugAdmininstrations } from "../config/standardSelects";
import { add, format, parseISO } from "date-fns";

function drugOnSitePrescriptionPDF(data, patient, documentData) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let medications;

  if (!data?.length) {
    medications = {
      margin: [0, 40, 0, 20],
      text: "Nenhum medicamento encontrado para este receituário.",
    };
  } else {
    const proceduresTitles = drugAdmininstrations.reduce(
      (previous, { title, procedure_id }) => {
        return { ...previous, [procedure_id]: title };
      },
      {}
    );

    const groupedMedications = [];
    const notGroupedMedications = [];

    data.forEach((medication) => {
      const groupIndex = groupedMedications.findIndex(
        ({ procedure_id }) => medication.procedure_id === procedure_id
      );

      if (!medication.procedure_id) {
        notGroupedMedications.push(medication);
      } else if (groupIndex !== -1) {
        groupedMedications[groupIndex].medications.push(medication);
      } else {
        groupedMedications.push({
          title: proceduresTitles[medication.procedure_id],
          procedure_id: medication.procedure_id,
          medications: [medication],
        });
      }
    });

    if (notGroupedMedications.length) {
      medications = {
        margin: [0, 40, 0, 20],
        ol: notGroupedMedications.map(
          ({ name, presentation, quantity, posology, notes }) => ({
            text: [
              { text: `${name} ${quantity} ${presentation}`, bold: true },
              { text: ` ${posology} \n` },
              { text: notes ? notes.toUpperCase() : "" },
            ],
            margin: [0, 0, 0, 10],
          })
        ),
      };
    } else {
      medications = groupedMedications.map((group, index) => [
        {
          margin: [0, index === 0 ? 30 : 10, 0, 10],
          fontSize: 12,
          text: group.title,
          bold: true,
          style: "header",
        },
        {
          ol: group.medications.map(
            ({
              name,
              presentation,
              quantity,
              posology,
              notes,
              treatment_cycle,
              medication_assisted_treatment,
              ...data
            }) => {
              for (let i = name.length; i < 80; i++) {
                name += ".";
              }

              return [
                {
                  text: `${name} | ${
                    quantity ? `${quantity} |` : ""
                  } ${presentation}`,
                  bold: true,
                },
                {
                  ul: [
                    {
                      text: posology,
                    },
                    {
                      text: notes || "",
                    },
                  ],
                  margin: [0, 0, 0, 10],
                },
                {
                  text: !medication_assisted_treatment
                    ? ""
                    : "Medicamento Assistido: Comparecer a unidade para realizar a administração.",
                  bold: true,
                },
                {
                  text: !medication_assisted_treatment
                    ? ""
                    : `Previsão de Término ${format(
                        add(
                          documentData?.createdAt
                            ? parseISO(documentData.createdAt)
                            : new Date(),
                          {
                            days: treatment_cycle,
                          }
                        ),
                        "dd/MM/yyyy"
                      )}`,
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
              ];
            }
          ),
        },
      ]);
    }
  }

  const urlType = pdfName + "/types/medications_on_site";

  const definitions = {
    info: { title: pdfName },
    pageMargins: [40, 105, 40, 260],
    defaultStyle: {
      fontSize: 10,
    },
    ...headerHorizontalStyle(documentData?.company),
    footer: footer(
      documentData?.createdAt,
      true,
      documentData?.employee,
      documentData?.company,
      urlType,
      [0, 140, 0, 0]
    ),
    content: [
      title("Prescrição de Medicamentos"),
      patientInformations(patient),
      medications,
    ],
  };
  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default drugOnSitePrescriptionPDF;
