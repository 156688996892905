import instance from "../config/api";
import urlQuery from "./../helper/urlQuery";
const path = "/scales/days/schedules";

const filterPathEmployee = "/scales?employee_number=";
const filterPathSector = "/scales?id_sector=";
const filterPathNumber = "/scales?number=";
const filterPathService = "/scales?id_service=";
const filterPathDay = "/scales?day=";

function useFilterScale() {
  async function filterScale(params) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function filterEmployee(query) {
    try {
      const response = await instance.get(filterPathEmployee + query);

      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function filterSector(query) {
    try {
      const response = await instance.get(filterPathSector + query);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function filterService(query) {
    try {
      const response = await instance.get(filterPathService + query);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function filterNumber(query) {
    try {
      const response = await instance.get(filterPathNumber + query);

      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function filterDay(query) {
    try {
      const response = await instance.get(filterPathDay + query);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    filterEmployee,
    filterSector,
    filterNumber,
    filterService,
    filterDay,
    filterScale,
  };
}
export default useFilterScale;
