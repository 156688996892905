import { useTheme } from "@emotion/react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  ListItem,
  List,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import useDocuments from "../../../service/useDocuments";
import { FormMakerContext } from "../../../contexts/FormMakerContext";
import { LocalHospitalRounded, Print } from "@mui/icons-material";
import { FormMakerFeedback } from "../../../components/FormMaker";
import { format, parseISO } from "date-fns";
import { Dialog } from "../../../helper";
import { AppContext } from "../../../contexts/AppContext";
import { classificationColors } from "../../../config/standardSelects";
import { useNotifier } from "../../../hooks";

const TabClassificationHistory = () => {
  const theme = useTheme();
  const [documentId, setDocumentId] = useState();
  const [displayClassification, setDisplayClassification] = useState(false);
  const notify = useNotifier();
  const { getDocumentsByType, getRecords } = useDocuments();
  const { patientData } = useContext(AppContext);
  const { setRecordDocument } = useContext(FormMakerContext);

  useQuery(
    ["documents-by-type"],
    () => getDocumentsByType("af3668a2-f760-4540-9281-952525d083e8"),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: [],
      onSuccess: (response) => {
        setDocumentId(response[0]?.external_document_id);
      },
      onError: (error) => {
        notify(error.message);
        setDocumentId(null);
      },
    }
  );

  const recordQuery = useQuery(
    ["records", patientData],
    () => getRecords({ patientId: patientData?.id, documentId }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!patientData?.id && !!documentId,
      onError: (error) => {
        notify(error.message);
        setRecordDocument(null);
      },
    }
  );

  const patientInformations = [
    {
      label: "Paciente",
      value: patientData?.social_prioritize
        ? patientData.social_name
        : patientData?.name || "Não informado" || "Não informado",
    },
    {
      label: "CPF",
      value: patientData?.physic_national || "Não informado",
    },
    { label: "CNS", value: patientData?.sus_card || "Não informado" },
    {
      label: "Data de nascimento",
      value: patientData?.birth_date
        ? format(parseISO(patientData.birth_date), "dd/MM/yyyy")
        : "Não informado",
    },
  ];
  return (
    <>
      <Dialog
        title={"Documento de classificação"}
        open={displayClassification}
        handleClose={() => setDisplayClassification(false)}
      >
        <FormMakerFeedback
          additionalData={patientInformations}
          signature
          signatureValidationPath="risk_classification"
        />
      </Dialog>
      <Box
        sx={{
          width: "calc(100% - 30px)",
        }}
      >
        <Typography variant="h6" color="secondary" fontWeight="700">
          Histórico de Classificação
        </Typography>
        <List
          sx={{
            maxHeight: 180,
            overflowY: "auto",
          }}
        >
          {recordQuery?.data?.items?.map((items, index) => {
            const classificationColorField = items.fields.filter(
              (i) => i.field_id === "6aed06b0-6b14-11ed-a7da-a122921f8f3b"
            )[0]?.value;
            return !!items?.fields?.length ? (
              <ListItem
                sx={{
                  paddingLeft: 0,
                  paddingRight: 1,
                  paddingY: 0.5,
                }}
              >
                <Grid
                  container
                  spacing={0}
                  sx={{
                    width: "100%",
                    position: "relative",
                    backgroundColor: theme.palette.primary.medium,
                    padding: 2,
                    borderRadius: 2,
                  }}
                >
                  <IconButton
                    color="primary"
                    sx={{
                      position: "absolute",
                      top: 20,
                      right: 15,
                    }}
                    onClick={() => {
                      setDisplayClassification(true);
                      setRecordDocument(recordQuery?.data?.items[index]);
                    }}
                  >
                    <Print fontSize="large" color="primary" />
                  </IconButton>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <LocalHospitalRounded
                        color="primary"
                        sx={{
                          width: 24,
                          height: 24,
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: theme.palette.primary.main,
                        }}
                        variant="span"
                      >
                        {items?.risk_classification?.token_audit?.attendance
                          ?.number || "Não Encontrado"}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: classificationColors[classificationColorField],
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: theme.palette.primary.main,
                        }}
                        variant="span"
                      >
                        {"Classificação: "}
                      </Typography>
                      {classificationColorField || "Não informado"}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: theme.palette.primary.main,
                      }}
                      variant="span"
                    >
                      Data e Hora:{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: theme.palette.primary.main,
                      }}
                      variant="span"
                    >
                      {items?.createdAt
                        ? format(
                            parseISO(items?.createdAt),
                            "dd/MM/yyyy 'às' HH:mm"
                          )
                        : "Não informado"}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: theme.palette.primary.main,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: theme.palette.primary.main,
                        }}
                        variant="span"
                      >
                        Senha:{" "}
                      </Typography>
                      {items?.risk_classification?.token_audit
                        ?.token_describe || "Não informado"}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: theme.palette.primary.main,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: theme.palette.primary.main,
                        }}
                        variant="span"
                      >
                        Status:{" "}
                      </Typography>
                      {items?.status === "finished" ? "Finalizado" : "Ativo"}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ) : (
              <Typography pl={2} color={theme.palette.primary.main}>
                O paciente não possui classificações
              </Typography>
            );
          })}
        </List>
      </Box>
    </>
  );
};

export default TabClassificationHistory;
