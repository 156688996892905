export default function verifyAttendanceFlow(isClassification) {
  let userData = localStorage.getItem("user-data");
  
  if(userData) {
    userData = JSON.parse(userData);
    const allowedCompany = userData.company.type.AMA || userData.company.type.UBS;

    if(allowedCompany) {
      if(isClassification) {
        return "ama";
      }

      return "ubs";
    }
  }

  return undefined;
}