import { parseISO, formatDuration, intervalToDuration } from "date-fns";
import ptBR from "date-fns/esm/locale/pt-BR";

export default function convertDateTobirthday(
  dateToConvert,
  unities = ["years", "months", "days"],
  verbose = true,
) {

  if (!dateToConvert) return; 
  const units = unities;
  const duration = intervalToDuration({
    start: parseISO(dateToConvert),
    end: new Date(),
  });

  const filteredDuration = {};
  units.forEach((unit) => {
    filteredDuration[unit] = duration[unit] || 0;
  });

  let formatedDate = formatDuration(filteredDuration, {
    delimiter: ", ",
    locale: ptBR,
  });


  if(!verbose) formatedDate = filteredDuration;

  return formatedDate;
}
