import {
  Box,
  Button,
  Grid,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { VerifyPermission } from "../../components/RequireAuth/VerifyPermission";
import usePermissions from "../../config/usePermissions";
import useTotem from "../../service/useTotem";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { BoxContainer } from "../../components";
import useNotifier from "../../hooks/useNotifier";
import { prioritiesIcon } from "../../config/config";
import TableFilter from "../../components/Table/TableFilter";
import { useCallSystem } from "../../hooks";

function PatientUnregistered() {
  const permissions = usePermissions("/attendance/patientUnregistered");
  const navigate = useNavigate();
  const { getTokens, confirmToken } = useTotem();
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [filters, setFilters] = React.useState({});
  const [count, setCount] = React.useState(0);
  const [keys, setKeys] = React.useState(0);
  const notify = useNotifier();
  const handleCallSystem = useCallSystem("pre-attendance");
  const { cancelToken } = useTotem();
  const tokenMutation = useMutation(cancelToken);
  const confirmTokenMutation = useMutation(confirmToken);

  const backgroundColors = {
    Vermelho: "#ef4444",
    Verde: "#22c55e",
    Azul: "#3b82f6",
    Amarelo: "#facc15",
    null: "#d1d5db",
  };

  const keyQuery = useQuery(
    ["keys", [page, limit, filters]],
    () => getTokens({ page, limit, family_id: null, ...filters }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setKeys(response.items);
        setCount(response.totalItems);
        const formatQuery = response.items.map((values) => ({
          Data: format(parseISO(values.createdAt), "HH:mm"),
          token_describe: values?.token_describe,
          calls_count: values.calls_count,
          keys: (
            <Tooltip title={values?.priority?.describe} placement="right-start">
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  maxWidth: 110,
                }}
              >
                <Typography>{values.token_describe}</Typography>
                {prioritiesIcon[values?.priority?.describe.toLowerCase()]}
              </Box>
            </Tooltip>
          ),
          codePatient: values?.patient?.number || "Não Identificado",
          namePatient: (
            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Typography>
                {values?.patient?.name || "Não Identificado"}
              </Typography>
              {values?.risk_classification && (
                <Tooltip title={`Senha ${values.token_describe} Classificada.`}>
                  <Box
                    sx={{
                      padding: "10px",
                      borderRadius: "100%",
                      background:
                        backgroundColors[
                          values?.risk_classification?.classification || "null"
                        ],
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          ),
          attendance: values?.attendance?.number || "Não Gerado", //inserir no controller
          hasName: values?.patient?.name ? true : false,
        }));
        setRows(formatQuery);
      },
    }
  );

  const tableColumns = [
    { name: "Hora", field: "Data" },
    { name: "Senha", field: "keys" },
    { name: "Código do Paciente", field: "codePatient" },
    { name: "Paciente", field: "namePatient" },
    { name: "Atendimento", field: "attendance" },
  ];

  const othersFilters = [
    {
      name: "Finalizados",
      type: "boolean",
      filter: "confirmed",
    },
  ];
  async function tokenCancel(index) {
    await tokenMutation.mutate(keyQuery.data?.items?.[index]?.id, {
      onError: (err) => {
        notify(err.message, "error");
      },
      onSuccess: () => {
        keyQuery.refetch();
      },
    });
  }

  const handleFilter = React.useCallback(
    (filter) => {
      setFilters(filter);
    },
    [filters]
  );

  return (
    <VerifyPermission valid={!!permissions} redirect={false}>
      <BoxContainer title="Recepção">
        <Button
          variant="contained"
          sx={{
            marginBottom: "10px",
          }}
          disabled={keyQuery.isLoading}
          onClick={() => {
            notify("Lista Atualizada");
            keyQuery.refetch();
          }}
        >
          Atualizar
        </Button>
        <Grid container xs={12}>
          <TableFilter
            data={rows}
            columns={tableColumns}
            othersFilter={othersFilters}
            handleFilter={handleFilter}
            confirmMessage={`Você realmente deseja *action* este pré-cadastro ( {row.token_describe} )?`}
            actions
            actionsTypes={["call", "response", "finish", "delete"]}
            callsCountField={"calls_count"}
            disableActions={(row, action) => {
              if (action === "response" && !row.hasName) {
                return false;
              }

              if (action === "response" && row.hasName) {
                return true;
              }

              if (action === "call" && row.hasName) {
                return false;
              }
              if (action === "delete" && permissions.delete) {
                return false;
              }
            }}
            actionHandleResponse={(i) => {
              setKeys(keys[i]);
              navigate(`/attendance/patient`, {
                state: {
                  token_audit_id: keys[i].id,
                },
              });
            }}
            actionHandleCall={(i) => {
              handleCallSystem(keys[i].id);
            }}
            actionHandleFinish={(i) => {
              confirmTokenMutation.mutate(keys[i].id, {
                onSuccess: () => {
                  notify("Operação realizada com sucesso!", "success");
                  keyQuery.refetch();
                },
              });
            }}
            actionHandleDelete={tokenCancel}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <TablePagination
              count={count}
              component="div"
              page={page}
              onPageChange={(_, newPage) => {
                setPage(newPage);
              }}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 20, 75]}
              onRowsPerPageChange={({ target }) => {
                setLimit(parseInt(target.value, 10));
                setPage(0);
              }}
            />
          </Box>
        </Grid>
      </BoxContainer>
    </VerifyPermission>
  );
}

export default PatientUnregistered;
