import instance from "../config/api";


const DPath = 'double-check'
export default function useDoubleCheck(){
    async function getDoubleChecks(params){
        try {
            const result = await instance.get(DPath,{params})
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function postDoubleCheck(data){
        try {
            const result = await instance.post(DPath,data)
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function patchDoubleCheck({id,data}){
        try {
            const result = await instance.patch(`${DPath}/${id}`,data)
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function deleteDoubleCheck(id){
        try {
            const result = await instance.delete(`${DPath}/${id}`)
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    return {
        getDoubleChecks,
        postDoubleCheck,
        patchDoubleCheck,
        deleteDoubleCheck
    }
}