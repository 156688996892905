import { useCallback } from "react";
import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "microareas";

function useMedicalRecords() {
  const postMicroArea = useCallback(async (data) => {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const getMicroArea = useCallback(async (params) => {
    try {
      const response = await instance.get(path, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const patchMicroArea = useCallback(async ({ id, data }) => {
    try {
      const response = await instance.patch(`${path}/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  return {
    postMicroArea,
    getMicroArea,
    patchMicroArea,
  };
}
export default useMedicalRecords;
