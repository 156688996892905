import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

const path = "/roles/";
function useRoles() {
  async function getRoles(params = { limit: 5, page: 0 }) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });

      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postRole(params) {
    try {
      const response = await instance.post(path, params);

      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updateRole(params) {
    try {
      const response = await instance.put(path + params.id, params);

      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getRoleScreens(roleID, params = { limit: 5, page: 0 }) {
    try {
      const response = await instance.get(path + roleID + "/screens", {
        params: urlQuery(params),
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function postRoleScreens({ roleID, insertion }) {
    try {
      const response = await instance.post(
        path + roleID + "/screens",
        insertion
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function deleteRoleScreens({ roleID, deletion }) {
    try {
      const response = await instance.delete(
        path + roleID + "/screens/" + deletion
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getRoleEmployeeTypes(roleID, params = { limit: 5, page: 0 }) {
    try {
      const response = await instance.get(path + roleID + "/employees/types", {
        params: urlQuery(params),
      });

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function postRoleEmployeeTypes({ roleID, insertion }) {
    try {
      const response = await instance.post(
        path + roleID + "/employees/types",
        insertion
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function deleteRoleEmployeeType({ roleID, deletion }) {
    try {
      const response = await instance.delete(
        path + roleID + "/employees/types/" + deletion
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function postRoleEmployee({ roleID, insertion }) {
    try {
      const response = await instance.post(
        path + roleID + "/employees",
        insertion
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function deleteRoleEmployee({ roleID, deletion }) {
    try {
      const response = await instance.delete(
        path + roleID + "/employees/" + deletion
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  return {
    getRoles,
    postRole,
    updateRole,
    getRoleScreens,
    postRoleScreens,
    getRoleEmployeeTypes,
    postRoleEmployeeTypes,
    postRoleEmployee,
    deleteRoleEmployee,
    deleteRoleScreens,
    deleteRoleEmployeeType,
  };
}

export default useRoles;
