import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { decode } from "html-entities";
import { useDocuments } from "../../../service";
import { FormMakerContext } from "../../../contexts/FormMakerContext";
import Yup from "../../../config/yup";
import { useDebounceCallback, useNotifier } from "../../../hooks/";
import { cleanUpMask, convertStringMaskArray } from "../../../config/mask";
import { accessObjectByString } from "../../../utils/";
import { CardMedia, Grid, Typography } from "@mui/material";
import { theme } from "../../../config/theme";
import {
  AutocompleteField,
  Button,
  CheckboxField,
  DateField,
  RadioField,
  SliderField,
  SwitchField,
  TextField,
} from "../../FormFields";
import useSignaturePassword from "../../../hooks/useSignaturePassword";
import { useParams } from "react-router-dom";
import { ConfirmDialog, Loading } from "../../../helper";
import axios from "axios";

const regex = /(<([^>]+)>)/gi;

const fieldTypes = {
  label: ({ field }) => {
    let variant = "";

    if (field.styles.fontSize <= 18) {
      variant = "h4";
    } else if (field.styles.fontSize > 18 && field.styles.fontSize <= 24) {
      variant = "h3";
    } else if (field.styles.fontSize > 24) {
      variant = "h2";
    }

    return (
      <Grid item xs={field.grid}>
        <Typography
          variant={field.variant}
          sx={{
            fontSize: variant === "h2" ? 24 : variant === "h3" ? 32 : 16,
            color: theme.palette.primary.light,
            fontWeight: 500,
          }}
        >
          {field.label}
        </Typography>
      </Grid>
    );
  },
  text: ({ control, field, handleRules, handleInputActions }) => (
    <Grid item xs={field.grid}>
      <TextField
        control={control}
        name={field._id}
        triggerMaskOnlyOnBlur={field.masks?.length > 1}
        label={field.label}
        mask={convertStringMaskArray(field.masks)}
        placeholder={field.placeholder}
        required={field.required}
        disabled={field.disabled}
        customOnChange={(value) => {
          handleRules(field, value);
          handleInputActions(field, "on-change");
        }}
        customOnBlur={() => handleInputActions(field, "on-blur")}
      />
    </Grid>
  ),
  textarea: ({ control, field, handleRules, handleInputActions }) => (
    <Grid item xs={field.grid}>
      <TextField
        control={control}
        triggerMaskOnlyOnBlur={field?.masks?.length > 1}
        mask={convertStringMaskArray(field.masks)}
        name={field._id}
        label={field.label}
        placeholder={field.placeholder}
        required={field.required}
        disabled={field.disabled}
        multiline
        minRows={5}
        maxRows={5}
        customOnChange={(value) => {
          handleRules(field, value);
          handleInputActions(field, "on-change");
        }}
        customOnBlur={() => handleInputActions(field, "on-blur")}
      />
    </Grid>
  ),
  select: ({ control, field, handleRules, handleInputActions }) => {
    return (
      <Grid item xs={field.grid}>
        <AutocompleteField
          control={control}
          name={field._id}
          label={field.label}
          placeholder={field.placeholder}
          optionCompareKey="value"
          optionKeyExtractor="label"
          required={field.required}
          disabled={field.disabled}
          options={field.options}
          customOnChange={(item) => {
            handleRules(field, item.value);
            handleInputActions(field, "on-change");
          }}
          onBlur={() => handleInputActions(field, "on-blur")}
          containerProps={{
            marginBottom: 12,
          }}
        />
      </Grid>
    );
  },
  number: ({ control, field, handleRules, handleInputActions }) => (
    <Grid item xs={field.grid}>
      <TextField
        control={control}
        name={field._id}
        type="number"
        mask={convertStringMaskArray(field.masks)}
        triggerMaskOnlyOnBlur={field.masks?.length > 1}
        label={field.label}
        placeholder={field.placeholder}
        required={field.required}
        disabled={field.disabled}
        customOnChange={(value) => {
          handleRules(field, value);
          handleInputActions(field, "on-change");
        }}
        customOnBlur={() => handleInputActions(field, "on-blur")}
      />
    </Grid>
  ),
  radio: ({ control, field, handleRules, handleInputActions }) => (
    <Grid item xs={field.grid}>
      <RadioField
        name={field._id}
        control={control}
        label={field.label}
        options={field.options}
        optionCompareKey="value"
        optionValueKey="value"
        optionKeyExtractor="label"
        orientation={field.orientation}
        required={field.required}
        disabled={field.disabled}
        customOnChange={(value) => {
          handleRules(field, value);
          handleInputActions(field, "on-change");
        }}
        onBlur={() => handleInputActions(field, "on-blur")}
      />
    </Grid>
  ),
  checkbox: ({ control, field, handleRules, handleInputActions }) => (
    <Grid item xs={field.grid}>
      <CheckboxField
        label={field.label}
        control={control}
        name={field._id}
        options={field.options}
        optionValueKey="value"
        optionKeyExtractor="label"
        orientation={field.orientation}
        required={field.required}
        disabled={field.disabled}
        customOnChange={(value) => {
          handleRules(field, value);
          handleInputActions(field, "on-change");
        }}
        onBlur={() => handleInputActions(field, "on-blur")}
      />
    </Grid>
  ),
  yesOrNot: ({ control, field, handleRules, handleInputActions }) => (
    <Grid item xs={field.grid}>
      <SwitchField
        control={control}
        name={field._id}
        label={field.label}
        required={field.required}
        disabled={field.disabled}
        customOnChange={(value) => {
          handleRules(field, value);
          handleInputActions(field, "on-change");
        }}
        onBlur={() => handleInputActions(field, "on-blur")}
      />
    </Grid>
  ),
  date: ({ control, field, handleRules, handleInputActions }) => (
    <Grid item xs={field.grid}>
      <DateField
        control={control}
        name={field._id}
        label={field.label}
        required={field.required}
        disabled={field.disabled}
        customOnChange={(value) => {
          handleRules(field, value);
          handleInputActions(field, "on-change");
        }}
        onBlur={() => handleInputActions(field, "on-blur")}
      />
    </Grid>
  ),
  range: ({ control, field, handleRules, handleInputActions }) => (
    <Grid item xs={field.grid}>
      <SliderField
        control={control}
        label={field.label}
        name={field._id}
        step={field.steps}
        min={field.min}
        max={field.max}
        required={field.required}
        disabled={field.disabled}
        marks
        customOnChange={(value) => {
          handleRules(field, value);
          handleInputActions(field, "on-change");
        }}
        onBlur={() => handleInputActions(field, "on-blur")}
      />
    </Grid>
  ),
  image: ({ field }) => {
    return (
      <Grid item xs={field.grid}>
        <CardMedia
          component="img"
          image={field.src}
          alt={field.label}
          sx={{
            width: "100%",
            maxWidth: field.size.width,
            height: field.size.height,
          }}
        />
      </Grid>
    );
  },
  default: null,
};

function RenderFormMaker({
  type = "register",
  patientId,
  refetch = true,
  addvaluesToSubmit,
  handleClose,
  onSuccess,
  onError,
}) {
  const notify = useNotifier();
  const validateSignature = useSignaturePassword();
  const { id } = useParams();
  const [confirm, setConfirm] = useState(false);
  const [submitType, setSubmitType] = useState(type);
  const [submiting, setSubmiting] = useState(false);
  const status = useRef(null);
  const firstRender = useRef(true);
  const firstSubmit = useRef(false);

  const {
    document,
    setDocument,
    recordDocument,
    setRecordDocument,
    submitFormatter,
    formConfig,
    setRecord,
    setFormConfig,
  } = useContext(FormMakerContext);
  const { control, reset, handleSubmit, trigger, getValues, setValue } =
    useForm({
      defaultValues: {},
      mode: "onBlur",
      resolver: yupResolver(Yup.object().shape(formConfig.validations)),
    });

  const queryClient = useQueryClient();
  const { postRecords, patchRecords } = useDocuments();
  const postRecordsMutation = useMutation(postRecords);
  const patchRecordsMutation = useMutation(patchRecords);

  const submitDocument = async (values) => {
    if (submitType === "edit") {
      let recordData = {
        ...recordDocument,
        fields: submitFormatter(values, document.versions[0].fields),
        status: status.current,
      };

      if (addvaluesToSubmit instanceof Function) {
        const additionalValues = await addvaluesToSubmit(values, recordData);
        recordData = {
          ...recordData,
          ...additionalValues,
        };
      }

      validateSignature(
        () => {
          patchRecordsMutation.mutate(
            {
              patientId: patientId ?? id,
              documentRecordId: recordDocument.id,
              data: recordData,
            },
            {
              onSuccess: (response) => {
                notify(response.message, "success");

                if (refetch) queryClient.invalidateQueries("records");

                if (
                  (type === "register" || status.current === "finished") &&
                  handleClose instanceof Function
                ) {
                  handleClose();
                }

                if (onSuccess instanceof Function) {
                  onSuccess();
                }
              },
              onError: (error) => {
                notify(error.message, "error");

                if (onError instanceof Function) {
                  onError();
                }
              },
              onSettled() {
                status.current = null;
                setSubmiting(false);
              },
            }
          );
        },
        false,
        () => {
          status.current = null;
          setSubmiting(false);
        }
      );
    } else if (submitType === "register") {
      let additionalValues = {};

      if (addvaluesToSubmit instanceof Function) {
        additionalValues = await addvaluesToSubmit(values, {});
      }

      const recordData = {
        document_id: document._id,
        version_id: document.versions[0]._id,
        version: document.versions[0].number,
        status: "filling",
        ...additionalValues,
      };

      postRecordsMutation.mutate(
        { patientId: patientId ?? id, data: recordData },
        {
          onSuccess: (response) => {
            if (refetch) queryClient.invalidateQueries("records");

            setSubmitType("edit");
            setRecordDocument(response);
            setRecord(response.fields, document);
            firstSubmit.current = true;
          },
          onError: (error) => {
            notify(error.message, "error");

            if (handleClose instanceof Function) {
              handleClose();
            }
          },
        }
      );
    }
  };

  function handleRules(field, fieldValue) {
    try {
      const documentStructure = { ...document };
      const formStructureConfig = { ...formConfig };

      const filteredRules = documentStructure.versions[0].rules?.filter(
        ({ origin_field }) => origin_field === field._id
      );

      if (!filteredRules.length) return;

      filteredRules.forEach((rule) => {
        const { type, condition, destiny_field, value } = rule;

        const ruleType = {
          enable(clear) {
            let run = true;
            const newFields = [...documentStructure.versions[0].fields];
            let matchs = 0;
            const hasMoreFields = Array.isArray(destiny_field);

            for (let index = 0; run; index++) {
              const macthField = hasMoreFields
                ? destiny_field.includes(newFields[index]._id)
                : newFields[index]._id === destiny_field;

              if (macthField) {
                matchs++;

                if (!hasMoreFields) {
                  run = false;
                } else if (matchs === destiny_field.length) {
                  run = false;
                }

                if (clear) {
                  if (newFields[index].disabled) return;
                  newFields[index].disabled = true;
                  setValue(newFields[index]._id, null);
                } else {
                  if (!newFields[index].disabled) return;
                  newFields[index].disabled = false;
                }
              }
            }

            documentStructure.versions[0].fields = newFields;
          },
          disable(clear) {
            let run = true;
            const newFields = [...documentStructure.versions[0].fields];
            let matchs = 0;
            const hasMoreFields = Array.isArray(destiny_field);

            for (let index = 0; run; index++) {
              const macthField = hasMoreFields
                ? destiny_field.includes(newFields[index]._id)
                : newFields[index]._id === destiny_field;

              if (macthField) {
                matchs++;

                if (!hasMoreFields) {
                  run = false;
                } else if (matchs === destiny_field.length) {
                  run = false;
                }

                if (clear) {
                  if (!newFields[index].disabled) return;
                  newFields[index].disabled = false;
                } else {
                  if (newFields[index].disabled) return;
                  newFields[index].disabled = true;
                  setValue(newFields[index]._id, null);
                }
              }
            }

            documentStructure.versions[0].fields = newFields;
          },
          require(clear) {
            let run = true;
            const newFields = [...documentStructure.versions[0].fields];
            const newValidations = formStructureConfig.validations;
            let matchs = 0;
            const hasMoreFields = Array.isArray(destiny_field);

            for (let index = 0; run; index++) {
              const macthField = hasMoreFields
                ? destiny_field.includes(newFields[index]._id)
                : newFields[index]._id === destiny_field;

              if (macthField) {
                matchs++;

                if (!hasMoreFields) {
                  run = false;
                } else if (matchs === destiny_field.length) {
                  run = false;
                }

                if (clear) {
                  if (!newFields[index].required) return;

                  newFields[index].required = false;
                  const newTests = newValidations[
                    newFields[index]._id
                  ].tests?.filter((test) => test.OPTIONS.name !== "required");
                  newValidations[newFields[index]._id].tests = newTests;
                } else {
                  if (newFields[index].required) return;

                  newFields[index].required = true;
                  newValidations[newFields[index]._id] =
                    newValidations[newFields[index]._id].required(
                      "É requerido"
                    );
                }
              }
            }

            documentStructure.versions[0].fields = newFields;
            formStructureConfig.validations = newValidations;
          },
        };

        fieldValue =
          fieldValue instanceof Object && !Array.isArray(fieldValue)
            ? fieldValue.value
            : typeof fieldValue === "boolean"
            ? fieldValue.toString()
            : fieldValue;
        const fieldValueIsArray = Array.isArray(fieldValue);
        const valueIsArray = Array.isArray(value);
        let result = false;

        const ruleConditions = {
          "=": () => {
            if (["", null].includes(value)) {
              result =
                fieldValue?.length === 0 ||
                [undefined, null, ""].includes(fieldValue);
            } else if (fieldValueIsArray && valueIsArray) {
              result = fieldValue?.some((fieldValue) =>
                value.includes(fieldValue)
              );
            } else if (fieldValueIsArray) {
              result = fieldValue?.some((fieldValue) => fieldValue == value);
            } else if (valueIsArray) {
              result = value.includes(fieldValue);
            } else {
              result = fieldValue == value;
            }

            if (result) {
              ruleType[type]();
            } else {
              ruleType[type](true);
            }
          },
          "≠": () => {
            if (["", null].includes(value)) {
              result = !(
                fieldValue?.length === 0 ||
                [undefined, null, ""].includes(fieldValue)
              );
            } else if (fieldValueIsArray && valueIsArray) {
              result = fieldValue.every(
                (fieldValue) => !value.includes(fieldValue)
              );
            } else if (fieldValueIsArray) {
              result = fieldValue?.some((fieldValue) => fieldValue != value);
            } else if (valueIsArray) {
              result = !value.includes(fieldValue);
            } else {
              result = fieldValue != value;
            }

            if (result) {
              ruleType[type]();
            } else {
              ruleType[type](true);
            }
          },
          ">": () => {
            if (fieldValueIsArray && valueIsArray) {
              result = fieldValue?.some((fieldValue) =>
                value.some((value) => fieldValue > value)
              );
            } else if (fieldValueIsArray) {
              result = fieldValue?.some((fieldValue) => fieldValue > value);
            } else if (valueIsArray) {
              result = value.some((value) => fieldValue > value);
            } else {
              result = fieldValue > value;
            }

            if (result) {
              ruleType[type]();
            } else {
              ruleType[type](true);
            }
          },
          "≥": () => {
            if (fieldValueIsArray && valueIsArray) {
              result = fieldValue?.some((fieldValue) =>
                value.some((value) => fieldValue >= value)
              );
            } else if (fieldValueIsArray) {
              result = fieldValue?.some((fieldValue) => fieldValue >= value);
            } else if (valueIsArray) {
              result = value.some((value) => fieldValue >= value);
            } else {
              result = fieldValue >= value;
            }

            if (result) {
              ruleType[type]();
            } else {
              ruleType[type](true);
            }
          },
          "<": () => {
            if (fieldValueIsArray && valueIsArray) {
              result = fieldValue?.some((fieldValue) =>
                value.some((value) => fieldValue < value)
              );
            } else if (fieldValueIsArray) {
              result = fieldValue?.some((fieldValue) => fieldValue < value);
            } else if (valueIsArray) {
              result = value.some((value) => fieldValue < value);
            } else {
              result = fieldValue < value;
            }

            if (result) {
              ruleType[type]();
            } else {
              ruleType[type](true);
            }
          },
          "≤": () => {
            if (fieldValueIsArray && valueIsArray) {
              result = fieldValue?.some((fieldValue) =>
                value.some((value) => fieldValue <= value)
              );
            } else if (fieldValueIsArray) {
              result = fieldValue?.some((fieldValue) => fieldValue <= value);
            } else if (valueIsArray) {
              result = value.some((value) => fieldValue <= value);
            } else {
              result = fieldValue <= value;
            }

            if (result) {
              ruleType[type]();
            } else {
              ruleType[type](true);
            }
          },
        };

        ruleConditions[condition]();
      });

      setFormConfig((formConfig) => ({
        ...formConfig,
        validations: formStructureConfig.validations,
      }));
      setDocument(documentStructure, false);
    } catch (err) {
      console.log(err);
    }
  }

  const handleInputActions = useCallback(
    useDebounceCallback(async (field, action) => {
      if (field.action && field.action.trigger === action) {
        try {
          if (field.action.delay) {
            await new Promise((resolve) => {
              setTimeout(resolve, field.action.delay);
            });
          }

          const actionUrl = field.action.api.request.url;
          const matchedParams = actionUrl.match(/{{.+?}}/g);

          const url = matchedParams?.reduce((url, param) => {
            const cleanedParam = param
              .replace(/[{{}}]/g, "")
              .replace("external_data.", "");
            const [tag, attributes] = cleanedParam.split(/\.(.+)/);
            const field = document.versions[0].fields.find(
              (field) => field.tag === tag
            );
            let value = getValues(field._id);

            if (attributes) {
              value = accessObjectByString(value, attributes);
            }

            value = cleanUpMask(value);

            return url.replace(param, value);
          }, actionUrl);

          const { data } = await axios.get(url);

          const fieldValues = Object.entries(field.action.from_to)?.reduce(
            (fieldValues, [requestKey, fieldId]) => {
              fieldValues[fieldId] = data[requestKey];
              return fieldValues;
            },
            {
              [field._id]: getValues(field._id),
            }
          );

          reset(fieldValues, { keepDefaultValues: true });
        } catch {
          notify(
            `Não foi possível prencher os dados integrados a partir do campo "${field.label}".`,
            "error"
          );
        }
      }
    }),
    []
  );

  useEffect(() => {
    if (type === "register") {
      submitDocument();
    }
  }, []);

  useEffect(() => {
    if (firstRender.current || firstSubmit.current) {
      firstRender.current = false;

      reset(formConfig.defaultValues);

      if (firstSubmit.current || type === "edit") {
        document?.versions[0]?.fields.forEach((field) => {
          handleRules(field, formConfig.defaultValues[field._id]);
        });
      }

      if (firstSubmit.current) firstSubmit.current = false;
    }
  }, [formConfig.defaultValues]);

  const formattedFields = useMemo(() => {
    let lastYAxis = 0;
    let totalWidth = 0;
    let lastIndexes = [];
    const fields = [];

    document?.versions[0]?.fields.forEach((field, index, originalFields) => {
      field.label = decode(field.label.replace(regex, ""));
      fields.push(field);

      const lastInterate = index === originalFields.length - 1;
      const breakLine = lastYAxis < field.position.y;

      if (breakLine || lastInterate) {
        if (lastInterate && breakLine) {
          fields[index].grid = 12;
        } else if (lastInterate) {
          lastIndexes.push(index);
          totalWidth += field.size.width;
        }

        lastIndexes.forEach((index) => {
          const gridValue =
            (originalFields[index].size.width / totalWidth) * 12;
          fields[index].grid = gridValue;
        });

        lastYAxis = field.position.y;
        totalWidth = field.size.width;
        lastIndexes = [index];
      } else {
        lastIndexes.push(index);
        totalWidth += field.size.width;
      }
    });

    return fields;
  }, [document]);

  if (postRecordsMutation.isLoading) return <Loading />;

  return (
    <>
      <ConfirmDialog
        message="Você realmente deseja *finalizar* esse documento?"
        hideBackDrop={false}
        open={confirm}
        maxWidth="sm"
        fullWidth={true}
        handleClose={() => {
          setConfirm(false);
        }}
        actions
        handleConfirm={() => {
          status.current = "finished";
          setSubmiting(true);
          const values = getValues();
          submitDocument(values);
        }}
        handleCancel={() => {
          status.current = null;
          setConfirm(false);
        }}
      />
      <Grid component="form" container spacing={3} marginTop="0.5rem">
        {formattedFields.map((field) => {
          const Component = fieldTypes[field.type];

          return Component ? (
            <Component
              key={field._id}
              control={control}
              field={field}
              handleRules={handleRules}
              handleInputActions={handleInputActions}
            />
          ) : (
            fieldTypes.default
          );
        })}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
            marginTop: 8,
          }}
        >
          <Button
            loading={submiting && status.current === "filling"}
            disabled={status.current === "finished"}
            onClick={() => {
              status.current = "filling";
              setSubmiting(true);

              handleSubmit(submitDocument, () => {
                setSubmiting(false);
                status.current = null;

                notify(
                  "Alguns Campos não foram preenchidos corretamente!",
                  "error"
                );
              })();
            }}
          >
            Salvar
          </Button>
          <Button
            loading={submiting && status.current === "finished"}
            disabled={status.current === "filling"}
            onClick={async () => {
              const isFormValid = await trigger();

              if (isFormValid) {
                setConfirm(true);
              } else {
                notify(
                  "Alguns Campos não foram preenchidos corretamente!",
                  "error"
                );
              }
            }}
            variant="outlined"
          >
            Finalizar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default RenderFormMaker;
