import {
  Box,
  Button,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import SoapContainer from "../../../components/SoapContainer/SoapContainer";
import TriageHistory from "./TriageHistory";
import useTriage from "../../../service/useTriage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useAttendaces, usePatient, useProcedures } from "../../../service";
import { Loading } from "../../../helper";
import { useContext, useState } from "react";
import { AppContext } from "../../../contexts/AppContext";
import useNotifier from "../../../hooks/useNotifier";
import { verifyAttendanceFlow } from "../../../utils";
import {
  CheckboxField,
  PaginatedAutocompleteField,
  RadioField,
  TextField,
} from "../../../components/FormFields";
import { useForm } from "react-hook-form";

export default function Triage() {
  const location = useLocation();
  const [isCeo, setIsCeo] = useState(location.state?.isCeo);
  const initialValues = isCeo
    ? { orientation: "" }
    : {
        activity: "",
        practices: [],
        // target_audience: [],
        caries_risk: null,
        periodontal_risk: null,
        tissue_risk: null,
        oclusal_risk: null,
        braces_risks: [],
        procedures: [],
        orientation: "",
      };

  const { handleSubmit, control, watch } = useForm({
    defaultValues: initialValues,
  });

  const [activityField] = watch(["activity"]);
  const activityOptions = [
    {
      label: "Educação em saúde",
    },
    {
      label: "Atendimento em grupo",
    },
    {
      label: "Avaliação / Procedimento Coletivo",
    },
    {
      label: "Mobilização social",
    },
  ];
  const healthPractices = [
    {
      label: "HAS / DIA",
    },
    {
      label: "Saúde bucal",
    },
    {
      label: "Aplicação tópica de flúor",
    },
    {
      label: "Escovação dental supervisionada",
    },
  ];

  const targetAudience = [
    "criança de 0 a 3 anos",
    "criança de 4 a 5 anos",
    "criança de 6 a 11 anos",
    "adolescentes",
    "gestante",
    "idoso",
    "tabaco",
    "usuário de álcool",
    "Outros",
  ];

  const { getRisks, postTriage } = useTriage();
  const { getPatient } = usePatient();
  const { getAttendanceById } = useAttendaces();
  const { getProcedures } = useProcedures();
  const { patientId, attendanceId } = useParams();
  const { setPatientData, patientData } = useContext(AppContext);
  const notify = useNotifier();
  const navigate = useNavigate();
  const triageMutation = useMutation({
    mutationFn: postTriage,
    mutationKey: "postTriage",
  });
  useQuery("getPatient", () => getPatient(patientId), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess(data) {
      setPatientData(data);
    },
  });

  const attendanceQuery = useQuery(
    "getAttendance",
    () => getAttendanceById(attendanceId),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setIsCeo(data?.service?.ceo || location.state?.isCeo);
      },
    }
  );

  function goBack() {
    navigate("/attendance/initial", {
      state: {
        menuServiceOpen: location.state.menuServiceOpen,
        use: attendanceId,
      },
      replace: true,
    });
  }

  const handleOnSubmit = handleSubmit(
    ({
      orientation,
      activity,
      braces_risks,
      caries_risk,
      oclusal_risk,
      periodontal_risk,
      practices,
      procedures,
      tissue_risk,
    }) => {
      const toSend = {
        orientation,
        procedures: procedures?.length > 0 ? procedures : null,
        activity,
        practices: practices?.length ? practices : null,
        braces_risks: braces_risks?.length ? braces_risks : null,
        caries_risk_id: caries_risk?.id,
        oclusal_risk_id: oclusal_risk?.id,
        periodontal_risk_id: periodontal_risk?.id,
        tissue_risk_id: tissue_risk?.id,
        attendance_id: attendanceId,
      };

      triageMutation.mutate(toSend, {
        onSuccess(data) {
          notify(data.message, "success");
          goBack();
        },
        onError(data) {
          notify(data.message, "error");
        },
      });
    }
  );

  return (
    <SoapContainer
      title={"Triagem - Odonto"}
      classificationHistory={false}
      programHistory={false}
      patient={patientData}
      history={<TriageHistory patientID={patientId} />}
    >
      {triageMutation.isLoading ? (
        <Loading maxWidth={"100%"} backgroundColor={"rgba(255,255,255,0.7)"} />
      ) : null}

      <Box component={"form"} onSubmit={handleOnSubmit}>
        <Grid container item xs={12} spacing={3} key={"form"}>
          <>
            <Grid item xs={12} key={"caries_risk"}>
              <PaginatedAutocompleteField
                control={control}
                service={() => getRisks({ type: "caries" })}
                name="caries_risk"
                autoCompleteProps={{
                  getOptionLabel: (data) => {
                    return data.identifier + ` ${data.description}`;
                  },
                }}
                queryKey={"caries-risk"}
                optionLabelKey="description"
                label="Risco de Cárie"
              />
            </Grid>
            <Grid item xs={12} key={"periodontal_risk"}>
              <PaginatedAutocompleteField
                control={control}
                service={() => getRisks({ type: "periodontal" })}
                name="periodontal_risk"
                autoCompleteProps={{
                  getOptionLabel: (data) => {
                    return data.identifier + ` ${data.description}`;
                  },
                }}
                queryKey={"periodontal-risk"}
                optionLabelKey="description"
                label="Risco de Periodontal"
              />
            </Grid>
            <Grid item xs={12} key={"tissue_risk"}>
              <PaginatedAutocompleteField
                control={control}
                service={() => getRisks({ type: "tissue" })}
                name="tissue_risk"
                autoCompleteProps={{
                  getOptionLabel: (data) => {
                    return data.identifier + ` ${data.description}`;
                  },
                }}
                queryKey={"tissue-risk"}
                optionLabelKey="description"
                label="Risco de Tecidos Moles"
              />
            </Grid>
            <Grid item xs={12} key={"oclusal_risk"}>
              <PaginatedAutocompleteField
                control={control}
                service={() => getRisks({ type: "oclusal" })}
                name="oclusal_risk"
                autoCompleteProps={{
                  getOptionLabel: (data) => {
                    return data.identifier + ` ${data.description}`;
                  },
                }}
                queryKey={"oclusal-risk"}
                optionLabelKey="description"
                label="Risco de Oclusal"
              />
            </Grid>
            <Grid item xs={12} key={"braces_risks"}>
              <PaginatedAutocompleteField
                control={control}
                service={() => getRisks({ type: "braces" })}
                name="braces_risks"
                autoCompleteProps={{
                  getOptionLabel: (data) => {
                    return data.identifier + ` ${data.description}`;
                  },
                }}
                multiple
                queryKey={"braces-risk"}
                optionLabelKey="description"
                optionCompareKey={"id"}
                label="Risco de Prótese"
              />
            </Grid>
            <Grid item xs={12} key={"divider"}>
              <Divider />
            </Grid>
            <Grid item xs={12} key={"activity"}>
              <Box>
                <RadioField
                  control={control}
                  name="activity"
                  label="Atividade:"
                  options={activityOptions}
                  optionLabelKey="label"
                  optionValueKey={"label"}
                  optionCompareKey={"label"}
                  row
                />
              </Box>
              <Box>
                <PaginatedAutocompleteField
                  control={control}
                  name={"procedures"}
                  label={"Código SIGTAP"}
                  multiple
                  service={() =>
                    getProcedures({
                      type: "triage",
                      patient_id: patientData?.id,
                      attendance_flow: verifyAttendanceFlow(
                        attendanceQuery.data?.risk_classification_id
                      ),
                    })
                  }
                  optionCompareKey={"id"}
                  queryKey={"procedures-triage"}
                  filterKey="filters"
                  disabled={
                    activityField !== "Avaliação / Procedimento Coletivo"
                  }
                  autoCompleteProps={{
                    getOptionLabel: (data) => {
                      let label = data?.name;

                      if (data?.tuss_code) {
                        label = `${data?.tuss_code} - ${label}`;
                      }

                      return label;
                    },
                  }}
                  optionLabelKey="description"
                />
              </Box>
            </Grid>
            {/* <Grid item xs={12} key={"target_audience"}>
                    <Grid container item xs={12} spacing={1}>
                      <Grid item xs={12}>
                        <SubTitle text="Público alvo:" sx={{ mb: 2 }} />
                      </Grid>
                      {targetAudience.map((data, index) => {
                        return (
                          <Grid key={data.value} item xs={2}>
                            <FormCheckbox
                              name="target_audience"
                              label={data.firstCharToUp()}
                              value={data}
                              key={index}
                              multiple
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid> */}
            <Grid item xs={12} key={"practices"}>
              <CheckboxField
                control={control}
                name="practices"
                label="Práticas em Saúde:"
                options={healthPractices}
                optionLabelKey="label"
                optionValueKey={"label"}
                optionCompareKey={"label"}
                row
              />
            </Grid>
          </>
          <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <CardHeader
              sx={{ textAlign: `left` }}
              title={
                <Typography
                  variant="h7"
                  color="primary"
                  fontWeight="500"
                  textAlign="center"
                  fontSize={30}
                >
                  <Typography
                    component="span"
                    color="secondary"
                    fontWeight="500"
                    fontSize={30}
                  >
                    T
                  </Typography>
                  riagem - CEO
                </Typography>
              }
            />
            <Divider
              variant="middle"
              sx={{ marginTop: "-10px", backgroundColor: "#D3E3ED" }}
            />
          </Box>
          <Grid item xs={12}>
            <TextField
              control={control}
              name={"orientation"}
              label={"AVALIAÇÃO/ORIENTAÇÃO"}
              multiline
              minRows={6}
              maxRows={6}
            />
          </Grid>

          <Grid item xs={12} display={"flex"} columnGap={3} key={"buttons"}>
            <Button type="submit" variant="contained">
              Finalizar
            </Button>
            <Button onClick={() => goBack()} variant="text">
              Voltar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </SoapContainer>
  );
}
