import React from "react";
import TableFilter from "../../../components/Table/TableFilter";
import { DialogMedium } from "../../../helper";
import RegulationFormModal from "./RegulationFormModal";
import { differenceInDays, format, parseISO } from "date-fns";
import { useQuery } from "react-query";
import { useRegulation } from "../../../service";
import { Box, TablePagination } from "@mui/material";
import useNotifier from "../../../hooks/useNotifier";
import limitName from "../../../utils/limitName";

export default function RegulationPendence() {
  const [filter, setFilter] = React.useState();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [schedulings, setSchedulings] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [scheduleSected, setScheduleSected] = React.useState();

  const { getRegulations } = useRegulation();
  const notify = useNotifier();

  const tableColumns = [
    {
      name: "Nome",
      field: "patient",
      type: "text",
      use: (value, row) =>
        limitName(
          row.patient?.social_prioritize ? row.patient.social_name : value.name,
          3,
          "Não informado"
        ),
    },
    {
      name: "Data de Nascimento",
      field: "birth_date",
      use: (date) =>
        date?.birth_date
          ? format(parseISO(date?.birth_date), "dd/MM/yyyy")
          : "Não informado",
      type: "date",
    },
    {
      name: "Especialidade/Procedimento",
      field: "specialty",
      type: "text",
      use: (data, row) =>
        data?.describe
          ? data?.describe
          : row?.procedure?.name
          ? row?.procedure?.name
          : "Não informado",
    },
    {
      name: "Data de Solicitação",
      field: "createdAt",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado",
      type: "date",
    },
    {
      name: "Dias de Espera",
      field: "createdAt",
      use: (date) =>
        date ? differenceInDays(new Date(), parseISO(date)) : "Não informado",
      type: "text",
    },
  ];

  const regulationsQuery = useQuery(
    ["regulations", page, limit, filter],
    () =>
      getRegulations({
        page: page,
        limit: limit,
        status: "pending",
        ...filter,
      }),
    {
      onSuccess: (response) => {
        setSchedulings(response.items);
        setCount(response.totalItems);
      },
      onError: (error) => {
        notify(error.message, "error");
        setSchedulings([]);
      },
      retry: false,
    }
  );

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleFilter(filter) {
    setPage(0);
    setFilter((previous) => ({ ...previous, ...filter }));
  }

  return (
    <div>
      <DialogMedium
        maxWidth={"md"}
        open={openModal}
        title={"Agendamento"}
        handleClose={handleCloseModal}
        children={
          <RegulationFormModal
            schedule={scheduleSected}
            handleClose={handleCloseModal}
          />
        }
      />

      <TableFilter
        data={schedulings}
        columns={tableColumns}
        actions
        loading={regulationsQuery.isLoading}
        handleFilter={handleFilter}
        actionUniqueIdentifier={"id"}
        actionsTypes={["response"]}
        actionHandleResponse={(schedule) => {
          setScheduleSected(
            schedulings.filter((item) => item.id === schedule)[0]
          );
          setOpenModal(true);
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={count}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </div>
  );
}
