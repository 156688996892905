import React from "react";
import { Typography, CardHeader, CardContent, Card, Grid } from "@mui/material";
import { format, parseISO } from "date-fns";

function PatientDischargeFeedback({ data }) {
  return (
    <Card
      sx={{
        border: "3px solid transparent",
        borderColor: "white",
        borderWidth: "3px",
        boxShadow: "0 0 0 1.5px #FFFFFF",
      }}
    >
      <CardHeader
        title={
          <Typography
            variant="h1"
            color="secondary"
            fontWeight="600"
            fontSize={15}
          >
            Alta
          </Typography>
        }
        sx={{ mb: -3 }}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="p"
              fontSize={13}
              color="secondary"
              fontWeight="500"
            >
              Profissional:{" "}
              <Typography
                color="primary"
                component="span"
                fontWeight={500}
                fontSize={13}
              >
                {data.employee_specialty.employee.name}
              </Typography>
            </Typography>
            <Typography
              variant="p"
              fontSize={13}
              color="secondary"
              fontWeight="500"
            >
              Data da alta:{" "}
              <Typography
                color="primary"
                component="span"
                fontWeight={500}
                fontSize={13}
              >
                {format(parseISO(data.createdAt), "dd/MM/yyyy")}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="p"
              color="gray"
              fontWeight="700"
              fontSize={12}
              sx={{ mb: 2 }}
            >
              #1 DADOS DA ALTA
            </Typography>
            <Typography
              variant="p"
              fontSize={13}
              color="secondary"
              fontWeight="500"
            >
              Motivo de alta:{" "}
              <Typography
                color="primary"
                component="span"
                fontWeight={500}
                fontSize={13}
              >
                {data.reason.describe}
              </Typography>
            </Typography>
            <Typography
              variant="p"
              fontSize={13}
              color="secondary"
              fontWeight="500"
            >
              Observação/Anotação:{" "}
              <Typography
                color="primary"
                component="span"
                fontWeight={500}
                fontSize={13}
              >
                {data.notes}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default PatientDischargeFeedback;
