import instance from "../config/api";

const DPath = '/activities'
export default function useActivities(){
    async function getActivities(params){
        try {
            const result = await instance.get(DPath,{params})
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    return {
        getActivities
    }
}