import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router";
import { Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { AvatarGender } from "..";
import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function AttendanceConfirmCard({
  name,
  time,
  patient,
  gender,
  id,
  age,
  handleClick,
  ...props
}) {
  const [tooltip, setTooltip] = React.useState(false);
  const theme = useTheme();
  const BoxContainer = styled(Grid)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
  }));
  const BoxItem = styled(Grid)({
    borderRight: `1px solid ${theme.palette.primary.main}`,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    padding: "10px",
    "&:last-child": {
      position: "relative",
      borderRight: "none",
    },
  });
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#9AC0D8",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  const BoxAvatar = styled(Box)({
    display: "flex",
    alignItems: "center",
    position: "relative",
    gap: "20px",
  });
  return (
    <BoxContainer
      container
      onMouseLeave={() => setTooltip(false)}
      onDoubleClick={handleClick}
      sx={{
        transition: ".3s",
        cursor: "pointer",
        "&:hover": {
          background: "rgba(22, 103, 154, 0.03)",
        },
      }}
    >
      <BoxItem item xs={12} sx={{}}>
        <LightTooltip title="Dê um duplo clique">
          <BoxAvatar>
            <Typography
              color="secondary.main"
              style={{
                fontFamily: "Montserrat",
                color: "#9AC0D8",
                fontSize: "16px",
              }}
            >
              {time && time}
            </Typography>
            <AvatarGender gender={gender || ""} size={50} />
            <Stack>
              <Typography fontSize={12} color="secondary.main">
                Cód. Paciente :{" "}
                <Typography component="span" fontWeight="bold" fontSize={12}>
                  {id}
                </Typography>
              </Typography>
              <Typography fontSize={16} color="secondary.main">
                {patient}
              </Typography>
              <Typography fontSize={12} color="primary.font">
                {age < 10 ? `0${age}` : age} Anos
              </Typography>
            </Stack>
          </BoxAvatar>
        </LightTooltip>
      </BoxItem>
    </BoxContainer>
  );
}

export default AttendanceConfirmCard;
