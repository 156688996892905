import instance from "../config/api";
const DPath = "attached-documents"
export default function useAttachedDocuments(){
    async function getAttachedDocuments(params){
        try {
            const response = await instance.get(DPath,{params})
            return response.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function postAttachedDocument(data) {
        try {
          const response = await instance.post(DPath, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          return response.data;
        } catch (err) {
          throw new Error(err.response.data.message);
        }
      }

      async function suspendAttachedDocument({id,suspended_notes}) {
        try {
          const response = await instance.patch(`${DPath}/${id}/suspend`,{suspended_notes});
          return response.data;
        } catch (err) {
          throw new Error(err.response.data.message);
        }
      }

    return {
        getAttachedDocuments,
        postAttachedDocument,
        suspendAttachedDocument
    }
}