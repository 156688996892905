import { headerHorizontalStyle, footer, title, patientInformations } from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";

function MedicalOpinionPDF(data, patient) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const description = {
    alignment: "center",
    text: [
      { text: `\n${data.observations}`, bold: true },
      { text: "." },
    ],
  };

  const code = {
    type: "none",
    margin: [0, 30, 0, 0],
    alignment: "center",
    ul: [
      {
        text: data.cid_id || "",
        fontSize: 26,
        bold: true,
        border: [false, false, false, false],
        decoration: "underline",
        decorationStyle: "dashed",
        decorationColor: "#514C4C",
      },
      {
        text: data.cid_id ? "Código da doença" : "",
        fontSize: 10,
        color: "#514C4C",
      },
    ],
  };

  const urlType = pdfName + "/types/declaration";

  let employee;

  if (data.soap?.employee_specialty) {
    employee = {
      ...data.soap.employee_specialty.employee,
      specialty: data.soap.employee_specialty.specialty,
    };
  }

  const definitions = {
    info: { title: pdfName },
    pageMargins: [40, 105, 40, 120],
    defaultStyle: {
      fontSize: 10,
    },
    ...headerHorizontalStyle(data?.attendance?.company),
    footer: footer(data?.createdAt, true, employee, data?.attendance?.company, urlType),
    content: [title("Laudo Médico"), patientInformations(patient), description, code],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default MedicalOpinionPDF;
