import React from "react";
import { Box } from "@mui/material";
import TabTotem from "./Totem/TabTotem";
import TabPrinter from "./Totem/TabPrinter";
import TabPriority from "./Totem/TabPriority";
import { Tabs } from "../../components";

function SettingsTotem() {
  const tabList = [
    {
      label: "Totem",
      content: <TabTotem />,
    },
    {
      label: "Prioridade",
      content: <TabPriority />,
    },
    {
      label: "Impressoras",
      content: <TabPrinter />,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs tabs={tabList} />
    </Box>
  );
}

export default SettingsTotem;
