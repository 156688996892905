import {  Divider, Grid, Typography } from "@mui/material";
import { FormCheckbox, FormTextField } from "../../../components";
import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";

export default function OrthodonticTab() {
  const {disableFields} = useContext(AppContext)

  return (
    <Grid container xs={12} spacing={2}>
      <Grid xs={12} item>
        <Typography fontWeight={"bold"} component={"span"} color={"primary"}>
          Mordida Aberta
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.open_bite.anterior"
          label={"Anterior"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.open_bite.posterior"
          label={"Posterior"}
          value={true}
        />
      </Grid>

      <Grid xs={12} item>
        <Typography fontWeight={"bold"} component={"span"} color={"primary"}>
          Mordida Cruzada
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.crossbite.before"
          label={"Anterior"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.crossbite.unilateral"
          label={"Unilateral"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.crossbite.bilateral"
          label={"Bilateral"}
          value={true}
        />
      </Grid>

      <Grid xs={12} item>
        <Typography fontWeight={"bold"} component={"span"} color={"primary"}>
          Mordida profunda
        </Typography>
        <Divider />
        <Typography component={"span"} color={"primary"}>
          Classe I com apinhamento
        </Typography>
      </Grid>
      <Grid xs={12} item></Grid>
      <Grid item xs={12}>
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_1.mouth_breathing"
          label={"Respiração Bucal"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_1.crowding"
          label={"Apinhamento"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_1.maxillary_atresia"
          label={"Atresia de maxila"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_1.mandibular_atresia"
          label={"Atresia de mandíbula"}
          value={true}
        />
      </Grid>
      <Grid item xs={6} display={"flex"} alignItems={"center"}>
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_1.others"
          label={"Outras anomalias de crescimento crânio facial"}
          value={true}
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextField disabled={disableFields} name="orthodontic.deep_bite.class_1.note" />
      </Grid>
      <Grid item xs={12}>
        <Typography component={"span"} color={"primary"} fontWeight={500}>
          Classe II
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component={"span"} color={"primary"}>
          Divisão 1ª
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_2.division_1.maxillary_protrusion"
          label={"Protrusão maxilar"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_2.division_1.labial_movement_incisors"
          label={"Vestibularização de incisivos"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_2.division_1.mandibular_retrusion"
          label={"Retrusão mandibular"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_2.division_1.excessive_overjet"
          label={"Sobressalência acentuada"}
          value={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component={"span"} color={"primary"}>
          Divisão 2ª
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_2.division_2.excessive_overbite"
          label={"Sobremordida acentuada"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_2.division_2.incisor_extrusion"
          label={"Verticalização de incisivos"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_2.division_2.mandibular_retrusion"
          label={"Retrusão mandibular"}
          value={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component={"span"} color={"primary"} fontWeight={500}>
          Classe III
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_3.mandibular_prognathism"
          label={"Prognatismo mandibular"}
          value={true}
        />
        <FormCheckbox
        disabled={disableFields}
          name="orthodontic.deep_bite.class_3.pre_maxillary_hypoplasia"
          label={"Pouco crescimento da pré-maxila"}
          value={true}
        />
      </Grid>
    </Grid>
  );
}
