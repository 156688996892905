import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/ciap";

function useCiaps() {
  async function getCiaps(filters) {
    try {
      const response = await instance.get(path, {
        params: urlQuery(filters),
      });

      return response.data;
    } catch (err) {
      return err;
    }
  }

  return {
    getCiaps,
  };
}

export default useCiaps;
