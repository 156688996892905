import * as React from "react";
import { styled } from "@mui/material/styles";
import { TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import propTypes from "prop-types";
import { IconButton } from "@mui/material";
import {
  DeleteRounded,
  EditRounded,
  VisibilityRounded,
  DoNotDisturbAlt,
} from "@mui/icons-material";
import { ConfirmDialog } from "../../helper";
import { useTheme } from "@emotion/react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.light,
  },
  [`&.${tableCellClasses.root}`]: {
    boxShadow: "none",
    borderBottom: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: "none",
  "&:nth-of-type(odd)": {
    color: "white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.disabled": {
    backgroundColor: "rgba(216, 0, 0, 0.1)",
    pointerEvents: "none",
  },
}));

export default function CustomTable({
  columns = [],
  rows = [],
  size = "small",
  actions = false,
  actionsTypes = [],
  actionUniqueIdentifier,
  suspendedIdentifier,
  actionHandleView,
  actionHandleEdit,
  onCancelConfirmation = () => {},
  actionConfirmItem = "item",
  confirmMessage,
  actionHandleDelete,
  actionHandleSuspend,
  paginate = true,
  formikFormHelper,
  shouldDisableFields = [],
  ...props
}) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [itemMessage, setItemMessage] = React.useState("");
  const [actionMessage, setActionMessage] = React.useState("");
  const actionDialog = React.useRef(() => {});
  const actionCancelDialog = React.useRef(() => {});

  const theme = useTheme();

  const togleDialog = () => {
    if (openDialog) {
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
    }
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if (!paginate) {
      setRowsPerPage(rows.length);
    }
  }, []);
  return (
    <>
      <TableContainer component={Paper} {...props}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table" size={size}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.name}
                  align={column.align ? column.align : "left"}
                >
                  {column.name}
                </StyledTableCell>
              ))}
              {actions ? (
                <StyledTableCell align="center">Ações</StyledTableCell>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <StyledTableRow
                  key={Object.values(row)[0] + index}
                  className={row[suspendedIdentifier] ? "disabled" : ""}
                >
                  {Object.keys(row).map((attr, index) => {
                    if (!shouldDisableFields.includes(attr)) {
                      if (attr === "align") return "";
                      else {
                        return (
                          <StyledTableCell
                            key={row[attr] + "-" + index}
                            align={row.align ? row.align : "left"}
                          >
                            {row[attr]}
                          </StyledTableCell>
                        );
                      }
                    }
                  })}
                  {actions ? (
                    <StyledTableCell align="center">
                      {actionsTypes.map((item) => {
                        switch (item) {
                          case "view":
                            return (
                              <IconButton
                                key={item}
                                color="secondary"
                                onClick={() => {
                                  actionHandleView(
                                    actionUniqueIdentifier
                                      ? row[actionUniqueIdentifier]
                                      : index,
                                    row,
                                    formikFormHelper ? formikFormHelper : ""
                                  );
                                }}
                              >
                                <VisibilityRounded />
                              </IconButton>
                            );
                          case "edit":
                            return (
                              <IconButton
                                key={item}
                                color="secondary"
                                onClick={() => {
                                  actionHandleEdit(
                                    actionUniqueIdentifier
                                      ? row[actionUniqueIdentifier]
                                      : index,
                                    row,
                                    formikFormHelper ? formikFormHelper : ""
                                  );
                                }}
                              >
                                <EditRounded />
                              </IconButton>
                            );
                          case "delete":
                            return (
                              <IconButton
                                key={item}
                                color="error"
                                onClick={() => {
                                  setActionMessage("deletar");
                                  togleDialog();
                                  actionDialog.current = () => {
                                    actionHandleDelete(
                                      actionUniqueIdentifier
                                        ? row[actionUniqueIdentifier]
                                        : index,
                                      formikFormHelper ? formikFormHelper : ""
                                    );
                                  };
                                  actionCancelDialog.current = () => {
                                    onCancelConfirmation(
                                      actionUniqueIdentifier
                                        ? row[actionUniqueIdentifier]
                                        : index,
                                      row,
                                      formikFormHelper ? formikFormHelper : ""
                                    );
                                  };
                                }}
                              >
                                <DeleteRounded />
                              </IconButton>
                            );
                          case "suspend":
                            return (
                              <>
                                <IconButton
                                  key={item}
                                  disabled={row[suspendedIdentifier]}
                                  color="secondary"
                                  onClick={() => {
                                    setActionMessage("suspender");
                                    togleDialog();
                                    actionDialog.current = () => {
                                      actionHandleDelete(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                    actionCancelDialog.current = () => {
                                      onCancelConfirmation(
                                        actionUniqueIdentifier
                                          ? row[actionUniqueIdentifier]
                                          : index,
                                        row,
                                        formikFormHelper ? formikFormHelper : ""
                                      );
                                    };
                                  }}
                                >
                                  <DoNotDisturbAlt />
                                </IconButton>
                              </>
                            );
                        }
                      })}
                    </StyledTableCell>
                  ) : (
                    ""
                  )}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={paginate ? [5, 10, 100] : [rows.length]}
        component="div"
        count={rows.length}
        rowsPerPage={paginate ? rowsPerPage : rows.length}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          color: theme.palette.primary.light,
          fontFamily: "Montserrat",
          fontSize: "15px",
        }}
      />
      <ConfirmDialog
        item={actionConfirmItem}
        message={confirmMessage}
        action={actionMessage}
        hideBackDrop={false}
        open={openDialog}
        maxWidth="sm"
        fullWidth={true}
        handleClose={togleDialog}
        actions
        handleConfirm={actionDialog.current}
        handleCancel={actionCancelDialog.current}
      />
    </>
  );
}
CustomTable.propTypes = {
  size: propTypes.oneOf(["small", "medium", "large"]),
  columns: propTypes.arrayOf(propTypes.object),
  rows: propTypes.arrayOf(propTypes.object),
  actions: propTypes.bool,
  actionUniqueIdentifier: propTypes.string,
  actionsTypes: propTypes.arrayOf(propTypes.string),
  actionHandleView: propTypes.func,
  actionHandleEdit: propTypes.func,
  actionHandleDelete: propTypes.func,
  formikFormHelper: propTypes.object,
};
