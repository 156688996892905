import {
  CampaignRounded,
  DeleteRounded,
  DoNotDisturbAlt,
  EditRounded,
  SwapHorizRounded,
  VisibilityRounded,
  Image,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  TableCell,
  TableRow as TableRowMUI,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CheckboxField } from "../../FormFields";

export const StyledTableCell = styled(TableCell)(
  ({ theme, tdcolor = "none" }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.primary.light,
      fontSize: 13,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.primary.light,
    },
    [`&.${tableCellClasses.root}`]: {
      boxShadow: "none",
      backgroundColor: tdcolor,
      borderBottom: "none",
    },
  })
);

export const StyledTableRow = styled(TableRowMUI)(({ theme }) => ({
  border: "none",
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.medium,
  },
  "&.disabled,&.error": {
    backgroundColor: "rgba(216, 0, 0, 0.1)",
  },
  "&.active": {
    backgroundColor: "rgba(0, 216, 0, 0.1)",
  },
  "&.info": {
    backgroundColor: "rgba(187, 222, 251, 0.6)",
  },
  "&.warning": {
    backgroundColor: "rgba(255, 223, 186, 0.8)",
  },
  "&.attention": {
    backgroundColor: "rgba(255, 246, 168, 0.8)",
  },
  "&.error-light": {
    backgroundColor: "rgba(216, 0, 0, 0.3)",
  },
}));

export default function TableRow({
  rowStatus,
  row,
  columns,
  actions,
  actionsTypes,
  customizeActions,
  disableActions,
  actionHandleView,
  actionHandleEdit,
  actionHandlePrint,
  actionHandleChecklist,
  actionHandleTransfer,
  actionHandleDownload,
  actionHandleDelete,
  actionHandleSuspend,
  actionHandleCall,
  actionHandleResponse,
  actionHandleFinish,
  actionHandleImage,
  formikFormHelper,
  togleDialog,
  actionDialog,
  actionCancelDialog,
  setMessage,
  actionUniqueIdentifier,
  checkboxActionController,
  callsCountField,
  rowNumber,
  confirmMessage = "Você realmente deseja *action* este item?",
  collapseColumns,
  collapseRowData,
  onCancelConfirmation = () => {},
}) {
  const activeCollapse = collapseColumns && collapseRowData;

  const handleMessage = (row, action) => {
    const message = confirmMessage.replace("action", action);
    const splitedMessage = message.split(" ").map((str) => {
      if (str.match(/({\D+})/gi)) {
        const replacer = str.replace(/[()?]/g, "");
        const value = eval(replacer.replace(/[{}]/g, ""));
        const string = str.replace(replacer, value);
        return string;
      } else {
        return str;
      }
    });
    const completedMessage = splitedMessage.join(" ");
    setMessage(completedMessage);
  };

  const [callCount, setCallCount] = useState(0);
  const [openCollapse, setOpenCollapse] = React.useState(false);

  const popperProps = {
    popperOptions: {
      positionFixed: true,
      modifiers: [
        {
          name: "flip",
          enabled: true,
          options: {
            rootBoundary: "viewport",
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            rootBoundary: "viewport",
          },
        },
        {
          name: "offset",
          options: {
            offset: [-50, -50],
          },
        },
      ],
    },
  };

  useEffect(() => {
    setCallCount(eval(`row.${callsCountField}`));
  }, [eval(`row.${callsCountField}`)]);

  return (
    <>
      <StyledTableRow className={rowStatus}>
        {columns?.map((col, index) => (
          <>
            <StyledTableCell
              key={col.name + "-" + rowNumber}
              tdcolor={col.tdColor}
              align={col.alignInRow ? col.alignInRow : "left"}
              sx={{
                textWrap: col.nowrap ? "nowrap" : "wrap",
              }}
            >
              {activeCollapse && index === 0 ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenCollapse(!openCollapse)}
                >
                  {openCollapse ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              ) : null}
              {eval(col.condition) || !col.condition
                ? col.use instanceof Function
                  ? col.use(eval(`row.${col.field}`), row)
                  : eval(`row.${col.field}`)
                : col.otherwise instanceof Function
                ? col.otherwise(row)
                : eval(`row.${col.otherwise}`)}
            </StyledTableCell>
          </>
        ))}
        {actions && callsCountField ? (
          <StyledTableCell key={"call-number"} align={"center"}>
            {callCount}
          </StyledTableCell>
        ) : null}
        {actions ? (
          <StyledTableCell
            align="center"
            sx={{
              backgroundColor: (theme) => "rgba(0,0,0, 0.03)",
            }}
          >
            {actionsTypes.map((item) => {
              switch (item) {
                case "view":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={customizeActions?.["view"]?.title || "Visualizar"}
                    >
                      <Box component="span">
                        <IconButton
                          color="secondary"
                          disabled={
                            !!disableActions && disableActions(row, "view")
                          }
                          onClick={() => {
                            actionHandleView(
                              actionUniqueIdentifier
                                ? row[actionUniqueIdentifier]
                                : rowNumber,
                              row,
                              formikFormHelper ? formikFormHelper : ""
                            );
                          }}
                        >
                          {customizeActions?.["view"]?.icon || (
                            <VisibilityRounded />
                          )}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );
                case "edit":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={customizeActions?.["edit"]?.title || "Editar"}
                    >
                      <Box component="span">
                        <IconButton
                          color="secondary"
                          disabled={
                            !!disableActions && disableActions(row, "edit")
                          }
                          onClick={() => {
                            actionHandleEdit(
                              actionUniqueIdentifier
                                ? row[actionUniqueIdentifier]
                                : rowNumber,
                              row,
                              formikFormHelper ? formikFormHelper : ""
                            );
                          }}
                        >
                          {customizeActions?.["edit"]?.icon || <EditRounded />}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );
                case "print":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={customizeActions?.["print"]?.title || "Imprimir"}
                    >
                      <Box component="span">
                        <IconButton
                          color="secondary"
                          disabled={
                            !!disableActions && disableActions(row, "print")
                          }
                          onClick={() => {
                            handleMessage(
                              row,
                              customizeActions?.["print"]?.verb || "imprimir"
                            );
                            togleDialog();

                            actionDialog.current = () => {
                              actionHandlePrint(
                                actionUniqueIdentifier
                                  ? row[actionUniqueIdentifier]
                                  : rowNumber,
                                row,
                                formikFormHelper ? formikFormHelper : ""
                              );
                            };
                            actionCancelDialog.current = () => {
                              onCancelConfirmation(
                                actionUniqueIdentifier
                                  ? row[actionUniqueIdentifier]
                                  : rowNumber,
                                row,
                                formikFormHelper ? formikFormHelper : ""
                              );
                            };
                          }}
                        >
                          {customizeActions?.["print"]?.icon || (
                            <LocalPrintshopRoundedIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );
                case "download":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={
                        customizeActions?.["download"]?.title || "Download"
                      }
                    >
                      <Box component="span">
                        <IconButton
                          color="secondary"
                          disabled={
                            !!disableActions && disableActions(row, "download")
                          }
                          onClick={() => {
                            actionHandleDownload(
                              actionUniqueIdentifier
                                ? row[actionUniqueIdentifier]
                                : rowNumber,
                              row,
                              formikFormHelper ? formikFormHelper : ""
                            );
                          }}
                        >
                          {customizeActions?.["download"]?.icon || (
                            <FileDownloadRoundedIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );
                case "checkbox":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={
                        customizeActions?.["checkbox"]?.title ||
                        "Marcar / Desmarcar"
                      }
                    >
                      <Box component="span">
                        <CheckboxField
                          control={checkboxActionController.control}
                          key={"table_checks" + rowNumber}
                          optionValueKey={actionUniqueIdentifier}
                          optionCompareKey={actionUniqueIdentifier}
                          name={"checksData"}
                          disabled={
                            (!!disableActions &&
                              disableActions(row, "checkbox")) ||
                            checkboxActionController
                              .watch("checksData")
                              .includes("all")
                          }
                          options={[row]}
                        />
                      </Box>
                    </Tooltip>
                  );
                case "response":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={
                        customizeActions?.["response"]?.title || "Responder"
                      }
                    >
                      <Box component="span">
                        <IconButton
                          color="secondary"
                          disabled={
                            !!disableActions && disableActions(row, "response")
                          }
                          onClick={() => {
                            actionHandleResponse(
                              actionUniqueIdentifier
                                ? row[actionUniqueIdentifier]
                                : rowNumber,
                              row,
                              formikFormHelper ? formikFormHelper : ""
                            );
                          }}
                        >
                          {customizeActions?.["response"]?.icon || (
                            <AssignmentOutlinedIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );
                case "checklist":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={
                        customizeActions?.["checklist"]?.title || "Checklist"
                      }
                    >
                      <Box component="span">
                        <IconButton
                          color="secondary"
                          disabled={
                            !!disableActions && disableActions(row, "checklist")
                          }
                          onClick={() => {
                            actionHandleChecklist(
                              actionUniqueIdentifier
                                ? row[actionUniqueIdentifier]
                                : rowNumber,
                              row,
                              formikFormHelper ? formikFormHelper : ""
                            );
                          }}
                        >
                          {customizeActions?.["checklist"]?.icon || (
                            <InventoryRoundedIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );
                case "transfer":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={
                        customizeActions?.["transfer"]?.title || "Transferir"
                      }
                    >
                      <Box component="span">
                        <IconButton
                          color="secondary"
                          disabled={
                            !!disableActions && disableActions(row, "transfer")
                          }
                          onClick={() => {
                            actionHandleTransfer(
                              actionUniqueIdentifier
                                ? row[actionUniqueIdentifier]
                                : rowNumber,
                              row,
                              formikFormHelper ? formikFormHelper : ""
                            );
                          }}
                        >
                          {customizeActions?.["transfer"]?.icon || (
                            <SwapHorizRounded />
                          )}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );
                case "finish":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={customizeActions?.["finish"]?.title || "Finalizar"}
                    >
                      <Box component="span">
                        <IconButton
                          color="secondary"
                          disabled={
                            !!disableActions && disableActions(row, "finish")
                          }
                          onClick={() => {
                            handleMessage(
                              row,
                              customizeActions?.["finish"]?.verb || "finalizar"
                            );
                            togleDialog();

                            actionDialog.current = () => {
                              actionHandleFinish(
                                actionUniqueIdentifier
                                  ? row[actionUniqueIdentifier]
                                  : rowNumber,
                                row,
                                formikFormHelper ? formikFormHelper : ""
                              );
                            };
                            actionCancelDialog.current = () => {
                              onCancelConfirmation(
                                actionUniqueIdentifier
                                  ? row[actionUniqueIdentifier]
                                  : rowNumber,
                                row,
                                formikFormHelper ? formikFormHelper : ""
                              );
                            };
                          }}
                        >
                          {customizeActions?.["finish"]?.icon || (
                            <DoneRoundedIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );
                case "delete":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={customizeActions?.["delete"]?.title || "Deletar"}
                    >
                      <Box component="span">
                        <IconButton
                          color="error"
                          disabled={
                            !!disableActions && disableActions(row, "delete")
                          }
                          onClick={() => {
                            handleMessage(
                              row,
                              customizeActions?.["delete"]?.verb || "deletar"
                            );
                            togleDialog();

                            actionDialog.current = () => {
                              actionHandleDelete(
                                actionUniqueIdentifier
                                  ? row[actionUniqueIdentifier]
                                  : rowNumber,
                                row,
                                formikFormHelper ? formikFormHelper : ""
                              );
                            };
                            actionCancelDialog.current = () => {
                              onCancelConfirmation(
                                actionUniqueIdentifier
                                  ? row[actionUniqueIdentifier]
                                  : rowNumber,
                                row,
                                formikFormHelper ? formikFormHelper : ""
                              );
                            };
                          }}
                        >
                          {customizeActions?.["delete"]?.icon || (
                            <DeleteRounded />
                          )}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );
                case "call":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={customizeActions?.["call"]?.title || "Chamar"}
                    >
                      <Box component="span">
                        <IconButton
                          color="secondary"
                          disabled={
                            !!disableActions && disableActions(row, "call")
                          }
                          onClick={() => {
                            handleMessage(
                              row,
                              customizeActions?.["call"]?.verb || "chamar"
                            );
                            togleDialog();

                            actionDialog.current = () => {
                              setCallCount((curr) => curr + 1);
                              actionHandleCall(
                                actionUniqueIdentifier
                                  ? row[actionUniqueIdentifier]
                                  : rowNumber,
                                row,
                                formikFormHelper ? formikFormHelper : ""
                              );
                            };
                            actionCancelDialog.current = () => {
                              onCancelConfirmation(
                                actionUniqueIdentifier
                                  ? row[actionUniqueIdentifier]
                                  : rowNumber,
                                row,
                                formikFormHelper ? formikFormHelper : ""
                              );
                            };
                          }}
                        >
                          {customizeActions?.["call"]?.icon || (
                            <CampaignRounded />
                          )}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );
                case "suspend":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={
                        customizeActions?.["suspend"]?.title || "Suspender"
                      }
                    >
                      <Box component="span">
                        <IconButton
                          disabled={
                            !!disableActions && disableActions(row, "suspend")
                          }
                          color="secondary"
                          onClick={() => {
                            handleMessage(
                              row,
                              customizeActions?.["suspend"]?.verb || "suspender"
                            );
                            togleDialog();

                            actionDialog.current = () => {
                              actionHandleSuspend(
                                actionUniqueIdentifier
                                  ? row[actionUniqueIdentifier]
                                  : rowNumber,
                                row,
                                formikFormHelper ? formikFormHelper : ""
                              );
                            };
                            actionCancelDialog.current = () => {
                              onCancelConfirmation(
                                actionUniqueIdentifier
                                  ? row[actionUniqueIdentifier]
                                  : rowNumber,
                                row,
                                formikFormHelper ? formikFormHelper : ""
                              );
                            };
                          }}
                        >
                          {customizeActions?.["suspend"]?.icon || (
                            <DoNotDisturbAlt />
                          )}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );
                case "image":
                  return (
                    <Tooltip
                      key={`${item}-${rowNumber}`}
                      PopperProps={popperProps}
                      title={customizeActions?.["image"]?.title || "Imagem"}
                    >
                      <Box component="span">
                        <IconButton
                          color="secondary"
                          disabled={
                            !!disableActions && disableActions(row, "image")
                          }
                          onClick={() => {
                            actionHandleImage(
                              actionUniqueIdentifier
                                ? row[actionUniqueIdentifier]
                                : rowNumber,
                              row,
                              formikFormHelper ? formikFormHelper : ""
                            );
                          }}
                        >
                          {customizeActions?.["image"]?.icon || <Image />}
                        </IconButton>
                      </Box>
                    </Tooltip>
                  );

                default:
                  return null;
              }
            })}
          </StyledTableCell>
        ) : (
          ""
        )}
      </StyledTableRow>
      {activeCollapse && openCollapse && (
        <>
          {collapseRowData.map((dataRow) => {
            return <TableRow columns={collapseColumns} row={dataRow} />;
          })}
        </>
      )}
    </>
  );
}
