import { useEffect } from "react";
import { useDebounceCallback } from "../hooks/";

const HandleChange = ({ data, touched, setLocalStorage, dependencies }) => {
  const debounceChange = useDebounceCallback(
    (data) => setLocalStorage(data),
    500
  );

  useEffect(() => {
    if (touched) {
      debounceChange(data);
    }
  }, dependencies);

  return null;
};

export default HandleChange;
