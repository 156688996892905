import instance from "../config/api";

export default function useTPortal(){
    const DPath = 'tportal/details/'
    async function getDocumentDetailsById(id,params){
        try {
            const response = instance.get(`${DPath}${id}`,{params})
            return (await response).data
        } catch (error) {
            throw new Error(error.response.data.message); 
        }
    }

    return {
        getDocumentDetailsById
    }
}