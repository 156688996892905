function accessObjectByString(object, string) {
  try {
    if (object instanceof Object && string) {
      const splitedString = string.split(".");

      const attribute = splitedString.reduce((previous, current) => {
        if (previous) return previous[current];
        return previous
      }, object);

      return attribute;
    }
  } catch (err) {
    console.log(err, "Attention to verify the 'string' parameter");
  }
}

export default accessObjectByString;
