import React from "react";
import { TITLE_NAME } from "../../config/config";
import { Helmet } from "react-helmet";
import { Box, TablePagination, Button } from "@mui/material";
import {
  dateTimeFormater,
  Dialog,
  DialogMedium,
  Error,
  Success,
} from "../../helper";
import CampaignForm from "../Vaccine/Form/CampaignForm";
import { useCampaign } from "../../service";
import CampaignFeedback from "./Feedback/CampaignFeedback";
import { useQuery } from "react-query";
import PaginatedTable from "../../components/Table/PaginatedTable";
import { format, parseISO } from "date-fns";
import CampaignEdit from "./Edit/CampaignEdit";
import { BoxContainer } from "../../components";
import { ViewCarousel } from "@mui/icons-material";
import usePermissions from "../../config/usePermissions";

function VaccineCampaign() {
  const { getCampaigns } = useCampaign();
  const [campaign, setCampaign] = React.useState([]);
  const [visualizationCampaing, setVisualizationCampaing] =
    React.useState(false);
  const [editCampaignDialog, setEditCampaignDialog] = React.useState(false);
  const [CampaignDialog, setCampaignDialog] = React.useState(false);
  const [showVisualization] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [unicCampaigns, setUnicCampaigns] = React.useState();
  const permission = usePermissions("/vaccine/campaign");

  const campaignQuery = useQuery(
    ["campaingn", [page, limit]],
    () => getCampaigns({ page, limit }),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  React.useEffect(() => {
    if (
      campaignQuery.isSuccess &&
      campaignQuery.data &&
      campaignQuery.data.items
    ) {
      if (count === 0) {
        setCount(campaignQuery.data.totalItems);
      }
      setCampaign(
        campaignQuery.data.items.map((index) => {
          return index;
        })
      );
      setRows(
        campaignQuery.data.items.map((index) => {
          const minimumAge = `${
            !!index.minimumAge?.years ? index.minimumAge.years + " ano(s)" : ""
          } ${
            !!index.minimumAge?.months
              ? index.minimumAge.months + " mese(s)"
              : ""
          } ${
            !!index.minimumAge?.weeks
              ? index.minimumAge.weeks + " semana(s)"
              : ""
          } ${
            !!index.minimumAge?.days ? index.minimumAge.days + " dia(s)" : ""
          }`;
          const maximumAge = `${
            !!index.maximumAge?.years ? index.maximumAge.years + " ano(s)" : ""
          } ${
            !!index.maximumAge?.months
              ? index.maximumAge.months + " mese(s)"
              : ""
          } ${
            !!index.maximumAge?.weeks
              ? index.maximumAge.weeks + " semana(s)"
              : ""
          } ${
            !!index.maximumAge?.days ? index.maximumAge.days + " dia(s)" : ""
          }`;

          const initialDate = index.initialDate
            ? format(parseISO(index.initialDate), "dd/MM/yyyy")
            : "Não Definido";
          const validade = index.finalDate
            ? format(parseISO(index.finalDate), "dd/MM/yyyy")
            : "Não Definido";

          return {
            id: index.id,
            describe: index.describe,
            minimumAge: minimumAge,
            maximumAge: maximumAge,
            status: index.status,
            initialDate,
            validade,
          };
        })
      );
    }
  }, [campaignQuery.dataUpdatedAt]);

  const columns = [
    { name: "Campanha" },
    { name: "Idade Minima" },
    { name: "Idade Máxima" },
    { name: "Status" },
    { name: "Inicio" },
    { name: "Término" },
  ];

  function handleVisualization() {
    setVisualizationCampaing(true);
  }

  function handleEditDialog() {
    setEditCampaignDialog(true);
  }
  function handleCampaignDialog() {
    setCampaignDialog(true);
  }
  function handleCloseDialog() {
    setEditCampaignDialog(false);
    setCampaignDialog(false);
    setVisualizationCampaing(false);
  }

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Vacina</title>
      </Helmet>

      <DialogMedium
        open={editCampaignDialog}
        title={"Editar Campanha"}
        handleClose={handleCloseDialog}
      >
        <CampaignEdit
          unicCampaigns={unicCampaigns}
          handleCloseDialog={handleCloseDialog}
        />
      </DialogMedium>
      <DialogMedium
        open={CampaignDialog}
        title={"Cadastrar Campanha"}
        handleClose={handleCloseDialog}
      >
        <CampaignForm
          handleCloseDialog={handleCloseDialog}
          showVisualization={showVisualization}
        />
      </DialogMedium>
      <DialogMedium
        fullWidth
        open={visualizationCampaing}
        title={"Visualização de campanha"}
        handleClose={handleCloseDialog}
      >
        <CampaignFeedback data={unicCampaigns} />
      </DialogMedium>
      <BoxContainer title="Campanhas de Vacinação">
        <PaginatedTable
          data={rows}
          columns={columns}
          actions
          actionsTypes={["view", "edit"]}
          shouldDisableFields={["id"]}
          disableActions={(_, action) =>
            action === "edit" && !permission.update
          }
          actionHandleEdit={(i) => {
            setUnicCampaigns(campaign[i]);
            handleEditDialog(i);
          }}
          actionHandleView={(i) => {
            setUnicCampaigns(campaign[i]);
            handleVisualization(i);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={count}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            color="secondary"
            disabled={!permission.create}
            onClick={() => handleCampaignDialog(CampaignDialog)}
          >
            Novo Cadastro
          </Button>
        </Box>
      </BoxContainer>
    </>
  );
}

export default VaccineCampaign;
