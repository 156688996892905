import * as React from "react";
import { Alert, Snackbar, Stack } from "@mui/material";

const ReferencedAlert = React.forwardRef((props, ref) => (
  <Alert ref={ref} {...props} />
));

export default function Notifier({
  open,
  close = () => null,
  severity,
  message,
  timeToClose = 6000,
}) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    close();
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={timeToClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <ReferencedAlert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </ReferencedAlert>
      </Snackbar>
    </Stack>
  );
}
