import React, { useEffect } from "react";
import { TITLE_NAME } from "../../config/config";
import { Helmet } from "react-helmet";
import { Grid, Box, Button, TablePagination } from "@mui/material";
import { useManufacturer } from "../../service/";
import { Dialog, DialogMedium, Error, Loading, Success } from "../../helper";
import { useQuery, useQueryClient } from "react-query";
import ManufacturerForm from "../Vaccine/Form/ManufacturerForm";
import ManufacturerEdit from "./Edit/ManufacturerEdit";
import ManufacturerFeedback from "../Vaccine/Feedback/ManufacturerFeedback";
import PaginatedTable from "../../components/Table/PaginatedTable";
import _ from "lodash";
import { BoxContainer } from "../../components";
import usePermissions from "../../config/usePermissions";

function VaccineManufacturer() {
  const { getManufacturers } = useManufacturer();
  const queryClient = useQueryClient();

  const [manufacturer, setManufacturer] = React.useState([]);
  const [visualizationManufacturer, setVisualizationManufacturer] =
    React.useState(false);
  const [editManufacturer, setEditManufacturer] = React.useState(false);
  const [newManufacturer, setNewManufacturer] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);
  const [unicManufacturer, setUnicManufacturer] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const permission = usePermissions("/vaccine/manufacturer");

  const manufacturerQuery = useQuery(
    ["manufacturer", [page, limit]],
    () => getManufacturers({ page, limit }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  React.useEffect(() => {
    if (
      manufacturerQuery.isSuccess &&
      manufacturerQuery.data &&
      manufacturerQuery.data.items
    ) {
      if (count === 0) {
        setCount(manufacturerQuery.data.totalItems);
      }
      setManufacturer(
        manufacturerQuery.data.items.map((index) => {
          return index;
        })
      );
      setRows(
        manufacturerQuery.data.items.map((index) => {
          return {
            id: index.id,
            describe: index.describe,
          };
        })
      );
    }
  }, [manufacturerQuery.dataUpdatedAt]);

  function handleView() {
    setVisualizationManufacturer(true);
  }
  function handleEdit() {
    setEditManufacturer(true);
  }
  function handleNewManufacturer() {
    setNewManufacturer(true);
  }
  function handleClose() {
    setEditManufacturer(false);
    setVisualizationManufacturer(false);
    setNewManufacturer(false);
    queryClient.invalidateQueries("manufacturer");
    // queryClient.invalidateQueries("manufacturer");
  }
  const columns = [{ name: "Fabricante" }];
  if (manufacturerQuery.isLoading) return <Loading />;
  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Vacina</title>
      </Helmet>

      <DialogMedium
        fullWidth
        open={editManufacturer}
        title={"Editar Fabricante"}
        handleClose={handleClose}
      >
        <ManufacturerEdit
          handleClose={handleClose}
          unicManufacturer={unicManufacturer}
        />
      </DialogMedium>
      <DialogMedium
        fullWidth
        open={newManufacturer}
        title={"Cadastrar Fabricantes"}
        handleClose={handleClose}
      >
        <ManufacturerForm handleClose={handleClose} />
      </DialogMedium>
      <DialogMedium
        open={visualizationManufacturer}
        title={"Visualização de Fabricante"}
        handleClose={handleClose}
        fullWidth
      >
        <ManufacturerFeedback data={unicManufacturer} />
      </DialogMedium>

      <BoxContainer title="Fabricante">
        <PaginatedTable
          data={rows}
          columns={columns}
          actions
          actionsTypes={["view", "edit"]}
          disableActions={(_, action) =>
            action === "edit" && !permission.update
          }
          shouldDisableFields={["id"]}
          actionHandleEdit={(i) => {
            setUnicManufacturer(manufacturer[i]);
            handleEdit(i);
          }}
          actionHandleView={(i) => {
            setUnicManufacturer(manufacturer[i]);
            handleView(i);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={count}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "right", marginTop: 4 }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!permission.create}
            onClick={() => handleNewManufacturer(newManufacturer)}
          >
            Novo Cadastro
          </Button>
        </Box>
      </BoxContainer>
    </>
  );
}

export default VaccineManufacturer;
