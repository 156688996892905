import { DoneAll, MedicationRounded } from "@mui/icons-material";
import React from "react";
import TableFilter from "../../../components/Table/TableFilter/Table";
import { drugAdmininstrations } from "../../../config/standardSelects";
import { format, parseISO } from "date-fns";
import { DialogMedium } from "../../../helper";
import DoubleCheckEvolution from "../../SOAP/Prescription/DoubleCheckEvolution";

export default function MedicationTable({
  medications,
  check,
  type,
  activeActions,
  disableActions,
}) {
  const [toOpenDoubleCheck, setToOpenDoubleCheck] = React.useState(false);

  const columns = [
    { name: "Medicamento", field: "name" },
    { name: "Quantidade", field: "quantity" },
    { name: "Apresentação", field: "presentation" },
    { name: "Posologia", field: "posology" },
    {
      name: "Inicio",
      field: "initial_date",
      active: type !== "medications_on_site",
    },
    {
      name: "Conclusão",
      field: "final_date",
      condition: "!row.continuous_use",
      otherwise: () => "Uso contínuo",
      active: type !== "medications_on_site",
    },
    {
      name: "Suspenso",
      field: "suspended",
      use: (value) => (value ? "Sim" : "Não"),
    },
    {
      name: "Tp. Aplicação",
      field: "procedure_id",
      use: (value) =>
        drugAdmininstrations.filter((o) => o.procedure_id === value)?.[0]
          ?.describe || "Não informado.",
    },
    { name: "Observação", field: "notes" },
  ];

  const assistedColumns = [
    {
      name: "Data da Administração",
      field: "date",
      use: (value) => format(parseISO(value), "dd/MM/yyyy 'às' HH:mm"),
    },
    {
      name: "Administrado",
      field: "administration",
      use: (value) => (value ? "Sim" : "Não"),
    },
    { name: "Administrado Por", field: "employee.name" },
    { name: "Local", field: "company.name" },
  ];

  return (
    <>
      <DialogMedium
        title={"Dupla checagem"}
        open={!!toOpenDoubleCheck}
        fullWidth
        maxWidth={"md"}
        handleClose={() => setToOpenDoubleCheck(false)}
      >
        <DoubleCheckEvolution medicationOnSiteID={toOpenDoubleCheck} />
      </DialogMedium>
      <TableFilter
        data={medications || []}
        noHeaderOptions
        columns={
          type !== "assisted"
            ? columns.filter(
                (column) =>
                  column.active === undefined || column.active === true
              )
            : assistedColumns
        }
        actions={type !== "assisted" && activeActions}
        actionsTypes={
          type === "medications_on_site"
            ? ["finish", "delete", "response"]
            : ["finish", "delete"]
        }
        customizeActions={
          type === "medications_on_site"
            ? {
                finish: {
                  title: "Administrado",
                  icon: <MedicationRounded />,
                  verb: "marcar como Administrado",
                },
                delete: {
                  title: "Não Administrado",
                  icon: <MedicationRounded />,
                  verb: "marcar como Não Administrado",
                },
                response: {
                  icon: <DoneAll />,
                  title: "Dupla checagem",
                },
              }
            : {
                delete: {
                  title: "Não Dispensado",
                  verb: "marcar como Não Dispensado.",
                },
                finish: {
                  title: "Dispensado",
                  verb: "marcar como Dispensado.",
                },
              }
        }
        rowStatus={(item) =>
          item.dispensed === true || item.done === true
            ? "active"
            : item.dispensed === false ||
              item.done === false ||
              (type === "assisted" && !item?.administration)
            ? "disabled"
            : ""
        }
        activeRows={(item) => item.dispensed === true || item.done === true}
        disableActions={(item, action) => {
          if (disableActions instanceof Function) {
            const result = disableActions(item, action);

            if (result) return result;
          }

          return !(item.dispensed === null || item.done === null);
        }}
        actionHandleResponse={(_, item) => {
          setToOpenDoubleCheck(item.id);
        }}
        actionHandleFinish={check}
        actionHandleDelete={(_, item, toSend) => check(_, item, toSend, false)}
      />
    </>
  );
}
