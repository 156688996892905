import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { FileField, TextField } from "../../../components/FormFields";
import useAttachedDocuments from "../../../service/useAttachedDocuments";
import { useMutation } from "react-query";
import { Loading, formDataObject } from "../../../helper";
import { useNotifier } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import validations from "./validations";

export default function AttachedDocumentsForm({
  type,
  patient_id,
  attendance_id,
  handleClose
}) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      description: "",
      attachments: null,
      type,
      patient_id,
      attendance_id,
    },
    resolver: yupResolver(validations)
  });

  const notify = useNotifier();
  const { postAttachedDocument } = useAttachedDocuments();
  const postMutation = useMutation({
    mutationFn: postAttachedDocument,
    mutationKey: "post-external-document",
  });

  const handleOnSubmit = handleSubmit(
    ({ attachments, description, ...values }) => {
      const external_document = formDataObject({
        attachments: [attachments],
        description: description,
        ...values,
      });

      postMutation.mutate(external_document, {
        onSuccess(data) {
          notify(data.message);
          handleClose()
        },
        onError(data) {
          notify(data.message, "error");
        },
      });
    }
  );

  return (
    <>
    <Grid
      container
      item
      xs={12}
      position={"relative"}
      component={"form"}
      spacing={2}
      >
        {postMutation.isLoading && <Loading message="Enviando arquivo..." maxWidth={"100%"} backgroundColor={"rgba(255,255,255,0.7)"} />}
      <Grid item xs={12}>
        <TextField
          control={control}
          name={"description"}
          label={"Descrição do arquivo:"}
          multiline
          minRows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <FileField
          control={control}
          name={"attachments"}
          label={"Escolha o arquivo:"}
          acceptedFileTypes={["image/jpeg", "image/png", ".pdf"]}
        />
      </Grid>
      <Grid item xs={12} justifyContent={"end"}>
        <Button variant="contained" onClick={() => handleOnSubmit()}>
          Enviar
        </Button>
      </Grid>
    </Grid>
    </>
  );
}
