import React from "react";
import Yup from "../../../config/yup";
import { Grid, Box } from "@mui/material";
import { useAgainstReferences } from "../../../service";
import useNotifier from "../../../hooks/useNotifier";
import {
  Button,
  PaginatedAutocompleteField,
  TextField,
} from "../../../components/FormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

const AgainstReferenceResponse = ({
  type = "edit",
  reference,
  handleClose,
}) => {
  const { putReferenceResponse } = useAgainstReferences();
  const putReferenceResponseMutation = useMutation(putReferenceResponse);
  const [loading, setLoading] = React.useState(false);
  const notify = useNotifier();

  const initialValues = {
    id: reference?.id,
    justification: reference?.justification,
    responsible: reference?.requestor_name,
    response: reference?.response ? reference?.response : null,
    patient: reference.patient?.name,
    requested_specialty: reference.requested_specialty,
    sectorEspecific: reference?.requested_specialty
    ? reference.requested_specialty
    : "Não especificado",
    cids: reference?.cids || [],
  };

  const validations = Yup.object().shape({
    patient: Yup.string().required("É requerido"),
    responsible: Yup.string().required("É requerido").nullable(),
    requested_specialty: Yup.string().required("É requerido"),
    justification: Yup.string().required("É requerido"),
    sectorEspecific: Yup.string().required("É requerido"),
    cids: Yup.array().nullable(),
    response: Yup.string().required("É requerido").nullable(),
  });

  const { handleSubmit, control, watch } = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const response = watch("response");

  async function onSubmit(values) {
    const objectResponse = {
      id: values.id,
      response: values.response,
    };
    setLoading(true);
    putReferenceResponseMutation.mutate(objectResponse, {
      onSuccess: (response) => {
        notify(response.message, "success");
        handleClose();
      },
      onError: (err) => {
        notify(err.message, "error");
      },
    });
    setLoading(false);
  }

  return (
    <>
      <Grid
        component="form"
        container
        spacing={2}
        mt={0.5}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={4}>
          <TextField
            control={control}
            name="patient"
            label="Paciente"
            required
            disabled={type === "edit"}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            control={control}
            name="responsible"
            label="Realizada por"
            required
            disabled={type === "edit"}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            control={control}
            name="requested_specialty"
            label="Especialidade Requisitada"
            required
            disabled={type === "edit"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            multiline
            minRows={5}
            maxRows={5}
            name="justification"
            label="Justificativa"
            required
            disabled={type === "edit"}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            control={control}
            name="sectorEspecific"
            label="Setor"
            required
            disabled={type === "edit"}
          />
        </Grid>
        <Grid item xs={8}>
          <PaginatedAutocompleteField
            control={control}
            label="CID"
            name="cids"
            multiple
            autoCompleteProps={{
              getOptionLabel: (option) =>
                `${option.id ? option.id : ""} ${option.description}`,
            }}
            optionCompareKey="id"
            filterKey="describe"
            queryKey="cids"
            disabled={type === "edit"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            multiline
            minRows={5}
            maxRows={5}
            name="response"
            label="Resposta da Referência / Contrarreferência"
            required
            disabled={type === "view"}
          />
        </Grid>
        <Box display="flex" justifyContent="center" margin="1.5rem auto">
          <Button type="submit" loading={loading} disabled={!response}>
            Salvar
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default AgainstReferenceResponse;
