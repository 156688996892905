import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import { theme } from "./config/theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Routes from "./Routes";
import { ConfirmContextProvider } from "./contexts/ConfirmContext";
import GlobalConfirmDialog from "./helper/GlobalConfirmDialog";
import { NotifierContextProvider } from "./contexts/NotifierContext";
import GlobalNotifier from "./helper/GlobalNotifier";
import { SignaturePasswordContextProvider } from "./contexts/SignaturePasswordContext";
import GlobalSignaturePassword from "./helper/GlobalSIgnaturePassword";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

export default function App() {
  useEffect(() => {
    String.prototype.firstCharToUp = function () {
      return this.charAt(0).toUpperCase() + this.substring(1).toLowerCase();
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <ThemeProvider theme={theme}>
        <SignaturePasswordContextProvider>
          <NotifierContextProvider>
            <ConfirmContextProvider>
              <Routes />
              <GlobalConfirmDialog />
              <GlobalNotifier />
              <GlobalSignaturePassword />
            </ConfirmContextProvider>
          </NotifierContextProvider>
        </SignaturePasswordContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
