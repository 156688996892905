import {
  headerHorizontalStyle,
  footer,
  title,
  patientInformations,
} from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";

function triagePDF(data, patient) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const content = [];

  if (data.attendance.service.odontology) {
    const odonto = [
      {
        margin: [0, 10, 0, 10],
        fontSize: 12,
        text: "Triagem - Odonto",
        bold: true,
        style: "header",
      },
      {
        margin: [-10, 0, 0, 0],
        type: "none",
        ul: [
          {
            text: [
              { text: "Risco de Cárie: ", bold: true },
              {
                text: data.caries_risks
                  ? `${data.caries_risks?.identifier} - ${data.caries_risks?.description}`
                  : "Não informado",
              },
            ],
            margin: [0, 0, 0, 5],
          },
          {
            text: [
              { text: "Risco Periodontal: ", bold: true },
              {
                text: data.periodontal_risks
                  ? `${data.periodontal_risks?.identifier} - ${data.periodontal_risks?.description}`
                  : "Não informado",
              },
            ],
            margin: [0, 0, 0, 5],
          },
          {
            text: [
              { text: "Risco de Tecidos Moles: ", bold: true },
              {
                text: data.tissue_risks
                  ? `${data.tissue_risks?.identifier} - ${data.tissue_risks?.description}`
                  : "Não informado",
              },
            ],
            margin: [0, 0, 0, 5],
          },
          {
            text: [
              { text: "Risco Oclusal: ", bold: true },
              {
                text: data.oclusal_risks
                  ? `${data.oclusal_risks?.identifier} - ${data.oclusal_risks?.description}`
                  : "Não informado",
              },
            ],
            margin: [0, 0, 0, 5],
          },
          {
            text: { text: "Necessidade de Prótese: ", bold: true },
            margin: [0, 0, 0, 5],
          },
          {
            ul: [],
            margin: [10, 0, 0, 5],
          },
          {
            text: [
              { text: "Atividade: ", bold: true },
              {
                text: data.activity ?? "Não informado",
              },
            ],
            margin: [0, 0, 0, 5],
          },
          {
            text: [
              { text: "Procedimentos: ", bold: true },
              {
                text: data.procedures?.describe ?? "Não informado",
              },
            ],
            margin: [0, 0, 0, 5],
          },
          {
            text: { text: "Práticas em Saúde: ", bold: true },
            margin: [0, 0, 0, 5],
          },
          {
            ul: [],
            margin: [10, 0, 0, 5],
          },
        ],
      },
    ];

    content.push(odonto);
  }

  if (data.attendance.service.ceo) {
    const ceo = [
      {
        margin: [0, 10, 0, 10],
        fontSize: 12,
        text: "Triagem - CEO",
        bold: true,
        style: "header",
      },
      {
        text: [
          { text: "AVALIAÇÃO/ORIENTAÇÃO: ", bold: true },
          {
            text: data.orientation ?? "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
    ];

    content.push(ceo);
  }

  data.braces_risks?.forEach(({ identifier, description }) => {
    const formatedField = {
      text: `${identifier} - ${description}`,
      margin: [0, 0, 0, 5],
    };

    content[0][1].ul[5].ul.push(formatedField);
  });

  data.practices?.forEach((practice) => {
    const formatedField = {
      text: practice,
      margin: [0, 0, 0, 5],
    };

    content[0][1].ul[9].ul.push(formatedField);
  });

  const urlType = pdfName + "/types/triage";

  let employee = null;

  if (data.employee) {
    employee = {
      ...data.employee_specialty.employee,
      specialty: data.employee_specialty.specialty,
    };
  }

  const definitions = {
    info: { title: pdfName },
    pageSize: "A4",
    pageMargins: [40, 105, 40, 260],
    defaultStyle: {
      fontSize: 10,
    },
    ...headerHorizontalStyle(data.attendance.company),
    footer: footer(
      data.createdAt,
      true,
      employee,
      data.attendance.company,
      urlType,
      [0, 140, 0, 0]
    ),
    content: [
      title("Documento de Triagem"),
      patientInformations(patient),
      content,
    ],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default triagePDF;
