import React from "react";
import Yup from "../../../config/yup";
import { Form, Formik } from "formik";
import { Button, Grid } from "@mui/material";
import { FormTextField, SubmitButton } from "../../../components";
import { useCids, useRegulation } from "../../../service";
import {
  FormCheckbox,
  FormDate,
  FormFile,
  FormRadio,
  FormSelectWithPaginate,
  FormTimePicker,
} from "../../../components/Form";
import { useCompany } from "../../../service/useCompany";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { formDataObject } from "../../../helper";
import { format } from "date-fns";
import removeNullProperties from "../../../utils/RemoveNullProperties";
import useNotifier from "../../../hooks/useNotifier";

export default function RegulationFormModal({
  schedule,
  handleClose,
  isConfirm = false,
}) {
  const { getCids } = useCids();
  const { getCompanies, getCompanyByID } = useCompany();
  const { updateRegulation } = useRegulation();
  const queryClient = useQueryClient();
  const notify = useNotifier();

  const regulationFormMutation = useMutation(updateRegulation);

  async function handleCodeAndDescriptionSearch({ filters, ...params }) {
    let id, describe;

    if (/\d/.test(filters)) {
      id = filters;
    } else {
      describe = filters;
    }

    const customParams = {
      id,
      describe,
      ...params,
      patient_id: schedule.patient?.id
    };

    const cidsData = await getCids(customParams);

    cidsData.items = cidsData?.items.map(({ description, id, ...cids }) => ({
      description: `${id ? id : ""} ${description}`,
      id,
      ...cids,
    }));

    return cidsData;
  }

  const cidByIdQuery = useQuery(
    ["cidsById", schedule.cid_id],
    () =>
      handleCodeAndDescriptionSearch({
        filters: schedule.cid_id,
      }),
    { retry: false, enabled: !!schedule.cid_id }
  );

  const companyQuery = useQuery(
    ["company", schedule?.company_id],
    () => getCompanyByID(schedule.company_id),
    {
      enabled: !!schedule?.company_id,
      retry: false,
    }
  );

  const initialValues = {
    id: schedule.id,
    name: schedule.patient?.social_prioritize
      ? schedule.patient.social_name
      : schedule.patient.name,
    sus_card: schedule.patient.sus_card,
    birth_date: schedule.patient.birth_date,
    createdAt: schedule.createdAt,
    specialty_describre: schedule?.specialty?.describe
      ? schedule?.specialty?.describe
      : schedule?.procedure?.name
      ? schedule?.procedure?.name
      : "",
    cid:
      isConfirm && !!cidByIdQuery.data?.items
        ? cidByIdQuery.data?.items[0]
        : null,
    requested_employee_name:
      isConfirm && schedule?.requested_employee_name
        ? schedule?.requested_employee_name
        : null,
    company_id: isConfirm && !!companyQuery.data ? companyQuery.data : null,
    external_company: "",
    isExternalCompany: schedule?.external_company ? true : false,
    schedule_type:
      isConfirm && schedule?.schedule_type ? schedule?.schedule_type : "",
    hour: null,
    attachment:
      isConfirm && schedule.attached_document
        ? schedule.attached_document
        : null,
    scheduling_date: null,
    notes: schedule?.notes || "",
  };

  const validations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
    birth_date: Yup.date().required("É requerido"),
    createdAt: Yup.date().required("É requerido"),
    attachment: Yup.array().nullable(),
    scheduling_date: Yup.date()
      .nullable()
      .when("attachment", (attachment, schema) => {
        return isConfirm ? schema.required("É requerido") : schema;
      }),
    specialty_describre: Yup.string().required("É requerido"),
    cid: Yup.object()
      .nullable()
      .when("attachment", (attachment, schema) => {
        return isConfirm ? schema.required("É requerido") : schema;
      }),
    requested_employee_name: Yup.string()
      .nullable()
      .when("attachment", (attachment, schema) => {
        return isConfirm ? schema.required("É requerido") : schema;
      }),
    isExternalCompany: Yup.boolean(),
    company_id: Yup.object()
      .nullable()
      .when("isExternalCompany", (isExternalCompany, schema) => {
        return !isExternalCompany && isConfirm
          ? schema.required("É requerido")
          : schema;
      }),
    external_company: Yup.string()
      .nullable()
      .when("isExternalCompany", (isExternalCompany, schema) => {
        return isExternalCompany && isConfirm
          ? schema.required("É requerido")
          : schema;
      }),
    schedule_type: Yup.string().when("attachment", (attachment, schema) => {
      return isConfirm ? schema.required("É requerido") : schema;
    }),
    hour: Yup.string()
      .nullable()
      .when("attachment", (attachment, schema) => {
        return isConfirm ? schema.required("É requerido") : schema;
      }),
  });

  const handleSubmit = (values) => {
    const dataToSend = !isConfirm
      ? {
          schedule_type: values.schedule_type,
          company_id:
            values.company_id && !values.isExternalCompany
              ? values.company_id.id
              : null,
          external_company:
            values.external_company && values.isExternalCompany
              ? values.external_company
              : null,
          attachments: values.attachment ? values.attachment[0] : null,
          cid_id: values.cid ? values.cid.id : null,
          hour: values.hour ? format(values.hour, "HH:mm:ss") : null,
          scheduling_date: values.scheduling_date,
          requested_employee_name: values.requested_employee_name,
        }
      : {
          schedule_type: values.schedule_type,
          company_id:
            values.company_id && !values.isExternalCompany
              ? values.company_id.id
              : null,
          external_company:
            values.external_company && values.isExternalCompany
              ? values.external_company
              : null,
          attachments:
            values.attachment[0] instanceof File ? values.attachment[0] : null,
          cid_id: values.cid.id,
          hour: values.hour ? format(values.hour, "HH:mm:ss") : null,
          scheduling_date: values.scheduling_date,
          requested_employee_name: values.requested_employee_name,
        };

    removeNullProperties(dataToSend);

    const formDataToSend = formDataObject(dataToSend);

    regulationFormMutation.mutate(
      { id: values.id, data: formDataToSend },
      {
        onSuccess: () => {
          handleClose(false);
          queryClient.invalidateQueries(["regulations"]);
          notify("Agendamento solicitado com sucesso", "success");
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ ...initialValues }}
        validationSchema={validations}
      >
        {({ values, resetForm }) => (
          <Form>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "flex-start",
                padding: 2,
              }}
            >
              <Grid container xs={8} spacing={2}>
                <Grid xs={12} item>
                  <FormTextField name="name" label="Nome" disabled required />
                </Grid>
                <Grid xs={12} item>
                  <FormTextField
                    name="sus_card"
                    label="Cartão SUS"
                    disabled
                    required
                  />
                </Grid>
                <Grid xs={12} item>
                  <FormTextField
                    name="specialty_describre"
                    label="Especialidade/Procedimento"
                    disabled
                    required
                  />
                </Grid>
                {!values.isExternalCompany ? (
                  <Grid xs={12} item>
                    <FormSelectWithPaginate
                      service={(params) =>
                        getCompanies({ ...params, show_siblings: true })
                      }
                      fields="name"
                      name={"company_id"}
                      label={"Unidade"}
                    />
                  </Grid>
                ) : (
                  <Grid xs={12} item>
                    <FormTextField name="external_company" label="Unidade" />
                  </Grid>
                )}
                <Grid xs={12} item>
                  <FormCheckbox
                    name="isExternalCompany"
                    label="Unidade Externa"
                  />
                </Grid>
                <Grid xs={12} item>
                  <Grid item xs={12}>
                    <FormSelectWithPaginate
                      service={handleCodeAndDescriptionSearch}
                      fields="description"
                      disablePortal={false}
                      label={"Cid"}
                      name="cid"
                      searchBy="filters"
                      maxItems={7}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} item>
                  <FormTextField
                    name="notes"
                    label="Descrição"
                    multiline
                    minRows={5}
                    maxRows={5}
                    required
                    disabled
                  />
                </Grid>
                <Grid xs={12} item>
                  <FormTextField
                    name="requested_employee_name"
                    label="Profissional"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormRadio
                    required
                    name="schedule_type"
                    legend="Tipo de Agenda"
                    radios={[
                      { label: "CROSS", value: "cross" },
                      {
                        label: "SIGA",
                        value: "siga",
                      },
                    ]}
                    keys={["label", "value"]}
                    row
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  <FormFile
                    name="attachment"
                    label="Anexar PDF"
                    typesAccepted={[".pdf"]}
                  />
                </Grid>
              </Grid>
              <Grid container xs={4} spacing={2}>
                <Grid item xs={12}>
                  <FormDate
                    name="birth_date"
                    label="Data de Nascimento"
                    disabled
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormDate
                    name="createdAt"
                    label="Data da Solicitação"
                    disabled
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormDate
                    name="scheduling_date"
                    label="Data da Consulta"
                    minDate={new Date()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTimePicker name="hour" label="Horário" required />
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent={"flex-end"}>
              <Grid item xs={2}>
                <Button onClick={resetForm}>Limpar Dados</Button>
              </Grid>
              <Grid item xs={2}>
                <SubmitButton loading={regulationFormMutation.isLoading}>
                  Agendar
                </SubmitButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
