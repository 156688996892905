import React from "react";
import { BoxContainer } from "../../components";
import PaginatedTable from "../../components/Table/PaginatedTable";
import { Dialog, Loading } from "../../helper";
import { useTeam } from "../../service";
import TeamForm from "./TeamForm";
import TeamFeedback from "../../helper/TeamFeedback";
import { useQuery, useQueryClient } from "react-query";
import { Box } from "@mui/system";
import { Button, TablePagination } from "@mui/material";
import usePermissions from "../../config/usePermissions";

function TeamList() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [registerTeam, setRegisterTeam] = React.useState(false);
  const [displayTeam, setDisplayTeam] = React.useState(false);
  const [typeTeamForm, setTypeTeamForm] = React.useState("register");
  const [teams, setTeams] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [team, setTeam] = React.useState();
  const { getTeam } = React.useMemo(useTeam, []);
  const queryClient = useQueryClient();
  const permission = usePermissions("/program/team");

  // Request
  const teamQuery = useQuery(
    ["team", [page, limit]],
    () => getTeam({ page, limit }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (response) => {
        setTeams(response.items);
        const formatedTeam = response.items.map(({ number, name, members }) => {
          const hasLeader = members.some((member) => !!member.leader);
          const leaderName = hasLeader
            ? members.find((member) => member.leader).employee.name
            : "Não informado";
          return {
            number,
            name,
            leader: leaderName,
          };
        });

        setRows(formatedTeam);
      },
    }
  );

  // Handle Functions

  function handleClose() {
    setRegisterTeam(false);
    setDisplayTeam(false);
    queryClient.invalidateQueries("team");
  }

  const tableColumns = [
    { name: "Código" },
    { name: "Equipe" },
    { name: "Líder" },
  ];

  if (teamQuery.isLoading) return <Loading />;

  return (
    <>
      <BoxContainer title={"Lista de Equipes"}>
        <Dialog
          open={registerTeam}
          title={
            typeTeamForm === "register"
              ? "Cadastro de Equipe"
              : "Edição de Equipe"
          }
          handleClose={handleClose}
        >
          <TeamForm type={typeTeamForm} team={team} handleClose={handleClose} />
        </Dialog>
        <Dialog
          open={displayTeam}
          title={"Visualização de Equipe"}
          handleClose={handleClose}
        >
          <TeamFeedback data={team} />
        </Dialog>
        <PaginatedTable
          data={rows}
          columns={tableColumns}
          loading={teamQuery.isRefetching}
          actions
          actionsTypes={["view", "edit"]}
          disableActions={(_, action) =>
            action === "edit" && !permission.update
          }
          actionHandleEdit={(i) => {
            setTeam(teams[i]);
            setRegisterTeam(true);
            setTypeTeamForm("edit");
          }}
          actionHandleView={(i) => {
            setTeam(teams[i]);
            setDisplayTeam(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={teamQuery.data?.totalItems || 0}
            component="div"
            page={page || 0}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          disabled={!permission.create}
          onClick={() => {
            setTeam({});
            setRegisterTeam(true);
            setTypeTeamForm("register");
          }}
        >
          Novo Cadastro
        </Button>
      </BoxContainer>
    </>
  );
}

export default TeamList;
