import { styled } from "@mui/material/styles";
import HighlightOffOutlined from "@mui/icons-material/HighlightOffOutlined";

const DeleteIcon = styled(HighlightOffOutlined)(({ theme }) => ({
  "&:hover": {
    color: "#FF7777",
  },
  transition: "0.3s",
  position: "absolute",
  top: 13,
  right: 15,
  width: 26,
  height: 26,
  cursor: "pointer",
  color: theme.palette.primary.light,
}));

export default DeleteIcon;
