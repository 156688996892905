import { Grid, Stack, Box } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import Yup from "../../../config/yup";
import { SubmitButton, FormSelectField } from "../../../components";
import { pt } from "yup-locale-pt";
import { Loading } from "../../../helper";
import { useServices } from "../../../service";
import instance from "../../../config/api";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { header, title, footer } from "../../../pdfModels/pdfComponents";
import useNotifier from "../../../hooks/useNotifier";

Yup.setLocale(pt);

export default function ServiceFormRegister() {
  const [loadingButton, setLoadingButton] = React.useState(false);
  const notify = useNotifier();
  const [loading, setLoading] = React.useState(false);
  const { getServices } = useServices();
  const [servicoArray, setServicoArray] = React.useState([]);

  React.useEffect(() => {
    async function fetchServices() {
      try {
        setLoading(true);
        const response = await getServices();
        if (response) {
          setServicoArray(response);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        notify(err.message, "error");
      }
    }

    fetchServices();
  }, []);

  const initialValues = {
    printOrder: null,
    servicos: [],
  };
  const validations = Yup.object({
    printOrder: Yup.string().required("É requerido").nullable(),
  });

  async function handleSubmit(values) {
    setLoadingButton(true);
    setTimeout(() => {
      setLoadingButton(false);
    }, 500);

    try {
      setLoading(true);
      const response = await instance.post(`/reports/services`, {
        order_by: values.printOrder,
      });

      values.printOrder =
        values.printOrder === "service_number"
          ? "Ordem Númerica"
          : "Ordem Alfabética";

      if (response.data && response.data.length) {
        pdfAttendances(response.data, values);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      notify(err.message, "error");
    }
  }

  function pdfAttendances(arrayObject, values) {
    let array = [];
    if (!!arrayObject) {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      const filtro = [
        {
          table: {
            headerRows: 1,
            widths: ["*"],
            body: [
              [
                {
                  text: "Ordem de agrupamento: " + values.printOrder,
                  fontSize: 8,
                  alignment: "center",
                  margin: [0, 5, 0, 5],
                },
              ],
            ],
          },
          layout: "noBorders",
        },
      ];

      const dados = arrayObject.map((cliente) => {
        return [
          { text: cliente?.number, fontSize: 8, margin: [0, 1, 0, 1] },
          { text: cliente?.describe, fontSize: 8, margin: [0, 1, 0, 1] },
        ];
      });

      const details = [
        {
          table: {
            style: "tableExample",
            headerRows: 1,
            widths: [27, "*"],

            body: [
              [
                {
                  text: "Código",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
                {
                  text: "Serviços",
                  style: "tableHeader",
                  fontSize: 8,
                  bold: true,
                  border: [false, false, false, true],
                },
              ],

              ...dados,
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              //horizontal
              return i === 1 || i === node.table.body.length ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              //vertical
              return i === 0 || i === node.table.widths.length ? 2 : 2;
            },
            hLineColor: function (i, node) {
              return i === 1 || i === node.table.body.length ? "black" : "gray";
            },

            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? "white"
                : "white";
            },
          },
        },
      ];

      var total = [
        {
          text: "Total de Atendimentos:" + " " + dados.length,
          fontSize: 8,
          bold: true,
          margin: [10, 10, 0, 15],
        },
      ];

      const docDefinitios = {
        pageMargins: [40, 105, 40, 95],
        defaultStyle: {
          fontSize: 10,
        },
        ...header(),
        footer: footer(),
        content: [
          title("Relatório Atendimento Por serviço"),
          filtro,
          details,
          total,
        ],
      };

      pdfMake.createPdf(docDefinitios).print();
    } else {
      return array;
    }
  }

  return (
    <>
      {!loading ? (
        <>
          <Formik
            initialValues={{ ...initialValues }}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ resetForm, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={2} sx={{ marginTop: 2.5 }}>
                  <Grid item xs={8}>
                    <FormSelectField
                      name="printOrder"
                      label="Ordem de impressão"
                      options={{
                        service_describe: "Ordem Alfabética(A - Z)",
                        service_number: "Ordem Numérica(1, 2, 3 ...) ",
                      }}
                      required
                    />
                  </Grid>
                </Grid>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "right",
                    justifyContent: "right",
                    pt: 2,
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <SubmitButton loading={loadingButton}>
                      Gerar PDF/Impressão
                    </SubmitButton>
                  </Stack>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
