import React from "react";
import { Typography, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

const ProgramHistoryCard = ({ data }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        background: theme.palette.primary.medium,
        padding: 2,
        borderRadius: 2,
      }}
      spacing={0}
    >
      <Grid item md={10} xs={8}>
        <Typography
          sx={{
            fontSize: 14,
            color: theme.palette.primary.main,
          }}
        >
          {format(parseISO(data.createdAt), "dd, MMMM yyyy", { locale: ptBR })}
        </Typography>
      </Grid>
      <Grid item md={10} xs={8}>
        <Typography
          sx={{
            fontSize: 14,
            color: theme.palette.primary.main,
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              color: theme.palette.primary.main,
            }}
            variant="span"
          >
            Nome:{" "}
          </Typography>
          {data.name}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography
          sx={{
            fontSize: 14,
            color: theme.palette.primary.main,
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              color: theme.palette.primary.main,
            }}
            variant="span"
          >
            Inserido por:{" "}
          </Typography>
          {data.inserted_by_employee.name}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProgramHistoryCard;
