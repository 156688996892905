import {
  Box,
  Typography,
  CardHeader,
  CardContent,
  Card,
  Avatar,
  Chip,
  Tooltip,
  Grid,
  Button,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React from "react";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { formatWithMask } from "../config/mask";
import RegexOf from "../config/regex";
import convertDateTobirthday from "../utils/convertDateToBirthday";

function ProviderFeedback({ data, button }) {
  const [copiedText, setCopiedText] = React.useState("");
  const notInformed = "Não informado";
  const county = data?.contact?.county;
  const state = data?.contact?.state;

  const locality =
    county && state ? `${county} - ${state}` : county || state || notInformed;

  const navigate = useNavigate();
  return (
    <>
      <>
        <Card
          sx={{
            border: "3px solid transparent",
            borderColor: "white",
            borderWidth: "3px",
            boxShadow: "0 0 0 1.5px #FFFFFF",
          }}
        >
          <CardHeader
            title={
              <CopyToClipboard
                text={data?.number}
                onCopy={() => setCopiedText(data?.number)}
              >
                <Tooltip
                  title={copiedText === data?.number ? "Copiado!!" : "Copiar"}
                  color="primary"
                  placement="top"
                >
                  <Chip
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: (theme) => theme.palette.primary.main,
                        }}
                      >
                        <Typography color="White" fontSize={12}>
                          {data && data?.name?.slice(0, 2).toUpperCase()}
                        </Typography>
                      </Avatar>
                    }
                    label={`ID - # ${data?.number}`}
                    variant="outlined"
                    color="primary"
                    clickable
                  />
                </Tooltip>
              </CopyToClipboard>
            }
          ></CardHeader>
          <Typography
            variant="h1"
            color="gray"
            fontWeight="700"
            fontSize={12}
            sx={{ ml: 2, mb: 1 }}
          >
            #1 DADOS PESSOAIS
          </Typography>
          <Typography
            variant="p"
            color="secondary"
            fontWeight="500"
            fontSize={15}
            sx={{ ml: 2 }}
          >
            {data?.name}
          </Typography>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", mt: "-10px" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={3}
                sx={{
                  marginTop: 2,
                  borderRight: (theme) =>
                    `1px solid ${theme.palette.primary.main}`,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Nome Social
                      </Typography>{" "}
                      : {data?.social_name || notInformed}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Estado Civil
                      </Typography>{" "}
                      : {data?.marital_status || notInformed}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Sexo
                      </Typography>{" "}
                      : {data?.genre || notInformed}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Raça
                      </Typography>{" "}
                      : {data?.race || notInformed}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Idade
                      </Typography>{" "}
                      :{" "}
                      {data?.birth_date
                        ? convertDateTobirthday(data?.birth_date)
                        : notInformed}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Data de Nascimento
                      </Typography>{" "}
                      :{" "}
                      {data?.birth_date
                        ? format(parseISO(data?.birth_date), "dd/MM/yyyy")
                        : notInformed}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        RG{" "}
                      </Typography>{" "}
                      : {data?.ident_national || notInformed}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Orgão Emissor
                      </Typography>{" "}
                      : {data?.issuing_body || notInformed}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        {data?.physic_national ? "CPF " : "CNPJ "}
                      </Typography>
                      : {data?.physic_national || data?.cnpj}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        CNS{" "}
                      </Typography>
                      : {!!data.sus_card ? data.sus_card : notInformed}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  marginTop: 2,
                  borderRight: (theme) =>
                    `1px solid ${theme.palette.primary.main}`,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {" "}
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Nacionalidade
                    </Typography>{" "}
                    : {data?.nationality || notInformed}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Naturalidade
                    </Typography>{" "}
                    : {data?.naturality || notInformed}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      CEP
                    </Typography>{" "}
                    :{" "}
                    {data?.contact?.cep
                      ? formatWithMask({
                          text: data?.contact?.cep,
                          mask: RegexOf.cep,
                        }).masked
                      : notInformed}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Número
                    </Typography>{" "}
                    : {data?.contact?.address_number || notInformed}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Bairro
                    </Typography>{" "}
                    : {data?.contact?.district || notInformed}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Logradouro
                    </Typography>{" "}
                    : {data?.contact?.street || notInformed}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Localidade
                    </Typography>{" "}
                    : {locality}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  marginTop: 2,
                  borderRight: (theme) =>
                    `1px solid ${theme.palette.primary.main}`,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="h1"
                    color="gray"
                    fontWeight="700"
                    fontSize={12}
                    sx={{ mb: 1.5 }}
                  >
                    #2 DADOS PROFISSIONAIS
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Nome de Acesso
                    </Typography>{" "}
                    : {data?.user_login}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Status
                    </Typography>{" "}
                    : {data?.active ? "Ativo" : "Inativo"}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Especialidades:
                    </Typography>{" "}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    {data?.specialties?.length
                      ? data.specialties.map(
                          (specialty) => `>${specialty?.describe}\n`
                        )
                      : "Não Informado"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} sx={{ marginTop: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="h1"
                    color="gray"
                    fontWeight="700"
                    fontSize={12}
                    sx={{ mb: 1.5 }}
                  >
                    #3 DADOS DE CONTATO
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Telefone
                    </Typography>{" "}
                    :{" "}
                    {data?.contact?.phone
                      ? formatWithMask({
                          text: data?.contact?.phone,
                          mask: RegexOf.telephone,
                        }).masked
                      : notInformed}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Telefone Celular
                    </Typography>{" "}
                    :{" "}
                    {data?.contact?.cell_phone
                      ? formatWithMask({
                          text: data?.contact?.cell_phone,
                          mask: RegexOf.phone,
                        }).masked
                      : notInformed}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Email
                    </Typography>{" "}
                    : {data?.contact?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  borderRight: (theme) =>
                    `1px solid ${theme.palette.primary.main}`,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: "50px",
                display: "flex",
                gap: "5px",
              }}
            >
              {!!button && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    navigate("/schedule/employee", { replace: true });
                  }}
                >
                  Exibir lista de profissionais
                </Button>
              )}
            </Box>
          </CardContent>
        </Card>
      </>
    </>
  );
}

export default ProviderFeedback;
