import { Close } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  SwipeableDrawer as MUIDrawer,
  Typography,
} from "@mui/material";

export default function Drawer({ open, handleClose, title ,children}) {
  return (
    <MUIDrawer
      anchor={"right"}
      elevation={8}
      sx={{ maxWidth: "0px", zIndex: 1300 }}
      hideBackdrop
      open={open}
    >
      <Box>
        <Box
          sx={{
            minHeight: "79px",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            display: "flex",
            fontSize: "1.25rem"
          }}
        >
          <Typography flex={1} fontSize={"1.25rem"} color={"primary.light"} textAlign={"center"}>
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close color="primary" />
          </IconButton>
        </Box>
        <Divider />
        <Box padding={'10px'}>
          {children}
        </Box>
      </Box>
    </MUIDrawer>
  );
}
