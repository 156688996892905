import { Box } from "@mui/material";
import { BoxContainer } from "../../components";

export default function CockPitPrevine() {
  return (
    <BoxContainer title={"Indicadores Previne"}>
      <Box width={"100%"} height={"84vh"}>
        <iframe
          title="Cockpit Previne"
          src={
            "https://lookerstudio.google.com/embed/reporting/98bc7c75-757c-4a56-a86b-d4cba2e128d9/page/iQYzD"
          }
          width={"100%"}
          height={"100%"}
          loading="eager"
          allowfullscreen
          style={{
            border: 0,
          }}
        />
      </Box>
    </BoxContainer>
  );
}
