import TeethArrayObject, { archViewBox, defaultArch } from "./TeethArrayObject";
import Tooth from "./Tooth";
import ToothFaces from "./ToothFaces";

export default function BottomArch({
  handleCrownClick,
  handleRootClick,
  handleFaceClick,
  teethWithValue,
  type,
  size,
  disabled,
  disableFaces,
  svg,
}) {
  return (
    <>
      {!svg && (
        <svg
          width={size}
          viewBox={archViewBox.faces}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="@BottomArcadaFaces">
            {TeethArrayObject?.[type]?.bottom?.map((item, index) => (
              <ToothFaces
                position={{ cx: 42 * index }}
                handleFaceClick={handleFaceClick}
                tooth_id={item.id}
                values={teethWithValue?.[item.id]?.faces}
                key={index}
                disabled={disabled}
                disableClick={disableFaces}
                bottom
              />
            ))}
          </g>
        </svg>
      )}
      <svg
        width={size}
        viewBox={archViewBox[type]}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="@BottomArcada">
          {TeethArrayObject?.[type]?.bottom?.map((item, index) => (
            <Tooth
              onCrownClick={handleCrownClick}
              onRootClick={handleRootClick}
              dCrown={item.dCrown}
              dRoot={item.dRoot}
              id={item.id}
              strokeWidth={item.strokeWidth}
              key={index}
              badgePosition={item.badgePosition}
              hasEvolutions={teethWithValue?.[item.id]}
              disabled={disabled}
              svg={svg}
            />
          ))}
        </g>
      </svg>
    </>
  );
}
