import { Button, TablePagination } from "@mui/material";
import { Box } from "@mui/system";
import { parseInt } from "lodash";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Dialog } from "../../../helper";
import useMonitoring from "../../../service/useMonitoring";
import { PatientInfo } from "../PatientInfo";
import PatientListTable from "./PatientListTable";
import ProceduresTable from "../Procedures/ProceduresTable";
import EvolutionList from "./Evolution/EvolutionList";
import useInterventions from "../../../service/useInterventions";
import useNotifier from "../../../hooks/useNotifier";

export default function ProcedurePatientList({ patientId }) {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [selectedMonitoring, setSelectedMonitoring] = React.useState(0);
  const [limitOfProcedures, setLimitOfProcedures] = React.useState(10);
  const [openEvolutionWithAttendance, setOpenEvolutionWithAttendance] =
    React.useState(false);
  const [proceduresPage, setProceduresPage] = React.useState(0);
  const [searchFilter, setSearchFilter] = React.useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const queryClient = useQueryClient();
  const { getMonitorings, updateWorkList } = useMonitoring();
  const { getInterventions, updateInterventions } = useInterventions();
  const notify = useNotifier();
  const updateWorkListStatusMutation = useMutation(updateWorkList);

  const updateWith = async (_, item, toSend, value = true) => {
    await updateInterventions(item.id, {
      done: value,
    });
    proceduresFromPatient.refetch();
  };
  const proceduresPatientsList = useQuery(
    ["proceduresPatientsList", page, limit, patientId],
    () =>
      getMonitorings({
        page,
        limit,
        work_list_status: patientId ? "finished" : "unfinished",
        ...searchFilter,
        type: "procedure",
        id_patient: patientId,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {
        queryClient.setQueriesData("proceduresPatientsList", []);
      },
    }
  );

  const proceduresFromPatient = useQuery(["proceduresFromPatient"], () => {
    if (selectedMonitoring)
      return getInterventions({
        page: proceduresPage,
        limit: limitOfProcedures,
        patient_id: selectedMonitoring?.attendance?.patient?.id,
        attendance_id: selectedMonitoring?.attendance?.id,
      });
  });

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedMonitoring();
    proceduresPatientsList.refetch();
  };

  const updateWorkListStatus = async (attendanceID, to = "finished") => {
    updateWorkListStatusMutation.mutate(
      { attendanceID, changes: { procedure: to } },
      {
        onSuccess: () => {
          proceduresPatientsList.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  };

  React.useEffect(() => {
    proceduresFromPatient.refetch();
  }, [selectedMonitoring, proceduresPage, limitOfProcedures]);

  React.useEffect(() => {
    proceduresPatientsList.refetch();
  }, [searchFilter]);

  return (
    <Box sx={{ pt: 5 }}>
      <Dialog
        title={"Procedimentos"}
        open={openEvolutionWithAttendance}
        handleClose={() => {
          setOpenEvolutionWithAttendance(false);
          proceduresPatientsList.refetch();
        }}
      >
        <PatientInfo attendance={selectedMonitoring?.attendance} />
        <EvolutionList
          title={"Procedimentos"}
          patient={selectedMonitoring?.attendance?.patient}
          attendance={selectedMonitoring?.attendance}
          formType={"procedure"}
          needProcedure={!selectedMonitoring?.has_plan}
          activeActions={!patientId}
        />
      </Dialog>
      <Dialog title={"Prescrições"} open={openDialog} handleClose={handleClose}>
        <PatientInfo attendance={selectedMonitoring?.attendance} />
        <Box sx={{ paddingBottom: 1, display: "flex", justifyContent: "end" }}>
          <Button
            onClick={() => setOpenEvolutionWithAttendance(true)}
            variant="contained"
          >
            Responder
          </Button>
        </Box>
        <ProceduresTable
          procedures={proceduresFromPatient.data?.items}
          check={updateWith}
          activeActions={!patientId}
        />
        <TablePagination
          count={proceduresFromPatient.data?.totalItems || 1}
          component="div"
          page={proceduresPage}
          onPageChange={(_, newPage) => {
            setProceduresPage(newPage);
          }}
          rowsPerPage={limitOfProcedures}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimitOfProcedures(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Dialog>
      <Button
        variant="contained"
        sx={{
          marginBottom: "10px",
        }}
        disabled={proceduresPatientsList.isLoading}
        onClick={() => {
          notify("Lista Atualizada");
          proceduresPatientsList.refetch();
        }}
      >
        Atualizar
      </Button>
      <PatientListTable
        data={proceduresPatientsList.data?.items}
        error={proceduresPatientsList.failureCount}
        loading={proceduresPatientsList.isFetching}
        noOutstanding={true}
        actionsTypes={patientId ? ["checklist", "response"] : undefined}
        callType={"procedure"}
        {...{
          page,
          limit,
          setLimit,
          setPage,
          setOpenDialog,
          setSelectedMonitoring,
          setOpenEvolutionWithAttendance,
          updateWorkListStatus,
          setSearchFilter,
        }}
        totalItems={proceduresPatientsList.data?.totalItems}
      />
    </Box>
  );
}
