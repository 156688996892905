import React from "react";
import { Box, Typography, CardContent, Card, Grid } from "@mui/material";
import { format, parseISO } from "date-fns";

function ImmunizingFeedback({ data }) {
  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
        }}
      >
        <Typography
          variant="h1"
          color="gray"
          fontWeight="700"
          fontSize={12}
          sx={{ ml: 2, mb: 1 }}
        >
          #1 DADOS DO LOTE
        </Typography>
        <Typography
          variant="p"
          color="secondary"
          fontWeight="600"
          fontSize={15}
          sx={{ ml: 2 }}
        >
          {data?.vaccine.describe}
        </Typography>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", mt: "-15px" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Cód. Lote
                    </Typography>{" "}
                    : {data?.number}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Lote
                      </Typography>{" "}
                      : {data?.code}
                    </Typography>
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Fabricante
                    </Typography>{" "}
                    {data?.manufacturer.describe}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Quantidade
                    </Typography>{" "}
                    : {data?.amount}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Status
                    </Typography>{" "}
                    : {data?.active === true ? "Ativo" : "Inativo"}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Data de Validade
                    </Typography>{" "}
                    : {format(parseISO(data?.expiration_date), "dd/MM/yyyy")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default ImmunizingFeedback;
