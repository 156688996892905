import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
import { handleParam } from "../utils/handleParam";
const path = "/cids";

function useCids() {
  async function getCids({ fieldValue, ...params }) {
    try {
      const param = handleParam(fieldValue, {
        id: /\d/,
        describe: /.+/,
      });

      const response = await instance.get(path, {
        params: urlQuery({ active: true, ...param, ...params }),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getCids,
  };
}
export default useCids;
