import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FaBullseye } from "react-icons/fa";

function ToggleStatus({ initial, onChange, canChange }) {
  const [status, setStatus] = useState(initial);
  const [isFirst, setIsFirst] = useState(true);

  const handleStatus = (newStatus) => {
    if (!canChange) return;

    setStatus(newStatus);
  };
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
      return;
    }
    if (onChange instanceof Function) onChange(status);
  }, [status]);

  const theme = useTheme();
  const StatusBadge = ({ children, active, onClick }) => {
    return (
      <Typography
        component="span"
        onClick={onClick}
        sx={{
          background: active ? theme.palette.primary.light : "trasparent",
          padding: "1px 5px",
          borderRadius: "5px",
          cursor: "pointer",
          userSelect: "none",
          color: !active ? theme.palette.primary.light : "white",
        }}
      >
        {children}
      </Typography>
    );
  };
  return (
    <Box sx={{ display: "flex", columnGap: "5px" }}>
      <StatusBadge onClick={() => handleStatus(true)} active={status}>
        {status ? "Ativo" : "Ativar"}
      </StatusBadge>
      <StatusBadge onClick={() => handleStatus(false)} active={!status}>
        {!status ? "Inativo" : "Desativar"}
      </StatusBadge>
    </Box>
  );
}

export default ToggleStatus;
