import React, { useContext } from "react";
import { createPortal } from "react-dom";
import {
  HIDE_SIGNATURE_DIALOG,
  SignaturePasswordContext,
} from "../contexts/SignaturePasswordContext";
import SignaturePassword from "./SignaturePassword";

const GlobalSignaturePassword = () => {
  const [signatureState, dispatch] = useContext(SignaturePasswordContext);

  const portalElement = document.getElementById("portal");
  const component = signatureState.show ? (
    <SignaturePassword
      open={signatureState.show}
      handleClose={() => dispatch(HIDE_SIGNATURE_DIALOG)}
    />
  ) : null;

  return createPortal(component, portalElement);
};
export default GlobalSignaturePassword;
