import React from "react";
import Box from "@mui/material/Box";
import { BoxContainer, Tabs } from "../../../components";
import ServiceBySpecialtyFormPeriod from "./ServiceBySpecialtyFormPeriod";
import PatientsByPrograms from "./PatientsByPrograms";
import PatientsXComorbidities from "./PatientsXComorbidities";

export default function ReportStatistical() {
  const tabs = [
    {
      label: "Atendimento Por Especialidade",
      content: <ServiceBySpecialtyFormPeriod />,
    },
    { label: "Pacientes por Programas", content: <PatientsByPrograms /> },
    { label: "Pacientes X Comorbidades", content: <PatientsXComorbidities /> },
  ];
  return (
    <BoxContainer title={"Relatórios estatísticos"} sx={{ width: "100%" }}>
      <Tabs tabs={tabs} />
    </BoxContainer>
  );
}
