import React from "react";
import { Box, Typography } from "@mui/material";

function HasNoPermission({position = "absolute"}) {
  return (
    <Box
      sx={{
        width: "100%",
        position: position,
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        color={"primary"}
        sx={{ fontSize: "4rem", fontFamily: "Montserrat,sans-serif" }}
      >
        401
      </Typography>
      <Typography
        sx={{ fontSize: "1.2em", fontFamily: "Montserrat,sans-serif" }}
      >
        Você não tem permissão para acessar esta tela.
      </Typography>
    </Box>
  );
}

export default HasNoPermission;
