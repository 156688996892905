import { useState } from "react";
import { useForm } from "react-hook-form";

export default function useTableChecks(data, page, identifier) {
  const [parsedPages, setParsedPages] = useState([]);
  const checkController = useForm({
    defaultValues: {
      checksData: [],
    },
  });

  const [checksData] = checkController.watch(["checksData"]);

  function handleCheckAll() {
    if (parsedPages.includes(page)) {
      const valuesToRemove = data.map((patient) => patient[identifier]);
      const newValues = checksData.filter(
        (value) => !valuesToRemove.includes(value)
      );

      checkController.setValue("checksData", newValues);
      setParsedPages((curr) =>
        curr.filter((parsedPage) => parsedPage !== page)
      );
    } else {
      const newValues = data.map((patient) => patient[identifier]);

      checkController.setValue("checksData", [
        ...new Set([...newValues, ...checksData]),
      ]);
      setParsedPages((curr) => [...curr, page]);
    }
  }

  return {
    checkController,
    checksData,
    handleCheckAll,
  };
}
