import instance from "../config/api";

export default function useMAB() {
  const DPath = "/mabs";

  async function getMABs(params) {
    try {
      const response = await instance.get(`${DPath}`, { params });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  return { getMABs };
}
