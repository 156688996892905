import React from "react";
import { format, parseISO } from "date-fns";
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Card,
} from "@mui/material";
import useScale from "../../service/useScales";
import { useMutation, useQueryClient } from "react-query";
import ToggleStatus from "../../components/ToggleStatus";
import usePermissions from "../../config/usePermissions";
import { useNotifier } from "../../hooks";

function SchedulingFeedBack({ config }) {
  const notify = useNotifier();
  const [loading, setLoading] = React.useState(false);
  const queryClient = useQueryClient();
  const permission = usePermissions("/schedule/consultation");

  const day = new Date();

  // requests
  const { putScale, updateScheduleStatus } = useScale();
  const putScaleMutation = useMutation(putScale);
  const statusUpdate = useMutation(updateScheduleStatus);

  async function handleUpdateHour(schedule, data, index) {
    const id = schedule.id;
    const scheduleData = {
      schedule: {
        id: data.id,
        hour: data.hour,
      },
    };
    setLoading(data.id);

    putScaleMutation.mutate(
      { data: scheduleData, id },
      {
        onSuccess: (success) => {
          schedule.schedules[index].patient = null;
          setLoading(false);
          notify(success.message, "success");
          queryClient.invalidateQueries("schedules");
        },
        onError: (error) => {
          setLoading(false);
          notify(error.message, "error");
        },
      }
    );
  }

  function statusChange(status, id) {
    statusUpdate.mutate({ id, status });
  }

  return (
    <>
      <Typography
        variant="h1"
        color="gray"
        fontWeight="700"
        fontSize={12}
        sx={{ ml: 2, mb: 1 }}
      >
        INFORMAÇŌES DA AGENDA
      </Typography>
      <Grid container xs={12}>
        <Grid xs={4}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="500"
            fontSize={15}
            sx={{ ml: 2 }}
          >
            <Typography
              fontSize={15}
              color="secondary"
              fontWeight="500"
              component="span"
            >
              Tipo de agenda:
            </Typography>
            {config.regulated ? " Regulada" : " Local"}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="500"
            fontSize={15}
            sx={{ ml: 2 }}
          >
            <Typography
              fontSize={15}
              color="secondary"
              fontWeight="500"
              component="span"
            >
              Data Inicial:{" "}
            </Typography>
            {format(parseISO(config.initial_date), "dd/MM/yyyy")}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="500"
            fontSize={15}
            sx={{ ml: 2 }}
          >
            <Typography
              fontSize={15}
              color="secondary"
              fontWeight="500"
              component="span"
            >
              Data Final:{" "}
            </Typography>
            {format(parseISO(config.final_date), "dd/MM/yyyy")}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="500"
            fontSize={15}
            sx={{
              ml: 2,
              display: "flex",
              columnGap: "15px",
              alignItems: "center",
            }}
          >
            <Typography
              fontSize={15}
              color="secondary"
              fontWeight="500"
              component="span"
            >
              Status da Agenda:{" "}
            </Typography>
            <ToggleStatus
              canChange={permission.update}
              initial={config?.active}
              onChange={(status) => statusChange(status, config?.id)}
            />
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="500"
            fontSize={15}
            sx={{ ml: 2 }}
          >
            <Typography
              fontSize={15}
              color="secondary"
              fontWeight="500"
              component="span"
            >
              Data:{" "}
            </Typography>
            {format(parseISO(config.date), "dd/MM/yyyy")}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography variant="h5" color="primary" fontSize={15} sx={{ ml: 2 }}>
            <Typography
              fontSize={15}
              color="secondary"
              fontWeight="500"
              component="span"
            >
              Horário Inicial:{" "}
            </Typography>
            {config.start_time}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography variant="h5" color="primary" fontSize={15} sx={{ ml: 2 }}>
            <Typography
              fontSize={15}
              color="secondary"
              fontWeight="500"
              component="span"
            >
              Horário Final:{" "}
            </Typography>
            {config.end_time}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="500"
            fontSize={15}
            sx={{ ml: 2 }}
          >
            <Typography
              fontSize={15}
              color="secondary"
              fontWeight="500"
              component="span"
            >
              Tempo Médio de Atendimento:{" "}
            </Typography>
            {config.average_time} minutos
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="500"
            fontSize={15}
            sx={{ ml: 2 }}
          >
            <Typography
              fontSize={15}
              color="secondary"
              fontWeight="500"
              component="span"
            >
              Profissional:{" "}
            </Typography>
            {config.employee?.name}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="500"
            fontSize={15}
            sx={{ ml: 2 }}
          >
            <Typography
              fontSize={15}
              color="secondary"
              fontWeight="500"
              component="span"
            >
              Setor:{" "}
            </Typography>
            {config.sector?.describe ? config.sector.describe : "Não consta"}
          </Typography>
        </Grid>
        {config.service_treatment.map((list) => (
          <>
            <Grid xs={4}>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="500"
                fontSize={15}
                sx={{ ml: 2 }}
              >
                <Typography
                  fontSize={15}
                  color="secondary"
                  fontWeight="500"
                  component="span"
                >
                  Serviço:{" "}
                </Typography>
                {list.service.describe}
              </Typography>
            </Grid>
            <Grid xs={4}>
              <Typography
                variant="h5"
                color="primary"
                fontSize={15}
                sx={{ ml: 2 }}
              >
                <Typography
                  fontSize={15}
                  color="secondary"
                  fontWeight="500"
                  component="span"
                >
                  Tipo de atendimento:{" "}
                </Typography>
                {list.treatment_type.describe}
              </Typography>
            </Grid>
          </>
        ))}
        <Grid xs={12} sx={{ marginTop: 3 }}>
          {config.schedules.map((list, i) => (
            <>
              <Grid>
                <Card
                  sx={{
                    marginTop: 1,
                    marginLeft: 2,

                    borderRadius: 2,
                    border: 1,
                    borderColor: "#0899BA",
                    boxShadow: "none",
                  }}
                >
                  <Grid container direction="row">
                    <Grid xs={2}>
                      <Typography
                        color="secondary.main"
                        variant="subtitle2"
                        fontSize={16}
                        fontWeight="bold"
                        sx={{
                          whiteSpace: "nowrap",

                          margin: 1,
                        }}
                      >
                        Horário: {list.hour}
                      </Typography>
                    </Grid>

                    <Grid xs={2}>
                      {list.patient ? (
                        <Typography
                          color="secondary.main"
                          variant="subtitle2"
                          fontSize={16}
                          fontWeight="bold"
                          sx={{
                            whiteSpace: "nowrap",

                            margin: 1,
                          }}
                        >
                          Nome do paciente:{" "}
                          {list?.social_prioritize
                            ? list.patient.social_name
                            : list.patient.name}{" "}
                          {list.confirmed ? (
                            <Typography
                              color="secondary.main"
                              variant="subtitle2"
                              fontSize={16}
                            >
                              Paciente Confirmado
                            </Typography>
                          ) : !list.confirmed &&
                            format(
                              new Date(config.date.replaceAll("-", "/")),
                              "dd/MM/yyyy 00:00:00"
                            ) < format(new Date(day), "dd/MM/yyyy 00:00:00") ? (
                            <Typography
                              color="secondary.main"
                              variant="subtitle2"
                              fontSize={16}
                            >
                              Paciente Faltoso
                            </Typography>
                          ) : format(
                              new Date(config.date.replaceAll("-", "/")),
                              "dd/MM/yyyy 00:00:00"
                            ) < format(new Date(day), "dd/MM/yyyy 00:00:00") ? (
                            <Typography
                              color="secondary.main"
                              variant="subtitle2"
                              fontSize={16}
                            >
                              Paciente Faltoso
                            </Typography>
                          ) : (
                            <Typography
                              color="secondary.main"
                              variant="subtitle2"
                              fontSize={16}
                            >
                              Aguardando Confirmação
                            </Typography>
                          )}
                        </Typography>
                      ) : (
                        <Typography
                          color="secondary.main"
                          variant="subtitle2"
                          fontSize={16}
                          fontWeight="bold"
                          sx={{
                            whiteSpace: "nowrap",
                            margin: 1,
                          }}
                        >
                          Horário Livre
                        </Typography>
                      )}
                    </Grid>
                    <Grid xs={6}></Grid>
                    {
                      <Grid xs={2} key={list.id}>
                        <Grid justifyContent="flex-end">
                          {list.patient ? (
                            <Typography
                              color="secondary.main"
                              variant="subtitle2"
                              fontSize={16}
                              fontWeight="bold"
                              sx={{
                                whiteSpace: "nowrap",

                                margin: 1,
                              }}
                            >
                              {list.patient.confirmed ? (
                                ""
                              ) : !list.confirmed &&
                                format(
                                  new Date(config.date.replaceAll("-", "/")),
                                  "dd/MM/yyyy 00:00:00"
                                ) <
                                  format(
                                    new Date(day),
                                    "dd/MM/yyyy 00:00:00"
                                  ) ? (
                                ""
                              ) : format(
                                  new Date(config.date.replaceAll("-", "/")),
                                  "dd/MM/yyyy 00:00:00"
                                ) <
                                format(new Date(day), "dd/MM/yyyy 00:00:00") ? (
                                ""
                              ) : (
                                <Typography
                                  color="secondary.main"
                                  variant="subtitle2"
                                  fontSize={16}
                                >
                                  {loading !== list.id &&
                                  !list.confirmed &&
                                  permission.update ? (
                                    <Button
                                      key={list.id}
                                      color="secondary"
                                      variant="contained"
                                      sx={{ margin: 1 }}
                                      onClick={() => {
                                        handleUpdateHour(config, list, i);
                                      }}
                                    >
                                      Liberar Horário
                                    </Button>
                                  ) : (
                                    loading === list.id && (
                                      <Button
                                        disabled
                                        sx={{
                                          bgcolor: "rgba(8, 153, 186, 0.1)",
                                          color: "white",
                                        }}
                                      >
                                        <CircularProgress
                                          color="primary"
                                          size={20}
                                          sx={{ mr: 1 }}
                                        />{" "}
                                        Liberando...
                                      </Button>
                                    )
                                  )}
                                </Typography>
                              )}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Card>
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
export default SchedulingFeedBack;
