import axios from "axios";
function useCep() {
  const fetchCep = async (cep) => {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  return {
    fetchCep,
  };
}

export default useCep;
