import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import FormTextField from "./FormTextField";

export const DateTime = ({ ...props }) => {
  return (
    <Tooltip
      title={
        <Box>
          <Typography variant="subtitle1">
            Use o formato: 0y 0m 0w 0d
          </Typography>
          <Stack>
            <Typography variant="subtitle1">* y = anos</Typography>
            <Typography variant="subtitle1">* m = meses</Typography>
            <Typography variant="subtitle1">* w = semanas</Typography>
            <Typography variant="subtitle1">* d = dias</Typography>
            <Typography variant="subtitle1">
              A ordem lógica é importante, y - m - w - d.
            </Typography>
          </Stack>
        </Box>
      }
      placement="bottom-start"
    >
      <Box>
        <FormTextField
          label={props.title}
          name={props.name}
          {...props}
          placeholder="ex: 2y 3m 3w 5d"
        />
      </Box>
    </Tooltip>
  );
};
