import React from "react";
import { Box, Typography, CardContent, Card, Grid } from "@mui/material";
import { format, parseISO } from "date-fns";
import { dateTimeFormater } from "../../../utils";

function CampaignFeedback({ data }) {
  const minimumAge = dateTimeFormater(data.vaccine.minimumAge);
  const maximumAge = dateTimeFormater(data.vaccine.maximumAge);
  const initialDate = data.initialDate
    ? format(parseISO(data.initialDate), "dd/MM/yyyy")
    : "Não Definido";
  const finalDate = data.finalDate
    ? format(parseISO(data.finalDate), "dd/MM/yyyy")
    : "Não Definido";

  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
        }}
      >
        <Typography
          variant="p"
          color="secondary"
          fontWeight="600"
          fontSize={15}
          sx={{ ml: 2 }}
        >
          Campanha - {data.describe}
        </Typography>
        <br />
        <Typography
          variant="p"
          color="secondary"
          fontWeight="600"
          fontSize={15}
          sx={{ ml: 2 }}
        >
          Vacina - {data?.vaccine?.describe}
        </Typography>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", mt: "-15px" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Idade Minima
                    </Typography>{" "}
                    : {minimumAge}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Idade Máxima
                    </Typography>{" "}
                    : {maximumAge}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Status
                    </Typography>{" "}
                    : {data?.status}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Data Inicial
                    </Typography>{" "}
                    : {initialDate}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Data Final
                    </Typography>{" "}
                    : {finalDate}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default CampaignFeedback;
