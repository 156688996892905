import React from "react";
import { Form, Formik } from "formik";
import Yup from "../../../config/yup";
import {
  SubmitButton,
  FormTextField,
  FormFile,
  DownloadButton,
  FormSearchSelectField,
} from "../../../components/Form";
import { Grid, Box } from "@mui/material";
import { againstReferencePDF } from "../../../pdfModels";

const ViewCounterReference = ({ type = "view", reference, patient }) => {
  const handleSubmit = (values, { resetForm }) => {
    resetForm();
  };

  const sector = reference?.requested_specialty
    ? reference.requested_specialty
    : "Não especificado";
  const initialValues = {
    justification: reference?.justification,
    responsible: reference.requestor_name,
    attachment: null,
    response: reference?.response,
    patient: patient?.name,
    requested_specialty: reference.requested_specialty,
    sectorEspecific: sector,
    cids: reference?.cids || [],
  };
  const validations = Yup.object().shape({
    justification: Yup.string().required("É requerido"),
    responsible: Yup.string().required("É requerido").nullable(),
    response: Yup.string().required("É requerido").nullable(),
  });

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
      >
        {({ values: { attachment, ...fields } }) => (
          <Form>
            <Grid container spacing={2} marginTop="0.5rem">
              <Grid item xs={4}>
                <FormTextField
                  name="patient"
                  label="Paciente"
                  required
                  disabled={type === "view"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  name="responsible"
                  label="Realizada por"
                  required
                  disabled={type === "view"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  name="requested_specialty"
                  label="Especialidade Requisitada"
                  required
                  disabled={type === "view"}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  multiline
                  minRows={5}
                  maxRows={5}
                  name="justification"
                  label="Justificativa"
                  required
                  disabled={type === "view"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  name="sectorEspecific"
                  label="Setor"
                  required
                  disabled={type === "view"}
                />
              </Grid>
              <Grid item xs={8}>
                <FormSearchSelectField
                  options={reference?.cids || []}
                  getOptionLabel={(cid) =>
                    cid.description ? `${cid.id}${cid.description}` : ""
                  }
                  multiple={true}
                  disablePortal={false}
                  label={"Cids:"}
                  name="cids"
                  maxItems={7}
                  disabled={type === "view"}
                />
              </Grid>
              <Grid item xs={4}>
                {type === "view" ? (
                  <DownloadButton url="#" fileName="example-download">
                    Anexo
                  </DownloadButton>
                ) : (
                  <FormFile name="attachment" disabled={type === "view"} />
                )}
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  multiline
                  minRows={5}
                  maxRows={5}
                  name="response"
                  label="Resposta da Referência / Contrarreferência"
                  required
                  disabled={type === "view"}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" margin="1.5rem auto">
              {type === "view" ? (
                <SubmitButton
                  handleClick={() =>
                    againstReferencePDF(
                      reference,
                      patient,
                      reference?.cids || []
                    ).open()
                  }
                >
                  Imprimir
                </SubmitButton>
              ) : (
                <SubmitButton
                  disabled={
                    !Object.values(fields).every((field) => {
                      return !!field;
                    })
                  }
                >
                  Salvar
                </SubmitButton>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ViewCounterReference;
