import React, { useContext, useState } from "react";
import { BoxContainer, CustomizedBarChart } from "../../../components";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import RadialBarChart from "../../../components/RadialBarChart/RadialBarChart";
import CountUp from "../../../components/CountUp/CountUp";
import { CalendarToday } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import {
  DateField,
  PaginatedAutocompleteField,
} from "../../../components/FormFields";
import { useCockpit } from "../../../service";
import { format, parseISO, subMonths } from "date-fns";
import { AuthContext } from "../../../contexts/AuthContext";
import { useQuery } from "react-query";
import { useCompany } from "../../../service/useCompany";
import calcPercent from "../../../utils/calcPercent";
const CardPanel = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: `#0008`,
  fontSize: "1em",
  borderRadius: `5px`,
  padding: 5,
}));

function CockPitSchedule() {
  const theme = useTheme();
  const { getCockiptSchedules } = useCockpit();
  const { getCompanies } = useCompany();
  const { userData } = useContext(AuthContext);

  const [filter, setFilter] = useState({
    initial_date: format(subMonths(new Date(), 1), "yyyy-MM-dd"),
    final_date: format(new Date(), "yyyy-MM-dd"),
    company_id: userData?.company?.id,
  });

  const initialValues = {
    initial_date: filter.initial_date,
    final_date: filter.final_date,
    company: userData?.company,
  };

  const cockpitSchedulesQuery = useQuery(
    ["schedules-cockpit", filter],
    () => getCockiptSchedules(filter),
    {
      initialData: {
        scheduling: {
          confirmed: 7,
          canceled: 0,
          not_confirmed: 0,
          confirmed_using_qrcode: 1,
          confirmed_using_sus_card: 1,
          confirmed_using_physic_national: 2,
          confirmed_using_mother_name: 0,
          patients: {
            without_cell_phone: 787909,
            total: 899214,
          },
          total: 40,
        },
        whatsapp: {
          canceled: 0,
          confirmed: 0,
          not_confirmed: 0,
          total: 0,
        },
        last_months: [],
      },
    }
  );

  const { control, watch } = useForm({
    defaultValues: initialValues,
  });

  const initialDate = watch("initial_date");

  const barConfig = {
    bars: [
      { key: "confirmed", fill: "#87EFAB", id: 1 },
      { key: "confirmed_using_qrcode", fill: "#86F0AA", id: 2 },
      { key: "confirmed_using_sus_card", fill: "#4ADE7D", id: 3 },
      { key: "confirmed_using_others", fill: "#24C55E", id: 4 },
    ],
  };

  function formatPercent(value) {
    return value.toFixed(2).replace(".", ",") + "%";
  }

  return (
    <BoxContainer
      title={
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={7}>
            <Typography>Visão Geral</Typography>
          </Grid>
          <Grid container item xs={5} spacing={1} component="form">
            <Grid item xs={3.2}>
              <DateField
                name="initial_date"
                label="Data Inicial"
                customOnChange={(value) => {
                  setFilter((currentFilter) => {
                    return {
                      ...currentFilter,
                      initial_date: format(value, "yyyy-MM-dd"),
                    };
                  });
                }}
                required
                maxDate={new Date()}
                control={control}
              />
            </Grid>
            <Grid item xs={3.2}>
              <DateField
                name="final_date"
                customOnChange={(value) => {
                  setFilter((currentFilter) => {
                    return {
                      ...currentFilter,
                      final_date: format(value, "yyyy-MM-dd"),
                    };
                  });
                }}
                label="Data Final"
                minDate={initialDate}
                maxDate={new Date()}
                required
                control={control}
              />
            </Grid>
            <Grid item xs={5.6}>
              <PaginatedAutocompleteField
                name="company"
                label="Empresa"
                optionLabelKey="name"
                customOnChange={(value) => {
                  setFilter((currentFilter) => {
                    return {
                      ...currentFilter,
                      company_id: value?.id,
                    };
                  });
                }}
                required
                queryKey={"companies-cockpit"}
                service={(params) => {
                  return getCompanies({
                    ...params,
                  });
                }}
                control={control}
              />
            </Grid>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2} textAlign={"center"}>
        <Grid item xs={3.5} display="flex" flexDirection="column" gap={1.5}>
          <CardPanel
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Typography
              fontSize={16}
              fontWeight={700}
              color={theme.palette.primary.light}
            >
              Agendas Previstas
            </Typography>
            <Box
              bgcolor={theme.palette.primary.light}
              alignItems={"center"}
              color={"white"}
              px={2}
              py={0.2}
              borderRadius={4}
              display="flex"
              gap={1.1}
              my={0.4}
            >
              <CalendarToday />
              <Typography fontSize={28} fontWeight={700}>
                {cockpitSchedulesQuery.data.scheduling.total}
              </Typography>
            </Box>
          </CardPanel>
          <CardPanel
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Typography
              fontSize={16}
              fontWeight={700}
              color={theme.palette.primary.light}
            >
              Confirmações via Whatsapp
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <RadialBarChart
                barSize={100}
                animation={false}
                data={[cockpitSchedulesQuery.data.whatsapp]}
                innerRadius="70%"
                outerRadius="90%"
                radials={[
                  {
                    key: "confirmed",
                    gradient: "gdr-1",
                    background: "#0004",
                  },
                ]}
                label={{
                  key: "confirmed",
                  content: ({ value }) => (
                    <CountUp
                      delay={0}
                      finalValue={value}
                      sx={{ fontSize: 24, fontWeight: 600, fill: "#0009" }}
                    />
                  ),
                }}
                sx={{ width: 140, height: 140 }}
                gradients={[
                  {
                    id: "gdr-1",
                    from: "#278a49",
                    to: "#1FC85D",
                  },
                ]}
              />
              <Typography fontSize={12} fontWeight={700}>
                Confirmadas
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <RadialBarChart
                animation={false}
                data={[cockpitSchedulesQuery.data.whatsapp]}
                innerRadius="70%"
                outerRadius="90%"
                radials={[
                  {
                    key: "canceled",
                    gradient: "gdr-3",
                    background: "#0004",
                  },
                ]}
                label={{
                  key: "canceled",
                  content: ({ value }) => (
                    <CountUp
                      delay={0}
                      finalValue={value}
                      sx={{ fontSize: 24, fontWeight: 600, fill: "#0009" }}
                    />
                  ),
                }}
                sx={{ width: 140, height: 140 }}
                gradients={[
                  {
                    id: "gdr-3",
                    from: "#EE4442",
                    to: "#EE4442",
                  },
                ]}
                barSize={100}
              />
              <Typography fontSize={12} fontWeight={700}>
                Não Confirmadas
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <RadialBarChart
                animation={false}
                data={[cockpitSchedulesQuery.data.whatsapp]}
                innerRadius="70%"
                outerRadius="90%"
                radials={[
                  {
                    key: "not_confirmed",
                    gradient: "gdr-2",
                    background: "#0004",
                  },
                ]}
                label={{
                  key: "not_confirmed",
                  content: ({ value }) => (
                    <CountUp
                      delay={0}
                      finalValue={value}
                      sx={{ fontSize: 22, fontWeight: 600, fill: "#0009" }}
                    />
                  ),
                }}
                sx={{ width: 120, height: 120 }}
                gradients={[
                  {
                    id: "gdr-2",
                    from: "#6961cf",
                    to: "#9F97FF",
                  },
                ]}
                barSize={100}
              />
              <Typography fontSize={12} fontWeight={700}>
                Não Respondidas
              </Typography>
            </Box>
          </CardPanel>
        </Grid>
        <Grid item container xs={8.5} spacing={1.5}>
          <Grid item container xs={12} flexDirection="column">
            <CardPanel
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              gap={2}
            >
              <Typography
                fontSize={16}
                fontWeight={700}
                color={theme.palette.primary.light}
              >
                Confirmações
              </Typography>
              <Grid container item xs={12}>
                <Grid
                  item
                  xs={3}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography fontSize={12} fontWeight={700}>
                    Presença Confirmada
                  </Typography>
                  <RadialBarChart
                    barSize={100}
                    animation={false}
                    data={[cockpitSchedulesQuery.data.scheduling]}
                    radials={[
                      {
                        key: "confirmed",
                        gradient: "gdr-4",
                        background: "#0004",
                      },
                    ]}
                    limit={100}
                    polarX={{ dataKey: "confirmed" }}
                    label={{
                      key: "confirmed",
                      position: "center",
                      fill: "#0009",
                      fontSize: 24,
                      fontWeight: 600,
                      formatter: (value) => value,
                    }}
                    sx={{ width: 120, height: 120 }}
                    gradients={[
                      {
                        id: "gdr-4",
                        from: "#7056E8",
                        to: "#AD68F6",
                      },
                    ]}
                  />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {formatPercent(
                      calcPercent(
                        cockpitSchedulesQuery.data.scheduling.confirmed,
                        cockpitSchedulesQuery.data.scheduling.total
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography fontSize={12} fontWeight={700}>
                    Presença não confirmada
                  </Typography>
                  <RadialBarChart
                    barSize={100}
                    animation={false}
                    data={[cockpitSchedulesQuery.data.scheduling]}
                    radials={[
                      {
                        key: "not_confirmed",
                        gradient: "gdr-4",
                        background: "#0004",
                      },
                    ]}
                    limit={100}
                    polarX={{ dataKey: "not_confirmed" }}
                    label={{
                      key: "not_confirmed",
                      position: "center",
                      fill: "#0009",
                      fontSize: 24,
                      fontWeight: 600,
                      formatter: (value) => value,
                    }}
                    sx={{ width: 120, height: 120 }}
                  />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {formatPercent(
                      calcPercent(
                        cockpitSchedulesQuery.data.scheduling.not_confirmed,
                        cockpitSchedulesQuery.data.scheduling.total
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography fontSize={12} fontWeight={700}>
                    Agendas Canceladas
                  </Typography>
                  <RadialBarChart
                    barSize={100}
                    animation={false}
                    data={[cockpitSchedulesQuery.data.scheduling]}
                    radials={[
                      {
                        key: "canceled",
                        gradient: "gdr-4",
                        background: "#0004",
                      },
                    ]}
                    limit={100}
                    polarX={{ dataKey: "canceled" }}
                    label={{
                      key: "canceled",
                      position: "center",
                      fill: "#0009",
                      fontSize: 24,
                      fontWeight: 600,
                      formatter: (value) => value,
                    }}
                    sx={{ width: 120, height: 120 }}
                  />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {formatPercent(
                      calcPercent(
                        cockpitSchedulesQuery.data.scheduling.canceled,
                        cockpitSchedulesQuery.data.scheduling.total
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography fontSize={12} fontWeight={700}>
                    Confirmadas no Totem: QRCODE
                  </Typography>
                  <RadialBarChart
                    barSize={100}
                    animation={false}
                    data={[cockpitSchedulesQuery.data.scheduling]}
                    radials={[
                      {
                        key: "confirmed_using_qrcode",
                        gradient: "gdr-4",
                        background: "#0004",
                      },
                    ]}
                    limit={100}
                    polarX={{ dataKey: "confirmed_using_qrcode" }}
                    label={{
                      key: "confirmed_using_qrcode",
                      position: "center",
                      fill: "#0009",
                      fontSize: 24,
                      fontWeight: 600,
                      formatter: (value) => value,
                    }}
                    sx={{ width: 120, height: 120 }}
                  />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {formatPercent(
                      calcPercent(
                        cockpitSchedulesQuery.data.scheduling
                          .confirmed_using_qrcode,
                        cockpitSchedulesQuery.data.scheduling.total
                      )
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={3}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography fontSize={12} fontWeight={700}>
                    Confirmadas no Totem: CNS
                  </Typography>
                  <RadialBarChart
                    barSize={100}
                    animation={false}
                    data={[cockpitSchedulesQuery.data.scheduling]}
                    radials={[
                      {
                        key: "confirmed_using_sus_card",
                        gradient: "gdr-4",
                        background: "#0004",
                      },
                    ]}
                    limit={100}
                    polarX={{ dataKey: "confirmed_using_sus_card" }}
                    label={{
                      key: "confirmed_using_sus_card",
                      position: "center",
                      fill: "#0009",
                      fontSize: 24,
                      fontWeight: 600,
                      formatter: (value) => value,
                    }}
                    sx={{ width: 120, height: 120 }}
                  />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {formatPercent(
                      calcPercent(
                        cockpitSchedulesQuery.data.scheduling
                          .confirmed_using_sus_card,
                        cockpitSchedulesQuery.data.scheduling.total
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography fontSize={12} fontWeight={700}>
                    Confirmadas no Totem: CPF
                  </Typography>
                  <RadialBarChart
                    barSize={100}
                    animation={false}
                    data={[cockpitSchedulesQuery.data.scheduling]}
                    radials={[
                      {
                        key: "confirmed_using_physic_national",
                        gradient: "gdr-4",
                        background: "#0004",
                      },
                    ]}
                    limit={100}
                    polarX={{ dataKey: "confirmed_using_physic_national" }}
                    label={{
                      key: "confirmed_using_physic_national",
                      position: "center",
                      fill: "#0009",
                      fontSize: 24,
                      fontWeight: 600,
                      formatter: (value) => value,
                    }}
                    sx={{ width: 120, height: 120 }}
                  />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {formatPercent(
                      calcPercent(
                        cockpitSchedulesQuery.data.scheduling
                          .confirmed_using_physic_national,
                        cockpitSchedulesQuery.data.scheduling.total
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography fontSize={12} fontWeight={700}>
                    Confirmadas no Totem: Nome Mãe
                  </Typography>
                  <RadialBarChart
                    barSize={100}
                    animation={false}
                    data={[cockpitSchedulesQuery.data.scheduling]}
                    radials={[
                      {
                        key: "confirmed_using_mother_name",
                        gradient: "gdr-4",
                        background: "#0004",
                      },
                    ]}
                    limit={100}
                    polarX={{ dataKey: "confirmed_using_mother_name" }}
                    label={{
                      key: "confirmed_using_mother_name",
                      position: "center",
                      fill: "#0009",
                      fontSize: 24,
                      fontWeight: 600,
                      formatter: (value) => value,
                    }}
                    sx={{ width: 120, height: 120 }}
                  />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {formatPercent(
                      calcPercent(
                        cockpitSchedulesQuery.data.scheduling
                          .confirmed_using_mother_name,
                        cockpitSchedulesQuery.data.scheduling.total
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography fontSize={12} fontWeight={700}>
                    Pacientes sem número de telefone
                  </Typography>
                  <RadialBarChart
                    barSize={100}
                    animation={false}
                    data={[cockpitSchedulesQuery.data.scheduling.patients]}
                    radials={[
                      {
                        key: "without_cell_phone",
                        gradient: "gdr-4",
                        background: "#0004",
                      },
                    ]}
                    limit={100}
                    polarX={{ dataKey: "without_cell_phone" }}
                    label={{
                      key: "without_cell_phone",
                      position: "center",
                      fill: "#0009",
                      fontSize: 24,
                      fontWeight: 600,
                      formatter: (value) => value,
                    }}
                    sx={{ width: 120, height: 120 }}
                  />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {formatPercent(
                      calcPercent(
                        cockpitSchedulesQuery.data.scheduling.patients
                          .without_cell_phone,
                        cockpitSchedulesQuery.data.scheduling.patients.total
                      )
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </CardPanel>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              position: "relative",
            }}
          >
            <CardPanel>
              <Typography
                fontSize={16}
                fontWeight={700}
                color={theme.palette.primary.light}
              >
                Histórico de Confirmações (mês)
              </Typography>
              <CustomizedBarChart
                sx={{ padding: 1, gap: 5 }}
                width={"100%"}
                height={230}
                data={cockpitSchedulesQuery.data.last_months}
                barSize={45}
                config={barConfig}
                legend={{
                  layout: "vertical",
                  verticalAlign: "top",
                  align: "right",
                  payload: [
                    {
                      value: "Presença Confirmada",
                      type: "circle",
                      id: 1,
                      fill: "#87EFAB",
                    },
                    {
                      value: "Confirmadas no totem QR-Code",
                      type: "circle",
                      id: 2,
                      fill: "#86F0AA",
                    },
                    {
                      value: "Confirmadas no totem CNS",
                      type: "circle",
                      id: 3,
                      fill: "#4ADE7D",
                    },
                    {
                      value: "Confirmadas de outra forma",
                      type: "circle",
                      id: 4,
                      fill: "#24C55E",
                    },
                  ],
                  content: (props) => {
                    const { payload } = props;
                    return (
                      <Box>
                        {payload.map((entry, index) => (
                          <Box
                            display="flex"
                            alignItems="center"
                            gap={0.4}
                            key={`item-${index}`}
                          >
                            <Typography
                              component="span"
                              bgcolor={entry.fill}
                              borderRadius={3}
                              width={16}
                              height={10}
                            />
                            <Typography
                              component="span"
                              fontWeight={600}
                              fontSize={11}
                            >
                              {entry.value}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    );
                  },
                }}
                cartesianGrid={{ strokeDasharray: "2", vertical: false }}
                layout="horizontal"
                x={{
                  type: "category",
                  dataKey: "date",
                  axisLine: false,
                  tickLine: false,
                  fontWeight: 600,
                  tickFormatter: (date) => {
                    if (typeof date === "string") {
                      return format(parseISO(date), "MM/yyyy");
                    }

                    return "";
                  },
                }}
                y={{
                  type: "number",
                  dataKey: "total_count",
                  axisLine: true,
                  tickLine: false,
                }}
                label={{
                  position: "top",
                  fontSize: "0.7rem",
                  fontWeight: 600,
                  fill: "#0009",
                  formatter: (value, data) =>
                    formatPercent(calcPercent(value, data.total_count)),
                }}
              />
            </CardPanel>
          </Grid>
        </Grid>
      </Grid>
    </BoxContainer>
  );
}

export default CockPitSchedule;
