import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { useContext, useState } from "react";
import { PaginatedScrollList } from "../../../components/PaginatedScrollList";
import { ptBR } from "date-fns/locale";
import { DoNotDisturb, Visibility } from "@mui/icons-material";
import { useConfirm } from "../../../helper/BlockNavigation";
import { Dialog } from "../../../helper";
import TriageViewHistory from "./TriageViewHistory";
import { AppContext } from "../../../contexts/AppContext";
import useTriage from "../../../service/useTriage";
import { usePatient } from "../../../service";
import { useMutation, useQuery } from "react-query";
import useNotifier from "../../../hooks/useNotifier";

export default function TriageHistory({ patientID }) {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [selectedHistory, setSelectedHistory] = useState();
  const { patientData } = useContext(AppContext);
  const [view, setView] = useState(false);
  const { confirm } = useConfirm();
  const notify = useNotifier();
  const { getTriagesByPatient } = usePatient();
  const { getTriage, deleteTriage } = useTriage();
  const getMutation = useMutation({
    mutationFn: getTriage,
    mutationKey: "getTriage",
  });
  const deleteMutation = useMutation({
    mutationFn: deleteTriage,
    mutationKey: "deleteTriage",
  });
  const { data, refetch } = useQuery(
    ["getTriagesByPatient"],
    () => getTriagesByPatient(patientID, { page, limit: 6 }),
    { refetchOnWindowFocus: false }
  );

  function setTriageToView(id) {
    getMutation.mutate(id, {
      onSuccess(data) {
        setSelectedHistory(data);
        setView(true);
      },
    });
  }

  function onDelete(id) {
    deleteMutation.mutate(id, {
      onSuccess(data) {
        notify(data.message, "success");
        refetch();
      },
      onError(data) {
        notify(data.message, "error");
      },
    });
  }
  return (
    <Box>
      <Dialog
        open={view}
        handleClose={() => setView(false)}
        title={"Histórico"}
      >
        <TriageViewHistory
          historyData={selectedHistory}
          patientData={patientData}
        />
      </Dialog>
      <Divider
        sx={{ backgroundColor: theme.palette.primary.medium, mt: 1, mb: 1 }}
      />
      <>
        <Typography variant="h6" color="secondary" fontWeight="700">
          Histórico de Triagens
        </Typography>
        <PaginatedScrollList
          handlePage={setPage}
          page={page}
          totalPage={0}
          data={data?.items}
          height="200px"
          minHeight="auto"
          hasDivider={false}
          emptyMessage="O paciente não possui triagens anteriores."
          endMessage="Não há mais nenhuma triagem para este paciente."
          component={(item, index) => (
            <List
              key={item?.id || "history-" + index}
              sx={{
                width: "100%",
                paddingLeft: 0,
                paddingRight: 1,
                paddingY: 0.5,
              }}
            >
              <ListItemButton
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  py: 2.5,
                  backgroundColor: !item.deletedAt
                    ? theme.palette.primary.medium
                    : "rgba(216, 0, 0, 0.1)",
                  border: `2px solid ${
                    item.id === selectedHistory
                      ? theme.palette.primary.light
                      : "tranparent"
                  }`,
                  "&:hover": {
                    background: !item.deletedAt ? "" : "rgba(216, 0, 0, 0.1)",
                  },
                  borderRadius: 2,
                }}
              >
                <Typography color={theme.palette.primary.main}>
                  <Typography variant="span" fontWeight="500">
                    {format(parseISO(item?.createdAt), "dd, MMMM ", {
                      locale: ptBR,
                    })}
                  </Typography>
                  {format(parseISO(item?.createdAt), "yyyy", {
                    locale: ptBR,
                  })}
                </Typography>

                <Box
                  sx={{
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <Typography color="secondary" fontWeight="medium">
                    N. Atendimento:
                    <Typography
                      variant="span"
                      fontWeight="700"
                      sx={{ ml: "3px" }}
                    >
                      {item?.attendance?.number}
                    </Typography>{" "}
                    - às {format(parseISO(item?.createdAt), "HH:mm")}h
                  </Typography>
                  {item?.employee_specialty && (
                    <Typography fontSize={14} color="primary" fontWeight={500}>
                      {`${item.employee_specialty.employee.name} - ${item.employee_specialty.specialty.describe}`}
                    </Typography>
                  )}
                  <>
                    {item?.attendance?.date_late ? (
                      <Typography fontSize={12} color="secondary.main">
                        <Typography
                          component="span"
                          color="secondary.main"
                          fontWeight="medium"
                          fontSize={12}
                        >
                          Dt. de Referência :{" "}
                          <Typography
                            component="span"
                            color="primary"
                            fontSize={12}
                          >
                            {format(
                              new Date(item?.attendance?.date_late),
                              "dd/MM/yyyy"
                            )}
                          </Typography>{" "}
                        </Typography>
                      </Typography>
                    ) : null}
                  </>
                  <Typography fontSize={14} color="secondary.main">
                    <Typography component="span" color="primary" fontSize={14}>
                      Serviço :{" "}
                      <Typography
                        component="span"
                        color="primary"
                        fontSize={14}
                      >
                        {item?.attendance?.service?.describe}{" "}
                        {item?.attendance?.service?.ceo && " - CEO"}
                      </Typography>{" "}
                    </Typography>
                  </Typography>
                </Box>
              </ListItemButton>
              {
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 25,
                    top: 15,
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setTriageToView(item.id);
                  }}
                >
                  <Visibility fontSize="large" />
                </IconButton>
              }
              {!item.deletedAt ? (
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 25,
                    bottom: 10,
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    confirm("Deseja *suspender* esta *Triagem/Grupo* ?", () =>
                      onDelete(item.id)
                    );
                  }}
                >
                  <DoNotDisturb fontSize="large" />
                </IconButton>
              ) : null}
            </List>
          )}
        />
      </>
    </Box>
  );
}
