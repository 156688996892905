import instance from "../config/api";

export default function useTriage() {
    const DPath = "triages"
    async function getRisks(params) {
        try {
            const result = await instance.get('/risks', { params })
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function getTriage(id, params) {
        try {
            const result = await instance.get(`${DPath}/${id}`, { params })
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function deleteTriage(id) {
        try {
            const result = await instance.delete(`${DPath}/${id}`)
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async function postTriage(data) {
        try {
            const result = await instance.post(`${DPath}`, data)
            return result.data
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
    return { getRisks, getTriage, deleteTriage, postTriage }
}