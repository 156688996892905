import instance from "../config/api";
import { useCallback } from "react";
import urlQuery from "../helper/urlQuery";
const path = "discharges";

function usePatientDischarge() {
  const getPatientDischargeByAttendance = useCallback(
    async (attendanceId, params) => {
      try {
        const response = await instance.get(
          `attendances/${attendanceId}/discharge`,
          {
            params: urlQuery(params),
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    },
    []
  );

  const getPatientDischarges = useCallback(async (patientId) => {
    try {
      const response = await instance.get(`patients/${patientId}/discharges`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  const postPatientDischarges = useCallback(async (data) => {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  const putPatientDischarges = useCallback(async () => {
    try {
      const response = await instance.put(path);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  const suspendPatientDischarges = useCallback(
    async ({ id, cancelation_notes }) => {
      try {
        const response = await instance.patch(
          `${path}/${id}/suspend`,
          cancelation_notes
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    },
    []
  );

  const getDischargesReasons = useCallback(async (params) => {
    try {
      const response = await instance.get(`${path}/reasons`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  return {
    getPatientDischargeByAttendance,
    getPatientDischarges,
    postPatientDischarges,
    putPatientDischarges,
    getDischargesReasons,
    suspendPatientDischarges,
  };
}

export default usePatientDischarge;
