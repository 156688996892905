import React from "react";
import { Grid, Typography, Box, TablePagination, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { DialogMedium } from "../../helper";
import { useMutation, useQuery } from "react-query";
import { useCaller, usePagination, useStation } from "../../service";
import { format, parseISO } from "date-fns";
import Yup from "../../config/yup";
import TableFilter from "../../components/Table/TableFilter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AutocompleteField from "../../components/FormFields/AutocompleteField";
import { TextField, SwitchField } from "../../components/FormFields";
import { useNotifier } from "../../hooks";

export default function SettingsCaller() {
  const theme = useTheme();
  const notify = useNotifier();
  const [edit, setEdit] = React.useState(false);
  const [caller, setCaller] = React.useState(null);
  const [limit, setLimit] = React.useState(5);

  const { getCallers, postCaller, updateCaller } = useCaller();
  const { getStations } = useStation();
  const callerMutation = useMutation(postCaller);
  const updateCallerMutation = useMutation(updateCaller);

  const stationQuery = useQuery(
    ["station"],
    () => getStations({ filter: "active" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: [],
    }
  );

  const callersQuery = useQuery(["callers"], () => getCallers(), {
    retry: false,
    initialData: [],
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  const { paginationInfo, handlePageChange } = usePagination(
    callersQuery.data,
    limit
  );

  const validations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      stations: [],
    },
    resolver: yupResolver(validations),
  });

  const onSubmit = handleSubmit((values) => {
    callerMutation.mutate(values, {
      onSuccess: (response) => {
        notify(response.message, "success");
        reset();
        callersQuery.refetch();
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    });
  });

  function handleClose() {
    setEdit(false);
    setCaller(null);
  }

  const columns = [
    {
      field: "name",
      name: "Nome",
      type: "string",
    },
    {
      field: "active",
      name: "Ativo",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
    },
    {
      field: "stations",
      name: "Nº de estações",
      type: "string",
      use: (value) => {
        return value.length;
      },
      alignInRow: "center",
      alignInHead: "center",
    },
    {
      field: "createdAt",
      name: "Criado em",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado",
      type: "date",
    },
    {
      field: "updatedAt",
      name: "Atualizado em",
      use: (date) =>
        date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado",
      type: "date",
    },
  ];

  const editInitialValues = {
    name: caller?.name,
    stations: caller?.stations || [],
    active: caller?.active,
  };

  const editValidations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
    active: Yup.boolean().required("É requerido"),
  });

  const editCallerForm = useForm({
    defaultValues: editInitialValues,
    resolver: yupResolver(editValidations),
  });

  const handleEditSubmit = editCallerForm.handleSubmit((values) => {
    const data = { ...values };

    data.stations = data.stations.map((station) => {
      return station.id;
    });

    updateCallerMutation.mutate(
      { id: caller.id, data },
      {
        onSuccess: (response) => {
          notify(response.message, "success");
          callersQuery.refetch();
          handleClose();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  });

  return (
    <>
      <DialogMedium
        open={edit}
        fullWidth
        maxWidth="sm"
        title="Edição de chamador"
        handleClose={handleClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              control={editCallerForm.control}
              name="name"
              label="Nome"
              required
            />
          </Grid>

          <Grid item xs={4}>
            <SwitchField
              control={editCallerForm.control}
              name="active"
              label="Alterar Status"
            />
          </Grid>
          <Grid item xs={8}>
            <AutocompleteField
              control={editCallerForm.control}
              name="stations"
              label="Estações"
              options={stationQuery.data}
              optionLabelKey="name"
              multiple
              required
              disabled={editCallerForm.watch("active") ? false : true}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              onClick={handleEditSubmit}
              onLoad={updateCallerMutation.isLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
      <Box>
        <TableFilter
          data={paginationInfo.items}
          columns={columns}
          loading={callersQuery.isLoading}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit"]}
          shouldDisableFields={["id"]}
          actionHandleEdit={(_, item) => {
            setCaller(item);
            editCallerForm.setValue("name", item.name);
            editCallerForm.setValue("active", item.active);
            editCallerForm.setValue("stations", item.stations);
            setEdit(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>

      <Grid container spacing={2} paddingX={1}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar chamador
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField control={control} name="name" label="Nome" required />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            onLoad={callerMutation.isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
