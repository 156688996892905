import { Grid } from "@mui/material";
import { useRoles } from "../../../../service";
import Yup from "../../../../config/yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNotifier } from "../../../../hooks";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  SelectField,
  TextField,
} from "../../../../components/FormFields";

export default function RolesForm({ data, handleClose }) {
  const notify = useNotifier();
  const queryClient = useQueryClient();
  const { updateRole, postRole } = useRoles();

  const updateRoleMutation = useMutation(updateRole);
  const postRoleMutation = useMutation(postRole);

  const validations = Yup.object().shape({
    name: Yup.string().required("É requerido").nullable(),
    active: Yup.string().required("É requerido").nullable(),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: data ? data.name : "",
      active: data ? data.active : true,
    },
    resolver: yupResolver(validations),
  });

  async function onSubmit(values) {
    if (data) {
      values.id = data.id;

      updateRoleMutation.mutate(values, {
        onSuccess() {
          reset();
          notify("Atualizado com sucesso", "success");
          queryClient.invalidateQueries("get-roles");
          handleClose();
        },
        onError(error) {
          notify(error.message, "error");
        },
      });
    } else {
      postRoleMutation.mutate(values, {
        onSuccess() {
          reset();
          notify("Cadastrado com sucesso", "success");
          queryClient.invalidateQueries("get-roles");
        },
        onError(error) {
          notify(error.message, "error");
        },
      });
    }
  }

  return (
    <Grid
      component="form"
      container
      spacing={2}
      onSubmit={handleSubmit(onSubmit)}
      marginTop={1}
    >
      <Grid xs={5} item>
        <TextField control={control} name="name" label="Nome" />
      </Grid>
      <Grid xs={5} item>
        <SelectField
          name="active"
          label="Status"
          control={control}
          optionValueKey="value"
          options={[
            { label: "Ativo", value: true },
            { label: "Inativo", value: false },
          ]}
        />
      </Grid>
      <Grid xs={2} item>
        <Button type="submit">{data ? "Salvar" : "Cadastrar"}</Button>
      </Grid>
    </Grid>
  );
}
