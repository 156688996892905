import { useContext } from "react";
import {
  NotifierContext,
  SHOW_NOTIFICATION,
} from "../contexts/NotifierContext";

export default function useNotifier() {
  const [, dispatch] = useContext(NotifierContext);

  const notify = (message, severity) => {
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: {
        message,
        severity,
      },
    });
  };

  return notify;
}
