import axios from "axios";
import cookie from "js-cookie";

function getSignaturetoken() {
  const singnatureToken = cookie.get("signer-auth-token");
  return singnatureToken || null;
}

function getAuthToken() {
  const authToken = cookie.get("x-access-token");
  return authToken || null;
}

const instance = axios.create({
  baseURL: window.API_URL,
});

export const lunaInstance = axios.create({
  baseURL: window.LUNA_AI_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  config.headers = {
    "x-access-token": getAuthToken(),
    "signer-auth-token": getSignaturetoken(),
    platform: "web",
    "internal-version": window.INTERNAL_VERSION,
  };
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const urlsToExclude = ["/external-services/tsigner/validate/signer-token"];
    const pathsToExclude = ["/login", "/tportal/medications/"];

    const isNotExcludedUrl = !urlsToExclude.some(url => error.response.config.url.includes(url)); 
    const isNotExcludedPath = !pathsToExclude.some(path => window.location.pathname.includes(path));

    if (
      error.response.status === 401 &&
      error.response.data?.valid === false &&
      isNotExcludedUrl &&
      isNotExcludedPath
    ) {
      cookie.remove("x-access-token");
      cookie.remove("signer-auth-token");
      localStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default instance;
