import { Box, Button, Grid } from "@mui/material";
import { BottomArch, TopArch } from "../../../components/Arch";
import { DialogMedium } from "../../../helper";
import { useContext, useEffect, useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import {
  FormSelectWithPaginate,
  FormSwitch,
  FormTextField,
} from "../../../components/Form";
import { Check } from "@mui/icons-material";
import { useProcedures } from "../../../service";
import { AppContext } from "../../../contexts/AppContext";
import { verifyAttendanceFlow } from "../../../utils";

export default function ArchTab({ archType, attendance, handleArchType }) {
  const [open, setOpen] = useState(false);
  const { setFieldValue, values: formValues } = useFormikContext();
  const { upper_denture, lower_denture } = formValues;
  const [initialValues, setInitialValues] = useState();
  const { disableFields, patientData } = useContext(AppContext);
  const [currentArch, setCurrentArch] = useState(null);
  const { getProcedures } = useProcedures();

  function handleSave({ procedures, notes }) {
    setOpen(false);
    const oldValue = { ...formValues.arch };
    if (!procedures.length && !notes && oldValue[open]) {
      delete oldValue[open];
      setFieldValue("arch", oldValue);
      return;
    }
    if (!procedures.length && !notes) return;
    setFieldValue("arch", { ...oldValue, [open]: { procedures, notes } });
  }

  function handleOpenForm(open) {
    if (open && formValues.arch?.[open]) {
      setInitialValues(formValues.arch?.[open]);
    } else {
      setInitialValues({ procedures: [], notes: "" });
    }
    setCurrentArch(open === "top" ? "arch_top" : "arch_bottom");
    setOpen(open);
  }

  useEffect(() => {
    if (lower_denture) {
      const oldValue = { ...formValues.arch };
      if (oldValue["bottom"]) {
        delete oldValue["bottom"];
        setFieldValue("arch", oldValue);
        return;
      }
    }
    if (upper_denture) {
      const oldValue = { ...formValues.arch };
      if (oldValue["top"]) {
        delete oldValue["top"];
        setFieldValue("arch", oldValue);
        return;
      }
    }
  }, [lower_denture, upper_denture]);

  return (
    <>
      <DialogMedium open={!!open} fullWidth handleClose={() => setOpen(false)}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSave}
        >
          {() => (
            <Form>
              <Grid
                container
                item
                display={"flex"}
                flexDirection={"column"}
                rowGap={3}
              >
                <FormSelectWithPaginate
                  name={"procedures"}
                  service={(params) =>
                    getProcedures({
                      ...params,
                      type: ["odonto", currentArch],
                      attendance_flow: verifyAttendanceFlow(
                        attendance.risk_classification_id
                      ),
                      patient_id: patientData?.id,
                    })
                  }
                  getOptionLabel={(data) => {
                    let label = data?.name;

                    if (data?.tuss_code) {
                      label = `${data?.tuss_code} - ${label}`;
                    }

                    return label;
                  }}
                  searchBy="filters"
                  multiple
                  fields="describe"
                  label={"Procedimentos"}
                  disabled={disableFields}
                />
                <FormTextField
                  name={"notes"}
                  label={"Observações"}
                  multiline
                  disabled={disableFields}
                  minRows={3}
                  maxRows={3}
                />
                {!disableFields && (
                  <Grid item xs={12} justifyContent={"end"} display={"flex"}>
                    <Button type="submit" variant="contained">
                      <Check />
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogMedium>
      <Box display={"flex"} width={"100%"} flexDirection={"row-reverse"}>
        <FormSwitch
          name={"archType"}
          label={"Decíduos"}
          customHandleChange={(value) => {
            // setFieldValue("teeth", []);
            if (value) {
              handleArchType("deciduous");
            } else {
              handleArchType("default");
            }
          }}
        />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        padding={"20px"}
        rowGap={"10px"}
        flexDirection={"column"}
        sx={{
          "& div": {
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          "& #top": {
            cursor: formValues.upper_denture ? "" : "pointer",
            backgroundColor: (t) =>
              formValues.arch?.top ? t.palette.secondary.main : "F4F4F4",
            "&:hover": {
              backgroundColor: (t) =>
                formValues.upper_denture ? "" : t.palette.secondary.main,
            },
          },
          "& #bottom": {
            cursor: formValues.lower_denture ? "" : "pointer",
            backgroundColor: (t) =>
              formValues.arch?.bottom ? t.palette.secondary.main : "F4F4F4",
            "&:hover": {
              backgroundColor: (t) =>
                formValues.lower_denture ? "" : t.palette.secondary.main,
            },
          },
        }}
      >
        <Box
          onClick={() =>
            handleOpenForm(formValues.upper_denture ? false : "top")
          }
          id={"top"}
        >
          <TopArch
            size={"100%"}
            type={archType}
            svg
            disabled={formValues.upper_denture}
          />
        </Box>
        <Box
          onClick={() =>
            handleOpenForm(formValues.lower_denture ? false : "bottom")
          }
          id={"bottom"}
        >
          <BottomArch
            size={"100%"}
            type={archType}
            svg
            disabled={formValues.lower_denture}
          />
        </Box>
      </Box>
    </>
  );
}
