import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/families";

function useFamily() {
  async function getFamily(params) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postFamily(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function putFamily({ familyId, familyData }) {
    try {
      const response = await instance.put(
        path + `/update/${familyId}`,
        familyData
      );
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function toggleStatus({ familyId, familyData }) {
    try {
      const response = await instance.patch(
        path + `/update/${familyId}/status`,
        familyData
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function deleteFamily({ familyId, patientsToDelete }) {
    try {
      const response = await instance.post(
        path + `/${familyId}/patients/batch-delete`,
        patientsToDelete
      );
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getFamilyExclusions (id, params)  {
    try {
      const response = await instance.get(`${path}/${id}/checkout`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getFamilyRecordsById (id, params)  {
    try {
      const response = await instance.get(`${path}/${id}/records`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  return {
    getFamily,
    postFamily,
    putFamily,
    deleteFamily,
    toggleStatus,
    getFamilyExclusions,
    getFamilyRecordsById,
  };
}
export default useFamily;
