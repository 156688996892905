const MEDICAL_EMPLOYEE_TYPE_ID = "80e2a102-781d-4f64-baa8-e6466acaf077";
const NURSE_EMPLOYEE_TYPE_ID = "98ed9bcb-4cf3-4f5f-b4f6-5a280d201f0d";
const HYPERTENSION_PRE_EXISTING_ILLNESSES_ID = "2fe49671-2bde-4ed8-8077-47ab3519ad8e";
const SYSTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID = "2ff55273-61c5-4b08-9014-317b1f153e19";
const DIASTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID = "1ab28855-8449-4cc1-b83b-52f5c192c4ef";

export {
  MEDICAL_EMPLOYEE_TYPE_ID,
  NURSE_EMPLOYEE_TYPE_ID,
  HYPERTENSION_PRE_EXISTING_ILLNESSES_ID,
  SYSTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID,
  DIASTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID
}