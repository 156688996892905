import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

export default function useRPA() {
  const path = "rpa";
  async function getRPA(params = {}) {
    try {
      const result = await instance.get(path, { params: urlQuery(params) });
      return result.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  const importRPA = async (params = {}) => {
    try {
      const response = await instance.get(path, {
        responseType: "arraybuffer",
        params: urlQuery({ ...params, export_file: true }),
      });
      return response;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const exportRPAToSiga = async (data) => {
    try {
      const response = await instance.post(`${path}/siga-exports`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const updateRPA = async ({ id, data }) => {
    try {
      const response = await instance.put(`${path}/${id}`, data);
      return response
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };
  return {
    getRPA,
    importRPA,
    updateRPA,
    exportRPAToSiga,
  };
}
