import React from "react";
import { Typography, CardHeader, CardContent, Card, Grid } from "@mui/material";
import { format, parseISO } from "date-fns";

function PatientDischargeFeedback({ data, patient }) {
  return (
    <Card
      sx={{
        border: "3px solid transparent",
        borderColor: "white",
        borderWidth: "3px",
        boxShadow: "0 0 0 1.5px #FFFFFF",
      }}
    >
      <CardHeader
        title={
          <Typography
            variant="h1"
            color="secondary"
            fontWeight="600"
            fontSize={15}
          >
            Visualização da evolução
          </Typography>
        }
        sx={{ mb: -3 }}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="p"
              fontSize={13}
              color="secondary"
              fontWeight="500"
            >
              Profissional:{" "}
              <Typography
                color="primary"
                component="span"
                fontWeight={500}
                fontSize={13}
              >
                {data.employee_specialty.employee.name}
              </Typography>
            </Typography>
            <Typography
              variant="p"
              fontSize={13}
              color="secondary"
              fontWeight="500"
            >
              Data da evolução:{" "}
              <Typography
                color="primary"
                component="span"
                fontWeight={500}
                fontSize={13}
              >
                {format(parseISO(data.createdAt), "dd/MM/yyyy")}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="p"
              color="gray"
              fontWeight="700"
              fontSize={12}
              sx={{ mb: 2 }}
            >
              #2 DADOS DO PACIENTE
            </Typography>
            <Typography
              variant="p"
              fontSize={13}
              color="secondary"
              fontWeight="500"
            >
              Paciente:{" "}
              <Typography
                color="primary"
                component="span"
                fontWeight={500}
                fontSize={13}
              >
                {patient?.name || "Não informado"}
              </Typography>
            </Typography>
            <Typography
              variant="p"
              fontSize={13}
              color="secondary"
              fontWeight="500"
            >
              Data de nascimento:{" "}
              <Typography
                color="primary"
                component="span"
                fontWeight={500}
                fontSize={13}
              >
                {patient?.birth_date
                  ? format(parseISO(patient.birth_date), "dd/MM/yyyy")
                  : "Não informado"}
              </Typography>
            </Typography>
            <Typography
              variant="p"
              fontSize={13}
              color="secondary"
              fontWeight="500"
            >
              CPF:{" "}
              <Typography
                color="primary"
                component="span"
                fontWeight={500}
                fontSize={13}
              >
                {patient?.physic_national || "Não informado"}
              </Typography>
            </Typography>
            <Typography
              variant="p"
              fontSize={13}
              color="secondary"
              fontWeight="500"
            >
              CNS:{" "}
              <Typography
                color="primary"
                component="span"
                fontWeight={500}
                fontSize={13}
              >
                {patient?.sus_card || "Não informado"}
              </Typography>
            </Typography>
            <Typography
              variant="p"
              color="gray"
              fontWeight="700"
              fontSize={12}
              sx={{ my: 2 }}
            >
              #2 DADOS DA EVOLUÇÃO
            </Typography>
            {data.order_number && (
              <Typography
                variant="p"
                fontSize={13}
                color="secondary"
                fontWeight="500"
              >
                Número de ordem :{" "}
                <Typography
                  color="primary"
                  component="span"
                  fontWeight={500}
                  fontSize={13}
                >
                  {data.order_number}
                </Typography>
              </Typography>
            )}
            {data.demand_typ && (
              <Typography
                variant="p"
                fontSize={13}
                color="secondary"
                fontWeight="500"
              >
                Demanda :{" "}
                <Typography
                  color="primary"
                  component="span"
                  fontWeight={500}
                  fontSize={13}
                >
                  {data.demand_typ}
                </Typography>
              </Typography>
            )}
            {data.chief_complaint && (
              <Typography
                variant="p"
                fontSize={13}
                color="secondary"
                fontWeight="500"
              >
                Queixa principal :{" "}
                <Typography
                  color="primary"
                  component="span"
                  fontWeight={500}
                  fontSize={13}
                >
                  {data.chief_complaint}
                </Typography>
              </Typography>
            )}
            {data.tag && (
              <Typography
                variant="p"
                fontSize={13}
                color="secondary"
                fontWeight="500"
              >
                Etiqueta :{" "}
                <Typography
                  color="primary"
                  component="span"
                  fontWeight={500}
                  fontSize={13}
                >
                  {data.tag}
                </Typography>
              </Typography>
            )}
            {data.notes && (
              <Typography
                variant="p"
                fontSize={13}
                color="secondary"
                fontWeight="500"
              >
                Anotações :{" "}
                <Typography
                  color="primary"
                  component="span"
                  fontWeight={500}
                  fontSize={13}
                >
                  {data.notes}
                </Typography>
              </Typography>
            )}

            {data.procedures?.length && (
              <>
                <Typography
                  variant="p"
                  color="gray"
                  fontWeight="700"
                  fontSize={12}
                  sx={{ my: 2 }}
                >
                  #3 PROCEDIMENTOS
                </Typography>
                {data.procedures.map(({ name, tuss_code }, index) => (
                  <Typography
                    variant="p"
                    fontSize={13}
                    color="secondary"
                    fontWeight="500"
                  >
                    {`${index + 1} - `}
                    <Typography
                      color="primary"
                      component="span"
                      fontWeight={500}
                      fontSize={13}
                    >
                      {`${name} (${tuss_code})`}
                    </Typography>
                  </Typography>
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default PatientDischargeFeedback;
