import { TextField, Box, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { theme } from "../../../src/config/theme";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import React from "react";

function FormFile({
  name,
  size = "small",
  disabled,
  customHandleChange,
  typesAccepted,
  multiple,
  ...props
}) {
  const { setFieldValue, values } = useFormikContext();

  const handleChange = ({ currentTarget }) => {
    setFieldValue(name, currentTarget.files);
    if (customHandleChange) {
      customHandleChange(currentTarget);
    }
  };

  const settings = {
    ...props,
    onChange: handleChange,
    onblur: handleChange,
    disabled,
    type: "file",
    size: size,
    fullWidth: true,
    InputLabelProps: { shrink: true },
    inputProps: {
      multiple,
      accept: typesAccepted.join(","),
    },
    sx: {
      zIndex: 10,
    },
  };

  return (
    <Box
      sx={{
        opacity: 1,
        position: "relative",
        backgroundColor: values[name] ? theme.palette.primary.light : "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 1,
        transition: ".3s",
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
          color: "white",
        },
        "&:hover svg": {
          fill: "white",
        },
        svg: {
          fill: values[name] ? "white" : theme.palette.primary.main,
        },
        input: {
          cursor: "pointer",
          opacity: "0 !important",
        },
        "&.disabled": {
          opacity: 0.5,
          backgroundColor: "white",
        },
        "&.disabled svg": {
          fill: theme.palette.primary.main,
        },
        "&.disabled input": {
          cursor: "auto",
        },
      }}
      className={disabled ? "disabled" : ""}
    >
      <TextField {...settings} />
      <UploadFileIcon
        color="primary"
        sx={{
          width: 28,
          height: 28,
          position: "absolute",
        }}
      />
    </Box>
  );
}

export default FormFile;
