import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { BoxContainer, CustomizedBarChart } from "../../../components";
import { ReactComponent as PreAttendance } from "../../../assets/svg/preAtendimento.svg";
import ClockIcon from "@mui/icons-material/MoreTime";
import RadialBarChart from "../../../components/RadialBarChart/RadialBarChart";
import {
  Female,
  Male,
  Transgender,
  TaskAlt,
  EventNote,
  EscalatorWarningRounded,
  AccessibleRounded,
  PregnantWomanRounded,
  DirectionsRunRounded,
  InfoOutlined,
} from "@mui/icons-material";
import { ReactComponent as Overweight } from "../../../assets/svg/overweight.svg";
import { ReactComponent as Autistic } from "../../../assets/svg/autistic.svg";
import { ReactComponent as AttendanceExceeded } from "../../../assets/svg/attendanceExceeded.svg";
import { ReactComponent as AttendanceWithoutPatient } from "../../../assets/svg/attendancesWithoutPatient.svg";
import { ReactComponent as ClassificationWithoutPatient } from "../../../assets/svg/classificationWithoutPatient.svg";
import { ReactComponent as DocumentationNotFinished } from "../../../assets/svg/documentationNotFinished.svg";
import { ReactComponent as RegulationNotFinished } from "../../../assets/svg/regulationNotFinished.svg";
import { ReactComponent as ProceduresNotFinished } from "../../../assets/svg/proceduresNotFinished.svg";
import { ReactComponent as EcgNotFinished } from "../../../assets/svg/ecgNotFinished.svg";
import { ReactComponent as ExamLabNotFinished } from "../../../assets/svg/examLabNotFinished.svg";
import { ReactComponent as MedicationNotFinished } from "../../../assets/svg/medicationNotFinished.svg";
import { ReactComponent as XrayNotFinished } from "../../../assets/svg/xrayNotFinished.svg";
import { ReactComponent as MonitoringExceeded } from "../../../assets/svg/monitoringExceeded.svg";
import { Dialog, DialogMedium, Loading } from "../../../helper";
import CockPitOperationIndicatorConfig from "./CockPitOperationIndicatorConfig";
import CockPitDataSheet from "./CockPitDataSheet";
import CountUp from "../../../components/CountUp/CountUp";
import useCockpit from "../../../service/useCockpit";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "../../../contexts/AuthContext";
import useNotifier from "../../../hooks/useNotifier";
import calcPercent from "../../../utils/calcPercent";

const CardPanel = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: `#0008`,
  fontSize: "1em",
  borderRadius: `5px`,
  padding: 5,
}));

const LabelPanel = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.primary.light}`,
  fontWeight: "600",
  fontSize: 13,
}));

const OverweightRoundedAMA = styled(Overweight)({
  "& > *": { fill: "#c331d6" },
  width: 20,
  height: 20,
});

const AutisticRoundedAMA = styled(Autistic)({
  "& > *": { fill: "#c331d6" },
  width: 20,
  height: 20,
});

const OverweightRoundedUBS = styled(Overweight)({
  "& > *": { fill: "#2CB043" },
  width: 20,
  height: 20,
});

const AutisticRoundedUBS = styled(Autistic)({
  "& > *": { fill: "#2CB043" },
  width: 20,
  height: 20,
});

const RankingCard = ({ color, quantity, time }) => {
  const colors = {
    Azul: "#3c65d6",
    Verde: "#2CB019",
    Amarelo: "#FD0",
    Laranja: "#FA0",
    Vermelho: "#F54242",
    "Aguardando Class.": "#0008",
  };
  return (
    <Box
      sx={{
        borderLeft: `4px solid ${colors[color]}`,
        borderRadius: 1,
        display: "flex",
        justifyContent: "space-between",
        padding: 1,
        alignItems: "center",
        backgroundColor: "#0001",
      }}
    >
      <Box>
        <Box display={"flex"} gap={0.6}>
          <Typography fontSize={10} fontWeight={500}>
            Fila:
          </Typography>
          <Typography fontWeight={600} fontSize={11} color={colors[color]}>
            {color}
          </Typography>
        </Box>
        <Box display={"flex"} gap={0.6}>
          <Typography fontSize={10} fontWeight={500}>
            Qtd:
          </Typography>
          <Typography fontSize={12} fontWeight={600} color={colors[color]}>
            {quantity}
          </Typography>
        </Box>
      </Box>
      <Typography fontWeight={600} fontSize={12} color={"#0008"}>
        <ClockIcon sx={{ color: "#6452E8", width: 13, height: 13 }} />
        {time}
      </Typography>
    </Box>
  );
};

function formatPercent(value) {
  return Math.round(value) + "%";
}

const HorizontalBarChart = ({ data, fill }) => {
  return (
    <CustomizedBarChart
      sx={{ minWidth: 100 }}
      width={100}
      height={20}
      data={[{ number: data, fill: fill }]}
      config={barConfig}
      layout={"vertical"}
      x={{ hide: true, type: "number" }}
      y={{ hide: true, type: "category" }}
      radius={7}
      barSize={"100%"}
      limit={100}
      label={{
        position: data < 60 ? "right" : "center",
        fontSize: ".8rem",
        fontWeight: 600,
        fill: data < 60 ? "#0009" : "#FFF",
        formatter: formatPercent,
      }}
      margin={{ bottom: -20 }}
    />
  );
};

const OrderRadialBarChart = ({
  centerValue = 0,
  topValues = [],
  bottomValues = [],
  gradientId,
  percent = false,
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    }}
  >
    <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
      {topValues?.map(({ icon, value, position }, index) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: position === "left" ? 78 : -30,
            gap: 0.3,
          }}
          key={value + "-" + index}
        >
          {icon}
          <Typography fontSize={11} fontWeight={600}>
            {value}
          </Typography>
        </Box>
      ))}
    </Box>
    <RadialBarChart
      animation={false}
      data={[
        {
          value: centerValue,
        },
      ]}
      limit={percent ? 100 : false}
      radials={[
        {
          key: "value",
          gradient: gradientId,
          background: "#0004",
        },
      ]}
      polarX={{ dataKey: "value" }}
      label={{
        key: "value",
        position: "center",
        fill: "#0009",
        fontSize: 20,
        fontWeight: 600,
        formatter: percent && formatPercent,
      }}
      sx={{ width: 85, height: 85 }}
    />
    {bottomValues?.map(({ icon, value, position }, index) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          bottom: 2,
          left: position === "left" ? 74 : -22,
        }}
        key={value + "-" + index}
      >
        {icon}
        <Typography fontSize={11} fontWeight={600}>
          {value}
        </Typography>
      </Box>
    ))}
  </Box>
);

const barConfig = {
  bars: [{ key: "number" }],
};

const servicesAMA = {
  color: "#c331d6",
};

const servicesUBS = {
  color: "#2CB043",
};

const legend = [
  {
    icon: <AttendanceExceeded style={{ width: 24, height: 24 }} />,
    label: "Atend. Excedidos",
  },
  {
    icon: <MonitoringExceeded style={{ width: 24, height: 24 }} />,
    label: "Monitoramentos Excedidos",
  },
  {
    icon: <AttendanceWithoutPatient style={{ width: 24, height: 24 }} />,
    label: "Atend. sem Pacientes",
  },
  {
    icon: <ClassificationWithoutPatient style={{ width: 24, height: 24 }} />,
    label: "Class. sem Pacientes",
  },
  {
    icon: <MedicationNotFinished style={{ width: 24, height: 24 }} />,
    label: "Medicações não Finalizadas",
  },
  {
    icon: <RegulationNotFinished style={{ width: 24, height: 24 }} />,
    label: "Regulações não Finalizadas",
  },
  {
    icon: <ExamLabNotFinished style={{ width: 24, height: 24 }} />,
    label: "Exames Lab não Finalizados",
  },
  {
    icon: <EcgNotFinished style={{ width: 24, height: 24 }} />,
    label: "ECGs não Finalizados",
  },
  {
    icon: <XrayNotFinished style={{ width: 24, height: 24 }} />,
    label: "Exames de Imagem não Finalizados",
  },
  {
    icon: <ProceduresNotFinished style={{ width: 24, height: 24 }} />,
    label: "Procedimentos não Finalizados",
  },
  {
    icon: <DocumentationNotFinished style={{ width: 24, height: 24 }} />,
    label: "Declarações não Finalizadas",
  },
  { icon: <DirectionsRunRounded />, label: "Evadidos" },
  { icon: <TaskAlt />, label: "Confirmados" },
  { icon: <EventNote />, label: "Agendados" },
];

export default function CockPitOperationIndicator() {
  const [settingsDialog, setSettingsDialog] = useState(false);
  const [dialogDataSheet, setDialogDataSheet] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const {
    getQuantityTokens,
    getRiskClassification,
    getPatientsClassifieds,
    getMonitoring,
    getServices,
  } = useCockpit();
  const queryClient = useQueryClient();
  const { userData } = useContext(AuthContext);
  const notify = useNotifier();
  const handleDialog = () => {
    setSettingsDialog(!settingsDialog);
  };
  const handleDataSheet = () => {
    setDialogDataSheet(!dialogDataSheet);
  };

  const params = { company_id: userData?.company?.id };
  const requestConfig = {
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: userData?.company?.id ? true : false,
  };

  const queryQuantityTokens = useQuery(
    ["cockpitQuantityTokens", userData?.company?.id],
    () => getQuantityTokens(params),
    {
      ...requestConfig,
      initialData: [
        {
          priorities: {
            ama: [{ priority: "", quantity: 0 }],
            ubs: [{ priority: "", quantity: 0 }],
          },
        },
      ],
      onSuccess: () => {
        setRefetch(false);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const queryRiskClassification = useQuery(
    ["cockpitRiskClassification", userData?.company?.id],
    () => getRiskClassification(params),
    {
      ...requestConfig,
      initialData: [
        {
          qtd_classification: 0,
        },
      ],
      onSuccess: () => {
        setRefetch(false);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const queryPatientsClassifieds = useQuery(
    ["cockpitPatientsClassifieds", userData?.company?.id],
    () => getPatientsClassifieds(params),
    {
      ...requestConfig,
      initialData: [
        {
          classificacao: "",
          qtd: 0,
          tempo: "00:00:00",
        },
      ],
      onSuccess: () => {
        setRefetch(false);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const queryMonitoring = useQuery(
    ["cockpitMonitoring", userData?.company?.id],
    () => getMonitoring(params),
    {
      ...requestConfig,
      initialData: [
        {
          escaped_patient_ama: 0,
          escaped_patient_ubs: 0,
          gender_female_ubs: 0,
          gender_male_ubs: 0,
          gender_undefined_ubs: 0,
          gender_female_ama: 0,
          gender_male_ama: 0,
          gender_undefined_ama: 0,
          quantity_attendance_ama: 0,
          time_avg_ama: 0,
          quantity_attendance_ubs: 0,
          time_avg_ubs: 0,
          scheduling: 0,
          scheduling_confirmed: 0,
          discharge_ama: 0,
          discharge_ubs: 0,
          soap_ama: 0,
          soap_ubs: 0,
          attendance_exceeded_ubs: 0,
          attendance_exceeded_ama: 0,
          monitoring_exceeded_ubs: 0,
          monitoring_exceeded_ama: 0,
          attendance_without_patients_ama: 0,
          attendance_without_patients_ubs: 0,
          classification_without_patients: 0,
          undocumented_classification: 0,
          exceeded_not_finished_medical_record_in_medications_on_site_ubs: 0,
          exceeded_not_finished_medical_record_in_medications_on_site_ama: 0,
          exceeded_not_finished_medical_record_in_medications_ubs: 0,
          exceeded_not_finished_medical_record_in_medications_ama: 0,
          exceeded_not_finished_medical_record_in_against_reference_ubs: 0,
          exceeded_not_finished_medical_record_in_against_reference_ama: 0,
          exceeded_not_finished_medical_record_in_lab_ubs: 0,
          exceeded_not_finished_medical_record_in_lab_ama: 0,
          exceeded_not_finished_medical_record_in_ecg_ubs: 0,
          exceeded_not_finished_medical_record_in_ecg_ama: 0,
          exceeded_not_finished_medical_record_in_xray_ubs: 0,
          exceeded_not_finished_medical_record_in_xray_ama: 0,
          exceeded_not_finished_medical_record_in_procedure_ubs: 0,
          exceeded_not_finished_medical_record_in_procedure_ama: 0,
          exceeded_not_finished_medical_record_in_documentation_ubs: 0,
          exceeded_not_finished_medical_record_in_documentation_ama: 0,
        },
      ],
      onSuccess: () => {
        setRefetch(false);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const queryServices = useQuery(
    ["cockpitServices", userData?.company?.id],
    () => getServices(params),
    {
      ...requestConfig,
      initialData: [
        {
          services: {
            AMA: {
              total: 0,
              services: [{ quantity_ama: 0, services_ama: "" }],
            },
            UBS: {
              total: 0,
              services: [{ quantity_ubs: 0, services_ubs: "" }],
            },
          },
        },
      ],
      onSuccess: () => {
        setRefetch(false);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const queryIdentifiers = [
    "cockpitQuantityTokens",
    "cockpitRiskClassification",
    "cockpitPatientsClassifieds",
    "cockpitMonitoring",
    "cockpitServices",
  ];

  if (
    queryQuantityTokens.isLoading ||
    queryRiskClassification.isLoading ||
    queryPatientsClassifieds.isLoading ||
    queryMonitoring.isLoading ||
    queryServices.isLoading
  ) {
    return <Loading />;
  }

  const quantityByGenderUBS = [
    { icon: <Male />, value: queryMonitoring?.data[0]?.gender_male_ubs || 0 },
    {
      icon: <Female />,
      value: queryMonitoring?.data[0]?.gender_female_ubs || 0,
    },
    {
      icon: <Transgender />,
      value: queryMonitoring?.data[0]?.gender_undefined_ubs || 0,
    },
  ];

  const quantityByGenderAMA = [
    { icon: <Male />, value: queryMonitoring?.data[0]?.gender_male_ama || 0 },
    {
      icon: <Female />,
      value: queryMonitoring?.data[0]?.gender_female_ama || 0,
    },
    {
      icon: <Transgender />,
      value: queryMonitoring?.data[0]?.gender_undefined_ama || 0,
    },
  ];

  const prioritiesAMA = queryQuantityTokens?.data[0]?.priorities?.ama?.map(
    ({ priority, quantity }) => {
      let priorities;
      switch (priority) {
        case "NORMAL":
          priorities = {
            priority: "Normal",
            quantity: quantity,
          };
          break;
        case "PC":
          priorities = {
            icon: <EscalatorWarningRounded />,
            priority: priority,
            quantity: quantity,
          };
          break;
        case "PA":
          priorities = {
            icon: <AutisticRoundedAMA />,
            priority: priority,
            quantity: quantity,
          };
          break;
        case "PD":
          priorities = {
            icon: <AccessibleRounded />,
            priority: priority,
            quantity: quantity,
          };
          break;
        case "PG":
          priorities = {
            icon: <PregnantWomanRounded />,
            priority: priority,
            quantity: quantity,
          };
          break;
        case "OB":
          priorities = {
            icon: <OverweightRoundedAMA />,
            priority: priority,
            quantity: quantity,
          };
          break;
        case "PI":
          priorities = {
            icon: (
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 13,
                  color: "#c331d6",
                  marginBottom: 0.8,
                }}
              >
                60+
              </Typography>
            ),
            priority: priority,
            quantity: quantity,
          };
          break;
        case "PS":
          priorities = {
            icon: (
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 13,
                  color: "#c331d6",
                  marginBottom: 0.8,
                }}
              >
                80+
              </Typography>
            ),
            priority: priority,
            quantity: quantity,
          };
          break;
        default:
          priorities = {
            priority: priority,
            quantity: quantity,
          };
      }
      return priorities;
    }
  );

  const prioritiesUBS = queryQuantityTokens?.data[0]?.priorities?.ubs?.map(
    ({ priority, quantity }) => {
      let priorities;
      switch (priority) {
        case "NORMAL":
          priorities = {
            priority: "Normal",
            quantity: quantity,
          };
          break;
        case "PC":
          priorities = {
            icon: <EscalatorWarningRounded />,
            priority: priority,
            quantity: quantity,
          };
          break;
        case "PA":
          priorities = {
            icon: <AutisticRoundedUBS />,
            priority: priority,
            quantity: quantity,
          };
          break;
        case "PD":
          priorities = {
            icon: <AccessibleRounded />,
            priority: priority,
            quantity: quantity,
          };
          break;
        case "PG":
          priorities = {
            icon: <PregnantWomanRounded />,
            priority: priority,
            quantity: quantity,
          };
          break;
        case "OB":
          priorities = {
            icon: <OverweightRoundedUBS />,
            priority: priority,
            quantity: quantity,
          };
          break;
        case "PI":
          priorities = {
            icon: (
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 13,
                  color: "#2CB043",
                  marginBottom: 0.8,
                }}
              >
                60+
              </Typography>
            ),
            priority: priority,
            quantity: quantity,
          };
          break;
        case "PS":
          priorities = {
            icon: (
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 13,
                  color: "#2CB043",
                  marginBottom: 0.8,
                }}
              >
                80+
              </Typography>
            ),
            priority: priority,
            quantity: quantity,
          };
          break;
        default:
          priorities = {
            priority: priority,
            quantity: quantity,
          };
      }
      return priorities;
    }
  );

  const dataDischarged = () => {
    if (queryQuantityTokens?.data[0]?.priorities?.ama == null) {
      return [
        {
          number: parseInt(queryMonitoring?.data[0]?.discharge_ubs || 0),
          category: "UBS",
          fill: "url(#gdr-2)",
        },
      ];
    } else if (queryQuantityTokens?.data[0]?.priorities?.ubs == null) {
      return [
        {
          number: parseInt(queryMonitoring?.data[0]?.discharge_ama || 0),
          category: "AMA",
          fill: "url(#gdr-1)",
        },
      ];
    } else {
      return [
        {
          number: parseInt(queryMonitoring?.data[0]?.discharge_ama || 0),
          category: "AMA",
          fill: "url(#gdr-1)",
        },
        {
          number: parseInt(queryMonitoring?.data[0]?.discharge_ubs || 0),
          category: "UBS",
          fill: "url(#gdr-2)",
        },
      ];
    }
  };

  const pendingIndicators = [
    {
      name: "Atendimentos Excedidos:",
      icon: <AttendanceExceeded style={{ width: 32, height: 32 }} />,
      ama: queryMonitoring?.data[0]?.attendance_exceeded_ama || 0,
      ubs: queryMonitoring?.data[0]?.attendance_exceeded_ubs || 0,
    },
    {
      name: "Monitoramentos Excedidos:",
      icon: <MonitoringExceeded style={{ width: 32, height: 32 }} />,
      ama: queryMonitoring?.data[0]?.monitoring_exceeded_ama || 0,
      ubs: queryMonitoring?.data[0]?.monitoring_exceeded_ubs || 0,
    },
    {
      name: "Atend. sem Pacientes:",
      icon: <AttendanceWithoutPatient style={{ width: 32, height: 32 }} />,
      ama: queryMonitoring?.data[0]?.attendance_without_patients_ama || 0,
      ubs: queryMonitoring?.data[0]?.attendance_without_patients_ubs || 0,
    },
    {
      name: "Class. sem Pacientes:",
      icon: <ClassificationWithoutPatient style={{ width: 32, height: 32 }} />,
      ama: queryMonitoring?.data[0]?.classification_without_patients || 0,
    },
    {
      name: "Medicações não Finalizados:",
      icon: <MedicationNotFinished style={{ width: 32, height: 32 }} />,
      ama:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_medications_on_site_ama ||
        0,
      ubs:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_medications_on_site_ubs ||
        0,
    },
    {
      name: "Regulações não Finalizadas:",
      icon: <RegulationNotFinished style={{ width: 32, height: 32 }} />,
      ama:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_against_reference_ubs || 0,
      ubs:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_against_reference_ubs || 0,
    },
    {
      name: "Exames Lab não Finalizados:",
      icon: <ExamLabNotFinished style={{ width: 32, height: 32 }} />,
      ama:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_lab_ama || 0,
      ubs:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_lab_ubs || 0,
    },
    {
      name: "ECGs não Finalizados:",
      icon: <EcgNotFinished style={{ width: 32, height: 32 }} />,
      ama:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_ecg_ama || 0,
      ubs:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_ecg_ubs || 0,
    },
    {
      name: "Exames de Imagem não Finalizados:",
      icon: <XrayNotFinished style={{ width: 32, height: 32 }} />,
      ama:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_xray_ama || 0,
      ubs:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_xray_ubs || 0,
    },
    {
      name: "Procedimentos não Finalizados:",
      icon: <ProceduresNotFinished style={{ width: 32, height: 32 }} />,
      ama:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_procedure_ama || 0,
      ubs:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_procedure_ubs || 0,
    },
    {
      name: "Declarações não Finalizadas:",
      icon: <DocumentationNotFinished style={{ width: 32, height: 32 }} />,
      ama:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_documentation_ama || 0,
      ubs:
        queryMonitoring?.data[0]
          ?.exceeded_not_finished_medical_record_in_documentation_ubs || 0,
    },
  ];

  return (
    <>
      <DialogMedium
        open={settingsDialog}
        title={"Configuração do Painel"}
        handleClose={handleDialog}
        fullWidth={true}
      >
        <CockPitOperationIndicatorConfig setDialog={setSettingsDialog} />
      </DialogMedium>
      <Dialog
        open={dialogDataSheet}
        title={"Ficha Técnica"}
        handleClose={handleDataSheet}
        sx={{ maxWidth: 900, width: "100%", marginY: 10, marginX: 50 }}
      >
        <CockPitDataSheet legend={legend} />
      </Dialog>
      <BoxContainer
        title={
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography>Indicadores de Operação</Typography>
            <Box display={"flex"} gap={0.3} alignItems={"center"}>
              <Button
                sx={{ height: 30 }}
                variant="contained"
                disabled={refetch}
                onClick={() => {
                  queryIdentifiers.forEach((identifier) => {
                    queryClient.invalidateQueries(identifier);
                  });
                  setRefetch(true);
                }}
              >
                Atualizar
              </Button>
              <Tooltip title="Ficha Técnica">
                <IconButton color="primary" onClick={handleDataSheet}>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
              {/* <Button
                onClick={handleDialog}
                sx={{ height: 25 }}
                variant="outlined"
              >
                <SettingsRounded fontSize="small" color="primary" />
              </Button> */}
            </Box>
          </Box>
        }
      >
        <Grid container spacing={1} textAlign={"center"}>
          <Grid item lg={4.25} md={6} xs={12}>
            <LabelPanel>AMA</LabelPanel>
            <CardPanel
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Box display={"flex"} gap={4}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <RadialBarChart
                    animation={false}
                    data={[
                      {
                        qtd_aten_ama: parseInt(
                          queryMonitoring?.data[0]?.quantity_attendance_ama || 0
                        ),
                      },
                    ]}
                    radials={[
                      {
                        key: "qtd_aten_ama",
                        gradient: "gdr-1",
                        background: "#0004",
                      },
                    ]}
                    label={{
                      key: "qtd_aten_ama",
                      content: ({ value }) => (
                        <CountUp
                          finalValue={value}
                          sx={{ fontSize: 20, fontWeight: 600, fill: "#0009" }}
                        />
                      ),
                    }}
                    sx={{ width: 85, height: 85 }}
                    gradients={[
                      {
                        id: "gdr-1",
                        from: "#c331d6",
                        to: "#f589e6",
                      },
                    ]}
                  />
                  <Typography fontSize={12} fontWeight={700}>
                    Em Atendimento
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <OrderRadialBarChart
                    centerValue={
                      queryMonitoring?.data[0]?.escaped_patient_ama || 0
                    }
                    gradientId={"gdr-1"}
                    bottomValues={[
                      {
                        value: "",
                        icon: <DirectionsRunRounded />,
                        position: "left",
                      },
                    ]}
                  />
                  <Typography fontSize={12} fontWeight={700}>
                    Evadidos
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <RadialBarChart
                    animation={false}
                    data={[
                      {
                        qtd_soap_ama: queryMonitoring?.data[0]?.soap_ama || 0,
                      },
                    ]}
                    radials={[
                      {
                        key: "qtd_soap_ama",
                        gradient: "gdr-1",
                        background: "#0004",
                      },
                    ]}
                    label={{
                      key: "qtd_soap_ama",
                      content: ({ value }) => (
                        <CountUp
                          finalValue={value}
                          sx={{ fontSize: 20, fontWeight: 600, fill: "#0009" }}
                        />
                      ),
                    }}
                    sx={{ width: 85, height: 85 }}
                    gradients={[
                      {
                        id: "gdr-1",
                        from: "#c331d6",
                        to: "#f589e6",
                      },
                    ]}
                  />
                  <Typography fontSize={12} fontWeight={700}>
                    SOAPs
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <RadialBarChart
                    animation={false}
                    data={[
                      {
                        qtd_services_ama:
                          queryServices?.data[0]?.services?.AMA?.total,
                      },
                    ]}
                    radials={[
                      {
                        key: "qtd_services_ama",
                        gradient: "gdr-1",
                        background: "#0004",
                      },
                    ]}
                    label={{
                      key: "qtd_services_ama",
                      content: ({ value }) => (
                        <CountUp
                          finalValue={value}
                          sx={{ fontSize: 20, fontWeight: 600, fill: "#0009" }}
                        />
                      ),
                    }}
                    sx={{ width: 85, height: 85 }}
                    gradients={[
                      {
                        id: "gdr-1",
                        from: "#c331d6",
                        to: "#f589e6",
                      },
                    ]}
                  />
                  <Typography fontSize={12} fontWeight={700}>
                    Total de Serviços
                  </Typography>
                </Box>
              </Box>
              <Grid container spacing={1} mt={1.5}>
                <Grid item xs={7.75}>
                  <LabelPanel>Serviços</LabelPanel>
                  <CardPanel height={305} sx={{ overflowY: "scroll" }}>
                    {queryServices?.data[0]?.services?.AMA?.services?.map(
                      (service, index) => (
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          key={service.services_ama + "-" + index}
                        >
                          <Typography fontWeight={600} fontSize={10}>
                            {service.services_ama} -
                          </Typography>
                          <Box
                            display={"flex"}
                            gap={3}
                            color={servicesAMA.color}
                          >
                            <CountUp
                              finalValue={service.quantity_ama}
                              sx={{ fontSize: 12, fontWeight: 600 }}
                            />
                            <HorizontalBarChart
                              data={calcPercent(
                                service.quantity_ama,
                                queryServices?.data[0]?.services?.AMA?.total
                              )}
                              fill={servicesAMA.color}
                            />
                          </Box>
                        </Box>
                      )
                    )}
                  </CardPanel>
                </Grid>
                <Grid item xs={4.25}>
                  <LabelPanel>Prioritários</LabelPanel>
                  <CardPanel mb={1} height={150} sx={{ overflowY: "scroll" }}>
                    <Grid container>
                      {prioritiesAMA?.map((service, index) => (
                        <Grid
                          item
                          xs={4}
                          paddingX={0.4}
                          paddingY={0.2}
                          alignItems={"center"}
                          key={service.priority + "-" + index}
                        >
                          {service.icon && (
                            <Typography
                              color={"#c331d6"}
                              borderBottom={0.6}
                              borderColor={"#0006"}
                            >
                              {service.icon}
                            </Typography>
                          )}
                          <CountUp
                            finalValue={service.quantity}
                            sx={{
                              fontSize: 13,
                              fontWeight: 700,
                              color: servicesAMA.color,
                            }}
                          />
                          <Typography fontWeight={600} fontSize={10}>
                            {service.priority}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </CardPanel>
                  <LabelPanel>Gênero</LabelPanel>
                  <CardPanel
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    gap={1}
                    mb={1}
                  >
                    {quantityByGenderAMA.map(({ icon, value }, index) => (
                      <Box alignItems={"center"} key={value + "-" + index}>
                        {icon}
                        <Box>
                          <CountUp
                            finalValue={value}
                            sx={{
                              fontSize: 13,
                              fontWeight: 700,
                              color: servicesAMA.color,
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </CardPanel>
                  <LabelPanel>Tempo Médio do Atend.</LabelPanel>
                  <CardPanel display={"flex"} flexDirection={"column"} gap={1}>
                    <Box display={"flex"} gap={0.5} justifyContent={"center"}>
                      <ClockIcon sx={{ color: "#6452E8" }} />
                      <Typography fontSize={19} fontWeight={600}>
                        {queryMonitoring?.data[0]?.time_avg_ama || "00:00:00"}
                      </Typography>
                    </Box>
                  </CardPanel>
                </Grid>
              </Grid>
            </CardPanel>
          </Grid>
          <Grid item lg={4.25} md={6} xs={12}>
            <LabelPanel>UBS</LabelPanel>
            <CardPanel
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Box display={"flex"} gap={5}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <RadialBarChart
                    animation={false}
                    data={[
                      {
                        qtd_aten_ama: parseInt(
                          queryMonitoring?.data[0]?.quantity_attendance_ubs || 0
                        ),
                      },
                    ]}
                    radials={[
                      {
                        key: "qtd_aten_ama",
                        gradient: "gdr-2",
                        background: "#0004",
                      },
                    ]}
                    label={{
                      key: "qtd_aten_ama",
                      content: ({ value }) => (
                        <CountUp
                          finalValue={value}
                          sx={{ fontSize: 20, fontWeight: 600, fill: "#0009" }}
                        />
                      ),
                    }}
                    sx={{ width: 85, height: 85 }}
                    gradients={[
                      {
                        id: "gdr-2",
                        from: "#12b02b",
                        to: "#6fed84",
                      },
                    ]}
                  />
                  <Typography fontSize={12} fontWeight={700}>
                    Em Atendimento
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <OrderRadialBarChart
                    centerValue={calcPercent(
                      queryMonitoring?.data[0]?.scheduling_confirmed,
                      queryMonitoring?.data[0]?.scheduling
                    )}
                    percent={true}
                    gradientId={"gdr-2"}
                    topValues={[
                      {
                        value:
                          queryMonitoring?.data[0]?.scheduling_confirmed || 0,
                        icon: <TaskAlt />,
                        position: "left",
                      },
                      {
                        value: queryMonitoring?.data[0]?.scheduling || 0,
                        icon: <EventNote />,
                        position: "right",
                      },
                    ]}
                    bottomValues={[
                      {
                        value:
                          queryMonitoring?.data[0]?.escaped_patient_ubs || 0,
                        icon: <DirectionsRunRounded />,
                        position: "left",
                      },
                    ]}
                  />
                  <Typography fontSize={12} fontWeight={700}>
                    Presenças
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <RadialBarChart
                    animation={false}
                    data={[
                      {
                        qtd_soap_ubs: queryMonitoring?.data[0]?.soap_ubs || 0,
                      },
                    ]}
                    radials={[
                      {
                        key: "qtd_soap_ubs",
                        gradient: "gdr-2",
                        background: "#0004",
                      },
                    ]}
                    label={{
                      key: "qtd_soap_ubs",
                      position: "center",
                      content: ({ value }) => (
                        <CountUp
                          finalValue={value}
                          sx={{ fontSize: 20, fontWeight: 600, fill: "#0009" }}
                        />
                      ),
                    }}
                    sx={{ width: 85, height: 85 }}
                    gradients={[
                      {
                        id: "gdr-2",
                        from: "#12b02b",
                        to: "#6fed84",
                      },
                    ]}
                  />
                  <Typography fontSize={12} fontWeight={700}>
                    SOAPs
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <RadialBarChart
                    animation={false}
                    data={[
                      {
                        qtd_services_ubs:
                          queryServices?.data[0]?.services?.UBS?.total,
                      },
                    ]}
                    radials={[
                      {
                        key: "qtd_services_ubs",
                        gradient: "gdr-2",
                        background: "#0004",
                      },
                    ]}
                    label={{
                      key: "qtd_services_ubs",
                      position: "center",
                      content: ({ value }) => (
                        <CountUp
                          finalValue={value}
                          sx={{ fontSize: 20, fontWeight: 600, fill: "#0009" }}
                        />
                      ),
                    }}
                    sx={{ width: 85, height: 85 }}
                    gradients={[
                      {
                        id: "gdr-2",
                        from: "#12b02b",
                        to: "#6fed84",
                      },
                    ]}
                  />
                  <Typography fontSize={12} fontWeight={700}>
                    Total de Serviços
                  </Typography>
                </Box>
              </Box>
              <Grid container spacing={1} mt={1.5}>
                <Grid item xs={7.75}>
                  <LabelPanel>Serviços</LabelPanel>
                  <CardPanel height={305} sx={{ overflowY: "scroll" }}>
                    {queryServices?.data[0]?.services?.UBS?.services?.map(
                      (service, index) => (
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          key={service.services_ubs + "-" + index}
                        >
                          <Typography fontWeight={600} fontSize={10}>
                            {service.services_ubs} -
                          </Typography>
                          <Box display={"flex"} gap={2.5}>
                            <CountUp
                              finalValue={service.quantity_ubs}
                              sx={{
                                fontSize: 12,
                                fontWeight: 600,
                                color: servicesUBS.color,
                              }}
                            />
                            <HorizontalBarChart
                              data={calcPercent(
                                service.quantity_ubs,
                                queryServices?.data[0]?.services?.UBS?.total
                              )}
                              fill={servicesUBS.color}
                            />
                          </Box>
                        </Box>
                      )
                    )}
                  </CardPanel>
                </Grid>
                <Grid item xs={4.25}>
                  <LabelPanel>Prioritários</LabelPanel>
                  <CardPanel mb={1} height={150} sx={{ overflowY: "scroll" }}>
                    <Grid container>
                      {prioritiesUBS?.map((service, index) => (
                        <Grid
                          item
                          xs={4}
                          paddingX={0.4}
                          paddingY={0.2}
                          alignItems={"center"}
                          key={service.priority + "-" + index}
                        >
                          {service.icon && (
                            <Typography
                              color={servicesUBS.color}
                              borderBottom={0.6}
                              borderColor={"#0006"}
                            >
                              {service.icon}
                            </Typography>
                          )}
                          <CountUp
                            finalValue={service.quantity}
                            sx={{
                              fontSize: 13,
                              fontWeight: 700,
                              color: servicesUBS.color,
                            }}
                          />
                          <Typography fontWeight={600} fontSize={10}>
                            {service.priority}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </CardPanel>
                  <LabelPanel>Gênero</LabelPanel>
                  <CardPanel
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    gap={1}
                    mb={1}
                  >
                    {quantityByGenderUBS.map(({ icon, value }, index) => (
                      <Box alignItems={"center"} key={value + "-" + index}>
                        {icon}
                        <Box>
                          <CountUp
                            finalValue={value}
                            sx={{
                              fontSize: 13,
                              fontWeight: 700,
                              color: servicesUBS.color,
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </CardPanel>
                  <LabelPanel>Tempo Médio do Atend.</LabelPanel>
                  <CardPanel display={"flex"} flexDirection={"column"} gap={1}>
                    <Box display={"flex"} gap={0.5} justifyContent={"center"}>
                      <ClockIcon sx={{ color: "#6452E8" }} />
                      <Typography fontSize={19} fontWeight={600}>
                        {queryMonitoring?.data[0]?.time_avg_ubs || "00:00:00"}
                      </Typography>
                    </Box>
                  </CardPanel>
                </Grid>
              </Grid>
            </CardPanel>
          </Grid>
          <Grid item lg={3.5} md={12} xs={12} textAlign={"center"}>
            <LabelPanel>Pacientes Liberados</LabelPanel>
            <CardPanel height={210}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <CustomizedBarChart
                    sx={{ padding: 1, gap: 2 }}
                    width={"100%"}
                    height={207}
                    data={dataDischarged()}
                    config={barConfig}
                    layout="horizontal"
                    x={{
                      type: "category",
                      dataKey: "category",
                      axisLine: false,
                      tickLine: false,
                      fontWeight: 600,
                    }}
                    y={{ type: "number", axisLine: true, tickLine: false }}
                    label={{
                      position: "center",
                      fontSize: "1.2rem",
                      fontWeight: 600,
                      fill: "#FFF",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  {!!queryQuantityTokens?.data[0]?.priorities?.ama && (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      <RadialBarChart
                        animation={false}
                        data={[
                          {
                            qtd_aten_ama: calcPercent(
                              queryMonitoring?.data[0]?.quantity_attendance_ama,
                              queryMonitoring?.data[0]?.discharge_ama
                            ),
                          },
                        ]}
                        limit={100}
                        radials={[
                          {
                            key: "qtd_aten_ama",
                            gradient: "gdr-1",
                            background: "#0004",
                          },
                        ]}
                        polarX={{ dataKey: "qtd_aten_ama" }}
                        label={{
                          key: "qtd_aten_ama",
                          position: "center",
                          fill: "#0009",
                          fontSize: 20,
                          fontWeight: 600,
                          formatter: (value) => value + "%",
                        }}
                        sx={{ width: 75, height: 75 }}
                      />
                      <Typography fontWeight={700}>AMA</Typography>
                    </Box>
                  )}
                  {!!queryQuantityTokens?.data[0]?.priorities?.ubs && (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      <RadialBarChart
                        animation={false}
                        data={[
                          {
                            qtd_aten_ubs: calcPercent(
                              queryMonitoring?.data[0]?.discharge_ubs,
                              queryMonitoring?.data[0]?.quantity_attendance_ubs
                            ),
                          },
                        ]}
                        limit={100}
                        radials={[
                          {
                            key: "qtd_aten_ubs",
                            gradient: "gdr-2",
                            background: "#0004",
                          },
                        ]}
                        polarX={{ dataKey: "qtd_aten_ubs" }}
                        label={{
                          key: "qtd_aten_ubs",
                          position: "center",
                          fill: "#0009",
                          fontSize: 20,
                          fontWeight: 600,
                          formatter: formatPercent,
                        }}
                        sx={{ width: 75, height: 75 }}
                      />
                      <Typography fontWeight={700}>UBS</Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CardPanel>
            <LabelPanel>Classificação de Risco</LabelPanel>
            <CardPanel height={230}>
              <Grid container spacing={0.5}>
                {queryPatientsClassifieds?.data?.map(
                  ({ classificacao, qtd, tempo }, index) => (
                    <Grid item xs={6} key={index + "-" + classificacao}>
                      <RankingCard
                        color={classificacao}
                        quantity={qtd}
                        time={tempo}
                      />
                    </Grid>
                  )
                )}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: "#0002",
                      border: "3px solid #6452E8",
                      borderRadius: 1,
                      alignItems: "center",
                      padding: 1,
                      gap: 0.7,
                    }}
                  >
                    <PreAttendance width={25} height={25} />
                    <Typography fontSize={11} fontWeight={600}>
                      Total de Classificados:
                    </Typography>
                    <Typography fontSize={13} fontWeight={700}>
                      {queryRiskClassification?.data[0]?.qtd_classification ||
                        0}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardPanel>
          </Grid>
          <CardPanel
            ml={1}
            mt={0.9}
            width={"100%"}
            height={42}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Box display={"flex"} justifyContent={"space-evenly"}>
              {pendingIndicators?.map(({ name, icon, ama, ubs }, index) => (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={0.5}
                  key={name + "-" + index}
                >
                  {icon}
                  <Typography
                    fontWeight={700}
                    fontSize={13}
                    color={servicesAMA.color}
                  >
                    {ama}
                  </Typography>
                  {ubs && (
                    <>
                      <Typography fontWeight={700} fontSize={11}>
                        |
                      </Typography>
                      <Typography
                        fontWeight={700}
                        fontSize={13}
                        color={servicesUBS.color}
                      >
                        {ubs}
                      </Typography>
                    </>
                  )}
                </Box>
              ))}
            </Box>
          </CardPanel>
        </Grid>
      </BoxContainer>
    </>
  );
}
