import React from "react";
import { useAdmeasurement, usePagination } from "../../../service";
import { DialogMedium } from "../../../helper";
import { useTheme } from "@emotion/react";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../config/yup";
import { Box, Button, Grid, TablePagination, Typography } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import {
  TextField,
  SwitchField,
  SelectField,
} from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

export default function AdmeasurementsStep() {
  const notify = useNotifier();
  const theme = useTheme();

  const { getAdmeasurement, postAdmeasurement, updateAdmeasurement } =
    useAdmeasurement();

  const [limit, setLimit] = React.useState(5);
  const [editAdmeasurementDialog, setEditAdmeasurementDialog] =
    React.useState(false);
  const [admeasurement, setAdmeasurement] = React.useState({});

  const createAdmeasurementMutation = useMutation(postAdmeasurement);
  const updateAdmeasurementMutation = useMutation(updateAdmeasurement);

  const admeasurementsQuery = useQuery(["admeasurements"], getAdmeasurement, {
    retry: false,
    initialData: [],
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  const { paginationInfo, handlePageChange } = usePagination(
    admeasurementsQuery.data,
    limit
  );

  const validator = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
  });
  const editValidator = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
    unit: Yup.string().required("É requerido"),
    active: Yup.boolean(),
    required: Yup.boolean(),
    maximum_age: Yup.number().nullable(),
    minimum_age: Yup.number().nullable(),
    pregnant: Yup.boolean(),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      describe: "",
      unit: "",
      system: "Ambos",
      required: false,
    },
    resolver: yupResolver(validator),
  });

  const editAdmeasurementForm = useForm({
    defaultValues: {
      describe: admeasurement?.describe,
      unit: admeasurement?.unit,
      active: admeasurement?.active,
      required: admeasurement?.required,
      maximum_age: admeasurement?.display_criteria?.maximum_age,
      minimum_age: admeasurement?.display_criteria?.minimum_age,
      pregnant: admeasurement?.display_criteria?.pregnant,
    },
    resolver: yupResolver(editValidator),
  });

  const handleCloseDialog = () => {
    setEditAdmeasurementDialog(false);
    setAdmeasurement({});
  };

  const onSubmit = handleSubmit((data) => {
    createAdmeasurementMutation.mutate(data, {
      onSuccess: (data) => {
        notify(data.message, "success");
        admeasurementsQuery.refetch();
        reset();
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    });
  });

  const onSubmitEdit = editAdmeasurementForm.handleSubmit((data) => {
    const dataSend = {
      ...data,
      display_criteria: {
        maximum_age: data.maximum_age,
        minimum_age: data.minimum_age,
        pregnant: data.pregnant,
      },
    };
    updateAdmeasurementMutation.mutate(
      { id: admeasurement.id, data: dataSend },
      {
        onSuccess: (data) => {
          notify(data.message, "success");
          admeasurementsQuery.refetch();
          handleCloseDialog();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  });

  const columns = [
    {
      field: "number",
      name: "Codigo",
      type: "string",
    },
    {
      field: "describe",
      name: "Descrição",
      type: "string",
    },
    {
      field: "unit",
      name: "Unidade",
      type: "string",
    },
    {
      field: "required",
      name: "Obrigatorio",
      type: "string",
      use: (value) => (value ? "Sim" : "Não"),
      alignInRow: "center",
      alignInHead: "center",
    },
    {
      field: "platform",
      name: "Sistema",
      type: "string",
    },
    {
      field: "active",
      name: "Status",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
      alignInRow: "center",
      alignInHead: "center",
    },
  ];

  return (
    <>
      <DialogMedium
        open={editAdmeasurementDialog}
        fullWidth
        maxWidth="md"
        title="Edição de item de aferição"
        handleClose={handleCloseDialog}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              control={editAdmeasurementForm.control}
              name="describe"
              label="Descrição"
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              control={editAdmeasurementForm.control}
              name="unit"
              label="Unidade"
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              control={editAdmeasurementForm.control}
              name="minimum_age"
              label="Faixa etária mínima"
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              control={editAdmeasurementForm.control}
              name="maximum_age"
              label="Faixa etária máxima"
              required
            />
          </Grid>
          <Grid item xs={3}>
            <SwitchField
              control={editAdmeasurementForm.control}
              name="pregnant"
              label="Ativo para Gestantes"
            />
          </Grid>
          <Grid item xs={3}>
            <SwitchField
              control={editAdmeasurementForm.control}
              name="required"
              label="Alterar Obrigatório"
            />
          </Grid>
          <Grid item xs={3}>
            <SwitchField
              control={editAdmeasurementForm.control}
              name="active"
              label="Alterar Status"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={onSubmitEdit}
              onLoad={updateAdmeasurementMutation.isLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Os itens configurados nesta serão exibidos no Subjetivo do atendimento
          SOAP
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items}
          columns={columns}
          loading={admeasurementsQuery.isLoading}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit"]}
          shouldDisableFields={["id"]}
          actionHandleEdit={(_, item) => {
            setAdmeasurement(item);
            editAdmeasurementForm.setValue("describe", item.describe);
            editAdmeasurementForm.setValue("active", item.active);
            editAdmeasurementForm.setValue("unit", item.unit);
            editAdmeasurementForm.setValue("required", item.required);
            editAdmeasurementForm.setValue(
              "maximum_age",
              item.display_criteria?.maximum_age
            );
            editAdmeasurementForm.setValue(
              "minimum_age",
              item.display_criteria?.minimum_age
            );
            editAdmeasurementForm.setValue(
              "pregnant",
              item.display_criteria?.pregnant
            );
            setEditAdmeasurementDialog(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Item de Aferição
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            control={control}
            name={"describe"}
            label={"Descrição"}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            control={control}
            name={"unit"}
            label={"Unidade"}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            control={control}
            name={"system"}
            label={"Exibido em"}
            required
            options={[
              { describe: "Ambos" },
              { describe: "Mobile" },
              { describe: "Navegador" },
            ]}
            optionLabelKey="describe"
          />
        </Grid>
        <Grid item xs={4}>
          <SwitchField
            control={control}
            name="required"
            label="Obrigatório ?"
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={onSubmit}
            onLoad={createAdmeasurementMutation.isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
