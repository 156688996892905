import React from "react";
import { styled, Typography, CircularProgress } from "@mui/material";
import PrescriptionCard from "./PrescriptionCard";
import { Box } from "@mui/system";
import { AppContext } from "../../../contexts/AppContext";
import { DialogMedium } from "../../../helper";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import { format, parseISO } from "date-fns";
import usePrescriptions from "../../../service/usePrescriptions";
import { useQuery } from "react-query";
import { theme } from "../../../config/theme";
import {
  drugOnSitePrescriptionPDF,
  drugPrescriptionPDF,
  openURL,
  sadtPDF,
  specialPrescriptionPDF,
} from "../../../pdfModels";
import useNotifier from "../../../hooks/useNotifier";

function PrescriptionList({ type = "medications", patient }) {
  const notify = useNotifier();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [history, setHistory] = React.useState([]);
  const [historyItems, setHistoryItems] = React.useState([]);
  const [collumns, setCollumns] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const { patientData } = React.useContext(AppContext);
  const { getPrescriptions } = usePrescriptions();

  // Requests
  const prescriptionQuery = useQuery(
    ["prescriptions", [patient?.id || patientData.id, type, page, limit]],
    () =>
      getPrescriptions(patient?.id || patientData.id, {
        page,
        limit,
        filter: type,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (response) => {
        const historyPrescriptions = response?.items.map((prescription) => {
          const {
            createdAt,
            medications_on_site,
            medications,
            exams,
            finishes_at,
            plan,
            signed_medications,
            signed_meds_on_site,
            signed_special_medications,
            signed_sadts,
            employee_specialty,
            company,
            patient,
          } = prescription;

          const documentData = {
            createdAt,
            company,
            employee: {
              ...employee_specialty.employee,
              specialty: employee_specialty.specialty,
            },
          };

          const typeSignedDocument = {
            medications: { signed_medications, signed_special_medications },
            exams: signed_sadts,
            medications_on_site: signed_meds_on_site,
          };

          const typeNotSignedDocument = {
            medications: (type, med) => {
              return type === "special_medications"
                ? specialPrescriptionPDF(
                    [med],
                    patient || patientData,
                    documentData
                  )
                : drugPrescriptionPDF(
                    medications?.filter((i) => !i.special),
                    patient || patientData,
                    documentData
                  );
            },
            exams: (type, exam) => {
              if (type === "images") {
                return sadtPDF(
                  "ecg",
                  {
                    cids: plan?.soap?.assessment?.cids,
                    prescriptions: [exam],
                  },
                  patient || patientData,
                  true,
                  documentData
                );
              } else if (type === "xray") {
                return sadtPDF(
                  "xray",
                  {
                    cids: plan?.soap?.assessment?.cids,
                    prescriptions: exams.filter(
                      ({ describe, type }) =>
                        describe.includes("RADIOGRAFIA") && type === "Imagem"
                    ),
                  },
                  patient || patientData,
                  true,
                  documentData
                );
              }

              return sadtPDF(
                type,
                { cids: plan?.soap?.assessment?.cids, prescriptions: exams },
                patient || patientData,
                true,
                documentData
              );
            },
            medications_on_site: () =>
              drugOnSitePrescriptionPDF(
                medications_on_site,
                patient || patientData,
                documentData
              ),
          };

          return {
            provider: employee_specialty.employee.name,
            CRM: employee_specialty.employee.council_registration_number,
            UF: employee_specialty.employee.id_council_state,
            initial_date: createdAt,
            final_date: finishes_at,
            time: format(parseISO(createdAt), "HH:mm'h'"),
            medications_on_site,
            medications,
            exams,
            signedDocument: typeSignedDocument[type],
            notSignedDocument: typeNotSignedDocument[type],
          };
        });
        setHistory((history) => [...history, ...historyPrescriptions]);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  // handle functions
  const handleScroll = ({ target }) => {
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 2) {
      if (
        page <= prescriptionQuery.data?.total &&
        !prescriptionQuery.isFetching
      ) {
        setPage(page + 1);
      }
    }
  };

  const toggleDialog = (medications, medications_on_site, exams) => {
    if (openDialog) {
      setHistoryItems([]);
      setOpenDialog(false);
    } else {
      if (type === "medications") {
        const formatedMedications = medications.map(
          ({
            name,
            quantity,
            presentation,
            posology,
            notes,
            suspended,
            continuous_use,
            suspended_at,
          }) => ({
            name,
            quantity,
            presentation,
            posology,
            continuous_use: continuous_use ? "Sim" : "Não",
            suspended_at: suspended
              ? format(parseISO(suspended_at), "dd/MM/yyyy")
              : "",
            notes,
            suspended,
          })
        );
        setHistoryItems(formatedMedications);
        setCollumns([
          { name: "Medicamento" },
          { name: "Quantidade" },
          { name: "Apresentação" },
          { name: "Posologia" },
          { name: "Uso contínuo" },
          { name: "Suspenso" },
          { name: "Descrição do Medicamento" },
        ]);
      } else if (type === "medications_on_site") {
        const formatedMedications = medications_on_site.map(
          ({
            name,
            quantity,
            presentation,
            posology,
            notes,
            suspended,
            suspended_at,
          }) => ({
            name,
            quantity,
            presentation,
            posology,
            suspended_at: suspended
              ? format(parseISO(suspended_at), "dd/MM/yyyy")
              : "",
            notes,
            suspended,
          })
        );
        setHistoryItems(formatedMedications);
        setCollumns([
          { name: "Medicamento" },
          { name: "Quantidade" },
          { name: "Apresentação" },
          { name: "Posologia" },
          { name: "Suspenso" },
          { name: "Descrição do Medicamento" },
        ]);
      } else {
        const formatedExams = exams.map(
          ({ type, describe, suspendedAt, finished, notes, id }) => ({
            type,
            describe,
            status: !!suspendedAt
              ? "Suspenso"
              : !!finished
              ? "Concluído"
              : "Pendente",
            suspendedAt: suspendedAt
              ? format(parseISO(suspendedAt), "dd/MM/yyyy")
              : "",
            suspended: !!suspendedAt,
            notes,
          })
        );
        setHistoryItems(formatedExams);
        setCollumns([
          { name: "Tipo" },
          { name: "Exame" },
          { name: "Status" },
          { name: "Suspenso em" },
          { name: "Observação" },
        ]);
      }
      setOpenDialog(true);
    }
  };

  // Internal Components
  const CardsContainer = React.useMemo(
    () =>
      styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        gap: 20,
        border: "1px solid " + theme.palette.primary.main,
        borderRadius: 10,
        padding: 10,
        minHeight: 140,
        maxHeight: 280,
        overflowY: "scroll",
      })),
    []
  );

  return (
    <>
      <CardsContainer onScrollCapture={handleScroll}>
        <Typography
          component="h3"
          sx={{
            fontSize: 16,
            fontWeight: 700,
            color: theme.palette.primary.light,
          }}
        >
          {type === "medications_on_site"
            ? "Histórico de medicações"
            : type === "medications"
            ? "Histórico de receituários"
            : "Histórico de exames"}
        </Typography>

        {history?.map((prescription, index) => {
          return (
            <PrescriptionCard
              key={prescription.provider + "-" + index}
              type={type}
              data={prescription}
              handleClick={toggleDialog}
              handlePrint={(signedDocument, notSignedDocument, type, index) => {
                if (signedDocument) {
                  openURL(signedDocument.result);
                } else {
                  notSignedDocument(type, index).open();
                }
              }}
            />
          );
        })}
        {!prescriptionQuery.isFetching &&
          !prescriptionQuery.data?.items.length && (
            <Typography
              component="p"
              sx={{
                fontSize: 16,
                color: theme.palette.primary.light,
                margin: "auto",
                alignSelf: "center",
              }}
            >
              Não há mais prescrições para este paciente
            </Typography>
          )}
        {prescriptionQuery.isFetching && (
          <Box display="flex" justifyContent="center" marginTop={4}>
            <CircularProgress />
          </Box>
        )}
      </CardsContainer>
      <DialogMedium
        title="Lista de Medicamentos"
        maxWidth="lg"
        fullWidth={true}
        open={openDialog}
        handleClose={toggleDialog}
      >
        <PaginatedTable
          columns={collumns}
          shouldDisableFields={["suspended"]}
          disableRows={(row) => row.suspended}
          disableActions={(row, action) =>
            row.suspended && action === "suspend"
          }
          data={historyItems}
        />
      </DialogMedium>
    </>
  );
}

export default PrescriptionList;
