import { useTheme } from "@emotion/react";
import React from "react";
import { DialogMedium } from "../../../helper";
import { useCouncils, usePagination, useProvider } from "../../../service";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../config/yup";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import AutocompleteField from "../../../components/FormFields/AutocompleteField";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { TextField, SwitchField } from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

export default function ProfissionalTypesStep() {
  const theme = useTheme();
  const notify = useNotifier();

  const { getTypes, postTypes, updateTypes } = useProvider();
  const { getCouncils } = useCouncils();

  const [selectEmployeeType, setSelectEmployeeType] = React.useState(null);
  const [councils, setCouncils] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [limit, setLimit] = React.useState(5);

  const createEmployeeTypeMutation = useMutation(postTypes);
  const updateEmployeeTypeMutation = useMutation(updateTypes);

  const employeeTypesQuery = useQuery(["employee-types"], getTypes, {
    retry: false,
    initialData: [],
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  useQuery(["councils"], getCouncils, {
    retry: false,
    onSuccess: (data) => {
      setCouncils(data);
    },
  });

  const { paginationInfo, handlePageChange } = usePagination(
    employeeTypesQuery.data?.data,
    limit
  );

  const handleCloseDialog = () => {
    setEdit(false);
    setSelectEmployeeType(null);
  };

  const validation = Yup.object().shape({
    description: Yup.string().required("É requerido"),
    id_council: Yup.object().required("É requerido").nullable(),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      description: "",
      id_council: null,
    },
    resolver: yupResolver(validation),
  });

  const editEmployeeTypeForm = useForm({
    defaultValues: {
      description: selectEmployeeType?.description,
      id_council: selectEmployeeType?.council,
      active: selectEmployeeType?.active,
    },
    resolver: yupResolver(validation),
  });

  const onSubmit = handleSubmit((data) => {
    const dataSend = { ...data, id_council: data.id_council.id };

    createEmployeeTypeMutation.mutate(dataSend, {
      onSuccess: (data) => {
        notify(data.message, "success");
        employeeTypesQuery.refetch();
        reset();
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    });
  });

  const onEditSubmit = editEmployeeTypeForm.handleSubmit((data) => {
    const dataSend = { ...data, id_council: data.id_council.id };

    updateEmployeeTypeMutation.mutate(
      { id: selectEmployeeType?.id, dataSend },
      {
        onSuccess: (data) => {
          notify(data.message, "success");
          employeeTypesQuery.refetch();
          handleCloseDialog();
          setEdit(false);
          setSelectEmployeeType(null);
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  });

  const columns = [
    {
      field: "number",
      name: "Codigo",
      type: "string",
    },
    {
      field: "description",
      name: "Descrição",
      type: "string",
    },
    {
      field: "id_council",
      name: "Conselho",
      type: "string",
      use: (value) => (value ? value : "Não informado"),
    },
    {
      field: "council",
      name: "Descrição do Conselho",
      type: "string",
      use: (value) => (value.description ? value.description : "Não informado"),
    },
    {
      field: "active",
      name: "Status",
      type: "string",
      use: (value) => (value ? "Ativo" : "Inativo"),
    },
    {
      field: "id",
      name: "Ações",
      alignInHead: "center",
      alignInRow: "center",
      with: 100,
      use: (_, item) => {
        return !item.system_id ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  setSelectEmployeeType(item);
                  editEmployeeTypeForm.setValue(
                    "description",
                    item.description
                  );
                  editEmployeeTypeForm.setValue("active", item.active);
                  editEmployeeTypeForm.setValue("id_council", item.council);
                  setEdit(true);
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            </>
          </Stack>
        ) : (
          <Typography>Não Editável</Typography>
        );
      },
      flex: 0.3,
    },
  ];

  return (
    <>
      <DialogMedium
        open={edit}
        fullWidth
        maxWidth="sm"
        title="Edição de Tipo de Profissional"
        handleClose={handleCloseDialog}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              control={editEmployeeTypeForm.control}
              name="description"
              label="Descrição"
              required
            />
          </Grid>
          <Grid item xs={8}>
            <AutocompleteField
              control={editEmployeeTypeForm.control}
              name="id_council"
              label="Concelho"
              options={councils}
              optionLabelKey="description"
              required
            />
          </Grid>
          <Grid item xs={3}>
            <SwitchField
              control={editEmployeeTypeForm.control}
              name="active"
              label="Alterar Status"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={onEditSubmit}
              onLoad={updateEmployeeTypeMutation.isLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Tipos de Profissionais
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items}
          columns={columns}
          loading={employeeTypesQuery.isLoading}
          actionUniqueIdentifier={"id"}
          shouldDisableFields={["id"]}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Tipo de Profissional
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            control={control}
            name={"description"}
            label={"Descrição"}
            required
          />
        </Grid>
        <Grid item xs={5}>
          <AutocompleteField
            control={control}
            name="id_council"
            label="Conselho"
            options={councils}
            optionLabelKey="description"
            required
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={onSubmit}
            onLoad={createEmployeeTypeMutation.isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
