import { useTheme } from "@emotion/react";
import { FormControl, FormLabel } from "@mui/material";
import Slider from "@mui/material/Slider";
import { useField, useFormikContext } from "formik";
import React from "react";

function FormSlider({
  name,
  label,
  fullWidth = true,
  valueLabelDisplay = "auto",
  customHandleChange,
  ...props
}) {
  const theme = useTheme();
  const [{ value, ...field }, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const settings = {
    ...field,
    value: value === undefined ? null : value,
    ...props,
    name,
    valueLabelDisplay,
  };

  if (meta.touched && meta.error) {
    settings.error = true;
    settings.helperText = meta.error;
  }

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <FormLabel
          htmlFor={name}
          sx={{ fontSize: "0.8rem", color: theme.palette.primary.light }}
        >
          {label}
        </FormLabel>
      )}
      <Slider
        {...settings}
        onChange={(_, value) => {
          setFieldValue(name, value);

          if (customHandleChange instanceof Function) {
            customHandleChange(value);
          }
        }}
      />
    </FormControl>
  );
}

export default FormSlider;
