import {
  Box,
  Typography,
  CardHeader,
  CardContent,
  Card,
  Avatar,
  Chip,
  Tooltip,
  Grid
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React from "react";

function TeamFeedback({ data }) {
  const [copiedText, setCopiedText] = React.useState("");

  return (
    <>
      <Card
        sx={{
          border: "3px solid transparent",
          borderColor: "white",
          borderWidth: "3px",
          boxShadow: "0 0 0 1.5px #FFFFFF"
        }}
      >
        <CardHeader
          title={
            <CopyToClipboard
              text={data.number}
              onCopy={() => setCopiedText(data.number)}
            >
              <Tooltip
                title={copiedText === data.number ? "Copiado!!" : "Copiar"}
                color="primary"
                placement="top"
              >
                <Chip
                  avatar={
                    <Avatar
                      sx={{
                        bgcolor: (theme) => theme.palette.primary.main
                      }}
                    >
                      <Typography color="White" fontSize={12}>
                        {data && data.name.slice(0, 2).toUpperCase()}
                      </Typography>
                    </Avatar>
                  }
                  label={`ID - # ${data.number}`}
                  variant="outlined"
                  color="primary"
                  clickable
                />
              </Tooltip>
            </CopyToClipboard>
          }
        ></CardHeader>
        <Typography
          variant="p"
          color="secondary"
          fontWeight="600"
          fontSize={16}
          sx={{ ml: 2 }}
        >
          {data.name}
        </Typography>
        <Typography
          variant="h1"
          color="gray"
          fontWeight="700"
          fontSize={12}
          sx={{ ml: 2, mt: 2 }}
        >
          #1 MÉDICO RESPONSÁVEL
        </Typography>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", mt: "-10px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
            {data.members.map(({ leader, employee, specialty }) => (
            leader && 
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center"
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Cód. Profissional
                    </Typography>{" "}
                    : {employee.number}
                    <Typography
                      variant="h5"
                      color="primary"
                      fontWeight="500"
                      fontSize={13}
                    >
                      <Typography
                        fontSize={13}
                        color="secondary"
                        fontWeight="500"
                        component="span"
                      >
                        Nome
                      </Typography>{" "}
                      : {employee.name}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Especialidade
                    </Typography>{" "}
                    : {specialty.describe}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    fontWeight="500"
                    fontSize={13}
                  >
                    <Typography
                      fontSize={13}
                      color="secondary"
                      fontWeight="500"
                      component="span"
                    >
                      Cód. de Conselho
                    </Typography>{" "}
                    : {employee.council_registration_number}
                  </Typography>
                </Box>
              </Box>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h1"
                  color="gray"
                  fontWeight="700"
                  fontSize={12}
                  sx={{ mb: 1 }}
                >
                  #2 PROFISSIONAIS
                </Typography>
                {data?.members.map(({ employee, specialty, leader }) => {
                  if (!leader) {
                  return (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          mb: 1.5
                        }}
                      >
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Cód. Profissional
                          </Typography>{" "}
                          : {employee.number}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Nome
                          </Typography>{" "}
                          : {employee.name}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Especialidade
                          </Typography>{" "}
                          : {specialty.describe}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="500"
                          fontSize={13}
                        >
                          <Typography
                            fontSize={13}
                            color="secondary"
                            fontWeight="500"
                            component="span"
                          >
                            Cód. de Conselho
                          </Typography>{" "}
                          : {employee.council_registration_number}
                        </Typography>
                      </Box>
                    </>
                  );
                }
                })}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default TeamFeedback;
