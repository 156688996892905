import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../../config/theme";
import { FormDate } from "../../../components";
import { Form, Formik } from "formik";
import Yup from "../../../config/yup";
import { FormSearchSelectField } from "../../../components/Form";

export default function CockPitOperationIndicatorConfig({ setDialog }) {
  const [initialValues, setInitialValues] = React.useState({
    initialDate: new Date(),
    finalDate: new Date(),
    company: null,
  });

  const validator = Yup.object().shape({
    initialDate: Yup.date()
      .required("É requerido")
      .min(new Date("01/01/1900"), "O ano deve ser posterior ou igual a 1900")
      .typeError("Data Inválida"),
    finalDate: Yup.date()
      .required("É requerido")
      .max(new Date(), "A data final não pode ser maior que o dia de hoje")
      .typeError("Data Inválida"),
    company: Yup.object().nullable(),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validator}>
      {({ values, resetForm }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box my={1}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  color={theme.palette.secondary.main}
                >
                  Período
                </Typography>
                <Box
                  display="flex"
                  mt={1.5}
                  gap={1.5}
                  alignItems="center"
                  maxWidth={400}
                >
                  <FormDate
                    name="initialDate"
                    label="Início"
                    maxDate={new Date()}
                  />
                  <FormDate name="finalDate" label="Fim" maxDate={new Date()} />
                </Box>
              </Box>
            </Grid>
            {/* Filtro por empresa se estiver logado na empresa mãe */}
            <Grid item xs={12}>
              <Box my={1}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  color={theme.palette.secondary.main}
                >
                  Empresa
                </Typography>
                <Box
                  display="flex"
                  mt={1.5}
                  gap={1.5}
                  alignItems="center"
                  maxWidth={400}
                >
                  <FormSearchSelectField
                    name="company"
                    label="Empresa"
                    options={[
                      { id: 1, describe: "Filha - Fazendo do Carmpo" },
                      { id: 2, describe: "UBS - JD STA TEREZINHA" },
                      { id: 3, describe: "UBS - JD SOARES" },
                      { id: 4, describe: "UBS - JD SAO PEDRO" },
                      { id: 5, describe: "AMA/UBS - BONI III" },
                      { id: 6, describe: "AMA/UBS - SITIO CASA PINTADA" },
                      { id: 7, describe: "AMA/UBS - JOSE BONIFACIO I" },
                      { id: 8, describe: "AMA/UBS - JD DAS OLIVEIRAS" },
                      { id: 9, describe: "AMA/UBS - JD BRASILIA" },
                      {
                        id: 10,
                        describe: "AMA/UBS - PRES JUSCELINO KUBITSCHEK",
                      },
                    ]}
                    getOptionLabel={(services) => services.describe}
                    isOptionEqualToValue={(service, value) =>
                      service.id === value.id
                    }
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} mt={2} display="flex" justifyContent="end">
              <Button
                variant="contained"
                onClick={() => {
                  setDialog(false);
                }}
              >
                Salvar Configurações
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
