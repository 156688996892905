import React from "react";
import { Typography, CardHeader, CardContent, Card } from "@mui/material";
import { format, parseISO } from "date-fns";

function MicroAreaFeedback({ data }) {
  return (
    <Card
      sx={{
        border: "3px solid transparent",
        borderColor: "white",
        borderWidth: "3px",
        boxShadow: "0 0 0 1.5px #FFFFFF",
      }}
    >
      <CardHeader
        title={
          <Typography
            variant="h1"
            color="secondary"
            fontWeight="600"
            fontSize={15}
          >
            {data.name}
          </Typography>
        }
        sx={{ mb: -3 }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="p"
          color="gray"
          fontWeight="700"
          fontSize={12}
          sx={{ mb: 2 }}
        >
          #1 Dados da microárea
        </Typography>
        <Typography
          variant="p"
          fontSize={13}
          color="secondary"
          fontWeight="500"
        >
          Criado em:
          <Typography
            color="primary"
            component="span"
            fontWeight={500}
            fontSize={13}
          >
            {format(parseISO(data.createdAt), "dd/MM/yyyy")}
          </Typography>
        </Typography>
        <Typography
          variant="p"
          fontSize={13}
          color="secondary"
          fontWeight="500"
        >
          Atualizado em:
          <Typography
            color="primary"
            component="span"
            fontWeight={500}
            fontSize={13}
          >
            {format(parseISO(data.updatedAt), "dd/MM/yyyy")}
          </Typography>
        </Typography>
        <Typography
          variant="p"
          color="gray"
          fontWeight="700"
          fontSize={12}
          sx={{ my: 2 }}
        >
          #2 Agentes Comunitários de Saúde
        </Typography>
        {!data.employees.length && (
          <Typography
            variant="p"
            fontSize={13}
            color="primary"
            fontWeight="500"
          >
            Nenhum profissional encontrado
          </Typography>
        )}
        {data.employees.map(({ name }, index) => (
          <Typography
            variant="p"
            fontSize={13}
            color="secondary"
            fontWeight="500"
          >
            {`${index + 1} - `}
            <Typography
              color="primary"
              component="span"
              fontWeight={500}
              fontSize={13}
            >
              {name}
            </Typography>
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
}

export default MicroAreaFeedback;
