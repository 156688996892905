import instance from "../config/api";
const path = "/councils ";
function useCouncil() {
  async function getCouncils() {
    try {
      const response = await instance.get(path);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getCouncils,
  };
}
export default useCouncil;
