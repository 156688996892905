import { useCallback } from "react";
import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

function usePrescriptions() {
  const getMedications = useCallback(async (patientId, params) => {
    try {
      const response = await instance.get(`patients/${patientId}/medications`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const getMedicationsOnSite = useCallback(async (patientId, params = {}) => {
    try {
      const response = await instance.get(
        `patients/${patientId}/medications-on-site`,
        { params: urlQuery(params) }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const putMedications = useCallback(async ({ id, data }) => {
    try {
      const response = await instance.put(`medications/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const getExams = useCallback(async (patientId, params) => {
    try {
      const response = await instance.get(`patients/${patientId}/exams`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const putExams = useCallback(async ({ id, data }) => {
    try {
      const response = await instance.put(`exams/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const getPrescriptions = useCallback(async (id, params) => {
    try {
      const response = await instance.get(`patients/${id}/prescriptions`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const getPrescriptionItems = useCallback(async (params) => {
    try {
      const response = await instance.get(`/prescritions/items`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const suspendMedicationOnSite = useCallback(async ({id, data}) => {
    try {
      const response = await instance.delete(`/medications-on-site/${id}`, {data});
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  return {
    getPrescriptions,
    getMedications,
    putMedications,
    getExams,
    putExams,
    getPrescriptionItems,
    getMedicationsOnSite,
    suspendMedicationOnSite,
  };
}
export default usePrescriptions;
