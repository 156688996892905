import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaticDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useField, useFormikContext } from "formik";
import { TextField } from "@mui/material";
import ptBR from "date-fns/esm/locale/pt-BR";
import moment from "moment";

function FormDateStatic({
  name,
  minDate,
  maxDate,
  views = ["year", "month", "day"],
  datas,
  handleChange = () => null,
  handleMonth,
  handleYear,
  handleView,
  ...props
}) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const settings = {
    ...field,
    ...props,
    size: "small",
    fullWidth: true,
    variant: "outlined",
    //InputLabelProps: { shrink: true },
  };
  if (meta && meta.touched && meta.error) {
    settings.error = true;
    settings.helperText = meta.error;
  }

  function onChange(value) {
    setFieldValue(field.name, moment(value).format("YYYY/MM/DD"));
    handleChange(value);
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <StaticDatePicker
        views={views}
        label="Data"
        value={field.value != null ? field.value : null}
        onChange={onChange}
        onMonthChange={handleMonth}
        onYearChange={handleYear}
        onViewChange={handleView}
        displayStaticWrapperAs="desktop"
        minDate={minDate}
        maxDate={maxDate}
        inputFormat={"dd/MM/yyyy"}
        shouldDisableDate={datas}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            {...settings}
            InputLabelProps={{ shrink: true }}
            size="small"
            id={field.name}
            error={settings?.error}
            helperText={settings?.helperText}
            fullWidth={true}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default FormDateStatic;
