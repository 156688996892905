import React from "react";
import TableFilter from "../../../components/Table/TableFilter";
import { DialogMedium } from "../../../helper";
import { isValid, format, parseISO } from "date-fns";
import { useMutation, useQuery } from "react-query";
import { usePatient, useSchedule } from "../../../service";
import {
  Box,
  Button,
  Divider,
  TablePagination,
  Typography,
} from "@mui/material";

import { BoxContainer, ColorInfoLabels } from "../../../components";
import { cleanUpMask, formatWithMask } from "../../../config/mask";
import RegexOf from "../../../config/regex";
import { PhoneDisabledRounded } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { TextField } from "../../../components/FormFields";
import Yup from "../../../config/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DynamicFeedback from "../../../components/DynamicFeedback";
import CopyToClipboardField from "../../../components/CardPatient/CopyToClipboardField";
import { convertDateToBirthday, limitName } from "../../../utils";
import { useNotifier } from "../../../hooks";

const statusColor = [
  {
    text: "Aguardando Mensagem",
    color: "rgba(255, 223, 186, 1)",
    status: null,
    type: "warning",
  },
  {
    text: "Mensagem Recebida",
    color: "rgba(0, 0, 0, 0.05)",
    type: "",
  },
  {
    text: "Mensagem Visualizada",
    color: "rgba(187, 222, 251, 0.6)",
    type: "info",
  },
  {
    text: "Confirmou Presença",
    color: "rgba(0, 216, 0, 0.1)",
    status: true,
    type: "active",
  },
  {
    text: "Não Confirmou Presença",
    color: "rgba(216, 0, 0, 0.1)",
    status: false,
    type: "error",
  },
];

function verifyStatus(row) {
  let status = {
    text: "",
    color: "",
    status: "",
    type: "",
  };

  // const statusColor = [
  //   {
  //     text: "Aguardando Mensagem",
  //     color: "rgba(255, 223, 186, 1)",
  //     status: null,
  //     type: "warning",
  //   },
  //   {
  //     text: "Mensagem Recebida",
  //     color: "rgba(0, 0, 0, 0.05)",
  //     type: "",
  //   },
  //   {
  //     text: "Mensagem Visualizada",
  //     color: "rgba(187, 222, 251, 0.6)",
  //     type: "info",
  //   },
  //   {
  //     text: "Confirmou Presença",
  //     color: "rgba(0, 216, 0, 0.1)",
  //     status: true,
  //     type: "active",
  //   },
  //   {
  //     text: "Não Confirmou Presença",
  //     color: "rgba(216, 0, 0, 0.1)",
  //     status: false,
  //     type: "error",
  //   },
  // ];
  if (row.confirmation_viewedAt && row.confirmed_showing_up === null) {
    status = statusColor[2];
    return status;
  }

  if (!row.confirmation_receivedAt && row.confirmed_showing_up === null) {
    status = statusColor[0];
    return status;
  }

  if (
    row.confirmation_receivedAt &&
    !row.confirmation_viewedAt &&
    row.confirmed_showing_up === null
  ) {
    status = statusColor[1];
    return status;
  }

  if ([true, false, null].includes(row.confirmed_showing_up)) {
    status = statusColor.find(
      (statusObject) => statusObject.status === row.confirmed_showing_up
    );
    return status;
  }

  return status;
}

const modalDefaultValues = {
  contact: false,
  scheduleInfo: false,
};

const validations = Yup.object().shape({
  contact: Yup.object().shape({
    cell_phone: Yup.string().test({
      message: "Número de celular inválido",
      name: "contact.cell_phone",
      test: function (value) {
        if (value.length === RegexOf.phone.length) {
          return true;
        }
        return false;
      },
    }),
  }),
});

const othersFilters = [
  {
    name: "Data de agendamentos",
    type: "date",
    filter: "date",
  },
  {
    name: "CPF do Paciente",
    type: "text",
    filter: "patient_cpf",
    mask: "999.999.999-99",
  },
  {
    name: "CNS do Paciente",
    type: "text",
    filter: "patient_sus_card",
    mask: "999999999999999",
  },
  {
    name: "Nome do Paciente",
    type: "text",
    filter: "patient_name",
  },
  {
    name: "Nome da mãe do paciente",
    type: "text",
    filter: "patient_mother_name",
  },
  {
    name: "Status",
    filter: "status",
    type: "select",
    options: [
      {
        label: "Aguardando Mensagem",
        value: "waiting_message",
      },
      {
        label: "Mensagem Recebida",
        value: "received_message",
      },
      {
        label: "Mensagem Visualizada",
        value: "message_seen",
      },
      {
        label: "Confirmou Presença",
        value: "confirmed_presence",
      },
      {
        label: "Não Confirmou Presença",
        value: "not_confirmed_presence",
      },
    ],
  },
  {
    name: "Meio de Confirmação",
    filter: "confirmed_showing_up_on_system",
    type: "select",
    options: [
      {
        label: "Whatsapp",
        value: "whatsapp",
      },
      {
        label: "T+Saúde",
        value: "tsaude",
      },
      {
        label: "Totem",
        value: "totem",
      },
    ],
  },
];

// const tomorrow = addDays(new Date(), 1);

const systemEnum = {
  tsaude: "T+Saúde",
  whatsapp: "Whatsapp",
  totem: "Totem",
};

const shapeFeedback = [
  {
    type: "header",
    label: "#1 DADOS DO PACIENTE",
  },
  {
    type: "description",
    label: "Nome",
    valueKey: "patient.name",
    formatter: (name) => name || "Não Informado",
  },
  {
    type: "description",
    label: "Nome da mãe",
    valueKey: "patient.mother_name",
    formatter: (sus_card) => sus_card || "Não Informado",
  },
  {
    type: "description",
    label: "Data de Nascimento",
    valueKey: "patient.birth_date",
    formatter: (birth, row) => {
      const formattedDate = parseISO(birth);
      return isValid(formattedDate)
        ? format(
            formattedDate,
            `dd/MM/yyyy - '${convertDateToBirthday(birth)}'`
          )
        : "Não Recebido";
    },
  },
  {
    type: "description",
    label: "Celular",
    valueKey: "patient.contact.cell_phone",
    formatter: (cell_phone) =>
    formatWithMask({
      text: cell_phone,
      mask: RegexOf.phone,
    },
  ).masked || "Não Informado",
  },
  {
    type: "description",
    label: "CPF",
    valueKey: "patient.physic_national",
    formatter: (cpf) =>
      formatWithMask({
        text: cpf,
        mask: RegexOf.cpf,
      }).masked || "Não Informado",
  },
  {
    type: "description",
    label: "CNS",
    valueKey: "patient.sus_card",
    formatter: (sus_card) => sus_card || "Não Informado",
  },
  {
    type: "header",
    label: "#2 DADOS ADICIONAIS DO AGENDAMENTO",
  },
  {
    type: "description",
    label: "Agendado no dia",
    valueKey: "date",
    formatter: (createdAt, row) => {
      const formattedDate = parseISO(createdAt);
      return isValid(formattedDate)
        ? format(formattedDate, `dd/MM/yyyy 'às' ${row.hour}`)
        : "Não Recebido";
    },
  },
  {
    type: "description",
    label: "Tipo do Agendamento",
    valueKey: "treatment_type.describe",
  },
  {
    type: "description",
    label: "Agendado para o prestador(a)",
    valueKey: "employee.name",
  },
  {
    type: "description",
    label: "Serviço",
    valueKey: "service.describe",
  },
  {
    type: "description",
    label: "Procedimento",
    valueKey: "procedure.name",
  },
  {
    type: "header",
    label: "#3 DADOS DA CONFIRMAÇÃO DO PACIENTE",
  },

  {
    type: "description",
    label: "Recebimento da Mensagem",
    valueKey: "confirmation_receivedAt",
    formatter: (createdAt) => {
      const formattedDate = parseISO(createdAt);
      return isValid(formattedDate)
        ? format(formattedDate, "dd/MM/yyyy 'às' HH:mm")
        : "Não Recebido";
    },
  },
  {
    type: "description",
    label: "Mensagem Visualizada",
    valueKey: "confirmation_viewedAt",
    formatter: (createdAt) => {
      const formattedDate = parseISO(createdAt);
      return isValid(formattedDate)
        ? format(formattedDate, "dd/MM/yyyy 'às' HH:mm")
        : "Não Visualizou";
    },
  },
  // {
  //   type: "description",
  //   label: "Data de Resposta no Whatsapp",
  //   valueKey: "confirmation_updatedAt",
  //   formatter: (createdAt, row) => {
  //     const formattedDate = parseISO(createdAt);
  //     if (row.confirmation_system !== "whatsapp") {
  //       return "Não Respondido via Whatsapp";
  //     }
  //     return isValid(formattedDate)
  //       ? format(formattedDate, "dd/MM/yyyy 'às' HH:mm")
  //       : "Não Respondido";
  //   },
  // },
  {
    type: "description",
    label: "Status",
    valueKey: "confirmed",
    formatter: (_, row) => {
      return verifyStatus(row).text;
    },
  },
  {
    type: "description",
    label: "Confirmação / Cancelamento via",
    valueKey: "confirmed_showing_up_on_system",
    formatter: (confirmed_showing_up_on_system, row) => {
      return systemEnum[confirmed_showing_up_on_system] || "Não Confirmado";
    },
  },
  {
    type: "description",
    label: "Data de Confirmação / Cancelamento",
    valueKey: "confirmed_showing_upAt",
    formatter: (updated) => {
      const formattedDate = parseISO(updated);
      return isValid(formattedDate)
        ? format(formattedDate, `dd/MM/yyyy 'às' HH:mm`)
        : "Não Recebido";
    },
  },
  {
    type: "description",
    label: "Confirmado / Cancelado por",
    valueKey: "confirmed_by",
    formatter: (confirmed_by) => {
      return confirmed_by?.name || "Agendamento realizado automaticamente";
    },
  },
];
export default function WhatsAppSchedulings() {
  const [filter, setFilter] = React.useState({
    date: format(new Date(), "yyyy-MM-dd"),
    order_by: "pending_confirmation",
    confirmed: "null",
  });
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [schedulings, setSchedulings] = React.useState();
  const [openModal, setOpenModal] = React.useState(modalDefaultValues);
  const { getSchedule, patchScheduling } = useSchedule();
  const { updatePatientPhone } = usePatient();
  const [currentPatientId, setCurrentPatientId] = React.useState(null);
  const notify = useNotifier();

  const tableColumns = [
    {
      name: "Paciente",
      field: "patient",
      // type: "text",
      use: (value, row) => {
        return (
          <>
            <Typography fontWeight={"bold"}>
              {limitName(
                row.patient?.social_prioritize
                  ? row.patient.social_name
                  : value.name,
                5,
                "Não informado"
              )}
            </Typography>
            <CopyToClipboardField
              textColor="primary.light"
              label="CPF"
              fontSize={14}
              text={
                row?.patient?.physic_national
                  ? formatWithMask({
                      mask: RegexOf.cpf,
                      text: row?.patient?.physic_national,
                    }).masked
                  : "Não Informado"
              }
            />
            <CopyToClipboardField
              textColor="primary.light"
              label="Celular"
              fontSize={14}
              text={
                row?.patient?.contact?.cell_phone
                  ? formatWithMask({
                    mask: RegexOf.phone,
                      text: row?.patient?.contact?.cell_phone,
                    }).masked
                  : "Não Informado"
              }
            />
          </>
        );
      },
    },
    {
      name: "Tipo de Agenda / Atendimento",
      field: "description",
      use: (value, row) => {
        return (
          <>
            <Typography>
              {value} / {row.treatment_type.describe}
            </Typography>
          </>
        );
      },
      // type: "text",
    },
    {
      name: "Prestador / Serviço",
      field: "service.describe",
      use: (value, row) => {
        return (
          <>
            <Typography fontWeight={"bold"}>
              {limitName(row.employee?.name, 5) || "Não informado"}
            </Typography>
            <Typography>{value}</Typography>
          </>
        );
      },
      // type: "text",
    },
    {
      name: "Data / Hora do Agendamento",
      field: "date",
      use: (date, row) => {
        return format(parseISO(date), `dd/MM/yyyy 'às' ${row.hour}`);
      },
    },
    {
      name: "Confirmação via",
      field: "confirmed_showing_up_on_system",
      use: (value, row) => {
        return value ? systemEnum[value] : "Não confirmado";
      },
    },
    {
      name: "Status",
      field: "status",
      use: (value, row) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"medium"}>
              {verifyStatus(row).text}
            </Typography>
          </Box>
        );
      },
      // type: "text",
    },
  ];

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      contact: {
        cell_phone: "",
      },
    },
    resolver: yupResolver(validations),
  });

  const scheduleQuery = useQuery(
    ["whatsapp-schedules", page, limit, filter],
    () =>
      getSchedule({
        page: page,
        limit: limit,
        ...filter,
      }),
    {
      onSuccess: (response) => {
        setSchedulings(response.items);
        setCount(response.totalItems);
      },
      onError: (error) => {
        notify(error.message, "error");
        setSchedulings([]);
      },
      retry: false,
    }
  );

  const updatePatientMutation = useMutation(updatePatientPhone, {
    onSuccess: () => {
      notify("Telefone celular atualizado sucesso", "success");
      handleCloseModal();
      scheduleQuery.refetch();
    },
    onError: (err) => {
      notify(
        "Houve um erro ao atuliazar o telefone celular do paciente",
        "error"
      );
    },
  });

  const patchSchedulingMutation = useMutation(patchScheduling, {
    onSuccess: () => {
      scheduleQuery.refetch();
      notify("Agendamento atualizado com sucesso", "success");
    },
    onError: () => {
      notify("Houve um erro ao atualizar o agendamento", "error");
    },
  });
  function handleCloseModal() {
    setOpenModal(modalDefaultValues);
  }

  function onSubmit(values) {
    updatePatientMutation.mutate({
      id: currentPatientId,
      data: {
        contact: {
          cell_phone: cleanUpMask(values.contact.cell_phone, "", [
            "(",
            ")",
            "-",
            " ",
          ]),
        },
      },
    });
  }
  function handleFilter(filter) {
    setPage(0);
    setFilter((currentFilter) => {
      return {
        date: currentFilter.date,
        ...filter,
      };
    });
  }

  return (
    <BoxContainer title={"Visualização de Agendamentos"}>
      <DialogMedium
        maxWidth={"md"}
        open={openModal.scheduleInfo}
        title={"Informação do paciente / Agendamento"}
        handleClose={handleCloseModal}
        children={
          <DynamicFeedback data={openModal?.row || []} shape={shapeFeedback} />
        }
      />
      <DialogMedium
        maxWidth={"md"}
        open={openModal.contact}
        title={"Editar número celular do paciente"}
        handleClose={handleCloseModal}
        children={
          <Box>
            <TextField
              control={control}
              label={"Número celular:"}
              mask={RegexOf.phone}
              placeholder={"(99) 99999-9999"}
              name={"contact.cell_phone"}
            />
            <Box marginTop={2} />
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              loading={updatePatientMutation.isLoading}
            >
              Salvar
            </Button>
          </Box>
        }
      />
      <ColorInfoLabels labels={statusColor} />
      <Button
        variant="contained"
        onClick={() => {
          scheduleQuery.refetch();
        }}
        sx={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        Atualizar
      </Button>
      <TableFilter
        data={schedulings}
        columns={tableColumns}
        actions
        loading={scheduleQuery.isLoading}
        handleFilter={handleFilter}
        customizeActions={{
          suspend: {
            title: "Cancelar Manualmente",
            verb: "Cancelar Manualmente",
            icon: <PhoneDisabledRounded sx={{}} />,
          },
          finish: {
            title: "Confirmar",
            verb: "Marcar como confirmado manualmente",
          },
          edit: {
            title: "Editar contato do paciente",
          },
        }}
        rowStatus={(row) => verifyStatus(row).type}
        othersFilter={othersFilters}
        actionUniqueIdentifier={"id"}
        actionsTypes={["view", "finish", "suspend"]}
        disableActions={(row, action) => {
          const status = verifyStatus(row).status;
          if (
            patchSchedulingMutation.isLoading ||
            scheduleQuery.isFetching ||
            patchSchedulingMutation.isLoading
          )
            return true;
          if (
            (status || status === false) &&
            (action === "finish" || action === "suspend")
          )
            return true;
        }}
        actionHandleView={(_, row) => {
          setCurrentPatientId(row.patient.id);
          setOpenModal((current) => ({
            ...current,
            row: row,
            scheduleInfo: true,
          }));
        }}
        actionHandleFinish={(id) => {
          patchSchedulingMutation.mutate({
            id,
            data: {
              confirmed: true,
            },
          });
        }}
        actionHandleEdit={(_, row) => {
          setCurrentPatientId(row.patient.id);
          setValue(
            "contact.cell_phone",
            formatWithMask({
              text: row.patient.contact.cell_phone,
              mask: RegexOf.phone,
            }).masked
          );
          setOpenModal((current) => ({
            ...current,
            contact: true,
          }));
        }}
        actionHandleSuspend={(id) => {
          patchSchedulingMutation.mutate({
            id,
            data: {
              confirmed: false,
            },
          });
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: "medium",
          }}
        >
          Exibindo dados do dia:{" "}
          <Typography component={"span"} fontWeight={"bold"}>
            {filter.date && format(parseISO(filter.date), "dd/MM/yyyy")}
          </Typography>
        </Typography>
        <TablePagination
          count={count}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
      <Divider />
      <Typography
        sx={{
          marginBottom: "10px",
          color: (theme) => theme.palette.primary.light,
          fontWeight: "medium",
          maxWidth: "700px",
          marginTop: "10px",
        }}
      >
        Por padrão são listado os agendamentos do dia atual que estão pendentes
        de confirmação via Whatsapp e pacientes que não receberam as mensagens
        de agendamento.
      </Typography>
    </BoxContainer>
  );
}
