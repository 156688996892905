import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
import calcPercent from "../utils/calcPercent";
const path = "/cockpit/soap";
const analisysPath = "/cockpit/integration-data";
const pathConfig = "/config/cockpit";
const schedulesPath = "/cockpit/schedulings";

function useCockpitSoap() {
  async function getCockiptSchedules(params) {
    try {
      const response = await instance.get(schedulesPath, { params });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getCockpitSoap() {
    try {
      const response = await instance.get(path);
      let { attendances, patients, services, preExistIllness } = response.data;

      preExistIllness = preExistIllness.map(
        ({ id, describe, count, female, male, other }) => ({
          id,
          describe,
          count,
          generalPercent: calcPercent(count, patients.count),
          femPercent: calcPercent(female, count),
          mascPercent: calcPercent(male, count),
          otherPercent: calcPercent(other, count),
        })
      );

      return {
        attendances: {
          count: attendances.count,
          femPercent: calcPercent(attendances.female. attendances.count),
          mascPercent: calcPercent(attendances.male, attendances.count),
          other: calcPercent(attendances.other, attendances.count),
        },
        patients: {
          count: patients.count,
          fem: patients.female,
          masc: patients.male,
          other: patients.other,
          femPercent: calcPercent(patients.female, patients.count),
          mascPercent: calcPercent(patients.male, patients.count),
          otherPercent: calcPercent(patients.other, patients.count),
        },
        services,
        preExistIllness,
      };
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getConfigCockpit() {
    try {
      const response = await instance.get(pathConfig);
      const json = response.data;
      const initialDate = json.initial_date;
      const finalDate = json.final_date;
      const services = json.services;
      const illness = json.preExistIllness;
      return { initialDate, finalDate, services, illness };
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postCockpitConfig(data) {
    try {
      const response = await instance.post(pathConfig, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function putCockpitConfig(data) {
    try {
      const response = await instance.put(pathConfig, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getDataAnalisys() {
    try {
      const response = await instance.get(analisysPath);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getDataAnalisysByProgram(idProgram, filters) {
    try {
      const response = await instance.get(`/programs/${idProgram}/patients`, {
        params: urlQuery(filters),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getAgesDataAnalisys(idProgram, filters) {
    try {
      const response = await instance.get(
        `${analisysPath}/programs/${idProgram}/patients`,
        {
          params: urlQuery(filters),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getQuantityTokens(company_id) {
    try {
      const response = await instance.get(
        `external-services/tpanels/quantity-tokens`,
        {
          params: urlQuery(company_id),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getRiskClassification(company_id) {
    try {
      const response = await instance.get(
        `external-services/tpanels/patient-riskclassification`,
        {
          params: urlQuery(company_id),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getPatientsClassifieds(company_id) {
    try {
      const response = await instance.get(
        `external-services/tpanels/patients-classifieds`,
        {
          params: urlQuery(company_id),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getMonitoring(company_id) {
    try {
      const response = await instance.get(
        `external-services/tpanels/monitoring`,
        {
          params: urlQuery(company_id),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getServices(company_id) {
    try {
      const response = await instance.get(
        `external-services/tpanels/services`,
        {
          params: urlQuery(company_id),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getCEM(params) {
    try {
      const response = await instance.get(
        "/cockpit/cem-panel",
        {
          params,
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getCockpitSoap,
    getConfigCockpit,
    postCockpitConfig,
    putCockpitConfig,
    getDataAnalisys,
    getDataAnalisysByProgram,
    getAgesDataAnalisys,
    getQuantityTokens,
    getRiskClassification,
    getPatientsClassifieds,
    getMonitoring,
    getServices,
    getCockiptSchedules,
    getCEM
  };
}
export default useCockpitSoap;
