import React, { useState } from "react";
import TableFilter from "../../../../components/Table/TableFilter";
import { Box, Button, TablePagination } from "@mui/material";
import { useFamily, usePatient } from "../../../../service";
import { useQueryClient, useQuery } from "react-query";
import { format, isValid, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { DialogMedium } from "../../../../helper";
import DynamicFeedback from "../../../../components/DynamicFeedback";

function ListEvolutions({ patient, type }) {
  const { getPatientEvolution } = usePatient();
  const { getFamilyRecordsById } = useFamily();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [filter, setFilter] = useState({ page: page, limit: limit });
  const [evolutionFeedback, setEvolutionFeedback] = useState(false);
  const [dataToFeedback, setDataToFeedback] = useState([]);

  const patientEvolutionsQuery = useQuery(
    ["patient-evolution", patient, filter, page, limit],
    () => getPatientEvolution(patient.id, filter),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: type === "patient",
    }
  );

  const familyEvolutionsQuery = useQuery(
    ["family-evolution", patient, filter, page, limit],
    () =>
      patient?.family?.id && getFamilyRecordsById(patient.family.id, filter),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: type === "family",
    }
  );

  const tableColumns = [
    {
      name: type === "patient" ? "Cod. Paciente" : "Cód. Família",
      field:
        type === "patient" ? "patient?.number" : "attendance?.family?.name",
    },
    {
      name: "Profissional",
      field: "created_by?.name",
    },
    {
      name: "Status",
      field: "status",
      type: "select",
      options: [
        { label: "Finalizado", value: "finished" },
        { label: "Não finalizado", value: "filling" },
      ],
      use: (status) => {
        return status === "finished"
          ? "Finalizado"
          : status === "filling"
          ? "Preenchendo"
          : "Não finalizado";
      },
    },
    {
      name: "Data e hora",
      field: "createdAt",
      use: (createdAt) => {
        if (createdAt)
          return format(parseISO(createdAt), "dd/MM/yyyy 'às' HH:mm", {
            locale: ptBR,
          });
        else {
          return "Não informado";
        }
      },
      type: "date",
    },
  ];

  function handleFilter(filterTable) {
    if (type === "patient") {
      queryClient.cancelQueries(["patient-evolution"]);
    } else {
      queryClient.cancelQueries(["family-evolution"]);
    }
    setPage(0);
    setFilter({ ...filter, ...filterTable });
  }
  function handleShow(evolutionId) {
    let evolutionSelected = {};
    if (type === "patient") {
      evolutionSelected = patientEvolutionsQuery.data?.items?.find(
        ({ id }) => id === evolutionId
      );
    } else {
      evolutionSelected = familyEvolutionsQuery.data?.items?.find(
        ({ id }) => id === evolutionId
      );
    }

    setDataToFeedback(evolutionSelected);
    setEvolutionFeedback(true);
  }

  const shapeFeedback = [
    {
      type: "header",
      label: "#1 DADOS DA EVOLUÇÃO",
    },
    {
      type: "description",
      label: "Prestador",
      valueKey: "created_by.name",
    },
    {
      type: "description",
      label: "Status",
      valueKey: "status",
      formatter: (status) => {
        return status === "finished"
          ? "Finalizado"
          : status === "filling"
          ? "Preenchendo"
          : "Não finalizado";
      },
    },
    {
      type: "description",
      label: "Data e hora",
      valueKey: "createdAt",
      formatter: (createdAt) => {
        const formattedDate = parseISO(createdAt);
        return isValid(formattedDate)
          ? format(formattedDate, "dd/MM/yyyy 'às' HH:mm")
          : "Não informado";
      },
    },
    {
      type: "description",
      label: "Desfecho da visita",
      valueKey: "visit_conclusion",
      formatter: (visit_conclusion) => {
        return visit_conclusion === "completed" ? "Realizada" : "Ausente";
      },
    },
    {
      type: "description",
      label: "Tipo do imóvel",
      valueKey: "property_type",
      formatter: (property_type) => {
        return property_type || "Não informado";
      },
    },
    {
      type: "description",
      label: "Visita compartilhada com outro profissional",
      valueKey: "shared_visit",
      formatter: (shared_visit) => {
        return shared_visit ? "Sim" : "Não";
      },
    },
    {
      type: "description",
      label: "Motivo da visita",
      valueKey: "visit_reasons.visit_type",
      formatter: (visit_type) => {
        return !!visit_type?.length ? visit_type.join(", ") : "Não informado";
      },
    },
    {
      type: "description",
      label: "Busca ativa",
      valueKey: "visit_reasons.active_search",
      formatter: (active_search) => {
        return !!active_search?.length
          ? active_search.join(", ")
          : "Não informado";
      },
    },
    {
      type: "description",
      label: "Acompanhamento",
      valueKey: "visit_reasons.follow_up",
      formatter: (follow_up) => {
        return !!follow_up?.length ? follow_up.join(", ") : "Não informado";
      },
    },
    {
      type: "description",
      label: "Controle ambiental/vetorial",
      valueKey: "visit_reasons.env_control_and_vector",
      formatter: (env_control_and_vector) => {
        return !!env_control_and_vector?.length
          ? env_control_and_vector.join(", ")
          : "Não informado";
      },
    },
    {
      type: "header",
      label: "#2 AVALIAÇÃO CLÍNICA",
    },
    {
      type: "description",
      label: "Peso",
      valueKey: "clinical_assessment.weight",
      formatter: (weight) => {
        return weight ? `${weight} kg` : "Não informado";
      },
    },
    {
      type: "description",
      label: "Altura",
      valueKey: "clinical_assessment.height",
      formatter: (height) => {
        return height ? `${height} kg` : "Não informado";
      },
    },
    {
      type: "description",
      label: "Temperatura",
      valueKey: "clinical_assessment.temperature",
      formatter: (temperature) => {
        return temperature ? `${temperature} °C` : "Não informado";
      },
    },
    {
      type: "description",
      label: "Pressão arterial",
      valueKey: "clinical_assessment.blood_pressure",
      formatter: (blood_pressure) => {
        return blood_pressure || "Não informado";
      },
    },
    {
      type: "description",
      label: "Glicemia capilar",
      valueKey: "clinical_assessment.glycemia",
      formatter: (glycemia) => {
        return glycemia ? `${glycemia} mg/dL` : "Não informado";
      },
    },
    {
      type: "description",
      label: "Momento da coleta",
      valueKey: "clinical_assessment.glycemia_collected_type",
      formatter: (glycemia_collected_type) => {
        return glycemia_collected_type || "Não informado";
      },
    },
    {
      type: "header",
      label: "#3 ANOTAÇÕES DA EVOLUÇÃO",
    },
    {
      type: "description",
      label: "Notas",
      valueKey: "notes",
      formatter: (notes) => {
        return notes || "Não informado";
      },
    },
  ];

  return (
    <>
      <DialogMedium
        fullWidth
        maxWidth="xl"
        open={evolutionFeedback}
        handleClose={() => setEvolutionFeedback(false)}
        title={"Informações da Evolução"}
      >
        <DynamicFeedback data={dataToFeedback} shape={shapeFeedback} />
      </DialogMedium>
      <Box>
        <Button
          variant="contained"
          sx={{ mt: 2, mb: 1 }}
          onClick={() => {
            if (type === "patient") {
              patientEvolutionsQuery.refetch();
            } else {
              familyEvolutionsQuery.refetch();
            }
          }}
        >
          Atualizar
        </Button>
        <TableFilter
          data={
            type === "patient"
              ? patientEvolutionsQuery.data?.items
              : familyEvolutionsQuery.data?.items
          }
          actions
          emptyMessage={
            type === "patient"
              ? patientEvolutionsQuery?.error?.message
              : familyEvolutionsQuery.error?.message
          }
          columns={tableColumns}
          handleFilter={handleFilter}
          loading={
            type === "patient"
              ? patientEvolutionsQuery.isLoading
              : familyEvolutionsQuery.isLoading
          }
          actionUniqueIdentifier={"id"}
          actionsTypes={["view"]}
          actionHandleView={handleShow}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={
              type === "patient"
                ? patientEvolutionsQuery.data?.totalItems
                : familyEvolutionsQuery.data?.totalItems
            }
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default ListEvolutions;
