import { Box } from "@mui/material";
import React from "react";
import TabRoles from "./Access/TabRoles";
import TabUsers from "./Access/TabUsers";
import { Tabs } from "../../components";

function SettingsAccess() {
  const tabList = [
    {
      label: "Papéis",
      content: <TabRoles />,
    },
    {
      label: "Usuários",
      content: <TabUsers />,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs tabs={tabList} />
    </Box>
  );
}

export default SettingsAccess;
