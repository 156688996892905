import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/health-tips";

function useHealthTips() {
  async function getHealthTips(filter = {}) {
    try {
      const response = await instance.get(path, { params: urlQuery(filter) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function getHealthTipsById(id) {
    try {
      const response = await instance.get(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function postHealthTips(data) {
    try {
      const response = await instance.post(path, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function updateHealthTips(data) {
    try {
      const response = await instance.put(
        `${path}/update/${data[0]}`,
        data[1],
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function deleteHealthTips(data) {
    try {
      const response = await instance.post(
        `${path}/${data[0]}/files/batch-delete`,
        {
          files: data[1].map((data) => data),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(JSON.stringify(err?.response?.data));
    }
  }
  async function deleteHealthTip(id) {
    try {
    } catch (err) {}
  }
  return {
    getHealthTips,
    getHealthTipsById,
    postHealthTips,
    updateHealthTips,
    deleteHealthTips,
  };
}
export default useHealthTips;
