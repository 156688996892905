import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import formatPhoneNumber from "../utils/formatPhoneNumber";
import mapObject from "../utils/mapObject";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { v4 as uuidv4 } from "uuid";
import { SP_LOGO } from "./pdfSources";
import { getUserData, writeRotatedText } from "./pdfComponents";

function againstReferencePDF(data, patient, cids) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const userData = getUserData();
  let currentEmployee = userData;
  let currentCompany = userData.company;

  if(data?.soap?.employee_specialty) {
    currentEmployee = data.soap.employee_specialty.employee;
  }

  if (data?.soap?.attendance?.company) {
    currentCompany = data.soap.attendance.company;
  }

  const url = `${window.QRCODES_URL.replace(
    /\/$/,
    ""
  )}/tportal/validate/${pdfName}/types/against_reference`;

  const etnOptions = {
    Negro: "PRETA",
    Branco: "BRANCA",
    Amarelo: "PARDA",
    Indígena: "INDIGENA",
  };

  const cidsDesc = cids?.reduce((str, { description, id }, index, array) => {
    return array.length - 1 === index
      ? str + `(${id} - ${description})`
      : str + `(${id} - ${description}), `;
  }, "");

  const exams_proc = mapObject(data.details, (value, label) => {
    if (["procedures", "exams"].includes(label)) {
      return value;
    }
  }).reduce((str, value, index, array) => {
    if (!value) return str;
    return str === "" ? str + value : str + `, ${value}`;
  }, "");

  const hipo_diag = mapObject(data.details, (value, label) => {
    if (["diagnostic", "hyphotesis"].includes(label)) {
      return value;
    }
  }).reduce((str, value, index, array) => {
    if (!value) return str;
    return str === "" ? str + value : str + `, ${value}`;
  }, "");

  const etnText = Object.keys(etnOptions)
    .map((a) => `( ${a === patient?.race ? "X" : ""} )  ` + etnOptions[a])
    .join("   ");

  const layout = {
    paddingLeft: function (i, node) {
      return 0;
    },
    paddingRight: function (i, node) {
      return 0;
    },
    paddingTop: function (i, node) {
      return 0;
    },
    paddingBottom: function (i, node) {
      return 0;
    },
    defaultBorder: false,
  };

  var dd = {
    background: function (currentPage, pageSize) {
      return {
        table: {
          widths: [pageSize.width - 50],
          heights: [pageSize.height - 538],
          body: [[""]],
        },
        margin: [30, 10],
      };
    },
    pageMargins: [30, 30, 10, 30],
    content: [
      {
        alignment: "justify",
        margin: [50, 0, 0, 0],
        columns: [
          {
            image: "sp",
            width: 100,
            height: 60,
          },
          {
            height: 60,
            style: "header",
            margin: [-10, 10, 0, 0],
            text: "FICHA DE REFERÊNCIA / CONTRARREFERÊNCIA",
          },
        ],
      },
      {
        margin: [0, 10, 10, 0],
        table: {
          widths: [52, "*"],
          heights: 50,
          body: [
            [
              {
                alignment: "center",
                text: "1 - DE",
                border: [],
                margin: [0, 15, 0, 0],
                padding: [0, 0, 14, 0],
              },
              {
                border: [1, 1, 1, 1],
                margin: [2, 2, 0, 0],
                text: [
                  {
                    text: currentCompany.crs
                      ? `CRS  ${currentCompany.crs}\n`
                      : "",
                  },
                  {
                    text: currentCompany.sts
                      ? `STS  ${currentCompany.sts}\n`
                      : "",
                  },
                  { text: `Unidade: ${currentCompany.name}\n` },
                  {
                    text: `${currentCompany.street},n° ${
                      currentCompany.address_number || ""
                    } - ${currentCompany.county}, São Paulo - ${
                      currentCompany.uf
                    }, ${currentCompany.address_cep}\n`,
                  },
                  {
                    text: `Telefone: ${formatPhoneNumber(
                      currentCompany.phone
                    )}`,
                  },
                ],
              },
            ],
          ],
        },
        layout: layout,
      },
      {
        margin: [0, 10, 10, 0],
        table: {
          widths: [52, "*"],
          heights: 100,
          body: [
            [
              {
                text: "2 - PARA",
                alignment: "center",
                margin: [0, 50, 0, 0],
                padding: [0, 0, 14, 0],
              },
              {
                border: [1, 1, 1, 1],
                table: {
                  widths: ["*"],
                  body: [
                    [
                      {
                        border: [0, 0, 0, 1],
                        table: {
                          widths: ["*"],
                          body: [
                            [
                              {
                                margin: [0, 5, 0, 0],
                                table: {
                                  widths: ["auto", "*"],
                                  padding: 0,
                                  body: [
                                    [
                                      {
                                        text: "UNIDADE DE REFERÊNCIA:",
                                        border: [],
                                      },
                                      { text: "", border: [0, 0, 0, 1] },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                margin: [0, 5, 0, 5],
                                table: {
                                  widths: ["auto", "*"],
                                  padding: 0,
                                  body: [
                                    [
                                      { text: "ENDEREÇO:", border: [] },
                                      { text: "", border: [0, 0, 0, 1] },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                          ],
                        },
                        layout: layout,
                      },
                    ],
                    [
                      {
                        border: [0, 0, 0, 0],
                        table: {
                          widths: ["*"],
                          body: [
                            [
                              {
                                margin: [0, 5, 0, 0],
                                table: {
                                  widths: [
                                    "auto",
                                    "*",
                                    "auto",
                                    "*",
                                    "auto",
                                    "*",
                                  ],
                                  padding: 0,
                                  body: [
                                    [
                                      { text: "CIDADE:", border: [] },
                                      { text: "", border: [0, 0, 0, 1] },
                                      { text: "ESTADO:", border: [] },
                                      { text: "", border: [0, 0, 0, 1] },
                                      { text: "BAIRRO:", border: [] },
                                      { text: "", border: [0, 0, 0, 1] },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                margin: [0, 5, 0, 0],
                                table: {
                                  widths: ["auto", "*"],
                                  padding: 0,
                                  body: [
                                    [
                                      {
                                        text: "LOCALIZAÇÃO / PONTO DE REFERÊNCIA:",
                                        border: [],
                                      },
                                      { text: "", border: [0, 0, 0, 1] },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                margin: [0, 5, 0, 0],
                                table: {
                                  widths: [55, 265, 75, "*"],
                                  padding: 0,
                                  body: [
                                    [
                                      { text: "SERVIÇO DE:", border: [] },
                                      {
                                        text: data.requested_specialty,
                                        border: [0, 0, 0, 1],
                                        margin: [5, 0, 40, 0],
                                      },
                                      { text: "CLASSIFICAÇÃO:", border: [] },
                                      {
                                        text: data.priority,
                                        border: [0, 0, 0, 1],
                                      },
                                    ],
                                    [
                                      {
                                        text: "(ESPECIALIDADE, PROGRAMA, ATIVIDADE)",
                                        alignment: "center",
                                        colSpan: 4,
                                        border: [0, 0, 0, 0],
                                        margin: [-120, 0, 0, 0],
                                      },
                                      { text: "", border: [] },
                                      { text: "", border: [] },
                                      { text: "", border: [] },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                          ],
                        },
                        layout: layout,
                      },
                    ],
                  ],
                },
                layout: layout,
              },
            ],
          ],
        },
        layout: layout,
      },
      {
        margin: [0, 50, 0, 0],
        table: {
          widths: ["*"],
          heights: 100,
          body: [
            [
              {
                border: [1, 1, 1, 1],
                table: {
                  widths: ["*"],
                  body: [
                    [
                      {
                        border: [0, 0, 0, 1],
                        table: {
                          widths: ["*"],
                          body: [
                            [
                              {
                                margin: [0, 10, 0, 0],
                                table: {
                                  widths: ["auto", "*", "auto", 70, "auto", 60],
                                  padding: 0,
                                  body: [
                                    [
                                      { text: "NOME DO USUÀRIO:", border: [] },
                                      {
                                        text: patient?.social_prioritize
                                          ? patient.social_name
                                          : patient.name,
                                        border: [0, 0, 0, 1],
                                        margin: [5, 0, 0, 0],
                                      },
                                      { text: "N° REGISTRO:", border: [] },
                                      {
                                        text: patient.number,
                                        border: [0, 0, 0, 1],
                                        margin: [5, 0, 0, 0],
                                      },
                                      {
                                        text: "DATA DE NASCIMENTO",
                                        border: [],
                                      },
                                      {
                                        text: patient?.birth_date
                                          ? format(
                                              parseISO(patient?.birth_date),
                                              "dd/MM/yyyy",
                                              {
                                                locale: ptBR,
                                              }
                                            )
                                          : "Não Informado",
                                        border: [0, 0, 0, 1],
                                        margin: [5, 0, 0, 0],
                                      },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                margin: [0, 10, 0, 0],
                                table: {
                                  widths: ["auto", "*", "auto", 160],
                                  padding: 0,
                                  body: [
                                    [
                                      { text: "ENDEREÇO:", border: [] },
                                      {
                                        text: patient.contact?.street
                                          ? `${patient.contact?.street},${
                                              patient.contact?.address_number ||
                                              ""
                                            }`
                                          : "",
                                        border: [0, 0, 0, 1],
                                        margin: [5, 0, 0, 0],
                                      },
                                      { text: "BAIRRO:", border: [] },
                                      {
                                        text: patient.contact?.district,
                                        border: [0, 0, 0, 1],
                                        margin: [5, 0, 0, 0],
                                      },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                margin: [0, 10, 0, 0],
                                table: {
                                  widths: ["auto", 250, "auto", "auto"],
                                  padding: 0,
                                  body: [
                                    [
                                      { text: "CNS:", border: [] },
                                      {
                                        text: patient.sus_card,
                                        border: [0, 0, 0, 1],
                                        margin: [5, 0, 0, 0],
                                      },
                                      { text: "RAÇA / COR:", border: [] },
                                      { text: etnText, margin: [5, 0, 0, 0] },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                          ],
                        },
                        layout: layout,
                      },
                    ],
                    [
                      {
                        border: [0, 0, 0, 0],
                        table: {
                          widths: ["*"],
                          body: [
                            [
                              {
                                margin: [0, 10, 0, 0],
                                table: {
                                  widths: ["auto", "*"],
                                  padding: 0,
                                  body: [
                                    [
                                      { text: "TELEFONE PARA CONTATO:" },
                                      {
                                        text: formatPhoneNumber(
                                          patient.contact?.phone
                                        ),
                                        border: [0, 0, 0, 1],
                                        margin: [5, 0, 0, 0],
                                      },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                margin: [0, 10, 0, 0],
                                table: {
                                  widths: ["*"],
                                  heights: 40,
                                  padding: 0,
                                  body: [
                                    [
                                      {
                                        text: `HIPÓTESE DIAGNÓSTICA / DIAGNÓSTICO: ${hipo_diag}`,
                                        border: [],
                                      },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                table: {
                                  widths: ["auto", "*"],
                                  padding: 0,
                                  body: [
                                    [
                                      { text: "CID10:", margin: [20, 0, 0, 0] },
                                      {
                                        text: cidsDesc || "",
                                        border: [0, 0, 0, 1],
                                        margin: [5, 0, 0, 0],
                                      },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                table: {
                                  widths: ["*"],
                                  heights: 35,
                                  body: [
                                    [
                                      {
                                        text:
                                          "MOTIVO DO ENCAMINHAMENTO: " +
                                          (data.justification || ""),
                                        border: [0, 0, 0, 1],
                                      },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                table: {
                                  widths: ["*"],
                                  heights: 40,
                                  body: [
                                    [
                                      {
                                        text: `EXAMES E PROCEDIMENTOS REALIZADOS: ${exams_proc}`,
                                        border: [0, 0, 0, 1],
                                      },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                table: {
                                  widths: ["*", "auto", "*"],
                                  padding: 0,
                                  body: [
                                    [
                                      {},
                                      {
                                        text: "",
                                        margin: [0, 55, 0, 0],
                                        border: [0, 0, 0, 1],
                                      },
                                      {},
                                    ],
                                    [
                                      {},
                                      {
                                        text: "CARIMBO E ASSINATURA DO PROFISSIONAL RESPONSÁVEL",
                                      },
                                      { text: "" },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                table: {
                                  widths: [
                                    "auto",
                                    150,
                                    "auto",
                                    150,
                                    "auto",
                                    "*",
                                    "auto",
                                  ],
                                  padding: 0,
                                  body: [
                                    [
                                      { text: "AGENDADO EM " },
                                      { text: "", border: [0, 0, 0, 1] },
                                      { text: "PARA O DIA" },
                                      { text: "", border: [0, 0, 0, 1] },
                                      { text: "AS" },
                                      { text: "", border: [0, 0, 0, 1] },
                                      { text: "HORAS", margin: [0, 0, 30, 0] },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                table: {
                                  widths: ["*"],
                                  heights: 35,
                                  body: [
                                    [
                                      {
                                        text: "RELATÓRIO DA CONSULTA: ",
                                        border: [0, 0, 0, 1],
                                      },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],

                            [
                              {
                                margin: [0, 10, 0, 0],
                                table: {
                                  widths: ["*"],
                                  heights: 35,
                                  body: [
                                    [
                                      {
                                        text: "PROCEDIMENTOS A SEREM ADOTADOS: ",
                                        border: [0, 0, 0, 1],
                                      },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                            [
                              {
                                table: {
                                  widths: ["*", "auto", "*"],
                                  heights: 25,
                                  padding: 0,
                                  body: [
                                    [
                                      {},
                                      {
                                        text: "",
                                        margin: [0, 10, 0, 0],
                                        border: [0, 0, 0, 1],
                                      },
                                      {},
                                    ],
                                    [
                                      {},
                                      {
                                        text: "CARIMBO E ASSINATURA DO PROFISSIONAL RESPONSÁVEL",
                                      },
                                      { text: "" },
                                    ],
                                  ],
                                },
                                layout: layout,
                              },
                            ],
                          ],
                        },
                        layout: layout,
                      },
                    ],
                  ],
                },
                layout: layout,
              },
            ],
          ],
        },
        layout: layout,
      },
      {
        margin: [0, -180, 0, 0],
        qr: url,
        fit: "130",
        eccLevel: "H",
        version: 12,
        absolutePosition: { x: 450, y: 689 },
      },
      writeRotatedText({
        text: [
          `Este documento é cópia do original, assinado digitalmente por ${currentEmployee?.name}.`,
          `Para conferir o original acesse o site https://validar.iti.gov.br, e utilize o Validar (QRcode) disponível no documento e insira os primeiros quatro dígitos do seu CPF.`,
        ],
        fontSize: "8px",
        rotate: "-90deg",
        absolutePosition: { x: 2, y: 720 },
      }),
    ],
    images: {
      sp: SP_LOGO,
    },
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: "start",
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 9,
    },
  };

  const definitions = {
    info: { title: pdfName },
    defaultStyle: {
      fontSize: 10,
    },
    ...dd,
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default againstReferencePDF;
