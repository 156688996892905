import React from "react";
import { TextField, MenuItem } from "@mui/material";
import { useField, useFormikContext } from "formik";

const FormSelectField = ({
  name,
  options,
  customHandleChange,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);

    if (customHandleChange instanceof Function) {
      customHandleChange(value);
    }
  };

  const settings = {
    ...field,
    ...otherProps,
    size: "small",
    select: true,
    fullWidth: true,
    variant: "outlined",
    InputLabelProps: { shrink: true },
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    settings.error = true;
    settings.helperText = meta.error;
  }

  return (
    <TextField {...settings}>
      <MenuItem defaultChecked={true}></MenuItem>
      {!!options &&
        Object.keys(options).map((item, pos) => {
          return (
            <MenuItem key={pos} value={item}>
              {options[item]}
            </MenuItem>
          );
        })}
    </TextField>
  );
};

export default FormSelectField;
