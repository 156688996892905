import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/manufacturers";

export default function useManufacturer() {
  async function getManufacturers(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function getManufacturersByBatch(id) {
    try {
      const response = await instance.get(`${path}?id_manufacturer=${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function postManufacturers(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function updateManufacturers(id, data) {
    try {
      const response = await instance.put(`${path}/update/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  return {
    getManufacturers,
    postManufacturers,
    updateManufacturers,
    getManufacturersByBatch,
  };
}
