import { Box } from "@mui/material";
import TopArch from "./TopArch";
import BottomArch from "./BottomArch";
import { useEffect, useState } from "react";
import CrownOptions from "../CrownOptions";
import RootOptions from "../RootOptions";
import { useField, useFormikContext } from "formik";
import { useQuery } from "react-query";
import useOdonto from "../../../service/useOdonto";
import teethArrayObject from "./TeethArrayObject";

export default function Teeth({
  name,
  archType = "default",
  size = "100%",
  disabledTop,
  disabledBottom,
  disabled,
  attendance,
}) {
  const [crownRefEl, setCrownRefEl] = useState(null);
  const [rootRefEl, setRootRefEl] = useState(null);
  const [teethWithValue, setTeethWithValue] = useState();
  const { getTeethProblems } = useOdonto();

  const { data: teethProblems } = useQuery(
    "getTeethProblems",
    () => getTeethProblems(),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  function handleCrownClick(event, id) {
    setRootRefEl(null);
    setCrownRefEl({ current: event.current, id }); // Use ref.current to get the DOM element
  }

  function handleRootClick(event, id) {
    setCrownRefEl(null);
    setRootRefEl({ current: event.current, id }); // Use ref.current to get the DOM element
  }
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  function handleTeethWithValues(teethValues) {
    const vwTeeth = teethValues
      ?.map((i) => ({
        [i.teeth_id || i.id]: {
          crown: i.crown?.problems?.length || 0,
          root: i.root?.problems?.length || 0,
          faces: i.crown?.faces,
          problems: i.crown?.problems || i.root?.problems
        },
      }))
      .reduce((result, obj) => {
        const key = Object.keys(obj)[0]; // Extract the key from the current object
        result[key] = obj[key]; // Merge the object into the result object
        return result;
      }, {});
    setTeethWithValue(vwTeeth);
  }
  
  const handleFieldValue = (value,fieldValue = [],piece) => {

    const contain = fieldValue?.findIndex(
      (i) => i.teeth_id === value.teeth_id
    );
    if(contain !== -1){
      let oldValue = fieldValue[contain]
      const toReturn = {
        contain: true,
        index: contain
      }
      if (!value[piece]) {
        if (oldValue[piece]?.faces) {
          oldValue[piece] = {
            faces: oldValue[piece].faces,
          };
        } else {
          delete oldValue[piece];
        }
        if (Object.keys(oldValue)?.length === 1) {
          return {
            contain : true,
            index: contain,
            newValue: null
          };
        }
        toReturn["newValue"] = oldValue
        return toReturn
      }
      if (value.crown) {
        value.crown.faces = oldValue?.crown?.faces;
      }
      oldValue[piece] = {...oldValue[piece],...value[piece]} 
      toReturn["newValue"] = oldValue
      return toReturn
    }
    return value
  }

  function handleSave({ replicate_to = [], ...value }, piece) {
    const fieldValue = field.value
    const add = handleFieldValue(value,fieldValue,piece)
    if(add.contain){
      if(add.newValue){
        fieldValue[add.index] = add.newValue
      }else{
        fieldValue.splice(add.index, 1);
      }
    }else{
      fieldValue.push(add)
    }

    replicate_to.forEach((tooth) => {
      const toReplicate = value[piece]?.problems;
      const add = handleFieldValue(
        {
          [piece]: { problems: toReplicate },
          teeth_id: tooth.id
        },
        fieldValue,
        piece
      );
      if(add.contain){
        if(add.newValue){
          fieldValue[add.index] = add.newValue
        }else{
          fieldValue.splice(add.index, 1);
        }
      }else{
        fieldValue.push(add)
      }
    })

    handleTeethWithValues(fieldValue)
    setFieldValue(name,fieldValue)
  }

  function handleFaceClick(face, tooth_id) {
    const contain = field.value?.findIndex((i) => i.teeth_id === tooth_id);
    if (contain !== -1) {
      if (!field?.value[contain].crown) {
        field.value[contain].crown = {};
      }
      let oldValue = field?.value[contain].crown?.faces;
      if (!oldValue) {
        oldValue = { [face]: true };
      } else if (oldValue[face] === false) {
        delete oldValue[face];
      } else {
        oldValue[face] = oldValue?.[face] === undefined ? true : false;
      }

      if (
        !Object.keys(oldValue).length &&
        Object.keys(field?.value[contain].crown).length === 1
      ) {
        delete field.value[contain].crown;
      } else if (!Object.keys(oldValue).length) {
        delete field.value[contain].crown.faces;
      } else {
        field.value[contain].crown.faces = oldValue;
      }

      if (Object.keys(field.value[contain])?.length === 1) {
        field.value.splice(contain, 1);
      }

      handleTeethWithValues(field.value);
      setFieldValue(name, field.value);
    } else {
      const newValue = {
        teeth_id: tooth_id,
        crown: { faces: { [face]: true } },
      };
      handleTeethWithValues([...field.value, newValue]);
      setFieldValue(name, [...field.value, newValue]);
    }
  }

  useEffect(() => {
    handleTeethWithValues(field.value);
  }, [field.value]);

  useEffect(() => {
    if (disabledTop) {
      const topTeeth = [];
      teethArrayObject?.default.top.forEach((tooth) => topTeeth.push(tooth.id));
      teethArrayObject?.deciduous.top.forEach((tooth) =>
        topTeeth.push(tooth.id)
      );
      const newValue = field.value.filter(
        (value) => !topTeeth.includes(value.teeth_id)
      );
      setFieldValue(name, newValue);
    }

    if (disabledBottom) {
      const bottomTeeth = [];
      teethArrayObject?.default.bottom.forEach((tooth) =>
        bottomTeeth.push(tooth.id)
      );
      teethArrayObject?.deciduous.bottom.forEach((tooth) =>
        bottomTeeth.push(tooth.id)
      );
      const newValue = field.value.filter(
        (value) => !bottomTeeth.includes(value.teeth_id)
      );
      setFieldValue(name, newValue);
    }
  }, [disabledTop, disabledBottom]);

  return (
    <Box display={"flex"} width={size} flexDirection={"column"}>
      <CrownOptions
        anchorEl={crownRefEl?.current}
        id={crownRefEl?.id}
        handleClose={() => setCrownRefEl(null)}
        open={!!crownRefEl}
        handleSave={handleSave}
        problems={teethProblems?.filter(
          (teethProblem) =>
            teethProblem.type === "crown" || teethProblem.type === "teeth"
        )}
        disabled={disabled}
        attendance={attendance}
        type={archType}
      />
      <RootOptions
        anchorEl={rootRefEl?.current}
        id={rootRefEl?.id}
        handleClose={() => setRootRefEl(null)}
        open={!!rootRefEl}
        handleSave={handleSave}
        problems={teethProblems?.filter(
          (teethProblem) =>
            teethProblem.type === "root" || teethProblem.type === "teeth"
        )}
        disabled={disabled}
        attendance={attendance}
        type={archType}
      />
      <>
        <TopArch
          handleCrownClick={handleCrownClick}
          handleRootClick={handleRootClick}
          teethWithValue={teethWithValue}
          handleFaceClick={handleFaceClick}
          type={archType}
          disabled={disabledTop}
          disableFaces={disabled}
          size={"100%"}
        />
        <BottomArch
          handleCrownClick={handleCrownClick}
          handleRootClick={handleRootClick}
          teethWithValue={teethWithValue}
          handleFaceClick={handleFaceClick}
          type={archType}
          disableFaces={disabled}
          disabled={disabledBottom}
          size={"100%"}
        />
      </>
    </Box>
  );
}
