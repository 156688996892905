import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { ConfirmDialog } from "../../../../helper";
import { useAuth } from "../../../../service";
import useNotifier from "../../../../hooks/useNotifier";
import { Button, TextField } from "../../../../components/FormFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../../config/yup";

const EmployeeUserAndPasswordReset = ({ employee }) => {
  const theme = useTheme();
  const usernameForm = useForm({
    defaultValues: {
      username: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        username: Yup.string().required("Informe um novo nome de usuário."),
      })
    ),
  });
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    onConfirm: () => null,
    message: "",
  });
  const notify = useNotifier();

  const { resetPassword, resetUsername } = useAuth();
  const resetPasswordMutation = useMutation(resetPassword);
  const resetUsernameMutation = useMutation(resetUsername);

  function handleResetPassword() {
    resetPasswordMutation.mutate(employee.id, {
      onSuccess(response) {
        notify(response.message, "success");
      },
      onError(error) {
        notify(error.message, "error");
      },
    });
  }

  const handleResetUsername = usernameForm.handleSubmit(({ username }) => {
    resetUsernameMutation.mutate(
      { id: employee.id, newUsername: username },
      {
        onSuccess(response) {
          notify(response.message, "success");
        },
        onError(error) {
          notify(error.message, "error");
        },
      }
    );
  });

  return (
    <Box>
      <ConfirmDialog
        open={openConfirm.open}
        message={openConfirm.message}
        actions
        handleClose={() => setOpenConfirm({ open: false })}
        handleConfirm={openConfirm.onConfirm}
      />
      <Typography
        sx={{
          fontSize: 15,
          color: theme.palette.primary.light,
          marginBottom: 2,
        }}
      >
        Modifique o nome de usuário.
      </Typography>
      <Box display={"flex"} alignItems={"start"} columnGap={3}>
        <Box flex={1}>
          <TextField
            control={usernameForm.control}
            name={"username"}
            label={"Novo Nome de usuário"}
          />
        </Box>
        <Box flex={2}>
          <Button
            loading={resetPasswordMutation.isLoading}
            onClick={() =>
              setOpenConfirm({
                open: true,
                message: `Você realmente deseja *Modificar* o usuário de acesso para *${
                  employee.name.split(" ")[0]
                }* ?`,
                onConfirm: handleResetUsername,
              })
            }
          >
            Modificar Usuário
          </Button>
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: 15,
          color: theme.palette.primary.light,
          marginBottom: 2,
          mt: 2,
        }}
      >
        A senha padrão do usuário é formada pelos primeiros 4 dígitos de seu
        CPF.
      </Typography>
      <Button
        loading={resetPasswordMutation.isLoading}
        onClick={() =>
          setOpenConfirm({
            open: true,
            message: `Você realmente deseja *resetar* a senha de *${
              employee.name.split(" ")[0]
            }* ?`,
            onConfirm: handleResetPassword,
          })
        }
      >
        Resetar senha
      </Button>
    </Box>
  );
};

export default EmployeeUserAndPasswordReset;
