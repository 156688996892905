import {
  headerHorizontalStyle,
  footer,
  title,
  patientInformations,
} from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";

function patientDischargePDF(data, patient, documentData) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const patientDischargeInformations = [
    {
      margin: [0, 30, 0, 10],
      fontSize: 12,
      text: "Informações da Alta",
      bold: true,
      style: "header",
    },
    {
      margin: [-10, 0, 0, 0],
      type: "none",
      ul: [
        {
          text: [
            { text: "Motivo da alta: ", bold: true },
            {
              text: data.reason.describe,
            },
          ],
          margin: [0, 0, 0, 5],
        },
        {
          text: [
            { text: "Observação/Anotação: ", bold: true },
            {
              text: data.notes,
            },
          ],
          margin: [0, 0, 0, 5],
        },
      ],
    },
  ];

  const urlType = pdfName + "/types/patient_discharge";

  const definitions = {
    info: { title: pdfName },
    pageMargins: [40, 105, 40, 120],
    defaultStyle: {
      fontSize: 10,
    },
    ...headerHorizontalStyle(documentData?.company),
    footer: footer(
      documentData?.createdAt,
      true,
      documentData?.employee,
      documentData?.company,
      urlType
    ),
    content: [
      title("Alta"),
      patientInformations(patient),
      patientDischargeInformations,
    ],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;
  documentPDF.base64 = async () => {
    const pathBase64 = await new Promise((resolve) => {
      documentPDF.getBase64((base64) => {
        resolve(base64);
      });
    });

    return pathBase64;
  };

  return documentPDF;
}

export default patientDischargePDF;
