import { Box, TablePagination } from "@mui/material";
import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { useRoles } from "../../../service";
import { DialogMedium } from "../../../helper";
import ScreenPermisions from "./AccessSettings/ScreenPermisions";
import RoleEmployeeType from "./AccessSettings/RoleEmployeeType";
import TAccordion from "../../../components/Accordion";
import RolesForm from "./AccessSettings/RolesForm";
import TableFilter from "../../../components/Table/TableFilter";

const TabRoles = () => {
  const { getRoles } = useRoles();
  const [page, setPage] = useState(0);
  const [roleToPermissions, setRoleToPermissions] = useState(null);
  const [roleToEdit, setRoleToEdit] = useState(null);
  const [limit, setLimit] = useState(5);

  const getRolesQuery = useQuery(
    ["get-roles", [page, limit]],
    () => getRoles({ page, limit }),
    {
      keepPreviousData: true,
      initialData: {
        totalItems: 0,
        items: [],
      },
    }
  );

  const tableColumns = [
    { name: "Number", field: "number" },
    { name: "Nome", field: "name" },
    {
      name: "Status",
      field: "active",
      use: (status) => (status ? "Ativo" : "Inativo"),
    },
  ];

  const settings = [
    {
      title: "Telas",
      body: <ScreenPermisions role={roleToPermissions} />,
    },
    {
      title: "Tipo de profissional",
      body: <RoleEmployeeType role={roleToPermissions} />,
    },
  ];

  return (
    <Box>
      <DialogMedium
        open={!!roleToPermissions}
        title={"Vincular a papel."}
        handleClose={() => setRoleToPermissions(null)}
        maxWidth={"100vw"}
      >
        <TAccordion configs={settings} />
      </DialogMedium>
      <DialogMedium
        open={!!roleToEdit}
        title={"Editar papel"}
        handleClose={() => setRoleToEdit(null)}
        maxWidth={"100vw"}
      >
        <Grid container xs={12} spacing={10}>
          <Grid xs={12} item>
            <RolesForm
              data={roleToEdit}
              handleClose={() => setRoleToEdit(null)}
            />
          </Grid>
        </Grid>
      </DialogMedium>
      <Grid container xs={12} spacing={2}>
        <Grid xs={12} item>
          <RolesForm />
        </Grid>
        <Grid xs={12} item>
          <Box>
            <TableFilter
              loading={getRolesQuery.isFetching}
              data={getRolesQuery.data.items}
              columns={tableColumns}
              actions
              actionsTypes={["view", "edit"]}
              actionHandleEdit={(_, role) => setRoleToEdit(role)}
              actionHandleView={(_, role) => setRoleToPermissions(role)}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <TablePagination
                count={getRolesQuery.data.totalItems}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 20, 75]}
                onRowsPerPageChange={({ target }) => {
                  setLimit(parseInt(target.value, 10));
                  setPage(0);
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TabRoles;
