import { Box, Typography } from "@mui/material";
import { ReactComponent as NotFound } from "../../assets/svg/tportal_notFound.svg";
export default function DocumentNotFound(){
    return (
        <Box
          width={"100%"}
          rowGap={"30px"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            position: "absolute",
            margin: "0 auto",
            top: "0",
            bottom: "0",
            right: "0",
            left: "0",
          }}
        >
          <NotFound />
          <Typography fontSize={"34px"} fontWeight={600} color={"primary"}>
            Não foi possível trazer os dados desse receituário.
          </Typography>
        </Box>
    )
}