import instance from "../config/api";
const path = "/call-system";
export default function useInitialAttendance() {
  async function getAllAttendancesByQuery(query, page, limit) {
    try {
      const pathPage = `page=${page}&limit=${limit}`;
      const response = await instance.get(`attendances?${pathPage}&${query}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data);
    }
  }

  async function getMonthlyAttendancesDates(date, monthlyAttendance) {
    try {
      const pathDate = `attendances?filter=${date}&monthly_attendances_dates_only=${monthlyAttendance}`;
      const response = await instance.get(pathDate);
      const attendanceDate = response.data.monthlyAttendancesDates;
      return attendanceDate;
    } catch (err) {
      throw new Error(err.response.data);
    }
  }

  async function postCallSystem(data) {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function getSectorsAndEmployees(params) {
    try {
      const response = await instance.get("search", {params});
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  return {
    getAllAttendancesByQuery,
    getMonthlyAttendancesDates,
    postCallSystem,
    getSectorsAndEmployees,
  };
}
