import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Box, Grid, Typography } from "@mui/material";
import Yup from "../../config/yup";
import { theme } from "../../config/theme";
import { useProvider, useTeam, useMicroArea } from "../../service";
import { DialogMedium, Loading } from "../../helper";
import TableFilter from "../../components/Table/TableFilter";
import { useForm } from "react-hook-form";
import TextField from "../../components/FormFields/TextField";
import SelectField from "../../components/FormFields/SelectField";
import RadioField from "../../components/FormFields/RadioField";
import AutocompleteField from "../../components/FormFields/AutocompleteField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router";
import {
  Button,
  PaginatedAutocompleteField,
} from "../../components/FormFields";
import { useNotifier } from "../../hooks";

function TeamForm({ type, team, handleClose }) {
  const notify = useNotifier();
  const [specialtyOptions, setSpecialtyOptions] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);
  const { getProviders } = useProvider();
  const { getMicroArea } = useMicroArea();
  const { postTeam, updateTeam } = useTeam();
  const { id } = useParams();
  const postTeamMutation = useMutation(postTeam);
  const updateTeamMutation = useMutation(updateTeam);
  const [openEdit, setOpenEdit] = useState(false);
  const [indexEmployee, setIndexEmployee] = useState(null);

  const initialValues = {
    team_name: null,
    ine: null,
    type: "",
    employee: null,
    microareas: [],
    specialty: null,
    leader: false,
    edit_specialty: null,
    edit_leader: employees[indexEmployee]?.leader,
  };

  const validations = Yup.object().shape({
    team_name: Yup.string().required("É requerido").nullable(),
    ine: Yup.number()
      .nullable()
      .typeError("Número Inválido")
      .required("É requerido"),
    type: Yup.object().required("É requerido").nullable(),
    microareas: Yup.array().nullable(),
    employee: Yup.object().required("É requerido").nullable(),
    specialty: Yup.object().required("É requerido").nullable(),
    leader: Yup.boolean().required("É requerido"),
  });

  const { handleSubmit, control, watch, setValue, reset } = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const [employee, specialty, edit_specialty, edit_leader] = watch([
    "employee",
    "specialty",
    "edit_specialty",
    "edit_leader",
  ]);

  useEffect(() => {
    if (type === "edit") {
      reset({
        team_name: team?.name,
        ine: team.ine,
        type: { describe: team.type },
        microareas: team.microareas,
      });

      const formatedEmployees = team?.members?.map(
        ({ id, employee, specialty, leader }) => ({
          employe_number: employee.number,
          employee_name: employee.name,
          specialty: specialty.describe,
          leader,
          specialty_id: specialty.id,
          employee_id: employee.id,
          employee_team_id: id,
          specialties: employee.specialties,
        })
      );

      setEmployees(formatedEmployees);
    }
  }, [type, team]);

  // Handle Functions
  function filterSpecialties(employee) {
    if (!!employee) {
      setSpecialtyOptions(employee.specialties);
      return employee.specialties;
    }
  }

  function verifyHasLeader(option) {
    if (option?.value) {
      const hasLeader = employees.some(({ leader }) => leader === true);
      return hasLeader;
    }
    return false;
  }

  function handleDelete(index) {
    const newEmployees = employees.filter((_, i) => i !== index);
    setEmployees(newEmployees);
  }

  function handleEdit(index) {
    const specialtyOptions = filterSpecialties(employees[index]);
    setValue(
      "edit_specialty",
      specialtyOptions.find(({ id }) => {
        return id === employees[index].specialty_id;
      })
    );
    setValue("edit_leader", employees[index].leader);
    setIndexEmployee(index);
    setOpenEdit(true);
  }

  function addEmployee({
    team_name,
    microareas,
    ine,
    type,
    employee,
    leader,
    specialty,
  }) {
    setEmployees((previousEmployee) => [
      ...previousEmployee,
      {
        employe_number: employee.number,
        employee_name: employee.name,
        specialty: specialty.describe,
        leader,
        specialty_id: specialty.id,
        employee_id: employee.id,
        employee_team_id: id,
        specialties: employee.specialties,
      },
    ]);

    reset({
      team_name,
      microareas,
      ine,
      type,
      employee: null,
      specialty: null,
      leader: false,
    });
  }

  function editEmployee(specialty, leader, index) {
    employees[index].specialty = specialty.describe;
    employees[index].specialty_id = specialty.id;
    employees[index].leader = leader;

    setEmployees(employees);
    setOpenEdit(false);
  }

  function formatTeam({ team_name, microareas, ine, type }) {
    const formatedEmployees = employees.reduce(
      (previous, { leader, employee_id, specialty_id }) => {
        previous?.members?.push({
          leader: leader,
          id_employee: employee_id,
          id_specialty: specialty_id,
        });

        return previous;
      },
      {
        members: [],
      }
    );

    const microAreasIds = microareas?.map(({ id }) => id);

    return {
      name: team_name,
      ine: ine.toString(),
      type: type.describe,
      microareas_ids: microAreasIds,
      ...formatedEmployees,
    };
  }
  async function onSubmit(values) {
    const teamData = formatTeam(values, employees);

    if (type !== "edit") {
      const team = formatTeam(values);
      postTeamMutation.mutate(team, {
        onSuccess: (response) => {
          setEmployees([]);
          reset({
            team_name: "",
            ine: "",
            type: null,
            employee: null,
            microareas: [],
            specialty: null,
            leader: false,
          });
          notify(response.data?.message, "success");
          handleClose();
        },
        onError: (error) => notify(error.message, "error"),
      });
    } else {
      updateTeamMutation.mutate(
        {
          teamId: team.id,
          teamData: teamData,
        },
        {
          onSuccess: (response) => {
            notify(response.data.message, "success");
            handleClose();
          },
          onError: (error) => {
            notify(error.message, "error");
          },
        }
      );
    }
  }

  const radioConfig = [
    { label: "Sim", value: true, disabled: verifyHasLeader() },
    { label: "Não", value: false },
  ];

  const optionTypesTeam = [
    { describe: "ESB" },
    { describe: "EAP" },
    { describe: "ECR" },
    { describe: "ESF" },
    { describe: "EMULTI" },
    { describe: "EMAP" },
    { describe: "EMAD T1" },
  ];

  const collumnsNames = [
    {
      name: "Código",
      field: "employe_number",
    },
    { name: "Profissional", field: "employee_name" },
    { name: "Especialidade", field: "specialty" },
    {
      name: "Profissional Referência",
      field: "leader",
      use: (value) => (!!value ? "Sim" : "Não"),
    },
  ];

  if (postTeamMutation.isLoading || updateTeamMutation.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(addEmployee)}>
        <Grid container spacing={3} my="0.5rem">
          <Grid xs={12} spacing={3} container item>
            <Grid item md={4} xs={12}>
              <TextField
                control={control}
                name="team_name"
                label="Equipe"
                type="text"
                required
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <PaginatedAutocompleteField
                control={control}
                name="microareas"
                label="Microáreas"
                optionLabelKey="name"
                filterKey={"name"}
                queryKey={"microareas"}
                service={(params) => getMicroArea({ ...params, team_id: null })}
                multiple
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                control={control}
                name="ine"
                label="Identificador nacional de equipe (INE)"
                type="text"
                inputProps={{ maxLength: 25 }}
                required
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SelectField
                control={control}
                name="type"
                label="Tipo de Equipe"
                options={optionTypesTeam}
                optionLabelKey="describe"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h2"
                sx={{
                  color: theme.palette.primary.light,
                  fontSize: 15,
                  fontWeight: 500,
                }}
              >
                Profissionais Participantes
              </Typography>
            </Grid>
            <Grid xs={12} md={4} item>
              <PaginatedAutocompleteField
                control={control}
                service={(params) =>
                  getProviders({
                    ...params,
                    active: true,
                  })
                }
                customOnChange={(value) => {
                  if (employee?.specialty.id === specialty?.id) {
                    setValue("specialty", null);
                  }

                  filterSpecialties(value);
                }}
                filterKey={"name"}
                name="employee"
                label="Profissional"
                required
                optionLabelKey="name"
                queryKey="employee"
                autoCompleteProps={{
                  filterOptions: (options) => {
                    return options?.filter((option) =>
                      employees.every(
                        ({ employee_id }) => employee_id !== option.id
                      )
                    );
                  },
                }}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <AutocompleteField
                control={control}
                name="specialty"
                label="Especialidades"
                options={specialtyOptions}
                optionLabelKey="describe"
                disabled={!employee}
                required
              />
            </Grid>
            <Grid item md={2} xs={8}>
              <RadioField
                control={control}
                name="leader"
                label="Médico responsável :"
                options={radioConfig}
                disabled={true}
                disableOptions={verifyHasLeader}
                optionLabelKey="label"
                optionValueKey="value"
                direction="row"
              />
            </Grid>
            <DialogMedium
              title={employees[indexEmployee]?.employee_name}
              open={openEdit}
              handleClose={() => setOpenEdit(false)}
              fullWidth
            >
              {!!employees[indexEmployee] && (
                <Grid container spacing={3} marginTop="0.5rem">
                  <Grid item xs={8}>
                    <AutocompleteField
                      control={control}
                      name="edit_specialty"
                      label="Especialidades"
                      options={specialtyOptions}
                      optionLabelKey="describe"
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RadioField
                      control={control}
                      name="edit_leader"
                      label="Médico responsável :"
                      options={radioConfig}
                      disabled={true}
                      disableOptions={verifyHasLeader}
                      optionLabelKey="label"
                      optionValueKey="value"
                      direction="row"
                    />
                  </Grid>
                  <Grid container item xs={12} justifyItems="center">
                    <Grid item xs={4}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                          editEmployee(
                            edit_specialty,
                            edit_leader,
                            indexEmployee
                          )
                        }
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </DialogMedium>
            <Grid item md={2} xs={4}>
              <Button type="submit" color="secondary" variant="contained">
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <TableFilter
          columns={collumnsNames}
          data={employees}
          actions
          actionsTypes={type === "edit" ? ["delete", "edit"] : ["delete"]}
          actionHandleEdit={handleEdit}
          actionHandleDelete={handleDelete}
        />
        <Button
          sx={{ marginTop: 8 }}
          onClick={() => onSubmit(watch())}
          disabled={!employees?.some(({ leader }) => leader === true)}
          loading={postTeamMutation.isLoading}
          color="secondary"
          variant="contained"
        >
          Salvar
        </Button>
      </Box>
    </>
  );
}

export default TeamForm;
