import { useCallback } from "react";
import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
import { handleParam } from "../utils/handleParam";
const path = "/procedures";

function useProcedures() {
  const getProcedures = useCallback(async ({ filters, ...params }) => {
    const param = handleParam(filters, {
      tuss_code: /^[0-9]+$/,
      name: /.+/,
    });

    try {
      const { data } = await instance.get(path, {
        params: urlQuery({ ...param, ...params }),
      });
      
      data.items = data?.items.map(
        ({ name, external_id, integration_system, ...procedure }) => ({
          describe: `${external_id ? external_id : ""} ${name} ${
            integration_system ? `- (${integration_system})` : ""
          }`,
          name,
          external_id,
          ...procedure,
        })
      );
      return data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  return {
    getProcedures,
  };
}
export default useProcedures;
