import { useCallback } from "react";
import instance from "../config/api";

const path = "/external-services/siga/schedulings";

function useIntegratedSheduling() {
  const postIntegratedSheduling = useCallback(async (data) => {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  return {
    postIntegratedSheduling,
  };
}
export default useIntegratedSheduling;
