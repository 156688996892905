import { useState } from "react";

export default function usePagination(initialData, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(initialData?.length / itemsPerPage);

  const paginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return initialData?.slice(startIndex, endIndex);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const paginationInfo = {
    totalItems: initialData?.length,
    page: currentPage,
    limit: itemsPerPage,
    totalPages: totalPages,
    currentPage: currentPage,
    items: paginatedData(),
  };

  return { paginationInfo, handlePageChange };
}
