import { FormProvider, useForm } from "react-hook-form";
import { useAttendaces, useProvider } from "../../../service";
import TextField from "../../../components/FormFields/TextField";
import { Button, Grid } from "@mui/material";
import Yup from "../../../config/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useNotifier } from "../../../hooks";
import { PaginatedAutocompleteField } from "../../../components/FormFields";

export default function TransferForm({ attendanceId, refetch, handleClose }) {
  const transferValidations = Yup.object().shape({
    employee: Yup.object()
      .nullable()
      .required("O profissional é necessário para realização da tranferência."),
    transfer_notes: Yup.string(),
  });

  const form = useForm({
    defaultValues: {
      employee: null,
      transfer_notes: "",
    },
    resolver: yupResolver(transferValidations),
  });
  const { getProviders } = useProvider();
  const { transferAttendance } = useAttendaces();
  const notify = useNotifier();
  const transferAttendanceMutation = useMutation(transferAttendance);

  function handleTransferAttendance({ transfer_notes, employee }) {
    transferAttendanceMutation.mutate(
      {
        attendanceId: attendanceId,
        data: {
          id_employee: employee.id,
          transfer_notes: transfer_notes || null,
        },
      },
      {
        onSuccess(response) {
          refetch();
          handleClose();
          notify(response.message, "success");
        },
        onError(error) {
          notify(error.message, "error");
        },
      }
    );
  }
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleTransferAttendance)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <PaginatedAutocompleteField
              service={getProviders}
              queryKey={"employeesQuery"}
              name={"employee"}
              filterKey={"name"}
              label={"Profissional"}
              optionLabelKey="name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="transfer_notes"
              label="Justificativa de transferência"
              multiline
              minRows={8}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              type="submit"
              loading={transferAttendanceMutation.isLoading}
            >
              Transferir
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
