import { Box, Typography, useTheme } from "@mui/material";
export default function ColorInfoLabels({ labels }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginBottom: "10px",
      }}
    >
      <Typography
        sx={{
          marginBottom: "5px",
          color: theme.palette.primary.light,
          fontWeight: "medium",
        }}
      >
        Mapa de cores
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        {labels.map((label, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "25px",
                height: "15px",
                borderRadius: "4px",
                background: label.color || theme.palette.primary.main,
              }}
            />
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              {label.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
