import { Box, Divider, Grid, Typography, styled } from "@mui/material";
import React from "react";

const Title = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.primary.light}`,
  fontSize: 16,
}));

const Label = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 500,
}));

const LabelDescribe = styled(Typography)(() => ({
  fontSize: 12,
}));

const generalInformations = [
  {
    label: "Total de Serviços:",
    describe:
      "Quantidade de Serviços gerados para os atendimentos no segmento AMA ou UBS da unidade",
  },
  {
    label: "Serviços:",
    describe:
      "Quantidade de cada serviço gerado para os atendimentos no segmento AMA ou UBS da unidade, barra com percentual do Serviço vigente na linha pelo Total de Serviços",
  },
  {
    label: "Prioritários:",
    describe: "Quantidade de Senhas Geradas por Prioridade",
  },
  {
    label: "Gênero:",
    describe: "Quantidade de Atendimentos por Gênero",
  },
  {
    label: "Tempo Médio do Atend:",
    describe:
      "Tempo médio para o atendimento do paciente, a partir da retirada da ficha até finalização do SOAP",
  },
];

const amaInformations = [
  {
    label: "Em Atendimento:",
    describe:
      "Quantidade de Atendimentos gerados para o segmento AMA da unidade",
  },
  {
    label: "Evadidos:",
    describe: "Quantidade de Pacientes Evadidos do segmento AMA da unidade",
  },
  {
    label: "SOAPs:",
    describe: "Quantidade de SOAPs criados no segmento AMA da unidade",
  },
];

const ubsInformations = [
  {
    label: "Em Atendimento:",
    describe:
      "Quantidade de Atendimentos gerados para o segmento UBS da unidade",
  },
  {
    label: "Presenças:",
    describe:
      "Percentual de Presenças confirmadas para a Unidade, Total de Agendados / Confirmados",
  },
  {
    label: "SOAPs:",
    describe: "Quantidade de SOAPs criados no segmento UBS da unidade",
  },
];

const dischargeInformations = [
  {
    label: "Gráfico de Barra:",
    describe:
      "Quantidade de Pacientes que tiveram alta da unidade no segmento AMA ou UBS",
  },
  {
    label: "Percentual:",
    describe:
      "Quantidade de Pacientes que tiveram alta da unidade no segmento AMA ou UBS pelo Total de Atendimentos",
  },
];

const riskClassificationInformations = [
  {
    label: "Card de Filas:",
    describe:
      "Cor da fila, quantidade de cada uma, tempo médio para Paciente ser classificado",
  },
  {
    label: "Total de Classificados:",
    describe: "Total de pacientes classificados na unidade",
  },
];

const pendingIndicatorsInformations = [
  {
    label: "Atendimentos Excedidos:",
    describe:
      "Atendimentos com tempo excedido, sem criação de SOAPs ou Monitoramento a mais de 1h",
  },
  {
    label: "Monitoramentos Excedidos:",
    describe:
      "Monitoramentos com tempo excedido, que não foram finalizados a mais de 1h",
  },
  {
    label: "Atendimento sem Pacientes:",
    describe: "Atendimentos gerados para pacientes não identificados",
  },
  {
    label: "Classificação sem Pacientes:",
    describe: "Classificação de atendimentos de pacientes não identificados",
  },
  {
    label: "Medicações não Finalizadas:",
    describe: "Medicações com Evolução que não foram finalizadas a mais de 1h",
  },
  {
    label: "Regulações não Finalizadas:",
    describe: "Regulações com Evolução que não foram finalizadas a mais de 1h",
  },
  {
    label: "Exames Lab não Finalizados:",
    describe:
      "Exames Laboratoriais com Evolução que não foram finalizadas a mais de 1h",
  },
  {
    label: "ECGs não Finalizados:",
    describe:
      "Ecocardiogramas com Evolução que não foram finalizadas a mais de 1h",
  },
  {
    label: "Exames de Imagem não Finalizados:",
    describe:
      "Exames de Imagem com Evolução que não foram finalizadas a mais de 1h",
  },
  {
    label: "Procedimentos não Finalizados:",
    describe:
      "Procedimentos com Evolução que não foram finalizadas a mais de 1h",
  },
  {
    label: "Declarações não Finalizados:",
    describe:
      "Declarações/Atestados com Evolução que não foram finalizadas a mais de 1h",
  },
];

export default function CockPitDataSheet({ legend }) {
  return (
    <>
      <Box mb={3.5}>
        <Title>Legendas</Title>
        <Divider sx={{ marginY: 1 }} />
        <Grid container spacing={1}>
          {legend.map(({ icon, label }, index) => (
            <Grid
              item
              xs={4}
              display={"flex"}
              gap={0.5}
              alignItems={"center"}
              color={(theme) => theme.palette.primary.light}
              key={index + "-" + label}
            >
              {icon}
              <Typography fontSize={12}>{label}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mb={3.5}>
        <Title>Informações Gerais</Title>
        <Divider sx={{ marginY: 1 }} />
        {generalInformations?.map(({ label, describe }) => (
          <Box display={"flex"} gap={2} mb={0.2} alignItems={"center"}>
            <Label>{label}</Label>
            <LabelDescribe>{describe}</LabelDescribe>
          </Box>
        ))}
      </Box>
      <Box mb={3.5}>
        <Title>Card AMA</Title>
        <Divider sx={{ marginY: 1 }} />
        {amaInformations?.map(({ label, describe }) => (
          <Box display={"flex"} gap={2} mb={0.2} alignItems={"center"}>
            <Label>{label}</Label>
            <LabelDescribe>{describe}</LabelDescribe>
          </Box>
        ))}
      </Box>
      <Box mb={3.5}>
        <Title>Card UBS</Title>
        <Divider sx={{ marginY: 1 }} />
        {ubsInformations?.map(({ label, describe }) => (
          <Box display={"flex"} gap={2} mb={0.2} alignItems={"center"}>
            <Label>{label}</Label>
            <LabelDescribe>{describe}</LabelDescribe>
          </Box>
        ))}
      </Box>
      <Box mb={3.5}>
        <Title>Card de Pacientes Liberados</Title>
        <Divider sx={{ marginY: 1 }} />
        {dischargeInformations?.map(({ label, describe }) => (
          <Box display={"flex"} gap={2} mb={0.2} alignItems={"center"}>
            <Label>{label}</Label>
            <LabelDescribe>{describe}</LabelDescribe>
          </Box>
        ))}
      </Box>
      <Box mb={3.5}>
        <Title>Card de Classificação de Risco</Title>
        <Divider sx={{ marginY: 1 }} />
        {riskClassificationInformations?.map(({ label, describe }) => (
          <Box display={"flex"} gap={2} mb={0.2} alignItems={"center"}>
            <Label>{label}</Label>
            <LabelDescribe>{describe}</LabelDescribe>
          </Box>
        ))}
      </Box>
      <Box mb={3.5}>
        <Box display={"flex"} gap={3}>
          <Title>Indicadores com Pendências</Title>
          <Box display={"flex"} gap={0.5} alignItems={"center"}>
            <Typography color="#C331D6" fontWeight={700} fontSize={12}>
              AMA
            </Typography>{" "}
            |
            <Typography color="#2CB043" fontWeight={700} fontSize={12}>
              UBS
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ marginY: 1 }} />
        {pendingIndicatorsInformations?.map(({ label, describe }) => (
          <Box display={"flex"} gap={2} mb={0.2} alignItems={"center"}>
            <Label>{label}</Label>
            <LabelDescribe>{describe}</LabelDescribe>
          </Box>
        ))}
      </Box>
      <LabelDescribe>
        * Obs: Todos os Indicadores do Painel são válidos para o dia atual de
        operação da unidade
      </LabelDescribe>
    </>
  );
}
