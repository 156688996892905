import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path2 = "/scales/days/schedulings";
const path3 = "/scales/days/schedulings?filter=sector";
const path4 = "/scales/days/schedulings?filter=employee";
function useDateAPI() {
  async function getDate(page = 0, limit = 75) {
    try {
      const response = await instance.get(
        `/scales/days/schedules/?page=${page}&limit=${limit}&filter=available&active=true`
      );
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function getDateSchedulings(params = {}) {
    try {
      const response = await instance.get(path2, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function getDateStatic() {
    try {
      const response = await instance.get(path2);
      return response;
    } catch (err) {
      return err;
    }
  }
  async function getDateStaticSetor(page = 0, limit = 75) {
    try {
      const response = await instance.get(
        path2 + `?page=${page}&limit=${limit}&filter=sector`
      );
      return response;
    } catch (err) {
      return err;
    }
  }
  async function getDateStaticEmployee(page = 0, limit = 75) {
    try {
      const response = await instance.get(
        path2 + `?page=${page}&limit=${limit}&filter=employee`
      );
      return response;
    } catch (err) {
      return err;
    }
  }
  return {
    getDate,
    getDateStatic,
    getDateStaticSetor,
    getDateStaticEmployee,
    getDateSchedulings,
  };
}
export default useDateAPI;
