import React from "react";
import { useField, useFormikContext } from "formik";
import { TextField } from "@mui/material";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DatePicker,
  LocalizationProvider,
  DesktopDateTimePicker,
} from "@mui/x-date-pickers";
import ptBR from "date-fns/esm/locale/pt-BR";

function FormDate({
  name,
  orientation = "portrait",
  minDate,
  maxDate,
  views,
  type,
  monthChange,
  datas,
  disabled,
  customHandleChange,
  ...props
}) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const [open, setOpen] = React.useState(false);
  const settings = {
    ...field,
    ...props,
    size: "small",
    fullWidth: true,
    variant: "outlined",
  };

  if (meta && meta.touched && meta.error) {
    settings.error = true;
    settings.helperText = meta.error;
  }

  return (
    <>
      {type === "date" || !type ? (
        <React.Fragment>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <DatePicker
              views={["year", "month", "day"]}
              open={open}
              orientation={orientation}
              name={field.name}
              label="Data"
              onMonthChange={monthChange}
              value={field.value != null ? field.value : null}
              onChange={(value) => {
                setFieldValue(field.name, value);

                if (customHandleChange instanceof Function) {
                  customHandleChange(value);
                }
              }}
              disabled={disabled}
              minDate={minDate}
              maxDate={maxDate}
              showToolbar={true}
              showTodayButton={true}
              toolbarTitle={"Escolha sua Data"}
              outsideCurrentMonth={true}
              showDaysOutsideCurrentMonth={true}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              inputFormat={"dd/MM/yyyy"}
              shouldDisableDate={datas}
              renderInput={(params) => (
                <React.Fragment>
                  <TextField
                    {...params}
                    {...props}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    id={field.name}
                    error={meta.error ? true : false}
                    helperText={!!meta && meta.error && meta.error}
                    fullWidth={true}
                    onClick={(e) => setOpen(!disabled)}
                  />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </React.Fragment>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <DesktopDateTimePicker
            value={new Date(field.value).getTime()}
            name={field.name}
            onChange={(value) => {
              const newValue = new Date(value).getTime();
              setFieldValue(field.name, newValue);

              if (customHandleChange instanceof Function) {
                customHandleChange(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...props}
                size="small"
                InputProps={{
                  size: "small",
                }}
                onBlur={field.onBlur}
                InputLabelProps={{ shrink: true }}
                error={meta && meta.error ? true : false}
                helperText={meta && meta.error && meta.error}
                fullWidth={true}
              />
            )}
            minDate={minDate}
          />
        </LocalizationProvider>
      )}
    </>
  );
}

export default FormDate;
