import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography as TypographyMui,
  styled,
  useTheme,
} from "@mui/material";
import OutlinedContainer from "../../../components/OutlinedContainer/OutlinedContainer";
import { format, parseISO } from "date-fns";
import { Print } from "@mui/icons-material";
import triagePDF from "../../../pdfModels/triagePDF";

const Typography = styled(TypographyMui)({
  fontSize: 16,
});

export default function TriageViewHistory({ patientData, historyData }) {
  const theme = useTheme();

  function handlePrint() {
    const pdf = triagePDF(historyData, patientData);
    pdf.open();
  }

  return (
    <Grid
      container
      spacing={2}
      minHeight={"100%"}
      display={"grid"}
      gridTemplateRows={"1fr 6fr"}
    >
      <Grid item xs={12}>
        <OutlinedContainer label="Atendimento">
          <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
            <Grid item xs={6}>
              <Typography color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  {`Paciente : `}
                </Typography>
                {patientData?.social_prioritize
                  ? patientData?.social_name
                    ? patientData.social_name.toUpperCase()
                    : "Não informado"
                  : patientData?.name
                  ? patientData.name.toUpperCase()
                  : "Não informado"}
              </Typography>
              <Typography color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  {`Data de nascimento : `}
                </Typography>
                {!patientData?.birth_date
                  ? "Não Informado"
                  : format(parseISO(patientData.birth_date), "dd/MM/yyyy")}
              </Typography>
              <Typography color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  {`CNS : `}
                </Typography>
                {!patientData?.sus_card
                  ? "Não Informado"
                  : patientData.sus_card}
              </Typography>
              <Typography color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  {`Nome da mãe : `}
                </Typography>
                {!patientData?.mother_name
                  ? "Não Informado"
                  : patientData.mother_name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                borderLeft: "0.1rem solid #D3E3ED",
                paddingLeft: "15px",
              }}
            >
              <Typography color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  {`Profissional : `}
                </Typography>
                {historyData.employee_specialty.employee.name}
              </Typography>
              <Typography color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  {`Serviço: `}
                </Typography>
                {historyData?.attendance?.service?.describe}
              </Typography>
              <Typography color={theme.palette.primary.main}>
                <Typography
                  variant="h7"
                  color={theme.palette.primary.light}
                  fontWeight="500"
                  sx={{ marginTop: "20px" }}
                >
                  {`Atendimento : `}
                </Typography>
                {historyData.attendance.number}
              </Typography>
            </Grid>
          </Grid>
        </OutlinedContainer>
      </Grid>
      <Grid item xs={12} height={"100%"} position={"relative"}>
        <OutlinedContainer
          height={"100%"}
          InputProps={{
            style: {
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            },
          }}
        >
          <Box
            display={"grid"}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
            rowGap={2}
          >
            {historyData.attendance.service.odontology && (
              <>
                <Typography
                  component="h3"
                  color="secondary"
                  fontWeight="500"
                  fontSize={15}
                >
                  Triagem - Odonto
                </Typography>
                {!!historyData.caries_risks && (
                  <Grid container xs={12} alignItems={"center"} rowGap={2}>
                    <Grid item xs={0.2}>
                      <Divider />
                    </Grid>
                    <Grid item xs={1.2}>
                      <Typography
                        sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                      >
                        Risco de Cárie
                      </Typography>
                    </Grid>
                    <Grid item xs={10.6}>
                      <Divider sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: theme.palette.text.primary,
                        }}
                      >
                        {historyData.caries_risks?.identifier} -{" "}
                        {historyData.caries_risks?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!!historyData.periodontal_risks && (
                  <Grid container xs={12} alignItems={"center"} rowGap={2}>
                    <Grid item xs={0.2}>
                      <Divider />
                    </Grid>
                    <Grid item xs={1.4}>
                      <Typography
                        sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                      >
                        Risco Periodontal
                      </Typography>
                    </Grid>
                    <Grid item xs={10.4}>
                      <Divider sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: theme.palette.text.primary,
                        }}
                      >
                        {historyData.periodontal_risks?.identifier} -{" "}
                        {historyData.periodontal_risks?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!!historyData.tissue_risks && (
                  <Grid container xs={12} alignItems={"center"} rowGap={2}>
                    <Grid item xs={0.2}>
                      <Divider />
                    </Grid>
                    <Grid item xs={1.8}>
                      <Typography
                        sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                      >
                        Risco de Tecidos Moles
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Divider sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: theme.palette.text.primary,
                        }}
                      >
                        {historyData.tissue_risks?.identifier} -{" "}
                        {historyData.tissue_risks?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!!historyData.oclusal_risks && (
                  <Grid container xs={12} alignItems={"center"} rowGap={2}>
                    <Grid item xs={0.2}>
                      <Divider />
                    </Grid>
                    <Grid item xs={1.1}>
                      <Typography
                        sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                      >
                        Risco Oclusal
                      </Typography>
                    </Grid>
                    <Grid item xs={10.7}>
                      <Divider sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: theme.palette.text.primary,
                        }}
                      >
                        {historyData.oclusal_risks?.identifier} -{" "}
                        {historyData.oclusal_risks?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!!historyData.braces_risks && (
                  <Grid container xs={12} alignItems={"center"} rowGap={2}>
                    <Grid item xs={0.2}>
                      <Divider />
                    </Grid>
                    <Grid item xs={1.8}>
                      <Typography
                        sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                      >
                        Necessidade de Prótese
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Divider sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: theme.palette.text.primary,
                          lineHeight: 4,
                        }}
                        component={"ul"}
                      >
                        {historyData.braces_risks.map((braces_risks) => {
                          return (
                            <Typography component={"li"}>
                              {braces_risks?.identifier} -{" "}
                              {braces_risks?.description}
                            </Typography>
                          );
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!!historyData.activity && (
                  <Grid container xs={12} alignItems={"center"} rowGap={2}>
                    <Grid item xs={0.2}>
                      <Divider />
                    </Grid>
                    <Grid item xs={0.8}>
                      <Typography
                        sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                      >
                        Atividade
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Divider sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ color: theme.palette.text.primary }}>
                        {" "}
                        - {historyData.activity}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {/* <Grid container xs={12} alignItems={"center"} rowGap={2}>
              <Grid item xs={0.2}>
                <Divider />
              </Grid>
              <Grid item xs={0.8}>
                <Typography sx={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  Público Alvo
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Divider sx={{ width: "100%" }} />
              </Grid>
              <Grid item xs={12}>
                  {historyData?.target_audience?.map((audience) => {
                    return (
                      <Typography sx={{ color: theme.palette.text.primary }}>
                        {" "}
                        - {audience}
                      </Typography>
                    );
                  })}
                  <Divider />
                </Grid>
            </Grid> */}
                {!!historyData?.procedures && (
                  <Grid container xs={12} alignItems={"center"} rowGap={2}>
                    <Grid item xs={0.2}>
                      <Divider />
                    </Grid>
                    <Grid item xs={1.3}>
                      <Typography
                        sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                      >
                        Procedimentos
                      </Typography>
                    </Grid>
                    <Grid item xs={10.5}>
                      <Divider sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12}>
                      {Array.isArray(historyData.procedures) ? (
                        historyData.procedures.map((procedure) => (
                          <Typography
                            sx={{ color: theme.palette.text.primary }}
                            key={procedure.id}
                          >
                            - {procedure.describe}
                          </Typography>
                        ))
                      ) : (
                        <Typography sx={{ color: theme.palette.text.primary }}>
                          - {historyData.procedures.describe}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
                {!!historyData?.practices && (
                  <Grid container xs={12} alignItems={"center"} rowGap={2}>
                    <Grid item xs={0.2}>
                      <Divider />
                    </Grid>
                    <Grid item xs={1.6}>
                      <Typography
                        sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                      >
                        Práticas em Saúde
                      </Typography>
                    </Grid>
                    <Grid item xs={10.2}>
                      <Divider sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: theme.palette.text.primary,
                          lineHeight: 4,
                        }}
                        component={"ul"}
                      >
                        {historyData.practices.map((practices) => {
                          return (
                            <Typography component={"li"}>
                              {practices}
                            </Typography>
                          );
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {historyData.attendance.service.ceo && (
              <>
                {" "}
                <Typography
                  component="h3"
                  color="secondary"
                  fontWeight="500"
                  fontSize={15}
                >
                  Triagem - CEO
                </Typography>
                <Grid container xs={12} alignItems={"center"} rowGap={2}>
                  <Grid item xs={0.2}>
                    <Divider />
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography
                      sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                    >
                      Orientações
                    </Typography>
                  </Grid>
                  <Grid item xs={10.6}>
                    <Divider sx={{ width: "100%" }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ color: theme.palette.text.primary }}>
                      {" "}
                      - {historyData?.orientation ?? "Não informado"}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </OutlinedContainer>
        {!historyData?.deletedAt ? (
          <IconButton
            sx={{
              position: "fixed",
              right: 30,
              bottom: 25,
            }}
            color="primary"
            onClick={handlePrint}
          >
            <Print fontSize="large" />
          </IconButton>
        ) : null}
      </Grid>
    </Grid>
  );
}
