import React from "react";
import { Box, Typography, CardContent, Card, Grid } from "@mui/material";
import { format } from "date-fns";

function ManufacturerFeedback({ data }) {
  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
        }}
      >
        <Typography
          variant="h1"
          color="gray"
          fontWeight="700"
          fontSize={12}
          sx={{ ml: 2, mb: 1 }}
        >
          #1 DADOS DO FABRICANTE
        </Typography>
        <Typography
          variant="p"
          color="secondary"
          fontWeight="600"
          fontSize={15}
          sx={{ ml: 2 }}
        >
          {data?.describe}
        </Typography>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", mt: "-15px" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                ></Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default ManufacturerFeedback;
