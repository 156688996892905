import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

export default function useThrift() {
  const EsusRecordPath = "/esus/records";
  const tconnectPath = "/external-services/tconnect";

  async function getESUSRecord(params) {
    try {
      const response = await instance.get(`${EsusRecordPath}`, { params });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getThriftAnalysisHistory(params) {
    try {
      const response = await instance.get(`${EsusRecordPath}/histories`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getThriftAnalysis(params) {
    try {
      const response = await instance.get(`${tconnectPath}/esus-analysis`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getThriftAnalysisByHistoryID(id, params) {
    try {
      const response = await instance.get(
        `${EsusRecordPath}/history/${id}/analysis`,
        { params }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getThriftAnalysisFile(id, params) {
    try {
      const response = await instance.get(
        `${EsusRecordPath}/history/${id}/analysis/downloads`,
        {
          responseType: "arraybuffer",
          params: urlQuery(params),
        }
      );
      return response;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function sendUpdateFile(data) {
    try {
      const response = await instance.post(
        `${tconnectPath}/esus-analysis/cnes/update`,
        data,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function sendThrift(params) {
    try {
      const response = await instance.get(`${tconnectPath}/esus-productions`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function getLastCnesUpdateDate(params) {
    try {
      const response = await instance.get(
        `${tconnectPath}/esus-analysis/last-cnes-update`,
        {
          params: urlQuery(params),
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  return {
    getESUSRecord,
    getThriftAnalysis,
    getThriftAnalysisHistory,
    getThriftAnalysisByHistoryID,
    getThriftAnalysisFile,
    sendUpdateFile,
    sendThrift,
    getLastCnesUpdateDate,
  };
}
