import React from "react";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import { DialogMedium } from "../../../helper";
import ViewCounterReference from "./ViewCounterReference";
import { usePatient } from "../../../service";
import { format, parseISO } from "date-fns";
import { useQuery } from "react-query";
import { Box, TablePagination } from "@mui/material";

function ListReference({ patient }) {
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [openReference, setOpenReference] = React.useState(false);
  const [reference, setReference] = React.useState();
  const [references, setReferences] = React.useState();
  const [action, setAction] = React.useState();
  const { getAgainstReferences } = usePatient();
  const idPatient = patient.id;

  const referenceListQuery = useQuery(
    ["againstReferences", [page, limit]],
    () => getAgainstReferences(page, limit, idPatient),
    { keepPreviousData: true }
  );

  const tableColums = [
    { name: "Data de Solicitação" },
    { name: "Profissional Solicitante" },
    { name: "Especialidade Requisitada" },
    { name: "Situação" },
  ];

  React.useEffect(() => {
    if (
      referenceListQuery.isSuccess &&
      referenceListQuery.data &&
      referenceListQuery.data.items
    ) {
      if (count === 0) {
        setCount(referenceListQuery.data.totalItems);
      }
      setReferences(
        referenceListQuery.data.items.map(
          ({
            response,
            justification,
            number,
            requested_specialty,
            requested_employee,
            priority,
            createdAt,
            id,
            soap,
            cids,
            details,
          }) => {
            const dataReference = format(parseISO(createdAt), "dd/MM/yyyy");
            return {
              dataReference,
              createdAt,
              requestor: soap.employee_specialty.employee,
              requestor_name: soap.employee_specialty.employee.name,
              requested_employee,
              requested_specialty: requested_specialty.describe,
              priority,
              justification,
              number,
              response,
              sector: soap?.attendance?.scheduling?.sector,
              attendance_number: soap?.attendance?.scheduling?.number,
              cids: cids,
              id,
              details,
            };
          }
        )
      );

      setRows(
        referenceListQuery.data.items.map(
          ({ createdAt, soap, requested_specialty, priority, id }) => {
            return {
              date: format(parseISO(createdAt), "dd/MM/yyyy"),
              requested_name: soap.employee_specialty.employee.name,
              requested_specialty: requested_specialty.describe,
              priority,
              id,
            };
          }
        )
      );
    }
  }, [referenceListQuery.dataUpdatedAt]);

  const toggleDialog = () => {
    if (openReference) {
      setOpenReference(false);
    } else {
      setOpenReference(true);
    }
  };

  const handleView = (index) => {
    setOpenReference(true);
    setAction("view");
  };

  return (
    <>
      <DialogMedium
        title="Referência / Contrarreferência"
        maxWidth="sm"
        fullWidth={true}
        open={openReference}
        handleClose={toggleDialog}
      >
        <ViewCounterReference
          type={action}
          patient={patient}
          reference={reference}
        />
      </DialogMedium>
      <PaginatedTable
        data={rows}
        columns={tableColums}
        loading={
          referenceListQuery.isFetching && referenceListQuery.isPreviousData
        }
        actions
        shouldDisableFields={["id"]}
        actionsTypes={["view"]}
        actionHandleView={(index) => {
          setReference(references[index]);
          handleView(index);
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={count}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}

export default ListReference;
