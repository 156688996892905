import React from "react";
import { Form, Formik } from "formik";
import Yup from "../../../config/yup";
import { Grid } from "@mui/material";
import { FormTextField, SubmitButton } from "../../../components/Form";
import { useManufacturer } from "../../../service";
import useNotifier from "../../../hooks/useNotifier";

function ManufacturerEdit({ unicManufacturer, handleClose }) {
  const { updateManufacturers } = useManufacturer();
  const notify = useNotifier();
  const initialValues = {
    describe: unicManufacturer.describe,
    id: unicManufacturer.id,
  };
  const validations = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
  });

  async function handleSubmit(values) {
    const manufacturerObject = {
      describe: values.describe,
    };
    try {
      const response = await updateManufacturers(values.id, manufacturerObject);
      if (response) {
        notify("Fabricante atualizado com Sucesso!", "success");
      }
    } catch (err) {
      notify(err.message, "error");
    }
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ ...initialValues }}
        validationSchema={validations}
      >
        {({}) => (
          <Form>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Grid xs={6} item>
                <FormTextField name="describe" label="Descrição" required />
              </Grid>
              <Grid item xs={3}>
                <SubmitButton>Salvar</SubmitButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ManufacturerEdit;
