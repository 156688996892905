import {
  Button,
  InputAdornment,
  TextField as MUITextField,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import {
  formatWithMask,
  multipleArrayMaskFormatter,
} from "../../../config/mask";
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";

export default function TextField({
  control,
  name,
  label,
  placeholder,
  mask,
  leftIcon,
  rightIcon,
  required,
  disabled,
  customOnChange,
  customOnBlur,
  onBlur,
  onChange,
  password,
  triggerMaskOnlyOnBlur = false,
  ...props
}) {
  const [passwordShown, setPasswordShown] = React.useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const icon = passwordShown ? (
    <VisibilityOffRounded sx={{ width: "20px", height: "20px" }} />
  ) : (
    <VisibilityRounded sx={{ width: "20px", height: "20px" }} />
  );
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <MUITextField
          {...field}
          type={!passwordShown && password ? "password" : "text"}
          disabled={disabled}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          label={label}
          fullWidth
          variant="outlined"
          size="small"
          placeholder={placeholder}
          required={required}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: leftIcon ? (
              <InputAdornment position="start">{leftIcon}</InputAdornment>
            ) : null,
            endAdornment: rightIcon ? (
              <InputAdornment position="end">{rightIcon}</InputAdornment>
            ) : password ? (
              <Button onClick={togglePassword}>{icon}</Button>
            ) : null,
          }}
          onChange={(event) => {
            let newValue = event.target.value;

            if (mask && !triggerMaskOnlyOnBlur) {
              newValue = formatWithMask({
                mask,
                text: newValue,
              }).masked;
            }

            if (onChange instanceof Function) {
              onChange(newValue);
            } else {
              field.onChange(newValue);
            }

            if (customOnChange instanceof Function) {
              customOnChange(newValue);
            }
          }}
          onBlur={(event) => {
            let newValue = event.target.value;

            if (triggerMaskOnlyOnBlur && mask) {
              const maskedValue = multipleArrayMaskFormatter({
                mask,
                text: newValue,
              });

              newValue = maskedValue.masked;

              if (!maskedValue.valid && maskedValue.masked) {
                control.setError(name, {
                  type: "mask_validation",
                  message: `O campo ${label} é inválido`,
                });
              } else {
                control.clearErrors(`${name}`);
              }
            }

            if (onBlur instanceof Function) {
              onBlur(newValue);
            } else {
              field.onBlur(newValue);
            }

            if (customOnBlur instanceof Function) {
              customOnBlur(newValue);
            }
          }}
          {...props}
        />
      )}
    />
  );
}
