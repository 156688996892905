import React from "react";
import { BoxContainer } from "../../../components";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import { Dialog, Loading } from "../../../helper";
import { useHealthTips } from "../../../service";
import HealthTipsForm from "./HealthTipsForm";
import HealthTipsFeedback from "../../../helper/HealthTipsFeedback";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Box } from "@mui/system";
import { Button, TablePagination } from "@mui/material";
import usePermissions from "../../../config/usePermissions";
import useNotifier from "../../../hooks/useNotifier";

function HealthTips() {
  const queryClient = useQueryClient();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [registerHealthTips, setRegisterHealthTips] = React.useState(false);
  const [displayHealthTips, setDisplayHealthTips] = React.useState(false);
  const [editHealthTips, setEditHealthTips] = React.useState(false);
  const [healthTips, setHealthTips] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [healthTip, setHealthTip] = React.useState();
  const [count, setCount] = React.useState();
  const notify = useNotifier();
  const { getHealthTips } = React.useMemo(useHealthTips, []);
  const { deleteHealthTips } = React.useMemo(useHealthTips, []);
  const [closeCancellation, setCloseCancellation] = React.useState(false);
  const permission = usePermissions("/program/Healthtips");

  const deleteHealthTipsMutation = useMutation(deleteHealthTips);
  // Request
  const getHealthTipstipsQuery = useQuery(
    ["healthTips", [page, limit]],
    () => getHealthTips({ page, limit }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (response) => {
        setHealthTips(response.items);
        setCount(response.totalItems);
        const formatedHealthTips = response.items.map(
          ({ number, name, id, describe }) => ({
            id: id,
            number,
            name,
            describe,
          })
        );
        setRows(formatedHealthTips);
      },
    }
  );

  const tableColumns = [
    { name: "Código" },
    { name: "Nome" },
    { name: "Descrição" },
  ];

  function handleClose() {
    setRegisterHealthTips(false);
    setDisplayHealthTips(false);
    setEditHealthTips(false);
  }

  function handleDelete(value) {
    deleteHealthTipsMutation.mutate(value, {
      onSuccess: () => {
        setCount(count - 1);
        queryClient.invalidateQueries("healthTips");
        setTimeout(() => {
          handleClose();
        }, 1500);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    });
  }

  if (getHealthTipstipsQuery.isLoading) return <Loading />;

  return (
    <>
      <BoxContainer title="Dicas de saúde">
        <Dialog
          open={registerHealthTips}
          title={"Cadastro das dicas de saúde"}
          handleClose={handleClose}
        >
          <HealthTipsForm type="register" />
        </Dialog>
        <Dialog
          open={displayHealthTips}
          title={"Visualização de dica de saúde"}
          handleClose={handleClose}
        >
          <HealthTipsFeedback data={healthTip} />
        </Dialog>
        <Dialog
          open={editHealthTips}
          title={"Edição da dicas de saúde"}
          handleClose={handleClose}
          cancelClose={closeCancellation}
        >
          <HealthTipsForm
            type="edit"
            data={healthTip}
            preventClose={(value) => setCloseCancellation(value)}
          />
        </Dialog>
        <PaginatedTable
          data={rows}
          columns={tableColumns}
          loading={getHealthTipstipsQuery.isRefetching}
          actions
          shouldDisableFields={["id"]}
          actionsTypes={["view", "edit"]}
          disableActions={(_, action) =>
            action === "edit" && !permission.update
          }
          actionHandleDelete={(i) => {
            setHealthTip(healthTips[i]);
            handleDelete(healthTips[i].id);
          }}
          actionHandleEdit={(i) => {
            setHealthTip(healthTips[i]);
            setEditHealthTips(true);
          }}
          actionHandleView={(i) => {
            setHealthTip(healthTips[i]);
            setDisplayHealthTips(true);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={count || 0}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit || 0}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          disabled={!permission.create}
          onClick={() => setRegisterHealthTips(true)}
        >
          Novo Cadastro
        </Button>
      </BoxContainer>
    </>
  );
}

export default HealthTips;
