import { Form, Formik } from "formik";
import React, { useState } from "react";
import Yup from "../../../config/yup";
import { useMutation, useQueryClient } from "react-query";
import {
  FormDate,
  SubmitButton,
  FormTextField,
  FormFile,
  DateTime,
} from "../../../components/Form/";
import { useHealthTips } from "../../../service";
import { Grid, Typography } from "@mui/material";
import { theme } from "../../../config/theme";
import { formDataObject } from "../../../helper";
import { dateTimeFormaterEdit } from "../../../utils";
import PaginatedTable from "../../../components/Table/PaginatedTable";
import ptBR from "date-fns/esm/locale/pt-BR";
import { format, parseISO } from "date-fns";
import useNotifier from "../../../hooks/useNotifier";
function convertToMB(size) {
  return (size / 1024 ** 2).toFixed(2);
}
function HealthTipsForm({ rows, data, preventClose, type = "edit" }) {
  const notify = useNotifier();
  const originalData = structuredClone(data);
  const [count, setCount] = React.useState(0);
  const [result, setResult] = useState([]);
  const [actions, setActions] = React.useState([]);
  const [canSave, setCanSave] = React.useState(false);
  const date = new Date();
  const { updateHealthTips, postHealthTips, deleteHealthTips } = React.useMemo(
    useHealthTips,
    []
  );
  const postHealthTipsMutation = useMutation(postHealthTips);
  const editHealthTipsMutation = useMutation(updateHealthTips);
  const deleteHealthTipsMutation = useMutation(deleteHealthTips);
  const [tableRows, setTableRows] = React.useState([]);
  const queryClient = useQueryClient();
  const [filesToDelete, setFilesToDelete] = React.useState([]);
  const [filesToSend, setFilesToSend] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    name: "",
    describe: "",
    link: "",
    attachments: null,
    activation_date: null,
    termination_date: null,
    observation: "",
    sending_frequency: "",
    destination: "",
  });
  const collumns = [
    { id: "name", name: "Nome" },
    { id: "type", name: "Tipo" },
    { id: "size", name: "Tamanho" },
  ];
  React.useEffect(() => {
    return () => {
      setFilesToDelete([]);
      setFilesToSend([]);
      setTableRows([]);
    };
  }, []);

  React.useEffect(() => {
    if (preventClose) {
      if (
        deleteHealthTipsMutation.isLoading ||
        editHealthTipsMutation.isLoading ||
        postHealthTipsMutation.isLoading
      ) {
        preventClose(true);
      } else {
        preventClose(false);
      }
    }
  }, [deleteHealthTipsMutation.isLoading, editHealthTipsMutation.isLoading]);

  const validation = Yup.object().shape({
    link: Yup.string().when("attachments", {
      is: null,
      then: Yup.string().required("É requerido").nullable(),
      otherwise: Yup.string().nullable(),
    }),
    name: Yup.string().required("É requerido"),
    describe: Yup.string().required("É requerido"),
    sending_frequency: Yup.string().validateTimeSchema(),
    observation: Yup.string().nullable(),
    activation_date: Yup.date()
      .typeError("Insira a data de ativação")
      .default(date)
      .required("Insira uma data de ativação"),
    termination_date: Yup.date()
      .typeError("Insira a data de desativação")
      .when("activation_date", (activation_date, schema) => {
        return schema.test({
          test: (termination_date) =>
            !!activation_date && termination_date >= activation_date,
          message: "Escolha a data de inativação posterior a data de ativação",
        });
      })
      .required("Insira uma data final para agenda posterior a data inicial"),
  });

  React.useEffect(() => {
    if (type === "edit") {
      const editInitialValues = {
        id: data?.id,
        name: data?.name,
        describe: data?.describe,
        number: data?.number,
        link: data?.link,
        attachments: null,
        activation_date: parseISO(data?.activation_date),
        termination_date: parseISO(data?.termination_date),
        observation: data?.observation,
        sending_frequency: dateTimeFormaterEdit(data?.sending_frequency),
      };
      setInitialValues(editInitialValues);
      setActions(["delete", "edit"]);
      addRows(data.attachments);
    } else {
      setActions(["delete"]);
      setCanSave(true);
    }
  }, [type, data]);
  function addRows(files, setFieldValue) {
    if (tableRows.length < 6) {
      const largeFiles = [];
      files = Array.from(files);
      files = files.filter((file) => {
        if (convertToMB(file.size) > 15) {
          largeFiles.push(file.name);
          return;
        }
        return file;
      });

      if (largeFiles.length) {
        const filesToList = largeFiles.join(", ");
        notify(
          `Não foi possível adicionar algums arquivos: ${filesToList}. Limite Ultrapassado.`,
          "error"
        );
      }

      const formatedFilesData = files.map((file) => {
        return {
          name: file.name,
          type: file.name.split(".")[file.name.split(".").length - 1],
          size: convertToMB(file.size) + " MB / 15 MB",
        };
      });

      setTableRows((prevRows) => [
        ...prevRows,
        ...formatedFilesData.filter((file, i) => {
          if (!prevRows.some((prevfile) => prevfile.name === file.name)) {
            setFilesToSend((prevFiles) => [...prevFiles, files[i]]);
            return file;
          }
        }),
      ]);
    }
  }

  function handleDelete(name) {
    setTableRows((prevFiles) =>
      prevFiles.filter((file) => {
        if (file.name !== name) {
          return file;
        }
        if (
          originalData?.attachments.some(
            (originalFile) => originalFile.name === name
          )
        ) {
          setFilesToDelete((prevFiles) => {
            const files = [...prevFiles, file];
            return [...new Set([...files].map((value) => value.name || value))];
          });
        }
      })
    );
    setFilesToSend((prevFiles) =>
      prevFiles.filter((file) => file.name !== name)
    );
  }
  async function handleSubmit(values, methods) {
    const id = values.id;
    if (type === "edit") {
      const editInitialValues = {
        name: values?.name || "",
        describe: values?.describe || "",
        link: values?.link || "",
        attachments: [...filesToSend],
        activation_date:
          format(values?.activation_date, "yyyy-MM-dd", {
            locale: ptBR,
          }) || "",
        termination_date:
          format(values?.termination_date, "yyyy-MM-dd", {
            locale: ptBR,
          }) || "",
        observation: values?.observation || "",
        sending_frequency: values?.sending_frequency || null,
      };
      editHealthTipsMutation.mutate([id, formDataObject(editInitialValues)], {
        onSuccess: () => {
          notify(`Dados da dica de saúde Atualizados com Sucesso!`, "success");
          queryClient.invalidateQueries("healthTips");
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      });
      if (filesToDelete.length) {
        deleteHealthTipsMutation.mutate([id, filesToDelete], {
          onSuccess: () => {
            notify(`Arquivos deletados com Sucesso!`, "success");
            queryClient.invalidateQueries("healthTips");
            setFilesToDelete([]);
          },
          onError: (error) => {
            const errorPayload = JSON.parse(error.message);
            addRows(
              originalData.attachments.filter((file) => {
                if (errorPayload.remainingFiles.includes(file.name)) {
                  return file;
                }
              })
            );
            setFilesToDelete([]);
            notify(error.message, "error");
          },
        });
      }
    } else {
      const RegisterValues = {
        name: values?.name,
        describe: values?.describe,
        link: values?.link,
        attachments: [...filesToSend],
        activation_date: values?.activation_date,
        termination_date: values?.termination_date,
        observation: values?.observation,
        sending_frequency: values?.sending_frequency,
      };
      postHealthTipsMutation.mutate(formDataObject(RegisterValues), {
        onSuccess: ({ data }) => {
          setResult(data);
          setCount(count + 1);
          notify(`Dados da dica de saúde criados com Sucesso!`, "success");
          queryClient.invalidateQueries("healthTips");
          methods.resetForm();
          setTableRows([]);
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      });
    }
  }
  return (
    <>
      <Grid xs={12}>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...initialValues }}
          validationSchema={validation}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid
                container
                spacing={2}
                xs={12}
                sx={{
                  display: "flex",
                  margin: "-20px 0px 12px 0px",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={6} alignItems="stretch" sx={{ pr: 1 }}>
                  <FormTextField
                    name="name"
                    label="Nome da Dica de saúde"
                    required
                    disabled={
                      deleteHealthTipsMutation.isLoading ||
                      editHealthTipsMutation.isLoading ||
                      postHealthTipsMutation.isLoading
                    }
                  />
                </Grid>
                <Grid item xs={6} alignItems="stretch">
                  <FormTextField
                    name="describe"
                    label="Descrição da Dica de saúde"
                    required
                    disabled={
                      deleteHealthTipsMutation.isLoading ||
                      editHealthTipsMutation.isLoading ||
                      postHealthTipsMutation.isLoading
                    }
                  />
                </Grid>
                <Grid item xs={7} alignItems="stretch">
                  <FormTextField
                    required
                    name="link"
                    label="link"
                    disabled={
                      deleteHealthTipsMutation.isLoading ||
                      editHealthTipsMutation.isLoading ||
                      postHealthTipsMutation.isLoading
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormFile
                    name="attachments"
                    customHandleChange={(currentTarget) => {
                      const totalFileSize = Array.from(
                        currentTarget.files
                      ).length;
                      if (
                        Array.from(currentTarget.files).length > 6 ||
                        tableRows.length + totalFileSize.length > 6 ||
                        tableRows.length === 6
                      ) {
                        notify("No máximo 6 arquivos permitidos.", "error");
                        setFieldValue("attachments", null);
                        currentTarget.value = null;
                        return;
                      }
                      addRows(currentTarget.files);
                      setFieldValue("attachments", null);
                      currentTarget.value = null;
                    }}
                    typesAccepted={["image/jpeg", "image/png", ".pdf"]}
                    multiple
                    disabled={
                      deleteHealthTipsMutation.isLoading ||
                      editHealthTipsMutation.isLoading ||
                      postHealthTipsMutation.isLoading
                    }
                  />
                </Grid>
                <Grid item xs={2} alignItems="stretch">
                  <FormDate
                    name="activation_date"
                    label="Data de ativação"
                    required
                    disabled={
                      deleteHealthTipsMutation.isLoading ||
                      editHealthTipsMutation.isLoading ||
                      postHealthTipsMutation.isLoading
                    }
                  />
                </Grid>
                <Grid item xs={2} alignItems="stretch">
                  <FormDate
                    name="termination_date"
                    label="Data de inativação"
                    minDate={new Date(values.activation_date)}
                    required
                    disabled={
                      deleteHealthTipsMutation.isLoading ||
                      editHealthTipsMutation.isLoading ||
                      postHealthTipsMutation.isLoading
                    }
                  />
                </Grid>
                <Grid item xs={9} alignItems="stretch">
                  <FormTextField
                    name="observation"
                    label="Obs"
                    disabled={
                      deleteHealthTipsMutation.isLoading ||
                      editHealthTipsMutation.isLoading ||
                      postHealthTipsMutation.isLoading
                    }
                  />
                </Grid>
                <Grid item xs={3} alignItems="stretch">
                  <DateTime
                    name="sending_frequency"
                    label="Frequência de envio"
                    required
                    disabled={
                      deleteHealthTipsMutation.isLoading ||
                      editHealthTipsMutation.isLoading ||
                      postHealthTipsMutation.isLoading
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  alignItems="stretch"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <PaginatedTable
                    sx={{ marginTop: 3 }}
                    columns={collumns}
                    data={tableRows}
                    actions
                    height={"300px"}
                    actionUniqueIdentifier={"name"}
                    actionsTypes={["delete"]}
                    shouldDisableFields={["id"]}
                    loading={
                      deleteHealthTipsMutation.isLoading ||
                      editHealthTipsMutation.isLoading ||
                      postHealthTipsMutation.isLoading
                    }
                    actionHandleDelete={handleDelete}
                  />
                  <Typography
                    color={tableRows.length < 6 ? "primary" : "error"}
                  >
                    {tableRows.length} / 6 Arquivos Selecionados.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: (theme) => theme.palette.secondary.main,
                    }}
                  >
                    * Os arquivos serão enviados separadamente da dica de saude.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: (theme) => theme.palette.secondary.main,
                    }}
                  >
                    * No máximo 6 arquivos.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: (theme) => theme.palette.secondary.main,
                    }}
                  >
                    * Apenas jpeg/jpg/pdfs/pngs são permitidos.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  alignItems="stretch"
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "flex-end",
                  }}
                >
                  <SubmitButton
                    loading={
                      postHealthTipsMutation.isLoading ||
                      editHealthTipsMutation.isLoading ||
                      deleteHealthTipsMutation.isLoading
                    }
                    loadingMessage={"Salvando..."}
                    // handleClick={() => handleSubmit(values)}
                  >
                    <Typography
                      fontSize="13px"
                      fontWeight={400}
                      color={theme.palette.secondary.light}
                    >
                      Salvar
                    </Typography>
                  </SubmitButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
}
export default HealthTipsForm;
