import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/campaigns";

export default function useCampaign() {
  async function getCampaigns(params = { page: 0, limit: 5 }) {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  async function postCampaigns(data) {
    try {
      const response = await instance.post(path, data);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }

  async function updateCampaigns(campaignsObject) {
    try {
      const response = await instance.put(
        `${path}/update/${campaignsObject.id}`,
        campaignsObject
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }
  return {
    getCampaigns,
    postCampaigns,
    updateCampaigns,
  };
}
