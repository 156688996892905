import Yup from "../../../../config/yup";

export const cidValidation = Yup.object().shape({
  cid: Yup.object().required("Informe o cid a ser adicionado.").nullable(),
});

export const procedureValidation = Yup.object().shape({
  procedure: Yup.object()
    .required("Informe o procedimento a ser adicionado.")
    .nullable(),
  specialty: Yup.object()
    .required("Informe a especialidade a ser adicionado.")
    .nullable(),
  qtd: Yup.number().min(1, "O valor mínimo a ser informado é 1."),
});

export const basicAttentionValidation = Yup.object().shape({
  mab: Yup.object().required("Código deve ser informado.").nullable(),
  complement: Yup.lazy((value)=> {
    switch (typeof value) { 
      case 'object':
        return Yup.object().when("mab", {
          is: (mab) => mab?.id === "P24",
          then: Yup.object().required('A escolha do cid é obrigatória').nullable(),
        }).nullable(); 
      case 'string':
        return Yup.string().when("mab", {
          is: (mab) => mab?.needs_complement,
          then: Yup.string().required("Este campo deve ser informado."),
        })
        .when("mab", {
          is: (mab) => mab?.id === "AA1",
          then: Yup.string().test({
            test: (value) => {
              const regex = /^[0-9]{1,3}([,]?(?<=[,\.])[0-9]{1,3})?$/;
              if (regex.test(value)) {
                const valueToNumber = parseFloat(value.replace(",", "."));
                if (valueToNumber <= 500 && valueToNumber >= 0.5) {
                  return true
                }
              }
              return false
            },
            message: "Valor mínimo de 0,5g e valor máximo de 500Kg"
          }),
        }).when("mab", {
          is: (mab) => mab?.id === "AA2",
          then: Yup.string().test({
            test: (value) => {
              const regex = /^[0-9]{1}([,]?[0-9]{0,2})?$/;
              if (regex.test(value)) {
                const valueToNumber = parseFloat(value.replace(",", ".")).toFixed(2);
                if (valueToNumber <= 2.5 && valueToNumber >= 0.2) {
                  return true
                }
                return false
              }
              return false
            },
            message: "Valor mínimo de 0,2m e valor máximo de 2,5m com até duas casas decimais."
          }),
        }).when("mab", {
          is: (mab) => mab?.id === "AA3",
          then: Yup.string().test({
            test: (value) => {
              const regex = /^[0-9]{1,3}([,]?[0-9]{0,2})?$/;
              if (regex.test(value)) {
                const valueToNumber = parseFloat(value.replace(",", ".")).toFixed(2);
                if (valueToNumber <= 200 && valueToNumber >= 10) {
                  return true
                }
                return false
              }
              return false
            },
            message: "Valor mínimo de 10cm e valor máximo de 200cm com até duas casas decimais."
          }),
        }).nullable(); 
    }
  }),
});

export const mainFormValidations = Yup.object().shape({
  date: Yup.date().required("É requerido.").nullable(),
  hour: Yup.date().required("É requerido.").nullable(),
  employee: Yup.object().required("É requerido.").nullable(),
  patient: Yup.object().required("É requerido.").nullable(),
  attendance_group: Yup.object().required("É requerido.").nullable(),
  attendance_type: Yup.object().required("É requerido.").nullable(),
  specialty: Yup.object().nullable().required("É requerido."),
});
