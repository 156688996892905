import { useTheme } from "@emotion/react";
import { FormControlLabel, Switch } from "@mui/material";
import { Field, useField, useFormikContext } from "formik";
import React from "react";

function FormSwitch({ name, label, customHandleChange, ...props }) {
  const theme = useTheme();
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const settings = {
    ...field,
    ...props,
    name,
  };

  if (meta && meta.touched && meta.error) {
    settings.error = true;
    settings.helperText = meta.error;
  }

  return (
    <FormControlLabel
      label={label}
      control={
        <Field
          {...settings}
          checked={field.value}
          onChange={(_, value) => {
            setFieldValue(name, value);

            if (customHandleChange instanceof Function)
              customHandleChange(value);
          }}
          type="checkbox"
          component={Switch}
        />
      }
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: "0.9rem",
          color: theme.palette.primary.light,
        },
      }}
    />
  );
}

export default FormSwitch;
