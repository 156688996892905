import React, { useContext, useState } from "react";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { FormPassword, SubmitButton } from "../../components";
import Yup from "../../config/yup";
import { DialogMedium } from "..";
import { useAuth } from "../../service";
import { AuthContext } from "../../contexts/AuthContext";
import { useNotifier } from "../../hooks";

const ChangePassowrd = ({ open, handleClose }) => {
  const { changePassword } = useAuth();
  const changePasswordMutation = useMutation(changePassword);
  const notify = useNotifier();
  const [loading, setLoading] = useState(false);
  const { firstAccess, setFirstAccess } = useContext(AuthContext);

  function handleSubmit({ password, confirm_password, passLogin }) {
    if (password !== confirm_password) {
      notify("As senhas não coincidem", "error");
    } else {
      setLoading(true);
      changePasswordMutation.mutate(
        { pass_login: password, old_pass: passLogin },
        {
          onSuccess(response) {
            setLoading(false);
            setFirstAccess(false);
            notify(response.message, "success");
            handleClose();
          },
          onError(error) {
            setLoading(false);
            notify(error.message, "error");
          },
        }
      );
    }
  }

  const initialValues = {
    password: "",
    confirm_password: "",
    passLogin: "",
  };

  const validations = Yup.object().shape({
    password: Yup.string().required("É requerido"),
    confirm_password: Yup.string().required("Confirme sua senha"),
  });

  return (
    <>
      <DialogMedium
        title="Alteração de senha"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        handleClose={!firstAccess ? handleClose : () => {}}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validations}
        >
          {() => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={2}>
                  <FormPassword
                    label="Senha atual"
                    name="passLogin"
                    sx={{ background: "white" }}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <FormPassword
                    label="Senha"
                    name="password"
                    sx={{ background: "white" }}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <FormPassword
                    label="Confirmar senha"
                    name="confirm_password"
                    placeholder="Confirme sua senha"
                    sx={{ background: "white" }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SubmitButton loading={loading}>Confirmar</SubmitButton>
                </Grid>
                {!firstAccess && (
                  <Grid item xs={4}>
                    <SubmitButton onClick={handleClose} color="error">
                      Cancelar
                    </SubmitButton>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogMedium>
    </>
  );
};

export default ChangePassowrd;
