import React, { useEffect } from "react";
import { TITLE_NAME } from "../../config/config";
import { Helmet } from "react-helmet";
import { Box, Button, TablePagination } from "@mui/material";
import { Dialog, DialogMedium, Loading } from "../../helper";
import UnitHealthForm from "../Vaccine/Form/UnitHealthForm";
import useHealthUnit from "../../service/useHealthUnit";
import UnitHealthFeedback from "./Feedback/UnitHealthFeedback";
import UnitHealthEdit from "./Edit/UnitHealthEdit";
import { useQuery, useQueryClient } from "react-query";
import PaginatedTable from "../../components/Table/PaginatedTable";
import { BoxContainer } from "../../components";
import usePermissions from "../../config/usePermissions";

function VaccineUnitHealth() {
  const { getHealthUnits } = useHealthUnit();
  const queryClient = useQueryClient();
  const [healthUnit, setHealtUnit] = React.useState([]);
  const [visualizationUnitHealth, setVisualizationUnitHealth] =
    React.useState(false);
  const [editUnitHealtDialog, setEditUnitHealtDialog] = React.useState(false);
  const [newHealtUnit, setNewHealthUnit] = React.useState(false);
  const [unicUnitHealth, setUnicUnitHealth] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const permission = usePermissions("/vaccine/unitHealth");

  const healthUnitQuery = useQuery(
    ["healthUnit", [page, limit]],
    () => getHealthUnits({ page, limit }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  React.useEffect(() => {
    if (
      healthUnitQuery.isSuccess &&
      healthUnitQuery.data &&
      healthUnitQuery.data.items
    ) {
      if (count === 0) {
        setCount(healthUnitQuery.data.totalItems);
      }
      setHealtUnit(
        healthUnitQuery.data.items.map((index) => {
          return index;
        })
      );
      setRows(
        healthUnitQuery.data.items.map((index) => {
          return {
            code: index.code,
            describe: index.describe,
            cnes: index.cnes,
            id: index.id,
          };
        })
      );
    }
  }, [healthUnitQuery.dataUpdatedAt]);

  const columns = [{ name: "Código" }, { name: "Unidade" }, { name: "CNES" }];

  function handleVisualization() {
    setVisualizationUnitHealth(true);
  }

  function handleEditUnitHealth() {
    setEditUnitHealtDialog(true);
  }
  function handleNewUnitHealt() {
    setNewHealthUnit(true);
  }
  function handleCloseDialog() {
    setEditUnitHealtDialog(false);
    setNewHealthUnit(false);
    setVisualizationUnitHealth(false);
    queryClient.invalidateQueries("healthUnit");
  }
  if (healthUnitQuery.isLoading) return <Loading />;
  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Vacina</title>
      </Helmet>

      <DialogMedium
        open={editUnitHealtDialog}
        title={"Editar Unidade"}
        handleClose={handleCloseDialog}
      >
        <UnitHealthEdit
          unicUnitHealth={unicUnitHealth}
          handleCloseDialog={handleCloseDialog}
        />
      </DialogMedium>
      <DialogMedium
        open={newHealtUnit}
        title={"Cadastrar Unidade"}
        handleClose={handleCloseDialog}
      >
        <UnitHealthForm
          healthUnit={healthUnit}
          handleClose={handleCloseDialog}
        />
      </DialogMedium>
      <DialogMedium
        maxWidth={2}
        open={visualizationUnitHealth}
        title={"Visualização de Lotes"}
        handleClose={handleCloseDialog}
      >
        <UnitHealthFeedback data={unicUnitHealth} />
      </DialogMedium>

      <BoxContainer title="Unidade de Saúde">
        <PaginatedTable
          data={rows}
          columns={columns}
          actions
          actionsTypes={["view", "edit"]}
          disableActions={(_, action) =>
            action === "edit" && !permission.update
          }
          actionHandleEdit={(i) => {
            setUnicUnitHealth(healthUnit[i]);
            handleEditUnitHealth(i);
          }}
          shouldDisableFields={["id"]}
          actionHandleView={(i) => {
            setUnicUnitHealth(healthUnit[i]);
            handleVisualization(i);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={count}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            color="secondary"
            disabled={!permission.create}
            onClick={() => {
              handleNewUnitHealt(newHealtUnit);
            }}
          >
            Novo Cadastro
          </Button>
        </Box>
      </BoxContainer>
    </>
  );
}

export default VaccineUnitHealth;
