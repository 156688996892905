import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FormTextField } from "../../components";
import FormMultipleSelectField from "../../components/Form/FormMultipleSelectField";
import {
  FormRadio,
  FormCheckbox,
  FormDate,
  FormSelectField,
  FormSwitch,
} from "../../components/Form";
import { Divider } from "@mui/material";
import { AppContext } from "../../contexts/AppContext";
import { useTheme } from "@emotion/react";
import calcIMC from "../../utils/calcIMC";
import { useFormikContext } from "formik";
import { InputAdornment } from "@mui/material";
import { calcByDUM, calcByUSG } from "../../utils/pregnantCalculations";
import { Dialog } from "../../helper";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { ReactComponent as Brain } from "../../assets/svg/brain.svg";
import EditorForms from "../Attendance/EditorForms/EditorForms";
import Exams from "../Exams";
import convertDateTobirthday from "../../utils/convertDateToBirthday";
import { AuthContext } from "../../contexts/AuthContext";
import { ampiOptions } from "../../config/standardSelects";
import { useQueryClient } from "react-query";
import Yup from "../../config/yup";
import { parseISO } from "date-fns";
import {
  DIASTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID,
  MEDICAL_EMPLOYEE_TYPE_ID,
  NURSE_EMPLOYEE_TYPE_ID,
  SYSTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID,
} from "../../config/constants";

const gpncaOptionsG = Array.from({ length: 15 }).reduce(
  (previous, current, index) => {
    const key = ++index;
    previous[key] = key;
    return previous;
  },
  {}
);

const gpncaOptions = { 0: 0, ...gpncaOptionsG };

const gpncaFields = [
  {
    title: "Gestações",
    name: "gravidity",
    label: "G",
    options: gpncaOptionsG,
  },
  {
    title: "Partos",
    name: "parity",
    label: "P",
    options: gpncaOptions,
  },
  {
    title: "Partos normais",
    name: "vaginal_deliveries_number",
    label: "N",
    options: gpncaOptions,
  },
  {
    title: "Partos Cesáreos",
    name: "cesarean_deliveries_number",
    label: "C",
    options: gpncaOptions,
  },
  {
    title: "Abortos",
    name: "abortions_number",
    label: "A",
    options: gpncaOptions,
  },
];

const rcvRisks = ["ALTO", "MODERADO", "BAIXO", "SEM RISCO"];

function Objective({
  company,
  attendance,
  preIllness,
  admeasurement,
  validations,
  setValidations,
  setAdmeasurementFilters,
  previousPregnancies,
  setPreviousPregnancies,
  gestationalResult,
  setGestationalResult,
  handleBloodPressureValidation,
  recordAMPIQuery,
  setRiskStratificationValidations,
  odonto,
  ceo,
}) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { disableFields, patientData, disabledFieldNames } =
    useContext(AppContext);
  const { userData } = useContext(AuthContext);
  const { values, setFieldValue } = useFormikContext();
  const [examsModal, setExamsModal] = useState(false);
  const [editorFormType, setEditorFormType] = useState(false);
  const convertedBirthDate = convertDateTobirthday(
    patientData.birth_date,
    ["years", "months"],
    false
  );
  const pregnantFieldIsDisabled = disabledFieldNames.includes("pregnant");

  let imcParams = { PESO: false, ALTURA: false };
  const admeasurementOptions = [];
  const complementaryAdmeasurements = [];

  admeasurement?.forEach((data) => {
    const description = data.describe.toUpperCase();
    const childAdmeasurements = ["PESO", "ALTURA"];
    const pregnantAdmeasurements = [
      "ALTURA UTERINA",
      "BATIMENTO CARDÍACO FETAL",
    ];

    if (childAdmeasurements.includes(description)) {
      imcParams[description] = data.id;
    }

    let required = validations?.admeasurement_objective?.fields?.[
      data.id
    ]?.tests?.some((test) => test.OPTIONS.name === "admeasurement-requirement");

    const component = (
      <Grid key={data.id} item xs={12 / Math.floor(data.length)}>
        <FormTextField
          name={`admeasurement_objective.${data.id}`}
          reference="admeasurementOption"
          label={data.describe.firstCharToUp() + ":"}
          required={required}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{data.unit}</InputAdornment>
            ),
          }}
          disabled={disableFields}
        />
      </Grid>
    );

    if (pregnantAdmeasurements.includes(description)) {
      complementaryAdmeasurements.push(component);
    } else {
      admeasurementOptions.push(component);
    }
  });

  useEffect(() => {
    if (patientData.pregnant) {
      handlePregnantValidations();
    }
  }, [patientData]);

  useEffect(() => {
    if (attendance) {
      const timeout = setTimeout(() => {
        let DUM = null;
        let USG = null;

        if (values.last_menstrual_period) {
          DUM = calcByDUM(attendance.createdAt, values.last_menstrual_period);
        }

        if (
          values.obstetric_usg_date &&
          values.obstetric_usg_weeks &&
          values.obstetric_usg_days
        ) {
          USG = calcByUSG(
            attendance.createdAt,
            values.obstetric_usg_date,
            values.obstetric_usg_weeks,
            values.obstetric_usg_days
          );
        }
        setGestationalResult({ DUM, USG });
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [
    attendance,
    values.last_menstrual_period,
    values.obstetric_usg_date,
    values.obstetric_usg_weeks,
    values.obstetric_usg_days,
  ]);

  const radios = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];
  const radiosAvailable = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
    { label: "Não Avaliado", value: "" },
  ];

  const radios2 = [
    { label: "Nega", value: "Nega" },
    { label: "Desconhece", value: "Desconhece" },
    { label: "Inclusão", value: "Inclusao" },
  ];

  const fetalPresentationOptions = {
    CEFÁLICO: "CEFÁLICO",
    PÉLVICO: "PÉLVICO",
    CÓRMICO: "CÓRMICO",
    "NÃO SE APLICA": "NÃO SE APLICA",
  };

  const breastfeedingOptions = {
    Exclusivo: "Exclusivo",
    Predominante: "Predominante",
    Complementado: "Complementado",
    Inexistente: "Inexistente",
  };

  const gestationalRiskOptions = {
    HABITUAL: "HABITUAL",
    ALTO: "ALTO",
  };

  const doneOptions = {
    NÃO: "NÃO",
    SIM: "SIM",
    "NÃO REALIZADO": "NÃO REALIZADO",
  };

  const edemaOptions = {
    "Sem edema": "Sem edema",
    "+": "+",
    "++": "++",
    "+++": "+++",
    "++++": "++++",
  };

  const childOptions = {
    Presente: "Presente",
    Ausente: "Ausente",
  };

  const DNPMOptions = {
    Adequado: "Adequado",
    Inadequado: "Inadequado",
  };

  const disabledSubstances =
    (values.radioStatusAlergia && values.radioStatusAlergia === "Nega") ||
    disableFields;

  const formTitles = {
    pregnant: "Lista de Encerramentos Gestacionais",
    child: "Lista de Primeira Consulta Pediátrica",
    caps: "Acolhimento Inicial",
    anamnese: "Anamnese",
    terms: "Termos",
  };

  const attendanceIsAMPI = convertedBirthDate?.years >= 60;

  const docsAMPINotRequireHigherEducation = [
    "64de5580476c19c173ad2a07",
    "63b6ce9c8ebc850fad923f87",
  ];

  const disableDocumentActions = (action, document) => {
    const isNotHigherEducation =
      !docsAMPINotRequireHigherEducation.includes(document?._id) &&
      !userData?.employee_type?.higher_education;

    const actionIsNotView = action !== "view";

    if (editorFormType === "ampi" && isNotHigherEducation && actionIsNotView) {
      return true;
    }
    return false;
  };

  const disableDocumentResponse = (document) => {
    const isNotHigherEducation =
      !docsAMPINotRequireHigherEducation.includes(document?._id) &&
      !userData?.employee_type?.higher_education;

    if (editorFormType === "ampi" && isNotHigherEducation) {
      return true;
    }
    return false;
  };

  function handleCloseEditorFormDialog() {
    setEditorFormType(false);
  }

  function handlePregnantValidations() {
    setValidations((validations) => ({
      ...validations,
      last_menstrual_period: Yup.date()
        .nullable()
        .max(
          parseISO(attendance.createdAt),
          "A data não pode ser posterior a data do atendimento"
        ),
      obstetric_usg_date: Yup.date()
        .nullable()
        .max(
          parseISO(attendance.createdAt),
          "A data não pode ser posterior a data do atendimento"
        )
        .when("last_menstrual_period", (last_menstrual_period, schema) => {
          if (last_menstrual_period) {
            return schema.min(
              last_menstrual_period,
              "A data não pode ser menor que a data última menstruação"
            );
          }

          return schema;
        }),
    }));
  }

  function handlePregnant(value) {
    const prenatalId = "daf3636d-7990-4090-8649-0aba6ca10bc9";
    const preIllnessOptions = values["doencasPreexistentes"];
    const hasPrenatal = preIllnessOptions?.some(({ id }) => id === prenatalId);

    if (!value) {
      setFieldValue("last_menstrual_period", null);
      setFieldValue("obstetric_usg_date", null);
      setFieldValue("obstetric_usg_weeks", null);
      setFieldValue("obstetric_usg_days", null);

      if (hasPrenatal) {
        const newOptions = preIllnessOptions.filter(
          ({ id }) => id !== prenatalId
        );

        setFieldValue("doencasPreexistentes", newOptions);
      }
    } else if (!hasPrenatal && preIllness) {
      const prenatal = preIllness.find(({ id }) => id === prenatalId);

      setFieldValue("doencasPreexistentes", [...preIllnessOptions, prenatal]);
    }

    setAdmeasurementFilters((filters) => ({
      ...filters,
      pregnant: value,
    }));

    handlePregnantValidations();
  }

  function toggleRCV(enabled) {
    setRiskStratificationValidations();
    setFieldValue("risk_stratification", enabled ? {} : null);
    setAdmeasurementFilters((filters) => ({
      ...filters,
      risk_stratification: enabled,
    }));
  }

  const systolic =
    values.admeasurement_objective[SYSTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID];
  const diastolic =
    values.admeasurement_objective[DIASTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID];

  const rcvStatus = useMemo(
    function () {
      if (!values.risk_stratification_status) return;
      let RCVresult = 3;
      let goalResult = "FORA DA META TERAPÊUTICA";
      const risk_stratification = values.risk_stratification;
      const hightRisk = [
        risk_stratification.diabetes,
        risk_stratification.left_ventricular_hypertrophy,
        risk_stratification.albuminuria,
        risk_stratification.glomerular_filtration_rate,
        risk_stratification.ischemic_stroke,
        risk_stratification.cerebral_hemorrhage,
        risk_stratification.transient_ischemic_attack,
        risk_stratification.stable_or_unstable_angina,
        risk_stratification.myocardial_infarction,
        risk_stratification.myocardial_revascularization,
        risk_stratification["heart_failure_or_reduced"],
        risk_stratification["artery_disease_lower_limbs"],
        risk_stratification["stage_four_chronic_kidney_disease"],
        risk_stratification.advanced_retinopathy,
      ];
      const hasHightRiskByHaveIt = hightRisk.some((value) => value);

      if (hasHightRiskByHaveIt) {
        RCVresult = 0;
      } else {
        const hasRiskFactor = [
          risk_stratification.cvd_family_history,
          risk_stratification.smoking,
          risk_stratification.dyslipidemia,
          risk_stratification.obesity,
        ].filter((v) => v);
        if (
          systolic >= 180 ||
          diastolic >= 110 ||
          (((systolic >= 160 && systolic <= 179) ||
            (diastolic >= 100 && diastolic <= 109)) &&
            !!hasRiskFactor.length) ||
          (((systolic >= 140 && systolic <= 159) ||
            (diastolic >= 90 && diastolic <= 99)) &&
            hasRiskFactor.length >= 3)
        ) {
          RCVresult = 0;
        } else if (
          (((systolic >= 160 && systolic <= 179) ||
            (diastolic >= 100 && diastolic <= 109)) &&
            !hasRiskFactor.length) ||
          (((systolic >= 140 && systolic <= 159) ||
            (diastolic >= 90 && diastolic <= 99)) &&
            !!hasRiskFactor.length &&
            hasRiskFactor.length <= 2) ||
          (((systolic >= 130 && systolic <= 139) ||
            (diastolic >= 85 && diastolic <= 89)) &&
            hasRiskFactor.length >= 3)
        ) {
          RCVresult = 1;
        } else if (
          (((systolic >= 140 && systolic <= 159) ||
            (diastolic >= 90 && diastolic <= 99)) &&
            !hasRiskFactor.length) ||
          (((systolic >= 130 && systolic <= 139) ||
            (diastolic >= 85 && diastolic <= 89)) &&
            !!hasRiskFactor.length &&
            hasRiskFactor.length <= 2)
        ) {
          RCVresult = 2;
        }
      }
      if (
        (RCVresult === 1 || RCVresult === 2) &&
        systolic < 140 &&
        diastolic < 90
      ) {
        goalResult = "DENTRO DA META TERAPÊUTICA";
      } else if (RCVresult === 0 && systolic < 130 && diastolic < 80) {
        goalResult = "DENTRO DA META TERAPÊUTICA";
      }

      setFieldValue(
        "risk_stratification.cardiovascular_risk",
        rcvRisks[RCVresult]
      );
      setFieldValue("risk_stratification.therapeutic_goal", goalResult);
      return {
        risk: rcvRisks[RCVresult],
        therapeutic_goal: goalResult,
      };
    },
    [values.risk_stratification, systolic, diastolic]
  );

  return (
    <>
      <Dialog open={examsModal} handleClose={() => setExamsModal(false)}>
        <Exams attendanceId={attendance?.id} hideBackButton />
      </Dialog>
      <Dialog
        open={editorFormType}
        title={formTitles[editorFormType]}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleCloseEditorFormDialog}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 5,
          }}
        >
          {patientData.name}
        </Typography>
        <EditorForms
          staticPatientId={patientData.id}
          showOnlyTable
          staticType={editorFormType}
          showTabs={
            !["pregnant", "pregnant_first_avaliation", "child"].includes(
              editorFormType
            )
          }
          disableResponse={disableDocumentResponse}
          disableActions={disableDocumentActions}
          onSuccess={() => {
            queryClient.invalidateQueries("records-ampi-query");
          }}
        />
      </Dialog>
      <Grid container spacing={2}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item container xs={12} spacing={2}>
            {company?.company?.type?.CAPS && (
              <Grid item xs={3}>
                <Button
                  onClick={() => setEditorFormType("caps")}
                  variant="outlined"
                  startIcon={<Brain />}
                >
                  Acolhimento Inicial
                </Button>
              </Grid>
            )}
            {attendanceIsAMPI && !values.pregnant && (
              <Grid item xs={3}>
                <Button
                  onClick={() => setEditorFormType("ampi")}
                  variant="outlined"
                >
                  AMPI
                </Button>
              </Grid>
            )}
            {convertedBirthDate?.years >= 18 &&
              [MEDICAL_EMPLOYEE_TYPE_ID, NURSE_EMPLOYEE_TYPE_ID].includes(
                userData?.employee_type?.id
              ) && (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  columnGap={"18px"}
                >
                  <FormSwitch
                    name="risk_stratification_status"
                    customHandleChange={toggleRCV}
                    label="Estratificação RCV"
                    disabled={disabledFieldNames.includes(
                      "risk_stratification_status"
                    )}
                  />
                  {values.risk_stratification_status && (
                    <>
                      <Typography fontSize={"11px"} color={"red"}>
                        {`RISCO CARDIOVASCULAR: ${rcvStatus.risk}`}
                      </Typography>
                      <Typography fontSize={"11px"} color="secondary.main">
                        {rcvStatus.therapeutic_goal}
                      </Typography>
                    </>
                  )}
                </Grid>
              )}
            {patientData?.gender === "Feminino" && !attendanceIsAMPI && (
              <>
                <Grid
                  item
                  container
                  md={pregnantFieldIsDisabled && (odonto || ceo) ? 12 : 3}
                  xs={pregnantFieldIsDisabled && (odonto || ceo) ? 12 : 3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormSwitch
                    name="pregnant"
                    label="Paciente gestante?"
                    disabled={
                      disableFields ||
                      pregnantFieldIsDisabled ||
                      odonto ||
                      ceo ||
                      convertedBirthDate?.years <= 6
                    }
                    customHandleChange={handlePregnant}
                  />
                  {!(odonto || ceo) &&
                    values.obstetric_usg_date &&
                    (values.obstetric_usg_weeks ||
                      values.obstetric_usg_weeks === 0) &&
                    (values.obstetric_usg_days ||
                      values.obstetric_usg_days === 0) && (
                      <Typography color="secondary">
                        <Typography
                          color="secondary"
                          fontWeight="600"
                          component="span"
                        >
                          Idade gestacional:{" "}
                        </Typography>
                        {gestationalResult.DUM?.gestationalAge}
                      </Typography>
                    )}
                </Grid>
                {values.pregnant && !(odonto || ceo) && (
                  <Grid
                    item
                    md={6}
                    xs={12}
                    display="flex"
                    justifyContent="space-between"
                    gap="20px"
                  >
                    <Button
                      variant="outlined"
                      onClick={() =>
                        setEditorFormType("pregnant_first_avaliation")
                      }
                      disabled={disableFields}
                    >
                      Primeira Consulta
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setEditorFormType("pregnant")}
                      disabled={disableFields}
                    >
                      Encerramento da gestação
                    </Button>
                  </Grid>
                )}
              </>
            )}
            {convertedBirthDate?.years <= 6 && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => setEditorFormType("child")}
                  disabled={disableFields}
                >
                  Primeira consulta pediátrica
                </Button>
              </Grid>
            )}
          </Grid>
          {values.pregnant && !(odonto || ceo) && (
            <>
              <Grid item xs={3}>
                <FormDate
                  name="last_menstrual_period"
                  label="Data da última menstruação"
                  disabled={disableFields}
                  maxDate={parseISO(attendance?.createdAt)}
                />
              </Grid>
              <Grid item xs={3}>
                <FormDate
                  name="obstetric_usg_date"
                  label="Data da primeira USG Obstétrico"
                  disabled={disableFields}
                  minDate={values.last_menstrual_period}
                  maxDate={parseISO(attendance?.createdAt)}
                />
              </Grid>
              <Grid item xs={2}>
                <FormTextField
                  type="number"
                  name="obstetric_usg_weeks"
                  label="Semanas no USG"
                  disabled={disableFields}
                  min={1}
                  max={42}
                />
              </Grid>
              <Grid item xs={2}>
                <FormTextField
                  type="number"
                  name="obstetric_usg_days"
                  label="Dias no USG"
                  disabled={disableFields}
                  min={0}
                  max={6}
                />
              </Grid>
              <Grid item container xs={12} spacing={2}>
                {(values.last_menstrual_period ||
                  values.obstetric_usg_date) && (
                  <Grid item container lg={7.6} xs={12} spacing={1} my={0.1}>
                    {values.last_menstrual_period && (
                      <Grid item xs={12} md={5.7}>
                        <Typography
                          color="secondary"
                          fontWeight="500"
                          fontSize={14}
                          mb={0.4}
                        >
                          Calculo gestacional (DUM):
                        </Typography>
                        <Box display="flex" gap={0.4}>
                          <Typography
                            color="secondary"
                            fontWeight="600"
                            component="span"
                            fontSize={12}
                          >
                            Idade gestacional:{" "}
                          </Typography>
                          <Typography
                            color="secondary"
                            component="span"
                            fontSize={12}
                          >
                            {gestationalResult.DUM?.gestationalAge}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={0.4}>
                          <Typography
                            color="secondary"
                            fontWeight="600"
                            component="span"
                            fontSize={12}
                          >
                            Data provável de parto:{" "}
                          </Typography>
                          <Typography
                            color="secondary"
                            component="span"
                            fontSize={12}
                          >
                            {gestationalResult.DUM?.probableDateOfBirth}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
                    {values.obstetric_usg_date &&
                      (values.obstetric_usg_weeks ||
                        values.obstetric_usg_weeks === 0) &&
                      (values.obstetric_usg_days ||
                        values.obstetric_usg_days === 0) && (
                        <Grid item xs={12} md={5.7}>
                          <Typography
                            color="secondary"
                            fontWeight="500"
                            fontSize={14}
                            mb={0.4}
                          >
                            Calculo gestacional (USG):
                          </Typography>
                          <Box display="flex" gap={0.4}>
                            <Typography
                              color="secondary"
                              fontWeight="600"
                              component="span"
                              fontSize={12}
                            >
                              Idade gestacional:{" "}
                            </Typography>
                            <Typography
                              color="secondary"
                              component="span"
                              fontSize={12}
                            >
                              {gestationalResult.USG?.gestationalAge}
                            </Typography>
                          </Box>
                          <Box display="flex" gap={0.4}>
                            <Typography
                              color="secondary"
                              fontWeight="600"
                              component="span"
                              fontSize={12}
                            >
                              Data provável de parto:{" "}
                            </Typography>
                            <Typography
                              color="secondary"
                              component="span"
                              fontSize={12}
                            >
                              {gestationalResult.USG?.probableDateOfBirth}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                  </Grid>
                )}
                <Grid item lg={4.4} xs={12} display="flex" gap={0.5}>
                  {gpncaFields.map((field) => (
                    <Box
                      width={60}
                      display="flex"
                      flexDirection="column"
                      gap={0.6}
                    >
                      <Tooltip title={field.title}>
                        <Typography
                          sx={{
                            borderRadius: 2,
                            backgroundColor: theme.palette.primary.main,
                            textAlign: "center",
                            color: "white",
                            paddingY: 0.5,
                            fontSize: 17,
                            fontWeight: 600,
                          }}
                        >
                          {field.label}
                        </Typography>
                      </Tooltip>
                      <FormSelectField
                        name={field.name}
                        options={field.options}
                        disabled={disableFields}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
          {(odonto || ceo) && (
            <Grid item xs={12} display={"flex"} columnGap={2}>
              <Button
                onClick={() => setEditorFormType("anamnese")}
                variant="contained"
                disabled={disableFields}
              >
                ANAMNESE
              </Button>
              <Button
                onClick={() => setEditorFormType("terms")}
                variant="contained"
                disabled={disableFields}
              >
                TERMOS
              </Button>
            </Grid>
          )}
          {attendanceIsAMPI && !values.pregnant && (
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={4}>
                <FormDate
                  name="ampi_last_classification"
                  label="Data da última classificação"
                  maxDate={new Date()}
                  disabled
                  required={!!recordAMPIQuery?.items?.length}
                />
              </Grid>
              <Grid item xs={4}>
                <FormSelectField
                  name="ampi_classification"
                  label="Classificação AMPI"
                  options={ampiOptions}
                  disabled
                  required={!!recordAMPIQuery?.items?.length}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormTextField
              name="physical_exams"
              multiline
              minRows={4}
              maxRows={4}
              label={
                userData?.employee_type?.higher_education
                  ? "Exame físico/psíquico:"
                  : "Sinais e Sintomas"
              }
              disabled={disableFields}
              required={userData.employee_type.higher_education ? true : false}
            />
          </Grid>
          <Grid item xs={convertedBirthDate?.years < 3 ? 9 : 8}>
            <FormMultipleSelectField
              name="doencasPreexistentes"
              required
              options={preIllness || []}
              keys={["id", "describe"]}
              label="Comorbidades :  "
              disabled={disableFields}
              customHandleChange={(_, value) =>
                handleBloodPressureValidation(value)
              }
            />
          </Grid>
          <Grid item xs={convertedBirthDate?.years < 3 ? 3 : 4}>
            <FormRadio
              name="radioVacina"
              radios={convertedBirthDate?.years < 3 ? radios : radiosAvailable}
              legend="Vacina em dia ?"
              keys={["label", "value"]}
              multiple
              row
              required={convertedBirthDate?.years < 3}
              disabled={disableFields}
            />
          </Grid>
          {/* <Grid item>
            <Button
              variant="contained"
              onClick={() => setExamsModal(true)}
              style={{ gap: 10 }}
            >
              <Exames />
              Prontuário Integrado
            </Button>
          </Grid> */}
        </Grid>
        <Grid item xs={12} my={1}>
          <Typography variant="h7" color="secondary" fontWeight="500">
            Itens de Aferição
          </Typography>
          <Divider />
        </Grid>
        <Grid container item spacing={2} xs={12}>
          {admeasurementOptions?.length ? (
            admeasurementOptions
          ) : (
            <Grid item xs={12}>
              <Typography variant="h7" color="secondary" fontWeight="500">
                Nenhuma aferição encontrada.
              </Typography>
            </Grid>
          )}
          {imcParams.PESO && imcParams.ALTURA ? (
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              xs={4}
            >
              <Typography
                component="p"
                color="textSecondary"
                sx={{
                  fontFamily: "Montserrat",
                  color: theme.palette.primary.light,
                  fontSize: 16,
                  fontWeight: 600,
                  width: "100%",
                }}
              >
                IMC:{" "}
                <Typography component="span" fontSize={16}>
                  {calcIMC(
                    values?.admeasurement_objective?.[imcParams.PESO],
                    values?.admeasurement_objective?.[imcParams.ALTURA],
                    <Typography component="span" sx={{ color: "#F51514" }}>
                      "Inserir altura e peso válidas."
                    </Typography>
                  )}
                </Typography>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        {values.risk_stratification_status && (
          <>
            <Grid item xs={12} my={1}>
              <Typography variant="h7" color="secondary" fontWeight="500">
                Estratificação RCV
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h7" color="secondary" fontWeight="500">
                Fatores de Risco
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormRadio
                name="risk_stratification.cvd_family_history"
                radios={radios}
                row
                legend="Histórico de DCV prematura em parentes de primeiro grau:"
                infoLegend={"+55 anos para homens ou +65 anos para mulheres"}
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <FormRadio
                name="risk_stratification.smoking"
                radios={radios}
                row
                legend="Tabagismo:"
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <FormRadio
                name="risk_stratification.diabetes"
                radios={radios}
                row
                legend="Diabetes:"
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormRadio
                name="risk_stratification.dyslipidemia"
                radios={radios}
                row
                legend="Dislipidemia:"
                infoLegend={
                  "Triglicerídeos > 150mg/dL e/ou LDL-C >100mg/dL e/ou HDL-C <40 mg/Dl"
                }
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <FormRadio
                name="risk_stratification.obesity"
                radios={radios}
                row
                legend="Obesidade:"
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h7" color="secondary" fontWeight="500">
                Lesão de Órgão Alvo (LOA)
              </Typography>
            </Grid>
            <Grid item xs={3.8}>
              <FormRadio
                name="risk_stratification.left_ventricular_hypertrophy"
                radios={radios}
                row
                legend="Hipertrofia Ventricular Esquerda (HVE):"
                infoLegend={"ECG: Sokolow-Lyon ≥ 35mm; ou RaVL>11mm"}
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={2.2}>
              <FormRadio
                name="risk_stratification.albuminuria"
                radios={radios}
                row
                legend="Albuminúria:"
                infoLegend={">30mg/dl"}
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <FormRadio
                name="risk_stratification.glomerular_filtration_rate"
                radios={radios}
                row
                legend="Taxa de Filtração Glomerular:"
                infoLegend={"<60ml/min/1,73 m²"}
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h7" color="secondary" fontWeight="500">
                Doença Cardiovascular Avançada
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <FormRadio
                name="risk_stratification.ischemic_stroke"
                radios={radios}
                row
                legend="AVE Isquêmico:"
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <FormRadio
                name="risk_stratification.cerebral_hemorrhage"
                radios={radios}
                row
                legend="Hemorragia Cerebral:"
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <FormRadio
                name="risk_stratification.transient_ischemic_attack"
                radios={radios}
                row
                legend="Ataque Isquêmico Transitório (AIT):"
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <FormRadio
                name="risk_stratification.stable_or_unstable_angina"
                radios={radios}
                row
                legend="Angina Estável ou Instável:"
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <FormRadio
                name="risk_stratification.myocardial_infarction"
                radios={radios}
                row
                legend="Infarto do Miocárdio:"
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={7}>
              <FormRadio
                name="risk_stratification.myocardial_revascularization"
                radios={radios}
                row
                legend="Revascularização do Miocárdio (Angioplastia ou Cirúrgica):"
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <FormRadio
                name="risk_stratification.heart_failure_or_reduced"
                radios={radios}
                row
                legend="Clínica de Insuficiência Cardíaca e/ou Fração de Ejeção Reduzida FEVE <40:"
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={7}>
              <FormRadio
                name="risk_stratification.artery_disease_lower_limbs"
                radios={radios}
                row
                legend="Doença Arterial Periférica Sintomática dos Membros Inferiores:"
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <FormRadio
                name="risk_stratification.stage_four_chronic_kidney_disease"
                radios={radios}
                row
                legend="Doença Renal Crônica Estágio 4:"
                infoLegend={"RFG-e 30ml/min/1,73 ou Albuminúria >300mg/24h"}
                keys={["label", "value"]}
                required
              />
            </Grid>
            <Grid item xs={5}>
              <FormRadio
                name="risk_stratification.advanced_retinopathy"
                radios={radios}
                row
                legend="Retinopatia avançada: hemorragias, exsudatos, papiledema:"
                keys={["label", "value"]}
                required
              />
            </Grid>
          </>
        )}
        {values.pregnant && !(odonto || ceo) && (
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} my={1}>
              <Typography
                color="secondary"
                fontWeight="500"
                sx={{
                  display: "flex",
                  justifyContent: "unset",
                  mt: 1,
                  mb: 0.5,
                }}
              >
                Campos complementares da gestante
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={2.5}>
              <FormRadio
                name="fetal_movements"
                legend="Movimentos fetais?"
                radios={radios}
                row
                keys={["label", "value"]}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={3}>
              <FormRadio
                name="folic_acid_supplementation"
                legend="Suplementação com Ácido Fólico?"
                radios={radios}
                row
                keys={["label", "value"]}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={3.5}>
              <FormRadio
                name="calcium_carbonate_supplementation"
                legend="Suplementação de Carbonato de Cálcio?"
                radios={radios}
                row
                keys={["label", "value"]}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={3}>
              <FormRadio
                name="ferrous_sulfate_supplementation"
                legend="Suplementação sulfato ferroso?"
                radios={radios}
                row
                keys={["label", "value"]}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={2.5}>
              <FormRadio
                name="planned_pregnancy"
                legend="Gravidez Planejada?"
                radios={radios}
                row
                keys={["label", "value"]}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={4.75}>
              <FormSelectField
                name="edema"
                label="Edema"
                options={edemaOptions}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={4.75}>
              <FormSelectField
                name="gestational_risk"
                label="Risco gestacional"
                options={gestationalRiskOptions}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={4}>
              <FormSelectField
                name="fetal_presentation"
                label="Apresentação fetal"
                options={fetalPresentationOptions}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={4}>
              <FormSelectField
                name="normal_clinical_exam"
                label="Exame clínico normal?"
                options={doneOptions}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={4}>
              <FormSelectField
                name="normal_gynecological_exam"
                label="Exame ginecológico normal?"
                options={doneOptions}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={4}>
              <FormSelectField
                name="normal_breast_exam"
                label="Exame de mamas normal?"
                options={doneOptions}
                disabled={disableFields}
              />
            </Grid>
            {complementaryAdmeasurements}
            <Grid item xs={12} my={1}>
              <Typography
                color="secondary"
                fontWeight="500"
                sx={{
                  display: "flex",
                  justifyContent: "unset",
                  mt: 1,
                  mb: 0.5,
                }}
              >
                Gestações anteriores
              </Typography>
              <Divider />
            </Grid>
            {Array(previousPregnancies)
              .fill()
              .map((_, index) => (
                <Grid item xs={4} key={"previous_pregnancies-" + index}>
                  <FormDate
                    name={"previous_pregnancies-" + index}
                    label="Data de gestação anterior"
                    disabled={disableFields}
                  />
                </Grid>
              ))}
            <Grid item xs={12} display="flex" alignItems="center" gap={4}>
              <Button
                variant="contained"
                color="error"
                disabled={previousPregnancies === 1 || disableFields}
                onClick={() => setPreviousPregnancies((value) => value - 1)}
              >
                <RemoveRoundedIcon color="#FFFF" />
              </Button>
              <Typography color="secondary" fontWeight="500" fontSize={16}>
                {previousPregnancies}
              </Typography>
              <Button
                variant="contained"
                onClick={() => setPreviousPregnancies((value) => value + 1)}
                disabled={disableFields}
              >
                <AddRoundedIcon color="#FFFF" />
              </Button>
            </Grid>
          </Grid>
        )}
        {convertedBirthDate?.years <= 6 && (
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} my={1}>
              <Typography
                color="secondary"
                fontWeight="500"
                sx={{
                  display: "flex",
                  justifyContent: "unset",
                  mt: 1,
                  mb: 0.5,
                }}
              >
                Campos complementares da criança
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} md={2}>
              <FormSelectField
                name={"breastfeeding"}
                label="Aleitamento materno?"
                disabled={disableFields}
                options={breastfeedingOptions}
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelectField
                name="ortolani"
                label="Ortolani"
                options={childOptions}
                disabled={disableFields}
                required={
                  parseFloat(
                    `${convertedBirthDate?.years}.${convertedBirthDate.months}`
                  ) <= 0.3
                }
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelectField
                name="neuropsychomotor_development"
                label="Desenvolvimento Neuropsicomotor(DNPM)"
                options={DNPMOptions}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={3}>
              <FormSelectField
                name="red_reflex_exam"
                label="Reflexo vermelho"
                options={childOptions}
                disabled={disableFields}
                required={convertedBirthDate?.years <= 2}
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} mt={2}>
          <Typography
            variant="h7"
            color="secondary"
            fontWeight="500"
            sx={{ display: "flex", justifyContent: "unset", mt: 1, mb: 0.5 }}
          >
            Alergia
          </Typography>
          <Divider />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={4}>
            <FormRadio
              name="radioStatusAlergia"
              radios={radios2}
              col
              multiple
              legend="Estado da Alergia:"
              keys={["label", "value"]}
              customChange={(value) => {
                if (value === "Nega") setFieldValue("allergy_substances", []);
              }}
              disabled={disableFields}
              required
            />
          </Grid>
          <Grid container item xs={6} spacing={1}>
            <Grid item xs={12}>
              <Typography color="secondary" fontSize={11}>
                Substância
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <FormCheckbox
                name="allergy_substances"
                label="Medicamento"
                value="medication"
                multiple
                disabled={disabledSubstances}
              />
              <FormCheckbox
                name="allergy_substances"
                label="Alimento"
                value="food"
                multiple
                disabled={disabledSubstances}
              />
              <FormCheckbox
                name="allergy_substances"
                value="others"
                label="Outros"
                multiple
                disabled={disabledSubstances}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name="allergy_description"
              label="Descrição"
              multiline
              minRows={1}
              maxRows={1}
              disabled={disableFields}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="complementary_exams"
            label="Exames complemetares / Medicamentos em uso"
            multiline
            minRows={8}
            maxLength={7000}
            maxRows={8}
            disabled={disableFields}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Objective;
