import React from "react";
import {
  Box,
  Button,
  Fade,
  IconButton,
  Popover,
  Popper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material";
import ptBR from "date-fns/esm/locale/pt-BR/index.js";
import { format, parseISO } from "date-fns";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import { MedicationLiquidRounded } from "@mui/icons-material";

const PrescriptionCard = ({ sx, data, type, handleClick, handlePrint }) => {
  const {
    provider,
    CRM,
    UF,
    initial_date,
    final_date,
    time,
    medications,
    exams,
    medications_on_site,
    signedDocument,
    notSignedDocument,
  } = data;

  const CardContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.medium,
    borderRadius: 8,
    padding: "10px 80px 10px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    position: "relative",
  }));

  const DateHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 700,
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
    gap:"5px",
    marginBottom: 5,
  }));

  const InfoHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.light,
    fontSize: 14,
    marginBottom: 5,
  }));

  const InfoDesc = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.light,
    fontSize: 12,
  }));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const buttonRef = React.useRef();
  const handleClickPrint = () => {
    setAnchorEl(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function hasExamByType(examType) {
    return exams?.some(({ describe, type }) => {
      if (examType === "xray") {
        return describe.includes("RADIOGRAFIA") && type === "Imagem";
      } else if (examType === "image") {
        return !describe.includes("RADIOGRAFIA") && type === "Imagem";
      } else {
        return type === examType;
      }
    });
  }

  return (
    <Box>
      {initial_date && (
        <DateHeader component="h3">
          {format(parseISO(initial_date), "dd 'de' MMMM, yyyy", {
            locale: ptBR,
          })}
        </DateHeader>
      )}
      {data?.medications_on_site?.some(
        (medication) => medication.medication_assisted_treatment
      ) && (
        <DateHeader component="h3">
          <MedicationLiquidRounded />
          <Typography>Medicamentos assistidos prescritos.</Typography>
        </DateHeader>
      )}
      <CardContainer sx={sx}>
        <InfoHeader fontWeight={700}>
          {provider} - CRM - {UF} - {CRM} - {time}{" "}
        </InfoHeader>
        <Box display="flex" flexDirection="column">
          {initial_date && (
            <InfoDesc>
              <Typography component="span" fontWeight={700}>
                Início:{" "}
              </Typography>
              {format(parseISO(initial_date), "dd/MM/yyyy")}
            </InfoDesc>
          )}
          {final_date && type === "medication" && (
            <InfoDesc>
              <Typography component="span" fontWeight={700}>
                Conclusão:{" "}
              </Typography>
              {format(parseISO(final_date), "dd/MM/yyyy")}
            </InfoDesc>
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 13,
            right: 10,
            display: "flex",
            gap: 1,
          }}
        >
          <CommentOutlinedIcon
            color="secondary"
            onClick={() => {
              handleClick(medications, medications_on_site, exams);
            }}
            sx={{
              width: 24,
              height: 24,
              cursor: "pointer",
            }}
          />
          {type === "medications_on_site" ? (
            <LocalPrintshopRoundedIcon
              color="secondary"
              onClick={() => handlePrint(signedDocument, notSignedDocument)}
              sx={{
                width: 24,
                height: 24,
                cursor: "pointer",
              }}
            />
          ) : (
            <>
              <Box>
                <IconButton
                  sx={{
                    width: 24,
                    height: 24,
                    cursor: "pointer",
                  }}
                  aria-describedby={id}
                  onClick={handleClickPrint}
                  ref={buttonRef}
                >
                  <LocalPrintshopRoundedIcon
                    color="secondary"
                    sx={{
                      width: 24,
                      height: 24,
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  sx={{
                    padding: "5px",
                    marginTop: -5,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  anchorEl={() => buttonRef?.current}
                >
                  <Box
                    sx={{
                      width: "100%",
                      padding: "10px 10px",
                      display: "flex",
                      gap: "15px",
                    }}
                  >
                    {type === "exams" && (
                      <>
                        {signedDocument?.["lab"] ||
                        hasExamByType("Laboratorial") ? (
                          <Button
                            onClick={() =>
                              handlePrint(
                                signedDocument?.["lab"],
                                notSignedDocument,
                                (type = "lab")
                              )
                            }
                          >
                            SADT LAB
                          </Button>
                        ) : null}
                        {signedDocument?.["xray"] || hasExamByType("xray") ? (
                          <Button
                            onClick={() =>
                              handlePrint(
                                signedDocument?.["xray"],
                                notSignedDocument,
                                (type = "xray")
                              )
                            }
                          >
                            SADT RX
                          </Button>
                        ) : null}
                        {signedDocument?.["images"] ? (
                          <>
                            {signedDocument?.["images"].map((exam, index) => (
                              <Button
                                onClick={() =>
                                  handlePrint(
                                    exam,
                                    notSignedDocument,
                                    (type = "images")
                                  )
                                }
                              >
                                {`SADT IMG ${index + 1}`}
                              </Button>
                            ))}
                          </>
                        ) : hasExamByType("Imagem") ? (
                          exams
                            .filter(
                              ({ describe, type }) =>
                                !describe.includes("RADIOGRAFIA") &&
                                type === "Imagem"
                            )
                            .map((exam, index) => (
                              <Button
                                onClick={() =>
                                  handlePrint(
                                    false,
                                    notSignedDocument,
                                    (type = "images"),
                                    exam
                                  )
                                }
                              >
                                {`SADT IMG ${index + 1}`}
                              </Button>
                            ))
                        ) : null}
                      </>
                    )}
                    {type === "medications" && (
                      <>
                        {signedDocument?.["signed_special_medications"] ? (
                          <>
                            {signedDocument?.["signed_special_medications"].map(
                              (presc, index) => (
                                <Button
                                  onClick={() =>
                                    handlePrint(
                                      presc,
                                      notSignedDocument,
                                      (type = "signed_special_medications")
                                    )
                                  }
                                >
                                  {`ESPECIAL ${index + 1}`}
                                </Button>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {medications
                              ?.filter((i) => i.special)
                              ?.map((med, index) => (
                                <Button
                                  onClick={() =>
                                    handlePrint(
                                      false,
                                      notSignedDocument,
                                      (type = "special_medications"),
                                      med
                                    )
                                  }
                                >
                                  {`ESPECIAL ${index + 1}`}
                                </Button>
                              ))}
                          </>
                        )}
                        {signedDocument?.["signed_medications"] ||
                        medications?.filter((i) => !i.special).length ? (
                          <Button
                            onClick={() =>
                              handlePrint(
                                signedDocument?.["signed_medications"],
                                notSignedDocument
                              )
                            }
                          >
                            {`Receituário`}
                          </Button>
                        ) : null}
                      </>
                    )}
                  </Box>
                </Popover>
              </Box>
            </>
          )}
        </Box>
      </CardContainer>
    </Box>
  );
};

export default PrescriptionCard;
