import { Box, CircularProgress, Typography } from "@mui/material";
import { useCallback } from "react";
import { ReactComponent as Logo } from "../../assets/svg/tascomBlue.svg";
import { useDebounceCallback } from "../../hooks";

export function PaginatedScrollList({
  height = "50vh",
  minHeight = "150px",
  hasDivider = true,
  loading = false,
  handlePage = () => null,
  page = 0,
  totalPage = 0,
  data = [],
  component = () => null,
  emptyMessage = "Não foi possível encontrar a pesquisa solicitada :(",
  endMessage = "Não há mais atendimentos neste dia",
}) {
  const debouncedPage = useDebounceCallback(() => handlePage(page + 1), 500);

  const detectScroll = useCallback(
    (e) => {
      if (
        e.target.scrollTop + e.target.clientHeight >=
        e.target.scrollHeight - 2
      ) {
        if (page < totalPage) {
          debouncedPage();
        }
      }
    },
    [page, totalPage]
  );

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        borderBottom={(theme) =>
          hasDivider ? `1px solid ${theme.palette.primary.light}` : "none"
        }
        sx={{
          maxHeight: height,
          minHeight: minHeight,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          rowGap: 1,
        }}
        onScroll={detectScroll}
      >
        {data?.map((value, index) =>
          component instanceof Function ? component(value, index) : null
        )}
        {!data.length && !loading && (
          <Typography color="primary.main">{emptyMessage}</Typography>
        )}
        {page === totalPage && !!data.length && !loading && (
          <Typography color="primary.main">{endMessage}</Typography>
        )}
      </Box>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 3000,
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "grayscale(30%)",
          }}
        >
          <Box marginTop={1} position="absolute">
            <Logo />
          </Box>
          <CircularProgress size={80}></CircularProgress>
        </Box>
      ) : null}
    </Box>
  );
}
