import React from "react";
import { Form, Formik } from "formik";
import Yup from "../../../config/yup";
import { Grid } from "@mui/material";
import { FormTextField, SubmitButton } from "../../../components/Form";
import { TITLE_NAME } from "../../../config/config";
import useManufacturer from "../../../service/useManufacturer";
import Helmet from "react-helmet";
import useNotifier from "../../../hooks/useNotifier";

function ManufacturerForm({ handleClose }) {
  const { postManufacturers } = useManufacturer();
  const notify = useNotifier();
  const initialValues = {
    describe: "",
  };

  const validations = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
  });

  async function handleSubmit(data) {
    const manufacturerObject = {
      describe: data.describe,
    };
    try {
      const response = await postManufacturers(manufacturerObject);
      if (response) {
        notify("Fabricante cadastrado com Sucesso!", "success");
      }
    } catch (err) {
      notify(err.message, "error");
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Vacina</title>
      </Helmet>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validations}
      >
        {({}) => (
          <Form>
            <Grid container spacing={2} alignItems="center">
              <Grid xs={8} item>
                <FormTextField name="describe" label="Descrição" required />
              </Grid>
              <Grid xs={4} item>
                <SubmitButton>Salvar Cadastro</SubmitButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ManufacturerForm;
