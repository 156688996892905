import React, { useState } from "react";
import { Box, TablePagination } from "@mui/material";
import { DialogMedium } from "../../helper";
import { useQuery } from "react-query";
import useDocuments from "../../service/useDocuments";
import SettingsEditDocumentsTypes from "./SettingsUpdatesDialogs/SettingsEditDocumentsTypes";
import TableFilter from "../../components/Table/TableFilter";
import { useNotifier } from "../../hooks";

export default function SettingsProfissional() {
  const notify = useNotifier();
  const [edit, setEdit] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentType, setDocumentType] = useState(null);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({});

  const { getDocumentsTypes } = useDocuments();

  const documentsTypesQuery = useQuery(
    ["documents-types", page, limit, filter],
    () => getDocumentsTypes({ page, limit }),
    {
      retry: false,
      onSuccess: (response) => {
        setDocuments(
          response.items.map((element, index) => {
            return { ...element, code: index + 1 };
          })
        );
        setCount(response.totalItems);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const handleCloseDialog = () => {
    setEdit(false);
    setDocumentType(null);
  };

  const columns = [
    {
      field: "code",
      name: "Código",
      width: 150,
      type: "string",
    },
    {
      field: "describe",
      name: "Tipo",
      width: 600,
      type: "string",
    },
  ];

  function handleFilter(filter) {
    setPage(0);
    setFilter(filter);
  }

  return (
    <>
      <DialogMedium
        open={edit}
        fullWidth
        maxWidth="md"
        title="Adicionar documentos"
        handleClose={handleCloseDialog}
      >
        <SettingsEditDocumentsTypes documentType={documentType} />
      </DialogMedium>

      <TableFilter
        data={documents}
        columns={columns}
        loading={documentsTypesQuery.isLoading}
        actions
        handleFilter={handleFilter}
        actionUniqueIdentifier={"id"}
        actionsTypes={["edit"]}
        shouldDisableFields={["id"]}
        actionHandleEdit={(_, item) => {
          setEdit(true);
          setDocumentType(item);
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={count}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}
