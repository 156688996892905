import React from "react";
import TableFilter from "../../../components/Table/TableFilter/Table";

export default function ExamTable({
  exams,
  check,
  activeActions,
  disableActions,
}) {
  const columns = [
    { name: "Exame", field: "describe" },
    { name: "Nota", field: "notes" },
    { name: "tipo", field: "type" },
  ];

  return (
    <>
      <TableFilter
        data={exams || []}
        columns={columns}
        noHeaderOptions
        actions={activeActions}
        actionsTypes={["finish", "delete"]}
        customizeActions={{
          finish: {
            title: "Coletado",
            verb: "Marcar como Coletado",
          },
          delete: {
            title: "Não Coletado",
            verb: "Marcar como Não Coletado",
          },
        }}
        rowStatus={(item) =>
          item.done ? "active" : item.done === false ? "disabled" : ""
        }
        disableActions={(item, action) => {
          if (disableActions instanceof Function) {
            const result = disableActions(item, action);

            if (result) return result;
          }

          return !(item.done === null);
        }}
        actionHandleFinish={check}
        actionHandleDelete={(_, i, v) => check(_, i, v, false)}
      />
    </>
  );
}
