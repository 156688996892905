import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "../../../config/theme";
import Yup from "../../../config/yup";
import { Grid, Box, Button, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import Grow from "@mui/material/Grow";
import { AppContext } from "../../../contexts/AppContext";
import { usePresets, useProcedures } from "../../../service";
import {
  AutocompleteField,
  PaginatedAutocompleteField,
  TextField,
} from "../../../components/FormFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteIcon } from "../../../components/Icons";
import PatientDataConfirm from "../PatientDataConfirm";
import { useLocalStorage } from "../../../hooks";

function ExamPrescription({ attendance, handleSave }) {
  const { getProcedures } = useProcedures();
  const { getPresets } = usePresets();
  const {
    examPrescriptions,
    setExamPrescriptions,
    disabledFields,
    patientData,
    setPatientData,
  } = useContext(AppContext);
  const [userData] = useLocalStorage("user-data");
  const [updatePatientType, setUpdatePatientType] = useState(() =>
    !patientData?.race ? "race" : null
  );

  const Types = {
    lab: "Laboratorial",
    image: "Imagem",
  };

  function presetChange(selectedItems) {
    "";
    const presets = selectedItems?.flatMap((value) => value.data) || [];

    function filterExams(exams) {
      const filteredExams = exams.filter(({ notPreseted }) => notPreseted);
      return [...presets, ...filteredExams];
    }
    setExamPrescriptions(filterExams);
  }

  const addPrescription = (values) => {
    const exams = {
      ...values,
      type: Types[values.type.type],
      isRegulated: values?.describe?.type.includes("regulation"),
      procedure_id: values?.describe?.id,
      describe:
        typeof values.describe === "string"
          ? values.describe
          : values.describe.name,
      notPreseted: true,
    };
    delete exams.preset;
    setExamPrescriptions((prescriptions) => [...prescriptions, exams]);
    reset();
  };

  const removePrescription = (index) => {
    const newPrescriptions = examPrescriptions.filter((p, i) => i !== index);
    setExamPrescriptions(newPrescriptions);
  };

  const initialValues = {
    type: null,
    describe: null,
    notes: "",
    segment_area: "",
    diagnostic_hypothesis: "",
  };

  const validations = Yup.object().shape({
    type: Yup.object().required("É requerido").nullable(),
    describe: Yup.object().required("É requerido").nullable(),
    notes: Yup.string().nullable(),
    segment_area: Yup.string(),
    diagnostic_hypothesis: Yup.string().nullable(),
  });

  const examTypes = [
    { type: "lab", describe: "Exame laboratorial" },
    { type: "image", describe: "Exame de imagem" },
  ];

  const CardPrescription = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 15,
    backgroundColor: theme.palette.primary.medium,
    borderRadius: 8,
    padding: "0.8rem",
    color: theme.palette.primary.light,
    position: "relative",
  }));

  const PrescriptionHeader = styled(Typography, {
    shouldForwardProp: (prop) => {
      return prop !== "color" && prop !== "sx";
    },
  })(({ theme }) => ({
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.light,
  }));

  const PrescriptionDesc = styled(Typography, {
    shouldForwardProp: (prop) => {
      return prop !== "color" && prop !== "sx";
    },
  })(({ theme }) => ({
    fontSize: 13,
    marginRight: 20,
    color: theme.palette.primary.light,
    wordBreak: "break-word",
  }));

  const AddPrescritptionButton = ({ children, ...props }) => {
    return (
      <Button
        {...props}
        variant="text"
        sx={{
          color: theme.palette.primary.light,
          fontWeight: "bold",
          padding: 0,
        }}
      >
        <AddCircleIcon sx={{ marginRight: 0.5 }} />
        <Typography variant="span">{children}</Typography>
      </Button>
    );
  };

  const { handleSubmit, control, watch, setValue, reset } = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const [type] = watch(["type"]);

  function onSavePrescription() {
    const hasRegulatedExams = examPrescriptions?.some(
      (e) => e.isRegulated === true
    );

    if (hasRegulatedExams && !patientData?.contact?.cell_phone) {
      setUpdatePatientType("phone");
    } else {
      handleSave();
    }
  }

  useEffect(() => {
    setExamPrescriptions((exams) => {
      return exams.map((exam) => ({ ...exam, notPreseted: true }));
    });
  }, []);

  return (
    <>
      <PatientDataConfirm
        open={!!updatePatientType}
        patientId={patientData.id}
        type={updatePatientType}
        handleClose={() => setUpdatePatientType(null)}
        onSuccess={(data, _, type) => {
          setPatientData(() => {
            if (type === "phone") {
              return {
                ...patientData,
                contact: {
                  ...patientData.contact,
                  ...data.contact,
                },
              };
            } else {
              return { ...patientData, race: data.race };
            }
          });
        }}
      />
      <Grid component="form" container spacing={2} marginTop="0.5rem">
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={16} color="secondary" fontWeight="600">
              Selecionar pré definição
            </Typography>
          </Grid>
          <Grid md={5} xs={8} item>
            <PaginatedAutocompleteField
              multiple
              control={control}
              name="preset"
              label="Predefinição"
              queryKey={"presets"}
              service={async (params) =>
                getPresets({ ...params, type: "exams" })
              }
              optionLabelKey={"describe"}
              customOnChange={presetChange}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={16} color="secondary" fontWeight="600">
              Exame
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <AutocompleteField
              required
              control={control}
              name="type"
              label="Tipo de exame"
              customOnChange={(value) => {
                if (value?.describe !== type?.describe) {
                  setValue("describe");
                }
              }}
              options={examTypes}
              optionLabelKey="describe"
            />
          </Grid>
          <Grid xs={8} item>
            <PaginatedAutocompleteField
              required
              label="Exame"
              name="describe"
              disabled={!type}
              control={control}
              filterKey="filters"
              queryKey={"preseting"}
              refetchService={[type]}
              optionLabelKey={"describe"}
              service={(params) => {
                const filters = {
                  ...params,
                  type: [type?.type],
                  filter: "active",
                  patient_id: patientData?.id,
                };

                if (
                  userData?.company.type.TWENTY_FOUR_HOURS &&
                  attendance.risk_classification_id &&
                  type?.type === "lab"
                ) {
                  filters.match_type_exactly = true;
                  filters.type.push("twenty_four_hours");
                }

                return getProcedures(filters);
              }}
              autoCompleteProps={{
                getOptionLabel: (data) => {
                  let label = data?.name;

                  if (data?.tuss_code) {
                    label = `${data?.tuss_code} - ${label}`;
                  }

                  return label;
                },
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              name="segment_area"
              label="AREA E/OU SEGMENTO ANATÔMICO A SER EXAMINADO:"
              control={control}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              name="diagnostic_hypothesis"
              label="Hipotese Diagnóstica"
              control={control}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField name="notes" label="Observação" control={control} />
          </Grid>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" margin="1.2rem auto">
        <AddPrescritptionButton onClick={handleSubmit(addPrescription)}>
          Adicionar Exame
        </AddPrescritptionButton>
      </Box>
      <Grid container spacing={2}>
        {examPrescriptions?.map(
          (
            { describe, type, notes, diagnostic_hypothesis, segment_area },
            index
          ) => {
            return (
              <Grid key={describe + "-" + index} xs={12} md={9} item>
                <Grow in={true} timeout={300}>
                  <CardPrescription>
                    <PrescriptionHeader variant="h3" component="h1">
                      {type}
                    </PrescriptionHeader>
                    <PrescriptionDesc variant="p" component="p">
                      {`${describe} - ${
                        segment_area ? segment_area + " - " : ""
                      } ${
                        diagnostic_hypothesis
                          ? diagnostic_hypothesis + " - "
                          : ""
                      } ${notes}`}
                    </PrescriptionDesc>
                    <DeleteIcon onClick={() => removePrescription(index)} />
                  </CardPrescription>
                </Grow>
              </Grid>
            );
          }
        )}
      </Grid>
      <Box display="flex" justifyContent="center" margin="1.5rem auto">
        <Button
          variant="contained"
          onClick={onSavePrescription}
          disabled={disabledFields || !examPrescriptions.length}
        >
          Salvar
        </Button>
      </Box>
    </>
  );
}

export default ExamPrescription;
