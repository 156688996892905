export default function calcIMC(weight, height, defaultValue) {
  if (weight && height) {
    const a = Number(weight.toString().replaceAll(",", "."));
    const b = Number(height.toString().replaceAll(",", "."));

    const limitWeight = a < 0 || a >= 1000;
    const limitHeight = b > 3;

    const isInvalid =
      [a, b].includes(NaN) ||
      [a, b].includes(Infinity) ||
      limitWeight ||
      limitHeight;

    if (!isInvalid) {
      const imc = a / b ** 2;
      return `${imc.toFixed(2).toString().replaceAll(".", ",")}.`;
    }
  }

  return defaultValue;
}
