import { useEffect, useState } from "react";

export default function ToothFaces({
  position,
  tooth_id,
  handleFaceClick,
  values,
  top,
  bottom,
  disabled,
  disableClick,
}) {
  function getColor(value) {
    return value ? "#6749ED" : value === false ? "#FF5C5C" : "transparent";
  }

  const style = {
    cursor: disabled || disableClick ? "" : "pointer",
  };

  return (
    <svg
      width="34"
      height="60"
      viewBox="0 0 34 32"
      y={position?.cy}
      x={position?.cx}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {top && (
        <g id="@ToothId">
          <text
            x={"50%"}
            y="-8"
            dominantBaseline="middle"
            textAnchor="middle"
            fill={disabled ? "#f1f1f1" : "#7564EF"}
            fontSize="8"
          >
            {tooth_id}
          </text>
        </g>
      )}
      <g id="@CrownFaces">
        <g
          id="@mesial"
          onClick={() => {
            if (!disabled && !disableClick) handleFaceClick("mesial", tooth_id);
          }}
        >
          <path
            id="Rectangle 1248"
            d="M9.74097 7.32237L1.40091 0.5H31.8908L23.5507 7.32237H9.74097Z"
            stroke={disabled ? "#f1f1f1" : "#6749ED"}
            fill={getColor(values?.mesial)}
            style={style}
          />
        </g>
        <g
          id="@lingual"
          onClick={() => {
            if (!disabled && !disableClick)
              handleFaceClick("lingual", tooth_id);
          }}
        >
          <path
            id="Rectangle 1247"
            d="M25.5 22.8084V9.04767L33.3542 2.1089V29.2741L25.5 22.8084Z"
            stroke={disabled ? "#f1f1f1" : "#6749ED"}
            fill={getColor(values?.lingual)}
            style={style}
          />
        </g>
        <g
          id="@distal"
          onClick={() => {
            if (!disabled && !disableClick) handleFaceClick("distal", tooth_id);
          }}
        >
          <path
            id="Rectangle 1249"
            d="M10.0901 24.5L1.44122 31.3223H32.5588L23.9099 24.5H10.0901Z"
            stroke={disabled ? "#f1f1f1" : "#6749ED"}
            fill={getColor(values?.distal)}
            style={style}
          />
        </g>
        <g
          id="@occlusal"
          onClick={() => {
            if (!disabled && !disableClick)
              handleFaceClick("occlusal", tooth_id);
          }}
        >
          <rect
            id="Rectangle 1245"
            x="10.5"
            y="9.5"
            width="13.1667"
            height="13.2222"
            stroke={disabled ? "#f1f1f1" : "#6749ED"}
            fill={getColor(values?.occlusal)}
            style={style}
          />
        </g>
        <g
          id="@buccal"
          onClick={() => {
            if (!disabled && !disableClick) handleFaceClick("buccal", tooth_id);
          }}
        >
          <path
            id="Rectangle 1246"
            d="M8.35547 22.8156V8.99083L0.501302 2.10346V29.5966L8.35547 22.8156Z"
            stroke={disabled ? "#f1f1f1" : "#6749ED"}
            fill={getColor(values?.buccal)}
            style={style}
          />
        </g>
        {bottom && (
          <g id="@ToothId">
            <text
              x={"50%"}
              y="43"
              dominantBaseline="middle"
              textAnchor="middle"
              fill={disabled ? "#f1f1f1" : "#7564EF"}
              fontSize="8"
            >
              {tooth_id}
            </text>
          </g>
        )}
      </g>
    </svg>
  );
}
