import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import { Form, Formik } from "formik";
import BoxContainer from "../../components/BoxContainer/BoxContainer";
import { Loading } from "../../helper";
import useAttendaces from "../../service/useAttendaces";
import { useServices } from "../../service";
import { useProvider } from "../../service";
import { useTeam } from "../../service";
import FormMultipleSelectField from "../../components/Form/FormMultipleSelectField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Helmet } from "react-helmet";
import { TITLE_NAME } from "../../config/config";
import {
  FormDate,
  SubmitButton,
  PatientsCard,
  FormTextField,
} from "../../components";
import FormSelectFieldWithPaginate from "../../components/Form/FormSelectWithPaginate";
import ptBR from "date-fns/esm/locale/pt-BR";
import { format } from "date-fns";
import { useQueryClient, useQuery } from "react-query";
import Yup from "../../config/yup";
import { useLocation } from "react-router-dom";
import PatientServicesDialog from "../../components/PatientServicesDialog";
import { useLocalStorage } from "../../hooks";
import useNotifier from "../../hooks/useNotifier";

function Patient() {
  const [attendances, setAttendances] = React.useState([]);
  const parent = React.useRef();
  const [filters, setFilters] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [enableAttendances, setEnableAttendances] = React.useState(false);
  const notify = useNotifier();

  const [storageFilters, setStorageFilters] = useLocalStorage(
    "patient-history-filters",
    {
      patient: null,
      services: [],
      employee: null,
      initialDate: null,
      finalDate: null,
      team: null,
      sus_card: null,
    }
  );

  const queryClient = useQueryClient();
  const { getAttendances, getAttendanceById } = useAttendaces();
  const { getTeam } = useTeam();
  const { getServices } = useServices();
  const { getProviders } = useProvider();

  async function handleSubmit(values) {
    const serviceNumbers = values.services?.map(({ number }) => number);
    const initialDate = format(new Date(values.initialDate), "yyyy-MM-dd", {
      locale: ptBR,
    });
    const finalDate = format(new Date(values.finalDate), "yyyy-MM-dd", {
      locale: ptBR,
    });

    const params = {
      patient_name: values.patient,
      service_number: serviceNumbers,
      employee_number: values.employee?.number,
      team_number: values.team?.number,
      initial_date: initialDate,
      final_date: finalDate,
      patient_sus_card: values.sus_card,
    };

    setAttendances([]);
    setEnableAttendances(true);
    setPage(0);
    setFilters({
      ...params,
    });
    queryClient.invalidateQueries("attendances");
  }

  function scrollEventPatients({ target }) {
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 1) {
      if (page < totalPage) {
        setPage(page + 1);
      }
    }
  }

  const attendancesQuery = useQuery(
    ["attendances", filters, page],
    () => getAttendances({ ...filters, page, limit: 10 }),
    {
      refetchOnWindowFocus: false,
      enabled: enableAttendances,
      onSuccess: (response) => {
        setTotalPage(response.total);
        setAttendances((attendances) => [...attendances, ...response.items]);
      },
      onError: (error) => {
        notify(error.message, "error");
        setAttendances([]);
        setEnableAttendances(false);
      },
    }
  );

  const servicesQuery = useQuery("services", getServices, {
    refetchOnWindowFocus: false,
    retry: false,
    initialData: [],
  });

  const date = new Date();

  const validator = Yup.object().shape({
    initialDate: Yup.date()
      .typeError("Por favor escolha uma data!")
      .default(date)
      .max(
        date,
        `Escolha uma data até o dia de hoje - ${format(
          new Date(date),
          "dd/MM/yyyy",
          {
            locale: ptBR,
          }
        )}`
      )
      .required("Insira uma data inicial para agenda"),
    finalDate: Yup.date()
      .typeError("Por favor escolha uma data!")
      .max(
        date,
        `Escolha uma data até o dia de hoje - ${format(
          new Date(date),
          "dd/MM/yyyy",
          {
            locale: ptBR,
          }
        )}`
      )
      .when("initialDate", (initialDate, schema) => {
        return schema.test({
          test: (finalDate) => !!initialDate && finalDate >= initialDate,
          message:
            "Escolha uma data final para agenda posterior a data inicial",
        });
      })
      .required("Insira uma data final para agenda posterior a data inicial"),
  });

  const [openServices, setOpenServices] = useState({ isOpen: false });
  const location = useLocation();
  useEffect(async () => {
    if (location.state?.menuServiceOpen) {
      const attendance = await getAttendanceById(location.state?.use);
      setOpenServices({ isOpen: true, patient: attendance });
    }
  }, []);

  if (attendancesQuery.isLoading) <Loading />;

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Histórico de Pacientes</title>
      </Helmet>
      <PatientServicesDialog
        handleClose={() => setOpenServices({ isOpen: false })}
        open={openServices}
        title={"Atendimento Inicial"}
      />
      <BoxContainer title="Histórico de Pacientes">
        <Formik
          initialValues={storageFilters}
          validationSchema={validator}
          onSubmit={handleSubmit}
        >
          {({ values, resetForm }) => (
            <>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={4} alignItems="stretch">
                    <FormTextField
                      name="patient"
                      label="Buscar Paciente"
                      customHandleChange={(patient) => {
                        setStorageFilters((filters) => ({
                          ...filters,
                          patient: patient ? patient : null,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} alignItems="stretch">
                    <FormTextField
                      name="sus_card"
                      label="CNS:"
                      mask={"999999999999999"}
                      customHandleChange={(sus_card) => {
                        setStorageFilters((filters) => ({
                          ...filters,
                          sus_card: sus_card ? sus_card : null,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} alignItems="stretch">
                    <FormMultipleSelectField
                      name="services"
                      options={servicesQuery.data}
                      keys={["id", "describe"]}
                      label="Serviço"
                      customHandleChange={(_, services) => {
                        setStorageFilters((filters) => ({
                          ...filters,
                          services,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} alignItems="stretch">
                    <FormSelectFieldWithPaginate
                      service={(params) =>
                        getProviders({
                          ...params,
                          id_specialty: values?.specialty?.id,
                        })
                      }
                      fields="name"
                      name="employee"
                      label="Profissionais"
                      required
                      refetch={[values?.specialty]}
                      customHandleChange={(employee) => {
                        setStorageFilters((filters) => ({
                          ...filters,
                          employee: employee
                            ? { name: employee.name, number: employee.number }
                            : null,
                        }));
                      }}
                    />
                  </Grid>

                  <Grid item xs={3} alignItems="stretch">
                    <FormDate
                      name="initialDate"
                      label="Data Inicial"
                      maxDate={new Date()}
                      customHandleChange={(initialDate) => {
                        setStorageFilters((filters) => ({
                          ...filters,
                          initialDate,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} alignItems="stretch">
                    <FormDate
                      name="finalDate"
                      label="Data Final"
                      minDate={new Date(values.initialDate)}
                      maxDate={new Date()}
                      customHandleChange={(finalDate) => {
                        setStorageFilters((filters) => ({
                          ...filters,
                          finalDate,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} alignItems="stretch">
                    <FormSelectFieldWithPaginate
                      service={(params) =>
                        getTeam({
                          ...params,
                          id: values?.id,
                        })
                      }
                      fields="name"
                      name="team"
                      label="Equipe"
                      required
                      refetch={[values?.id]}
                      customHandleChange={(team) => {
                        setStorageFilters((filters) => ({
                          ...filters,
                          team: team
                            ? { name: team.name, number: team.number }
                            : null,
                        }));
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} alignItems="stretch">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "right",
                        justifyContent: "right",
                        pt: 2,
                      }}
                    >
                      <Button
                        onClick={() => {
                          resetForm();
                          setAttendances([]);
                          setEnableAttendances(false);
                          setStorageFilters({
                            patient: null,
                            services: [],
                            employee: null,
                            initialDate: null,
                            finalDate: null,
                            team: null,
                          });
                        }}
                        sx={{ mr: 1 }}
                      >
                        Limpar os Dados
                      </Button>
                      <SubmitButton loading={attendancesQuery.isLoading}>
                        <SearchOutlinedIcon />
                      </SubmitButton>
                    </Box>
                  </Grid>
                </Grid>
              </Form>

              <Grid item xs={12} sx={{ paddingTop: 3 }}>
                <Stack
                  ref={parent}
                  onScrollCapture={scrollEventPatients}
                  gap={1}
                  width={"100%"}
                  maxHeight={"58vh"}
                  overflow="auto"
                  paddingBottom={3}
                  paddingRight={2}
                >
                  {attendances?.map((item, index, array) => {
                    return (
                      <PatientsCard
                        patient={item}
                        key={index}
                        old={false}
                        history={true}
                        noTextSelection
                      />
                    );
                  })}
                  {page === totalPage &&
                    attendances?.length > 0 &&
                    !attendancesQuery.isFetching && (
                      <Typography color="primary.main">
                        Não há mais pacientes neste período!
                      </Typography>
                    )}
                  {attendancesQuery.isFetching && (
                    <CircularProgress sx={{ margin: "auto" }} />
                  )}
                </Stack>
              </Grid>
            </>
          )}
        </Formik>
      </BoxContainer>
    </>
  );
}

export default Patient;
