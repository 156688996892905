import { FormControl, FormHelperText, FormLabel, Slider } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "@emotion/react";

export default function SwitchField({
  control,
  name,
  label,
  marks,
  step,
  min,
  max,
  required,
  disabled,
  options = [],
  customOnChange,
  onBlur,
  onChange,
  ...props
}) {
  const theme = useTheme();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormControl error={fieldState.invalid} disabled={disabled} fullWidth>
          <FormLabel
            required={required}
            sx={{
              fontSize: "0.9rem",
              color: theme.palette.primary.light,
            }}
          >
            {label}
          </FormLabel>
          <Slider
            {...field}
            {...props}
            label={label}
            valueLabelDisplay="auto"
            disabled={disabled}
            marks={marks}
            step={step}
            min={min}
            max={max}
            onChange={(_, value) => {
              if (onChange instanceof Function) {
                onChange(value);
              } else {
                field.onChange(value);
              }

              if (customOnChange instanceof Function) {
                customOnChange(value);
              }
            }}
            onBlur={(_, value) => {
              if (onBlur instanceof Function) {
                onBlur(value);
              } else {
                field.onBlur(value);
              }
            }}
          />
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
