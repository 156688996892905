import React from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { Box, useTheme, Typography } from "@mui/material";
import { styled } from "@mui/system";

const ChartDesc = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.primary.light}`,
  fontSize: 13,
  fontWeight: "600",
}));

const PercentageDesc = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.primary.light}`,
  fontSize: 13,
  fontWeight: "300",
  marginRight: "8px",
}));

function LineAreaChart({
  width = 300,
  height = 80,
  gradientConfig = [],
  areaConfig = [],
  title = "",
  data,
  percentage = "",
}) {
  const theme = useTheme();

  const gradients = (
    <defs>
      {gradientConfig.map(({ id, from, to }) => {
        return (
          <linearGradient key={id} id={id} x1="1" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={to} stopOpacity={0.22} />
            <stop offset="90%" stopColor={from} stopOpacity={0.04} />
          </linearGradient>
        );
      })}
    </defs>
  );

  const areas = areaConfig.map(({ key, lineId, bgId }) => {
    return (
      <Area
        key={key}
        type="natural"
        dataKey={key}
        stroke={`url(#${lineId})`}
        strokeWidth={3}
        fillOpacity={1}
        fill={`url(#${bgId})`}
      />
    );
  });

  return (
    <>
      <Box maxWidth={width} width="115%" height={height}>
        <Box display="flex" justifyContent="space-between">
          <ChartDesc>{title}</ChartDesc>
          <PercentageDesc>{percentage}</PercentageDesc>
        </Box>
        <ResponsiveContainer width={"100%"} height={"120%"}>
          <AreaChart
            data={data}
            margin={{
              top: 12,
              bottom: 3,
              left: 12,
              right: 12,
            }}
          >
            <XAxis hide />
            <YAxis hide />
            <Tooltip />
            {gradients}
            {areas}
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}

export default LineAreaChart;
